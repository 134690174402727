import { AccessLevel, Application, ClientStatus } from "./enums";

export interface User {
  username: string;
  email: string;
  token: string;
  firstName: string;
  lastName: string;
  id: string;
  clientId: string;
  accessLevel?: AccessLevel;
  status?: ClientStatus;
  application?: Application;
  isDataActive: boolean;
  forcePasswordReset: boolean;
}

export interface SignUpFormValues {
  email: string;
  username?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  clientName?: string;
  password?: string;
  confirmPassword?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  faxNumber?: string;
  error?: string;
  id?: string ;
  ipAddress?: string;
  location?: string;
}

export interface RegistrationDto {
  clientId: string;
  suggestedName: string;
}

export interface UserForm {
  id?: string;
  email?: string;
  username: string;
  application?: string[];
  accessLevel?: AccessLevel;
  firstName?: string;
  lastName?: string;
  isInternal?: boolean;
  isActive?: boolean;
  password?: string;
  primaryNumber?: string;
  cellNumber?: string;
  referenceNotes?: string;
  error?: string;
}

export class UserForm implements UserForm {
  constructor(init?: UserFormValues) {
    if (init) {
      this.id = init.id;
      this.email = init.email;
      this.username = init.username;
      this.application = init.application;
      this.accessLevel = init.accessLevel;
      this.firstName = init.firstName;
      this.lastName = init.lastName;
      this.isInternal = init.isInternal && init.isInternal.toString() === "true" ? true : false;
      this.isActive = init.isActive && init.isActive.toString() === "true" ? true : false;
      this.password = init.password;
      this.primaryNumber = init.primaryNumber;
      this.cellNumber = init.cellNumber;
      this.referenceNotes = init.referenceNotes;
      this.error = init.error;
    }
  }
}

export class UserFormValues {
  id?: string = undefined;
  email?: string = undefined;
  username: string = "";
  application?: string[];
  accessLevel?: AccessLevel  = undefined;
  firstName?: string = undefined;
  lastName?: string = undefined;
  isInternal?: boolean = undefined;
  isActive?: boolean = undefined;
  password?: string = undefined;
  primaryNumber?: string = undefined;
  cellNumber?: string = undefined;
  referenceNotes?: string = undefined;
  error?: string = undefined;
  
  constructor(user?: UserForm) {
    if (user) {
      this.id = user.id;
      this.email = user.email;
      this.username = user.username;
      this.application = user.application;
      this.accessLevel = user.accessLevel;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.isInternal = Boolean(user.isInternal);
      this.isActive = user.isActive && user.isActive.toString() === "true" ? true : false;
      this.password = user.password;
      this.primaryNumber = user.primaryNumber;
      this.cellNumber = user.cellNumber;
      this.referenceNotes = user.referenceNotes;
      this.error = user.error;
    }
  }
}



export interface UserLogin {
  login: string;
  password: string;
  ipAddress: string;
  location: string;
}

export class UserChangePassword {
  id?: string = undefined;
  currentPassword?: string = undefined;
  token?: string = undefined;
  newPassword: string = "";
  confirmPassword: string = "";
  isChangePassword: boolean = false;
  forcePasswordReset: boolean = false;
}

export interface UserForgotPassword {
  emailAddress?: string
}

export interface StaffUserList {
  id: string;
  email: string;
  username: string;
  application?: string[];
  accessLevel?: AccessLevel;
  firstName?: string;
  lastName?: string;
  isInternal?: boolean;
  isActive?: boolean;
  createdDate?: Date;
  referenceNotes?: string;
}