import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, Divider, Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { TicketType } from "../../../app/models/enums";
import {
  formatCurrency,
  formatPhoneNumber,
  getTicketTypeColor,
} from "../../../app/common/util/functions";
import { format } from "date-fns";
import { RecentTickets } from "../../../app/models/ticket";
import { v4 as uuid } from "uuid";
import { useStore } from "../../../app/stores/store";
import CustomerVehicleSelection from "./CustomerVehicleSelection";
import VinDisplay from "../../../app/common/form/VinDisplay";
import { resetTicket } from "../../../app/common/util/ticketfunctions";
import AlreadyInProgress from "../dashboard/modals/AlreadyInProgress";

interface Props {
  ticket: RecentTickets;
  initialOpen: boolean;
}

export default observer(function RecentTicketsItem({
  ticket,
  initialOpen,
}: Props) {
  const { t } = useTranslation(["common", "recentTickets"]);
  const navigate = useNavigate();
  const location = useLocation();
  const { ticketSessionStore, modalStore, ticketStore } = useStore();

  const { createCustomerTicket } = ticketSessionStore;
  const { resetTicketHistoryRegistry, getIsInProgress } = ticketStore;
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);

  return (
    <table width={"100%"} className={isOpen ? "openStyles" : "closedStyles"}>
      <tr
        id={`ticketRow.${ticket.ticketId}`}
        style={{ width: "100%" }}
        key={`ticketHead.${ticket.ticketId}`}
        className={isOpen ? "recentBorders1 cellInnerBorder" : "recentsBorder"}
      >
        <th style={{ width: "33%", verticalAlign: "bottom" }}>
          <Grid columns={2}>
            <Grid.Column
              verticalAlign="bottom"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              width={12}
            >
              <Icon name={isOpen ? "angle up" : "angle down"} />
              <span className="intro">
                {ticket.businessName &&
                  ticket.isCommercial &&
                  `${ticket.businessName}${ticket.lastName ?? ticket.firstName ? ": " : ""}`}
                {ticket.lastName && `${ticket.lastName}${ticket.firstName ? ", " : ""}`}
                {ticket.firstName && `${ticket.firstName}`}
              </span>
            </Grid.Column>
            <Grid.Column textAlign="right" width={3}>
              {!isOpen && (
                <Button
                  size="mini"
                  icon="edit"
                  type="button"
                  floated="right"
                  onClick={() => {
                    resetTicket();
                    getIsInProgress(ticket.ticketId ?? "")
                      .then((result) => {
                        if (result) {
                          modalStore.openWiderModal(
                            <AlreadyInProgress
                              ticketId={ticket.ticketId ?? ""}
                            />
                          );
                        } else {
                          navigate(`/ticket/summary/${ticket.ticketId}/true`);
                        }
                      })
                      .catch();
                  }}
                />
              )}
            </Grid.Column>
          </Grid>
        </th>
        <th
          style={{ width: "34%", verticalAlign: "bottom" }}
          className="cellInnerBorder"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <span className="intro introPadding">
            {ticket.year && ticket.year > 1 && `${ticket.year} `}
            {ticket.make && `${ticket.make} `}
            {ticket.model}
          </span>
        </th>
        <th
          style={{ width: "33%", verticalAlign: "bottom" }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <Grid columns={2}>
            <Grid.Column>
              <span
                className="intro1"
                style={{ color: getTicketTypeColor(ticket.ticketType) }}
              >
                {ticket.ticketType === TicketType.Estimate
                  ? `${t("estimate.est", {
                      ns: "tickets",
                    })}# ${ticket.estimateNumber ?? 0}`
                  : `${t("estimate.invoice", { ns: "tickets" })}# ${
                      ticket.invoiceNumber
                    }`}
              </span>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <span className="right_padding">
                <Icon name="calendar alternate" />
                {ticket.ticketType === TicketType.Estimate
                  ? ticket.estimateDate
                    ? `${format(ticket.estimateDate, "P")}`
                    : ""
                  : ticket.invoiceDate
                  ? `${format(ticket.invoiceDate, "P")}`
                  : ""}
              </span>
            </Grid.Column>
          </Grid>
        </th>
      </tr>
      {isOpen && (
        <tbody>
          <tr
            id={`ticketsItem.${ticket.ticketId}`}
            style={{ width: "100%" }}
            key={`ticketsItem.${ticket.ticketId}`}
            className="recentBordersMid cellInnerBorder recentBorders2"
          >
            <td valign="top">
              <Grid>
                <Grid.Row columns={1} className="removeNegativeMargin">
                  <Grid.Column className="zero_space">
                    {ticket.addressLine1 ||
                    ticket.addressLine2 ||
                    ticket.city ||
                    ticket.state ||
                    ticket.postalCode ? (
                      <>
                        {ticket.addressLine1 &&
                          `${ticket.addressLine1}${
                            (ticket.addressLine2 ||
                              ticket.city ||
                              ticket.state ||
                              ticket.postalCode) &&
                            ","
                          } `}
                        {ticket.addressLine2 &&
                          `${ticket.addressLine2}${
                            (ticket.city ||
                              ticket.state ||
                              ticket.postalCode) &&
                            ","
                          } `}
                        {ticket.city && `${ticket.city}`}
                        {ticket.state && `, ${ticket.state}`}
                        {ticket.postalCode &&
                          `${ticket.state && ","} ${ticket.postalCode}`}
                      </>
                    ) : (
                      <i>{t("unknownAddress", { ns: "common" })}</i>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  columns={2}
                  className="removeNegativeMargin recentBoxHeight"
                >
                  <Grid.Column className="zero_space">
                    {`${
                      ticket.isCommercial
                        ? t("main", { ns: "customer" })
                        : t("cell", { ns: "common" })
                    }: ${
                      ticket.primaryNumber
                        ? formatPhoneNumber(ticket.primaryNumber)
                        : "--"
                    }`}
                  </Grid.Column>
                  <Grid.Column className="zero_space">
                    {`${
                      ticket.isCommercial
                        ? t("alt", { ns: "customer" })
                        : t("home", { ns: "common" })
                    }: ${
                      ticket.secondaryNumber
                        ? formatPhoneNumber(ticket.secondaryNumber)
                        : "--"
                    }`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  columns={1}
                  className="removeNegativeMargin recentBoxHeight"
                >
                  <Grid.Column className="zero_space email_wrap">
                    {`${t("email", { ns: "common" })}: ${
                      ticket.email ? ticket.email : "--"
                    }`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="marginBottom">
                  <Grid.Column>
                    <Divider className="removeTopMargin" />
                    <Button
                      color="green"
                      size="mini"
                      icon="plus"
                      content={t("ticket_create", {
                        ns: "common",
                      })}
                      onClick={() => {
                        resetTicket();
                        modalStore.openModal(
                          <CustomerVehicleSelection
                            customerId={ticket.customerId ?? ""}
                            
                            businessName={ticket.businessName}
                            lastName={ticket.lastName}
                            firstName={ticket.firstName}
                          />
                        );
                      }}
                    />
                    <Button
                      color="grey"
                      size="mini"
                      icon="address card outline"
                      type="button"
                      content={t("customer", {
                        ns: "common",
                      })}
                      onClick={() => {
                        navigate(`/customer/${ticket.customerId}`);
                      }}
                    />
                    <Button
                      color="grey"
                      size="mini"
                      icon="edit"
                      type="button"
                      content={t("edit", {
                        ns: "common",
                      })}
                      onClick={() => {
                        navigate(`/customer/edit/${ticket.customerId}`, {
                          state: { location },
                        });
                      }}
                    />
                    <span className="side_note floatRight vertical-align-middle ">
                      {ticket.customerDateCreated &&
                        `${t("since", {
                          ns: "recentTickets",
                        })}: ${format(ticket.customerDateCreated, "PP")}`}
                    </span>
                    <Divider className="cellBottomMobile" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </td>

            <td valign="top">
              <Grid>
                <Grid.Row
                  columns={1}
                  className="removeNegativeMargin recentBoxHeight1"
                >
                  <Grid.Column className="zero_space removeTopMargin">
                    {ticket.engine && `${ticket.engine}`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  columns={2}
                  className="removeNegativeMargin recentBoxHeight"
                >
                  <Grid.Column className="zero_space">
                    {`${t("tag", { ns: "common" })}: ${
                      ticket.tag ? ticket.tag : "--"
                    }`}
                  </Grid.Column>
                  <Grid.Column className="zero_space">
                    {`${t("mileage", { ns: "common" })}: ${
                      ticket.mileage ? ticket.mileage : "--"
                    }`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  columns={2}
                  className="removeNegativeMargin recentBoxHeight"
                >
                  <Grid.Column className="zero_space">
                    {`${t("vin", { ns: "recentTickets" })}: `}
                    {ticket.vin ? (
                      <VinDisplay vin={ticket.vin.toString()} />
                    ) : (
                      "--"
                    )}
                  </Grid.Column>
                  <Grid.Column className="zero_space">
                    {`${t("veh", { ns: "recentTickets" })}#: ${
                      ticket.vehicleNumber ? ticket.vehicleNumber : "--"
                    }`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="marginBottom">
                  <Grid.Column>
                    <Divider className="removeTopMargin" />
                    <Button
                      color="green"
                      size="mini"
                      icon="plus"
                      content={t("new_veh_tix", {
                        ns: "recentTickets",
                      }).toString()}
                      onClick={() => {
                        if (ticket.customerId && ticket.vehicleId) {
                          resetTicket();
                          let ticketId = uuid();
                          createCustomerTicket(
                            ticket.customerId,
                            ticketId,
                            ticket.vehicleId
                          ).then(() => {
                            navigate(`/ticket/jobCategory/${ticketId}/false`);
                          });
                        }
                      }}
                    />
                    <span className="side_note floatRight vertical-align-middle ">
                      {ticket.vehicleCount && ticket.vehicleCount > 1
                        ? `${ticket.vehicleCount} ${t("estimate.vehicles", {
                            ns: "tickets",
                          })}`
                        : `${ticket.vehicleCount} ${t("vehicle", {
                            ns: "common",
                          })}`}
                    </span>
                    <Divider className="cellBottomMobile" />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </td>
            <td valign="top" className="removeBottomMargin">
              <Grid>
                <Grid.Row columns={1} className="removeNegativeMargin">
                  <Grid.Column className="zero_space removeTopMargin">
                    <Icon name="wrench" />
                    {ticket.description ? ticket.description : "--"}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  columns={2}
                  className="removeNegativeMargin recentBoxHeight"
                >
                  <Grid.Column className="zero_space">
                    {`${t("total", {
                      ns: "common",
                    })}: ${formatCurrency(ticket.total ? ticket.total : 0.0)}`}
                  </Grid.Column>
                  <Grid.Column className="zero_space">
                    {ticket.poNumber &&
                      `${t("po", { ns: "common" })} #: ${ticket.poNumber}`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row className="boxSizeSame recentBoxHeight"></Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Divider className="removeTopMargin" />
                    <Button
                      color="grey"
                      size="mini"
                      icon="eye"
                      type="button"
                      content={t("view", { ns: "common" })}
                      onClick={() => {
                        resetTicket();
                        resetTicketHistoryRegistry();
                        navigate(`/ticket/view/${ticket.ticketId}`);
                      }}
                    />
                    <Button
                      color="grey"
                      size="mini"
                      icon="edit"
                      type="button"
                      content={t("edit", {
                        ns: "common",
                      })}
                      onClick={() => {
                        resetTicket();
                        getIsInProgress(ticket.ticketId ?? "")
                          .then((result) => {
                            if (result) {
                              modalStore.openWiderModal(
                                <AlreadyInProgress
                                  ticketId={ticket.ticketId ?? ""}
                                />
                              );
                            } else {
                              navigate(
                                `/ticket/summary/${ticket.ticketId}/true`
                              );
                            }
                          })
                          .catch();
                      }}
                    />
                    <Button
                      color="grey"
                      size="mini"
                      icon="plus square"
                      type="button"
                      content={t("more", {
                        ns: "recentTickets",
                      })}
                      onClick={() => {
                        navigate(`/customer/${ticket.customerId}`);
                      }}
                    />
                    <span className="side_note floatRight vertical-align-middle ">
                      {ticket.ticketCount && ticket.ticketCount > 1
                        ? `${ticket.ticketCount} ${t("tickets", {
                            ns: "tickets",
                          })}`
                        : `${ticket.ticketCount} ${t("ticket", {
                            ns: "tickets",
                          })}`}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
});
