import { observer } from "mobx-react-lite";
import { Button, Icon, Table } from "semantic-ui-react";
import { TicketSearch } from "../../app/models/search";
import {
  formatCurrency,
  formatPhoneNumber,
  getTicketTypeColor,
} from "../../app/common/util/functions";
import { useState } from "react";
import { format } from "date-fns";
import TicketItemSearch from "./TicketItemSearch";
import { useTranslation } from "react-i18next";
import { TicketType } from "../../app/models/enums";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { resetTicket } from "../../app/common/util/ticketfunctions";

interface Props {
  ticket: TicketSearch;
}

export default observer(function TicketSearch({ ticket }: Props) {
  const { t } = useTranslation(["common", "search"]);
  const navigate = useNavigate();

  const [expand, setExpand] = useState<boolean>(false);
  const { ticketStore } = useStore();
  const { resetTicketHistoryRegistry} = ticketStore
  const handleExpand = () => {
    setExpand(!expand);
  };

  return (
    <>
      <Table.Row verticalAlign="top">
        <Table.Cell className="noTextAlignMobile">
          <span
            className="intro1"
            style={{ color: getTicketTypeColor(ticket.ticketType) }}
          >
            {ticket.ticketType === TicketType.Estimate
              ? `${t("estimate.est", { ns: "tickets" })}: # ${ticket.estimateNumber && ticket.estimateNumber}` 
              : `${t("estimate.invoice", { ns: "tickets" })}: #` +
                ticket.invoiceNumber}
          </span>
          <br />
          <Icon name="calendar alternate outline"></Icon>
          <span className="largerPaddedLeft" >{ticket.ticketType === TicketType.Estimate
            ? ticket.estimateDate && format(ticket.estimateDate, "PP")
            : ticket.invoiceDate && format(ticket.invoiceDate, "PP")}</span>
          <br /> <span className="largerPaddedLeft">{ticket.businessName && `${ticket.businessName}: `}
          {ticket.lastName}{ticket.firstName && `, ${ticket.firstName}`}</span>
        </Table.Cell>
        <Table.Cell verticalAlign="bottom" className="noTextAlignMobile">
          {t("total", { ns: "common" })}: {formatCurrency(ticket.totalAmount)}
          <br />
          {`${
            ticket.isCommercial
              ? t("main", { ns: "customer" })
              : t("cell", { ns: "common" })
          }: ${ticket.primaryPhone? formatPhoneNumber(ticket.primaryPhone) : "--"}`}
          <br />
          <span style={{fontSize:"larger"}}>{`${ticket.year ?? ""} ${
            ticket.make ?? ""} ${ticket.model}`}</span>
        </Table.Cell>
        <Table.Cell verticalAlign="bottom" className="noTextAlignMobile">
          {t("estimate.po", { ns: "tickets" })}: {ticket.poNumber}
          <br />
          {`${
            ticket.isCommercial
              ? t("alt", { ns: "customer" })
              : t("home", { ns: "common" })
          }: ${ticket.secondaryPhone? formatPhoneNumber(ticket.secondaryPhone) : "--"}`} <br/><br/>
        </Table.Cell>
        <Table.Cell className="noTextAlignMobile">
          <Button
            icon="plus square"
            floated="right"
            size="small"
            content={t("more", { ns: "recentTickets" })}
            onClick={() => {
              navigate(`/customer/${ticket.customerId}`);
            }}
          />
          <Button
            icon="edit"
            floated="right"
            size="small"
            content={t("edit", { ns: "common" })}
            onClick={() => {
              resetTicket();
              navigate(`/ticket/summary/${ticket.id}/true`);
            }}
          />
          <Button
            icon="eye"
            floated="right"
            size="small"
            content={t("view", { ns: "common" })}
            className="tealishButtons"
            onClick={() => {
              resetTicket();
              resetTicketHistoryRegistry();
              navigate(`/ticket/view/${ticket.id}`);
            }}
          />
          <Button
            icon="plus square"
            floated="right"
            size="small"
            onClick={handleExpand}
            content={t("see_items", { ns: "common" })}
            color="blue"
          />
        </Table.Cell>
      </Table.Row>
      {expand && <TicketItemSearch id={ticket.id} />}
    </>
  );
});
