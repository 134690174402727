export interface SettingsDisclaimer {
  id?: string;
  invoiceMiddle: string;
  invoiceLeft: string;
  invoiceRight: string;
  estimateMiddle: string;
  estimateLeft: string;
  estimateRight: string;
  error?: string;
}
export class SettingsDisclaimer implements SettingsDisclaimer {
  constructor(init?: SettingsDisclaimerFormValues) {
    if (init) {
      this.id = init.id;
      this.invoiceMiddle = init.invoiceMiddle;
      this.invoiceLeft = init.invoiceLeft;
      this.invoiceRight = init.invoiceRight;
      this.estimateLeft = init.estimateLeft;
      this.estimateMiddle = init.estimateMiddle;
      this.estimateRight = init.estimateRight;
      this.error = init.error;
    }
  }
}

export class SettingsDisclaimerFormValues {
  id?: string = undefined;
  invoiceMiddle: string = "";
  invoiceLeft: string = "";
  invoiceRight: string = "";
  estimateMiddle: string = "";
  estimateLeft: string = "";
  estimateRight: string = "";
  error?: string = undefined;

  constructor(settingsDisclaimer?: SettingsDisclaimerFormValues) {
    if (settingsDisclaimer) {
      this.id = settingsDisclaimer.id;
      this.invoiceMiddle = settingsDisclaimer.invoiceMiddle;
      this.invoiceLeft = settingsDisclaimer.invoiceLeft;
      this.invoiceRight = settingsDisclaimer.invoiceRight;
      this.estimateMiddle = settingsDisclaimer.estimateMiddle;
      this.estimateLeft = settingsDisclaimer.estimateLeft;
      this.estimateRight = settingsDisclaimer.estimateRight;
      this.error = settingsDisclaimer.error;
    }
  }
}
