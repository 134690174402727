import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxProps,
  Grid,
  Icon,
  Input,
  Message,
  Table,
} from "semantic-ui-react";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { FreeTrialRequestEmail, FreeTrialRequestReport, FreeTrial, SetTrial } from "../../../app/models/systemAdmin";
import agent from "../../../app/api/agent";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";
import { downloadCSV } from "../../../app/common/util/csv";

export default observer(function TrialEmails() {
  const { t } = useTranslation(["common"]);
  const { trialRequestsStore } = useStore();
  const {
    loadTrialRequests,
    getTrialRequests,
    getTrialRequest,
    pagination,
    setPagingParams,
    pagingParams,
    setPredicate,
    selectedId,
    selectedIds,
    anySelected,
    selectId,
    clearSelected,
    selectAll,
  } = trialRequestsStore;

  useEffect(() => {
    loadTrialRequests();
  }, [loadTrialRequests]);

  const YesNoIcon: React.FC<{ value: boolean }> = ({ value }) => 
      value ? <Icon name="check" color="green" /> : <Icon name="x" color="red" />
  
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const handleCheckboxChange = (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    selectId(data.value as number, data.checked ?? false)
  }

  const handleSelectAll = (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
    setAllSelected(data.checked ?? false);

    if (data.checked) {
      selectAll();
    } else {
      clearSelected();
    }
  }

  const handleFreeTrialAction = (action: (values: FreeTrial) => Promise<void>) => {
    if (!anySelected()) return;

    action({trialId: [...selectedIds()]}).then(() => {
      loadTrialRequests();
    });
  }

  const handleSetTrialAction =  (action: (values: SetTrial) => Promise<void>, clean: boolean) => {
        if (!anySelected()) return;

    action({trialId: [...selectedIds()], setClean: clean}).then(() => {
      loadTrialRequests();
    });
  }

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadTrialRequests();
      
      setAllSelected(false);
      clearSelected();
    }
  }

  function exportEntries() {
    if (!anySelected()) return;

    let objArray: FreeTrialRequestReport[] = [];
    selectedIds().forEach((id) => {
      let req = getTrialRequest(id);
      if (req) {
        objArray.push(req);
      }
    });

    downloadCSV(objArray, 'freetrialrequest');
  }

  function exportEmails() {
    if (!anySelected()) return;

    let objArray: FreeTrialRequestEmail[] = [];
    selectedIds().forEach((id) => {
      let req = getTrialRequest(id);
      if (req) {
        objArray.push({
          id: req.id,
          createdDate: req.createdDate,
          contactName: req.contactName,
          companyName: req.companyName,
          email: req.email,
        });
      }
    });

    downloadCSV(objArray, 'freetrialrequestemail');
  }

  return (
    <>
      <Grid>
        <Grid.Row>
          {" "}
          <Grid.Column>
            <PageHeader
              header="Free Trial Requests"
              type={"h1"}
              divider={false}
              addTitle={true}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Input
              onChange={(e, d) => {
                setPagingParams(new PagingParams());
                setPredicate("SearchTerm", d.value);
                clearSelected();
                loadTrialRequests();
              }}
              placeholder={""}
              name={""}
              icon={"search"}
            />
          </Grid.Column>
          <Grid.Column>
            <div style={{ marginBottom: "4px" }}>
              <Button content="EXPORT" color="blue" size="mini" onClick={(e) => exportEntries()} />
              <Button content="EMAILS" color="blue" size="mini" onClick={(e) => exportEmails()}/>
              <Button
                content="CLEAN"
                color="green"
                size="mini"
                onClick={(e) =>
                  handleSetTrialAction(agent.SystemAdminTools.setTrialRequestType, true)
                }
              />
              <Button
                content="DIRTY"
                color="red"
                size="mini"
                onClick={(e) =>
                  handleSetTrialAction(
                    agent.SystemAdminTools.setTrialRequestType,
                    false
                  )
                }
              />
              <Button
                content="EMAIL"
                color="blue"
                size="mini"
                onClick={(e) =>
                  handleFreeTrialAction(agent.SystemAdminTools.approveFreeTrial)
                }
              />
            </div>
            <div>
              <Button
                content="EMAIL FOLLOW-UP"
                color="blue"
                size="mini"
                onClick={(e) =>
                  handleFreeTrialAction(agent.SystemAdminTools.followUpFreeTrial)
                }
              />
              <Button
                content="REJECT"
                color="red"
                size="mini"
                onClick={(e) =>
                  handleFreeTrialAction(agent.SystemAdminTools.rejectFreeTrial)
                }
              />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    <Checkbox onChange={handleSelectAll} checked={allSelected}></Checkbox>
                  </Table.HeaderCell>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>
                    Contact Name/ <br />
                    Company Name
                  </Table.HeaderCell>
                  <Table.HeaderCell>Contact Information</Table.HeaderCell>
                  <Table.HeaderCell>Address</Table.HeaderCell>
                  <Table.HeaderCell className="verticalText">
                    Clean
                  </Table.HeaderCell>
                  <Table.HeaderCell className="verticalText">
                    Imported
                  </Table.HeaderCell>
                  <Table.HeaderCell className="verticalText">
                    Emailed
                  </Table.HeaderCell>
                  <Table.HeaderCell className="verticalText">
                    Followed Up
                  </Table.HeaderCell>
                  <Table.HeaderCell className="verticalText">
                    Rejected
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {getTrialRequests.length > 0 ? (
                  getTrialRequests.map((x) => (
                    <Table.Row key={`trialrequest.${x.id}`}>
                      <Table.Cell>
                        <Checkbox
                          checked={selectedId(x.id)}
                          onChange={handleCheckboxChange}
                          name={`trialrequest.${x.id}`}
                          value={x.id}
                        ></Checkbox>
                      </Table.Cell>
                      <Table.Cell>{format(x.createdDate, "P")}</Table.Cell>
                      <Table.Cell>
                        {x.contactName} <br />
                        {x.companyName}
                      </Table.Cell>
                      <Table.Cell>
                        {x.email && <div>{x.email}</div>}
                        {x.phoneNumber && <div>{x.phoneNumber}</div>}
                      </Table.Cell>
                      <Table.Cell>
                        <div>{x.addressLine1}</div>
                        <div>
                          {x.city} {x.state} {x.postalCode}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <YesNoIcon value={x.isClean} />
                      </Table.Cell>
                      <Table.Cell>
                        <YesNoIcon value={x.isImported} />
                      </Table.Cell>
                      <Table.Cell>
                        <YesNoIcon value={x.isEmailed} />
                      </Table.Cell>
                      <Table.Cell>
                        <YesNoIcon value={x.isFollowUp} />
                      </Table.Cell>
                      <Table.Cell>
                        <YesNoIcon value={x.isRejected} />
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row key={"noitems"}>
                    <Table.Cell width="8" colSpan={8}>
                      <Message
                        color="yellow"
                        className="textAlignCenter fontStyleItalic"
                      >
                        {t("grid_noItems", { ns: "common" })}
                      </Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="6">
                    <MyPaging
                      itemCount={pagination ? pagination.totalItems : 25}
                      pageSize={pagingParams.pageSize}
                      currentPage={pagination ? pagination.currentPage : 1}
                      onClick={listOnClick}
                      onChange={listOnClick}
                      floated="right"
                    ></MyPaging>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
});
