import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Divider, Button } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { router } from "../../../app/router/Routes";
import { useNavigate, useParams } from "react-router-dom";
import AddAdminNotes from "../AddAdminNotes";
import AdminNotesList from "../AdminNotesList";
import SystemAdministrationClientDetail from "./SystemAdministrationClientDetail";
import ClientAvailableTrials from "./ClientAvailableTrials";
import ClientCompanyStatuses from "./ClientCompanyStatuses";
import ClientBillingSubscription from "./ClientBillingSubscription";
import UpdateClient from "./UpdateClient";
import ClientExtendedTrial from "./ClientExtendedTrial";
import ClientSubscriptionHistory from "./ClientSubscriptionHistory";
import ClientContact from "./ClientContact";
import ClientUsers from "./ClientUsers";
import AuthenticationLog from "../AuthenticationLog";
import SysAdminSidebar from "../SysAdminSidebar";
import { AccessLevel, ClientStatus } from "../../../app/models/enums";
import { Pagination, PagingParams } from "../../../app/models/pagination";
import UpdateClientStatus from "./UpdateClientStatus";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function ViewClient() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { id } = useParams<{ id: string }>();

  const {
    modalStore,
    clientSystemAdminStore,
    authenticationLogStore,
    userStore,
    adminNotesStore,
    subscriptionStore,
  } = useStore();

  const { checkAccessLevel } = userStore;

  const { selectedSystemAdminClientDetails, loadClientInformation } =
    clientSystemAdminStore;
  const { createSubscriptionExtension } =
    subscriptionStore;

  const { resetAuthenticationLogs, clearPredicate } = authenticationLogStore;

  const {
    getAdminNotes,
    loadClientAdminNotes,
    pagination: adminNotesPagination,
    pagingParams: adminNotesPagingParams,
    setPagingParams: setAdminNotesPagingParams,
  } = adminNotesStore;

  useEffect(() => {
    resetAuthenticationLogs();
    clearPredicate();
  }, []);

  useEffect(() => {
    loadClientInformation(id ?? "");
  }, [loadClientInformation, id]);

  useEffect(() => {
    loadClientAdminNotes(id);
  }, [loadClientAdminNotes, id]);

  function adminNotesListOnClick(value: number) {
    if (
      value > 0 &&
      adminNotesPagination &&
      value < adminNotesPagination?.totalPages + 1
    ) {
      setAdminNotesPagingParams(new PagingParams(value));
      loadClientAdminNotes(id);
    }
  }

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column width={3}>
          <SysAdminSidebar />
        </Grid.Column>
        <Grid.Column width={13}>
          <Grid.Row columns={1}>
            <Grid.Column>
              <PageHeader
                type="h1"
                header={`View Company: ${selectedSystemAdminClientDetails?.name}`}
                className="modal-text-color"
                textAlign="left"
                divider
                addTitle
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <SystemAdministrationClientDetail
                selectedClientInformation={selectedSystemAdminClientDetails}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                floated="left"
                content="Update Company"
                size="mini"
                onClick={() => {
                  modalStore.openModal(
                    <UpdateClient
                      clientDetailsFormValues={selectedSystemAdminClientDetails}
                    />
                  );
                }}
                type="button"
              />
              <Button
                floated="left"
                content="Add Admin Notes"
                size="mini"
                onClick={() => {
                  modalStore.openModal(
                    <AddAdminNotes
                      clientId={id ?? ""}
                      loadAdminNotes={loadClientAdminNotes}
                    />
                  );
                }}
                type="button"
              />
              {((selectedSystemAdminClientDetails?.status ?? 0) &
                ClientStatus.Trial) ==
                ClientStatus.Trial && (
                <Button
                  floated="left"
                  content="Extend Trial"
                  size="mini"
                  onClick={() => {
                    modalStore.openModal(
                      <ClientExtendedTrial clientId={id ?? ""} />
                    );
                  }}
                  type="button"
                />
              )}
              {((selectedSystemAdminClientDetails?.status ?? 0) &
                ClientStatus.Subscription) ==
                ClientStatus.Subscription && (
                <Button
                  floated="left"
                  content="Extend Subscription"
                  size="mini"
                  onClick={() => {
                    createSubscriptionExtension(id ?? "", true).then(() => {
                      loadClientInformation(id ?? "");
                    });
                  }}
                  type="button"
                />
              )}
              {checkAccessLevel(
                AccessLevel.SystemAdministrator | AccessLevel.Manager
              ) && (
                <>
                  <Button
                    floated="left"
                    content="Company Data Recovery"
                    size="mini"
                    onClick={() => {
                      router.navigate(`/admin/company/datarecovery/${id}`);
                    }}
                    type="button"
                  />
                  <Button
                    floated="left"
                    content="Update Company Status"
                    size="mini"
                    onClick={() => {
                      modalStore.openModal(
                        <UpdateClientStatus
                          clientDetailsFormValues={
                            selectedSystemAdminClientDetails
                          }
                        />
                      );
                    }}
                    type="button"
                  />
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row columns={1}>
            <Grid.Column>
              <Header
                as="h2"
                content={t("logs.notes_intro", { ns: "systemAdmin" })}
                className="modal-text-color"
                textAlign="left"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <AdminNotesList
                adminNotes={getAdminNotes}
                pagination={adminNotesPagination}
                pagingParams={adminNotesPagingParams}
                listOnClick={adminNotesListOnClick}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Divider />
            <Grid.Column>
              <Header
                as="h2"
                content={t("companies.payment_log", { ns: "systemAdmin" })}
                className="modal-text-color"
                textAlign="left"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ClientBillingSubscription clientId={id ?? ""} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Divider />
            <Grid.Column>
              <Header
                as="h2"
                content={t("companies.subscription_history", {
                  ns: "systemAdmin",
                })}
                className="modal-text-color"
                textAlign="left"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ClientSubscriptionHistory clientId={id ?? ""} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Divider />
            <Grid.Column>
              <Header
                as="h2"
                content={t("companies.contacts", { ns: "systemAdmin" })}
                className="modal-text-color"
                textAlign="left"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ClientContact clientId={id ?? ""} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Divider />
            <Grid.Column>
              <Header
                as="h2"
                content={t("users", { ns: "common" })}
                className="modal-text-color"
                textAlign="left"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ClientUsers clientId={id ?? ""} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Divider />
            <Grid.Column>
              <Header
                as="h2"
                content={t("logs.auth_intro", { ns: "systemAdmin" })}
                className="modal-text-color"
                textAlign="left"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <AuthenticationLog clientId={id} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Divider />
            <Grid.Column>
              <Header
                as="h2"
                content={t("reports.company_status", { ns: "systemAdmin" })}
                className="modal-text-color"
                textAlign="left"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <ClientCompanyStatuses clientId={id ?? ""} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Divider />
            <Grid.Column>
              <Header
                as="h2"
                content={t("availabletrials", { ns: "subscription" })}
                className="modal-text-color"
                textAlign="left"
              />
            </Grid.Column>
          </Grid.Row>{" "}
          <Grid.Row columns={1}>
            <Grid.Column>
              <ClientAvailableTrials clientId={id ?? ""} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row></Grid.Row>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
});
