import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Icon,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../app/layout/PageHeader";
import { useStore } from "../../app/stores/store";
import { CustomerFormValues } from "../../app/models/customer";
import { format } from "date-fns";
import ConfirmDelete from "../../app/common/form/ConfirmDelete";
import { Vehicle } from "../../app/models/vehicle";
import CustomerVehicleForm from "../customerVehicle/CustomerVehicleForm";
import { AccessLevel, TicketItemType } from "../../app/models/enums";
import CustomerVehicleSelection from "../tickets/recentTickets/CustomerVehicleSelection";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { PagingParams } from "../../app/models/pagination";
import PartItemForm from "../tickets/dashboard/modals/PartItemForm";
import { TicketItemFormValues } from "../../app/models/ticket";

export default observer(function PartDetails() {
  const { t } = useTranslation(["common", "customer"]);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    customerStore,
    modalStore,
    customerVehicleStore,
    customerTicketStore,
    userStore,
    customerHistoryReportStore,
    ticketStore,
  } = useStore();

  const { checkAccessLevel } = userStore;

  const { resetCustomerHistory } = customerHistoryReportStore;

  const {
    loadCustomer,
    selectedCustomer,
    setSelectedCustomer,
    removeCustomer,
    loadCustomerNotes,
    customerNotes,
    setSelectedCustomerNote,
    removeCustomerNotes,
    loadingInitial: customerLoading,
  } = customerStore;

  const {
    loadCustomerVehicles,
    resetCustomerVehicleRegistry,
    loadingInitial: vehicleLoading,
  } = customerVehicleStore;

  const {
    resetCustomerTicketRegistry,
    loadCustomerTickets,
    loadingInitial: ticketsLoading,
    setPagingParams,
    resetPredicate,
  } = customerTicketStore;

  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;

  const [loadingInitial, setLoadingInitial] = useState<boolean>(false);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  useEffect(() => {
    if (id) {
      setLoadingInitial(true);
      loadCustomer(id)
        .then((customer) => {
          resetCustomerVehicleRegistry();
          resetCustomerTicketRegistry();
          setSelectedCustomer(new CustomerFormValues(customer));
          setPagingParams(new PagingParams());
          loadCustomerTickets(id);
          loadCustomerVehicles(id);
          loadCustomerNotes(id);
        })
        .finally(() => setLoadingInitial(false));
    }
  }, [loadCustomer, id, loadCustomerNotes, loadCustomerTickets, loadCustomerVehicles, resetCustomerTicketRegistry, resetCustomerVehicleRegistry, setSelectedCustomer]);

  if (loadingInitial && customerLoading && vehicleLoading && ticketsLoading)
    return (
      <LoadingComponent
        content={t("customerDetails_loading", { ns: "customer" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("Part Details: Part Desc. Here", { ns: "common" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={false}
      />
      <Container className="page">
        <Segment>
          <Grid columns={2}>
            <Grid.Column>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">Part Number:</span>
                  </Grid.Column>
                  <Grid.Column>12345</Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Grid.Column className="textAlignRight">
                    <span className="intro">Average Cost:</span>
                  </Grid.Column>
                  <Grid.Column>$225</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">Total Quantity:</span>
                  </Grid.Column>
                  <Grid.Column>12</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">Description:</span>
                  </Grid.Column>
                  <Grid.Column>The thingy that does the stuff</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">Unit Price:</span>
                  </Grid.Column>
                  <Grid.Column>$500</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">Location:</span>
                  </Grid.Column>
                  <Grid.Column>2012 Goliax St</Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
        </Segment>
        <Grid>
          <Grid.Column>
            <Button
              className="save_button mobile_buttons"
              icon="edit"
              content={t("Edit Part", { ns: "common" })}
              onClick={() => {
                modalStore.openModal(
                  <PartItemForm
                    isAdd={false}
                    addItem={function (
                      values: TicketItemFormValues,
                      type: TicketItemType
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                    autoCapitalizeFirstLetter={false}
                    vendorDropdownList={[]}
                  />
                );
              }}
            />

            <Button
              color="orange"
              className="save_button mobile_buttons"
              icon="dolly"
              content={t("Vendor Report", { ns: "customer" })}
              onClick={() => {
                resetCustomerHistory();
                navigate(`/reports/vendor/`);
              }}
            />
            <Button
              color="yellow"
              className="save_button mobile_buttons"
              icon="car"
              type="button"
              content={t("Part Report", { ns: "customer" })}
              onClick={() => {
                navigate(`/reports/partSales/`, { state: { location } });
              }}
            />
            <Button
              color="olive"
              className="save_button mobile_buttons"
              icon="clock"
              type="button"
              content={t("Edit Notification Setting", { ns: "customer" })}
              onClick={() => {
                navigate(`/customer/edit/${id}`, { state: { location } });
              }}
            />
            {checkAccessLevel(AccessLevel.Manager) && (
              <Button
                color="red"
                className="save_button mobile_buttons"
                icon="delete calendar"
                type="button"
                content={t("Delete Part", { ns: "customer" })}
                onClick={() => {
                  modalStore.openModal(
                    <ConfirmDelete
                      header={t("removeCustomer", {
                        ns: "customer",
                      })}
                      value={id}
                      text={t("removeCustomerText", {
                        ns: "customer",
                      })}
                      deleteValue={(d) => {
                        removeCustomer(d).then(() => {
                          navigate("/customers");
                        });
                      }}
                    />
                  );
                }}
              />
            )}
            <Button
              className="save_button mobile_buttons"
              as={Link}
              to="/inventoryIndex"
              icon="cancel"
              type="button"
              content={t("cancel", { ns: "common" })}
            />
          </Grid.Column>
        </Grid>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={t("vendors.vendor", { ns: "miscSettings" })}
                type={"h3"}
                divider={false}
                addTitle={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Divider></Divider>
          <Grid.Row>
            <Grid.Column>
              <Table striped unstackable className="dnxTable no_padding">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {t("vendors.vendor", { ns: "miscSettings" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("Cost", { ns: "common" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("Markup %", { ns: "common" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("Quantity in Stock", { ns: "common" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("Quantity Used", { ns: "common" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("Core Tracking", { ns: "common" })}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row key={""}>
                    <Table.Cell
                      data-label={`${t("description", { ns: "common" })}:`}
                    >
                      O'Reilly
                    </Table.Cell>
                    <Table.Cell data-label={`${t("date", { ns: "common" })}:`}>
                      $225
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("ticket", { ns: "common" })}:`}
                    >
                      125%
                    </Table.Cell>
                    <Table.Cell data-label={`${t("po", { ns: "common" })}#:`}>
                      10
                    </Table.Cell>
                    <Table.Cell data-label={`${t("total", { ns: "common" })}:`}>
                      482
                    </Table.Cell>
                    <Table.Cell data-label={`${t("total", { ns: "common" })}:`}>
                      <Icon name="check" color="green" size="big" />
                    </Table.Cell>
                  </Table.Row>
                  {/* ))
          ) : (
            <Table.Row key={"noItems"}>
              <Table.Cell width="8" colSpan={8}  className="noPaddingLeft">
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )} */}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign="bottom">
              <PageHeader
                header={t("Locations", { ns: "customer" })}
                type={"h3"}
                divider={false}
                addTitle={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Divider></Divider>
          <Grid.Row>
            <Grid.Column>
              <Table striped unstackable className="dnxTable">
                <Table.Header>
                  <Table.Row key={"notesheader"}>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t("Location", { ns: "common" })}`}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t("Quantity in Stock", { ns: "customer" })}`}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t("note", { ns: "customer" })}`}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row key={``}>
                    <Table.Cell className="textAlignLeft">
                      <Button
                        color="grey"
                        icon="edit"
                        type="button"
                        onClick={() => {}}
                      ></Button>
                    </Table.Cell>
                    <Table.Cell data-label={`${t("date", { ns: "common" })}:`}>
                      Neverland (as a link)
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("author", { ns: "customer" })}:`}
                    >
                      10
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("note", { ns: "customer" })}:`}
                    >
                      idk. I'm sure they will have something they want to put
                      here?
                    </Table.Cell>
                  </Table.Row>
                  {/* ))
                  ) : (
                    <Table.Row key={"noItems1"}>
                      <Table.Cell
                        className="noPaddingLeft"
                        width="8"
                        colSpan={8}
                      >
                        <Message
                          color="yellow"
                          className="textAlignCenter fontStyleItalic"
                        >
                          {t("grid_noItems", { ns: "common" })}
                        </Message>
                      </Table.Cell>
                    </Table.Row>
                  )} */}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={t("tickets", { ns: "customer" })}
                type={"h3"}
                divider={false}
                addTitle={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Divider></Divider>
          <Grid.Row>
            <Grid.Column>
              <Table striped unstackable className="dnxTable">
                <Table.Header>
                  <Table.Row key={"notesheader"}>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t("Ticket #", { ns: "common" })}`}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t("Customer Details", { ns: "customer" })}`}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t("Vehicle Details", { ns: "customer" })}`}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t("Ticket Total", { ns: "customer" })}`}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row key={``}>
                    <Table.Cell className="textAlignLeft"></Table.Cell>
                    <Table.Cell data-label={`${t("date", { ns: "common" })}:`}>
                      #11251 (as a link)
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("author", { ns: "customer" })}:`}
                    >
                      Sheryl Crow (as a link)
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("note", { ns: "customer" })}:`}
                    >
                      Insert Fancy Car Info Here (as a link)
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("note", { ns: "customer" })}:`}
                    >
                      $5123989.00
                    </Table.Cell>
                  </Table.Row>
                  {/* ))
                  ) : (
                    <Table.Row key={"noItems1"}>
                      <Table.Cell
                        className="noPaddingLeft"
                        width="8"
                        colSpan={8}
                      >
                        <Message
                          color="yellow"
                          className="textAlignCenter fontStyleItalic"
                        >
                          {t("grid_noItems", { ns: "common" })}
                        </Message>
                      </Table.Cell>
                    </Table.Row>
                  )} */}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
});
