import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { EarlyChargeReport } from "../../models/systemAdmin";

//TODO: has no API, so set up as best as could until API is built. As of now, this is in the store, has an API call(needs to be double checked to make sure it aligns with the API), the start of the DTO, and the start of a UI.

export default class EarlyChargeStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.earlyChargeRegistry.clear();
        this.loadEarlyChargeReport();
      }
    );
  }

  loading = false;
  setLoading = (state: boolean) => {
    this.loading = state;
  };

  predicate = new Map().set("all", true);
  earlyChargeRegistry = new Map<number, EarlyChargeReport>();

  loadEarlyChargeReport = async () => {
    this.loading = true;
    try {
         this.earlyChargeRegistry.clear();
         const result = await agent.SystemAdminReports.getEarlyChargeReport();
         runInAction(() => {
           result.forEach((earlyChargeReport) => {
             this.setEarlyChargeReport(earlyChargeReport);
           });
         });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  private setEarlyChargeReport = (
    earlyChargeReport: EarlyChargeReport
  ) => {
    earlyChargeReport.charge1 = new Date(earlyChargeReport.charge1);
    earlyChargeReport.charge2 = new Date(earlyChargeReport.charge2);
    earlyChargeReport.nextChargeDate = new Date(earlyChargeReport.nextChargeDate);
    this.earlyChargeRegistry.set(
      earlyChargeReport.id,
      earlyChargeReport
    );
  };

  get getEarlyChargeReport() {
    return Array.from(this.earlyChargeRegistry.values()).sort(
      (a, b) => b.id - a.id
    );
  }

  fixEarlyChargeReport = async (ids: number[]) => {
    this.loading = true;
    try {
      await agent.SystemAdminReports.fixEarlyChargeReport(ids);
      runInAction(() => {
        this.loadEarlyChargeReport();
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  }

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (startDate?: Date, endDate?: Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };

    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
  };
}
