import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Button, Grid, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { v4 as uuid } from "uuid";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { resetTicket } from "../../../app/common/util/ticketfunctions";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";

interface Props {
  customerId: string;
  businessName?: string;
  lastName?: string;
  firstName?: string;
}

export default observer(function CustomerVehicleSelection({
  customerId,
  businessName,
  lastName,
  firstName,
}: Props) {
  const { t } = useTranslation(["common", "customer"]);
  const {
    vehicleStore,
    ticketSessionStore,
    modalStore,
    customerStore,
    customerVehicleStore,
  } = useStore();
  const { setSelectedVehicle, resetMakeList, resetModelList, resetEngineList } =
    vehicleStore;

  const {
    loadCustomerVehicles,
    customerVehicles,
    resetCustomerVehicleRegistry,
    pagination,
    pagingParams,
    setPagingParams,
  } = customerVehicleStore;

  const {
    createCustomerTicket,
    setSelectedPONumber,
    setSelectedTicketSession,
    resetTicketItems,
    resetTicketPaymentItems,
    resetJobLabors,
    resetLabors,
  } = ticketSessionStore;
  const { setSelectedCustomer } = customerStore;
  const navigate = useNavigate();

  useEffect(() => {
    resetCustomerVehicleRegistry();
    loadCustomerVehicles(customerId);
  }, [loadCustomerVehicles, customerId]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      resetCustomerVehicleRegistry();
      loadCustomerVehicles(customerId);
    }
  }

  return (
    <>
      <PageHeader
        type="h1"
        header={t("customer_new_ticket", { ns: "customer" })}
        divider={true}
        addTitle={false}
      />
      <Grid>
        <Grid.Row width={16}>
          <Grid.Column>
            {`${t("customer", { ns: "common" })}: `}
            <Link
              to={`/customer/${customerId}`}
              onClick={() => {
                resetCustomerVehicleRegistry();
                modalStore.closeModal();
              }}
            >
              {businessName
                ? `${businessName}${lastName ?? firstName ? ": " : ""} `
                : ""}
              {lastName && `${lastName}${firstName ? ", " : ""}`}
              {firstName && `${firstName}`}
            </Link>
            <br />
            {` ${t("select_vehicle", { ns: "customer" })}`}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button
              className="save_button"
              type="button"
              color="green"
              content={` ${t("start_mew_ticket", { ns: "customer" })}`}
              onClick={() => {
                resetTicket();
                let ticketId = uuid();
                createCustomerTicket(customerId ?? "", ticketId).then(() => {
                  navigate(`/ticket/vehicle/${ticketId}/false`);
                  modalStore.closeModal();
                });
              }}
            />
            <Button
              className="save_button"
              icon="cancel"
              type="reset"
              content={t("cancel", { ns: "common" })}
              onClick={() => {
                resetCustomerVehicleRegistry();
                modalStore.closeModal();
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("date", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("vehicledescription", { ns: "common" })}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {customerVehicles &&
                  customerVehicles.length > 0 &&
                  customerVehicles.map((item) => (
                    <Table.Row key={`customerVehicle.${item.id}`}>
                      <Table.Cell>
                        <Button
                          icon="plus"
                          className="save_button"
                          type="button"
                          color="green"
                          onClick={() => {
                            resetTicket();
                            let ticketId = uuid();
                            createCustomerTicket(
                              customerId ?? "",
                              ticketId,
                              item.id
                            ).then(() => {
                              navigate(
                                `ticket/jobCategory/${ticketId}/:complete`
                              );
                              modalStore.closeModal();
                            });
                          }}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {item.createdDate && format(item.createdDate, "Pp")}
                      </Table.Cell>
                      <Table.Cell>
                        {(item.year ?? 0) > 1 && `${item.year} `}
                        {item.makeName &&
                          `${item.makeName ? item.makeName : ""} `}
                        {`${item.modelName} `}
                        <br></br>
                        {item.engineName}
                        <br></br>
                        {item.vin && (
                          <i>
                            {`${t("estimate.vin", { ns: "tickets" })}: ${
                              item.vin
                            }`}
                            <br></br>{" "}
                          </i>
                        )}

                        {item.tag && (
                          <i>
                            {`${t("tag", { ns: "common" })}: ${item.tag}`}
                            <br></br>
                          </i>
                        )}

                        {item.vehicleNumber && (
                          <i>
                            {`${t("vehicle", { ns: "common" })}: ${
                              item.vehicleNumber
                            }`}
                            <br></br>
                          </i>
                        )}
                        {(item.mileageOut || item.mileageIn) && (
                          <i>
                            {`${t("mileage", { ns: "common" })}: ${
                              item.mileageOut ? item.mileageOut : item.mileageIn
                            }`}
                            <br></br>
                          </i>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell colSpan="3">
                    <MyPaging
                      itemCount={pagination ? pagination.totalItems : 10}
                      pageSize={pagingParams.pageSize}
                      currentPage={pagination ? pagination.currentPage : 1}
                      onClick={listOnClick}
                      onChange={listOnClick}
                      floated="right"
                    />
                  </Table.Cell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
});
