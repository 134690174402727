import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { TicketItemType } from "../../../app/models/enums";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../app/layout/compose";
import { TicketItem } from "../../../app/models/ticket";
import RepairOrderLaborPdfView from "./TicketViewComponents/RepairOrderLaborPdfView";
import RepairOrderPartsPdfView from "./TicketViewComponents/RepairOrderPartsPdfView";

interface Props {
  showNotesLabel: boolean;
}

export default observer(function RepairOrderBodyPdf({ showNotesLabel }: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  const { ticketSessionStore } = useStore();

  const { selectedTicketSession } = ticketSessionStore;


  let laborTicketItems: TicketItem[] = [];
  let partTicketItems: TicketItem[] = [];

  if (
    selectedTicketSession?.ticketItems &&
    selectedTicketSession?.ticketItems.length > 0
  ) {
    laborTicketItems = selectedTicketSession.ticketItems.filter((x) => {
      return x.type === TicketItemType.Labor;
    });

    partTicketItems = selectedTicketSession.ticketItems.filter((x) => {
      return x.type === TicketItemType.Parts;
    });
  }

  return (
    <View>
      {selectedTicketSession && (
        <>
          <View style={{ fontWeight: "bold", fontSize: "4mm" }}>
            <Text>{t("estimate.labor", { ns: "tickets" })}</Text>
          </View>
          <RepairOrderLaborPdfView ticketItems={laborTicketItems} />
          <View
            style={{ fontWeight: "bold", paddingTop: "6mm", fontSize: "4mm" }}
          >
            <Text>{t("estimate.parts", { ns: "tickets" })}</Text>
          </View>
          <RepairOrderPartsPdfView ticketItems={partTicketItems} />
        </>
      )}
      {(showNotesLabel || selectedTicketSession?.notes )&& (
        <View style={{ ...compose("view flex pageWidth") }}>
          <View style={{ ...compose("view") }}>
            <Text
              style={{ fontWeight: "bold", paddingTop: "3mm"}}
            >
              {t("estimate.notes", { ns: "tickets" })}:{" "}
            </Text>
          </View>
          <View style={{ ...compose("view pageWidth") }}>
            <Text style={{paddingTop: "3mm"}}>{selectedTicketSession?.notes}</Text>
          </View>
        </View>
      )}
    </View>
  );
});
