import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Header,
  Divider,
  Grid,
  Button,
  Icon,
  Table,
  Input,
  Message,
  Checkbox,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Sidebar from "./Sidebar";
import PageHeader from "../../../app/layout/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";

import PackageJobDetails from "../../packageJobs/PackageJobDetails";
import ConfirmDelete from "../../../app/common/form/ConfirmDelete";
import {
  formatCurrency,
  getMiscChargeAmount,
  getColor,
  getComboColor,
  getNegative,
  round2Decimals,
  formatCurrencyDecimal,
} from "../../../app/common/util/functions";
import { TicketItem, TicketItemFormValues } from "../../../app/models/ticket";
import { MiscellaneousCharge } from "../../../app/models/miscellaneousCharges";
import {
  SalesTaxOptionType,
  TicketItemType,
  TicketWorkflowSteps,
} from "../../../app/models/enums";
import { v4 as uuid } from "uuid";
import PotentialCustomerMatchingMessage from "../../customers/PotentialCustomerMatchingMessage";
import VendorStore from "../../../app/stores/vendorStore";
import PartItemForm from "./modals/PartItemForm";
import LaborItemForm from "./modals/LaborItemForm";
import MiscellaneousItemForm from "./modals/MiscellaneousItemForm";

interface Props {
  defaultMarkup?: number;
  settingsTaxRate: number;
  settingsSalesTaxOption: SalesTaxOptionType;
}

export default observer(function Summary({
  defaultMarkup,
  settingsTaxRate,
  settingsSalesTaxOption,
}: Props) {
  const { t } = useTranslation(["common", "translation"]);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { complete } = useParams<{ complete: string }>();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const {
    ticketSessionStore,
    modalStore,
    ticketStore,
    miscellaneousChargeStore,
    customerStore,
    vehicleStore,
    vendorStore
  } = useStore();

  const {
    loadMiscellaneousCharges,
    loadTicketSummaryMiscCharges,
    ticketSummaryMiscCharges,
    addTicketSummaryMiscCharges,
    removeTicketSummaryMiscCharges,
    allMiscellaneousChargesForTicket,
    updatedCharge,
    setUpdateMiscellaneousCharges,
    useUpdatedMiscellaneousCharges,
  } = miscellaneousChargeStore;

  const {
    loadSettingsWorkflow,
    selectedWorkflow,
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;
  const { selectedCustomer } = customerStore;
  const { selectedVehicle } = vehicleStore;

  const {
    selectedTicketSession,
    updateTicketSession,
    addTicketItemToSelectedTicketSession,
    ticketItems,
    removeTicketItem,
    updateSortOrder,
    calculateTotals,
    newSelectedJobLabor,
    removeLaborAndJobLabor,
    calculateMiscellaneousCharges,
    setSelectedTicketDescription,
    updateIsTaxable,
  } = ticketSessionStore;

  const {loadDropdownListVendors, vendorDropdownList, loadVendors} = vendorStore

  
  useEffect(() => {
    loadDropdownListVendors();
  }, [loadDropdownListVendors]);

  useEffect(() => {
    loadMiscellaneousCharges().then(() => {
      loadTicketSummaryMiscCharges(Array.from(ticketItems.values()));
    });
  }, [loadMiscellaneousCharges, loadTicketSummaryMiscCharges]);

  const [originalTaxRate, setOriginalTaxRate] = useState<number>(
    selectedTicketSession?.taxRate ?? 0.0
  );

  const [taxChanged, setTaxChanged] = useState<boolean>(false);

  const [originalSalesTaxType] = useState<SalesTaxOptionType>(
    selectedTicketSession?.salesTaxOptionType ?? SalesTaxOptionType.Parts
  );

  useEffect(() => {
    let value = false;

    if (settingsSalesTaxOption !== originalSalesTaxType) value = true;

    if (originalTaxRate !== settingsTaxRate) {
      value = originalTaxRate !== settingsTaxRate;
    }

    setTaxChanged(value);
  }, [settingsTaxRate, settingsSalesTaxOption]);

  useEffect(() => {
    loadSettingsWorkflow();
  }, [loadSettingsWorkflow]);
  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);
  useEffect(() => {
    loadVendors();
  }, [loadVendors]);

  useEffect(() => {
    calculateTotals();
  }, [selectedTicketSession, ticketItems.size, calculateTotals]);

  useEffect(() => {
    loadTicketSummaryMiscCharges(
      Array.from(ticketItems.values()),
      useUpdatedMiscellaneousCharges
    );
    calculateMiscellaneousCharges(allMiscellaneousChargesForTicket);
    calculateTotals();
  }, [useUpdatedMiscellaneousCharges, allMiscellaneousChargesForTicket]);

  const [summaryTicket, setSummaryTicket] = useState<TicketItem[]>([]);

  useEffect(() => {
    setSummaryTicket(
      Array.from(ticketItems.values()).sort((a, b) => a.order! - b.order!)
    );
  }, [ticketItems.size]);

  function addTicketSessionItem(
    values: TicketItemFormValues,
    type: TicketItemType
  ) {
    values.type = type;

    addTicketItemToSelectedTicketSession(
      values,
      allMiscellaneousChargesForTicket,
      selectedTicketSession?.salesTaxOptionType ??
        settingsSalesTaxOption ??
        SalesTaxOptionType.Parts,
      selectedVehicle,
      selectedCustomer
    );
    updateIsTaxable(
      selectedTicketSession?.salesTaxOptionType ??
        settingsSalesTaxOption ??
        SalesTaxOptionType.Parts
    );

    setSummaryTicket(
      Array.from(ticketItems.values()).sort((a, b) => a.order! - b.order!)
    );
  }

  const [description, setDescription] = useState<string | undefined>(
    selectedTicketSession?.description
  );

  function addMiscellaneousCharge(miscCharge: MiscellaneousCharge) {
    let rate: number = 0.0;
    if (selectedTicketSession)
      rate = getMiscChargeAmount(
        selectedTicketSession.totalLaborAmount,
        selectedTicketSession.totalPartsAmount,
        miscCharge
      );
    let miscItem = new TicketItem();
    miscItem.id = uuid();
    miscItem.type = TicketItemType.MiscellaneousCharge;
    miscItem.description = miscCharge.description;
    miscItem.isTaxable = miscCharge.isTaxable;
    miscItem.miscellaneousChargeId = miscCharge.id;
    miscItem.miscellaneousChargeVersion = miscCharge.version;
    miscItem.order = ticketItems.size;
    miscItem.quantity = 1;
    miscItem.rate = round2Decimals(rate);
    miscItem.unitPrice = round2Decimals(rate);
    miscItem.subTotal = round2Decimals(rate);
    miscItem.taxRate = selectedTicketSession?.taxRate ?? 0.0;
    miscItem.tax = miscCharge.isTaxable
      ? round2Decimals(
          Number(selectedTicketSession?.taxRate ?? 0.0) *
            Number(0.01) *
            Number(miscItem.subTotal)
        )
      : 0.0;
    miscItem.total = Number(miscItem.subTotal) + Number(miscItem.tax);

    addTicketItemToSelectedTicketSession(
      miscItem,
      allMiscellaneousChargesForTicket,
      selectedTicketSession?.salesTaxOptionType ??
        settingsSalesTaxOption ??
        SalesTaxOptionType.Parts,
      selectedVehicle,
      selectedCustomer
    );

    if (miscCharge.id) removeTicketSummaryMiscCharges(miscCharge.id);
  }

  return (
    <Grid stackable>
      <Grid.Column width={11}>
        <PotentialCustomerMatchingMessage
          workflowStep={TicketWorkflowSteps.Summary}
        />
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign="bottom">
              <PageHeader
                type="h1"
                header={t("ticket_summary", { ns: "tickets" }).toString()}
                className="modal-text-color"
                textAlign="left"
                divider={false}
                addTitle={true}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="bottom">
              <Button
                animated
                color="green"
                floated="right"
                onClick={() => {
                  if (selectedTicketSession?.id) {
                    setSelectedTicketDescription(description);
                    updateTicketSession(
                      TicketWorkflowSteps.Summary,
                      selectedTicketSession.id,
                      selectedVehicle,
                      selectedCustomer
                    );
                    if (selectedWorkflow.isCustomerInfoFirst) {
                      navigate(`/ticket/finalize/${id}/${complete}`);
                    } else {
                      navigate(`/ticket/customer/${id}/${complete}`);
                    }
                  }
                }}
              >
                <Button.Content visible>
                  {selectedWorkflow.isCustomerInfoFirst
                    ? t("finalize", { ns: "tickets" })
                    : t("continue", { ns: "tickets" })}
                </Button.Content>
                <Button.Content hidden>
                  <Icon name="caret right" />
                </Button.Content>
              </Button>
              <Button
                floated="right"
                onClick={() => {
                  if (selectedTicketSession?.id) {
                    setSelectedTicketDescription(description);
                    updateTicketSession(
                      TicketWorkflowSteps.Summary,
                      selectedTicketSession.id,
                      selectedVehicle,
                      selectedCustomer
                    );
                  }
                  newSelectedJobLabor();
                  navigate(`/ticket/jobCategory/${id}/${complete}`);
                }}
              >
                <Icon name="caret left" />
                {t("add_job", { ns: "tickets" })}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        {taxChanged && (
          <Message info>
            <Icon size="small" name="info circle"></Icon>
            {t("taxesUpdated", { ns: "tickets" })}...
          </Message>
        )}
        {updatedCharge && (
          <Message info>
            <Icon size="small" name="info circle"></Icon>
            {t("updatedMiscellaneousCharge", { ns: "tickets" })}...
          </Message>
        )}
        <Grid stackable>
          <Grid.Row>
            <Grid.Column>
              {t("estimate.description", { ns: "tickets" }) + ": "}
              <Input
                fluid
                value={description}
                id={"ticketDescription"}
                name={"ticketDescription"}
                onChange={(e, d) => {
                  setDescription(d.value);
                }}
                maxLength={500}
                focus
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="noPaddingLeft">
              <Table striped unstackable className="dnxTable">
                <Table.Header>
                  <Table.Row key="LineItems">
                    {isOpen && <Table.HeaderCell></Table.HeaderCell>}
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("estimate.part_no", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("estimate.description", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell className="textAlignRight">
                      {t("estimate.hr_qt", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell className="textAlignRight">
                      {t("estimate.ea_rate", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell className="textAlignRight">
                      {t("estimate.total", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell className="width0"></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {summaryTicket &&
                    summaryTicket.length > 0 &&
                    summaryTicket.map((item, index) => (
                      <Table.Row key={`item.${item.id}`}>
                        {isOpen && (
                          <Table.Cell
                            className="textAlignLeft stackIcons noPaddingTopMobile width35"
                            verticalAlign={"middle"}
                          >
                            <Button.Group
                              vertical
                              compact
                              basic
                              className="mobileInvisible sortIconButtons"
                            >
                              {index > 0 && (
                                <>
                                  <Button
                                    className="mobileInvisible sortIconButtons"
                                    icon="sort ascending"
                                    onClick={() => {
                                      updateSortOrder(
                                        item.id ?? "",
                                        index,
                                        true
                                      );
                                      updateTicketSession(
                                        TicketWorkflowSteps.Summary,
                                        selectedTicketSession?.id ?? "",
                                        selectedVehicle,
                                        selectedCustomer
                                      ).then(() => {
                                        setSummaryTicket(
                                          Array.from(ticketItems.values()).sort(
                                            (a, b) => a.order! - b.order!
                                          )
                                        );
                                      });
                                    }}
                                    fitted={true}
                                  />
                                </>
                              )}

                              {index < summaryTicket.length - 1 && (
                                <Button
                                  className="mobileInvisible sortIconButtons"
                                  icon="sort descending"
                                  size="mini"
                                  onClick={() => {
                                    updateSortOrder(
                                      item.id ?? "",
                                      index,
                                      false
                                    );
                                    updateTicketSession(
                                      TicketWorkflowSteps.Summary,
                                      selectedTicketSession?.id ?? "",
                                      selectedVehicle,
                                      selectedCustomer
                                    ).then(() => {
                                      setSummaryTicket(
                                        Array.from(ticketItems.values()).sort(
                                          (a, b) => a.order! - b.order!
                                        )
                                      );
                                    });
                                  }}
                                  fitted={true}
                                />
                              )}
                            </Button.Group>

                            {index > 0 && (
                              <>
                                <Icon
                                  className="desktopInvisible"
                                  name="caret square up"
                                  link
                                  size="big"
                                  onClick={() => {
                                    updateSortOrder(
                                      item.id ?? "",
                                      index,
                                      true
                                    );
                                    updateTicketSession(
                                      TicketWorkflowSteps.Summary,
                                      selectedTicketSession?.id ?? "",
                                      selectedVehicle,
                                      selectedCustomer
                                    ).then(() => {
                                      setSummaryTicket(
                                        Array.from(ticketItems.values()).sort(
                                          (a, b) => a.order! - b.order!
                                        )
                                      );
                                    });
                                  }}
                                />
                              </>
                            )}
                          </Table.Cell>
                        )}
                        <Table.Cell
                          className="textAlignLeftButRightMobile"
                          verticalAlign={"middle"}
                        >
                          <Button
                            color="grey"
                            icon="edit"
                            type="button"
                            onClick={() => {
                              switch (item.type) {
                                case TicketItemType.Labor:
                                  modalStore.openModal(
                                    <LaborItemForm
                                      saveItem={addTicketSessionItem}
                                      isAdd={false}
                                      ticketItem={item}
                                      autoCapitalizeFirstLetter={
                                        selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                                      }
                                    />
                                  );
                                  break;
                                case TicketItemType.Parts:
                                  modalStore.openModal(
                                    <PartItemForm
                                      addItem={addTicketSessionItem}
                                      isAdd={false}
                                      part={item}
                                      autoCapitalizeFirstLetter={
                                        selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                                      }
                                      vendorDropdownList={vendorDropdownList}
                                    />
                                  );
                                  break;
                                case TicketItemType.MiscellaneousCharge:
                                  modalStore.openModal(
                                    <MiscellaneousItemForm
                                      addItem={addTicketSessionItem}
                                      ticketItem={item}
                                      type={TicketItemType.MiscellaneousCharge}
                                      isAdd={false}
                                      autoCapitalizeFirstLetter={
                                        selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                                      }
                                    />
                                  );
                                  break;
                                case TicketItemType.AdhocMiscellaneous:
                                  modalStore.openModal(
                                    <MiscellaneousItemForm
                                      addItem={addTicketSessionItem}
                                      ticketItem={item}
                                      type={TicketItemType.AdhocMiscellaneous}
                                      isAdd={false}
                                      autoCapitalizeFirstLetter={
                                        selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                                      }
                                    />
                                  );
                                  break;
                              }
                            }}
                          ></Button>
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("estimate.part_no", {
                            ns: "tickets",
                          })}:`}
                          verticalAlign={"middle"}
                          style={{
                            color: getColor(item.type),
                          }}
                        >
                          {item.partCode}
                        </Table.Cell>
                        <Table.Cell
                          className="desc_overflow"
                          data-label={`${t("estimate.description", {
                            ns: "tickets",
                          })}:`}
                          verticalAlign={"middle"}
                          style={{
                            color: getColor(item.type),
                          }}
                        >
                          {item.description}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("estimate.hr_qt", {
                            ns: "tickets",
                          })}:`}
                          verticalAlign={"middle"}
                          className="textAlignRightNotMobile"
                          style={{
                            color: getColor(item.type),
                          }}
                        >
                          {item.quantity}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("estimate.ea_rate", {
                            ns: "tickets",
                          })}:`}
                          verticalAlign={"middle"}
                          className="textAlignRightNotMobile"
                          style={{
                            color: getComboColor(
                              item.rate ? item.rate : 0.0,
                              item.type
                            ),
                          }}
                        >
                          {formatCurrencyDecimal(
                            Number(item.rate ? item.rate : 0.0),
                            item.type === TicketItemType.Parts ? 3 : 2
                          )}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("estimate.total", {
                            ns: "tickets",
                          })}:`}
                          verticalAlign={"middle"}
                          className="textAlignRightNotMobile"
                          style={{
                            color: getComboColor(Number(item.subTotal ?? 0)),
                          }}
                        >
                          {formatCurrency(Number(item.subTotal ?? 0))}
                        </Table.Cell>

                        <Table.Cell
                          className="textAlignRightBoth"
                          verticalAlign={"middle"}
                        >
                          <Button
                            color={"grey"}
                            icon="trash"
                            type="button"
                            onClick={() => {
                              modalStore.openModal(
                                <ConfirmDelete
                                  header={t("removeTicketItem", {
                                    ns: "tickets",
                                  })}
                                  value={item.id ? item.id : ""}
                                  text={""}
                                  deleteValue={() => {
                                    removeLaborAndJobLabor(item.id ?? "");
                                    removeTicketItem(
                                      item.id ?? "",
                                      allMiscellaneousChargesForTicket,
                                      selectedVehicle,
                                      selectedCustomer
                                    );

                                    calculateTotals();
                                    if (
                                      item.type ===
                                        TicketItemType.MiscellaneousCharge &&
                                      item.miscellaneousChargeId
                                    ) {
                                      addTicketSummaryMiscCharges(
                                        item.miscellaneousChargeId
                                      );
                                    }
                                  }}
                                />
                              );
                            }}
                          ></Button>
                        </Table.Cell>
                        {isOpen && (
                          <Table.Cell
                            className="textAlignLeft width0"
                            verticalAlign={"middle"}
                          >
                            {index < summaryTicket.length - 1 && (
                              <Icon
                                className="desktopInvisible1"
                                name="caret square down"
                                link
                                size="big"
                                onClick={() => {
                                  updateSortOrder(
                                    item.id ?? "",
                                    index,
                                    false
                                  );
                                  updateTicketSession(
                                    TicketWorkflowSteps.Summary,
                                    selectedTicketSession?.id ?? "",
                                    selectedVehicle,
                                    selectedCustomer
                                  ).then(() => {
                                    setSummaryTicket(
                                      Array.from(ticketItems.values()).sort(
                                        (a, b) => a.order! - b.order!
                                      )
                                    );
                                  });
                                }}
                              />
                            )}
                          </Table.Cell>
                        )}
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Button.Group>
                <Button
                  basic
                  color="green"
                  onClick={() => {
                    modalStore.openModal(
                      <LaborItemForm
                        saveItem={addTicketSessionItem}
                        isAdd={true}
                        autoCapitalizeFirstLetter={
                          selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                        }
                      />
                    );
                  }}
                >
                  <Icon name="plus" />
                  {t("estimate.labor", { ns: "tickets" })}
                </Button>
                <Button
                  basic
                  color="blue"
                  onClick={() => {
                    let partItem = new TicketItemFormValues();
                    partItem.partMarkup = partItem.partMarkup ?? defaultMarkup;
                    modalStore.openModal(
                      <PartItemForm
                        addItem={addTicketSessionItem}
                        isAdd={true}
                        part={partItem}
                        autoCapitalizeFirstLetter={
                          selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                        }
                        vendorDropdownList={vendorDropdownList}
                      />
                    );
                  }}
                >
                  <Icon name="plus" />
                  {t("estimate.part", { ns: "tickets" })}
                </Button>
                <Button
                  basic
                  color="black"
                  onClick={() => {
                    modalStore.openModal(
                      <MiscellaneousItemForm
                        addItem={addTicketSessionItem}
                        type={TicketItemType.AdhocMiscellaneous}
                        isAdd={true}
                        autoCapitalizeFirstLetter={
                          selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                        }
                      />
                    );
                  }}
                >
                  <Icon name="plus" />
                  {t("estimate.misc", { ns: "tickets" })}
                </Button>
              </Button.Group>
            </Grid.Column>
            <Grid.Column>
              <Button
                onClick={() => {
                  modalStore.openModal(<PackageJobDetails />);
                }}
                floated="right"
              >
                <Icon name="save outline" />
                {t("estimate.save_pkg_job", { ns: "tickets" })}
              </Button>
              <Button
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                floated="right"
                color={isOpen ? "green" : "grey"}
              >
                <Icon name="sort" />
                {t("estimate.sort", { ns: "tickets" })}
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Header
            as="h3"
            content={t("estimate.misc_charges", { ns: "tickets" }).toString()}
            className="modal-text-color"
            textAlign="left"
          />
          {updatedCharge && (
            <Message info>
              <Checkbox
                label={t("includeUpdatedMiscellaneousCharges", {
                  ns: "tickets",
                })}
                id="updateMiscCharges"
                name="updateMiscCharges"
                defaultChecked={useUpdatedMiscellaneousCharges}
                onClick={(e, d) => {
                  setUpdateMiscellaneousCharges(d.checked ?? false);
                  loadTicketSummaryMiscCharges(
                    Array.from(ticketItems.values()),
                    d.checked
                  );
                  calculateMiscellaneousCharges(
                    allMiscellaneousChargesForTicket
                  );
                  calculateTotals();
                }}
              ></Checkbox>
            </Message>
          )}
          <Grid.Row>
            <Grid.Column className="noPaddingLeft">
              <Table striped unstackable className="dnxTable">
                <Table.Header>
                  <Table.Row key="miscCharges">
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("estimate.description", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("estimate.applies", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("estimate.amount", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {ticketSummaryMiscCharges &&
                    ticketSummaryMiscCharges.length > 0 &&
                    ticketSummaryMiscCharges.map((misc, index) => (
                      <Table.Row key={misc.id}>
                        <Table.Cell className="textAlignLeft">
                          <Button
                            icon="plus"
                            color="green"
                            onClick={() => {
                              addMiscellaneousCharge(misc);
                            }}
                          />
                        </Table.Cell>
                        <Table.Cell
                          className="email_wrap"
                          data-label={`${t("estimate.description", {
                            ns: "tickets",
                          })}:`}
                        >
                          {misc.description}
                        </Table.Cell>
                        <Table.Cell
                          className="email_wrap"
                          data-label={`${t("estimate.applies", {
                            ns: "tickets",
                          })}:`}
                        >
                          {misc.appliesTo}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("estimate.amount", {
                            ns: "tickets",
                          })}:`}
                          style={{
                            color: getNegative(
                              getMiscChargeAmount(
                                selectedTicketSession
                                  ? selectedTicketSession.totalLaborAmount
                                  : 0,
                                selectedTicketSession
                                  ? selectedTicketSession.totalPartsAmount
                                  : 0,
                                misc
                              )
                            ),
                          }}
                        >
                          {selectedTicketSession &&
                            formatCurrency(
                              getMiscChargeAmount(
                                selectedTicketSession.totalLaborAmount,
                                selectedTicketSession.totalPartsAmount,
                                misc
                              )
                            )}
                        </Table.Cell>
                        <Table.Cell className="textAlignRight">
                          <Button
                            color="grey"
                            icon="trash"
                            type="button"
                            onClick={() => {
                              modalStore.openModal(
                                <ConfirmDelete
                                  header={t("vendors.removeVendor", {
                                    ns: "miscSettings",
                                  })}
                                  value={misc.id ? misc.id : ""}
                                  text={
                                    "It will only be hidden on this ticket."
                                  }
                                  deleteValue={(d) => {
                                    if (misc.id)
                                      removeTicketSummaryMiscCharges(misc.id);
                                  }}
                                />
                              );
                            }}
                          ></Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={5}>
        <Grid>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Sidebar
              workflowStep={TicketWorkflowSteps.Summary}
              autoCapitalizeFirstLetter={
                selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
              }
              showTaxUpdate={taxChanged}
              settingsTaxRate={settingsTaxRate}
              settingsSalesTaxOptions={settingsSalesTaxOption}
              originalTaxRate={originalTaxRate}
              originalSalesTaxOptions={originalSalesTaxType}
            />
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
});
