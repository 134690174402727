import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../../app/stores/store";
import SignUp from "./SignUp";
import GettingStarted from "./GettingStarted";
import { useNavigate } from "react-router-dom";

export default observer(function Registration() {
  const { userStore } = useStore();
  const { registrationStep, isLoggedIn } = userStore;
  const navigate = useNavigate();

  //Don't allow logged in people to try and create another account
  if (isLoggedIn) navigate("/");

  return (
    <>
      {registrationStep === 0 && <SignUp />}
      {registrationStep === 1 && <GettingStarted />}
    </>
  );
});
