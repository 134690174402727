import React from "react";
import { observer } from "mobx-react-lite";
import { Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { getCellColor } from "../../../app/common/util/functions";

export default observer(function ActiveCompanyStatusesComponent() {
  const { t } = useTranslation(["common", "companySettings"]);

  const { statsStore } = useStore();

  const { statsCompare } = statsStore;

  return (
    <>
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Service Type</Table.HeaderCell>
            <Table.HeaderCell>Deactivated</Table.HeaderCell>
            <Table.HeaderCell>Trial Accounts</Table.HeaderCell>
            <Table.HeaderCell>Trial Extended</Table.HeaderCell>
            <Table.HeaderCell>Sub Active</Table.HeaderCell>
            <Table.HeaderCell>Sub Extended</Table.HeaderCell>
            <Table.HeaderCell>After Sub Trial</Table.HeaderCell>
            <Table.HeaderCell>Active Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <b>No Service</b>
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.noServiceDeactivated ?? 0)}
            >
              {statsCompare?.noServiceDeactivated}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.noServiceTrial ?? 0)}
            >
              {statsCompare?.noServiceTrial}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(
                statsCompare?.noServiceTrialExtended ?? 0
              )}
            >
              {statsCompare?.noServiceTrialExtended}
            </Table.Cell>
            <Table.Cell>0</Table.Cell>
            <Table.Cell>0</Table.Cell>
            <Table.Cell>0</Table.Cell>
            <Table.Cell>
              {statsCompare ? statsCompare.noServiceTrial : 0}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Basic</b>
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.basicDeactivated ?? 0)}
            >
              {statsCompare?.basicDeactivated}
            </Table.Cell>
            <Table.Cell className={getCellColor(statsCompare?.basicTrial ?? 0)}>
              {statsCompare?.basicTrial}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.basicTrialExtended ?? 0)}
            >
              {statsCompare?.basicTrialExtended}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.basicActive ?? 0)}
            >
              {statsCompare?.basicActive}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.basicExtended ?? 0)}
            >
              {statsCompare?.basicExtended}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.basicAfterTrial ?? 0)}
            >
              {statsCompare?.basicAfterTrial}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.clientBasicTotal ?? 0)}
            >
              {statsCompare?.clientBasicTotal}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Pro</b>
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.proDeactivated ?? 0)}
            >
              {statsCompare?.proDeactivated}
            </Table.Cell>
            <Table.Cell className={getCellColor(statsCompare?.proTrial ?? 0)}>
              {statsCompare?.proTrial}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.proTrialExtended ?? 0)}
            >
              {statsCompare?.proTrialExtended}
            </Table.Cell>
            <Table.Cell className={getCellColor(statsCompare?.proActive ?? 0)}>
              {statsCompare?.proActive}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.proExtended ?? 0)}
            >
              {statsCompare?.proExtended}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.proAfterTrial ?? 0)}
            >
              {statsCompare?.proAfterTrial}
            </Table.Cell>
            <Table.Cell
              className={getCellColor(statsCompare?.clientProTotal ?? 0)}
            >
              {statsCompare?.clientProTotal}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell>
              {(statsCompare ? statsCompare.basicDeactivated : 0) +
                (statsCompare ? statsCompare.proDeactivated : 0) +
                (statsCompare ? statsCompare.noServiceDeactivated : 0)}
            </Table.Cell>
            <Table.Cell>
              {(statsCompare ? statsCompare.noServiceTrial : 0) +
                (statsCompare ? statsCompare.basicTrial : 0) +
                (statsCompare ? statsCompare.proTrial : 0)}
            </Table.Cell>
            <Table.Cell>
              {(statsCompare ? statsCompare.noServiceTrialExtended : 0) +
                (statsCompare ? statsCompare.basicTrialExtended : 0) +
                (statsCompare ? statsCompare.proTrialExtended : 0)}
            </Table.Cell>
            <Table.Cell>
              {(statsCompare ? statsCompare.basicActive : 0) +
                (statsCompare ? statsCompare.proActive : 0)}
            </Table.Cell>
            <Table.Cell>
              {(statsCompare ? statsCompare.basicExtended : 0) +
                (statsCompare ? statsCompare.proExtended : 0)}
            </Table.Cell>
            <Table.Cell>
              {(statsCompare ? statsCompare.basicAfterTrial : 0) +
                (statsCompare ? statsCompare.proAfterTrial : 0)}
            </Table.Cell>
            <Table.Cell>
              {(statsCompare && (statsCompare.clientProTotal ?? 0) + (statsCompare.clientBasicTotal ?? 0) + (statsCompare.noServiceTrial ?? 0))}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
});
