import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { CustomerHistory, ReportInput } from "../../models/reports";
import { PagingParams, Pagination } from "../../models/pagination";
import { CustomerFormValues } from "../../models/customer";
import { sortingStrings } from "../../common/util/functions";
import { DateRangeOption } from "../../models/enums";
import { Vehicle } from "../../models/vehicle";

export default class CustomerHistoryReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.customerHistoryRegistry.clear();
        this.loadCustomerHistory();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  reportCriteria: ReportInput = new ReportInput({
    dateRange: DateRangeOption.Today,
    startDate: new Date(),
    endDate: new Date(),
    filter: "0",
  });

  selectedCustomer: CustomerFormValues = new CustomerFormValues();
  setCustomer = (value: CustomerFormValues) => {
    this.selectedCustomer = value;
  };

  customerHistoryRegistry = new Map<string, CustomerHistory>();

  loadCustomerHistory = async () => {
    this.loadingInitial = true;
    try {
      this.customerHistoryRegistry.clear();
      const result = await agent.Reports.getCustomerHistory(
        this.axiosParams,
        this.selectedCustomer.id ?? ""
      );
      runInAction(() => {
        result.forEach((customerHistory) => {
          this.setCustomerHistory(customerHistory);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setCustomerHistory = (customerHistory: CustomerHistory) => {
    if (customerHistory.invoiceDate)
      customerHistory.invoiceDate = new Date(customerHistory.invoiceDate);

    this.customerHistoryRegistry.set(
      customerHistory.ticketId ?? "",
      customerHistory
    );
  };

  get getCustomerHistory() {
    return Array.from(this.customerHistoryRegistry.values()).sort((a, b) => {
      let sort = (a.year ?? 0) - (b.year ?? 0);
      if (sort === 0) {
        sort = sortingStrings(a.make ?? "", b.make ?? "") ?? 0;
        if (sort === 0) {
          sort = sortingStrings(a.model ?? "", b.model ?? "") ?? 0;
          if (sort === 0) {
            sort = sortingStrings(a.engine ?? "", b.engine ?? "") ?? 0;
          }
        }
      }
      return sort;
    });
  }

  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (startDate?: Date, endDate?: Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
  };

  resetCustomerHistory = () => {
    this.customerHistoryRegistry.clear();
  };

  setReportCriteria = (values: ReportInput) => {
    this.reportCriteria = new ReportInput(values);
  };

  get groupedByVehicle() {
    return Object.entries(
      this.getCustomerHistory.reduce(
        (groups, vehicle) => {
          const year: string =(vehicle.year??0) >0  ? `${vehicle.year} ` : "" 
          const name =  `${year}${vehicle.make ? vehicle.make : ""} ${vehicle.model} ${vehicle.engine ? vehicle.engine: ""}`;
          groups[name] = groups[name] ? [...groups[name], vehicle] : [vehicle];
          return groups;
        },
        {} as { [key: string]: CustomerHistory[] }
      )
    );
  }

  calculateTotalSales = (data: CustomerHistory[]) => {
    const summary = data.reduce(
      (accumulator: CustomerHistory, item: CustomerHistory) => {
        accumulator.totalLabor += item.totalLabor;
        accumulator.totalParts += item.totalParts;
        accumulator.totalMisc += item.totalMisc;
        accumulator.totalTax += item.totalTax;
        accumulator.totalAmount += item.totalAmount;
        return accumulator;
      },
      {
        totalLabor: 0,
        totalParts: 0,
        totalMisc: 0,
        totalTax: 0,
        totalAmount: 0,
      }
    );
    return summary;
  };


}
