import { ErrorMessage, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message, Form, Table } from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { store, useStore } from "../../app/stores/store";
import { packageJobOptions } from "../../app/common/options/option";
import { PackageJobsFormValues } from "../../app/models/packageJobs";
import ValidationErrors from "../errors/ValidationErrors";
import { formatCurrency } from "../../app/common/util/functions";
import PageHeader from "../../app/layout/PageHeader";
import MyRadioGroup from "../../app/common/form/RadioButtonGroups/MyRadioGroup";

interface Props {
  id: string;
  name: string;
  header: string;
  isAdd: boolean;
}

export default observer(function UpdatePackageJobs(props: Props) {
  const { t } = useTranslation(["common", "miscSettings", "errors"]);

  const { packageJobStore, ticketStore } = useStore();

  const { selectedPackageJobs, updatePackageJobs, loadPackageJob } =
    packageJobStore;
  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;
  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  const [packageJob, setPackageJob] = useState<PackageJobsFormValues>(
    new PackageJobsFormValues()
  );

  useEffect(() => {
    loadPackageJob(props.id).then((result) => {
      if (result) setPackageJob(result);
    });
  }, [loadPackageJob, props.id, setPackageJob]);

  const [completed, setCompleted] = useState<boolean>(false);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("descriptionRequired", { ns: "errors" })),
  });

  function handleFormSubmit(
    values: PackageJobsFormValues,
    setErrors: (errors: FormikErrors<PackageJobsFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    updatePackageJobs(values)
      .then(() => {
        setCompleted(true);
        setSubmitting(false);
        store.modalStore.closeModal();
      })
      .catch((error) => {
        setErrors({ error: error });
        setSubmitting(false);
      });
  }

  return (
    <Formik
      initialValues={packageJob}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, { setErrors, setSubmitting }) =>
        handleFormSubmit(values, setErrors, setSubmitting)
      }
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        dirty,
        handleBlur,
        handleChange,
        errors,
        values,
        touched,
      }) => (
        <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
          {completed && (
            <Message positive>
              {t("settingsupdated", {
                ns: "common",
              })}
            </Message>
          )}
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <PageHeader
            header={props.header}
            type={"h3"}
            divider={true}
            getAlerts={true}
            addTitle={false}
          />
          <MyTextInput
            placeholder={t("description", { ns: "common" })}
            name={"name"}
            label={`${t("description", { ns: "common" })}:`}
            autoCapitalizeFirstLetter={
              selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
            }
            maxLength={255}
          />
          <MyRadioGroup
            name={"isFixedRate"}
            label={`${t("packages.laborTimes", {
              ns: "miscSettings",
            })}:`}
            options={packageJobOptions}
            inline={true}
          />
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("name", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell singleLine>
                  {t("packages.savedamount", { ns: "miscSettings" })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {selectedPackageJobs?.packageJobItems &&
              selectedPackageJobs.packageJobItems.length > 0 ? (
                selectedPackageJobs.packageJobItems.map((item) => (
                  <Table.Row key={item.id}>
                    <Table.Cell>{item.itemDescription}</Table.Cell>
                    <Table.Cell>
                      {formatCurrency(
                        (item.unitPrice ? item.unitPrice : 0.0) * item.quantity
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row key={"noItems"}>
                  <Table.Cell width="5" colSpan={5}>
                    <Message
                      color="yellow"
                      className="textAlignCenter fontStyleItalic"
                    >
                      {t("grid_noItems", { ns: "common" })}
                    </Message>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>

          <Button
            color="green"
            className="save_button"
            icon="check"
            content={t("save", { ns: "common" })}
            disabled={isSubmitting || !dirty || !isValid}
            loading={isSubmitting}
            type="submit"
          />
          <Button
            className="save_button"
            onClick={() => {
              store.modalStore.closeModal();
            }}
            icon="cancel"
            type="reset"
            content={t("cancel", { ns: "common" })}
          />
        </Form>
      )}
    </Formik>
  );
});
