import React, { useState } from "react";
import { Button, Container, Form, Grid, TextArea } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import PageHeader from "../../app/layout/PageHeader";

interface Props {
  clientId: string;
  userId?: string;
  loadAdminNotes: (id?: string) => Promise<void>
}

export default function AddAdminNotes({clientId, userId, loadAdminNotes}: Readonly<Props>) {
  const { t } = useTranslation(["common", "pricingSettings", "errors"]);
  const { modalStore, adminNotesStore } = useStore();

  const { createAdminNotes } = adminNotesStore;
  const [adminNotes, setAdminNotes] = useState<string>("");

  return (
    <>
      <PageHeader header=" Add Admin Notes" type="h2" divider={true} addTitle={false}/>
      <Container className="page">
        <Form
          className="ui form error padding_top"
          autoComplete="off"
        >
          <Grid columns={1}>
            <TextArea
              placeholder={""}
              rows={5}
              onChange={(e) => {
                setAdminNotes(e.target.value);
              }}
            />

            <Grid.Row>
              <Grid.Column>
                <Button
                  className="save_button ui button"
                  icon="cancel"
                  type="button"
                  content={t("close", { ns: "common" })}
                  onClick={modalStore.closeModal}
                />
                <Button
                  className="save_button ui button"
                  icon="save"
                  type="button"
                  color="green"
                  content={t("save", { ns: "common" })}
                  onClick={() =>
                    createAdminNotes(
                      adminNotes,
                      clientId,
                      userId
                    ).then(() => { 

                      loadAdminNotes(userId ? userId : clientId);
                      modalStore.closeModal();
                    })
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    </>
  );
}
