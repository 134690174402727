import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Button, Message, Table } from "semantic-ui-react";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { accessLevelOptions } from "../../../app/common/options/option";
import MyHelpButton from "../../../app/common/form/MyHelpButton";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { ClientStatus } from "../../../app/models/enums";

export default observer(function AdminPage() {
  const navigate = useNavigate();
  const { t } = useTranslation([
    "common",
    "users",
    "errors",
    "miscSettings",
    "subscription",
  ]);
  const { userStore, subscriptionStore } = useStore();
  const {
    loadUsersList,
    getUserList,
    setSelectedUser,
    loadingInitial,
    toggleUser,
    returnMessage,
    resetReturnMessage,
    user,
    getActiveUserCount,
    checkStatus,
  } = userStore;

  const { getCurrentSubscription, clientSubscriptionStatus } =
    subscriptionStore;
  const [subscriptionUserCount, setSubscriptionUserCount] = useState<number>(0);
  useEffect(() => {
    getCurrentSubscription().then((result) => {
      setSubscriptionUserCount(
        (result?.subscriptionUsers ?? 0) <= (result?.renewalUsers ?? 0)
          ? result?.subscriptionUsers ?? 0
          : result?.renewalUsers ?? 0
      );
    });
  }, [getCurrentSubscription]);

  useEffect(() => {
    loadUsersList()
      .then((result) => {
        setActiveUserCount(getActiveUserCount());
      })
      .catch();
  }, [loadUsersList, getActiveUserCount]);

  useEffect(() => {
    if (returnMessage && returnMessage.length > 0) {
      setCompletedMessage(returnMessage);
      resetReturnMessage();
    }
  }, [resetReturnMessage, returnMessage]);

  const [completedMessage, setCompletedMessage] = useState<string>("");
  const [activeUserCount, setActiveUserCount] = useState<number>(0);

  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <>
      <PageHeader
        header={t("staff_management", { ns: "common" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <>
        {completedMessage && <Message positive>{completedMessage}</Message>}
        <Message info className="padding-bottom-5px">
          {`${t("staff.activeUserMessage", {
            ns: "users",
          })} ${activeUserCount} ${t("staff.activeUserMessage2", {
            ns: "users",
          })} ${
            clientSubscriptionStatus.isTrial
              ? 0
              : subscriptionUserCount
          }${t("staff.paidUser", { ns: "users" })}.`}
          <MyHelpButton
            position="bottom left"
            help="staffManagement"
            color="blue"
            name={"staff management"}
            seeMore={true}
          ></MyHelpButton>
          {(activeUserCount < subscriptionUserCount ||
            ((user?.status ?? 0) & ClientStatus.Trial) ==
              ClientStatus.Trial) && (
            <Button
              className="save_button padding-bottom-5px"
              floated="right"
              type="button"
              color="green"
              icon="plus"
              as={Link}
              to="/staff/addStaff"
              content={t("staff.add_new", { ns: "users" })}
              disabled={false}
              onClick={() => {}}
            />
          )}
          <br />
          <br />
        </Message>
        <Table striped unstackable className="dnxTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left"></Table.HeaderCell>
              <Table.HeaderCell>
                {t("created", { ns: "miscSettings" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("status", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("accessLevel", { ns: "users" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("username", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("email", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("last_name", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("first_name", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("staff.referenceNote", { ns: "users" })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {getUserList && getUserList.length > 0 ? (
              getUserList.map((item, index) => (
                <Table.Row key={`userRow.${item.id}`}>
                  <Table.Cell className="textAlignLeft">
                    <Button
                      color="grey"
                      icon="edit"
                      type="button"
                      as={Link}
                      to={`/staff/editStaff/${item.id}`}
                      onClick={() => {
                        setSelectedUser(item);
                      }}
                    ></Button>
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("created", { ns: "miscSettings" })}:`}
                  >
                    {format(item.createdDate!, "PP")}
                  </Table.Cell>
                  <Table.Cell data-label={`${t("status", { ns: "common" })}:`}>
                    <Button
                      basic
                      color={item.isActive ? "green" : "red"}
                      type="button"
                      onClick={() => {
                        if (item.id) {
                          toggleUser(item.id).then(() => {
                            setActiveUserCount(getActiveUserCount());
                          });
                        }
                      }}
                      disabled={
                        item.id == user?.id ||
                        (!item.isActive &&
                          !(activeUserCount < subscriptionUserCount ||
                            ((user?.status ?? 0) & ClientStatus.Trial) ==
                              ClientStatus.Trial) )
                      }
                    >
                      {t(item.isActive ? "enabled" : "disabled", {
                        ns: "miscSettings",
                      })}
                    </Button>
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("accessLevel", { ns: "users" })}:`}
                  >
                    {
                      //user.accessLevel && user.accessLevel.toString()
                      accessLevelOptions.find(
                        (values) => values.value === item.accessLevel
                      )?.text
                    }
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("username", { ns: "common" })}:`}
                  >
                    {item.username}
                  </Table.Cell>
                  <Table.Cell data-label={`${t("email", { ns: "common" })})}:`}>
                    {item.email}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("last_name", { ns: "common" })}:`}
                  >
                    {item.lastName}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("first_name", { ns: "common" })}:`}
                  >
                    {item.firstName}
                  </Table.Cell>
                  <Table.Cell data-label={`${t("Note", { ns: "users" })}:`}>
                    {item.referenceNotes}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row key={"noItems"}>
                <Table.Cell width="9" colSpan={9}>
                  <Message
                    color="yellow"
                    className="textAlignCenter fontStyleItalic"
                  >
                    {t("grid_noItems", { ns: "common" })}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {checkStatus(ClientStatus.Active | ClientStatus.Subscription) && (
          <Button
            color="green"
            className="save_button"
            icon="plus"
            content={"Purchase More Users"}
            onClick={() => navigate(`/subscription/Users`)}
          />
        )}
      </>
    </>
  );
});
