import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { SubscriberReport } from "../../../app/models/systemAdmin";
import { convertApplicationToString, convertTermToString, formatDecimal } from "../../../app/common/util/functions";

export default observer(function SubscriberReport() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { subscriberReportStore } = useStore();
  const { loadSubscriberReport, getSubscriberReport, getTotalSubscribers } = subscriberReportStore;

  const getPlanName = (x: SubscriberReport) => {
    const user = x.userCount === 1 ? 'user' : 'users';
    const app = convertApplicationToString(x.application);
    const term = convertTermToString(x.term);

    return `${x.userCount} ${user}, ${app}, ${term}`;
  };

  const getPercentOfTotal = (x: SubscriberReport) => {
    return formatDecimal((x.subscriberCount / getTotalSubscribers) * 100, 2);
  }

  useEffect(() => {
    loadSubscriberReport();
  }, [loadSubscriberReport]);
  return (
    <>
      <PageHeader
        type="h1"
        header={t("Subscriber Report", { ns: "systemAdmin" })}
        className="modal-text-color"
        textAlign="left"
        divider
        addTitle
      />
      <Header
        as="h3"
        content={`Total Subscribers: ${getTotalSubscribers}`}
        className="modal-text-color"
        textAlign="left"
      />
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Plan Name</Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
            <Table.HeaderCell>% of All</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getSubscriberReport.map(x => (
            <Table.Row>
              <Table.Cell>{getPlanName(x)}</Table.Cell>
              <Table.Cell>{x.subscriberCount}</Table.Cell>
              <Table.Cell>{getPercentOfTotal(x)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
});
