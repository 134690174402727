import { addMonths, subMonths } from "date-fns";
import { ClientSubscriptionStatus } from "../../models/clientSubscription";
import {
  RateTypes,
  TicketItemType,
  TicketType,
  PhoneNumberType,
  Application,
  TermEnum,
  SubscriptionType,
  PaymentStatus,
  AccessLevel,
  ClientStatus,
  EmailType,
} from "../../models/enums";
import { MiscellaneousChargeFormValues } from "../../models/miscellaneousCharges";
import { Phone } from "../../models/systemAdmin";
import { store } from "../../stores/store";
import i18n from "../i18n/i18n";
import { Options, accessLevelOptions } from "../options/option";
import { SettingsStatus } from "../../models/settings";

export function round2Decimals(values: number) {
  return Math.round((values + Number.EPSILON) * 100) / 100;
}

export function round3Decimals(values: number) {
  return Math.round((values + Number.EPSILON) * 1000) / 1000;
}

export function calculateProfitMargin(defaultMarkup: number) {
  let markupDenominator: number = Number(defaultMarkup) + 100;
  let profitMargin: number =
    markupDenominator !== 0
      ? (Number(defaultMarkup) / Number(markupDenominator)) * 100
      : 0;
  return round2Decimals(profitMargin);
}

export function formatCurrency(
  amount: number,
  locale?: string,
  localeCurrency?: string
) {
  return new Intl.NumberFormat(locale ?? "en-us", {
    style: "currency",
    currency: localeCurrency ?? "USD",
    currencyDisplay: "symbol",
    currencySign: "accounting",
  }).format(amount);
}

export function formatCurrencyDecimal(
  amount: number,
  decimal: number,
  locale?: string,
  localeCurrency?: string
) {
  let stringAmount = new Intl.NumberFormat(locale ?? "en-us", {
    style: "currency",
    currency: localeCurrency ?? "USD",
    currencyDisplay: "symbol",
    currencySign: "accounting",
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  }).format(amount);

  if (stringAmount.endsWith("0", stringAmount.length)) {
    stringAmount = formatCurrency(amount);
  }

  return stringAmount;
}

export function formatPhoneNumber(input: string): string {
  let returnValue = input;

  switch (input.length) {
    case 7: {
      const groups = RegExp(/^(\d{0})(\d{3})(\d{4})$/).exec(input);

      if (groups) {
        returnValue = `${groups[2]}-${groups[3]}`;
      }
      break;
    }
    case 10: {
      const groups = RegExp(/^(\d{3})(\d{3})(\d{4})$/).exec(input);

      if (groups) {
        returnValue = `(${groups[1]}) ${groups[2]}-${groups[3]}`;
      }
      break;
    }
    case 11: {
      const groups = RegExp(/^1(\d{3})(\d{3})(\d{4})$/).exec(input);
      if (groups) {
        if (groups) {
          returnValue = `1(${groups[1]}) ${groups[2]}-${groups[3]}`;
        }
      } else {
        const groups = RegExp(/^(\d{3})(\d{3})(\d{4})(\d)$/).exec(input);
        if (groups) {
          returnValue = `(${groups[1]}) ${groups[2]}-${groups[3]} x ${groups[4]} `;
        }
      }
      break;
    }
    default: {
      if (input.length > 11) {
        const groups = RegExp(/^1(\d{3})(\d{3})(\d{4})(\d)$/).exec(input);

        if (groups) {
          if (groups) {
            returnValue = `1(${groups[1]}) ${groups[2]}-${groups[3]}`;
          }
        } else {
          const groups = RegExp(/^(\d{3})(\d{3})(\d{4})(\d)$/).exec(input);
          if (groups) {
            returnValue = `(${groups[1]}) ${groups[2]}-${groups[3]} x ${groups[4]} `;
          }
        }
        break;
      }
    }
  }

  return returnValue;
}

export function formatDecimal(
  amount: number,
  decimal: number,
  locale?: string
) {
  return new Intl.NumberFormat(locale ?? "en-us", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  }).format(amount);
}

export function getPhoneNumber(
  type: PhoneNumberType,
  phone: Phone[] | undefined
) {
  if (phone === undefined) {
    return null;
  }
  return phone.find((p) => p.type === type)?.number;
}

export function convertRateTypeToString(rateType: RateTypes) {
  let rateTypeString: string = "";
  switch (rateType) {
    case RateTypes.Flat:
      rateTypeString = "Flat";
      break;
    case RateTypes.Hourly:
      rateTypeString = "Hourly";
      break;
    case RateTypes.Flat | RateTypes.Hourly:
      rateTypeString = "Flat & Hourly";
      break;
  }
  return rateTypeString;
}

export function convertTicketTypeToString(ticketType: TicketType) {
  let ticketTypeString: string = "";
  switch (ticketType) {
    case TicketType.Estimate:
      ticketTypeString = i18n.t("ticket_path.estimate", {
        ns: "workflowSettings",
      });
      break;

    case TicketType.Invoice:
      ticketTypeString = i18n.t("ticket_path.invoice", {
        ns: "workflowSettings",
      });
      break;
  }

  return ticketTypeString;
}

export function convertTicketItemTypeToString(ticketItemType?: TicketItemType) {
  let ticketTypeString: string = "";
  switch (ticketItemType) {
    case TicketItemType.Labor:
      ticketTypeString = i18n.t("estimate.labor", {
        ns: "tickets",
      });
      break;
    case TicketItemType.Parts:
      ticketTypeString = i18n.t("estimate.parts", {
        ns: "tickets",
      });
      break;
    case TicketItemType.MiscellaneousCharge:
    case TicketItemType.AdhocMiscellaneous:
      ticketTypeString = i18n.t("miscellaneouscharges", {
        ns: "common",
      });
      break;
  }

  return ticketTypeString;
}

export function convertApplicationToString(application: Application) {
  let appString: string = "";
  switch (application) {
    case Application.None:
      break;

    case Application.AutomotiveExpert:
      appString = i18n.t("service_type.automotiveExpert", { ns: "common" });
      break;
    case Application.RealTimeLaborGuidePro:
      appString = i18n.t("service_type.realTimePro", { ns: "common" });
      break;
    case Application.RealTimeLaborGuide:
      appString = i18n.t("service_type.realTimeGuide", { ns: "common" });
      break;
  }

  return appString;
}

export function getMiscChargeAmount(
  labor: number,
  parts: number,
  miscellaneousCharge: MiscellaneousChargeFormValues
) {
  let amount: number = 0;
  let laborAmount: number = 0;
  let partAmount: number = 0;

  if (miscellaneousCharge.useLaborPercentage) {
    if (labor < miscellaneousCharge.laborPercentSplit) {
      laborAmount =
        Number(labor) * Number(miscellaneousCharge.laborPercentUnder) * 0.01;
    } else {
      laborAmount =
        Number(labor) * Number(miscellaneousCharge.laborPercentOver) * 0.01;
    }
  }

  if (miscellaneousCharge.usePartPercentage) {
    partAmount =
      Number(parts) * Number(miscellaneousCharge.partPercentage) * 0.01;
  }

  amount = Number(laborAmount) + Number(partAmount);

  if (
    miscellaneousCharge.useMinimumAmount &&
    Number(amount) < Number(miscellaneousCharge.minimumAmount)
  ) {
    amount = miscellaneousCharge.minimumAmount;
  } else if (
    miscellaneousCharge.useMinimumAmount &&
    Number(miscellaneousCharge.minimumAmount) < Number(0)
  ) {
    amount = miscellaneousCharge.minimumAmount;
  }

  if (
    miscellaneousCharge.useMaximumAmount &&
    amount > miscellaneousCharge.maximumAmount
  ) {
    amount = miscellaneousCharge.maximumAmount;
  }

  return amount;
}

export function getColor(ticketItemType?: TicketItemType) {
  let returnValue: string = "";
  switch (ticketItemType) {
    case TicketItemType.Labor:
      returnValue = "green";
      break;
    case TicketItemType.Parts:
      returnValue = "blue";
      break;
    case TicketItemType.MiscellaneousCharge:
    case TicketItemType.AdhocMiscellaneous:
      break;
  }
  return returnValue;
}

export function getTicketTypeColor(ticketType?: TicketType) {
  let returnValue: string = "";
  switch (ticketType) {
    case TicketType.Invoice:
      returnValue = "green";
      break;
    case TicketType.Estimate:
      returnValue = "blue";
      break;
  }
  return returnValue;
}

export function getNegative(value: number) {
  let returnValue: string = "black";
  if (value < 0) {
    returnValue = "red";
  }

  return returnValue;
}

export function getComboColor(value: number, ticketItemType?: TicketItemType) {
  let returnValue: string = "";
  if (value < 0) {
    returnValue = getNegative(value);
  } else {
    returnValue = getColor(ticketItemType);
  }
  return returnValue;
}

export function getBooleanColor(value: boolean) {
  return value ? "positive" : "negative";
}

export function getCellColor(value: number) {
  return getBooleanColor(value >= 0);
}

export function getCardLast4(value: string) {
  //TODO fix this function
  return "1234";
}

export function getCardBin(value: string) {
  //TODO fix this function
  //additionally need to find out when the 8 goes into play
  return "123456";
}

export function sortingStrings(a: string, b: string) {
  let tempA = a.toLowerCase();
  let tempB = b.toLowerCase();
  if (tempA < tempB) return -1;
  if (tempA > tempB) return 1;
  if (tempA === tempB) return 0;
}

export function resetRegistries() {
  store.customerStore.resetCustomerStoreRegistries();
  store.customerTicketStore.resetCustomerTicketRegistry();
  store.customerVehicleStore.resetCustomerVehicleRegistry();
  store.customVehicleStore.resetCustomVehicleRegistry();
  store.makeListStore.resetSettingsMakeListRegistry();
  store.miscellaneousChargeStore.resetMiscellaneousChargesRegistries();
  store.packageJobStore.resetPackageJobs();
  store.partsStore.resetSearchPartRegistry();
  store.pricingStore.resetPayTypesRegistries();
  store.quickVinStore.resetLookupRegistry();
  store.searchStore.resetSearchRegistry();
  store.technicianStore.resetTechnicianRegistry();
  store.ticketSessionStore.resetTicketSessionRegistries();
  store.ticketStore.resetTicketRegistries();
  store.userStore.resetUserRegistry();
  store.vehicleStore.resetVehicleArrays();
  store.vendorStore.resetVendorRegistries();
  store.creditCardTransactionReportStore.resetCCTransactionsRegistry();
  store.customerHistoryReportStore.resetCustomerHistory();
  store.laborSalesReportStore.resetLaborReportRegistry();
  store.miscChargesReportStore.resetMiscellaneousChargeRegistry();
  store.partSalesReportStore.resetPartRegistry();
  store.payTypeReportStore.resetPayTypeRegistry();
  store.taxReportStore.resetTaxReportRegistry();
  store.technicianReportStore.resetTechnicianRegistry();
  store.totalSalesReportStore.resetTotalSalesRegistry();
  store.vendorReportStore.resetVendorReportRegistry();
}

export function creditCardValidationLuhnCheck(
  creditCardNumber: string
): boolean {
  let returnValue = false;
  if (creditCardNumber.length > 13) {
    let addedDigits: number = 0;
    let odd = true;
    for (let i = creditCardNumber.length - 1; i >= 0; i--) {
      if (odd) {
        addedDigits += Number(creditCardNumber[i]);
        odd = false;
      } else {
        let even: number = Number(creditCardNumber[i]) * 2;
        even = even > 9 ? even - 9 : even;

        addedDigits += Number(even);
        odd = true;
      }
    }
    returnValue = addedDigits % 10 == 0;
  }

  return returnValue;
}

export function expirationDateCheck(year: string, month: string): boolean {
  let currentDate = new Date();
  let currentYear = currentDate.getFullYear();
  let currentMonth = currentDate.getMonth();

  let returnValue = true;

  if (Number(year) == currentYear) {
    return Number(month) > currentMonth;
  }

  return returnValue;
}

export function convertDowellSystemApplicationToString(
  dowellSystemApplication: Application
): string {
  let returnValue = "";

  switch (dowellSystemApplication) {
    case Application.RealTimeLaborGuide:
      returnValue = i18n.t("service_type.realTimeGuide", { ns: "common" });
      break;
    case Application.RealTimeLaborGuidePro:
      returnValue = i18n.t("service_type.realTimePro", { ns: "common" });
      break;
    case Application.AutomotiveExpert:
      returnValue = "";
      break;
  }

  return returnValue;
}

export function convertTermToString(term: TermEnum): string {
  let returnValue = "";

  switch (term) {
    case TermEnum.Annual:
      returnValue = i18n.t("subscription.annual", { ns: "subscription" });
      break;
    case TermEnum.Quarterly:
      returnValue = i18n.t("subscription.quarterly", { ns: "subscription" });
      break;
    case TermEnum.Monthly:
      returnValue = i18n.t("subscription.monthly", { ns: "subscription" });
      break;
    case TermEnum.Trial:
      returnValue = i18n.t("subscription.trial", { ns: "subscription" });
      break;
    default:
      returnValue = i18n.t("subscription.none", { ns: "subscription" });
  }

  return returnValue;
}

export function convertSubscriptionTypeToString(
  subscriptionType: SubscriptionType
): string {
  let returnValue = "";

  switch (subscriptionType) {
    case SubscriptionType.None:
      returnValue = i18n.t("subscriptionType.none", { ns: "subscription" });
      break;
    case SubscriptionType.Create | SubscriptionType.Basic:
      returnValue = i18n.t("subscriptionType.genericPayment", {
        ns: "subscription",
      });
      break;
    case SubscriptionType.Basic:
      returnValue = i18n.t("subscriptionType.payment", { ns: "subscription" });
      break;
    case SubscriptionType.Create | SubscriptionType.Pro:
      returnValue = i18n.t("subscriptionType.genericPayment", {
        ns: "subscription",
      });
      break;
    case SubscriptionType.Pro:
      returnValue = i18n.t("subscriptionType.payment", { ns: "subscription" });
      break;
    case SubscriptionType.Expert:
      returnValue = i18n.t("subscriptionType.expert", { ns: "subscription" });
      break;
    case SubscriptionType.Payment | SubscriptionType.Expert:
    case SubscriptionType.Payment | SubscriptionType.Basic:
    case SubscriptionType.Payment | SubscriptionType.Pro:
    case SubscriptionType.Payment:
      returnValue = i18n.t("subscriptionType.payment", { ns: "subscription" });
      break;
    case SubscriptionType.AdditionalUsers:
      returnValue = i18n.t("subscriptionType.additionalUsers", {
        ns: "subscription",
      });
      break;
    case SubscriptionType.Upgrade | SubscriptionType.AdditionalUsers:
      returnValue = i18n.t("subscriptionType.additionalUsers", {
        ns: "subscription",
      });
      break;
    case SubscriptionType.ReduceUser:
      returnValue = i18n.t("subscriptionType.reduceUser", {
        ns: "subscription",
      });
      break;
    case SubscriptionType.Downgrade | SubscriptionType.ReduceUser:
      returnValue = i18n.t("subscriptionType.reduceUser", {
        ns: "subscription",
      });
      break;
    case SubscriptionType.Upgrade | SubscriptionType.Pro:
      returnValue = i18n.t("subscriptionType.upgradeToPro", {
        ns: "subscription",
      });
      break;
    case SubscriptionType.Upgrade | SubscriptionType.Expert:
      returnValue = i18n.t("subscriptionType.upgradeToExpert", {
        ns: "subscription",
      });
      break;
    case SubscriptionType.Downgrade | SubscriptionType.Basic:
      returnValue = i18n.t("subscriptionType.downgradeToBasic", {
        ns: "subscription",
      });
      break;
    case SubscriptionType.Downgrade | SubscriptionType.Pro:
      returnValue = i18n.t("subscriptionType.downgradeToPro", {
        ns: "subscription",
      });
      break;

    case SubscriptionType.ChangePeriod |
      SubscriptionType.Upgrade |
      SubscriptionType.Monthly |
      SubscriptionType.Quarterly:
      returnValue = `${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.quarterly", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Downgrade |
      SubscriptionType.Monthly |
      SubscriptionType.Quarterly:
      returnValue = `${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.monthly", {
        ns: "subscription",
      })}`;
      break;

    case SubscriptionType.ChangePeriod |
      SubscriptionType.Upgrade |
      SubscriptionType.Monthly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.annual", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Downgrade |
      SubscriptionType.Monthly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.monthly", {
        ns: "subscription",
      })}`;
      break;

    case SubscriptionType.ChangePeriod |
      SubscriptionType.Upgrade |
      SubscriptionType.Quarterly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.annual", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Downgrade |
      SubscriptionType.Quarterly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.quarterly", {
        ns: "subscription",
      })}`;
      break;

    case SubscriptionType.ChangePeriod |
      SubscriptionType.Upgrade |
      SubscriptionType.AdditionalUsers |
      SubscriptionType.Monthly |
      SubscriptionType.Quarterly:
      returnValue = `${i18n.t("subscriptionType.additionalUsers", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.quarterly", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Upgrade |
      SubscriptionType.ReduceUser |
      SubscriptionType.Monthly |
      SubscriptionType.Quarterly:
      returnValue = `${i18n.t("subscriptionType.reduceUser", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.quarterly", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Downgrade |
      SubscriptionType.AdditionalUsers |
      SubscriptionType.Monthly |
      SubscriptionType.Quarterly:
      returnValue = `${i18n.t("subscriptionType.additionalUsers", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.monthly", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Downgrade |
      SubscriptionType.ReduceUser |
      SubscriptionType.Monthly |
      SubscriptionType.Quarterly:
      returnValue = `${i18n.t("subscriptionType.reduceUser", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.monthly", {
        ns: "subscription",
      })}`;
      break;

    case SubscriptionType.ChangePeriod |
      SubscriptionType.Upgrade |
      SubscriptionType.AdditionalUsers |
      SubscriptionType.Monthly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.additionalUsers", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.annual", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Upgrade |
      SubscriptionType.ReduceUser |
      SubscriptionType.Monthly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.reduceUser", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.annual", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Downgrade |
      SubscriptionType.AdditionalUsers |
      SubscriptionType.Monthly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.additionalUsers", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.monthly", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Downgrade |
      SubscriptionType.ReduceUser |
      SubscriptionType.Monthly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.reduceUser", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.monthly", {
        ns: "subscription",
      })}`;
      break;

    case SubscriptionType.ChangePeriod |
      SubscriptionType.Upgrade |
      SubscriptionType.AdditionalUsers |
      SubscriptionType.Quarterly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.additionalUsers", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.annual", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Upgrade |
      SubscriptionType.ReduceUser |
      SubscriptionType.Quarterly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.reduceUser", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.annual", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Downgrade |
      SubscriptionType.AdditionalUsers |
      SubscriptionType.Quarterly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.additionalUsers", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.quarterly", {
        ns: "subscription",
      })}`;
      break;
    case SubscriptionType.ChangePeriod |
      SubscriptionType.Downgrade |
      SubscriptionType.ReduceUser |
      SubscriptionType.Quarterly |
      SubscriptionType.Yearly:
      returnValue = `${i18n.t("subscriptionType.reduceUser", {
        ns: "subscription",
      })} & ${i18n.t("subscriptionType.renewalPeriodChange", {
        ns: "subscription",
      })} ${i18n.t("subscription.quarterly", {
        ns: "subscription",
      })}`;
      break;
  }

  return returnValue;
}

export function convertPaymentStatusToString(
  paymentStatus: PaymentStatus
): string {
  let returnValue = "";

  switch (paymentStatus) {
    case PaymentStatus.Created:
      returnValue = i18n.t("paymentStatus.created", { ns: "subscription" });
      break;
    case PaymentStatus.Pending:
      returnValue = i18n.t("paymentStatus.pending", { ns: "subscription" });
      break;
    case PaymentStatus.Succeeded:
      returnValue = i18n.t("paymentStatus.succeeded", { ns: "subscription" });
      break;
    case PaymentStatus.Declined:
      returnValue = i18n.t("paymentStatus.declined", { ns: "subscription" });
      break;
    case PaymentStatus.Failed:
      returnValue = i18n.t("paymentStatus.failed", { ns: "subscription" });
      break;
  }

  return returnValue;
}

export function convertClientStatusToString(clientStatus: ClientStatus) {
  let returnValue = "";

  switch (clientStatus) {
    case ClientStatus.TerminatedDnr: {
      returnValue = "Terminated-Dnr";
      break;
    }
    case ClientStatus.Declined: {
      returnValue = "Declined";
      break;
    }
    case ClientStatus.Deactivated: {
      returnValue = "Deactivated";
      break;
    }
    case ClientStatus.PreTrial: {
      returnValue = "PreTrial";
      break;
    }
    case ClientStatus.Expired | ClientStatus.Trial: {
      returnValue = "Inactive With Expired Trial";
      break;
    }
    case ClientStatus.Expired | ClientStatus.Subscription: {
      returnValue = "Inactive With Expired Subscription";
      break;
    }
    case ClientStatus.Expired | ClientStatus.SubscriptionExtension: {
      returnValue = "Inactive With Expired Subscription Extension";
      break;
    }
    case ClientStatus.Active | ClientStatus.SignUpInProgress: {
      returnValue = "Sign Up InProgress";
      break;
    }
    case ClientStatus.Active | ClientStatus.Trial: {
      returnValue = "Active With Trial";
      break;
    }
    case ClientStatus.Active | ClientStatus.Subscription: {
      returnValue = "Active With Subscription";
      break;
    }
    case ClientStatus.Active |
      ClientStatus.Trial |
      ClientStatus.Expired |
      ClientStatus.Subscription: {
      returnValue = "Active With Trial And Expired Subscription";
      break;
    }
    case ClientStatus.Active | ClientStatus.SubscriptionExtension: {
      returnValue = "Active With Subscription Extension";
      break;
    }
    case ClientStatus.Active |
      ClientStatus.Trial |
      ClientStatus.Expired |
      ClientStatus.SubscriptionExtension: {
      returnValue = "Active With Trial And Expired Subscription Extension";
      break;
    }
    default: {
      break;
    }
  }

  return returnValue;
}

export function calculateTrialExpirationDate(
  isExtension: boolean,
  clientSubscriptionStatus: ClientSubscriptionStatus
): Date {
  let expirationDate = new Date();

  if (clientSubscriptionStatus.isActive && clientSubscriptionStatus.isTrial) {
    let startDate = new Date();
    if (clientSubscriptionStatus.subscriptionStart)
      startDate = new Date(clientSubscriptionStatus.subscriptionStart);

    if (isExtension) {
      expirationDate.setDate(startDate.getDate() + 7);
    } else {
      expirationDate.setDate(
        startDate.getDate() + clientSubscriptionStatus.term
      );
    }
  } else if (
    clientSubscriptionStatus.isActive &&
    !clientSubscriptionStatus.isTrial &&
    isExtension
  ) {
    expirationDate.setDate(expirationDate.getDate() + 7);
  } else {
    expirationDate.setDate(expirationDate.getDate() + (isExtension ? 7 : 21));
  }

  return expirationDate;
}

export function getAccessOptionList(accessLevel: AccessLevel): Options[] {
  return accessLevelOptions.filter((x) => {
    return Number(x.value) <= accessLevel;
  });
}

export function convertEmailTypeToString(emailType: EmailType) {
  let returnValue: string = "";
  switch (emailType) {
    case EmailType.CardExpiration: {
      returnValue = "Card Expiration";
      break;
    }
    case EmailType.CardExpired: {
      returnValue = "Card Expired";
      break;
    }
    case EmailType.ImportantPaymentInformation: {
      returnValue = "Important Payment Information";
      break;
    }
    case EmailType.ImportShopFinished: {
      returnValue = "Import Shop Finished";
      break;
    }
    case EmailType.NewAccountNoTrial: {
      returnValue = "New Account No Trial";
      break;
    }
    case EmailType.Payment: {
      returnValue = "Payment";
      break;
    }
    case EmailType.PaymentReminder: {
      returnValue = "Payment Reminder";
      break;
    }
    case EmailType.QuickVinApproved: {
      returnValue = "Quick Vin Approved";
      break;
    }
    case EmailType.ReminderFreeTrial: {
      returnValue = "Reminder Free Trial";
      break;
    }
    case EmailType.ReminderSubExtensionEnded: {
      returnValue = "Reminder Sub Extension Ended";
      break;
    }
    case EmailType.ReminderTrialEnding: {
      returnValue = "Reminder Trial Ending";
      break;
    }
    case EmailType.SubscriptionPayment: {
      returnValue = "Subscription Payment";
      break;
    }
    case EmailType.Trial7Days: {
      returnValue = "Trial 7 Days";
      break;
    }
    case EmailType.TrialExtensionAvailable: {
      returnValue = "Trial Extension Available";
      break;
    }
    case EmailType.TrialExtensionUsed: {
      returnValue = "Trial Extension Used";
      break;
    }
    case EmailType.AccountVerification: {
      returnValue = "Account Verification";
      break;
    }
    case EmailType.ResentAccountVerification: {
      returnValue = "Resent Account Verification";
      break;
    }
    case EmailType.ForgotPassword: {
      returnValue = "Forgot Password";
      break;
    }
    case EmailType.SignUpActivation: {
      returnValue = "Sign Up Activation";
      break;
    }
    case EmailType.SignUpDecline: {
      returnValue = "Sign Up Decline";
      break;
    }
    case EmailType.TrialApproval: {
      returnValue = "Trial Approval";
      break;
    }
    case EmailType.AeTrialFollowUp: {
      returnValue = "Ae Trial Follow Up";
      break;
    }
    case EmailType.AeTrialReject: {
      returnValue = "Ae Trial Reject";
      break;
    }
    case EmailType.ContactForm: {
      returnValue = "Contact Form";
      break;
    }
    case EmailType.TrialRejected: {
      returnValue = "Trial Rejected";
      break;
    }
    default:
      returnValue = emailType;
  }

  return returnValue;
}

export function getTimePeriodInMonths(date: Date, days: number) {
  // Get the start date of 4 weeks ago
  const numberOfMonths = days === 30 ? 1 : Math.round(days / 30);
  const startDate = subMonths(date, numberOfMonths);
  const months = [];
  let currentStartDate = startDate;
  // Get the start of each week for the last 4 weeks
  while (currentStartDate <= date) {
    months.push(currentStartDate);
    currentStartDate = addMonths(currentStartDate, 1);
  }

  return months;
}

export const convertKeyValueStringsToObject = (str: string, delim: string = "[\r\n]+") => {
  const obj: { [key: string]: string } = {};

  const regex = new RegExp(delim);
  for (let line of str.split(regex)) {
    const [key, value] = line.split("=");
    obj[key] = value;
  }

  return obj;
}

export const convertSelectableCommentStatusToString = (status: SettingsStatus) => {
  let returnValue = "";
  switch (status) {
    case SettingsStatus.Off: {
      returnValue = "Off";
      break;
    }
    case SettingsStatus.Always: {
      returnValue = "Always";
      break;
    }
    case SettingsStatus.AlwaysAsk: {
      returnValue = "Always Ask";
      break;
    }
    case SettingsStatus.Remove: {
      returnValue = "Remove";
      break;
    }
    default:
      returnValue = status;
  }

  return returnValue;
}