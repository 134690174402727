import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Header, Icon, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

export default function AccessDenied() {
  const { t } = useTranslation(["common", "errors"]);

  useEffect(() => {
    document.title = t("accessDenied", { ns: "errors" })
}, []);

  return (
    <Segment placeholder>
      <Header
        as={'h1'}
        className="modal-text-color"
        textAlign={"left"}

      > <Icon name="empire"></Icon>{t("accessDenied", { ns: "errors" })}</Header>
      <Divider/>
      <Segment.Inline>
        You are the Droid we are looking for! <br/>
        <Button as={Link} to="/" primary>
          {t("return", { ns: "common" })}
        </Button>
      </Segment.Inline>
    </Segment>
  );
}
