import { observer } from "mobx-react-lite";
import React, { useRef, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import ReportDateSelector from "../../app/common/form/ReportDateSelector";
import PageHeader from "../../app/layout/PageHeader";
import { ReportInput } from "../../app/models/reports";
import { useStore } from "../../app/stores/store";
import LoadingComponent from "../../app/layout/LoadingComponent";
import ReportsDropdownMenu from "./ReportsDropdownMenu";
import ReactToPrint from "react-to-print";
import TaxPrint from "./pdf/TaxPrint";

export default observer(function Tax() {
  const { t } = useTranslation(["common", "reports"]);
  const componentRef = useRef<HTMLDivElement | null>(null);

  const { taxReportStore } = useStore();
  const {
    setPredicate,
    getTaxReport,
    loadingInitial,
    reportCriteria,
    setReportCriteria,
  } = taxReportStore;

  const validationSchema = Yup.object({});
  const [submitCounter, setSubmitCounter] = useState<number>(0);

  function handleFormSubmit(values: ReportInput) {
    setReportCriteria(values);
    setSubmitCounter(submitCounter + 1);
    setPredicate(values.startDate, values.endDate, values.reportType);
  }

  if (loadingInitial)
    return <LoadingComponent content={t("tax.loading", { ns: "reports" })} />;

  return (
    <>
      <Grid stackable columns={2}>
        <Grid.Column>
          <PageHeader
            header={`${t("report", { ns: "reports" })}: ${t("estimate.tax", {
              ns: "tickets",
            })}`}
            type="h2"
            divider={false}
            getAlerts={true}
            addTitle={true}
          />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <ReportsDropdownMenu />
        </Grid.Column>
      </Grid>
      <Divider />
      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={reportCriteria}
          onSubmit={handleFormSubmit}
        >
          {({ handleSubmit, values }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <ReportDateSelector
                cancelButtonLink="/reports"
                values={values}
                hasReportType={true}
                isUpdate={submitCounter > 0}
                hasResults={getTaxReport.length > 0}
              />
            </Form>
          )}
        </Formik>
        {getTaxReport && getTaxReport.length > 0 && (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                <ReactToPrint
                    trigger={() => (
                      <Button floated="right" icon="print" type="button">
                        {t("estimate.print", { ns: "tickets" })}
                      </Button>
                    )}
                    content={() => componentRef.current}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div ref={componentRef} className="printMargins">
              <TaxPrint reportType={reportCriteria.reportType ?? 0} startDate={reportCriteria.startDate ?? new Date()} endDate={reportCriteria.endDate ?? new Date()} />
            </div>
          </>
        )}
      </Container>
    </>
  );
});
