import { makeAutoObservable } from "mobx";

export default class SystemAdminStore {
  loading = false;
  loadingInitial = false;
  activeTab: number = 0;
  activeSubTab: number = 0;

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  setActiveSubTab = (activeSubTab: string | number | undefined) => {
    this.activeSubTab = activeSubTab ? Number(activeSubTab) : 0;
  };
  constructor() {
    makeAutoObservable(this);
  }
}
