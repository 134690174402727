import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { PaginatedResult } from "../models/pagination";
import { store } from "../stores/store";
import i18n from "../common/i18n/i18n";
import { router } from "../router/Routes";
import { Account } from "./AccountApi";
import { Vendors } from "./VendorApi";
import { Profiles } from "./ProfilesApi";
import { UserRoles } from "./UserRoles";
import { DataSource } from "./DataSourceApi";
import { Subscription } from "./SubscriptionApi";
import { JobLabors } from "./JobLaborApi";
import { Job } from "./JobApi";
import { VehicleApi } from "./VehicleAPi";
import { technician } from "./TechnicianApi";
import { customVehicles } from "./CustomVehiclesApi";
import { Client } from "./ClientApi";
import { makeList } from "./MakeListApi";
import { Disclaimers } from "./DisclaimerApi";
import { PaymentProcessing } from "./PaymentProcessingApi";
import { customer } from "./CustomerApi";
import { QuickVin } from "./QuickVinApi";
import { MiscellaneousChanges } from "./MiscellaneousChargesApi";
import { packageJobs } from "./PackageJobs";
import { Pricing } from "./PricingApi";
import { Tickets } from "./TicketApi";
import { SearchApi } from "./SearchApi";
import { Reports } from "./ReportApi";
import { PartApi } from "./PartsApi";
import { SystemAdminClient } from "./SystemAdministration/SystemAdminClientApi";
import { SystemAdminUsers } from "./SystemAdministration/SystemAdminUserApi";
import { SystemAdminLogs } from "./SystemAdministration/SystemAdminLogsApi";
import { SystemAdminTools } from "./SystemAdministration/SystemAdminToolsApi";
import { SystemAdminReports } from "./SystemAdministration/SystemAdminReportsApi";
import { SystemAdminStats } from "./SystemAdministration/SystemAdminStatsApi";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use((config) => {
  if (config.url !== process.env.REACT_APP_IP_ADDRESS_LOOKUP) {
    const token = store.commonStore.token;
    if (token) config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    const pagination = response.headers["pagination"];
    if (pagination) {
      response.data = new PaginatedResult(
        response.data,
        JSON.parse(pagination)
      );
      return response as AxiosResponse<PaginatedResult<any>>;
    }
    return response;
  },
  (error: AxiosError) => {
    const myResponse = error.response as AxiosResponse;
    const modalStateErrors = [];
    if (!myResponse?.status) {
      modalStateErrors.push(i18n.t(error.message, { ns: "errors" }));
      return Promise.reject(modalStateErrors);
    }

    switch (myResponse.status) {
      case 400:
        if (
          myResponse.config.method === "get" &&
          myResponse.data.errors.hasOwnProperty("id")
        ) {
          router.navigate("/not-found");
        }
        if (myResponse.data.errors) {
          for (const key in myResponse.data.errors) {
            if (myResponse.data.errors[key]) {
              modalStateErrors.push(
                i18n.t(myResponse.data.errors[key], { ns: "errors" })
              );
            }
          }
          throw modalStateErrors.flat();
        } else {
          modalStateErrors.push(i18n.t(myResponse.data, { ns: "errors" }));
          throw modalStateErrors.flat();
        }
      case 401:
        router.navigate("/");
        if (store.userStore.user) store.userStore.logout();
        if (myResponse.data === "invalid_token") {
          toast.error(i18n.t("expired_session", { ns: "errors" }).toString());
        } else {
          modalStateErrors.push(i18n.t(myResponse.data, { ns: "errors" }));
          throw modalStateErrors.flat();
        }
        break;
      case 403:
        router.navigate("/");
        if (store.userStore.user) store.userStore.logout();
        break;
      case 404:
        router.navigate("/not-found");
        break;
      case 413:
        modalStateErrors.push(i18n.t("UploadTooLarge", { ns: "errors" }));
        throw modalStateErrors.flat();
      case 418:  //I am a teapot!
        //need to update user
        store.userStore.getUser();
        break;
      case 500:
        store.commonStore.setServerError(myResponse.data);
        router.navigate("/server-error");
        break;
      default:
        store.commonStore.setServerError(myResponse.status + myResponse.data);
        router.navigate("/server-error");
        break;
    }

    return Promise.reject(error);
  }
);

const agent = {
  Account,
  Client,
  customer,
  customVehicles,
  DataSource,
  Disclaimers,
  Job,
  JobLabors,
  makeList,
  MiscellaneousChanges,
  packageJobs,
  PartApi,
  PaymentProcessing,
  Pricing,
  Profiles,
  QuickVin,
  Reports,
  SearchApi,
  Subscription,
  SystemAdminClient,
  SystemAdminLogs,
  SystemAdminReports,
  SystemAdminStats,
  SystemAdminTools,
  SystemAdminUsers,
  technician,
  Tickets,
  UserRoles,
  VehicleApi,
  Vendors,
};

export default agent;
