import { requests } from "./Requests";
import { SettingsPaymentProcessor } from "../models/paymentProcessing";
import { TicketCardTransaction, TicketPaymentItem } from "../models/ticket";

export const PaymentProcessing = {
  getSettingsPaymentProcessor: () =>
    requests.get<SettingsPaymentProcessor>(
      `/SettingsPaymentProcessor/getSettingsPaymentProcessor/`
    ),
  addSettingsPaymentProcessor: (values: SettingsPaymentProcessor) =>
    requests.post<void>(
      "/SettingsPaymentProcessor/addSettingsPaymentProcessor",
      values
    ),
  updateSettingsPaymentProcessor: (values: SettingsPaymentProcessor) =>
    requests.put<void>(
      `/SettingsPaymentProcessor/updateSettingsPaymentProcessor/${values.id}`,
      values
    ),
  unPairSettingsPaymentProcessor: () =>
    requests.put<void>(
      `/SettingsPaymentProcessor/unPairSettingsPaymentProcessor`,
      {}
    ),
  processPayment: (ticketPaymentId: string, byPassCheckStatus: boolean) =>
    requests.post<void>(
      `/payments/processPayment/${ticketPaymentId}/${byPassCheckStatus}`,
      {}
    ),

  checkStatus: (ticketPaymentId: string) =>
    requests.post<void>(
      `/payments/checkStatus/${ticketPaymentId}`,
      {}
    ),
  getTicketTransaction: (ticketPaymentId: string) =>
    requests.get<TicketCardTransaction>(
      `/payments/getTicketTransaction/${ticketPaymentId}`
    ),
};
