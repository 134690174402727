import { ErrorMessage, Form, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";

import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import {
  SettingsVendorsFormValues,
  VendorsFormValues,
} from "../../app/models/vendor";
import { store, useStore } from "../../app/stores/store";
import ValidationErrors from "../errors/ValidationErrors";
import PageHeader from "../../app/layout/PageHeader";
import MyIconTextInput from "../../app/common/form/MyIconTextInput";
import { calculateProfitMargin } from "../../app/common/util/functions";

interface Props {
  name: string;
  header: string;
  isAdd: boolean;
  defaults: SettingsVendorsFormValues;
  autoCapitalizeFirstLetter: boolean;
}

export default observer(function AddVendor(props: Props) {
  const { t } = useTranslation(["common", "miscSettings"]);

  const { vendorStore } = useStore();

  const {
    loadingInitial: vendorLoadingInitial,
    createVendor,
    selectedVendor,
    updateVendor,
    loadVendors,
  } = vendorStore;

  const [completed, setCompleted] = useState<boolean>(false);

  let newVendor: VendorsFormValues = new VendorsFormValues();
  newVendor.defaultMarkup = props.defaults.defaultMarkup;

  const [vendor] = useState<VendorsFormValues>(
    props.isAdd ? newVendor : selectedVendor
  );

  const validationSchema = Yup.object({
    name: Yup.string().required(t("nameRequired", { ns: "errors" })),
  });

  const [profitMarginPercentage, setProfitMarginPercentage] =
    useState<number>(0);

  useEffect(() => {
    setProfitMarginPercentage(calculateProfitMargin(vendor.defaultMarkup));
  }, [vendor.defaultMarkup]);

  function calculations(value: number) {
    setProfitMarginPercentage(calculateProfitMargin(value));
  }

  function handleFormSubmit(
    values: VendorsFormValues,
    setErrors: (errors: FormikErrors<VendorsFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateVendor(values)
      : () => createVendor(values);

    action()
      .then(() => {
        setCompleted(true);
        setSubmitting(false);
        loadVendors();
        store.modalStore.closeModal();
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  return (
    <Formik
      initialValues={vendor}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, { setErrors, setSubmitting }) =>
        handleFormSubmit(values, setErrors, setSubmitting)
      }
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        dirty,
        handleBlur,
        handleChange,
        errors,
        values,
        touched,
      }) => (
        <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <PageHeader
            header={props.header}
            type={"h3"}
            divider={true}
            getAlerts={true}
            addTitle={false}
          />
          <MyTextInput
            placeholder={t("name", { ns: "common" })}
            name={"name"}
            label={`${t("vendors.vendorname", { ns: "miscSettings" })}:`}
            autoCapitalizeFirstLetter={props.autoCapitalizeFirstLetter}
            maxLength={255}
          ></MyTextInput>
          <MyIconTextInput
            name="defaultMarkup"
            placeholder={"0.00"}
            icon="percent"
            label={`${t("vendors.default", { ns: "miscSettings" })}:`}
            className="drop-down"
            onChange={calculations}
            maxLength={500}
          />
          <MyIconTextInput
            name="profitMarginPercentage"
            placeholder={profitMarginPercentage.toString()}
            icon="percent"
            label={`${t("vendors.profitmarginpercentage", {
              ns: "miscSettings",
            })}:`}
            className="drop-down"
            disabled
            value={profitMarginPercentage.toString()}
            maxLength={500}
          />
          <Button
            color="green"
            className="save_button"
            icon="check"
            content={t("save", { ns: "common" })}
            disabled={isSubmitting || !dirty || !isValid}
            loading={isSubmitting}
            type="submit"
          />
          <Button
            className="save_button"
            onClick={() => store.modalStore.closeModal()}
            icon="cancel"
            type="reset"
            content={t("cancel", { ns: "common" })}
          />
        </Form>
      )}
    </Formik>
  );
});
