import {
  Customer,
  CustomerChangeLog,
  CustomerNote,
  PotentialCustomer,
} from "../models/customer";
import axios, { AxiosResponse } from "axios";
import { requests } from "./Requests";
import { PaginatedResult } from "../models/pagination";
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const customer = {
  getCustomer: (id: string | undefined) =>
    requests.get<Customer>(`/Customer/getCustomer/${id}`),
  getPotentialCustomer: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<PotentialCustomer[]>>(
        `/Customer/potentialMatches`,
        { params }
      )
      .then(responseBody),
  listCustomers: (params: URLSearchParams) =>
  axios
    .get<PaginatedResult<Customer[]>>(
      `/Customer/listCustomers`,
      { params }
    )
    .then(responseBody),
  listCustomerChangeLogs: (id: string) =>
    requests.get<CustomerChangeLog[]>(`/Customer/listCustomerChangeLogs/${id}`),
  removeCustomer: (id: string | undefined) =>
    requests.put<void>(`/Customer/removeCustomer/${id}`, {}),
  updateCustomer: (values: Customer) =>
    requests.put<void>(`/Customer/updateCustomer/${values.id}`, values),

  addCustomerNote: (values: CustomerNote) =>
    requests.post<void>("/CustomerNotes/addCustomerNote", values),
  listCustomerNotes: (id: string | undefined) =>
    requests.get<CustomerNote[]>(`/CustomerNotes/getCustomerNotes/${id}`),
  updateCustomerNote: (values: CustomerNote) =>
    requests.put<void>(
      `/CustomerNotes/updateCustomerNote/${values.id}`,
      values
    ),
  removeCustomerNote: (id: string | undefined) =>
    requests.put<void>(`/CustomerNotes/removeCustomerNote/${id}`, {}),
};
