import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, Container, Form, Grid, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors, FormikState } from "formik";
import { Link } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { SettingsPaymentProcessorFormValues } from "../../../app/models/paymentProcessing";
import WholeNumberInput from "../../../app/common/form/WholeNumberInput";
import { RegexConstants } from "../../../app/common/util/regexConstants";
import ValidationErrors from "../../errors/ValidationErrors";
import ClientDetails from "../../../app/common/form/ClientDetails";

export default observer(function PaymentProcessing() {
  const { t } = useTranslation(["common", "advancedSettings", "errors"]);

  const { paymentProcessingStore } = useStore();
  const postalCodeRegExp = new RegExp(RegexConstants.postalCodeExp);
  const businessNameRegExp = new RegExp(RegexConstants.businessNameRegExp);

  const {
    loadSettingsPaymentProcessor,
    createSettingsPaymentProcessor,
    updateSettingsPaymentProcessor,
    selectedPaymentProcessor,
  } = paymentProcessingStore;

  const validationSchema = Yup.object({
    businessName: Yup.string()
      .required(t("quickvin.required_ClientName", { ns: "errors" }))
      .matches(
        businessNameRegExp,
        t("registration.required_ClientName", { ns: "errors" })
      )
      .max(500),
    addressLine1: Yup.string()
      .required(t("registration.required_address", { ns: "errors" }))
      .max(500),
    city: Yup.string()
      .required(t("registration.required_city", { ns: "errors" }))
      .max(255),
    state: Yup.string()
      .required(t("registration.required_state", { ns: "errors" }))
      .max(50),
    postalCode: Yup.string()
      .required(t("registration.required_postalcode", { ns: "errors" }))
      .matches(
        postalCodeRegExp,
        t("registration.invalid_postalcode", { ns: "errors" })
      )
      .max(50),
    contactName: Yup.string()
      .required(t("quickvin.required_ContactName", { ns: "errors" }))
      .max(255),
    contactEmail: Yup.string()
      .required(t("registration.required_Email", { ns: "errors" }))
      .email(t("registration.invalid_email", { ns: "errors" }))
      .max(255),
    primaryNumber: Yup.string().required(
      t("registration.invalid_PhoneNumber", { ns: "errors" })
    ),

    terminalSerialNumber: Yup.number().required(
      t("paymentProcessing.serialNumber", { ns: "errors" })
    ),
  });

  const [completed, setCompleted] = useState<boolean>(false);

  function handleFormSubmit(
    values: SettingsPaymentProcessorFormValues,
    setErrors: (
      errors: FormikErrors<SettingsPaymentProcessorFormValues>
    ) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: (
      nextState?:
        | Partial<FormikState<SettingsPaymentProcessorFormValues>>
        | undefined
    ) => void
  ) {
    const action = values.id
      ? () => updateSettingsPaymentProcessor(values)
      : () => createSettingsPaymentProcessor(values);

    action()
      .then(() => {
        setSubmitting(false);
        setCompleted(true);
        loadSettingsPaymentProcessor();
        resetForm({ values });
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  return (
    <Container className="page">
      <Formik
        validationSchema={validationSchema}
        initialValues={selectedPaymentProcessor}
        onSubmit={(values, { setErrors, setSubmitting, resetForm }) =>
          handleFormSubmit(values, setErrors, setSubmitting, resetForm)
        }
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setErrors,
          setFieldError,
        }) => (
          <Form
            className="ui form error"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            {completed && isValid && (
              <Message positive>
                {t("settingsupdated", {
                  ns: "common",
                })}
              </Message>
            )}
            <Grid stackable>
              <Grid.Row>
                <Grid.Column>
                  <label className="myLabel">
                    {t("payprocessing.fill_out", {
                      ns: "advancedSettings",
                    })}
                  </label>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  {!isValid && dirty && errors.error && (
                    <ValidationErrors errors={errors.error} />
                  )}
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </Grid.Column>
              </Grid.Row>
              <ClientDetails />
              <Grid.Row>
                <Grid.Column>
                  <WholeNumberInput
                    placeholder={t("payprocessing.serial", {
                      ns: "advancedSettings",
                    })}
                    label={`${t("payprocessing.serial", {
                      ns: "advancedSettings",
                    })}:`}
                    name="terminalSerialNumber"
                    className="required"
                    maxLength={255}
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Button
                    color="green"
                    className="save_button"
                    icon="check"
                    content={t("save", { ns: "common" })}
                    disabled={isSubmitting || !isValid || !dirty}
                    loading={isSubmitting}
                    type="submit"
                  />
                  <Button
                    className="save_button"
                    as={Link}
                    to="/settings"
                    icon="cancel"
                    type="reset"
                    content={t("cancel", { ns: "common" })}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
});
