import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import MyTextInput from "../../app/common/form/MyTextInput";
import StateSelect from "../../app/common/form/StateSelect";

export default observer(function CustomerSearch() {
  const { t } = useTranslation(["common", "customer"]);

  return (
    <Container>
      <Grid stackable columns={3}>
        <Grid.Row>
          <Grid.Column>
            <MyTextInput
              label={`${t("email", { ns: "common" })}:`}
              placeholder={t("email", { ns: "common" })}
              name="email"
              autoCapitalizeFirstLetter={false}
              maxLength={255}
            />
          </Grid.Column>
          <Grid.Column>
            <MyTextInput
              label={`${t("address.address", { ns: "common" })}:`}
              placeholder={t("address.address", { ns: "common" })}
              name="address"
              autoCapitalizeFirstLetter={false}
              maxLength={500}
            />
          </Grid.Column>
          <Grid.Column>
            <MyTextInput
              label={`${t("address.city", { ns: "common" })}:`}
              placeholder={t("address.city", { ns: "common" })}
              name="city"
              autoCapitalizeFirstLetter={false}
              maxLength={255}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <MyTextInput
              label={`${t("address.postalcode", { ns: "common" })}:`}
              placeholder={t("address.postalcode", { ns: "common" })}
              name="postalCode"
              autoCapitalizeFirstLetter={false}
              maxLength={50}
            />
          </Grid.Column>
          <Grid.Column>
            <StateSelect
              placeholder={t("address.state", { ns: "common" })}
              name={"state"}
              label={`${t("address.state", { ns: "common" })}:`}
            />
          </Grid.Column>
          <Grid.Column>
            <MyTextInput
              label={`${t("companyname", { ns: "common" })}:`}
              placeholder={t("companyname", { ns: "common" })}
              name="companyName"
              autoCapitalizeFirstLetter={false}
              maxLength={500}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
});
