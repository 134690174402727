import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Grid, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import {
  formatCurrency,
  formatDecimal,
  getNegative,
} from "../../../app/common/util/functions";
import PageHeader from "../../../app/layout/PageHeader";
import { format } from "date-fns";

interface Props {
  startDate: Date;
  endDate: Date;
}

export default observer(function LaborSalesPrint({
  startDate,
  endDate,
}: Props) {
  const { t } = useTranslation(["common", "reports"]);

  const { laborSalesReportStore } = useStore();
  const { getLaborReport, calculateTotalSales } = laborSalesReportStore;

  return (
    <>
      <Container className="page">
        <Grid stackable columns={2} className="headerPrint">
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={`${t("report", { ns: "reports" })}: ${t(
                  "labor_sales.header",
                  {
                    ns: "reports",
                  }
                )}`}
                type="h2"
                divider={false}
                getAlerts={true}
                addTitle={false}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`${format(startDate, "PP")} - ${format(
                  endDate,
                  "PP"
                )}`}
                as="h2"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {getLaborReport && getLaborReport.length > 0 && (
          <Table striped unstackable className="dnxTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("labor_description", { ns: "reports" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("tickets", { ns: "customer" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("pops.hours", { ns: "tickets" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("pops.rate", { ns: "tickets" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("revenue", { ns: "reports" })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getLaborReport.map((x) => (
                <Table.Row key={x.description}>
                  <Table.Cell
                    data-label={`${t("description", { ns: "common" })}:`}
                  >
                    {x.description}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("tickets", { ns: "customer" })}:`}
                    textAlign="right"
                  >
                    {x.ticketCount}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("pops.hours", { ns: "tickets" })}:`}
                    textAlign="right"
                  >
                    {formatDecimal(x.quantity ?? 0, 2)}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("pops.rate", { ns: "tickets" })}:`}
                    textAlign="right"
                    style={{
                      color: getNegative(x.rate ?? 0.0),
                    }}
                  >
                    {formatCurrency(x.rate ?? 0.0)}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("revenue", { ns: "reports" })}:`}
                    textAlign="right"
                    style={{
                      color: getNegative(x.revenue),
                    }}
                  >
                    {formatCurrency(x.revenue)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Body>
              <Table.Row key={"grandTotals"} className="greenRow terms_bold">
                <Table.Cell>{t("grandTotals", { ns: "reports" })}:</Table.Cell>
                <Table.Cell textAlign="right"></Table.Cell>
                <Table.Cell textAlign="right"></Table.Cell>
                <Table.Cell textAlign="right"></Table.Cell>
                <Table.Cell
                  data-label={`${t("revenue", { ns: "reports" })}:`}
                  textAlign="right"
                  style={{
                    color: getNegative(
                      calculateTotalSales(getLaborReport).revenue
                    ),
                  }}
                >
                  {formatCurrency(calculateTotalSales(getLaborReport).revenue)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </Container>
    </>
  );
});
