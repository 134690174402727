import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  CustomVehicleFormValues,
  CustomVehicle,
  CustomVehicleNoteFormValues,
} from "../models/customvehicles";
import { Pagination, PagingParams } from "../models/pagination";
import { DropdownItemProps } from "semantic-ui-react";

export default class CustomVehicleStore {
  loadingInitial = false;
  activeTab: number = 0;

  yearList: DropdownItemProps[] = [];

  selectedCustomVehicle: CustomVehicleFormValues = {
    id: undefined,
    lowYear: undefined,
    highYear: undefined,
    makeName: "",
    modelName: "",
    engineName: "",
    isActive: true,
    yearOption: 1,
  };

  useSimpleView: boolean = false;

  customVehicleRegistry = new Map<string | undefined, CustomVehicle>();
  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.customVehicleRegistry.clear();
        this.loadCustomVehicleList();
      }
    );
  }

  setUseSimpleView = (state: boolean) => {
    this.useSimpleView = state;
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  resetCustomVehicleRegistry = () => {
    this.customVehicleRegistry.clear();
  };

  loadCustomVehicleList = async () => {
    this.loadingInitial = true;
    try {
      this.customVehicleRegistry.clear();
      const result = await agent.customVehicles.listCustomVehicle(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((customVehicle) => {
          this.setCustomVehicle(customVehicle);
        });
      });
      this.setPagination(result.pagination);
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (
    predicate: string,
    value: string | Date | number | undefined
  ) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "make":
        resetPredicate();
        if (value) this.predicate.set("make", value);
        break;
      case "model":
        resetPredicate();
        if (value) this.predicate.set("model", value);
        break;
      case "engine":
        resetPredicate();
        if (value) this.predicate.set("engine", value);
        break;
    }
  };

  get getCustomVehicles() {
    return Array.from(this.customVehicleRegistry.values()).sort((a, b) => {
      if (b.highYear && a.highYear && b.lowYear && a.lowYear)
        return b.highYear - a.highYear || b.lowYear - a.lowYear;
      return 0;
    });
  }

  setSelectedCustomVehicle = (values: CustomVehicleFormValues) => {
    this.selectedCustomVehicle = values;
  };

  private setCustomVehicle = (customVehicle: CustomVehicle) => {
    customVehicle.yearOption = 0;

    if (customVehicle.highYear === customVehicle.lowYear)
      customVehicle.yearOption = 1;
    else if (customVehicle.highYear === 9999) customVehicle.yearOption = 3;
    else if (
      customVehicle.lowYear &&
      customVehicle.highYear &&
      customVehicle.lowYear < customVehicle.highYear
    )
      customVehicle.yearOption = 2;

    this.customVehicleRegistry.set(customVehicle.id, customVehicle);
  };


  private getCustomVehicle = (id: string) => {
    return this.customVehicleRegistry.get(id);
  };

  createCustomVehicle = async (values: CustomVehicleFormValues) => {
    try {
      let myNew: CustomVehicle = new CustomVehicle(values);
      await agent.customVehicles.addCustomVehicle(myNew);
      runInAction(() => {
        this.customVehicleRegistry.clear();
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  removeCustomVehicle = async (id: string) => {
    try {
      await agent.customVehicles.removeCustomVehicle(id);
      runInAction(() => {
        this.customVehicleRegistry.delete(id);
        let count = this.customVehicleRegistry.size;
        if (count < 1) {
          this.pagingParams = new PagingParams();
        }
        this.loadCustomVehicleList();
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateCustomVehicle = async (values: CustomVehicleFormValues) => {
    try {
      let myNew: CustomVehicle = new CustomVehicle(values);
      await agent.customVehicles.updateCustomVehicle(myNew);

      runInAction(() => {
        if (values.id) {
          let updatedCustomVehicle = {
            ...this.getCustomVehicle(values.id),
            ...values,
          };
          this.customVehicleRegistry.set(
            values.id,
            updatedCustomVehicle as CustomVehicle
          );
          this.selectedCustomVehicle = updatedCustomVehicle as CustomVehicle;
        }
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  upsertCustomVehicleNotes = async (customVehicleNotes: CustomVehicleNoteFormValues) => {
    this.loadingInitial = true;
    try {
      await agent.customVehicles.upsertVehicleNote(customVehicleNotes);
      runInAction(() => {});
    } catch(error) {
      console.log(error);
      throw error;
    } finally {
      this.loadingInitial = false;
    }
  }

  getCustomVehicleYearLabel = (customVehicle: CustomVehicle) => {
    let label: string = "";

    if (customVehicle.lowYear === 0 && customVehicle.highYear === 9999) {
      label = "All";
    } else if (customVehicle.lowYear !== customVehicle.highYear) {
      label = `(${customVehicle.lowYear} - ${customVehicle.highYear})`;
    } else {
      label = customVehicle.lowYear ? customVehicle.lowYear.toString() : "";
    }

    return label;
  };

  resetYears = () => {
    this.yearList = [];
  };

  loadYears = async (customVehicle: CustomVehicle) => {
    this.loadingInitial = true;
    try {
      const result = await agent.customVehicles.listCustomVehicleYears(
        customVehicle.id
      );
      runInAction(() => {
        this.yearList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };
}
