import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import { QuickVinNightlyLog } from "../../models/systemAdmin";
import { format } from "date-fns";

export default class QuickVinNightlyLogStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams(undefined, 50);
        this.QuickVinNightlyLogRegistry.clear();
        this.loadQuickVinNightlyLog();
      }
    );
  }

  QuickVinNightlyLogRegistry = new Map<string, QuickVinNightlyLog>();
  loading = false;
  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  loadQuickVinNightlyLog = async () => {
    try {
      this.QuickVinNightlyLogRegistry.clear();
      const result = await agent.SystemAdminLogs.getCarfaxDaemonLogs(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((x: QuickVinNightlyLog) => {
          this.setQuickVinNightlyLog(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setQuickVinNightlyLog = (quickVinNightlyLog: QuickVinNightlyLog) => {
    quickVinNightlyLog.createdDate = new Date(quickVinNightlyLog.createdDate);
    this.QuickVinNightlyLogRegistry.set(
      format(quickVinNightlyLog.createdDate, 'p'),
      quickVinNightlyLog
    );
  };

  get getQuickVinNightlyLog() {
    return Array.from(this.QuickVinNightlyLogRegistry.values()).sort(
      (a, b) => b.createdDate.getTime() - a.createdDate.getTime()
    );  
  }

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    
    if (value) this.predicate.set(predicate, value);
    else this.predicate.delete(predicate);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  setLoading = (loading: boolean)  => {
    this.loading = loading;
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString()); 
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
}
