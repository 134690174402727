export interface SettingsPaymentProcessor {
  id?: string;
  businessName: string;
  addressLine1: string;
  city: string;
  state: string;
  postalCode: string;
  primaryNumber: string;
  contactName: string;
  contactEmail: string;
  terminalSerialNumber: number | undefined;
  error?: string;
}
export class SettingsPaymentProcessor implements SettingsPaymentProcessor {
  constructor(init?: SettingsPaymentProcessorFormValues) {
    if (init) {
      this.id = init.id;
      this.businessName = init.businessName;
      this.addressLine1 = init.addressLine1;
      this.city = init.city;
      this.state = init.state;
      this.postalCode = init.postalCode;
      this.primaryNumber = init.primaryNumber;
      this.contactName = init.contactName;
      this.contactEmail = init.contactEmail;
      this.terminalSerialNumber = init.terminalSerialNumber;
      this.error = init.error;
    }
  }
}

export class SettingsPaymentProcessorFormValues {
  id?: string = undefined;
  businessName: string = "";
  addressLine1: string = "";
  city: string = "";
  state: string = "";
  postalCode: string = "";
  primaryNumber: string = "";
  contactName: string = "";
  contactEmail: string = "";
  terminalSerialNumber:  number | undefined = undefined;
  error?: string = undefined;

  constructor(settingsPaymentProcessor?: SettingsPaymentProcessorFormValues) {
    if (settingsPaymentProcessor) {
      this.id = settingsPaymentProcessor.id;
      this.businessName = settingsPaymentProcessor.businessName;
      this.addressLine1 = settingsPaymentProcessor.addressLine1;
      this.city = settingsPaymentProcessor.city;
      this.state = settingsPaymentProcessor.state;
      this.postalCode = settingsPaymentProcessor.postalCode;
      this.primaryNumber = settingsPaymentProcessor.primaryNumber;
      this.contactName = settingsPaymentProcessor.contactName;
      this.contactEmail = settingsPaymentProcessor.contactEmail;
      this.terminalSerialNumber = settingsPaymentProcessor.terminalSerialNumber;
      this.error = settingsPaymentProcessor.error;
    }
  }
}

export class PaymentProcessor{
  payTypeId?: string;
  payTypeName?: string;
}

