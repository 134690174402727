import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Grid,
  Input,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../app/layout/PageHeader";
import { useStore } from "../../app/stores/store";
import { CustomerFormValues } from "../../app/models/customer";
import AddCustomerNotes from "./AddCustomerNotes";
import { format } from "date-fns";
import ConfirmDelete from "../../app/common/form/ConfirmDelete";
import { Vehicle } from "../../app/models/vehicle";
import CustomerVehicleForm from "../customerVehicle/CustomerVehicleForm";
import { AccessLevel, Application, TicketItemType } from "../../app/models/enums";
import CustomerVehicleSelection from "../tickets/recentTickets/CustomerVehicleSelection";
import LoadingComponent from "../../app/layout/LoadingComponent";
import CustomerDetailTicketList from "./CustomerDetailTicketList";
import { PagingParams } from "../../app/models/pagination";
import CustomerDetailVehicleList from "./CustomerDetailVehicleList";
import { formatPhoneNumber } from "../../app/common/util/functions";
import ARForm from "../accountsReceivable/ARForm";
import { TicketItemFormValues } from "../../app/models/ticket";
import ARTicketSearch from "../accountsReceivable/ARTicketSearch";


export default observer(function CustomerDetails() {
  const { t } = useTranslation(["common", "customer"]);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    customerStore,
    modalStore,
    customerVehicleStore,
    customerTicketStore,
    userStore,
    customerHistoryReportStore,
    ticketStore,
  } = useStore();

  const { checkAccessLevel, checkApplication } = userStore;

  const { resetCustomerHistory } = customerHistoryReportStore;

  const {
    loadCustomer,
    selectedCustomer,
    setSelectedCustomer,
    removeCustomer,
    loadCustomerNotes,
    customerNotes,
    setSelectedCustomerNote,
    removeCustomerNotes,
    //resetCustomerNoteRegistry,
    loadingInitial: customerLoading,
  } = customerStore;

  const {
    loadCustomerVehicles,
    resetCustomerVehicleRegistry,
    pagination,
    setPredicate,
    loadingInitial: vehicleLoading,
  } = customerVehicleStore;

  const {
    resetCustomerTicketRegistry,
    loadCustomerTickets,
    loadingInitial: ticketsLoading,
    setPagingParams,
    resetPredicate,
  } = customerTicketStore;

  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;

  const [loadingInitial, setLoadingInitial] = useState<boolean>(false);
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  useEffect(() => {
    if (id) {
      setLoadingInitial(true);
      loadCustomer(id)
        .then((customer) => {
          //resetCustomerNoteRegistry();
          resetCustomerVehicleRegistry();
          resetCustomerTicketRegistry();
          setSelectedCustomer(new CustomerFormValues(customer));
          setPagingParams(new PagingParams());
          loadCustomerTickets(id);
          loadCustomerVehicles(id);
          loadCustomerNotes(id);
        })
        .finally(() => setLoadingInitial(false));
    }
  }, [loadCustomer, id, loadCustomerNotes, loadCustomerTickets, loadCustomerVehicles, resetCustomerTicketRegistry, resetCustomerVehicleRegistry, setSelectedCustomer]);

  //resetCustomerNoteRegistry
  if (loadingInitial && customerLoading && vehicleLoading && ticketsLoading)
    return (
      <LoadingComponent
        content={t("customerDetails_loading", { ns: "customer" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("customerdetails", { ns: "common" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        // showHelp
        // helpName="customerDetails.general"
        addTitle={true}
      />
      <Container className="page">
        <Segment>
          <Grid columns={2}>
            <Grid.Column>
              <Grid>
                {selectedCustomer?.isCommercial && (
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                      <span className="intro">
                        {`${t("businessName", { ns: "customer" })}: `}
                      </span>
                    </Grid.Column>
                    <Grid.Column>
                      {selectedCustomer?.companyName
                        ? `${selectedCustomer?.companyName}`
                        : `${t("notSet", { ns: "customer" })}`}
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row columns={2}>
                  <Grid.Column className="textAlignRight">
                    <span className="intro">
                      {`${t(
                        selectedCustomer?.isCommercial
                          ? "contactName"
                          : "customername",
                        { ns: "common" }
                      )}: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    {`${
                      selectedCustomer?.lastName
                        ? `${selectedCustomer.lastName}, `
                        : ""
                    }${
                      selectedCustomer?.firstName
                        ? selectedCustomer.firstName
                        : "--"
                    } `}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${
                        selectedCustomer?.isCommercial
                          ? t("main", { ns: "customer" })
                          : t("cell", { ns: "common" })
                      }: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>{`${
                    selectedCustomer?.primaryNumber
                      ? formatPhoneNumber(selectedCustomer?.primaryNumber ?? "")
                      : `${t("notSet", { ns: "customer" })}`
                  }`}</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${
                        selectedCustomer?.isCommercial
                          ? t("secondary", { ns: "customer" })
                          : t("home", { ns: "common" })
                      }: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>{`${
                    selectedCustomer?.secondaryNumber
                      ? formatPhoneNumber(
                          selectedCustomer?.secondaryNumber ?? ""
                        )
                      : `${t("notSet", { ns: "customer" })}`
                  }`}</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${
                        selectedCustomer?.isCommercial
                          ? t("fax", { ns: "common" })
                          : t("work", { ns: "customer" })
                      }: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>{`${
                    selectedCustomer?.ternaryNumber
                      ? formatPhoneNumber(selectedCustomer?.ternaryNumber ?? "")
                      : `${t("notSet", { ns: "customer" })}`
                  }`}</Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${t("email", { ns: "common" })}: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    {selectedCustomer?.email
                      ? selectedCustomer.email
                      : `${t("notSet", { ns: "customer" })}`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${t("createdon", { ns: "customer" })}: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    {selectedCustomer?.createdDate &&
                      format(selectedCustomer.createdDate, "Pp")}
                  </Grid.Column>
                </Grid.Row>
                {selectedCustomer?.updatedDate && (
                  <Grid.Row columns={2}>
                    <Grid.Column textAlign="right">
                      <span className="intro">
                        {`${t("last_updated", { ns: "customer" })}: `}
                      </span>
                    </Grid.Column>
                    <Grid.Column>
                      {selectedCustomer?.updatedDate &&
                        format(selectedCustomer.updatedDate, "Pp")}
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Grid.Column>
            <Grid.Column>
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${t("address.address", { ns: "common" })}: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    {selectedCustomer?.addressLine1
                      ? selectedCustomer.addressLine1
                      : `${t("notSet", { ns: "customer" })}`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${t("address.address2", { ns: "common" })}: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    {selectedCustomer?.addressLine2
                      ? selectedCustomer.addressLine2
                      : `${t("notSet", { ns: "customer" })}`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${t("address.city", { ns: "common" })}: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    {selectedCustomer?.city
                      ? selectedCustomer.city
                      : `${t("notSet", { ns: "customer" })}`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${t("address.state", { ns: "common" })}: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    {selectedCustomer?.state
                      ? selectedCustomer.state
                      : `${t("notSet", { ns: "customer" })}`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${t("address.postalcode", { ns: "common" })}: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    {selectedCustomer?.postalCode
                      ? selectedCustomer.postalCode
                      : `${t("notSet", { ns: "customer" })}`}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right">
                    <span className="intro">
                      {`${t("tax_exempt", { ns: "customer" })}: `}
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    {selectedCustomer?.isTaxExempt
                      ? t("yes", { ns: "common" })
                      : t("no", { ns: "common" })}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
        </Segment>
        <Grid>
          <Grid.Column>
            <Button
              color="green"
              className="save_button mobile_buttons"
              icon="plus"
              content={t("ticket_create", { ns: "common" })}
              onClick={() => {
                modalStore.openModal(
                  <CustomerVehicleSelection
                    customerId={selectedCustomer?.id ?? ""}
                    businessName={selectedCustomer?.companyName}
                    lastName={selectedCustomer?.lastName}
                    firstName={selectedCustomer?.firstName}
                  />
                );
              }}
            />
            <Button
              color="grey"
              className="save_button mobile_buttons"
              icon="check"
              type="button"
              content={t("editcustomerdetails", { ns: "customer" })}
              onClick={() => {
                navigate(`/customer/edit/${id}`, { state: { location } });
              }}
            />
            <Button
              color="orange"
              className="save_button mobile_buttons"
              icon="history"
              content={t("buttons.history_report", { ns: "customer" })}
              onClick={() => {
                resetCustomerHistory();
                navigate(`/reports/customerHistory/${id}`);
              }}
            />
            {checkAccessLevel(AccessLevel.Manager) && (
              <>
                <Button
                  color="red"
                  className="save_button mobile_buttons"
                  icon="user delete"
                  type="button"
                  content={t("buttons.delete_customer", { ns: "customer" })}
                  onClick={() => {
                    modalStore.openModal(
                      <ConfirmDelete
                        header={t("removeCustomer", {
                          ns: "customer",
                        })}
                        value={id}
                        text={t("removeCustomerText", {
                          ns: "customer",
                        })}
                        deleteValue={(d) => {
                          removeCustomer(d).then(() => {
                            navigate("/customers");
                          });
                        }}
                      />
                    );
                  }}
                />
               {checkApplication(Application.AutomotiveExpert) && (
                  <>
                <Button
                  className="save_button mobile_buttons"
                  as={Link}
                  to="/customers"
                  icon="sitemap"
                  type="button"
                  color="teal"
                  content={t("Merge Dup. Customer", { ns: "common" })}
                />
                <Button
                  className="save_button mobile_buttons"
                  type="button"
                  color="olive"
                  icon="money bill alternate outline"
                  content="Accounts Receivable"
                  onClick={() => {
                    modalStore.openModal(
                      <ARForm
                        isAdd={false}
                        addItem={function (
                          values: TicketItemFormValues,
                          type: TicketItemType
                        ): void {
                          throw new Error("Function not implemented.");
                        }}
                        autoCapitalizeFirstLetter={false}
                      />
                    );
                  }}
                />
              </>
                )}
              </>
            )}
            <Button
              className="save_button mobile_buttons"
              as={Link}
              to="/customers"
              icon="cancel"
              type="button"
              content={t("cancel", { ns: "common" })}
            />
          </Grid.Column>
        </Grid>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign="bottom">
              <PageHeader
                header={t("shop_notes", { ns: "customer" })}
                type={"h3"}
                divider={false}
                showHelp
                helpName="customerDetails.privateShopNotes"
                addTitle={false}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                floated="right"
                color="green"
                className="save_button mobile_buttons"
                icon="plus"
                content={t("buttons.new_note", { ns: "customer" })}
                onClick={() =>
                  modalStore.openModal(
                    <AddCustomerNotes
                      name={"addCustomerNote"}
                      header={t("addShopNote", { ns: "customer" })}
                      isAdd={true}
                    />
                  )
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Divider></Divider>
          <Grid.Row>
            <Grid.Column>
              <Table striped unstackable className="dnxTable">
                <Table.Header>
                  <Table.Row key={"notesheader"}>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t("date", { ns: "common" })}`}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t("author", { ns: "customer" })}`}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {`${t("note", { ns: "customer" })}`}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {customerNotes && customerNotes.length > 0 ? (
                    customerNotes.map((note) => (
                      <Table.Row key={`notes.${note.id}`}>
                        <Table.Cell className="textAlignLeft">
                          <Button
                            color="grey"
                            icon="edit"
                            type="button"
                            onClick={() => {
                              setSelectedCustomerNote(note);
                              modalStore.openModal(
                                <AddCustomerNotes
                                  name={`updateCustomerNote.${note.id}`}
                                  header={t("editShopNote", {
                                    ns: "customer",
                                  })}
                                  isAdd={false}
                                />
                              );
                            }}
                          ></Button>
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("date", { ns: "common" })}:`}
                        >
                          {note.createdDate && format(note.createdDate, "Pp")}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("author", { ns: "customer" })}:`}
                        >
                          {note.firstName || note.lastName
                            ? `${note.lastName}${
                                note.firstName && `, ${note.firstName}`
                              }`
                            : note.userName}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("note", { ns: "customer" })}:`}
                        >
                          {note.notes}
                        </Table.Cell>

                        <Table.Cell className="textAlignRight">
                          <Button
                            color="grey"
                            icon="trash"
                            onClick={() => {
                              modalStore.openModal(
                                <ConfirmDelete
                                  header={t("removeShopNote", {
                                    ns: "customer",
                                  })}
                                  value={note.id ? note.id : ""}
                                  deleteValue={(d) => {
                                    removeCustomerNotes(d).then(() => {});
                                  }}
                                />
                              );
                            }}
                          ></Button>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row key={"noItems1"}>
                      <Table.Cell
                        className="noPaddingLeft"
                        width="8"
                        colSpan={8}
                      >
                        <Message
                          color="yellow"
                          className="textAlignCenter fontStyleItalic"
                        >
                          {t("grid_noItems", { ns: "common" })}
                        </Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign="bottom">
              <PageHeader
                header={t("vehicles", { ns: "customer" })}
                type={"h3"}
                divider={false}
                showHelp
                helpName="customerDetails.vehicles"
                addTitle={false}
              />
               {(isFiltered || (pagination && pagination.totalItems > 10)) && (
                <Input
                  icon="search"
                  type="text"
                  textAlign="right"
                  onChange={(e, d) => {
                    resetCustomerVehicleRegistry();
                    setPredicate("searchTerm", d.value);
                    setIsFiltered(d.value.length > 0);
                    loadCustomerVehicles(id?? "")
                  }}
                  className="width150"
                  maxLength={1000}
                ></Input>
              )}
            </Grid.Column>
            <Grid.Column>
             
              {isFiltered && (
                <Button
                  color="grey"
                  floated="right"
                  type="button"
                  content={t("clearFilter", { ns: "customer" })}
                  onClick={() => {
                    setIsFiltered(false);
                    resetPredicate();
                    setPagingParams(new PagingParams());
                    resetCustomerTicketRegistry();
                    loadCustomerTickets(id ?? "");
                  }}
                ></Button>
              )}
            </Grid.Column>
          </Grid.Row>
          <Divider></Divider>
          <Grid.Row>
            <Grid.Column>
              <CustomerDetailVehicleList
                customerId={id ?? ""}
                capitalizeFirstCharacter={
                  selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                }
                setIsFiltered={setIsFiltered}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={t("tickets", { ns: "customer" })}
                type={"h3"}
                divider={false}
                showHelp
                helpName="customerDetails.tickets"
                addTitle={false}
              />
            </Grid.Column>
          </Grid.Row>
          <Divider></Divider>
          <Grid.Row>
            <Grid.Column>
              <CustomerDetailTicketList customerId={id ?? ""} />
            </Grid.Column>
          </Grid.Row>
          {checkApplication(Application.AutomotiveExpert) && (
            <>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <PageHeader
                    header={t("accountsReceivable.header", { ns: "settings" })}
                    type={"h3"}
                    divider={false}
                    addTitle={false}
                  />
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Button
                    content="Pay on Account"
                    icon="dollar"
                    color="green"
                  />
                  <Button
                    content="Print Account Statement"
                    icon="print"
                    color="grey"
                  />
                </Grid.Column>
              </Grid.Row>
              <Divider></Divider>
              <Grid.Row>
                <ARTicketSearch />
              </Grid.Row>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
});
