import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../app/stores/store";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";
import { TicketType } from "../../../../app/models/enums";

export default observer(function VehiclePdfView() {
  const { t } = useTranslation(["common", "tickets"]);

  const { ticketSessionStore } = useStore();

  const { selectedTicketSession } = ticketSessionStore;

  return (
    <View
      style={{
        ...compose("view noPaddingLeftRight"),
        marginLeft: "1mm",
        marginRight: "1mm",
        flexGrow:1,
        flexShrink:1
      }}
    >
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom borderSolidRight borderSolidTop lineHeight"
          ),
        }}
      >
        <View
          style={{
            ...compose(
              "view metaHead borderSolidLeft noMarginLeft padding5pxLeftRight borderSolidRight width15"
            ),
          }}
        >
          <Text
            style={{
              ...compose(
                "text padding1mmTop noMarginLeft noPaddingLeft"
              ),
            }}
          >
            {t("year", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view borderSolidRight padding5pxLeftRight padding1mmTop padding5pxLeft noMarginLeft"
            ),
            width: "13mm",
            maxWidth: "13mm",
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft "),
            }}
          >
            {(selectedTicketSession?.vehicle?.year ?? 0) > 1 &&
              selectedTicketSession?.vehicle?.year}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view metaHead borderSolidRight noMarginLeft padding5pxLeftRight width15"
            ),
          }}
        >
          <Text
            style={{
              ...compose(
                "text padding1mmTop noMarginLeft noPaddingLeft"
              ),
            }}
          >
            {t("make", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose("view padding1mmTop padding5pxLeftRight noMarginLeft"),
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft "),
            }}
          >
            {selectedTicketSession?.vehicle?.makeName &&
              selectedTicketSession.vehicle.makeName}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom borderSolidRight lineHeight"
          ),
        }}
      >
        <View
          style={{
            ...compose(
              "view metaHead borderSolidRight borderSolidLeft noMarginLeft padding5pxLeftRight width15"
            ),
          }}
        >
          <Text
            style={{
              ...compose(
                "text padding1mmTop noMarginLeft noPaddingLeft "
              ),
            }}
          >
            {t("model", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding1mmTop padding5pxLeftRight noMarginLeft"
            ),
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft noPaddingLeft"),
            }}
          >
            {selectedTicketSession?.vehicle?.modelName &&
              selectedTicketSession.vehicle.modelName}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom borderSolidRight lineHeight"
          ),
        }}
      >
        <View
          style={{
            ...compose(
              "view metaHead borderSolidRight borderSolidLeft noMarginLeft padding5pxLeftRight width15"
            ),
          }}
        >
          <Text
            style={{
              ...compose(
                "text padding1mmTop noMarginLeft noPaddingLeft"
              ),
            }}
          >
            {t("engine", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding1mmTop padding5pxLeftRight noMarginLeft"
            ),
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft noPaddingLeft"),
            }}
          >
            {selectedTicketSession?.vehicle?.engineName &&
              selectedTicketSession.vehicle.engineName}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom borderSolidRight lineHeight"
          ),
        }}
      >
        <View
          style={{
            ...compose(
              "view metaHead borderSolidRight borderSolidLeft noMarginLeft padding5pxLeftRight width15"
            ),
          }}
        >
          <Text
            style={{
              ...compose(
                "text padding1mmTop noMarginLeft noPaddingLeft"
              ),
            }}
          >
            {t("estimate.vin", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding5pxLeftRight padding1mmTop noMarginLeft "
            ),
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft noPaddingLeft "),
            }}
          >
            {selectedTicketSession?.vehicle?.vin &&
              selectedTicketSession.vehicle.vin}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom borderSolidRight lineHeight"
          ),
        }}
      >
        <View
          style={{
            ...compose(
              "view metaHead borderSolidRight borderSolidLeft noMarginLeft padding5pxLeftRight width15"
            ),
          }}
        >
          <Text
            style={{
              ...compose(
                "text padding1mmTop noMarginLeft noPaddingLeft "
              ),
            }}
          >
            {t("tag", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "text borderSolidRight padding5pxLeft padding1mmTop noMarginLeft"
            ),
            minWidth: "15mm",
            flexGrow:1,
            flexShrink:1
          }}
        >
          <Text>
            {selectedTicketSession?.vehicle?.tag &&
              selectedTicketSession.vehicle.tag}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view metaHead borderSolidRight noMarginLeft padding5pxLeftRight width18"
            ),
          }}
        >
          <Text
            style={{
              ...compose(
                "text  padding1mmTop noMarginLeft noPaddingLeft "
              ),
            }}
          >
            {t("vehicle", { ns: "common" })} #
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding1mmTop noMarginLeft padding5pxLeftRight"
            ),
            flexGrow:1,
            flexShrink:1
          }}
        >
          <Text>
            {selectedTicketSession?.vehicle?.vehicleNumber &&
              selectedTicketSession.vehicle.vehicleNumber}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidRight borderSolidTop borderSolidBottom lineHeight"
          ),
          marginTop: "1mm",
        }}
      >
        <View
          style={{
            ...compose(
              "view metaHead borderSolidRight borderSolidLeft noMarginLeft padding5pxLeftRight width21"
            ),
          }}
        >
          <Text
            style={{
              ...compose(
                "text padding1mmTop noMarginLeft noPaddingLeft "
              ),
            }}
          >
            {t("estimate.mileageIn", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding1mmTop borderSolidRight padding5pxLeftRight noMarginLeft"
            ),
            flexGrow:1,
            flexShrink:1
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft "),
            }}
          >
            {selectedTicketSession?.vehicle?.mileageIn &&
            selectedTicketSession?.vehicle?.mileageIn > 0
              ? selectedTicketSession.vehicle.mileageIn
              : " "}
          </Text>
        </View>
        <View
          style={{
            ...compose(`view metaHead borderSolidRight noMarginLeft padding5pxLeftRight`),
          }}
        >
          <Text
            style={{
              ...compose(
                `text padding1mmTop noMarginLeft noPaddingLeft`
              ),
            }}
          >
            {selectedTicketSession?.ticketType === TicketType.Estimate
              ? t("estimate.parts", { ns: "tickets" })
              : t("estimate.mileageOut", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              `view padding5pxLeftRight padding1mmTop noMarginLeft `
            ),
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft "),
            }}
          >
            {selectedTicketSession?.ticketType === TicketType.Estimate
              ? selectedTicketSession?.returnParts
                ? `${t("yes", { ns: "common" })}`
                : `${t("no", { ns: "common" })}`
              : selectedTicketSession?.vehicle?.mileageOut &&
                selectedTicketSession?.vehicle?.mileageOut > 0 &&
                selectedTicketSession.vehicle.mileageOut}
          </Text>
        </View>
      </View>
    </View>
  );
});
