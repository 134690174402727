import * as React from "react";

interface Props {
  action: string;
  children: (props: CaptchaProps) => React.ReactNode;
}

interface CaptchaProps {
  isReady: boolean;
  execute: () => Promise<string>;
}

interface State {
  readonly isReady: boolean;
}

export class ReCaptcha extends React.PureComponent<Props, State> {
  state: State = {
    isReady: false,
  };

  private script: HTMLScriptElement | undefined;
  private widget: HTMLDivElement | undefined;

  componentDidMount(): void {
    this.loadScript();
  }

  componentWillUnmount(): void {
    document.body.removeChild(this.widget!);
    document.body.removeChild(this.script!);
  }

  render(): React.ReactNode {
    return this.props.children({
      isReady: this.state.isReady,
      execute: this.executeCaptcha,
    });
  }

  private loadScript = (): void => {
    window.captchaOnLoad = this.onLoad;

    const url = "https://www.google.com/recaptcha/api.js";
    const queryString = "?onload=captchaOnLoad&render=explicit";
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = url + queryString;
    script.async = true;
    script.defer = true;

    this.script = document.body.appendChild(script);
  };

  private onLoad = (): void => {
    const widget = document.createElement("div");
    widget.id = "g-recaptcha";
    this.widget = document.body.appendChild(widget);

    window.grecaptcha.render("g-recaptcha", {
      sitekey: process.env.REACT_APP_RECAPTCHA_SITEKEY!,
      size: "invisible",
    });

    window.grecaptcha.ready(() => {
      this.setState({ isReady: true });
    });
  };

  private executeCaptcha = (): Promise<string> => {
    if (!this.state.isReady) {
      throw new Error("Captcha can be executed only when it's ready.");
    }

    return window.grecaptcha.execute({ action: this.props.action });
  };
}
