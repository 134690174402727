import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Message } from "semantic-ui-react";

interface Props {
  errors: any;
}

export default observer(function ValidationErrors({ errors }: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [errors]);

  return (
    <Message negative>
      {errors && (
        <Message.List>
          {errors.map((err: any, i: number) => (
            <Message.Item key={`error.${i}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: err,
                }}
              />
            </Message.Item>
          ))}
        </Message.List>
      )}
    </Message>
  );
});
