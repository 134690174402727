import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  SettingsLaborRates,
  SettingsPayType,
  SettingsPricing,
  SettingsPricingFormValues,
  SettingsPayTypeFormValues,
  SettingsTaxRateFormValues,
  SettingsTaxRate,
} from "../models/pricing";
import { DropdownItemProps } from "semantic-ui-react";
import { v4 as uuid } from "uuid";
import { TimeType } from "../models/enums";

export default class PricingStore {
  loadingInitial = false;
  activeTab: number = 0;

  selectedSettingsTaxRate: SettingsTaxRateFormValues = {
    id: undefined,
    taxRate: 0,
    saleTaxOption: 0,
  };

  selectedSettingsPricing: SettingsPricingFormValues = {
    id: undefined,
    laborTimeHighIsActive: false,
    laborTimeAverageIsActive: false,
    laborTimeLowIsActive: false,
    laborRates: [],
    taxRate: 0,
  };

  selectedLaborRates: SettingsLaborRates | undefined = {
    id: undefined,
    isActive: false,
    name: "",
    rate: 0,
    order: 0,
  };

  selectedPayType: SettingsPayTypeFormValues = {
    id: undefined,
    name: "",
    isActive: false,
    isStandard: false,
    isPaymentProcessor: false,
  };

  dropdownPayTypesList: DropdownItemProps[] = [];

  payTypeRegistry = new Map<string | undefined, SettingsPayType>();

  dropdownLaborRatesList: DropdownItemProps[] = [];

  activeLaborRates: TimeType = TimeType.Custom;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => {},
      () => {
        this.payTypeRegistry.clear();
        this.loadSettingsPayType(false);
      }
    );
  }

  loadSettingsTaxRate = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Pricing.getSettingsTaxRate();
      runInAction(() => {
        this.selectedSettingsTaxRate = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  resetPayTypesRegistries = () => {
    this.payTypeRegistry.clear();
    this.dropdownLaborRatesList = [];
    this.dropdownPayTypesList = [];
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  getPrimaryLaborRate = () => {
    let primaryLaborRate: SettingsLaborRates | undefined = {
      id: undefined,
      isActive: false,
      name: "",
      rate: 0,
      order: 0,
    };

    if (
      this.selectedSettingsPricing.laborRates &&
      this.selectedSettingsPricing.laborRates.length > 0
    ) {
      primaryLaborRate = this.selectedSettingsPricing.laborRates.find((x) => {
        return x.isActive && x.order === 0;
      });
    }

    return primaryLaborRate;
  };

  loadSettingsPricing = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Pricing.getSettingsPricing();
      runInAction(() => {
        this.selectedSettingsPricing = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadDropdownPayTypeList = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Pricing.dropdownListSettingsPayTypes();
      runInAction(() => {
        this.dropdownPayTypesList = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  getLaborRate = (id: string) => {
    let laborRate: SettingsLaborRates | undefined = {
      id: undefined,
      isActive: false,
      name: "",
      rate: 0,
      order: 0,
    };

    if (
      this.selectedSettingsPricing.laborRates &&
      this.selectedSettingsPricing.laborRates.length > 0
    ) {
      laborRate = this.selectedSettingsPricing.laborRates.find((x) => {
        return x.id === id;
      });
    }

    return laborRate;
  };

  loadSettingsPayType = async (includePaymentProcessors: boolean) => {
    this.loadingInitial = true;
    try {
      this.payTypeRegistry.clear();
      const result = await agent.Pricing.listSettingsPayTypes(
        includePaymentProcessors
      );
      result.forEach((payType) => {
        this.setPayType(payType);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  get getPayTypes() {
    return Array.from(this.payTypeRegistry.values());
  }

  getPayTypeName = (id: string): string => {
    let returnValue: string = "";

    let paymentType: SettingsPayType | undefined = this.payTypeRegistry.get(id);

    if (paymentType) {
      returnValue = paymentType.name;
    }

    return returnValue;
  };

  setSelectedSettingsTaxRate = (values: SettingsTaxRateFormValues) => {
    this.selectedSettingsTaxRate = values;
  };

  setSelectedSettingsPayType = (values: SettingsPayTypeFormValues) => {
    this.selectedPayType = values;
  };

  setSelectedSettingsPricing = (values: SettingsPricingFormValues) => {
    this.selectedSettingsPricing = values;
  };

  private setPayType = (payType: SettingsPayType) => {
    this.payTypeRegistry.set(payType.id, payType);
  };
  getPayType = (id: string) => {
    return this.payTypeRegistry.get(id);
  };

  createSettingsTaxRate = async (values: SettingsTaxRateFormValues) => {
    try {
      let myNew: SettingsTaxRate = new SettingsTaxRate(values);
      await agent.Pricing.addSettingsTaxRate(myNew);

      runInAction(() => {
        this.setSelectedSettingsTaxRate(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  createSettingsPayType = async (values: SettingsPayTypeFormValues) => {
    try {
      let myNew: SettingsPayType = new SettingsPayType(values);
      myNew.id = uuid();
      myNew.isActive = true;
      await agent.Pricing.addSettingsPayTypes(myNew);

      runInAction(() => {
        this.payTypeRegistry.set(
          myNew.id,
          myNew
        )
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateSettingsPayType = async (values: SettingsPayTypeFormValues) => {
    try {
      let myNew: SettingsPayType = new SettingsPayType(values);
      await agent.Pricing.updateSettingsPayTypes(myNew);

      runInAction(() => {
        if (values.id) {
          let updatedPayType = { ...this.getPayType(values.id), ...values };
          this.payTypeRegistry.set(
            values.id,
            updatedPayType as SettingsPayType
          );
          this.selectedPayType = updatedPayType as SettingsPayType;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  removeSettingsPayType = async (id: string) => {
    try {
      await agent.Pricing.removeSettingsPayTypes(id);

      runInAction(() => {
        this.payTypeRegistry.delete(id);
      });
    } catch (error) {
      console.log(error);
    }
  };

  createSettingsPricing = async (values: SettingsPricingFormValues) => {
    try {
      let myNew: SettingsPricing = new SettingsPricing(values);
      await agent.Pricing.addSettingsPricing(myNew);

      runInAction(() => {
        this.setSelectedSettingsPricing(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateSettingsTaxRate = async (values: SettingsTaxRateFormValues) => {
    try {
      let myNew: SettingsTaxRate = new SettingsTaxRate(values);
      await agent.Pricing.updateSettingsTaxRate(myNew);

      runInAction(() => {
        this.setSelectedSettingsTaxRate(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  toggleSettingsPayType = async (id: string) => {
    try {
      await agent.Pricing.toggleSettingsPayTypes(id);
      runInAction(() => {
        if (id) {
          let updatedPayType = this.getPayType(id);
          if (updatedPayType && !updatedPayType.isStandard) {
            updatedPayType.isActive = !updatedPayType.isActive;
            this.payTypeRegistry.set(id, updatedPayType);
          } else {
            this.payTypeRegistry.clear();
          }
        }
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateSettingsPricing = async (values: SettingsPricingFormValues) => {
    try {
      let myNew: SettingsPricing = new SettingsPricing(values);

      await agent.Pricing.updateSettingsPricing(myNew);

      runInAction(() => {
        this.setSelectedSettingsPricing(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadSettingsLaborRatesDropdown = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Pricing.listSettingsLaborRatesDropdown();

      runInAction(() => {
        this.dropdownLaborRatesList = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  getActiveLaborRates = async (loadLabors: boolean) => {
    this.loadingInitial = true;
    let laborRate = TimeType.Custom;
    try {
      if (loadLabors) {
        const result = await agent.Pricing.getActiveLaborRates();

        runInAction(() => {
          this.activeLaborRates = result;
        });

        return result;
      } else return laborRate;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };
}
