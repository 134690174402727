import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { Labor } from "../models/labor";
import { VehicleFormValues } from "../models/vehicle";
import { JobCategory } from "../models/enums";
import { DropdownItemProps } from "semantic-ui-react";
import adjustmentsdiagnosis from "../common/img/adjustmentsdiagnosis.png";
import basicmaintenance from "../common/img/basicmaintenance.png";
import brakeswheels from "../common/img/brakeswheels.png";
import computerselectronics from "../common/img/computerselectronics.png";
import coolingsystembelts from "../common/img/coolingsystembelts.png";
import drivetrainrearend from "../common/img/drivetrainrearend.png";
import electriclights from "../common/img/electricligths.png";
import enginevalvetrain from "../common/img/enginevalvetrain.png";
import exhaustemissions from "../common/img/exhaustemissions.png";
import frontendsuspension from "../common/img/frontendsuspension.png";
import fuelsystemtuneup from "../common/img/fuelsystemtuneup.png";
import gasketsseals from "../common/img/gasketseals.png";
import heatingac from "../common/img/heatingac.png";
import miscellaneousaccessories from "../common/img/miscellaneousaccessories.png";
import steeingcolumngauges from "../common/img/steeringcolumngauges.png";
import transmissiontransaxle from "../common/img/transmissiontransaxle.png";

export default class JobLaborStore {
  jobCategoryList: DropdownItemProps[] = [];
  jobTypeList: DropdownItemProps[] = [];
  jobList: DropdownItemProps[] = [];

  loadingInitial = false;
  loading = false;
  predicate = new Map().set("all", true);

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.jobCategoryList = [];
        this.jobTypeList = [];
        this.jobList = [];

        this.loadJobCategoryList();
      }
    );
  }
  loadJobTypeList = async (jobCategoryId: number) => {
    this.loadingInitial = true;
    try {
      const result = await agent.Job.jobTypeList(jobCategoryId);
      runInAction(() => {
        this.jobTypeList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadDsiJobList = async (
    jobTypeId: number,
    model: string,
    engine: string,
    isCustom: boolean
  ) => {
    this.loadingInitial = true;
    try {
      const result = await agent.Job.dsiJobList(
        jobTypeId,
        model,
        engine,
        isCustom
      );
      runInAction(() => {
        this.jobList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  getJobRequiresEngine = async (id: number) => {
    this.loadingInitial = true;
    try {
      const result = await agent.Job.jobRequiresEngine(id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  resetJobType = () => {
    this.jobTypeList = [];
  };

  resetJob = () => {
    this.jobList = [];
  };

  loadLabor = async (vehicle: VehicleFormValues, jobId: number) => {
    this.loadingInitial = true;
    try {
      let result: Labor;
      result = await agent.JobLabors.getJobLabor(
        vehicle.year!,
        vehicle.modelId!,
        jobId,
        vehicle.engineId!
      );
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadCustomLabor = async (vehicle: VehicleFormValues, jobId: number) => {
    this.loadingInitial = true;
    try {
      let make: string = vehicle.makeName
        ? vehicle.makeName
        : 'undefined';
      let customVehicleId: string =
        vehicle.engineId && vehicle.engineId !== "-1"
          ? vehicle.engineId
          : '';
      let year: number = (vehicle.year ?? 0) > 0 ? vehicle.year ?? 0 : 0;

      
      if (vehicle.makeName) make = vehicle.makeName;
      const result = await agent.JobLabors.getCustomJobLabor(
        year,
        vehicle.modelId ?? "",
        jobId,
        make,
        customVehicleId
      );
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadJobCategoryList = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Job.jobCategoryList();
      runInAction(() => {
        this.jobCategoryList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  jobSearch = async (model: string, engine: string) => {
    this.loadingInitial = true;
    try {
      const result = await agent.Job.searchJobList(model, engine);
      runInAction(() => {
        this.jobList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  handleImage = (jobCategory: JobCategory) => {
    let img: string = "";
    switch (jobCategory) {
      case JobCategory.AdjustmentsDiagnosis:
        img = adjustmentsdiagnosis;
        break;
      case JobCategory.BasicMaintenance:
        img = basicmaintenance;
        break;
      case JobCategory.BrakesWheels:
        img = brakeswheels;
        break;
      case JobCategory.CoolingSystemBelts:
        img = coolingsystembelts;
        break;
      case JobCategory.ComputersElectronics:
        img = computerselectronics;
        break;
      case JobCategory.DriveTrainRearEnd:
        img = drivetrainrearend;
        break;
      case JobCategory.ElectricalLights:
        img = electriclights;
        break;
      case JobCategory.EngineValveTrain:
        img = enginevalvetrain;
        break;
      case JobCategory.ExhaustEmissions:
        img = exhaustemissions;
        break;
      case JobCategory.FrontEndSuspension:
        img = frontendsuspension;
        break;
      case JobCategory.FuelSystemTuneup:
        img = fuelsystemtuneup;
        break;
      case JobCategory.GasketsSeals:
        img = gasketsseals;
        break;
      case JobCategory.HeatingAC:
        img = heatingac;
        break;
      case JobCategory.MiscellaneousAccessories:
        img = miscellaneousaccessories;
        break;
      case JobCategory.SteeringColumnGauges:
        img = steeingcolumngauges;
        break;
      case JobCategory.TransmissionTransaxle:
        img = transmissiontransaxle;
        break;
    }
    return img;
  };
}
