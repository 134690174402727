import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Grid, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, FieldArray, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { SettingsPricingFormValues } from "../../../app/models/pricing";
import MyCheckbox from "../../../app/common/form/MyCheckbox";
import PageHeader from "../../../app/layout/PageHeader";
import ValidationErrors from "../../errors/ValidationErrors";
import LaborRate from "./LaborRate";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyHelpButton from "../../../app/common/form/MyHelpButton";

export default observer(function LaborRates() {
  const { t } = useTranslation(["common", "pricingSettings"]);

  const { pricingStore, ticketStore } = useStore();
  const {
    loadSettingsPricing,
    loadingInitial,
    createSettingsPricing,
    updateSettingsPricing,
  } = pricingStore;

  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;

  const validationSchema = Yup.object({
    // laborRates: Yup.array().of(
    //   Yup.object().shape({
    //     rate: Yup.string().matches(
    //       new RegExp(RegexConstants.decimalPlacesRegex),
    //       t("validation.invalid_currency", { ns: "errors" })
    //     ),
    //   })
    // ),
  });

  const [settingsPricing, setSettingsPricing] =
    useState<SettingsPricingFormValues>(new SettingsPricingFormValues());

  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadValues();
  }, [loadSettingsPricing]);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  function loadValues() {
    loadSettingsPricing().then((settingsPricing) =>
      setSettingsPricing(new SettingsPricingFormValues(settingsPricing))
    );
  }

  function handleFormSubmit(
    values: SettingsPricingFormValues,
    setError: (errors: FormikErrors<SettingsPricingFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsPricing(values)
      : () => createSettingsPricing(values);

    action()
      .then(() => {
        loadValues();
        setSubmitting(false);
        setCompleted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setError(error);
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("laborRates_loading", { ns: "pricingSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("laborrateoptions", { ns: "common" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsPricing}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />

              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    {t("labor_rate.p1", { ns: "pricingSettings" })}
                    {t("labor_rate.p2", { ns: "pricingSettings" })}
                    {t("labor_rate.p3", { ns: "pricingSettings" })}
                    <MyHelpButton
                      name="pricingHelp"
                      help="settings.pricing.laborRate"
                      color={"blue"}
                      position="bottom right"
                      seeMore={true}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  {values.laborRates && (
                    <FieldArray
                      name="laborRates"
                      render={() =>
                        values.laborRates.map((source, index) => (
                          <LaborRate
                            key={`laborRate.${source.id}`}
                            id={index}
                            name={"laborRates"}
                            source={source}
                            autoCapitalizeFirstLetter={
                              selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                            }
                          ></LaborRate>
                        ))
                      }
                    ></FieldArray>
                  )}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    {t("labor_rate.show_labor_times", {
                      ns: "pricingSettings",
                    })}
                    <MyHelpButton
                      name="pricingHelp"
                      help="settings.pricing.laborTime"
                      color={"blue"}
                      position="top left"
                      seeMore={true}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyCheckbox
                      label={t("labor_rate.low", { ns: "pricingSettings" })}
                      name="laborTimeLowIsActive"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyCheckbox
                      label={t("labor_rate.average", {
                        ns: "pricingSettings",
                      })}
                      name="laborTimeAverageIsActive"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyCheckbox
                      label={t("labor_rate.high", { ns: "pricingSettings" })}
                      name="laborTimeHighIsActive"
                      disabled={false}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color="green"
                      className="save_button"
                      icon="check"
                      content={t("save", { ns: "common" })}
                      disabled={isSubmitting || !dirty || !isValid}
                      loading={isSubmitting}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/settings"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
