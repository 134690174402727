import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import {
  Button,
  Grid,
  Message,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import UpsertSelectableComment from "./UpsertSelectableComment";
import ConfirmDelete from "../../../app/common/form/ConfirmDelete";
import { SelectableCommentsDto } from "../../../app/models/settings";
import { convertSelectableCommentStatusToString } from "../../../app/common/util/functions";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";

export default observer(function SelectableComments() {
  const { t } = useTranslation(["common", "ticketSettings"]);
  const { modalStore, selectableCommentsStore, userStore, settingsStore } = useStore();
  const { loadSelectableComments, getSelectableComments, setSelectedSelectableComments, pagination, pagingParams, setPagingParams, SelectableCommentsRegistry, removeSelectableComment } = selectableCommentsStore;
  const { user } = userStore;

  useEffect(
    () => {
      if (user && user.clientId) {
        loadSelectableComments();
      }
    }, []);

  function handleDelete(commentId: string) {
    removeSelectableComment(commentId)
      .then(() => {
        loadSelectableComments();
      })
      .catch(error => {
        console.log("Remove comment error:", error);
      })
  }

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadSelectableComments();
    }
  }
  return (
    <>
      <PageHeader
        header={t("selectableComments.header", { ns: "settings" })}
        type="h2"
        divider
        getAlerts={true}
        addTitle={false}
      />
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Button
              color={"green"}
              className="save_button"
              icon="plus"
              type="button"
              content={t("selectableComments.new", { ns: "settings" })}
              onClick={() => {
                modalStore.openModal(<UpsertSelectableComment clientId={user?.clientId!} isAdd={true} />);
              }}
            />

            <Table>
              <Table.Header>
                <Table.HeaderCell textAlign="left"></Table.HeaderCell>
                <Table.HeaderCell textAlign="left">
                  {t("selectableComments.commentName", { ns: "settings" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("selectableComments.comment", { ns: "settings" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("status", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right"></Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {getSelectableComments &&
                  getSelectableComments.length > 0 ?
                  getSelectableComments.map((c: SelectableCommentsDto) => (

                    <Table.Row key={c.id}>
                      <Table.Cell>
                        {" "}
                        <Button
                          color="grey"
                          icon="edit"
                          type="button"
                          onClick={() => {
                            console.log('c:', c);
                            setSelectedSelectableComments(c);
                            modalStore.openModal(<UpsertSelectableComment clientId={user?.clientId!} isAdd={false} />);
                          }}
                        ></Button>
                      </Table.Cell>
                      <Table.Cell>
                        {c.name}
                      </Table.Cell>
                      <Table.Cell>
                        {c.comment}
                      </Table.Cell>
                      <Table.Cell>
                        {convertSelectableCommentStatusToString(c.status)}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Button
                          color="grey"
                          icon="trash"
                          type="button"
                          onClick={() =>
                            modalStore.openModal(
                              <ConfirmDelete
                                header={t("selectableComments.remove", {
                                  ns: "settings",
                                })}
                                deleteValue={handleDelete}
                                value={c.id}
                              />
                            )
                          }
                        ></Button>
                      </Table.Cell>
                    </Table.Row>
                  )) : (
                    <Table.Row key={"noitems"}>
                      <Table.Cell width="8" colSpan={8}>
                        <Message
                          color="yellow"
                          className="textAlignCenter fontStyleItalic"
                        >
                          {t("grid_noItems", { ns: "common" })}
                        </Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='12'>
                    <MyPaging
                      itemCount={pagination ? pagination.totalItems : 10}
                      pageSize={pagingParams.pageSize}
                      currentPage={pagination ? pagination.currentPage : 1}
                      onClick={listOnClick}
                      onChange={listOnClick}
                      floated="right"
                    ></MyPaging>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
});
