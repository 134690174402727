import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { ReportInput, VendorReport } from "../../models/reports";
import { Pagination, PagingParams } from "../../models/pagination";
import { DateRangeOption, ReportType } from "../../models/enums";
import i18n from "../../common/i18n/i18n";
export default class VendorReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.vendorReportRegistry.clear();
        this.loadVendorReport();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  reportCriteria: ReportInput = new ReportInput({
    dateRange: DateRangeOption.Today,
    startDate: new Date(),
    endDate: new Date(),
    reportType: ReportType.Summary,
    filter: "assigned",
  });

  vendorReportRegistry = new Map<Date, VendorReport>();

  loadVendorReport = async () => {
    this.loadingInitial = true;
    try {
      this.resetVendorReportRegistry();
      const result = await agent.Reports.getVendorReport(this.axiosParams);
      runInAction(() => {
        result.forEach((vendorReport) => {
          this.setVendorReport(vendorReport);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setVendorReport = (vendorReport: VendorReport) => {
    vendorReport.date = new Date(vendorReport.date);
    this.vendorReportRegistry.set(vendorReport.date, vendorReport);
  };

  get getVendorReport() {
    return Array.from(this.vendorReportRegistry.values()).sort(
      (a, b) => b.date.getTime() - a.date.getTime()
    );
  }

  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (
    startDate?: Date,
    endDate?: Date,
    type?: ReportType,
    filter?: string
  ) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
    if (type) this.predicate.set("Type", type);
    if (filter) this.predicate.set("Filter", filter);
  };

  get groupedVendorReport() {
    return Object.entries(
      this.getVendorReport.reduce((groups, vendor) => {
        const name = `${
          vendor.vendor
            ? vendor.vendor
            : i18n.t("unassigned", {
                ns: "tickets",
              })
        }`;
        groups[name] = groups[name] ? [...groups[name], vendor] : [vendor];
        return groups;
      }, {} as { [key: string]: VendorReport[] })
    );
  }

  setReportCriteria = (values: ReportInput) => {
    this.reportCriteria = new ReportInput(values);
  };

  calculateVendor = (data: VendorReport[]) => {
    const summary = data.reduce(
      (accumulator: VendorReport, item: VendorReport) => {
        accumulator.quantity += item.quantity;
        accumulator.costTotal += item.costTotal;
        accumulator.subTotal += item.subTotal;
        accumulator.profit += item.profit;
        accumulator.totalMarkup += item.totalMarkup;
        accumulator.markup += item.markup;

        return accumulator;
      },
      {
        date: new Date(),
        quantity: 0,
        costTotal: 0,
        subTotal: 0,
        profit: 0,
        vendor: "",
        invoiceNumber: 0,
        partCode: "",
        description: "",
        rate: 0,
        markup: 0,
        unitPrice: 0,
        lowestMarkup: 0,
        highestMarkup: 0,
        totalMarkup: 0,
      }
    );

    summary.markup = summary.markup / data.length;

    return summary;
  };

  resetVendorReportRegistry = () => {
    this.vendorReportRegistry.clear();
  };
}
