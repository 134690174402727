import { observer } from "mobx-react-lite";
import { Button, ButtonOr, Message, Table } from "semantic-ui-react";
import {
  formatCurrency,
  getTicketTypeColor,
} from "../../app/common/util/functions";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TicketType } from "../../app/models/enums";
import { TicketSearch } from "../../app/models/search";

// interface Props {
//   id: string;
// }

export default observer(function PartTicketSearch() {
  const { t } = useTranslation(["common", "search"]);
  const navigate = useNavigate();

  const { searchStore, ticketStore } = useStore();

  const { loadVehicleTicket, filterTicketsByVehicleId } = searchStore;
  const { resetTicketHistoryRegistry } = ticketStore;

  //   const [ticketSearch, setTicketSearch] = useState<TicketSearch[]>([]);
  //   useEffect(() => {
  //     loadVehicleTicket(id).then(() => {
  //       setTicketSearch(filterTicketsByVehicleId(id));
  //     });
  //   }, [loadVehicleTicket, setTicketSearch, filterTicketsByVehicleId, id]);

  return (
    <Table.Cell className="tableInTable textAlignRightNotMobile" colSpan={7}>
      <Table striped unstackable className="dnxTable no_padding">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("Ticket #", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell>{t("date", { ns: "common" })}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("Customer Information", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={""}>
            <Table.Cell data-label={`${t("description", { ns: "common" })}:`}>
             #11590
            </Table.Cell>
            <Table.Cell
              data-label={`${t("date", { ns: "common" })}:`}
            >3/27/2024</Table.Cell>
            <Table.Cell
              data-label={`${t("ticket", { ns: "common" })}:`}
            >Travis Shortbread (Link to customer)</Table.Cell>
            <Table.Cell className="textAlignRight" width={5}>
              <Button
                content={t("View Ticket", { ns: "common" })}
                icon="eye"
                className="tealishButtons"
                size="small"
                onClick={() => {
                  resetTicketHistoryRegistry();
                  navigate(`/ticket/view/`);
                }}
              />
            </Table.Cell>
          </Table.Row>
          {/* ))
          ) : (
            <Table.Row key={"noItems"}>
              <Table.Cell width="5" colSpan={5}  className="noPaddingLeft">
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )} */}
        </Table.Body>
      </Table>
    </Table.Cell>
  );
});
