import React from "react";
import { Button, Card } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import MyHelpText from "./MyHelpText";
import { useStore } from "../../stores/store";
import PageHeader from "../../layout/PageHeader";

interface Props {
  name: string;
  help: string;
  seeMore?: boolean;
}

export default function MyHelpModal(props: Readonly<Props>) {
  const { t } = useTranslation(["common", "help"]);
  const { modalStore } = useStore();
  return (
    <Card className="bordernone">
      <Card.Header>
        <PageHeader
          type={"h3"}
          header={t(`${props.help}.header`, { ns: "help" })}
          divider={false}
          addTitle={false}
        />
      </Card.Header>
      <Card.Content className="help-text linebreaks">
        {t(`${props.help}.shortdescription`, { ns: "help" })}
        <br /><br />
        {props.seeMore && (
          <Button
            color="blue"
            onClick={() =>
              modalStore.openModal(<MyHelpText name={props.name} help={props.help} />)
            }
            size="medium"
          >
            {t(`${props.help}.seemore`, { ns: "help" })}
          </Button>
        )}
      </Card.Content>
    </Card>
  );
}
