import { ErrorMessage, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Message, Form, Grid, Divider } from "semantic-ui-react";
import * as Yup from "yup";

import { store, useStore } from "../../app/stores/store";
import ValidationErrors from "../errors/ValidationErrors";
import PageHeader from "../../app/layout/PageHeader";
import MyDropdownInput from "../../app/common/form/MyDropdownInput";
import { TicketWorkflowSteps } from "../../app/models/enums";
import { useNavigate } from "react-router-dom";

interface Props {
  name: string;
  header: string;
  ticketId?: string;
  complete: boolean;
}

export default observer(function PickCustomVehicleYear({
  name,
  header,
  ticketId,
  complete,
}: Props) {
  const { t } = useTranslation([
    "common",
    "miscSettings",
    "tickets",
    "workFlowSettings",
  ]);
  const navigate = useNavigate();

  const {
    customVehicleStore,
    vehicleStore,
    customerStore,
    ticketSessionStore,
  } = useStore();

  const { selectedCustomVehicle, yearList, loadYears } = customVehicleStore;
  const { selectCustomVehicle, selectedVehicle } = vehicleStore;
  const { selectedCustomer } = customerStore;
  const { updateTicketSession } = ticketSessionStore;

  const [completed] = useState<boolean>(false);

  const validationSchema = Yup.object({
    //year: Yup.string().required(t("yearRequired", { ns: "errors" })),
  });

  interface CustomVehicleYear {
    year?: number;
    error?: string;
  }

  let initial: CustomVehicleYear = { year: undefined, error: undefined };

  useEffect(() => {
    loadYears(selectedCustomVehicle);
  }, [loadYears, selectedCustomVehicle]);

  function handleFormSubmit(
    values: CustomVehicleYear,
    setErrors: (errors: FormikErrors<CustomVehicleYear>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    setSubmitting(false);

    selectCustomVehicle(selectedCustomVehicle, values.year);

    const action = () =>
        updateTicketSession(
          TicketWorkflowSteps.Vehicle,
          ticketId ?? "",
          selectedVehicle,
          selectedCustomer
        );
    action()
      .then(() => {
        navigate(`/ticket/jobCategory/${ticketId}/${complete}`);
        store.modalStore.closeModal();
      })
      .catch((error) => {});
  }

  return (
    <Formik
      initialValues={initial}
      validationSchema={validationSchema}
      onSubmit={(values, { setErrors, setSubmitting }) =>
        handleFormSubmit(values, setErrors, setSubmitting)
      }
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        dirty,
        handleBlur,
        handleChange,
        setFieldValue,
        errors,
        values,
        touched,
      }) => (
        <Form
          className="ui form error setGridHeight"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <PageHeader
            header={header}
            type={"h3"}
            divider={true}
            addTitle={false}
          />
          <Grid>
            {errors.error && (
              <Grid.Row>
                <Grid.Column>
                  {completed && isValid && (
                    <Message positive>
                      {t("settingsupdated", {
                        ns: "common",
                      })}
                    </Message>
                  )}
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row>
              <Grid.Column>
                <MyDropdownInput
                  placeholder={t("year", { ns: "tickets" })}
                  label={undefined}
                  name="year"
                  options={yearList}
                  disabled={false}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          <Button
            color="green"
            className="save_button"
            icon="check"
            content={t("save", { ns: "common" })}
            disabled={isSubmitting}
            loading={isSubmitting}
            type="submit"
          />
          <Button
            className="save_button"
            onClick={store.modalStore.closeModal}
            icon="cancel"
            type="reset"
            content={t("cancel", { ns: "common" })}
          />
        </Form>
      )}
    </Formik>
  );
});
