import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import PageHeader from "../../app/layout/PageHeader";
import { ClientSubscriptions } from "../../app/models/clientSubscription";

interface Props {
  clientSubscription: ClientSubscriptions;
}

export default observer(function BillingAddress({
  clientSubscription,
}: Props) {
  const { t } = useTranslation(["common", "subscription"]);

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <PageHeader
            header={t("billingAddress", { ns: "common" })}
            type={"h2"}
            divider={true}
            addTitle={false}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="RemoveTopBottomPadding">
        <Grid.Column>{clientSubscription.clientName}</Grid.Column>
      </Grid.Row>
      <Grid.Row className="RemoveTopBottomPadding">
        <Grid.Column>
          {clientSubscription.firstName} {clientSubscription.lastName}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="RemoveTopBottomPadding">
        <Grid.Column>{clientSubscription.addressLine1}</Grid.Column>
      </Grid.Row>
      <Grid.Row className="RemoveTopBottomPadding">
        <Grid.Column>{clientSubscription.addressLine2}</Grid.Column>
      </Grid.Row>
      <Grid.Row className="RemoveTopBottomPadding">
        <Grid.Column>
          {clientSubscription.city}, {clientSubscription.state}{" "}
          {clientSubscription.postalCode}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row className="RemoveTopBottomPadding">
        <Grid.Column>{clientSubscription.country}</Grid.Column>
      </Grid.Row>
      <Grid.Row className="removeTopPadding">
        <Grid.Column>
          {`${t("phonenumber", { ns: "common" })}:`}{" "}
          {clientSubscription.billingPhone}
        </Grid.Column>
      </Grid.Row>
    </>
  );
});
