import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { IndividualTypeSales, ReportInput } from "../../models/reports";
import { PagingParams, Pagination } from "../../models/pagination";
import { sortingStrings } from "../../common/util/functions";
import { DateRangeOption, ReportType } from "../../models/enums";

export default class PartSalesReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.partReportRegistry.clear();
        this.loadPartReport();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  reportCriteria: ReportInput = new ReportInput({
    dateRange: DateRangeOption.Today,
    startDate: new Date(),
    endDate: new Date(),
    reportType: ReportType.Summary,
    filter: "0",
  });

  partReportRegistry = new Map<string, IndividualTypeSales>();

  resetPartRegistry = () => {
    this.partReportRegistry.clear();
  };

  loadPartReport = async () => {
    this.loadingInitial = true;
    try {
      this.partReportRegistry.clear();
      const result = await agent.Reports.getPartReport(this.axiosParams);
      runInAction(() => {
        result.forEach((parts) => {
          this.setPartReport(parts, this.reportCriteria.reportType === ReportType.Detailed);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setPartReport = (
    partReport: IndividualTypeSales,
    isDetailed: boolean
  ) => {
    let key = partReport.description ?? "";
    if (isDetailed) {
      key = `${key} ${partReport.partCode ?? ""} ${partReport.rate ?? "0"}`;
    }

    this.partReportRegistry.set(key, partReport);
  };

  get getPartReport() {
    return Array.from(this.partReportRegistry.values()).sort((a, b) => {
      return sortingStrings(a.description ?? "", b.description ?? "") ?? 0;
    });
  }

  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (startDate?: Date, endDate?: Date, type?: ReportType) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
    if (type) this.predicate.set("Type", type);
  };

  setReportCriteria = (values: ReportInput) => {
    this.reportCriteria = new ReportInput(values);
  };

  calculateTotalSales = (data: IndividualTypeSales[]) => {
    const summary = data.reduce(
      (accumulator: IndividualTypeSales, item: IndividualTypeSales) => {
        accumulator.revenue += item.revenue ?? 0.0;
        return accumulator;
      },
      {
        revenue: 0,
      }
    );
    return summary;
  };
}
