import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Input,
  Segment,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { useStore } from "../../app/stores/store";
import { MiscellaneousChargeFormValues } from "../../app/models/miscellaneousCharges";
import { SettingsTaxRate } from "../../app/models/pricing";
import MyHelpButton from "../../app/common/form/MyHelpButton";
import { formatDecimal } from "../../app/common/util/functions";

interface Props {
  miscellaneousCharge: MiscellaneousChargeFormValues;
}

export default observer(function ExampleCalculations({
  miscellaneousCharge,
}: Props) {
  const { t } = useTranslation(["common", "miscSettings", "tickets", "errors"]);
  const { miscellaneousChargeStore, pricingStore } = useStore();

  const { calculateMiscCharge, calculateMiscChargeTax } =
    miscellaneousChargeStore;

  const { loadSettingsTaxRate } = pricingStore;

  useEffect(() => {
    loadSettingsTaxRate().then((taxRate) =>
      setSettingsTaxRate(new SettingsTaxRate(taxRate))
    );
  }, [loadSettingsTaxRate]);

  const [settingsTaxRate, setSettingsTaxRate] = useState<SettingsTaxRate>(
    new SettingsTaxRate()
  );

  const [labor, setLabor] = useState<number>(0);
  const [parts, setParts] = useState<number>(0);
  const [misc, setMisc] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);

  const [showExampleCalculations, setShowExampleCalculations] =
    useState<boolean>(false);

  return (
    <Container className="page">
      <Form
        className="ui form error"
        autoComplete="off"
        //onSubmit={handleSubmit}
      >
        <Grid.Row verticalAlign="middle">
          <Button
            className="save_button"
            content={
              showExampleCalculations
                ? t("new_charges.hideCalculations", {
                    ns: "miscSettings",
                  })
                : t("new_charges.showCalculations", {
                    ns: "miscSettings",
                  })
            }
            type="button"
            color="blue"
            onClick={() => {
              setShowExampleCalculations(!showExampleCalculations);
            }}
            inverted
          ></Button>
        </Grid.Row>
        {showExampleCalculations && (
          <Segment>
            <Grid.Row>
              <Grid.Column>
                <label className="myLabel fontStyleItalic">
                  {`${t("new_charges.exampleCalculations", {
                    ns: "miscSettings",
                  })}:`}{" "}
                  <MyHelpButton
                    name="status"
                    help="settings.miscCharges.status"
                    color={"blue"}
                  />
                </label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label className="myLabel" htmlFor="labor">
                  {t("tech.labor", { ns: "miscSettings" })}:
                </label>
                <Input
                  fluid
                  className="input"
                  iconPosition="left"
                  name="labor"
                  type="number"
                  icon="dollar"
                  placeholder={`${t("dollarPlaceholder", {
                    ns: "miscSettings",
                  })}`}
                  value={labor || undefined}
                  onInput={(e: any, d: any) => {
                    let value = e.target.value;
                    const arr = value.split(".");
                    if (arr.length === 2) {
                      if (arr[1].length > 2) {
                        value = arr[0] + "." + arr[1].substring(0, 2);
                        if (!isNaN(parseFloat(value))) {
                          setLabor(Number(value));
                        }
                        e.target.value = value;
                      }
                    }
                  }}
                  onChange={(e, d) => {
                    if (!isNaN(parseFloat(d.value))) {
                      let tempLabor: number = parseFloat(d.value);
                      setLabor(tempLabor);

                      let tempMisc: number = calculateMiscCharge(
                        tempLabor,
                        parts,
                        miscellaneousCharge
                      );
                      setMisc(tempMisc);

                      let tempTax: number = calculateMiscChargeTax(
                        tempLabor,
                        parts,
                        tempMisc,
                        miscellaneousCharge.isTaxable,
                        settingsTaxRate.taxRate,
                        settingsTaxRate.saleTaxOption
                      );

                      setTax(tempTax);
                    }
                  }}
                  id="labor"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label className="myLabel" htmlFor="parts">
                  {t("estimate.parts", { ns: "tickets" })}:
                </label>
                <Input
                  fluid
                  iconPosition="left"
                  name="parts"
                  type="number"
                  icon="dollar"
                  placeholder={`${t("dollarPlaceholder", {
                    ns: "miscSettings",
                  })}`}
                  value={parts || undefined}
                  onInput={(e: any, d: any) => {
                    let value = e.target.value;
                    const arr = value.split(".");
                    if (arr.length === 2) {
                      if (arr[1].length > 2) {
                        value = arr[0] + "." + arr[1].substring(0, 2);
                        if (!isNaN(parseFloat(value))) {
                          setParts(Number(value));
                        }
                        e.target.value = value;
                      }
                    }
                  }}
                  onChange={(e, d) => {
                    if (!isNaN(parseFloat(d.value))) {
                      let tempParts: number = parseFloat(d.value);

                      setParts(tempParts);

                      let tempMisc: number = calculateMiscCharge(
                        labor,
                        tempParts,
                        miscellaneousCharge
                      );
                      setMisc(tempMisc);

                      let tempTax: number = calculateMiscChargeTax(
                        labor,
                        tempParts,
                        tempMisc,
                        miscellaneousCharge.isTaxable,
                        settingsTaxRate.taxRate,
                        settingsTaxRate.saleTaxOption
                      );

                      setTax(tempTax);
                    }
                  }}
                  id="parts"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label className="myLabel" htmlFor="miscCharge">
                  {t("misc_charges.miscCharge", { ns: "miscSettings" })}:
                </label>
                <Input
                  fluid
                  iconPosition="left"
                  name="miscCharge"
                  type="text"
                  icon="dollar"
                  placeholder={"0.00"}
                  value={formatDecimal(misc, 2) || undefined}
                  disabled={true}
                  id="miscCharge"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <label className="myLabel" htmlFor="tax">
                  {t("estimate.tax", { ns: "tickets" })}:
                </label>
                <Input
                  fluid
                  iconPosition="left"
                  name="tax"
                  type="text"
                  icon="dollar"
                  placeholder={"0.00"}
                  value={formatDecimal(tax, 2) || undefined}
                  disabled={true}
                  id="tax"
                />
              </Grid.Column>
            </Grid.Row>
          </Segment>
        )}
      </Form>
    </Container>
  );
});
