import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../app/stores/store";
import { formatPhoneNumber } from "../../../../app/common/util/functions";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";

interface Props {
  logoCenter: boolean;
  isReceipt?: boolean;
}

export default observer(function ClientPdfView({
  logoCenter,
  isReceipt,
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  const { clientStore } = useStore();

  const { selectedClientDetails } = clientStore;

  return (
    <View
      style={{
        ...compose(`view textAlignCenter`),
        width: `${logoCenter ? "60mm" : "80mm"}`,
      }}
    >
      <Text style={compose("fontSize16px")}>{selectedClientDetails.name}</Text>
      {!isReceipt && (
        <Text>{selectedClientDetails.additionalInformationHeader}</Text>
      )}
      <Text>{selectedClientDetails.addressLine1}</Text>
      <Text>{selectedClientDetails.addressLine2}</Text>
      <Text>
        {selectedClientDetails.city}, {selectedClientDetails.state}{" "}
        {selectedClientDetails.postalCode}
      </Text>
      {!isReceipt && (
        <>
          <Text>
            {selectedClientDetails.commercialNumber && (
              <>
                {t("phone", { ns: "common" })}:{" "}
                {formatPhoneNumber(selectedClientDetails.commercialNumber)}
              </>
            )}
          </Text>
          <Text>
            {selectedClientDetails.faxNumber && (
              <>
                {t("fax", { ns: "common" })}:{" "}
                {formatPhoneNumber(selectedClientDetails.faxNumber)}
              </>
            )}
          </Text>
          <Text style={{flexWrap:"nowrap"}} >
            {selectedClientDetails.email && (
                `${t("email", { ns: "common" })}: ${selectedClientDetails.email}`
            )}
          </Text>
          <Text>{selectedClientDetails.additionalInformationFooter}</Text>
        </>
      )}
    </View>
  );
});
