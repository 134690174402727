import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Grid, Header, List } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";

export default observer(function ServiceOverviewComponent() {
  const { t } = useTranslation(["common", "companySettings"]);

  const { statsStore } = useStore();

  const { statsOverview, loadStatsOverview } = statsStore;

  useEffect(() => {
    if (!statsOverview) {
      loadStatsOverview();
    }
  }, [statsOverview, loadStatsOverview]);

  return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h3" content="CarFax Stats: " />
              </List.Item>
              <List.Item>
                <b>Auth Pending: </b>
                {statsOverview?.carFaxPending}
              </List.Item>
              <List.Item>
                <b>Total Accounts: </b>
                {statsOverview?.carFaxTotalAccount}
              </List.Item>
              <List.Item>
                <b>Pending Data, Companies: </b>
                {statsOverview?.carFaxPendingClient}
              </List.Item>
              <List.Item>
                <b>Pending Data, Tickets: </b>
                {statsOverview?.carFaxPendingTickets}
              </List.Item>
              <List.Item>
                <b>Pending Data, Ticket Items: </b>
                {statsOverview?.carFaxPendingTicketsItems}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h3" content="Sign Up Stats: " />
              </List.Item>
              <List.Item>
                <b>Email Confirmations: </b>
                {statsOverview?.emailConfirmed}
              </List.Item>
              <List.Item>
                <b>Company Waiting For Review: </b>
                {statsOverview?.clientPendingReview}
              </List.Item>
              <List.Item>
                <b>Company Declined: </b>
                {statsOverview?.clientDeclined}
              </List.Item>
              <List.Item>
                <b>Company Approved No Login: </b>
                {statsOverview?.clientApprovedNoLogin}
              </List.Item>
              <List.Item>
                <b>Company Approved Active: </b>
                {statsOverview?.clientApprovedTotal}
              </List.Item>
              <List.Item>
                <b>Company Subscribed: </b>
                {statsOverview?.clientSubscribed}
              </List.Item>
              <List.Item>
                <b>Company Users Subscribed: </b>
                {statsOverview?.clientUsersSubscribed}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h3" content="System Stats: " />
              </List.Item>
              <List.Item>
                <b>Quotes: </b>
                All: {" "} {statsOverview?.quotesTotal}. Custom: {" "}
                {statsOverview?.quotesCustomTotal}
              </List.Item>
              <List.Item>
                <b>Finalized Invoices: </b>
                {statsOverview?.finalizedInvoices}
              </List.Item>
              <List.Item>
                <b>Custom Vehicles: </b>
                {statsOverview?.customVehicles}
              </List.Item>
              <List.Item>
                <b>Multiple Recurrings: </b>
                {statsOverview?.multipleRecurring}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h3" content="Company Stats: " />
              </List.Item>
              <List.Item>
                <b>Companies (Basic/Pro): </b>
                {statsOverview?.clientBasicTotal} / {" "}
                {statsOverview?.clientProTotal}
              </List.Item>
              <List.Item>
                <b>Users (Basic/Pro): </b>
                {statsOverview?.userBasicTotal} / {" "}
                {statsOverview?.userProTotal}
              </List.Item>
              <List.Item>
                <b>Total Extended Trials: </b>
                {statsOverview?.totalExtendedTrial}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <List>
              <List.Item>
                <Header as="h3" content="Admin Stats: " />
              </List.Item>
              <List.Item>
                <b>Site Admin Users: </b>
                {statsOverview?.internalStaffActive} / {" "} 
                {statsOverview?.internalStaff}
              </List.Item>
              <List.Item>
                <b>System Admin Users: </b>
                {statsOverview?.internalManagerActive} / {" "}
                {statsOverview?.internalManager}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid.Row>
      </Grid>
  );
});
