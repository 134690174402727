import React from "react";
import { observer } from "mobx-react-lite";
import { Dropdown } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default observer(function ReportsDropdownMenu() {
  const { t } = useTranslation(["common", "users"]);

  return (
    <Dropdown
      selection={true}
      placeholder={t("select_report", { ns: "reports" })}
      className="widerDropdownMenu"
    >
      <Dropdown.Menu>
        <Dropdown.Item
          id="reportsTotalSales"
          key={"totalSales"}
          as={Link}
          to={`/reports/totalSales`}
          text={t("total_sales.header", { ns: "reports" })}
          icon="dollar sign"
        />
        <Dropdown.Item
          id="reportsLaborSales"
          key={"laborSales"}
          as={Link}
          to={`/reports/laborSales`}
          text={t("labor_sales.header", { ns: "reports" })}
          icon="wrench"
        />
        <Dropdown.Item
          id="reportsPartSales"
          key={"partSales"}
          as={Link}
          to={`/reports/partSales`}
          text={t("part_sales.header", { ns: "reports" })}
          icon="car"
        />
        {/* <Dropdown.Item

          key={"miscSales"}
          as={Link}
          to={`/reports/inventory`}
          text={t("Inventory", { ns: "reports" })}
          icon="clipboard outline"
        /> */}
        <Dropdown.Item
          id="reportsMiscCharges"
          key={"miscCharges"}
          as={Link}
          to={`/reports/miscCharges`}
          text={t("misc_charge_sales.header", { ns: "reports" })}
          icon="plus square outline"
        />
        <Dropdown.Item
          id="reportsTax"
          key={"tax"}
          as={Link}
          to={"/reports/tax"}
          text={t("tax.header", { ns: "reports" })}
          icon="percent"
        />
        <Dropdown.Item
          id="reportsPayType"
          key={"payType"}
          as={Link}
          to={`/reports/payType`}
          text={t("pay_type.header", { ns: "reports" })}
          icon="tags"
        />
        <Dropdown.Item
          id="reportsTechnician"
          key={"technician"}
          as={Link}
          to={`/reports/technician`}
          text={t("techs.header", { ns: "reports" })}
          icon="users"
        />
        <Dropdown.Item
          id="reportsVendor"
          key={"vendor"}
          as={Link}
          to={`/reports/vendor`}
          text={t("vendor.header", { ns: "reports" })}
          icon="dolly"
        />
        <Dropdown.Item
          id="reportsCardTransaction"
          key={"cardTransaction"}
          as={Link}
          to={`/reports/cardTransaction`}
          text={t("cc_transaction.header", { ns: "reports" })}
          icon="credit card outline"
        />
        {/* <Dropdown.Item
          key={"accountsReceivable"}
          as={Link}
          to={`/reports/accountsReceivable`}
          text={t("Accounts Receivable", { ns: "reports" })}
          icon="money bill alternate outline"
        /> */}
      </Dropdown.Menu>
    </Dropdown>
  );
});
