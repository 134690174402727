import { useField } from "formik";
import React from "react";
import { Form, Label } from "semantic-ui-react";
import MyHelpButton from "./MyHelpButton";

interface Props {
  placeholder: string;
  name: string;
  label?: string;
  className?: string;
  showHelp?: boolean;
  helpName?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  myValidate?: (value: string) => void;
  helpSeeMore?: boolean;
  maxLength: number;
}

export default function WholeNumberInput({
  placeholder,
  name,
  label,
  className,
  showHelp,
  helpName,
  disabled,
  autoFocus,
  myValidate,
  helpSeeMore,
  maxLength,
}: Readonly<Props>) {
  const [field, meta] = useField(name);
  let labelClassName: string = "myLabel";
  let inputClassName: string = "input";
  if (className) labelClassName = `${labelClassName} ${className}`;
  if (className) inputClassName = `${inputClassName} ${className}`;

  return (
    <Form.Field error={meta.touched && !!meta.error} className="ui" name={name}>
      {label && label.length > 0 && (
        <label className={labelClassName} htmlFor={name}>
          {`${label} `}
          {showHelp && (
            <MyHelpButton
              name={`${name}.help`}
              help={helpName ?? ""}
              seeMore={helpSeeMore}
              color={"blue"}
            />
          )}
        </label>
      )}
      <input
        {...field}
        placeholder={placeholder}
        name={name}
        disabled={disabled}
        maxLength={maxLength}
        id={name}
        autoFocus={autoFocus ?? false}
        className={inputClassName}
        type="number"
        value={field.value ?? undefined}
        onInput={(e) => {
          const re = /^[0-9\b]+$/;
          if (e.currentTarget.value === "" || re.test(e.currentTarget.value)) {
            e.currentTarget.value = e.currentTarget.value;
          }
        }}
      />
      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
