export interface Vendor {
  id?: string;
  isActive: boolean;
  name: string;
  defaultMarkup: number;
  createdDate: Date;
  error?: string;
}

export class Vendor implements Vendor {
  constructor(init?: VendorsFormValues) {
    if (init) {
      this.id = init.id;
      this.name = init.name;
      this.defaultMarkup = Number(init.defaultMarkup);
      this.isActive = init.isActive.toString() === "true";
      this.error = init.error;
    }
  }
}


export class VendorsFormValues {
  id?: string = undefined;
  name: string = "";
  defaultMarkup: number = 0;
  isActive: boolean = false;
  error?: string = undefined;

  constructor(vendors?: VendorsFormValues) {
    if (vendors) {
      this.id = vendors.id;
      this.name = vendors.name;
      this.defaultMarkup = vendors.defaultMarkup;
      this.isActive = vendors.isActive;
    }
  }
}

export interface SettingsVendors {
  id?: string;
  enablePartMarkup: boolean;
  defaultMarkup: number;
  error?: string;
}
export class SettingsVendors implements SettingsVendors {
  constructor(init?: SettingsVendorsFormValues) {
    if (init) {
      this.id = init.id;
      this.enablePartMarkup =
        init.enablePartMarkup.toString() === "true";
      this.defaultMarkup = Number(init.defaultMarkup);
    }
  }
}
export class SettingsVendorsFormValues {
  id?: string = undefined;
  enablePartMarkup: boolean = false;
  defaultMarkup: number = 0;
  error?: string = undefined;

  constructor(SettingsVendors?: SettingsVendorsFormValues) {
    if (SettingsVendors) {
      this.id = SettingsVendors.id;
      this.enablePartMarkup = SettingsVendors.enablePartMarkup;
      this.defaultMarkup = SettingsVendors.defaultMarkup;
      this.error = SettingsVendors.error;
    }
  }
}
