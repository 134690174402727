import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import common_en from "./locales/en/common.json";
import errors_en from "./locales/en/errors.json";
import photos_en from "./locales/en/photos.json";
import profile_en from "./locales/en/profile.json";
import registration_en from "./locales/en/registration.json";
import corrections_en from "./locales/en/corrections.json";
import eula_en from "./locales/en/eula.json";
import privacy_en from "./locales/en/privacy.json";
import terms_en from "./locales/en/terms.json";
import settings_en from "./locales/en/settings.json";
import companySettings_en from "./locales/en/companySettings.json";
import pricingSettings_en from "./locales/en/pricingSettings.json";
import ticketSettings_en from "./locales/en/ticketSettings.json";
import disclaimerSettings_en from "./locales/en/disclaimerSettings.json";
import workflowSettings_en from "./locales/en/workflowSettings.json";
import miscSettings_en from "./locales/en/miscSettings.json";
import advancedSettings_en from "./locales/en/advancedSettings.json";
import reports_en from "./locales/en/reports.json";
import customer_en from "./locales/en/customer.json";
import search_en from "./locales/en/search.json";
import recentTickets_en from "./locales/en/recentTickets.json";
import abandoned_en from "./locales/en/abandoned.json";
import tickets_en from "./locales/en/tickets.json";
import systemAdmin_en from "./locales/en/systemAdmin.json";
import subscription_en from "./locales/en/subscription.json";
import users_en from "./locales/en/users.json";
import help_en from "./locales/en/help.json";
import alert_en from "./locales/en/alerts.json";
import common_es from "./locales/es/common.json";

export const resources = {
  en: {
    common: common_en,
    corrections: corrections_en,
    errors: errors_en,
    photos: photos_en,
    profile: profile_en,
    registration: registration_en,
    subscription: subscription_en,
    users: users_en,
    eula: eula_en,
    privacy: privacy_en,
    terms: terms_en,
    settings: settings_en,
    companySettings: companySettings_en,
    pricingSettings: pricingSettings_en,
    ticketSettings: ticketSettings_en,
    disclaimerSettings: disclaimerSettings_en,
    workflowSettings: workflowSettings_en,
    miscSettings: miscSettings_en,
    advancedSettings: advancedSettings_en,
    reports: reports_en,
    customer: customer_en,
    search: search_en,
    recentTickets: recentTickets_en,
    abandoned: abandoned_en,
    help: help_en,
    tickets: tickets_en,
    systemAdmin: systemAdmin_en,
    alerts: alert_en,
  },
  
  es: {
    common: common_es,
  },
} as const;

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",
    debug: true,
    resources,
    ns: [
      "common",
      "corrections",
      "errors",
      "subscription",
      "users",
      "registration",
      "profile",
      "eula",
      "privacy",
      "terms",
      "settings",
      "companySettings",
      "pricingSettings",
      "ticketSettings",
      "disclaimerSettings",
      "workflowSettings",
      "miscSettings",
      "advancedSettings",
      "reports",
      "customer",
      "search",
      "recentTickets",
      "abandoned",
      "help",
      "tickets",
      "alerts",
      "systemAdmin",
    ],

    defaultNS: "common",
    lng: navigator.language,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
