import { observer } from "mobx-react-lite";
import React from "react";
import {
  Card,
  Container,
  Divider,
  Icon,
  Item,
  List,
  Message,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PageHeader from "../../app/layout/PageHeader";
import { useStore } from "../../app/stores/store";
import { Application, ClientStatus } from "../../app/models/enums";

export default observer(function SettingsMain() {
  const { t } = useTranslation([
    "common",
    "users",
    "errors",
    "settings",
    "registration",
    "disclaimerSettings",
    "pricingSettings",
  ]);
  const { userStore } = useStore();
  const { user, checkStatus, checkApplication } = userStore;

  return (
    <>
      <PageHeader
        header={t("settings_header", { ns: "settings" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <>
        <Container>
          {user && user.status === ClientStatus.PreTrial && (
            <Message warning>
              {t("settingsMessage.activateAccount", { ns: "registration" })}
              <br />
              {t("settingsMessage.accountSettings", { ns: "registration" })}
              <br />
              <br />
              <strong>
                {t("settingsMessage.atMinimum", { ns: "registration" })}
              </strong>
              <List bulleted>
                <Item>
                  <strong>
                    {`${t("settingsMessage.pricingTab", {
                      ns: "registration",
                    })} `}
                  </strong>
                  <Link to={"/settings/pricing"}>
                    {`${t("pricing", { ns: "common" })}`}
                  </Link>
                  .
                </Item>
              </List>
              {t("settingsMessage.suggestion", { ns: "registration" })}
              <br />
              <List bulleted>
                <Item>
                  {`${t("settingsMessage.companyReview", {
                    ns: "registration",
                  })} `}
                  <Link to={"/settings/company"}>
                    {`${t("company", { ns: "common" })} `}
                  </Link>
                  {t("settingsMessage.companyReview2", { ns: "registration" })}
                </Item>
                <Item>
                  {`${t("settingsMessage.ticketReveiw", {
                    ns: "registration",
                  })} `}
                  <Link to={"/settings/ticket"}>
                    {t("ticket", { ns: "common" })}
                  </Link>
                  .
                </Item>
                <Item>
                  {`${t("settingsMessage.disclaimersReview", {
                    ns: "registration",
                  })} `}
                  <Link to={"/settings/disclaimers"}>
                    {t("disclaimers", { ns: "common" })}
                  </Link>
                  .
                </Item>
              </List>
            </Message>
          )}
          <Card.Group className="settings-header-cards">
            <Card href="/settings/company">
              <Card.Content>
                <Card.Header className="card_header">
                  <Icon name="briefcase" /> {t("company", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_description">
                  {t("company.intro", { ns: "settings" })}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card href="/settings/pricing">
              <Card.Content>
                <Card.Header className="card_header">
                  <Icon name="dollar sign" /> {t("pricing", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_description">
                  {t("pricing.intro", { ns: "pricingSettings" })}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card href="/settings/ticket">
              <Card.Content>
                <Card.Header className="card_header">
                  <Icon name="file alternate outline" />
                  {t("ticket", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_description">
                  {t("ticket.intro", { ns: "settings" })}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card href="/settings/disclaimers">
              <Card.Content>
                <Card.Header className="card_header">
                  <Icon name="exclamation triangle" />
                  {t("disclaimers", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_description">
                  {t("disclaimers.intro", { ns: "disclaimerSettings" })}
                  <br></br>
                  <span className="side_note">
                    {t("disclaimers.note", { ns: "disclaimerSettings" })}
                  </span>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card href="/settings/workFlow">
              <Card.Content>
                <Card.Header className="card_header">
                  <Icon name="sitemap" /> {t("workflow", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_description">
                  {t("workflow.intro", { ns: "settings" })}
                </Card.Description>
              </Card.Content>
            </Card>
            {checkStatus(ClientStatus.Trial | ClientStatus.Active) &&
              checkApplication(Application.RealTimeLaborGuidePro) && (
                <>
                  <Card href="/settings/techs">
                    <Card.Content>
                      <Card.Header className="card_header">
                        <Icon name="user circle" />
                        {t("technicians", { ns: "common" })}
                      </Card.Header>
                      <Card.Description className="card_description">
                        {t("techs.intro", { ns: "settings" })}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                  <Card href="/settings/vendors">
                    <Card.Content>
                      <Card.Header className="card_header">
                        <Icon name="dolly" />{" "}
                        {t("vendor.header", { ns: "settings" })}
                      </Card.Header>
                      <Card.Description className="card_description">
                        {t("vendor.intro", { ns: "settings" })}
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </>
              )}
            {checkApplication(Application.AutomotiveExpert) && (
              <>
                <Card href="/settings/inventory">
                  <Card.Content>
                    <Card.Header className="card_header">
                      <Icon name="clipboard outline" />{" "}
                      {t("inventory.header", { ns: "settings" })}
                    </Card.Header>
                    <Card.Description className="card_description">
                      {t("inventory.intro", { ns: "settings" })}
                    </Card.Description>
                  </Card.Content>
                </Card>
                <Card href="/settings/accountsReceivable">
                  <Card.Content>
                    <Card.Header className="card_header">
                      <Icon name="money bill alternate outline" />{" "}
                      {t("accountsReceivable.header", { ns: "settings" })}
                    </Card.Header>
                    <Card.Description className="card_description">
                      {t("accountsReceivable.intro", { ns: "settings" })}
                    </Card.Description>
                  </Card.Content>
                </Card>
              </>
            )}
            <Card href="/settings/misccharges">
              <Card.Content>
                <Card.Header className="card_header">
                  <Icon name="plus cart" />
                  {t("miscellaneouscharges", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_description">
                  {t("misc_charges.intro", { ns: "settings" })}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card href="/settings/packagejobs">
              <Card.Content>
                <Card.Header className="card_header">
                  <Icon name="box" />
                  {t("packaged_jobs.header", { ns: "settings" })}
                </Card.Header>
                <Card.Description className="card_description">
                  {t("packaged_jobs.intro", { ns: "settings" })}
                </Card.Description>
              </Card.Content>
            </Card>
            <Card href="/settings/advanced">
              <Card.Content>
                <Card.Header className="card_header">
                  <Icon name="cogs" /> {t("advanced", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_description">
                  {t("advanced.intro", { ns: "settings" })}
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>
        </Container>
        <Divider />
        <Container className="page">
          <Card.Group>
            <Card>
              <Card.Content>
                <Card.Header className="card_header">
                  {t("company", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_links">
                  <List>
                    <List.Item>
                      <Link to="/settings/company">
                        {t("details", { ns: "common" })}
                      </Link>
                    </List.Item>
                    <List.Item>
                      <Link to="/settings/companyLogo">
                        {t("company.logo", { ns: "settings" })}
                      </Link>
                    </List.Item>
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header className="card_header">
                  {t("pricing", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_links">
                  <List>
                    <List.Item>
                      <Link to="/settings/laborRates">
                        {t("laborrateoptions", { ns: "common" })}
                      </Link>
                    </List.Item>
                    <List.Item>
                      <Link to="/settings/salesTax">
                        {t("salestax", { ns: "common" })}
                      </Link>
                    </List.Item>
                    <List.Item>
                      <Link to="/settings/payTypes">
                        {t("paytypes", { ns: "common" })}
                      </Link>
                    </List.Item>
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header className="card_header">
                  {t("ticket", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_links">
                  <List>
                    <List.Item>
                      <Link to="/settings/formatting">
                        {t("formatting", { ns: "common" })}
                      </Link>
                    </List.Item>
                    <List.Item>
                      <Link to="/settings/numbers">
                        {t("numbers", { ns: "common" })}
                      </Link>
                    </List.Item>
                    <List.Item>
                      <Link to="/settings/inputDefaults">
                        {t("inputdefaults", { ns: "common" })}
                      </Link>
                    </List.Item>
                    <List.Item>
                      <Link to="/settings/ticketDefaults">
                        {t("ticketdefaults", { ns: "common" })}
                      </Link>
                    </List.Item>
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header className="card_header">
                  {t("disclaimers", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_links">
                  <List>
                    <List.Item>
                      <Link to="/settings/invoiceDisclaimers">
                        {t("disclaimers.li1", { ns: "disclaimerSettings" })}
                      </Link>
                    </List.Item>
                    <List.Item>
                      <Link to="/settings/estimateDisclaimers">
                        {t("disclaimers.li2", { ns: "disclaimerSettings" })}
                      </Link>
                    </List.Item>
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header className="card_header">
                  {t("workflow", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_links">
                  <List>
                    <List.Item>
                      <Link to="/settings/ticketPath">
                        {t("ticketpath", { ns: "common" })}
                      </Link>
                    </List.Item>
                    <List.Item>
                      <Link to="/settings/manufacturers">
                        {t("manufacturers", { ns: "common" })}
                      </Link>
                    </List.Item>
                    <List.Item>
                      <Link to="/settings/customVehicles">
                        {t("customvehicles", { ns: "common" })}
                      </Link>
                    </List.Item>
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
            <Card>
              <Card.Content>
                <Card.Header className="card_header">
                  {t("advanced", { ns: "common" })}
                </Card.Header>
                <Card.Description className="card_links">
                  <List>
                    <List.Item>
                      <Link to="/settings/quickvin">
                        {t("quickvin", { ns: "common" })}
                      </Link>
                    </List.Item>
                    {checkStatus(ClientStatus.Trial | ClientStatus.Active) &&
                      checkApplication(Application.RealTimeLaborGuidePro) && (
                        <>
                          <List.Item>
                            <Link to="/settings/import">
                              {t("import", { ns: "common" })}
                            </Link>
                          </List.Item>
                          <List.Item>
                            <Link to="/settings/paymentProcessing">
                              {t("paymentprocessing", { ns: "common" })}
                            </Link>
                          </List.Item>
                        </>
                      )}
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
          </Card.Group>
        </Container>
      </>
    </>
  );
});
