import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Header, Divider, Button, Table } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { formatCurrency } from "../../../app/common/util/functions";
import PageHeader from "../../../app/layout/PageHeader";
import { SubscriptionType, TermEnum } from "../../../app/models/enums";

export default observer(function ANSettle() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { authorizeNetReportStore } = useStore();
  const {
    loading,
    loadAuthorizeNetReport,
    calculateTotals,
    getVisaMcTransactions,
    getAmexTransactions,
    getDiscoverTransactions,
    getApprovedTransactions,
    getDeclinedTransactions,
    getVoidedTransactions,
    getRefundedTransactions,
    filterBySubscriptionTypeAll,
    filterBySubscriptionTypeAny,
  } = authorizeNetReportStore;

  const [startDate, setStartDate] = useState<Date>(new Date());

  const upgradeProTransactions = filterBySubscriptionTypeAll(getApprovedTransactions, SubscriptionType.Upgrade | SubscriptionType.Pro);
  const additionalUserTransactions = filterBySubscriptionTypeAny(getApprovedTransactions, SubscriptionType.AdditionalUsers);

  const apps = [
    { name: "Basic - Monthly", bitmask: SubscriptionType.Basic | SubscriptionType.Payment, term: TermEnum.Monthly },
    { name: "Basic - Quarterly", bitmask: SubscriptionType.Basic | SubscriptionType.Payment, term: TermEnum.Quarterly },
    { name: "Basic - Yearly", bitmask: SubscriptionType.Basic | SubscriptionType.Payment, term: TermEnum.Annual },
    { name: "Pro - Monthly", bitmask: SubscriptionType.Pro | SubscriptionType.Payment, term: TermEnum.Monthly },
    { name: "Pro - Quarterly", bitmask: SubscriptionType.Pro | SubscriptionType.Payment, term: TermEnum.Quarterly },
    { name: "Pro - Yearly", bitmask: SubscriptionType.Pro | SubscriptionType.Payment, term: TermEnum.Annual },
  ];

  const services = [
    {name: "Basic", bitmask: SubscriptionType.Basic },
    {name: "Pro", bitmask: SubscriptionType.Pro },
  ];

  const serviceTypes = [
    { name: "Subscriptions", bitmask: SubscriptionType.Payment },
    { name: "Upgrades", bitmask: SubscriptionType.Upgrade },
    { name: "AddUsers", bitmask: SubscriptionType.AdditionalUsers },
    { name: "Total", bitmask: SubscriptionType.Payment | SubscriptionType.Upgrade | SubscriptionType.AdditionalUsers },
  ];

  const serviceTypeTransactions: any[][] = [];

  services.forEach((service, sIndex) => {
    serviceTypeTransactions[sIndex] = [];

    serviceTypes.forEach((serviceType, stIndex) => {
      const transactions = filterBySubscriptionTypeAll(getApprovedTransactions, serviceType.bitmask | service.bitmask);
      serviceTypeTransactions[sIndex][stIndex] = transactions;
    });
  });

  const newRecurring = [
    { name: "New", bitmask: SubscriptionType.Create },
    { name: "Recurring", bitmask: SubscriptionType.Payment },
  ]

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <SemanticDatepicker
              name="startDate"
              format="MM/DD/YYYY"
              placeholder={t("start_date", { ns: "reports" })}
              value={startDate}
              onChange={(e, d) => {
                if (d.value && !Array.isArray(d.value)) {
                  let newDate = new Date(
                    d.value.getFullYear(),
                    d.value.getMonth(),
                    d.value.getDate()
                  );
                  setStartDate(newDate);
                }
              }}
            />
            <Button
              color="green"
              className="save_button"
              icon="check"
              content={t("get_report", { ns: "reports" })}
              onClick={() => {
                loadAuthorizeNetReport(startDate);
              }}
              loading={loading}
              disabled={loading}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h3"
              header={"Authorize.Net Daily Settlement"}
              className="modal-text-color"
              textAlign="left"
              divider={false}
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">Date</Table.HeaderCell>
                  <Table.HeaderCell>Transactions</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Charges</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width="8">{format(startDate, "P")}</Table.Cell>
                  <Table.Cell>{getApprovedTransactions.length}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(
                      calculateTotals(getApprovedTransactions.concat(getRefundedTransactions.map(r => { r.amount = 0 - r.amount; return r; }))).amount
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Transaction Types"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">
                    Transaction Type
                  </Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Count</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Charge
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getApprovedTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {getApprovedTransactions.length}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Declined
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getDeclinedTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {getDeclinedTransactions.length}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Void
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getVoidedTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {getVoidedTransactions.length}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Refunded
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getRefundedTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {getRefundedTransactions.length}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Card Transactions"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">Card Type</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Count</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Visa/MC
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getVisaMcTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {getVisaMcTransactions.length}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    American Express
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getAmexTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {getAmexTransactions.length}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Discover
                  </Table.Cell>
                  <Table.Cell>
                    {formatCurrency(
                      calculateTotals(getDiscoverTransactions).amount
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {getDiscoverTransactions.length}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Plan Type Breakdown"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">Plan Type</Table.HeaderCell>
                  <Table.HeaderCell>Count</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {apps.map((app) => {
                  const transactions = filterBySubscriptionTypeAll(
                    getApprovedTransactions,
                    app.bitmask,
                    app.term
                  );

                  return (
                    <Table.Row>
                      <Table.Cell width="8" className="intro">
                        {app.name}
                      </Table.Cell>
                      <Table.Cell>{transactions.length}</Table.Cell>
                      <Table.Cell textAlign="right">
                        {formatCurrency(calculateTotals(transactions).amount)}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Non-plan Transactions"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">Type</Table.HeaderCell>
                  <Table.HeaderCell>Count</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                <Table.Row>
                  <Table.Cell width="8" className="intro">
                    Upgrade to Pro
                  </Table.Cell>
                  <Table.Cell>{upgradeProTransactions.length}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(
                      calculateTotals(upgradeProTransactions).amount
                    )}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                <Table.Cell width="8" className="intro">
                    Additional Users
                  </Table.Cell>
                  <Table.Cell>{additionalUserTransactions.length}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {formatCurrency(
                      calculateTotals(additionalUserTransactions).amount
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Service Type Breakdown"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Service Type</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Sub Count</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Sub Amount</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Upgrade Count</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Upgrade Amount</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Add Users Count</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Add Users Amount</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Total Amount</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {services.map((service, sIndex) => (
                  <Table.Row key={sIndex}>
                    <Table.Cell className="intro">{service.name}</Table.Cell>
                    {serviceTypes.map((serviceType, stIndex) => {
                      const transactions =
                        serviceTypeTransactions[sIndex][stIndex];
                      return (
                        <>
                          {stIndex < serviceTypes.length - 1 && (
                            <Table.Cell textAlign="right">{transactions.length}</Table.Cell>
                          )}
                          <Table.Cell textAlign="right">
                            {formatCurrency(
                              calculateTotals(transactions).amount
                            )}
                          </Table.Cell>
                        </>
                      );
                    })}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Transaction New/Recurring Grouped"}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="8">Bucket</Table.HeaderCell>
                  <Table.HeaderCell>Count</Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">Amount</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {newRecurring.map((nr) => {
                  const transactions = filterBySubscriptionTypeAny(getApprovedTransactions, nr.bitmask);

                  return (
                    <Table.Row>
                      <Table.Cell width="8">{nr.name}</Table.Cell>
                      <Table.Cell>{transactions.length}</Table.Cell>
                      <Table.Cell textAlign="right">{formatCurrency(calculateTotals(transactions).amount)}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        <Divider />
      </Grid>
    </>
  );
});
