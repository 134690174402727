import { observer } from "mobx-react-lite";
import React from "react";
import { Card, Container, Divider, Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../app/layout/PageHeader";
import MyHelpButton from "../../app/common/form/MyHelpButton";

export default observer(function ReportsMain() {
  const { t } = useTranslation(["common", "users", "errors", "reports"]);

  return (
    <>
      <Grid columns={2}>
        <PageHeader
          header={t("reports", { ns: "reports" })}
          type={"h1"}
          divider={false}
          getAlerts={true}
          addTitle={true}
        />
        <MyHelpButton
          name="reports"
          help="reports"
          position="bottom left"
          color={"blue"}
          seeMore={true}
        />
      </Grid>
      <Divider />
      <Container className="page">
        <Card.Group className="reports-header-cards">
          <Card href="/reports/totalSales">
            <Card.Content>
              <Card.Header className="card_header">
                <Icon name="dollar sign" />
                {t("total_sales.header", { ns: "reports" })}
              </Card.Header>
              <Card.Description className="card_description">
                {t("total_sales.desc", { ns: "reports" })}
              </Card.Description>
            </Card.Content>
          </Card>
          <Card href="/reports/laborSales">
            <Card.Content>
              <Card.Header className="card_header">
                <Icon name="wrench" />
                {t("labor_sales.header", { ns: "reports" })}
              </Card.Header>
              <Card.Description className="card_description">
                {t("labor_sales.desc", { ns: "reports" })}
              </Card.Description>
            </Card.Content>
          </Card>
          <Card href="/reports/partSales">
            <Card.Content>
              <Card.Header className="card_header">
                <Icon name="car" />
                {t("part_sales.header", { ns: "reports" })}
              </Card.Header>
              <Card.Description className="card_description">
                {t("part_sales.desc", { ns: "reports" })}
              </Card.Description>
            </Card.Content>
          </Card>
          <Card href="/reports/miscCharges">
            <Card.Content>
              <Card.Header className="card_header">
                <Icon name="plus square outline" />
                {t("misc_charge_sales.header", { ns: "reports" })}
              </Card.Header>
              <Card.Description className="card_description">
                {t("misc_charge_sales.desc", { ns: "reports" })}
              </Card.Description>
            </Card.Content>
          </Card>
          <Card href="/reports/tax">
            <Card.Content>
              <Card.Header className="card_header">
                <Icon name="percent" /> {t("tax.header", { ns: "reports" })}
              </Card.Header>
              <Card.Description className="card_description">
                {t("tax.desc", { ns: "reports" })}
              </Card.Description>
            </Card.Content>
          </Card>
          <Card href="/reports/payType">
            <Card.Content>
              <Card.Header className="card_header">
                <Icon name="tags" /> {t("pay_type.header", { ns: "reports" })}
              </Card.Header>
              <Card.Description className="card_description">
                {t("pay_type.desc", { ns: "reports" })}
              </Card.Description>
            </Card.Content>
          </Card>
          <Card href="/reports/technician">
            <Card.Content>
              <Card.Header className="card_header">
                <Icon name="users" /> {t("technicians", { ns: "common" })}
              </Card.Header>
              <Card.Description className="card_description">
                {t("techs.desc", { ns: "reports" })}
              </Card.Description>
            </Card.Content>
          </Card>
          <Card href="/reports/vendor">
            <Card.Content>
              <Card.Header className="card_header">
                <Icon name="dolly" /> {t("vendor.header", { ns: "reports" })}
              </Card.Header>
              <Card.Description className="card_description">
                {t("vendor.desc", { ns: "reports" })}
              </Card.Description>
            </Card.Content>
          </Card>
          <Card href="/reports/cardTransaction">
            <Card.Content>
              <Card.Header className="card_header">
                <Icon name="credit card outline" />{" "}
                {t("cc_transaction.header", { ns: "reports" })}
              </Card.Header>
              <Card.Description className="card_description">
                {t("cc_transaction.desc", { ns: "reports" })}
              </Card.Description>
            </Card.Content>
          </Card>
        </Card.Group>
      </Container>
    </>
  );
});
