import { observer } from "mobx-react-lite";
import { Icon, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useEffect } from "react";
import { useStore } from "../../../app/stores/store";

interface Props {
  clientId: string;
}

export default observer(function ClientCompanyStatuses({ clientId }: Props) {
  const { t } = useTranslation(["common"]);
  const { clientSystemAdminStore } = useStore();

  const { loadClientCompanyStatus, getClientCompanyStatus } =
    clientSystemAdminStore;

  useEffect(() => {
    loadClientCompanyStatus(clientId);
  }, [loadClientCompanyStatus, clientId]);

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Status Type</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
          <Table.HeaderCell>End</Table.HeaderCell>
          <Table.HeaderCell>Active</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {getClientCompanyStatus && getClientCompanyStatus.length > 0 ? (
          getClientCompanyStatus.map((x) => (
            <Table.Row key={`client.${x.createdDate}`}>
              <Table.Cell>{x.statusType}</Table.Cell>
              <Table.Cell>{format(x.createdDate, "P")}</Table.Cell>
              <Table.Cell>{format(x.endDate, "P")}</Table.Cell>
              <Table.Cell>
                {x.isActive ? (
                  <Icon name="check" color="green" />
                ) : (
                  <Icon name="x" color="red" />
                )}
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noitems"}>
            <Table.Cell width="9" colSpan={9}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
});
