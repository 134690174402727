import { SettingsMakeList } from "../models/makeList";
import { requests } from "./Requests";

export const makeList = {

  listSettingsMakeFilters: () =>
    requests.get<SettingsMakeList[]>(
      `/SettingsMakeFilter/listSettingsMakeFilter/`
    ),

    toggleSettingsMakeFilter: (id: number) =>
    requests.post<void>(`/SettingsMakeFilter/toggleSettingsMakeFilter/${id}`, {}),

    toggleBatchSettingsMakeFilter: (isAllDisabled: boolean) =>
    requests.post<void>(`/SettingsMakeFilter/toggleBatchSettingsMakeFilter/${isAllDisabled}`, {}),
};
