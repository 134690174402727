import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { CustomerSignatureLine, TicketType } from "../../../app/models/enums";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../app/layout/compose";
import { formatCurrency } from "../../../app/common/util/functions";

interface Props {
  paymentMethod: string;
  showPaymentMethod: boolean;
  totalPaymentAmount: number;
  customerSignatureLine: CustomerSignatureLine;
  height: number;
  leftColumnWidth: number;
  rightColumnWidth: number;
}

export default observer(function TicketFooterPdfView({
  paymentMethod,
  showPaymentMethod,
  totalPaymentAmount,
  customerSignatureLine,
  height,
  leftColumnWidth,
  rightColumnWidth,
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  const { ticketSessionStore, disclaimerStore } = useStore();

  const { selectedTicketSession } = ticketSessionStore;

  const { selectedSettingsDisclaimer } = disclaimerStore;

  const [showPayMethod] = useState<boolean>(
    showPaymentMethod && paymentMethod != null && paymentMethod.length > 0
  );



  return (
    <View
      fixed
      style={{
        ...compose("pageWidth"),
        position: "absolute",
        bottom: 1,
        paddingLeft: "8mm",
        paddingRight: "8mm",
        minHeight: `${
          selectedTicketSession?.ticketType == TicketType.Estimate
            ? `${height + 7}mm`
            : `${height + 14}mm`
        }`,
      }}
    >
      <View
        style={{
          ...compose("flex pageWidth"),
        }}
      >
        <View
          style={{
            ...compose("view noMarginLeft fontSize6pt padding5pxRight"),
            flex: 1,
            minWidth: `${leftColumnWidth}mm`,
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          <Text>
            {selectedTicketSession?.ticketType === TicketType.Estimate
              ? selectedSettingsDisclaimer.estimateLeft
              : selectedSettingsDisclaimer.invoiceLeft}
          </Text>
        </View>
        <View
          style={{
            ...compose("view noMarginLeft fontSize6pt"),
            flex: 1,
            minWidth: `${rightColumnWidth}mm`,
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          <Text>
            {selectedTicketSession?.ticketType === TicketType.Estimate
              ? selectedSettingsDisclaimer.estimateRight
              : selectedSettingsDisclaimer.invoiceRight}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose("flex pageWidth"),
          alignItems: "flex-end",
        }}
      >
        <View
          style={{
            ...compose(
              "view noMarginLeft padding5pxRight lineHeight padding1mmTop width15"
            ),
            flex: 1,
            textAlign: "right",
          }}
        >
          <Text>{t("estimate.SIGNED", { ns: "tickets" })}</Text>
        </View>
        <View
          style={{
            ...compose(
              `view noPadding noMarginLeft  ${
                customerSignatureLine === CustomerSignatureLine.Underline
                  ? "borderSolidBottom"
                  : "writeBox"
              } lineHeight`
            ),
            flex: 1,
            width: "66mm",
            maxWidth: "66mm",
            marginRight: "1mm",
          }}
        ></View>
        <View
          style={{
            ...compose(
              "view noMarginLeft padding5pxRight lineHeight padding1mmTop"
            ),
            flex: 1,
            textAlign: "right",
            width: "10mm",
            maxWidth: "10mm",
          }}
        >
          <Text>{t("DATE", { ns: "common" })}</Text>
        </View>
        <View
          style={{
            ...compose(
              `view noMarginLeft ${
                customerSignatureLine === CustomerSignatureLine.Underline
                  ? "borderSolidBottom"
                  : "writeBox"
              }  lineHeight`
            ),
            flex: 1,
            width: "44mm",
            maxWidth: "44mm",
            marginRight: 15,
          }}
        ></View>
        <View
          style={{
            ...compose("view flex noMargin noPadding"),
            flex: 1,
            width: "50mm",
            //maxWidth: "50mm",
            justifyContent: "flex-end",
          }}
        >
          <View
            style={{
              ...compose(
                "view metaHead borderSolidTop borderSolidLeft borderSolidBottom"
              ),
              flex: 1,
              width: `${showPayMethod ? "31mm" : "25mm"}`,
              maxWidth: `${showPayMethod ? "31mm" : "25mm"}`,
              height: `${
                selectedTicketSession?.ticketType === TicketType.Invoice
                  ? "14mm"
                  : "7mm"
              }`,
              maxHeight: `${
                selectedTicketSession?.ticketType === TicketType.Invoice
                  ? "14mm"
                  : "7mm"
              }`,
            }}
          >
            {selectedTicketSession?.ticketType === TicketType.Invoice && (
              <View
                style={{
                  ...compose(
                    "view noMargin noPadding lineHeight borderSolidBottom"
                  ),
                  width: `${showPayMethod ? "31mm" : "25mm"}`,
                  maxWidth: `${showPayMethod ? "31mm" : "25mm"}`,
                }}
              >
                <Text
                  style={{
                    ...compose(
                      "text noMargin noPadding lineHeight padding1mmTop padding5pxRight"
                    ),
                    textAlign: "right",
                    width: `${showPayMethod ? "31mm" : "25mm"}`,
                    maxWidth: `${showPayMethod ? "31mm" : "25mm"}`,
                  }}
                >
                  {showPaymentMethod && paymentMethod}{" "}
                  {t("payment", { ns: "tickets" })}
                </Text>
              </View>
            )}
            <View
              style={{
                ...compose("view noMargin noPadding lineHeight"),
                width: `${showPayMethod ? "31mm" : "25mm"}`,
                maxWidth: `${showPayMethod ? "31mm" : "25mm"}`,
              }}
            >
              <Text
                style={{
                  ...compose(
                    "text noMargin noPadding lineHeight padding1mmTop padding5pxRight"
                  ),
                  textAlign: "right",
                  width: `${showPayMethod ? "31mm" : "25mm"}`,
                  maxWidth: `${showPayMethod ? "31mm" : "25mm"}`,
                }}
              >
                {selectedTicketSession?.ticketType === TicketType.Estimate
                  ? t("total", { ns: "common" })
                  : t("estimate.amountDue", { ns: "tickets" })}
              </Text>
            </View>
          </View>

          <View
            style={{
              ...compose("view borderSolid"),
              flex: 1,
              width: "24mm",
              maxWidth: "24mm",
              height: `${
                selectedTicketSession?.ticketType === TicketType.Invoice
                  ? "14mm"
                  : "7mm"
              }`,
              // maxHeight: `${
              //   selectedTicketSession?.ticketType === TicketType.Invoice
              //     ? "14mm"
              //     : "7mm"
              // }`,
            }}
          >
            {selectedTicketSession?.ticketType === TicketType.Invoice && (
              <View
                style={{
                  ...compose(
                    "view noMargin noPadding lineHeight borderSolidBottom"
                  ),
                  width: "24mm",
                  maxWidth: "24mm",
                }}
              >
                <Text
                  style={{
                    ...compose(
                      "text noMargin noPadding lineHeight padding1mmTop padding5pxRight"
                    ),
                    textAlign: "right",
                    width: "24mm",
                    maxWidth: "24mm",
                  }}
                >
                  {formatCurrency(totalPaymentAmount)}
                </Text>
              </View>
            )}
            <View
              style={{
                ...compose("view noMargin noPadding lineHeight"),
                width: "24mm",
                maxWidth: "24mm",
              }}
            >
              <Text
                style={{
                  ...compose(
                    "text noMargin noPadding lineHeight padding1mmTop padding5pxRight"
                  ),
                  textAlign: "right",
                  width: "24mm",
                  maxWidth: "24mm",
                }}
              >
                {selectedTicketSession?.ticketType === TicketType.Estimate
                  ? formatCurrency(selectedTicketSession?.totalAmount ?? 0.0)
                  : formatCurrency(selectedTicketSession?.amountDue ?? 0.0)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
});
