import { requests } from "./Requests";
import {
  SettingsPayType,
  SettingsPricing,
  SettingsTaxRate,
} from "../models/pricing";
import { DropdownItemProps } from "semantic-ui-react";
import { TimeType } from "../models/enums";
export const Pricing = {
  listSettingsLaborRatesDropdown: () =>
    requests.get<DropdownItemProps[]>(
      `/SettingsLaborRates/listSettingsLaborRatesDropdown/`
    ),

  getSettingsPayTypes: () =>
    requests.get<SettingsPayType>(`/SettingsPayTypes/getsettingsPayType/`),
  listSettingsPayTypes: (includePaymentProcessors: boolean) =>
    requests.get<SettingsPayType[]>(`/SettingsPayTypes/listSettingsPayType/${includePaymentProcessors}`),
  dropdownListSettingsPayTypes: () =>
    requests.get<DropdownItemProps[]>(
      `/SettingsPayTypes/dropDownlistSettingsPayType/`
    ),
  addSettingsPayTypes: (values: SettingsPayType) =>
    requests.post<void>("/SettingsPayTypes/addsettingsPayType", values),
  updateSettingsPayTypes: (values: SettingsPayType) =>
    requests.put<void>(
      `/SettingsPayTypes/updatesettingsPayType/${values.id}`,
      values
    ),
  toggleSettingsPayTypes: (id: string) =>
    requests.put<void>(`/SettingsPayTypes/toggleSettingsPayTypes/${id}`, {}),
  removeSettingsPayTypes: (id: string) =>
    requests.del<void>(`/SettingsPayTypes/removesettingsPayType/${id}`),

  getSettingsPricing: () =>
    requests.get<SettingsPricing>(`/SettingsPricing/getSettingsPricing/`),
  getActiveLaborRates: () =>
    requests.get<TimeType>(`/settingsPricing/getActiveLaborRates/`),
  addSettingsPricing: (values: SettingsPricing) =>
    requests.post<void>("/SettingsPricing/addSettingsPricing", values),
  updateSettingsPricing: (values: SettingsPricing) =>
    requests.put<void>(
      `/SettingsPricing/updateSettingsPricing/${values.id}`,
      values
    ),

  getSettingsTaxRate: () =>
    requests.get<SettingsTaxRate>(`/SettingsPricing/getSettingsTaxRate/`),
  addSettingsTaxRate: (values: SettingsTaxRate) =>
    requests.post<void>("/SettingsPricing/addSettingsTaxRate", values),
  updateSettingsTaxRate: (values: SettingsTaxRate) =>
    requests.put<void>(
      `/SettingsPricing/updateSettingsTaxRate/${values.id}`,
      values
    ),
};
