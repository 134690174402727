import axios from "axios";
import { PaginatedResult } from "../../models/pagination";
import {
  ClientUsers,
  UserDetails,
} from "../../models/systemAdmin";
import { requests, responseBody } from "../Requests";

export const SystemAdminUsers = {
  getUserList: (params: URLSearchParams, abortController: AbortController) =>
    axios
      .get<PaginatedResult<ClientUsers[]>>(`/SystemAdminUser/listUsers`, {
        params,
        signal: abortController.signal
      })
      .then(responseBody),
  getUserDetails: (id: string) =>
    requests.get<UserDetails>(`/SystemAdminUser/getUserDetails/${id}`),

  toggleByPassLocationAuthorization: (id?: string) =>
    requests.put<void>(`/SystemAdminUser/toggleBypassAuthCountry/${id}`, {}),
  toggleByPassAdditionalAuthorization: (id?: string) =>
    requests.put<void>(`/SystemAdminUser/toggleAdditionalAuthCheck/${id}`, {}),
};
