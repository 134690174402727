import React from "react";
import { observer } from "mobx-react-lite";
import { LogoPlacement, TicketType } from "../../../app/models/enums";
import { convertTicketTypeToString } from "../../../app/common/util/functions";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../app/layout/compose";
import ClientPdfView from "./TicketViewComponents/ClientPdfView";
import LogoView from "./TicketViewComponents/LogoView";
import TicketDetailPdfView from "./TicketViewComponents/TicketDetailPdfView";
import CustomerPdfView from "./TicketViewComponents/CustomerPdfView";
import VehiclePdfView from "./TicketViewComponents/VehiclePdfView";
import TicketAmountPdfView from "./TicketViewComponents/TicketAmountPdfView";
import EstimateDetailsPdfView from "./TicketViewComponents/EstimateDetailsPdfView";
import {
  SettingsTicketFormattingFormValues,
  SettingsTicketInputDefaults,
  TicketSession,
} from "../../../app/models/ticket";
import { useTranslation } from "react-i18next";

interface Props {
  paymentMethod: string;
  selectedTicketSession: TicketSession;
  selectedSettingsTicketFormatting: SettingsTicketFormattingFormValues;
  selectedSettingsTicketInputDefaults: SettingsTicketInputDefaults;
}

export default observer(function TicketHeaderPdf({
  paymentMethod,
  selectedTicketSession,
  selectedSettingsTicketFormatting,
  selectedSettingsTicketInputDefaults
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);
  
  return (
    <View fixed>
      <View
        style={{
          ...compose("flex pageWidth"),
        }}
      >
        <View style={{...compose("flex"), width:"140mm"}}>
        {selectedSettingsTicketFormatting.logoPlacement ===
        LogoPlacement.Center ? (
          <>
            <ClientPdfView
              logoCenter={
                selectedSettingsTicketFormatting.logoPlacement ===
                LogoPlacement.Center
              }
            />
            <LogoView
              logoCenter={
                selectedSettingsTicketFormatting.logoPlacement ===
                LogoPlacement.Center
              }
            />
          </>
        ) : (
          <>
            <LogoView
              logoCenter={
                selectedSettingsTicketFormatting.logoPlacement ===
                LogoPlacement.Center
              }
            />
            <ClientPdfView
              logoCenter={
                selectedSettingsTicketFormatting.logoPlacement ===
                LogoPlacement.Center
              }
            />
          </>
        )}
        </View>
        <TicketDetailPdfView
          showEstimateNumber={
            selectedSettingsTicketFormatting.showEstimateNumber
          }
          showPoNumber={selectedSettingsTicketFormatting.showPONumber}
          isRepairOrder={false}
        />
      </View>
      <View
        style={{
          ...compose(
            "view pageWidth flex dividerBar noPadding noMarginTop marginBottom1mm"
          ),
          position: "relative",
        }}
      >
        <Text
          style={{
            ...compose("text invoiceText"),
            flex: 1,
            alignSelf: "center",
          }}
        ></Text>
        <Text
          style={{
            ...compose("text invoiceText"),
            flex: 1,
            alignSelf: "center",
            marginLeft: "10mm",
          }}
        >
          {convertTicketTypeToString(selectedTicketSession?.ticketType ?? 0)}
        </Text>
        <Text
          style={{
            ...compose("text fontSize6pt textAlignRight"),
            flex: 1,
            alignSelf: "flex-end",
            color: "#fff",
            marginBottom: "2.5mm",
            marginRight: "2mm",
          }}
          render={({ pageNumber, totalPages }) =>
            `${t("page", { ns: "tickets" })} ${pageNumber} ${t("of", { ns: "tickets" })} ${totalPages}`
          }
        />
      </View>
      <View
        style={{
          ...compose("flex pageWidth"),
        }}
      >
        <CustomerPdfView settingsTicketInputDefaults={selectedSettingsTicketInputDefaults} />
        <VehiclePdfView />
        <TicketAmountPdfView />
      </View>
      {selectedTicketSession?.ticketType === TicketType.Estimate && (
        <View
          style={{
            ...compose("view pageWidth marginTop noMarginLeftRight"),
            height: "15mm",
            maxHeight: "15mm",
          }}
        >
          <EstimateDetailsPdfView paymentMethod={paymentMethod} settingsTicketInputDefaults={selectedSettingsTicketInputDefaults} />
        </View>
      )}
    </View>
  );
});
