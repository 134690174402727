import { requests } from "./Requests";
import { SettingsTechnician, Technician } from "../models/technician";
import { DropdownItemProps } from "semantic-ui-react";

export const technician = {
  listTechnician: () =>
    requests.get<Technician[]>(`/Technician/listTechnician/`),
  reportDropdownListTechnician: () =>
    requests.get<DropdownItemProps[]>(
      `/Technician/reportDropdownListTechnician/`
    ),
  addTechnician: (values: Technician) =>
    requests.post<void>("/Technician/AddTechnician", values),
  toggleTechnician: (id: string) =>
    requests.put<void>(`/Technician/toggle/${id}`, {}),
  removeTechnician: (id?: string) =>
    requests.put<void>(`/Technician/removeTechnician/${id}`, {}),
  updateTechnician: (values: Technician) =>
    requests.put<void>(`/Technician/updateTechnician/${values.id}`, values),

  getSettingsTechnician: () =>
    requests.get<SettingsTechnician>(
      `/SettingsTechnician/getSettingsTechnician/`
    ),
  addSettingsTechnician: (values: SettingsTechnician) =>
    requests.post<void>("/SettingsTechnician/addSettingsTechnician", values),
  updateSettingsTechnician: (values: SettingsTechnician) =>
    requests.put<void>(
      `/SettingsTechnician/updateSettingsTechnician/${values.id}`,
      values
    ),
  dropdownListTechnician: () =>
    requests.get<DropdownItemProps[]>(`/Technician/dropdownListTechnician/`),
};
