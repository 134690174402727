import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Container,
  Form,
  Grid,
  Message,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../app/layout/PageHeader";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import UpdatePackageJobs from "./UpdatePackageJobs";
import ConfirmDelete from "../../app/common/form/ConfirmDelete";
import SettingsNavBar from "../settings/SettingsNavBar";
import LoadingComponent from "../../app/layout/LoadingComponent";
import MyHelpButton from "../../app/common/form/MyHelpButton";
import SettingsDropdownMenuMobile from "../settings/SettingsDropdownMenuMobile";

export default observer(function PackageJobs() {
  const { t } = useTranslation(["common", "miscSettings"]);

  const { packageJobStore, modalStore } = useStore();
  const {
    loadPackageJobs,
    togglePackageJobs,
    setSelectedPackageJobs,
    getPackageJobs,
    removePackageJobs,
    loadingInitial,
  } = packageJobStore;

  useEffect(() => {
    loadPackageJobs();
  }, [loadPackageJobs]);

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("packageJobs_loading", { ns: "settings" })}
      />
    );

  return (
    <Grid stackable>
      <Grid.Column width={3} className="mobileHeight">
        <SettingsNavBar />
        <SettingsDropdownMenuMobile />
      </Grid.Column>
      <Grid.Column width={13}>
        <PageHeader
          header={t("packages.intro", { ns: "miscSettings" })}
          type={"h1"}
          divider={true}
          getAlerts={true}
          addTitle={true}
        />

        <Container className="page">
          <Message info>
            <Message.Header>
              <MyHelpButton
                name="company"
                help="settings.packageJobs"
                position="bottom left"
                color={"blue"}
                seeMore={true}
              />
              {t("packages.banner", { ns: "miscSettings" })}
            </Message.Header>
          </Message>
          <Form className="ui form error" autoComplete="off">
            <Table striped unstackable className="dnxTable">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="left"></Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("name", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("packages.items", { ns: "miscSettings" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("created", { ns: "miscSettings" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("status", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right"></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {getPackageJobs && getPackageJobs.length > 0 ? (
                  getPackageJobs.map((packagejobs, index) => (
                    <Table.Row key={`packageJob.${packagejobs.id}`}>
                      <Table.Cell className="textAlignLeft">
                        <Button
                          color="grey"
                          icon="edit"
                          type="button"
                          onClick={() => {
                            setSelectedPackageJobs(packagejobs);
                            modalStore.openModal(
                              <UpdatePackageJobs
                                name={"updatePackageJob"}
                                header={t("packages.editPackageJob", {
                                  ns: "miscSettings",
                                })}
                                isAdd={false}
                                id={packagejobs.id ? packagejobs.id : ""}
                              />
                            );
                          }}
                        ></Button>
                      </Table.Cell>
                      <Table.Cell data-label={`${t("name", { ns: "tickets" })}:`}>{packagejobs.name}</Table.Cell>
                      <Table.Cell data-label={`${t("items", { ns: "tickets" })}:`}>{packagejobs.description}</Table.Cell>
                      <Table.Cell data-label={`${t("created", { ns: "miscSettings" })}:`}>
                        {packagejobs.createdDate &&
                          format(new Date(packagejobs.createdDate), "PP")}
                      </Table.Cell>
                      <Table.Cell data-label={`${t("status", { ns: "common" })}:`}>
                        <Button
                          onClick={() =>
                            togglePackageJobs(packagejobs.id!.toString())
                          }
                          basic
                          color={packagejobs.isEnabled ? "green" : "red"}
                        >
                          {packagejobs.isEnabled
                            ? t("enabled", { ns: "miscSettings" })
                            : t("disabled", { ns: "miscSettings" })}
                        </Button>
                      </Table.Cell>

                      <Table.Cell className="textAlignRight">
                        <Button
                          color="grey"
                          icon="trash"
                          type="button"
                          onClick={() =>
                            modalStore.openModal(
                              <ConfirmDelete
                                header={t("packages.removePackageJob", {
                                  ns: "miscSettings",
                                })}
                                value={packagejobs.id ? packagejobs.id : ""}
                                text={packagejobs.name}
                                deleteValue={removePackageJobs}
                              />
                            )
                          }
                        ></Button>
                      </Table.Cell>
                    </Table.Row>
                  ))
                ) : (
                  <Table.Row key={"noitems"}>
                    <Table.Cell width="8" colSpan={8}>
                      <Message
                        color="yellow"
                        className="textAlignCenter fontStyleItalic"
                      >
                        {t("grid_noItems", { ns: "common" })}
                      </Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Form>
        </Container>
      </Grid.Column>
    </Grid>
  );
});
