import { observer } from "mobx-react-lite";
import { Message, Table } from "semantic-ui-react";
import { formatCurrency, formatDecimal } from "../../app/common/util/functions";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import { TicketItemSearch } from "../../app/models/search";

interface Props {
  id: string;
}

export default observer(function TicketItemSearch({ id }: Props) {
  const { t } = useTranslation(["common", "search"]);

  const { searchStore } = useStore();

  const { loadTicketItem, filterTicketItemsByTicketId } = searchStore;
  const [ticketItems, setTicketItems] = useState<TicketItemSearch[]>([]);

  useEffect(() => {
    loadTicketItem(id).then(() => {
      setTicketItems(filterTicketItemsByTicketId(id));
    });
  }, [loadTicketItem, setTicketItems, filterTicketItemsByTicketId, id]);

  return (
    <Table.Row>
      <Table.Cell colSpan={4} className="tableInTable textAlignRightNotMobile">
        <Table className="no_padding dnxTable" striped unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t("part_number", { ns: "reports" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("description", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {t("estimate.hr_qt", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {t("estimate.ea_rate", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                {t("total", { ns: "common" })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {ticketItems.length > 0 ? (
              ticketItems.map((x) => (
                <Table.Row key={x.id}>
                  <Table.Cell
                    data-label={`${t("part_number", { ns: "reports" })}:`}
                  >
                    {x.partCode}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("description", { ns: "common" })}:`}
                  >
                    {x.description}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    data-label={`${t("estimate.hr_qt", { ns: "tickets" })}:`}
                  >
                    {formatDecimal(x.quantity, 2)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    data-label={`${t("estimate.ea_rate", { ns: "tickets" })}:`}
                  >
                    {formatCurrency(x.rate ?? 0)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    data-label={`${t("total", { ns: "common" })}:`}
                  >
                    {formatCurrency(x.subTotal)}
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row key={"noItems"}>
                <Table.Cell colSpan={5} className="noPaddingLeft">
                  <Message
                    color="yellow"
                    className="textAlignCenter fontStyleItalic"
                  >
                    {t("grid_noItems", { ns: "common" })}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Table.Cell>
    </Table.Row>
  );
});
