import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Container,
  Form,
  Grid,
  Label,
  Message,
  Radio,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import {
  defaultTicketOptions,
  workFlowOptions,
} from "../../../app/common/options/option";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { SettingsWorkflowFormValues } from "../../../app/models/ticket";
import ValidationErrors from "../../errors/ValidationErrors";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyHelpButton from "../../../app/common/form/MyHelpButton";

export default observer(function TicketPath() {
  const { t } = useTranslation(["common", "workflowSettings"]);

  const { ticketStore } = useStore();

  const {
    loadSettingsWorkflow,
    createSettingsWorkflow,
    updateSettingsWorkflow,
    loadingInitial,
  } = ticketStore;

  const validationSchema = Yup.object({
    isCustomerInfoFirst: Yup.boolean().required(),
    defaultTicketType: Yup.boolean().required(),
  });

  const [settingsWorkflow, setSettingsWorkflow] =
    useState<SettingsWorkflowFormValues>(new SettingsWorkflowFormValues());

  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadValues();
  }, [loadSettingsWorkflow]);

  function loadValues() {
    loadSettingsWorkflow().then((workflow) =>
    setSettingsWorkflow(new SettingsWorkflowFormValues(workflow))
    );
  }
  function handleFormSubmit(
    values: SettingsWorkflowFormValues,
    setErrors: (errors: FormikErrors<SettingsWorkflowFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsWorkflow(values)
      : () => createSettingsWorkflow(values);

    action()
      .then(() => {
        loadValues();
        setSubmitting(false);
        setCompleted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("ticketPath_loading", { ns: "workflowSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("ticketpath", { ns: "common" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsWorkflow}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
              <Grid columns={2}>
                <Grid.Column>
                  <label className="required">
                    {`${t("ticket_path.opt1_custinfo", {
                      ns: "workflowSettings",
                    })}:`}
                  </label>
                  <MyHelpButton
                    help="settings.workflow.enterCustomerInfo"
                    position="bottom left"
                    name="ticketpath"
                    color={"blue"}
                  />
                  <Form.Group inline>
                    {workFlowOptions.map((data, index) => (
                      <Form.Field key={`isCustomerInfoFirst[${data.value}]`}>
                        <Radio
                          id={`isCustomerInfoFirst[${index}]`}
                          key={`isCustomerInfoFirst[${data.value}]`}
                          value={data.value}
                          name="isCustomerInfoFirst"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={
                            values.isCustomerInfoFirst.toString() === data.value
                          }
                        />
                      </Form.Field>
                    ))}
                    {touched.isCustomerInfoFirst &&
                      errors.isCustomerInfoFirst && (
                        <Label basic color="red">
                          {errors.isCustomerInfoFirst}
                        </Label>
                      )}
                  </Form.Group>
                  {touched.isCustomerInfoFirst &&
                    errors.isCustomerInfoFirst && (
                      <Label basic color="red">
                        {errors.isCustomerInfoFirst}
                      </Label>
                    )}
                </Grid.Column>
                <Grid.Column>
                  <label className="required">
                    {`${t("ticket_path.opt2_default", {
                      ns: "workflowSettings",
                    })}:`}
                  </label>
                  <MyHelpButton
                    help="settings.workflow.defaultTicketType"
                    position="bottom right"
                    name="ticketpath"
                    color={"blue"}
                  />

                  <Form.Group inline>
                    {defaultTicketOptions.map((data, index) => (
                      <Form.Field key={`defaultTicketType[${data.value}]`}>
                        <Radio
                          id={`defaultTicketType[${index}]`}
                          key={`defaultTicketType[${data.value}]`}
                          value={data.value}
                          name={"defaultTicketType"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={
                            values.defaultTicketType.toString() ===
                            data.value.toString()
                          }
                        />
                      </Form.Field>
                    ))}
                  </Form.Group>
                  {touched.defaultTicketType && errors.defaultTicketType && (
                    <Label basic color="red">
                      {errors.defaultTicketType}
                    </Label>
                  )}
                </Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color="green"
                      className="save_button"
                      icon="check"
                      content={t("save", { ns: "common" })}
                      disabled={isSubmitting || !dirty || !isValid}
                      loading={isSubmitting}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/settings"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
