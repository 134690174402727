import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Grid, Icon, Table, Menu } from "semantic-ui-react";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";

export default observer(function NoLogins() {
  const { t } = useTranslation(["common", "systemAdmin"]);

  const { systemAdminReportStore } = useStore();

  const { 
    getClientNoLogin, 
    loadClientNoLogin,     
    setPagingParams,
    pagingParams,
    pagination, 
  } = systemAdminReportStore;

  useEffect(() => {
    loadClientNoLogin();
  }, [loadClientNoLogin]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadClientNoLogin();
    }
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={t("reports.noLogins_intro", {
                ns: "systemAdmin",
              }).toString()}
              className="modal-text-color"
              textAlign="left"
              divider
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Column stackable>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("reports.comp_id", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("reports.created", { ns: "systemAdmin" })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {getClientNoLogin.map((x) => (
                <Table.Row>
                  <Table.Cell>{x.clientId}</Table.Cell>
                  <Table.Cell>{format(x.createdDate, "Pp")}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.Cell colSpan="6">
                  <MyPaging
                    itemCount={pagination ? pagination.totalItems : 10}
                    pageSize={pagingParams.pageSize}
                    currentPage={pagination ? pagination.currentPage : 1}
                    onClick={listOnClick}
                    onChange={listOnClick}
                    floated="right"
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
});
