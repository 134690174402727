import { makeAutoObservable } from "mobx";
export default class SettingsStore {
  loadingInitial = false;
  activeTab: number = 0;

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  constructor() {
    makeAutoObservable(this);
  }
}
