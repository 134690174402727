import { requests } from "./Requests";
import {
  User,
  UserFormValues,
  UserChangePassword,
  UserForgotPassword,
  UserLogin,
  SignUpFormValues,
  RegistrationDto,
  StaffUserList,
  UserForm,
} from "../models/user";
import { DropdownItemProps } from "semantic-ui-react";
import { Application } from "../models/enums";

export const Account = {
  current: () => requests.get<User>("/account"),
  login: (user: UserLogin) => requests.post<User>("/account/login", user),
  logout: () => requests.post<void>("/account/logout", {}),
  signUp: (signup: SignUpFormValues, token: string) =>
    requests.post<User>(`/account/signUpStep2?reCaptchaToken=${token}`, signup),
  addUser: (user: UserForm) => requests.post<void>(`/user/addUser`, user),
  updateUser: (user: UserForm) => requests.put<void>(`/user/updateUser`, user),
  toggleUser: (id: string) => requests.put<void>(`/user/toggleUser/${id}`, {}),
  fbLogin: (accessToken: string) =>
    requests.post<User>(`/account/fbLogin?accessToken=${accessToken}`, {}),
  refreshToken: () => requests.post<User>("/account/refreshToken", {}),
  verifyEmail: (token: string, email: string) =>
    requests.post<string>(
      `/account/verifyEmail?token=${token}&email=${email}`,
      {}
    ),
  resendEmailConfirm: () =>
    requests.get(`/account/resendEmailConfirmationLink`),
  resendEmailConfirmWithUserId: (id: string) =>
    requests.get(`/account/resendEmailConfirmationLink/${id}`),
  changePassword: (user: UserChangePassword) =>
    requests.post<void>("/account/changePassword", user),
  ForgotPassword: (user: UserForgotPassword) =>
    requests.post<void>("/email/ForgotPasswordEmail", user),
  ResetPassword: (user: UserChangePassword) =>
    requests.post<void>("/account/ResetPassword", user),
  userList: () => requests.get<StaffUserList[]>("/user/listApplicationUsers"),
  getUser: (id: string) => requests.get<UserFormValues>(`/user/getUser/${id}`),
  roleList: () => requests.get<DropdownItemProps[]>("/UserRole/RoleList"),
  getUsernameSuggestion: (signup: SignUpFormValues) =>
    requests.post<RegistrationDto>("/account/signUpStep1", signup),
  emailVerified: (userId: string) =>
    requests.get<boolean>(`/account/emailVerified/${userId}`),
  activateTrial: (application: Application, isExtension: boolean) =>
    requests.post<User>(`/account/activateTrial/${application}/${isExtension}`, {}),
  checkUsername: (userName: string) =>
    requests.post<void>(`/user/CheckUserNames/${userName}`, {}),
  checkUsernamePlus: (userName: string, id?: string) =>
    requests.post<void>(`/user/CheckUserNamesWithId/${userName}/${id}`, {}),
  checkEmail: (email: string) =>
    requests.post<void>(`/user/CheckEmail/`, {email}),
  checkEmailPlus: (email: string, id?: string) =>
    requests.post<void>(`/user/CheckEmailWithId/${id}`, {email}),
  getUserAlerts: () => requests.get<string[]>("/user/getAlerts/"),
  listStaffUsers: () => requests.get<DropdownItemProps[]>("/user/listStaffUsers")
};

