import {
  DateRangeOption,
  PaymentStatus,
  ReportType,
  TicketItemType,
  TransactionType,
} from "./enums";

export interface IndividualTypeSales {
  description?: string;
  ticketCount?: number;
  quantity?: number;
  rate?: number;
  revenue: number;
  partCode?: string;
}
export class IndividualTypeSales implements IndividualTypeSales {
  constructor(init?: IndividualTypeSales) {
    if (init) {
      this.description = init.description;
      this.ticketCount = Number(init.ticketCount);
      this.quantity = Number(init.quantity);
      this.rate = Number(init.rate);
      this.revenue = Number(init.revenue);
      this.partCode = init.partCode;
    }
  }
}

export interface TaxReport {
  date: string;
  totalLabor: number;
  totalParts: number;
  totalMisc: number;
  grossSales: number;
  nonTaxable: number;
  taxable: number;
  totalTax: number;
}
export class TaxReport implements TaxReport {
  constructor(init?: TaxReport) {
    if (init) {
      this.date = init.date;
      this.totalLabor = Number(init.totalLabor);
      this.totalParts = Number(init.totalParts);
      this.totalMisc = Number(init.totalMisc);
      this.grossSales = Number(init.grossSales);
      this.nonTaxable = Number(init.nonTaxable);
      this.taxable = Number(init.taxable);
      this.totalTax = Number(init.totalTax);
    }
  }
}

export interface PayTypeReport {
  date?: Date;
  payType?: string;
  invoiceNumber?: number;
  isCommercial?: boolean;
  businessName?: string;
  firstName?: string;
  lastName?: string;
  description?: string;
  amount: number;
  amountDue: number;
}
export class PayTypeReport implements PayTypeReport {
  constructor(init?: PayTypeReport) {
    if (init) {
      this.date = init.date;
      this.payType = init.payType;
      this.invoiceNumber = init.invoiceNumber;
      this.isCommercial = init.isCommercial;
      this.businessName = init.businessName;
      this.firstName = init.firstName;
      this.lastName = init.lastName;
      this.description = init.description;
      this.amount = Number(init.amount);
      this.amountDue = Number(init.amountDue)
    }
  }
}

export interface TechnicianReport {
  date?: Date;
  invoiceNumber?: number;
  technicianId?: string;
  firstName?: string;
  lastName?: string;
  description?: string;
  type?: TicketItemType;
  quantity: number;
  rate: number;
  subTotal: number;
}
export class TechnicianReport implements TechnicianReport {
  constructor(init?: TechnicianReport) {
    if (init) {
      this.date = init.date;
      this.invoiceNumber = init.invoiceNumber;
      this.technicianId = init.technicianId;
      this.lastName = init.lastName;
      this.lastName = init.lastName;
      this.description = init.description;
      this.type = init.type;
      this.quantity = Number(init.quantity);
      this.rate = Number(init.rate);
      this.subTotal = Number(init.subTotal);
    }
  }
}

export interface VendorReport {
  vendorId?: string;
  vendor: string;
  date: Date;
  invoiceNumber: number;
  partCode: string;
  description: string;
  markup: number;
  unitPrice: number;
  quantity: number;
  rate: number;
  costTotal: number;
  subTotal: number;
  profit: number;
  lowestMarkup: number;
  highestMarkup: number;
  totalMarkup: number;
}
export class VendorReport implements VendorReport {
  constructor(init?: VendorReport) {
    if (init) {
      this.vendor = init.vendor;
      this.date = init.date;
      this.invoiceNumber = Number(init.invoiceNumber);
      this.partCode = init.partCode;
      this.description = init.description;
      this.markup = Number(init.markup);
      this.unitPrice = Number(init.unitPrice);
      this.quantity = Number(init.quantity);
      this.rate = Number(init.rate);
      this.costTotal = Number(init.costTotal);
      this.subTotal = Number(init.subTotal);
      this.profit = Number(init.profit);
      this.lowestMarkup = Number(init.lowestMarkup);
      this.highestMarkup = Number(init.highestMarkup);
      this.totalMarkup = Number(init.totalMarkup);
    }
  }
}

export interface TotalSalesReport {
  date?: Date;
  totalLabor: number;
  totalParts: number;
  totalMisc: number;
  totalTax: number;
  totalFinal: number;
  invoiceNumber?: number;
  poNumber?: string;
  isCommercial?: boolean;
  businessName?: string;
  firstName?: string;
  lastName?: string;
  description?: string;
  payType?: string;
}

export class TotalSalesReport implements TotalSalesReport {
  constructor(init?: TotalSalesReport) {
    if (init) {
      this.date = new Date(init.date!);
      this.totalLabor = Number(init.totalLabor);
      this.totalParts = Number(init.totalParts);
      this.totalMisc = Number(init.totalMisc);
      this.totalTax = Number(init.totalTax);
      this.totalFinal = Number(init.totalFinal);
      this.poNumber = init.poNumber;
      this.invoiceNumber = init.invoiceNumber;
      this.isCommercial = init.isCommercial;
      this.businessName = init.businessName;
      this.firstName = init.firstName;
      this.lastName = init.lastName;
      this.description = init.description;
      this.payType = init.payType;
    }
  }
}

export interface ReportInput {
  dateRange: DateRangeOption;
  startDate?: Date;
  endDate?: Date;
  reportType?: ReportType;
  filter?: string;
  useAccrual?: boolean;
}

export class ReportInput implements ReportInput {
  constructor(init?: ReportInput) {
    if (init) {
      this.dateRange = Number(init.dateRange);
      this.startDate = init.startDate
        ? new Date(
            init.startDate.getFullYear(),
            init.startDate.getMonth(),
            init.startDate.getDate(),
            0,
            0,
            0,
            0
          )
        : new Date();
      this.endDate = init.endDate
        ? new Date(
            init.endDate.getFullYear(),
            init.endDate.getMonth(),
            init.endDate.getDate(),
            0,
            0,
            0,
            0
          )
        : new Date();
      this.reportType = Number(init.reportType);
      this.filter = init.filter;
      this.useAccrual = init.useAccrual;
    }
  }
}

export interface CustomerHistory {
  ticketId?: string;
  vehicleId?: string;
  year?: number;
  make?: string;
  model?: string;
  engine?: string;
  totalAmount: number;
  invoiceDate?: Date;
  totalLabor: number;
  totalParts: number;
  totalMisc: number;
  totalTax: number;
  invoiceNumber?: number;
  description?: string;
  payType?: string;
}

export interface Transaction {
  date?: Date;
  ticketId?: string;
  invoiceNumber?: number;
  amount: number;
  isCommercial?: boolean;
  businessName?: string;
  firstName?: string;
  lastName?: string;
  lastFour?: string;

  ticketPaymentId?: string;
  refunded?: boolean;
  transactionType?: TransactionType;
  status?: PaymentStatus;
}

export class Transaction implements Transaction {
  constructor(init?: Transaction) {
    if (init) {
      this.date = new Date(init.date!);
      this.ticketId = init.ticketId;
      this.invoiceNumber = init.invoiceNumber;
      this.amount = init.amount;
      this.isCommercial = init.isCommercial;
      this.businessName = init.businessName;
      this.firstName = init.firstName;
      this.lastName = init.lastName;
    }
  }
}
