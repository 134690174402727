import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Container, Grid, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import {
  formatCurrency,
  getNegative,
} from "../../../app/common/util/functions";
import { Link, useNavigate } from "react-router-dom";
import {
  PaymentStatus,
  SearchType,
  TransactionType,
} from "../../../app/models/enums";
import ConfirmPaymentReversal from "../../tickets/paymentProcessing/ConfirmPaymentReversal";
import { TicketPaymentItem } from "../../../app/models/ticket";
import { format } from "date-fns";

interface Props {
  startDate: Date;
  endDate: Date;
}

export default observer(function CardTransactionPrint({
  startDate,
  endDate,
}: Props) {
  const { t } = useTranslation(["common", "reports"]);
  const navigate = useNavigate();

  const { creditCardTransactionReportStore, searchStore, modalStore } =
    useStore();
  const { getCCTransaction, groupedTransactionReport, calculateGrandTotal } =
    creditCardTransactionReportStore;

  const { setPredicate: searchPredicate } = searchStore;

  return (
    <>
      <Container className="page">
        <Grid stackable columns={2} className="headerPrint">
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={`${t("report", { ns: "reports" })}: ${t(
                  "cc_transaction.header",
                  {
                    ns: "reports",
                  }
                )}`}
                type="h2"
                divider={false}
                getAlerts={true}
                addTitle={false}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`${format(startDate, "PP")} - ${format(
                  endDate,
                  "PP"
                )}`}
                as="h2"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {getCCTransaction && getCCTransaction.length > 0 && (
          <Table stackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">
                  {t("date", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="left">
                  {t("invoicenumber", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("customer", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("maskedLast4", { ns: "subscription" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("amount", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell
                  className="printPoof"
                  textAlign="right"
                ></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            {groupedTransactionReport.map(([group, x]) => (
              <>
                <Table.Body className="terms_bold">
                  <Table.Row>
                    <Table.Cell colSpan={6}>{group}</Table.Cell>
                  </Table.Row>
                </Table.Body>
                <Table.Body>
                  {x.map((item) => (
                    <Table.Row key={item.ticketId}>
                      <Table.Cell>{group}</Table.Cell>
                      <Table.Cell textAlign="left">
                        <Link
                          onClick={() => {
                            searchPredicate(
                              SearchType.InvoiceNumber,
                              item.invoiceNumber
                            );
                            navigate(
                              `/search/${SearchType.InvoiceNumber}/${item.invoiceNumber}`
                            );
                          }}
                          to={""}
                        >
                          {item.invoiceNumber}
                        </Link>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {item.isCommercial
                          ? item.businessName
                          : `${item.lastName ?? "--"}, ${
                              item.firstName ?? "--"
                            }`}
                      </Table.Cell>
                      <Table.Cell textAlign="right">{item.lastFour}</Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{
                          color: getNegative(item.amount),
                        }}
                      >
                        {formatCurrency(item.amount)}
                      </Table.Cell>
                      <Table.Cell textAlign="right" className="printPoof">
                        {!item.refunded &&
                          item.transactionType !== TransactionType.Refund &&
                          item.status == PaymentStatus.Succeeded && (
                            <Button
                              color="green"
                              icon="undo"
                              content={t("estimate.reverse", { ns: "tickets" })}
                              onClick={() => {
                                let payment: TicketPaymentItem =
                                  new TicketPaymentItem();

                                modalStore.openModal(
                                  <ConfirmPaymentReversal
                                    ticketPaymentItem={payment}
                                    isPaymentProcessor={true}
                                  />
                                );
                              }}
                            />
                          )}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </>
            ))}
            <Table.Footer>
              <Table.Row key="grandTotals">
                <Table.Cell colSpan="4"></Table.Cell>
                <Table.Cell textAlign="right">
                  {formatCurrency(calculateGrandTotal(getCCTransaction).amount)}
                </Table.Cell>
                <Table.Cell
                  textAlign="right"
                  className="printPoof"
                ></Table.Cell>
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </Container>
    </>
  );
});
