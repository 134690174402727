import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";

interface Props {
  pageBreak: boolean;
  disclaimer: string;
}

export default observer(function DisclaimerMiddlePdfView({
  pageBreak,
  disclaimer,
}: Props) {
  const { disclaimerStore } = useStore();

  const {
    previewMiddleDisclaimer,
    loadSettingDisclaimer,
  } = disclaimerStore;

  useEffect(() => {
    loadSettingDisclaimer();
  }, [loadSettingDisclaimer]);

  return (
    <View
      style={{
        ...compose("view pageWidth borderSolid padding5pxLeft padding5pxRight"),
        height: "55mm",
        maxHeight: "55mm",
        marginTop: "2mm"
      }}
      wrap={false}
      break={pageBreak}
    >
      <Text
        style={{
          ...compose("text "),
          width: "197mm",
          fontSize: "13pt",
          letterSpacing: "0",
        }}
      >
        {previewMiddleDisclaimer(disclaimer)}
      </Text>
    </View>
  );
});
