import { Pagination, PagingParams } from "../models/pagination";
import { IUpsertSelectableCommentFormValues, SelectableCommentsDto, UpsertSelectableCommentFormValues } from "../models/settings";
import agent from "../api/agent";
import { makeAutoObservable, reaction, runInAction } from "mobx";

export default class SelectableCommentsStore {
  loadingInitial = false;

  pagingParams = new PagingParams();
  pagination: Pagination | null = null;

  selectedSelectableComments: SelectableCommentsDto | undefined = undefined;

  SelectableCommentsRegistry = new Map<string | undefined, SelectableCommentsDto>();

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
        this.SelectableCommentsRegistry.clear();
        this.loadSelectableComments();
      }
    );
  }

  setSelectedSelectableComments = (values: SelectableCommentsDto | undefined) => {
    this.selectedSelectableComments = values;
  };

  private setSelectableComment = (comm: SelectableCommentsDto) => {
    comm.createdDate = new Date(comm.createdDate);
    this.SelectableCommentsRegistry.set(
      comm.id,
      comm
    );
  }

  loadSelectableComments = async () => {
    this.setLoadingInitial(true);
    this.SelectableCommentsRegistry.clear();
    try {
      const result = await agent.Tickets.getSettingsSelectableCommments(this.axiosParams);
      runInAction(() => {
        result.data.forEach((x: SelectableCommentsDto) => this.setSelectableComment(x))
      });
      this.setPagination(result.pagination);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  };

  createSelectableComment = async (values: IUpsertSelectableCommentFormValues) => {
    this.setLoadingInitial(true);
    try {
      await agent.Tickets.addSettingsSelectableCommment(values);
      runInAction(() => {
        this.SelectableCommentsRegistry.clear();
      });
    } 
    catch(error) {
      console.log("Error:", error);
    }
    finally {
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  }

  updateSelectableComment = async (commentId: string, values: IUpsertSelectableCommentFormValues) => {
    this.setLoadingInitial(true);
    try {
      await agent.Tickets.updateSettingsSelectableCommments(commentId, values);
      runInAction(() => {
        this.SelectableCommentsRegistry.clear();
        this.setSelectedSelectableComments(undefined);
      });
    } 
    catch(error) {
      console.log("Error:", error);
    }
    finally {
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  }

  removeSelectableComment = async (commentId: string) => {
    this.setLoadingInitial(true);
    try {
      await agent.Tickets.removeSettingsSelectableCommments(commentId);
      runInAction(() => {
        this.SelectableCommentsRegistry.clear();
      });
    } 
    catch(error) {
      console.log("Error:", error);
    }
    finally {
      runInAction(() => {
        this.setLoadingInitial(false);
      });
    }
  
  }

  setLoadingInitial(value: boolean) {
    this.loadingInitial = value; 
  }

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    return params;
  }

  get getSelectableComments() {
    return Array.from(this.SelectableCommentsRegistry.values()).sort(
      (a, b) => b.createdDate!.getTime() - a.createdDate!.getTime()
    );
  }

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, pagingParams.pageSize ?? 10);
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  }
}
