import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Icon, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import AddPayType from "./AddPayType";
import ConfirmDelete from "../../../app/common/form/ConfirmDelete";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyHelpButton from "../../../app/common/form/MyHelpButton";

export default observer(function PayTypes() {
  const { t } = useTranslation(["common", "pricingSettings"]);

  const { pricingStore, modalStore, ticketStore } = useStore();
  const {
    loadSettingsPayType,
    payTypeRegistry,
    getPayTypes,
    toggleSettingsPayType,
    removeSettingsPayType,
    setSelectedSettingsPayType,
    loadingInitial,
  } = pricingStore;
  const {loadSettingsTicketInputDefaults, selectedSettingsTicketInputDefaults} = ticketStore

  useEffect(() => {
    loadSettingsPayType(false);
  }, [loadSettingsPayType, payTypeRegistry]);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  const [deleteError, setDeleteError] = useState<boolean>(false);

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("payTypes_loading", { ns: "pricingSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("paytypes", { ns: "common" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      {deleteError && (
        <Message negative>
          {t("pricing.deleteError", {
            ns: "errors",
          })}
        </Message>
      )}
      <Button
        floated="right"
        color="green"
        onClick={() =>
          modalStore.openModal(
            <AddPayType
              header={t("pricing.addPayType", { ns: "pricingSettings" })}
              name={""}
              isAdd={true}
              autoCapitalizeFirstLetter={selectedSettingsTicketInputDefaults.capitalizeFirstCharacter}
            />
          )
        }
      >
        <Icon name="plus" />
        {t("pay_types.add", { ns: "pricingSettings" })}
        <MyHelpButton
          position="bottom right"
          name="pricingHelp"
          help="settings.pricing.payType"
          color="black"
        />
      </Button>
      <br></br>
      <br></br>
      <Table striped unstackable className="dnxTable">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>
              {t("description", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {t("status", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getPayTypes.map((payType) => (
            <Table.Row
              key={`payTypeRow.${payType.id}`}
            >
              <Table.Cell className="textAlignLeft">
                {!payType.isStandard && (
                  <Button
                    color="grey"
                    icon="edit"
                    type="button"
                    onClick={() => {
                      setSelectedSettingsPayType(payType);
                      modalStore.openModal(
                        <AddPayType
                          name={""}
                          header={t("pricing.editPayType", {
                            ns: "pricingSettings",
                          })}
                          isAdd={false}
                          autoCapitalizeFirstLetter={selectedSettingsTicketInputDefaults.capitalizeFirstCharacter}
                        />
                      );
                    }}
                  ></Button>
                )}
              </Table.Cell>
              <Table.Cell data-label={`${t("description", { ns: "common" })}:`}>{payType.name}</Table.Cell>
              <Table.Cell data-label={`${t("status", { ns: "common" })}:`} className="textAlignRightNotMobile">
                <Button
                  basic
                  color={payType.isActive === true ? "green" : "red"}
                  onClick={() => {
                    setDeleteError(false);
                    if (payType.id) {
                      let valid: boolean = true;
                      if (payType.isActive) {
                        let count = Array.from(payTypeRegistry.values()).filter(
                          (x) => {
                            return x.isActive;
                          }
                        );
                        valid = count.length > 1;
                      }

                      if (valid) {
                        toggleSettingsPayType(payType.id);
                      } else {
                        setDeleteError(true);
                      }
                    }
                  }}
                >
                  {payType.isActive === true
                    ? t("enabled", { ns: "miscSettings" })
                    : t("disabled", { ns: "miscSettings" })}
                </Button>
              </Table.Cell>
              <Table.Cell className="textAlignRight">
                {!payType.isStandard && (
                  <Button
                    color="grey"
                    icon="trash"
                    type="button"
                    onClick={() => {
                      setDeleteError(false);
                      if (payType.id) {
                        let valid: boolean = true;
                        if (payType.isActive) {
                          let count = Array.from(
                            payTypeRegistry.values()
                          ).filter((x) => {
                            return x.isActive;
                          });
                          valid = count.length > 1;
                        }

                        if (valid) {
                          modalStore.openModal(
                            <ConfirmDelete
                              header={t("pricing.removePayType", {
                                ns: "pricingSettings",
                              })}
                              value={payType.id ? payType.id : ""}
                              text={payType.name}
                              deleteValue={removeSettingsPayType}
                            />
                          );
                        } else {
                          setDeleteError(true);
                        }
                      }
                    }}
                  ></Button>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
});
