import { TicketItemType } from "../models/enums";

export interface PackageJobsItems {
  id?: string;
  packageJobId?: string;
  jobId?: number;
  miscellaneousChargeId?: string;
  laborRateId?: string;
  itemDescription?: string;
  partId?: string;
  partName?: string;
  quantity: number;
  unitPrice?: number;
  isTaxable: boolean;
  timeType?: number;
  order?: number;
  type?: TicketItemType;
}

export interface PackageJobs {
  id?: string;
  name: string;
  description: string;
  isFixedRate: boolean;
  isEnabled: boolean;
  createdDate?: Date;
  packageJobItems: PackageJobsItems[];
  error?: string;
  
}

export class PackageJobs implements PackageJobs {
  constructor(init?: PackageJobsFormValues) {
    if (init) {
      this.id = init.id;
      this.name = init.name;
      this.description = init.description;
      this.isFixedRate = init.isFixedRate.toString() === "true";
      this.isEnabled = init.isEnabled.toString() === "true";
      this.packageJobItems = init.packageJobItems;
      this.createdDate = init.createdDate
      this.error = init.error;
    }
  }
}

export class PackageJobsFormValues {
  id?: string = undefined;
  name: string = "";
  description: string = "";
  isFixedRate: boolean = false;
  isEnabled: boolean = false;
  packageJobItems: PackageJobsItems[] = [];
  createdDate?: Date = new Date();
  error?: string = undefined;

  constructor(packagejobs?: PackageJobsFormValues) {
    if (packagejobs) {
      this.id = packagejobs.id;
      this.name = packagejobs.name;
      this.description = packagejobs.description;
      this.isFixedRate = packagejobs.isFixedRate;
      this.isEnabled = packagejobs.isEnabled;
      this.packageJobItems = packagejobs.packageJobItems
      this.createdDate = packagejobs.createdDate;
      this.error = packagejobs.error;
    }
  }
}
