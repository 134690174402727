import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useStore } from "../../../app/stores/store";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { resetTicket } from "../../../app/common/util/ticketfunctions";

export default observer(function TicketStart() {
  const navigate = useNavigate();
  const {
    ticketStore,
  } = useStore();
  const { loadSettingsWorkflow } = ticketStore;


  useEffect(() => {
    loadSettingsWorkflow().then((result) => {
      resetTicket();
      let url = `/ticket/vehicle/${uuid()}/false`;
      if (result?.isCustomerInfoFirst) {
        url = `/ticket/start/customer/${uuid()}/`;
      }

      navigate(url);
    });
  }, [loadSettingsWorkflow, navigate]);

  return <></>;
});
