import { observer } from "mobx-react-lite";
import { Icon, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { useEffect } from "react";
import { useStore } from "../../app/stores/store";
import { PagingParams } from "../../app/models/pagination";
import MyPaging from "../../app/common/form/MyPaging";

interface Props {
  clientId?: string;
  userId?: string;
  ipAddress?: string;
  isRelatedIp?: boolean;
}

export default observer(function AuthenticationLog({
  clientId,
  userId,
  ipAddress,
  isRelatedIp
}: Props) {
  const { t } = useTranslation(["common"]);
  const { authenticationLogStore } = useStore();

  const {
    loadAuthenticationLogs,
    getAuthenticationLogs,
    setPredicate,
    pagination,
    setPagingParams,
    pagingParams,
  } = authenticationLogStore;

  useEffect(() => {
      setPredicate('ip', ipAddress);
      loadAuthenticationLogs(clientId, userId, isRelatedIp);
  }, [
    loadAuthenticationLogs,
    clientId,
    userId,
    ipAddress
  ]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      setPredicate('ip', ipAddress);
      loadAuthenticationLogs(clientId, userId, isRelatedIp);
    }
  }

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date Created</Table.HeaderCell>
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.HeaderCell>Company Name</Table.HeaderCell>
          <Table.HeaderCell>IP Address</Table.HeaderCell>
          <Table.HeaderCell>Success</Table.HeaderCell>
          <Table.HeaderCell>Status / Reason</Table.HeaderCell>
          <Table.HeaderCell>Admin</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {getAuthenticationLogs && getAuthenticationLogs.length > 0 ? (
          getAuthenticationLogs.map((x) => (
            <Table.Row key={x.id}>
              <Table.Cell>{format(x.createdDate, "P p")}</Table.Cell>
              <Table.Cell name="userName">
                {" "}
                <Link to={`/admin/userManage/${x.userId}`}>{x.username}</Link>
              </Table.Cell>
              <Table.Cell name="companyName">
                {" "}
                <Link to={`/admin/company/${x.clientId}`}>{x.clientName}</Link>
              </Table.Cell>
              <Table.Cell>
                <Link
                  to={"/admin/Tools/IpLookup/"}
                  onClick={(e) => {
                    setPredicate("Ip", x.ipAddress);
                  }}
                >
                  {x.ipAddress + " "}
                </Link>
                <Link to={`/admin/Tools/IpLookup/${x.ipAddress}`}>[L]</Link>
              </Table.Cell>

              <Table.Cell>
                {x.success ? (
                  <Icon name="check" color="green" />
                ) : (
                  <Icon name="x" color="red" />
                )}
              </Table.Cell>
              <Table.Cell>{x.statusReason}</Table.Cell>
              <Table.Cell>
                {x.admin ? (
                  <Icon name="check" color="green" />
                ) : (
                  <Icon name="x" color="red" />
                )}
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noItems"}>
            <Table.Cell width="8" colSpan={8}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="8">
            {userId && !isRelatedIp && <Link to={`/admin/authLog/user/${userId}`}>Find all related IP addresses</Link>} 
            {clientId && !isRelatedIp && <Link to={`/admin/authLog/client/${clientId}`}>Find all related IP addresses</Link>} 
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 10}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            ></MyPaging>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
