import { format } from "date-fns";

  /**
   * convert JSON or array to CSV and return the resulting string
   */
  export const convertToCSV = (objArray: string | object[], withHeadingRow = true) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;

    if (!array || !array?.length) return '';

    let str = '';

    if (withHeadingRow) {
      let line = '';
      for (let index in array[0]) {
        if (line !== '') line += ',';

        line += index;
      }

      str += line + '\r\n';
    }

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';

        // https://www.ietf.org/rfc/rfc4180.txt
        const escQuote = array[i][index].toString().replaceAll('"', '""');
        line += `"${escQuote}"`;
      }

      str += line + '\r\n';
    }

    return str;
  };

  /**
   * convert JSON or array to CSV and automatically start the browser downloading the resulting data to a file 
   */
  export const downloadCSV = (data: string | object[], prefix: string, withHeadingRow = true) => {
    const csvStr = convertToCSV(data, withHeadingRow);
    if (!csvStr.length) return;

    const csvData = new Blob([csvStr], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);

    const date = format(new Date(), "y-LL-dd-kk-mm");

    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${prefix}-${date}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };