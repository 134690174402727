import { SettingsDisclaimer } from "../models/disclaimers";
import { requests } from "./Requests";

export const Disclaimers = {
  getSettingsDisclaimer: () =>
    requests.get<SettingsDisclaimer>(
      `/SettingsDisclaimer/getSettingDisclaimer/`
    ),
  addSettingsDisclaimer: (values: SettingsDisclaimer) =>
    requests.post<void>("/SettingsDisclaimer/addSettingsDisclaimer", values),
  updateSettingDisclaimerInvoice: (values: SettingsDisclaimer) =>
    requests.put<void>(
      `/SettingsDisclaimer/updateSettingDisclaimerInvoice/${values.id}`,
      values
    ),
  updateSettingDisclaimerEstimate: (values: SettingsDisclaimer) =>
    requests.put<void>(
      `/SettingsDisclaimer/updateSettingDisclaimerEstimate/${values.id}`,
      values
    ),
};
