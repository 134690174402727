import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Grid, Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import Formatting from "./Formatting";
import Numbers from "./Numbers";
import InputDefaults from "./InputDefaults";
import TicketDefaults from "./TicketDefaults";
import PageHeader from "../../../app/layout/PageHeader";
import SettingsNavBar from "../SettingsNavBar";
import SettingsDropdownMenuMobile from "../SettingsDropdownMenuMobile";
import { Link } from "react-router-dom";
import EmailDefaults from "./EmailDefaults";
import SelectableComments from "./SelectableComments";
import { Application } from "../../../app/models/enums";

interface Props {
  activeTab: number;
}

export default observer(function Ticket(props: Props) {
  const { t } = useTranslation(["common", "ticketSettings"]);
  const { settingsStore, userStore } = useStore();
  const { checkApplication } = userStore;

  const { setActiveTab, activeTab } = settingsStore;

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [setActiveTab, props.activeTab]);

  const panes = [
    {
      menuItem: {
        as: Link,
        content: t("formatting", { ns: "common" }),
        to: "/settings/formatting",
        exact: "true",
      },
      render: () => <Formatting />,
    },
    {
      menuItem: {
        as: Link,
        content: t("numbers", { ns: "common" }),
        to: "/settings/numbers",
        exact: "true",
      },
      render: () => <Numbers />,
    },
    {
      menuItem: {
        as: Link,
        content: t("inputdefaults", { ns: "common" }),
        to: "/settings/inputDefaults",
        exact: "true",
      },
      render: () => <InputDefaults />,
    },
    {
      menuItem: {
        as: Link,
        content: t("ticketdefaults", { ns: "common" }),
        to: "/settings/ticketDefaults",
        exact: "true",
      },
      render: () => <TicketDefaults />,
    },
  ];

  if  (checkApplication(Application.AutomotiveExpert)) {
    panes.push({
      menuItem: {
        as: Link,
        content: t("Email Defaults", { ns: "common" }),
        to: "/settings/emailDefaults",
        exact: "true",
      },
      render: () => <EmailDefaults />,
    });
    panes.push({
      menuItem: {
        as: Link,
        content: t("Selectable Comments", { ns: "common" }),
        to: "/settings/selectableComments",
        exact: "true",
      },
      render: () => <SelectableComments />,
    });
  }

  return (
    <Grid stackable>
      <Grid.Column width={3} className="mobileHeight">
        <SettingsNavBar />
        <SettingsDropdownMenuMobile />
      </Grid.Column>
      <Grid.Column width={13}>
        <PageHeader
          header={t("ticket", { ns: "common" })}
          type={"h1"}
          divider={true}
          getAlerts={true}
          addTitle={false}
        />
        <Tab
          menu={{ fluid: true }}
          menuPosition="left"
          panes={panes}
          activeIndex={activeTab}
          onTabChange={(e, data) => setActiveTab(data.activeIndex)}
        />
      </Grid.Column>
    </Grid>
  );
});
