import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Divider,
  Grid,
  Button,
  Icon,
  Form,
  Message,
  Label,
  DropdownItemProps,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Sidebar from "./Sidebar";
import PageHeader from "../../../app/layout/PageHeader";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyIconTextInput from "../../../app/common/form/MyIconTextInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import * as Yup from "yup";
import {
  TicketFinalizeFormValues,
  TicketItemFormValues,
  TicketPaymentItem,
  TicketSession,
} from "../../../app/models/ticket";
import { useStore } from "../../../app/stores/store";
import MyRadioGroup from "../../../app/common/form/RadioButtonGroups/MyRadioGroup";
import {
  defaultTicketOptions,
  hourlyRateOptions,
  returnPartsOption,
  yesNoOptions,
} from "../../../app/common/options/option";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import {
  Application,
  PaymentStatus,
  PhoneNumberType,
  TicketItemType,
  TicketType,
  TicketWorkflowSteps,
  TransactionType,
} from "../../../app/models/enums";
import { Link, useNavigate, useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import ValidationErrors from "../../errors/ValidationErrors";
import CustomerForm from "../../customers/CustomerForm";
import { CustomerFormValues } from "../../../app/models/customer";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import PotentialCustomerMatchingMessage from "../../customers/PotentialCustomerMatchingMessage";
import PaymentItem from "../paymentProcessing/PaymentItem";
import {
  formatDecimal,
  getNegative,
  round2Decimals,
} from "../../../app/common/util/functions";
import ConfirmDelete from "../../../app/common/form/ConfirmDelete";
import AddSelectableCommentsToNotes from "./modals/AddSelectableCommentsToNotes";

interface Props {
  isCustomerInfoFirst: boolean;
  formatPhoneNumber: boolean;
  autoCapitalizeFirstLetter: boolean;
  estimatePaymentMethodId?: string;
  technicianTrackingEnabled: boolean;
  technicianPartsTrackingEnabled: boolean;
  dropdownPayTypesList: DropdownItemProps[];
  isCompleted: boolean;
}

export default observer(function Finalize({
  isCustomerInfoFirst,
  formatPhoneNumber,
  autoCapitalizeFirstLetter,
  estimatePaymentMethodId,
  technicianTrackingEnabled,
  technicianPartsTrackingEnabled,
  dropdownPayTypesList,
  isCompleted,
}: Props) {
  const { t } = useTranslation(["common", "translation"]);
  const validationSchema = Yup.object({});
  const {
    ticketStore,
    ticketSessionStore,
    pricingStore,
    vehicleStore,
    customerStore,
    userStore,
    modalStore,
  } = useStore();

  const { resetRecentTickets } = ticketStore;
  const { checkApplication } = userStore;

  const {
    selectedTicketSession,
    updateFinalizeSession,
    createdAndSuccessfulTicketPayments,
    addTicketPaymentItem,
    removeTicketPaymentItem,
    setSelectedTicketSessionAmountDue,
    setSelectedTicketSessionPaymentReceived,
    calculateTotals,
    resetTicketPaymentItems,
    processPayments,
    hasLabor,
    hasParts,
    addTicketShopQuotedTimes,
    addTicketMiscellaneousCharges,
    addQuickVinLogs,
    updateGoBack,
  } = ticketSessionStore;

  const { selectedVehicle } = vehicleStore;
  const { selectedCustomer, isCompanySet, isLastNameSet } = customerStore;

  const { loadSettingsPayType } = pricingStore;
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { complete } = useParams<{ complete: string }>();
  let initial: boolean = isCompanySet || isLastNameSet;
  const [isCustomerSet, setIsCustomerSet] = useState<boolean>(initial);

  useEffect(() => {
    loadSettingsPayType(true);
  }, [loadSettingsPayType]);

  const [finalizeTicketSession] = useState<TicketFinalizeFormValues>(
    loadFinalizeTicket(selectedTicketSession)
  );

  function loadFinalizeTicket(selectedTicketSession?: TicketSession) {
    let newFinalize = new TicketFinalizeFormValues();

    if (selectedTicketSession) {
      newFinalize.id = selectedTicketSession.id;
      newFinalize.ticketType = selectedTicketSession.ticketType;
      newFinalize.estimateDate = isCompleted
        ? selectedTicketSession.estimateDate
        : new Date();
      newFinalize.estimateDate = isCompleted
        ? selectedTicketSession.estimateDate
        : new Date();
      newFinalize.invoiceDate = isCompleted
        ? selectedTicketSession.invoiceDate
        : new Date();
      newFinalize.promisedDate = selectedTicketSession.promisedDate;
      newFinalize.promisedDate = selectedTicketSession.promisedDate;
      newFinalize.showSeparateCharges =
        selectedTicketSession.showSeparateCharges;
      newFinalize.showLaborHours = selectedTicketSession.showLaborHours;
      newFinalize.rateType = selectedTicketSession.rateType;
      newFinalize.returnParts = selectedTicketSession.returnParts;
      newFinalize.otherAuthorizedPerson =
        selectedTicketSession.authorizedContact;
      newFinalize.otherAuthorizedPersonPhone =
        selectedTicketSession.otherAuthorizedPersonPhone;
      newFinalize.notes = selectedTicketSession.notes;
      newFinalize.ticketPayments = selectedTicketSession.ticketPayments;
      newFinalize.estimateCharge = selectedTicketSession.estimateCharge;
      newFinalize.estimatePaymentMethod =
        selectedTicketSession.estimatePaymentMethod ?? estimatePaymentMethodId;
    }

    return newFinalize;
  }

  function handleFormSubmit(
    values: TicketFinalizeFormValues,
    setErrors: (errors: FormikErrors<TicketFinalizeFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    updateFinalizeSession(
      TicketWorkflowSteps.Finalize,
      values,
      selectedVehicle,
      selectedCustomer
    )
      .then(() => {
        addTicketMiscellaneousCharges(id ?? "");
        addTicketShopQuotedTimes(id ?? "");
        addQuickVinLogs(id ?? "");

        resetRecentTickets();
        //this must use the == instead of the === because it isn't always the right datatype for some reason

        if (values.ticketType == TicketType.Invoice && processPayments) {
          navigate(`/ticket/processPayment/${id}`);
        } else if (
          values.ticketType == TicketType.Invoice &&
          technicianTrackingEnabled &&
          checkApplication(Application.RealTimeLaborGuidePro) &&
          (hasLabor() || (technicianPartsTrackingEnabled && hasParts()))
        ) {
          navigate(`/ticket/techtracking/${id}`);
        } else {
          navigate(`/ticket/print/${id}`);
        }
        setSubmitting(false);
      })
      .catch((error) => {
        setErrors({ error: error });
        setSubmitting(false);
      });
  }

  const [isInvoice, setIsInvoice] = useState<boolean>(
    selectedTicketSession
      ? selectedTicketSession.ticketType === TicketType.Invoice
      : false
  );

  const [amountDue, setAmountDue] = useState<number>(0.0);

  const [paymentReceived, setPaymentReceived] = useState<number>(0.0);

  useEffect(() => {
    calculateTotals();
    CalculateAmountDue();
  }, [createdAndSuccessfulTicketPayments.length]);

  function CalculateAmountDue() {
    let paymentReceived: number = 0.0;
    createdAndSuccessfulTicketPayments.forEach((item) => {
      paymentReceived = Number(paymentReceived) + Number(item.amount ?? 0);
    });

    if (selectedTicketSession) {
      setPaymentReceived(round2Decimals(Number(paymentReceived)));
      setSelectedTicketSessionPaymentReceived(
        round2Decimals(Number(paymentReceived))
      );

      let amountDue: number =
        Number(selectedTicketSession.totalAmount ?? 0) -
        Number(paymentReceived ?? 0);

      setSelectedTicketSessionAmountDue(round2Decimals(Number(amountDue)));
      setAmountDue(Number(amountDue));
    }
  }

  function onInvoiceChange(value: string) {
    setIsInvoice(value !== TicketType.Estimate.toString());
    if (!isInvoice) {
      resetTicketPaymentItems();
    }
  }

  return (
    <Grid stackable>
      <Grid.Column width={11}>
        <PotentialCustomerMatchingMessage
          workflowStep={TicketWorkflowSteps.Finalize}
        />
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={finalizeTicketSession}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({ handleSubmit, isSubmitting, errors, values, setFieldValue }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid stackable>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <PageHeader
                      type="h1"
                      header={t("finalize_ticket", {
                        ns: "tickets",
                      }).toString()}
                      className="modal-text-color"
                      textAlign="left"
                      divider={false}
                      addTitle={true}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      animated
                      color="green"
                      floated="right"
                      type="submit"
                      disabled={isSubmitting}
                      loading={isSubmitting}
                    >
                      <Button.Content visible>
                        {t("complete", { ns: "tickets" })}
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon name="caret right" />
                      </Button.Content>
                    </Button>
                    <Button
                      floated="right"
                      type="button"
                      onClick={() => {
                        updateGoBack(
                          isCustomerInfoFirst
                            ? TicketWorkflowSteps.Summary
                            : TicketWorkflowSteps.Customer2,
                          values,
                          selectedVehicle,
                          selectedCustomer
                        ).then(() => {
                          isCustomerInfoFirst
                            ? navigate(`/ticket/summary/${id}/${complete}`)
                            : navigate(`/ticket/customer/${id}/${complete}`);
                        });
                      }}
                    >
                      <Icon name="caret left" />
                      {t("back", { ns: "tickets" })}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Divider />
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    {checkApplication(Application.RealTimeLaborGuidePro) && (
                      <>
                        {!isCustomerSet && (
                          <>
                            {!errors.error ? (
                              <Message color="yellow">
                                <Message.Content>
                                  {t("InvalidCustomer", { ns: "errors" })}{" "}
                                  <Link
                                    to={""}
                                    onClick={() => {
                                      modalStore.openModal(
                                        <CustomerForm
                                          isTicket={true}
                                          isModal={true}
                                          isCustomerSet={(d) =>
                                            setIsCustomerSet(d)
                                          }
                                          previousLocation={""}
                                          customerFormValues={
                                            selectedCustomer ??
                                            new CustomerFormValues()
                                          }
                                          updateData={true}
                                          ticketWorkflowStep={
                                            TicketWorkflowSteps.Finalize
                                          }
                                          ticketId={id ?? ""}
                                        />
                                      );
                                    }}
                                  >
                                    <strong>
                                      {t("editCustomer", { ns: "customer" })}
                                    </strong>
                                  </Link>
                                </Message.Content>
                              </Message>
                            ) : (
                              <Grid.Row>
                                <Grid.Column>
                                  <ErrorMessage
                                    name="error"
                                    render={() => (
                                      <ValidationErrors errors={errors.error} />
                                    )}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <MyRadioGroup
                      name={"ticketType"}
                      options={defaultTicketOptions}
                      inline={true}
                      label={`${t("ticket_type", { ns: "tickets" })}:`}
                      onChange={(data) => {
                        onInvoiceChange(data);
                        if (data == TicketType.Estimate.toString()) {
                          setFieldValue("estimateDate", new Date());
                        } else {
                          setFieldValue("invoiceDate", new Date());
                        }
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    {isInvoice ? (
                      <>
                        <label className="myLabel">
                          {t("estimate.invoice", { ns: "tickets" }) + ":"}
                        </label>
                        <br />
                        <SemanticDatepicker
                          name="invoiceDate"
                          format="MM/DD/YYYY"
                          value={values.invoiceDate}
                          onChange={(e, d) => {
                            setFieldValue("invoiceDate", d.value);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <label className="myLabel">
                          {t("estimate.est", { ns: "tickets" }) + ":"}
                        </label>
                        <br />
                        <SemanticDatepicker
                          name="estimateDate"
                          format="MM/DD/YYYY"
                          value={values.estimateDate}
                          onChange={(e, d) => {
                            setFieldValue("estimateDate", d.value);
                          }}
                        />
                        <br />
                        <label className="myLabel">
                          {t("estimate.promised", { ns: "tickets" }) + ":"}
                        </label>
                        <br />
                        <SemanticDatepicker
                          name="promisedDate"
                          format="MM/DD/YYYY"
                          value={values.promisedDate}
                          onChange={(e, d) => {
                            setFieldValue("promisedDate", d.value);
                          }}
                        />
                      </>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <MyRadioGroup
                      name={"showSeparateCharges"}
                      options={yesNoOptions}
                      inline={true}
                      label={`${t("sep_tix_items", { ns: "tickets" })}:`}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <MyRadioGroup
                      name={"showLaborHours"}
                      options={yesNoOptions}
                      inline={true}
                      label={`${t("show_hours", { ns: "tickets" })}:`}
                    />
                  </Grid.Column>
                </Grid.Row>
                {!isInvoice && (
                  <>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <MySelectInput
                          options={dropdownPayTypesList}
                          label={t("expected_method", { ns: "tickets" }) + ":"}
                          placeholder={""}
                          name="estimatePaymentMethod"
                          clearable={false}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyIconTextInput
                          icon="dollar sign"
                          iconPosition="left"
                          placeholder="0.00"
                          label={t("est_charge", { ns: "tickets" }) + ":"}
                          name="estimateCharge"
                          maxLength={500}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <MyRadioGroup
                          name={"rateType"}
                          options={hourlyRateOptions}
                          inline={true}
                          label={`${t("rate", { ns: "tickets" })}:`}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <MyRadioGroup
                          name={"returnParts"}
                          options={returnPartsOption}
                          inline={true}
                          label={`${t("return_parts", { ns: "tickets" })}:`}
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <MyTextInput
                          label={
                            t("estimate.auth_person", { ns: "tickets" }) + ":"
                          }
                          name="otherAuthorizedPerson"
                          placeholder={""}
                          autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                          maxLength={255}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <PhoneNumberInput
                          label={t("estimate.phone", { ns: "tickets" }) + ":"}
                          name="otherAuthorizedPersonPhone"
                          placeholder={""}
                          autoFormat={formatPhoneNumber}
                          phoneType={PhoneNumberType.Landline}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
                {isInvoice && (
                  <>
                    <Grid.Row columns={2}>
                      <Grid.Column verticalAlign="bottom">
                        <Button
                          color="green"
                          icon="plus"
                          content={t("add_payment", {
                            ns: "tickets",
                          })}
                          type="button"
                          onClick={() => {
                            let newItem = new TicketPaymentItem();
                            newItem.id = uuid();
                            newItem.createdDate = new Date();
                            newItem.status = PaymentStatus.Created;
                            newItem.transactionType = TransactionType.Sale;
                            newItem.paymentTypeId =
                              selectedTicketSession?.estimatePaymentMethod ??
                              estimatePaymentMethodId;
                            newItem.isAdd = true;
                            modalStore.openTallModal(
                              <PaymentItem
                                name={`addPlusPaymentItem.${newItem.id}`}
                                dropdownItems={dropdownPayTypesList}
                                ticketPaymentItem={newItem}
                                addItem={addTicketPaymentItem}
                                amountDue={amountDue}
                                isAdd={true}
                              />
                            );
                          }}
                        ></Button>
                      </Grid.Column>
                      <Grid.Column>
                        <label className={"myLabel"}>
                          {t("estimate.amountDue", { ns: "tickets" })}:
                        </label>
                        <br />
                        <Label
                          basic
                          fluid={"true"}
                          className="myLabel shortInput labelHeight"
                          style={{
                            color: getNegative(amountDue ?? 0),
                          }}
                        >
                          <Icon name="dollar" />
                          {formatDecimal(
                            round2Decimals(amountDue ?? 0) ?? 0,
                            2
                          )}
                        </Label>
                        <Button
                          icon="caret square up outline"
                          attached="right"
                          type="button"
                          className="caretButton"
                          disabled={amountDue === 0.0}
                          onClick={() => {
                            let newItem = new TicketPaymentItem();
                            newItem.id = uuid();
                            newItem.createdDate = new Date();
                            newItem.status = PaymentStatus.Created;
                            newItem.transactionType = TransactionType.Sale;
                            newItem.paymentTypeId =
                              selectedTicketSession?.estimatePaymentMethod ??
                              estimatePaymentMethodId;
                            newItem.amount = round2Decimals(
                              selectedTicketSession?.amountDue ?? 0
                            );
                            newItem.isAdd = true;
                            modalStore.openTallModal(
                              <PaymentItem
                                name={`addAmountDuePaymentItem.${newItem.id}`}
                                dropdownItems={dropdownPayTypesList}
                                ticketPaymentItem={newItem}
                                addItem={addTicketPaymentItem}
                                amountDue={amountDue}
                                isAdd={true}
                              />
                            );
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column verticalAlign="bottom">
                        <label className={"myLabel"}>
                          {t("estimate.payment_method", { ns: "tickets" })}:
                        </label>
                      </Grid.Column>
                      <Grid.Column verticalAlign="bottom">
                        <label className={"myLabel"} htmlFor={"amount"}>
                          {t("paymentAmountReceived", {
                            ns: "tickets",
                          })}
                          :
                        </label>
                      </Grid.Column>
                    </Grid.Row>
                    {createdAndSuccessfulTicketPayments.length > 0 &&
                      createdAndSuccessfulTicketPayments.map(
                        (ticketPaymentItem) => (
                          <Grid.Row
                            columns={2}
                            key={`ticketPaymentItem.${ticketPaymentItem.id}`}
                          >
                            <Grid.Column
                              textAlign="left"
                              verticalAlign={"bottom"}
                            >
                              <Button
                                icon="edit"
                                type="button"
                                className="leftButton"
                                disabled={!ticketPaymentItem.isAdd}
                                onClick={() => {
                                  modalStore.openModal(
                                    <PaymentItem
                                      name={"editPaymentItem"}
                                      dropdownItems={dropdownPayTypesList}
                                      ticketPaymentItem={ticketPaymentItem}
                                      addItem={(value) => {
                                        addTicketPaymentItem(value);
                                        CalculateAmountDue();
                                      }}
                                      amountDue={amountDue}
                                      isAdd={false}
                                    />
                                  );
                                }}
                              />
                              <Label
                                basic
                                simple
                                fluid
                                className="myLabel shortInput labelHeight"
                              >
                                <Icon name={"credit card"} />
                                {ticketPaymentItem.paymentType}
                              </Label>
                            </Grid.Column>
                            <Grid.Column>
                              <Label
                                basic
                                simple
                                fluid
                                className="myLabel shortInput labelHeight"
                                style={{
                                  color: getNegative(
                                    ticketPaymentItem.amount ?? 0
                                  ),
                                }}
                              >
                                <Icon name="dollar" />
                                {formatDecimal(
                                  Number(ticketPaymentItem.amount ?? 0),
                                  2
                                )}
                              </Label>
                              <Button
                                icon="trash"
                                type="button"
                                className="caretButton"
                                disabled={!ticketPaymentItem.isAdd}
                                onClick={() => {
                                  modalStore.openModal(
                                    <ConfirmDelete
                                      header={t("removePaymentItem", {
                                        ns: "tickets",
                                      })}
                                      value={
                                        ticketPaymentItem.id
                                          ? ticketPaymentItem.id
                                          : ""
                                      }
                                      text={""}
                                      deleteValue={() => {
                                        removeTicketPaymentItem(
                                          ticketPaymentItem.id ?? ""
                                        );
                                      }}
                                    />
                                  );
                                }}
                              />
                            </Grid.Column>
                          </Grid.Row>
                        )
                      )}
                    <Grid.Row columns={3}>
                      <Grid.Column width={5}>
                        {checkApplication(Application.AutomotiveExpert) && (
                          <Button
                            color="green"
                            icon="plus"
                            content={t("selectableComments.header", {
                              ns: "settings",
                            })}
                            type="button"
                            onClick={() => {
                              modalStore.openTallModal(
                                <AddSelectableCommentsToNotes currentNotes={values.notes} setNotes={(newNotes: string) => setFieldValue('notes', newNotes)} />
                              );
                            }}
                          ></Button>
                        )}
                      </Grid.Column>
                      <Grid.Column textAlign="right" verticalAlign="middle" width={3}>
                        {t("paymentsFinalize", { ns: "tickets" })}:
                      </Grid.Column>
                      <Grid.Column verticalAlign="bottom" width={8}>
                        <Label
                          basic
                          simple
                          fluid
                          className="myLabel shortInput labelHeight"
                          style={{
                            color: getNegative(paymentReceived ?? 0),
                          }}
                        >
                          <Icon name="dollar" />
                          {formatDecimal(paymentReceived ?? 0, 2)}
                        </Label>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}

                <Grid.Row>
                  <Grid.Column>
                    <MyTextArea
                      label={t("estimate.notes", { ns: "tickets" }) + ":"}
                      name="notes"
                      placeholder={""}
                      rows={3}
                      maxLength={8000}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid.Column>
      <Grid.Column width={5}>
        <Grid>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Sidebar
              workflowStep={TicketWorkflowSteps.Finalize}
              autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
              setCustomer={(d) => setIsCustomerSet(d)}
            />
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
});
