import { observer } from "mobx-react-lite";
import React from "react";
import {
  DropdownItemProps,
  Grid,
  GridColumn,
  GridRow,
} from "semantic-ui-react";
import MyTextInput from "./MyTextInput";
import { useTranslation } from "react-i18next";
import MySelectInput from "./MySelectInput";
import { monthsOptions } from "../options/expirationMonth";
import PageHeader from "../../layout/PageHeader";
import { Options } from "../options/option";
import MyCheckbox from "./MyCheckbox";
import { useField } from "formik";

interface Props {
  showCreditCardFields: boolean;
}

export default observer(function CreditCard({ showCreditCardFields }: Props) {
  const { t } = useTranslation(["common", "profile", "subscription"]);
  const [field, meta, helpers] = useField("useCreditCardOnFile");

  let currentYear = new Date().getFullYear();
  let years: DropdownItemProps[] = [];

  for (let i = 0; i < 20; i++) {
    let uOption: Options = {
      text: (currentYear + i).toString(),
      value: (currentYear + i).toString(),
    };
    years.push(uOption);
  }

  return (
    <>
      {showCreditCardFields && (
        <>
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={t("creditCard.label", { ns: "subscription" })}
                type={"h2"}
                divider={true}
                addTitle={false}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <MyTextInput
                className='required'
                name="creditCardNumber"
                placeholder={t("creditCard.number", { ns: "subscription" })}
                label={`${t("creditCard.number", { ns: "subscription" })}:`}
                autoCapitalizeFirstLetter={false}
                maxLength={16}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column>
              <MySelectInput
                className='required'
                name="expirationMonth"
                placeholder={t("creditCard.expirationMonth", {
                  ns: "subscription",
                })}
                options={monthsOptions}
                label={`${t("creditCard.expirationMonth", {
                  ns: "subscription",
                })}:`}
                clearable={true}
              />
            </Grid.Column>
            <Grid.Column>
              <MySelectInput
                className='required'
                name="expirationYear"
                placeholder={t("year", { ns: "common" })}
                options={years}
                label={`${t("year", { ns: "common" })}:`}
                clearable={true}
              />
            </Grid.Column>
            <Grid.Column>
              <MyTextInput
                className='required'
                name="securityCode"
                placeholder={t("creditCard.cvv", { ns: "subscription" })}
                label={`${t("creditCard.cvv", { ns: "subscription" })}:`}
                autoCapitalizeFirstLetter={false}
                maxLength={4}
              />
            </Grid.Column>
          </Grid.Row>
        </>
      )}
    </>
  );
});
