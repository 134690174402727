import React from "react";
import { usStatesOptions } from "../options/statesOptions";
import MyDropdownInput from "./MyDropdownInput";

interface Props {
  placeholder: string;
  name: string;
  label?: string;
  showDefaults?: boolean;
  onSetDefaults?: (value: string) => void;
}

export default function StateSelect(props: Readonly<Props>) {
  //TODO fix the swapping of the options
  return (
    <MyDropdownInput
      placeholder={props.placeholder}
      name={props.name}
      label={props.label}
      options={usStatesOptions}
      showDefaults={props.showDefaults}
      onSetDefaults={props.onSetDefaults}
    />
  );
}
