import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Container,
  Form,
  FormInput,
  Grid,
  Label,
  Message,
  Radio,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import { useStore } from "../../app/stores/store";
import {
  SettingsVendorsFormValues,
  VendorsFormValues,
} from "../../app/models/vendor";
import format from "date-fns/format";
import AddVendor from "./AddVendor";
import PageHeader from "../../app/layout/PageHeader";
import { yesNoOptions } from "../../app/common/options/option";
import ValidationErrors from "../errors/ValidationErrors";
import ConfirmDelete from "../../app/common/form/ConfirmDelete";
import MyIconTextInput from "../../app/common/form/MyIconTextInput";
import SettingsNavBar from "../settings/SettingsNavBar";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { formatDecimal } from "../../app/common/util/functions";
import SettingsDropdownMenuMobile from "../settings/SettingsDropdownMenuMobile";
import { Application } from "../../app/models/enums";

export default observer(function Vendors() {
  const { t } = useTranslation(["common", "miscSettings"]);

  const { vendorStore, modalStore, ticketStore, userStore } = useStore();
  const { checkApplication } = userStore;

  const {
    loadVendors,
    loadingInitial,
    loadingListInitial,
    getVendors,
    toggleVendor,
    loadSettingVendors,
    createSettingsVendors,
    updateSettingsVendors,
    setSelectedVendor,
    removeVendor,
  } = vendorStore;

  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;

  const validationSchema = Yup.object({
    enablePartMarkup: Yup.boolean().required(),
  });

  const [settingsVendor, setSettingsVendor] =
    useState<SettingsVendorsFormValues>(new SettingsVendorsFormValues());
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadValues();
  }, [loadSettingVendors, loadVendors]);

  function loadValues() {
    loadSettingVendors().then((settingsVendors) => {
      setSettingsVendor(new SettingsVendorsFormValues(settingsVendors));
      loadVendors();
    });
  }

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  function handleFormSubmit(
    values: SettingsVendorsFormValues,
    setErrors: (errors: FormikErrors<SettingsVendorsFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsVendors(values)
      : () => createSettingsVendors(values);

    action()
      .then(() => {
        loadValues();
        setCompleted(true);
        setSubmitting(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }
  if (loadingInitial || loadingListInitial)
    return (
      <LoadingComponent content={t("vendor_loading", { ns: "settings" })} />
    );

  return (
    <>
      <Grid stackable>
        <Grid.Column width={3} className="mobileHeight">
          <SettingsNavBar />
          <SettingsDropdownMenuMobile />
        </Grid.Column>
        <Grid.Column width={13}>
          <PageHeader
            header={t("vendors.intro", { ns: "miscSettings" })}
            type={"h1"}
            divider={true}
            getAlerts={true}
            addTitle={true}
          />
          <>
            <Container className="page">
              <Button
                color={"green"}
                className="save_button"
                icon="plus"
                type="button"
                content={t("vendors.new", { ns: "miscSettings" })}
                onClick={() => {
                  setSelectedVendor(new VendorsFormValues());
                  modalStore.openModal(
                    <AddVendor
                      name={""}
                      header={t("vendors.add_vendor", { ns: "miscSettings" })}
                      isAdd={true}
                      defaults={settingsVendor}
                      autoCapitalizeFirstLetter={
                        selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                      }
                    />
                  );
                }}
              />
              <Table striped unstackable className="dnxTable">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="left"></Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("name", { ns: "common" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {t("vendors.markup", { ns: "miscSettings" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("created", { ns: "miscSettings" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {t("status", { ns: "common" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right"></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {getVendors && getVendors.length > 0 ? (
                    getVendors.map((Vendors, index) => (
                      <Table.Row key={`vendors${index}`}>
                        <Table.Cell className="textAlignLeft">
                          <Button
                            color="grey"
                            icon="edit"
                            type="button"
                            onClick={() => {
                              setSelectedVendor(Vendors);
                              modalStore.openModal(
                                <AddVendor
                                  name={""}
                                  header={t("vendors.update", {
                                    ns: "miscSettings",
                                  })}
                                  isAdd={false}
                                  defaults={settingsVendor}
                                  autoCapitalizeFirstLetter={
                                    selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                                  }
                                />
                              );
                            }}
                          ></Button>
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("name", { ns: "common" })}:`}
                        >
                          {Vendors.name}
                        </Table.Cell>
                        <Table.Cell
                          className="textAlignRightNotMobile"
                          data-label={`${t("vendors.markup", {
                            ns: "miscSettings",
                          })}:`}
                        >
                          {formatDecimal(Vendors.defaultMarkup, 2) + "%"}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("status", { ns: "common" })}:`}
                        >
                          {format(Vendors.createdDate!, "P")}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("inv", { ns: "search" })}:`}
                        >
                          <Button
                            basic
                            color={Vendors.isActive ? "green" : "red"}
                            type="button"
                            onClick={() => {
                              if (Vendors.id) toggleVendor(Vendors.id);
                            }}
                          >
                            {t(Vendors.isActive ? "enabled" : "disabled", {
                              ns: "miscSettings",
                            })}
                          </Button>
                        </Table.Cell>
                        <Table.Cell className="textAlignRight">
                          <Button
                            color="grey"
                            icon="trash"
                            type="button"
                            onClick={() =>
                              modalStore.openModal(
                                <ConfirmDelete
                                  header={t("vendors.removeVendor", {
                                    ns: "miscSettings",
                                  })}
                                  value={Vendors.id ? Vendors.id : ""}
                                  text={Vendors.name}
                                  deleteValue={removeVendor}
                                />
                              )
                            }
                          ></Button>
                        </Table.Cell>
                      </Table.Row>
                    ))
                  ) : (
                    <Table.Row key={"noItems"}>
                      <Table.Cell width="5" colSpan={5}>
                        <Message
                          color="yellow"
                          className="textAlignCenter fontStyleItalic"
                        >
                          {t("grid_noItems", { ns: "common" })}
                        </Message>
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
              <br></br>
              <PageHeader
                header={`${t("vendors.parts_markup", {
                  ns: "miscSettings",
                })}`}
                type={"h2"}
                divider={true}
                addTitle={false}
              />
              <Formik
                validationSchema={validationSchema}
                enableReinitialize
                initialValues={settingsVendor}
                onSubmit={(values, { setErrors, setSubmitting }) =>
                  handleFormSubmit(values, setErrors, setSubmitting)
                }
              >
                {({
                  handleSubmit,
                  isValid,
                  isSubmitting,
                  dirty,
                  handleBlur,
                  handleChange,
                  errors,
                  values,
                  touched,
                  setErrors,
                }) => (
                  <Form
                    className="ui form error"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    {completed && isValid && (
                      <Message positive>
                        {t("settingsupdated", {
                          ns: "common",
                        })}
                      </Message>
                    )}
                    <ErrorMessage
                      name="error"
                      render={() => <ValidationErrors errors={errors.error} />}
                    />
                    <Grid columns={2}>
                      <Grid.Column>
                        <label className="myLabel" htmlFor="enablePartMarkup">
                          {`${t("vendors.enable_markup", {
                            ns: "miscSettings",
                          })}:`}
                        </label>
                        <Form.Group inline>
                          {yesNoOptions.map((data, index) => (
                            <Form.Field key={`enablePartMarkup[${index}]`}>
                              <Radio
                                id={`enablePartMarkup[${index}]`}
                                key={`enablePartMarkup[${index}]`}
                                value={data.value}
                                name={"enablePartMarkup"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={data.text}
                                checked={
                                  values.enablePartMarkup.toString() ===
                                  data.value.toString()
                                }
                                className="myLabel"
                              />
                            </Form.Field>
                          ))}
                        </Form.Group>
                        {touched.enablePartMarkup &&
                          errors.enablePartMarkup && (
                            <Label basic color="red">
                              {errors.enablePartMarkup}
                            </Label>
                          )}
                      </Grid.Column>
                      <Grid.Column>
                        <MyIconTextInput
                          name="defaultMarkup"
                          placeholder={"0.00"}
                          icon="percent"
                          label={`${t("vendors.default", {
                            ns: "miscSettings",
                          })}:`}
                          className="drop-down"
                          maxLength={500}
                        />
                      </Grid.Column>
                      {checkApplication(Application.AutomotiveExpert) && (
                        <>
                          <Grid.Column>
                            <Form.Group inline>
                              <Form.Field key={``}>
                                <Radio
                                  id={``}
                                  key={``}
                                  value={``}
                                  name={"enablePartMarkup"}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={`Off`}
                                  checked={false}
                                  className="myLabel"
                                />
                                <Radio
                                  id={``}
                                  key={``}
                                  value={``}
                                  name={"enablePartMarkup"}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={`One`}
                                  checked={false}
                                  className="myLabel"
                                />
                                <Radio
                                  id={``}
                                  key={``}
                                  value={``}
                                  name={"enablePartMarkup"}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  label={`Multiple`}
                                  checked={true}
                                  className="myLabel"
                                />
                              </Form.Field>
                            </Form.Group>
                          </Grid.Column>
                          <Grid.Column>
                            <label
                              className="myLabel"
                              htmlFor="enablePartMarkup"
                            >
                              {`${t("Markup 1", {
                                ns: "miscSettings",
                              })}:`}
                            </label>
                            <Grid>
                              <Grid.Row columns={4}>
                                <Grid.Column>
                                  <MyIconTextInput
                                    name="defaultMarkup"
                                    placeholder={"0.00"}
                                    icon="dollar"
                                    iconPosition="left"
                                    className="width100"
                                    maxLength={500}
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <label
                                    className="myLabel"
                                    htmlFor="enablePartMarkup"
                                  >
                                    {`${t("to", {
                                      ns: "miscSettings",
                                    })}`}
                                  </label>
                                </Grid.Column>
                                <Grid.Column>
                                  <MyIconTextInput
                                    name="defaultMarkup"
                                    placeholder={"0.00"}
                                    icon="dollar"
                                    iconPosition="left"
                                    className="width100"
                                    maxLength={500}
                                  />
                                </Grid.Column>
                                <Grid.Column>
                                  <FormInput
                                    label={`Markup %`}
                                    min={100}
                                    max={2000}
                                    name="duration"
                                    step={100}
                                    type="range"
                                    value={``}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column>
                                  <Button
                                    color="green"
                                    icon="plus"
                                    content="add more"
                                  />
                                </Grid.Column>
                              </Grid.Row>
                              <Grid.Row>
                                <Grid.Column>
                                  <label
                                    className="myLabel"
                                    htmlFor="enablePartMarkup"
                                  >
                                    *Last blank option will equal unlimited
                                    amount
                                  </label>
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Grid.Column>
                        </>
                      )}
                      <Grid.Row>
                        <Grid.Column>
                          <Button
                            color="green"
                            className="save_button"
                            icon="check"
                            content={t("save", { ns: "common" })}
                            disabled={isSubmitting || !dirty || !isValid}
                            loading={isSubmitting}
                            type="submit"
                          />
                          <Button
                            className="save_button"
                            as={Link}
                            to="/settings"
                            icon="cancel"
                            type="reset"
                            content={t("cancel", { ns: "common" })}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Container>
          </>
        </Grid.Column>
      </Grid>
    </>
  );
});
