import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import CustomerDetails from "../../features/customers/CustomerDetails";
import EditCustomerDetails from "../../features/customers/EditCustomerDetails";
import Customers from "../../features/customers/CustomerIndex";
import NotFound from "../../features/errors/NotFound";
import ServerError from "../../features/errors/ServerError";
import Eula from "../../features/staticPages/Eula";
import Privacy from "../../features/staticPages/Privacy";
import CustomerHistory from "../../features/reports/CustomerHistory";
import LaborSales from "../../features/reports/LaborSales";
import MiscCharge from "../../features/reports/MiscCharge";
import PartSales from "../../features/reports/PartSales";
import PayType from "../../features/reports/PayType";
import ReportsMain from "../../features/reports/ReportsMain";
import Tax from "../../features/reports/Tax";
import Technicians from "../../features/reports/Technicians";
import TotalSales from "../../features/reports/TotalSales";
import Vendor from "../../features/reports/Vendor";
import Advanced from "../../features/settings/advanced/Advanced";
import Company from "../../features/settings/company/Company";
import ViewClient from "../../features/systemAdmin/Clients/ViewClient";
import Disclaimers from "../../features/settings/disclaimers/Disclaimers";
import MiscCharges from "../../features/miscCharges/MiscellaneousCharges";
import PackageJobs from "../../features/packageJobs/PackageJobs";
import Pricing from "../../features/settings/pricing/Pricing";
import SettingsMain from "../../features/settings/SettingsMain";
import Techs from "../../features/techs/Techs";
import Ticket from "../../features/settings/ticket/Ticket";
import Vendors from "../../features/vendor/Vendors";
import Workflow from "../../features/settings/workflow/Workflow";
import Terms from "../../features/staticPages/Terms";
import TicketDashboard from "../../features/tickets/dashboard/TicketDashboard";
import RecentTickets from "../../features/tickets/recentTickets/RecentTickets";
import AdminPage from "../../features/users/admin/AdminPage";
import ChangePassword from "../../features/users/passwordchange/ChangePassword";
import ForgotPassword from "../../features/users/passwordchange/ForgotPassword";
import ConfirmEmail from "../../features/users/registration/ConfirmEmail";
import App from "../layout/App";
import RequireAuth from "./RequireAuth";
import Abandoned from "../../features/tickets/abandoned/Abandoned";
import LandingTabs from "../../features/systemAdmin/LandingTabs";
import Registration from "../../features/users/registration/Registration";
import AvailableTrials from "../../features/Subscription/AvailableTrials";
import ResendConfirmEmail from "../../features/users/registration/ResentConfirmEmail";
import MiscellaneousCharge from "../../features/miscCharges/MiscellaneousCharge";
import SubscriptionContent from "../../features/Subscription/SubscriptionContent";
import Details from "../../features/users/form/Details";
import CustomVehicles from "../../features/customVehicle/CustomVehicles";
import { AccessLevel, Application, ClientStatus } from "../models/enums";
import AccessDenied from "../../features/errors/AccessDenied";
import Search from "../../features/search/search";
import { TicketWorkflowSteps } from "../../app/models/enums";
import TicketStart from "../../features/tickets/dashboard/TicketStart";
import TechTracking from "../../features/techs/TechTracking";
import ViewUser from "../../features/systemAdmin/Users/ViewUser";
import AuthLogs from "../../features/systemAdmin/records/AuthLogs";
import CardTransaction from "../../features/reports/CardTransaction";
import UpdateAnnouncement from "../../features/systemAdmin/tools/UpdateAnnouncement";
import ViewAnnouncement from "../../features/systemAdmin/tools/ViewAnnouncement";
import ManageAnnouncements from "../../features/systemAdmin/tools/ManageAnnouncements";
import ClientDataRecovery from "../../features/systemAdmin/Clients/ClientDataRecovery";
import TicketPdfView from "../../features/tickets/Pdf/TicketPdfView";
import TicketPdf from "../../features/tickets/Pdf/TicketViewComponents/TicketPdf";
import RepairOrderPdfView from "../../features/tickets/Pdf/RepairOrderPdfView";
import PaymentProcessing from "../../features/tickets/paymentProcessing/PaymentProcessing";
import Status from "../../features/Subscription/Status";
import BillingInformation from "../../features/Subscription/BillingInformation";
import ServiceType from "../../features/Subscription/ServiceType";
import AdditionalUsers from "../../features/Subscription/AdditionalUsers";
import RenewalOptions from "../../features/Subscription/RenewalOptions";
import CancelSubscription from "../../features/Subscription/CancelSubscription";
import TicketReceiptPdfView from "../../features/tickets/Pdf/TicketReceiptPdfView";
import UpdateClientStatus from "../../features/systemAdmin/Clients/UpdateClientStatus";
import ClientPhoneNumbers from "../../features/systemAdmin/Clients/ClientPhoneNumbers";
import ClientAddresses from "../../features/systemAdmin/Clients/ClientAddresses";
import InventoryIndex from "../../features/inventory/InventoryIndex";
import PartDetails from "../../features/inventory/PartDetails";
import InventorySettings from "../../features/settings/inventory/InventorySettings";
import Inventory from "../../features/reports/Inventory";
import AccountsReceivable from "../../features/reports/AccountsReceivable";
import ARSettings from "../../features/settings/accountsReceivable/ARSettings";
import ARIndex from "../../features/accountsReceivable/ARIndex";
import EmailDefaults from "../../features/settings/ticket/EmailDefaults";
import SelectableComments from "../../features/settings/ticket/SelectableComments";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        element: (
          <RequireAuth
            status={ClientStatus.PreTrial}
            accessLevel={AccessLevel.Manager}
          />
        ),
        children: [
          {
            path: "account/resendVerificationEmail",
            element: <ResendConfirmEmail />,
          },

          { path: "settings", element: <SettingsMain /> },
          { path: "settings/company", element: <Company activeTab={0} /> },
          { path: "settings/companyLogo", element: <Company activeTab={1} /> },
          { path: "settings/pricing", element: <Pricing activeTab={0} /> },
          { path: "settings/laborRates", element: <Pricing activeTab={0} /> },
          { path: "settings/salesTax", element: <Pricing activeTab={1} /> },
          { path: "settings/payTypes", element: <Pricing activeTab={2} /> },
          { path: "settings/ticket", element: <Ticket activeTab={0} /> },
          { path: "settings/formatting", element: <Ticket activeTab={0} /> },
          { path: "settings/numbers", element: <Ticket activeTab={1} /> },
          { path: "settings/inputDefaults", element: <Ticket activeTab={2} /> },
          {
            path: "settings/ticketDefaults",
            element: <Ticket activeTab={3} />,
          },
          {
            path: "settings/Disclaimers",
            element: <Disclaimers activeTab={0} />,
          },
          {
            path: "settings/invoiceDisclaimers",
            element: <Disclaimers activeTab={1} />,
          },
          {
            path: "settings/estimateDisclaimers",
            element: <Disclaimers activeTab={0} />,
          },
          { path: "settings/workFlow", element: <Workflow activeTab={0} /> },
          { path: "settings/ticketPath", element: <Workflow activeTab={0} /> },
          {
            path: "settings/manufacturers",
            element: <Workflow activeTab={1} />,
          },
          {
            path: "settings/customVehicles",
            element: <Workflow activeTab={2} />,
          },
          { path: "settings/advanced", element: <Advanced activeTab={0} /> },
          { path: "settings/quickvin", element: <Advanced activeTab={0} /> },

          { path: "settings/packageJobs", element: <PackageJobs /> },
          { path: "settings/miscCharges", element: <MiscCharges /> },
          {
            path: "settings/miscCharges/addMiscCharge",
            element: <MiscellaneousCharge key="add" />,
          },
          {
            path: "settings/misccharges/editMiscCharge/:id",
            element: <MiscellaneousCharge key="change" />,
          },
        ],
      },

      {
        element: <RequireAuth status={ClientStatus.PreTrial} />,
        children: [
          {
            path: "users/changePassword/:id",
            element: <ChangePassword isChange={true} />,
          },
        ],
      },
      {
        element: (
          <RequireAuth
            status={ClientStatus.SignUpInProgress | ClientStatus.Active}
            accessLevel={AccessLevel.Owner}
          />
        ),
        children: [{ path: "trials", element: <AvailableTrials /> }],
      },

      {
        element: (
          <RequireAuth status={ClientStatus.Trial | ClientStatus.Active} />
        ),
        children: [
          { path: "ticket/", element: <TicketStart /> },
          {
            path: "ticket/vehicle/:id/:complete",
            element: (
              <TicketDashboard activeTab={TicketWorkflowSteps.Vehicle} />
            ),
          },
          {
            path: "ticket/jobCategory/:id/:complete",
            element: (
              <TicketDashboard activeTab={TicketWorkflowSteps.JobCategory} />
            ),
          },
          {
            path: "ticket/jobType/:id/:complete",
            element: (
              <TicketDashboard activeTab={TicketWorkflowSteps.JobType} />
            ),
          },
          {
            path: "ticket/job/:id/:complete",
            element: <TicketDashboard activeTab={TicketWorkflowSteps.Job} />,
          },
          {
            path: "ticket/time/:id/:complete",
            element: <TicketDashboard activeTab={TicketWorkflowSteps.Time} />,
          },
          {
            path: "ticket/summary/:id/:complete",
            element: (
              <TicketDashboard activeTab={TicketWorkflowSteps.Summary} />
            ),
          },
          {
            path: "ticket/customer/:id/:complete",
            element: (
              <TicketDashboard activeTab={TicketWorkflowSteps.Customer2} />
            ),
          },
          {
            path: "ticket/start/customer/:id/",
            element: (
              <TicketDashboard activeTab={TicketWorkflowSteps.Customer1} />
            ),
          },
          {
            path: "ticket/finalize/:id/:complete",
            element: (
              <TicketDashboard activeTab={TicketWorkflowSteps.Finalize} />
            ),
          },
          {
            path: "ticket/customVehicles/:ticketId/:complete",
            element: <CustomVehicles isTicket={true} />,
          },
          { path: "ticket/processPayment/:id", element: <PaymentProcessing /> },
          {
            path: "ticket/print/:id/",
            element: <TicketPdfView isPrintView={true} />,
          },
          {
            path: "ticket/view/:id",
            element: <TicketPdfView isPrintView={false} />,
          },
          { path: "ticket/pdf/receipt/:id", element: <TicketReceiptPdfView /> },
          // { path: "ticket/pdf/htmlView/:id", element: <HtmlTicketView /> },
          { path: "ticket/pdf/view/:id", element: <TicketPdf /> },
          {
            path: "ticket/pdf/repairOrder/:id",
            element: <RepairOrderPdfView />,
          },
        ],
      },
      {
        element: (
          <RequireAuth
            status={ClientStatus.Trial}
            accessLevel={AccessLevel.Owner}
          />
        ),
        children: [
          { path: "subscription/", element: <Status /> },
          { path: "subscription/Status", element: <Status /> },
          {
            path: "subscription/BillingInformation",
            element: <BillingInformation />,
          },
          {
            path: "subscription/AvailableTrials",
            element: <AvailableTrials />,
          },
          { path: "staff", element: <AdminPage /> },
          { path: "staff/editStaff/:id", element: <Details isAdd={false} /> },
          { path: "staff/addStaff", element: <Details isAdd={true} /> },
        ],
      },
      {
        element: (
          <RequireAuth
            status={ClientStatus.Active | ClientStatus.Subscription}
            accessLevel={AccessLevel.Owner}
          />
        ),
        children: [
          { path: "subscription/ServiceType", element: <ServiceType /> },
          { path: "subscription/Users", element: <AdditionalUsers /> },
          { path: "subscription/RenewalOptions", element: <RenewalOptions /> },
          { path: "subscription/Cancel", element: <CancelSubscription /> },
        ],
      },
      {
        element: (
          <RequireAuth
            status={ClientStatus.Trial | ClientStatus.Active}
            application={Application.RealTimeLaborGuidePro}
          />
        ),
        children: [
          { path: "search/:searchType/:searchValue", element: <Search /> },
          { path: "Customers", element: <Customers /> },
          { path: "Customer/edit/:id", element: <EditCustomerDetails /> },
          { path: "Customer/:id", element: <CustomerDetails /> },

          { path: "recent", element: <RecentTickets /> },
          { path: "abandoned", element: <Abandoned /> },
          { path: "ticket/techtracking/:id", element: <TechTracking /> },
        ],
      },
      {
        element: (
          <RequireAuth
            status={ClientStatus.Trial | ClientStatus.Active}
            application={Application.RealTimeLaborGuidePro}
            accessLevel={AccessLevel.Manager}
          />
        ),
        children: [
          { path: "reports", element: <ReportsMain /> },
          { path: "reports/totalSales", element: <TotalSales /> },
          { path: "reports/laborSales", element: <LaborSales /> },
          { path: "reports/partSales", element: <PartSales /> },
          { path: "reports/miscCharges", element: <MiscCharge /> },
          { path: "reports/tax", element: <Tax /> },
          { path: "reports/payType", element: <PayType /> },
          { path: "reports/technician", element: <Technicians /> },
          { path: "reports/vendor", element: <Vendor /> },
          { path: "reports/customerHistory/:id", element: <CustomerHistory /> },
          { path: "reports/cardTransaction", element: <CardTransaction /> },
          { path: "settings/techs", element: <Techs /> },
          { path: "settings/vendors", element: <Vendors /> },
          { path: "settings/import", element: <Advanced activeTab={1} /> },
          {
            path: "settings/paymentProcessing",
            element: <Advanced activeTab={2} />,
          },
        ],
      },
      {
        element: (
          <RequireAuth
            status={ClientStatus.Trial | ClientStatus.Active}
            application={Application.AutomotiveExpert}
          />
        ),
        children: [
          { path: "inventoryIndex/", element: <InventoryIndex /> },
          { path: "inventory/partDetails", element: <PartDetails /> },
        ],
      },
      {
        element: (
          <RequireAuth
            status={ClientStatus.Trial | ClientStatus.Active}
            application={Application.AutomotiveExpert}
            accessLevel={AccessLevel.Manager}
          />
        ),
        children: [
          { path: "settings/accountsReceivable", element: <ARSettings /> },
          {
            path: "settings/emailDefaults",
            element: <Ticket activeTab={4} />,
          },
          {
            path: "settings/selectableComments",
            element:  <Ticket activeTab={5} />,
          },
          { path: "settings/inventory", element: <InventorySettings /> },
        ],
      },
      {
        element: (
          <RequireAuth
            status={ClientStatus.Active | ClientStatus.Subscription}
            accessLevel={AccessLevel.SystemAdministrator}
          />
        ),
        children: [
          {
            path: "admin",
            element: <LandingTabs activeTab={0} activeSubTab={undefined} />,
          },
          {
            path: "admin/companyList",
            element: <LandingTabs activeTab={1} activeSubTab={undefined} />,
          },
          {
            path: "admin/userList",
            element: <LandingTabs activeTab={0} activeSubTab={undefined} />,
          },
          { path: "admin/company/:id", element: <ViewClient /> },
          {
            path: "admin/Logs/AdminNotes",
            element: <LandingTabs activeTab={2} activeSubTab={0} />,
          },
          {
            path: "admin/Logs/AuthLogs/",
            element: <LandingTabs activeTab={2} activeSubTab={1} />,
          },
          {
            path: "admin/Logs/CarFaxNightly",
            element: <LandingTabs activeTab={2} activeSubTab={2} />,
          },
          {
            path: "admin/Tools/IpLookup/:ipAddress?",
            element: <LandingTabs activeTab={3} activeSubTab={0} />,
          },
          {
            path: "admin/Tools/ManageAnnouncements",
            element: <LandingTabs activeTab={3} activeSubTab={1} />,
          },
          {
            path: "admin/Tools/TrialEmails",
            element: <LandingTabs activeTab={3} activeSubTab={2} />,
          },
          {
            path: "admin/companyReview",
            element: <LandingTabs activeTab={4} activeSubTab={undefined} />,
          },
          {
            path: "admin/Reports/MultiRecurring",
            element: <LandingTabs activeTab={5} activeSubTab={0} />,
          },
          {
            path: "admin/Reports/noLogins",
            element: <LandingTabs activeTab={5} activeSubTab={1} />,
          },
          {
            path: "admin/Reports/subEnded",
            element: <LandingTabs activeTab={5} activeSubTab={2} />,
          },
          {
            path: "admin/Reports/authNetSettle",
            element: <LandingTabs activeTab={5} activeSubTab={3} />,
          },
          {
            path: "admin/Reports/activeServiceTypes",
            element: <LandingTabs activeTab={5} activeSubTab={4} />,
          },
          {
            path: "admin/Reports/carfaxShopData",
            element: <LandingTabs activeTab={5} activeSubTab={5} />,
          },
          {
            path: "admin/Stats/Overview",
            element: <LandingTabs activeTab={6} activeSubTab={0} />,
          },
          {
            path: "admin/Stats/SignUp",
            element: <LandingTabs activeTab={6} activeSubTab={1} />,
          },
          {
            path: "admin/Stats/TrialActivity",
            element: <LandingTabs activeTab={6} activeSubTab={2} />,
          },
          {
            path: "admin/Stats/CoUsers",
            element: <LandingTabs activeTab={6} activeSubTab={3} />,
          },
          {
            path: "admin/Stats/Extensions",
            element: <LandingTabs activeTab={6} activeSubTab={4} />,
          },
          {
            path: "admin/AdminTools/cancellationReport",
            element: <LandingTabs activeTab={7} activeSubTab={0} />,
          },
          {
            path: "admin/AdminTools/recurringCharges",
            element: <LandingTabs activeTab={7} activeSubTab={1} />,
          },
          {
            path: "admin/AdminTools/nextChargeAdjustment",
            element: <LandingTabs activeTab={7} activeSubTab={2} />,
          },
          {
            path: "admin/AdminTools/earlyChargeReport",
            element: <LandingTabs activeTab={7} activeSubTab={3} />,
          },
          {
            path: "admin/AdminTools/subscriberReport",
            element: <LandingTabs activeTab={7} activeSubTab={4} />,
          },
          {
            path: "admin/company/dataRecovery/:id",
            element: <ClientDataRecovery />,
          },
          {
            path: "admin/company/companyPhoneNumbers/:id/:phoneNumber",
            element: <ClientPhoneNumbers />,
          },
          {
            path: "admin/client/clientPhoneNumbers/:id/:phoneNumber",
            element: <ClientPhoneNumbers />,
          },
          {
            path: "admin/company/companyAddresses/:id",
            element: <ClientAddresses />,
          },
          {
            path: "admin/client/clientAddresses/:id",
            element: <ClientAddresses />,
          },
          { path: "admin/userManage/:id", element: <ViewUser /> },
          { path: "admin/authLog/user/:userId", element: <AuthLogs /> },
          { path: "admin/authLog/client/:clientId", element: <AuthLogs /> },
          { path: "admin/authLog/LookupIp/:ip", element: <AuthLogs /> },
          {
            path: "admin/UpdateAnnouncement/:id",
            element: <UpdateAnnouncement />,
          },
          { path: "admin/AddAnnouncement/", element: <UpdateAnnouncement /> },
          { path: "admin/ViewAnnouncement/:id", element: <ViewAnnouncement /> },
        ],
      },
      { path: "accessDenied", element: <AccessDenied /> },
      { path: "not-found", element: <NotFound /> },
      { path: "server-error", element: <ServerError /> },
      { path: "forgotpassword", element: <ForgotPassword /> },
      { path: "account/verifyEmail", element: <ConfirmEmail /> },
      {
        path: "account/resetpassword/:id/:token",
        element: <ChangePassword isChange={false} />,
      },
      { path: "eula", element: <Eula /> },
      { path: "privacy", element: <Privacy /> },
      { path: "terms", element: <Terms /> },
      { path: "registration", element: <Registration /> },
      { path: "login", element: <Navigate replace to="/" /> },
      { path: "*", element: <Navigate replace to="/" /> }, // temporary till we get urls fixed not-found
    ],
  },
];

export const router = createBrowserRouter(routes);
