import { useField } from "formik";
import React from "react";
import { Form, Input, Label } from "semantic-ui-react";
import MyHelpButton from "./MyHelpButton";

interface Props {
  placeholder: string;
  name: string;
  type?: string;
  label?: string;
  className?: string;
  icon: string;
  iconPosition?: "left";
  showHelp?: boolean;
  helpName?: string;
  disabled?: boolean;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  value?: string;
  maxLength: number;
}

export default function MyIconTextInput(props: Readonly<Props>) {
  const [field, meta, helpers] = useField(props.name);
  let labelClassName: string = "myLabel";
  let inputClassName: string ="input";
  if (props.className) labelClassName = `${labelClassName} ${props.className}`;
  if (props.className) inputClassName = `${inputClassName} ${props.className}`;
  if (props.value) field.value = props.value;

  return (
    <Form.Field
      error={meta.touched && !!meta.error}
      className="ui"
      name={props.name}
    >
      {(props.label && props.label.length > 0) && (
        <label className={labelClassName} htmlFor={props.name}>
          {`${props.label} `}
          {props.showHelp && (
            <MyHelpButton
              name={`${props.name}.help`}
              help={props.helpName ? props.helpName : ""}
              color={"blue"}
            />
          )}
        </label>
      )}
      <Input
        iconPosition={props.iconPosition}
        className={inputClassName}
        {...field}
        name={props.name}
        type={props.type}
        icon={props.icon? props.icon : undefined}
        placeholder={props.placeholder}
        value={field.value || undefined}
        disabled={props.disabled}
        onChange={(e, d) => {
          field.onChange(e);
          let val = d.value !== "" ? d.value : undefined;
          helpers.setValue(val);
          if (props.onChange) props.onChange(d.value);
        }}
        onBlur={(e: any, d :any) => {
          field.onBlur(e);
          if (props.onBlur) props.onBlur(field.value);
        }}
        id={props.name}
        spellCheck={true}
      />
      {(meta.touched && meta.error) && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
