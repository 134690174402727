import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../app/stores/store";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";
import { TicketItem } from "../../../../app/models/ticket";

interface Props {
  ticketItems: TicketItem[];
}

export default observer(function RepairOrderLaborPdfView({
  ticketItems,
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);
  const { ticketStore } = useStore();
  const { selectedSettingsTicketFormatting } = ticketStore;

  return (
    <View style={{ ...compose("view pageWidth") }}>
      <View
        style={{
          ...compose(
            " view flex pageWidth borderSolidTop borderSolidRight borderSolidLeft noMargin noPadding lineHeight darkHeader textAlignCenter borderSolidBottom"
          ),
        }}
      >
        <View
          style={{ ...compose("view width100 borderSolidRight padding1mmTop") }}
        >
          <Text>{t("description", { ns: "common" })}</Text>
        </View>
        <View style={{ ...compose("view width100 padding1mmTop") }}>
          <Text>{t("estimate.notes", { ns: "tickets" })}</Text>
        </View>
      </View>
      {ticketItems && ticketItems.length > 0 ? (
        ticketItems.map((x, index) => (
          <View
            key={`ticketItem.${x.id}`}
            style={{
              ...compose(
                `view flex pageWidth borderSolidRight borderSolidLeft noMargin noPadding lineHeight ${
                  ticketItems.length - 1 === index && "borderSolidBottom"
                }  ${
                  selectedSettingsTicketFormatting.shadeAlternatingRows &&
                  index % 2 === 1 &&
                  "backgroundColorEEE"
                }`
              ),
            }}
          >
            <View
              style={{
                ...compose(
                  `view width100 padding1mmTop padding5pxLeft ${
                    selectedSettingsTicketFormatting.showBorderOnPrint &&
                    "borderSolidRight"
                  }`
                ),
              }}
            >
              <Text>{x.description}</Text>
            </View>
            <View
              style={{
                ...compose(
                  `view width100 padding1mmTop textAlignRight padding5pxRight`
                ),
              }}
            >
              <Text></Text>
            </View>
          </View>
        ))
      ) : (
        <View
          style={{
            ...compose(
              `view flex pageWidth borderSolidRight borderSolidLeft noMargin noPadding lineHeightborder borderSolidBottom`
            ),
          }}
        >
          <View
            style={{
              ...compose("view pageWidth padding1mmTop padding5pxLeft"),
            }}
          >
            <Text>{t("pdf_noItems", { ns: "common" })}</Text>
          </View>
        </View>
      )}
    </View>
  );
});
