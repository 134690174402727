import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Divider, Grid, Button, Icon, Label } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Sidebar from "../dashboard/Sidebar";
import PageHeader from "../../../app/layout/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { PaymentStatus, TicketWorkflowSteps } from "../../../app/models/enums";
import { formatDecimal, getNegative } from "../../../app/common/util/functions";
import PaymentTerminal from "./PaymentTerminal";
import TicketPaymentHistory from "../Pdf/TicketPaymentHistory";
import {
  loadTicket,
  resetTicket,
} from "../../../app/common/util/ticketfunctions";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { VehicleFormValues } from "../../../app/models/vehicle";
import { CustomerFormValues } from "../../../app/models/customer";
import { TicketSession } from "../../../app/models/ticket";

export default observer(function PaymentProcessing() {
  const { t } = useTranslation(["common", "translation"]);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const {
    ticketSessionStore,
    pricingStore,
    modalStore,
    ticketStore,
    technicianStore,
    vehicleStore,
    customerStore,
  } = useStore();

  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
    selectedSettingsTicketFormatting,
    loadSettingsTicketFormatting,
  } = ticketStore;
  const {
    setBatchTicketItems,
    populateLaborMap,
    setBatchTicketPaymentItems,
    populateJobLabors,
    selectedTicketSession,
    loadingInitial,
    loadTicket,
    setSelectedPONumber,
    setSelectedTicketSession,
    validatePaymentTotals
//    updatePaymentAmounts,
  } = ticketSessionStore;

  const { setSelectedVehicle, selectedVehicle } = vehicleStore;
  const { setSelectedCustomer, selectedCustomer } = customerStore;

  const {
    loadSettingsTaxRate,
    selectedSettingsTaxRate,
    loadDropdownPayTypeList,
    loadSettingsPayType,
    dropdownPayTypesList,
  } = pricingStore;
  const { selectedSettingsTechnician, loadSettingsTechnician } =
    technicianStore;

  useEffect(() => {
    loadSettingsTaxRate();
  }, [loadSettingsTaxRate]);

  useEffect(() => {
    loadSettingsTicketFormatting();
  }, [loadSettingsTicketFormatting]);

  const [ticketLoaded, setTicketLoaded] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean> (false);

  useEffect(() => {
    setTicketLoaded(true);
    loadDropdownPayTypeList().then(() => {
      resetTicket();
      if (id) {
        loadTicket(id)
          .then((result) => {
            if (result) {
              setSelectedVehicle(new VehicleFormValues(result.vehicle));
              setSelectedCustomer(new CustomerFormValues(result.customer));
              setSelectedPONumber(result.poNumber);

              setSelectedTicketSession(new TicketSession(result));

              if (result.jobLabors && result.jobLabors.length > 0) {
                populateJobLabors(result.jobLabors);
              }

              if (result.ticketItems && result.ticketItems.length > 0) {
                setBatchTicketItems(
                  result.ticketItems,
                  selectedSettingsTicketFormatting.showRemoveAndReplace
                );
              }
              //need this due to the history is being displayed off of it
              if (result.ticketPayments && result.ticketItems.length > 0) {
                setBatchTicketPaymentItems(result.ticketPayments);
              }
              if (result.labors && result.labors.length > 0) {
                populateLaborMap(result.labors);
              }
            }
          })
          .finally(() => setTicketLoaded(false));
      }
    });
  }, [loadDropdownPayTypeList, loadTicket, id]);

  useEffect(() => {
    loadSettingsTechnician();
  }, [loadSettingsTechnician]);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  useEffect(() => {
    loadSettingsPayType(true);
  }, [loadSettingsPayType]);

  if (loadingInitial && ticketLoaded)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <Grid stackable>
      <Grid.Column width={11}>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign="bottom">
              <PageHeader
                type="h1"
                header={t("paymentprocessing", { ns: "common" })}
                className="modal-text-color"
                textAlign="left"
                divider={false}
                addTitle={true}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="bottom">
              <Button
                animated
                color="green"
                floated="right"
                loading={processing}
                disabled = {processing}
                onClick={() => {
                  if (id) {
                    setProcessing(true);
                    validatePaymentTotals().then(() => {
                      setProcessing(false);
                      if (
                        selectedSettingsTechnician.technicianTrackingEnabled
                      ) {
                        navigate(`/ticket/techtracking/${id}`);
                      } else {
                        navigate(`/ticket/view/${id}`);
                      }
                    });
                  }
                }}
              >
                <Button.Content visible>
                  {t("viewTicket", { ns: "tickets" })}
                </Button.Content>
                <Button.Content hidden>
                  <Icon name="caret right" />
                </Button.Content>
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />

        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column verticalAlign="bottom">
              <label className={"myLabel"}>
                {t("estimate.payment_method", { ns: "tickets" })}:
              </label>
            </Grid.Column>
            <Grid.Column verticalAlign="bottom">
              <label className={"myLabel"} htmlFor={"amount"}>
                {t("paymentAmountReceived", {
                  ns: "tickets",
                })}
                :
              </label>
            </Grid.Column>
          </Grid.Row>
          {selectedTicketSession?.ticketPayments &&
            selectedTicketSession?.ticketPayments.length > 0 && (
              <>
                {selectedTicketSession?.ticketPayments.map(
                  (ticketPaymentItem) =>
                    ticketPaymentItem.status == PaymentStatus.Created && (
                      <Grid.Row
                        columns={2}
                        key={`ticketPaymentItem.${ticketPaymentItem.id}`}
                      >
                        <Grid.Column textAlign="left" verticalAlign={"bottom"}>
                          <Label
                            basic
                            simple="true"
                            className="myLabel shortInput labelHeight"
                          >
                            <Icon name={"credit card"} />

                            {ticketPaymentItem.paymentType}
                          </Label>
                        </Grid.Column>
                        <Grid.Column>
                          <Label
                            basic
                            simple="true"
                            className="myLabel shortInput labelHeight"
                            style={{
                              color: getNegative(ticketPaymentItem.amount ?? 0),
                            }}
                          >
                            <Icon name="dollar" />
                            {formatDecimal(ticketPaymentItem.amount ?? 0, 2)}
                          </Label>
                          {ticketPaymentItem.status ===
                            PaymentStatus.Created && (
                            <Button
                              icon="credit card"
                              color="blue"
                              content={t("process", { ns: "tickets" })}
                              onClick={() => {
                                modalStore.openModal(
                                  <PaymentTerminal
                                    header={t("paymentprocessing", {
                                      ns: "common",
                                    })}
                                    ticketPaymentItem={ticketPaymentItem}
                                    ticketId={id ?? ""}
                                    dropdownPaymentItemList={
                                      dropdownPayTypesList
                                    }
                                  />,
                                  false
                                );
                              }}
                            />
                          )}
                        </Grid.Column>
                      </Grid.Row>
                    )
                )}
                <Grid.Row columns={2}>
                  <Grid.Column textAlign="right" verticalAlign="middle">
                    {t("paymentsFinalize", { ns: "tickets" })}:
                  </Grid.Column>
                  <Grid.Column verticalAlign="bottom">
                    <Label
                      basic
                      simple="true"
                      className="myLabel shortInput labelHeight"
                      style={{
                        color: getNegative(0 ?? 0),
                      }}
                    >
                      <Icon name="dollar" />
                      {formatDecimal(0 ?? 0, 2)}
                    </Label>
                  </Grid.Column>
                </Grid.Row>
              </>
            )}

          <Grid.Row>
            <Grid.Column>
              <TicketPaymentHistory ticketId={id ?? ""} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={5}>
        <Grid>
          <Grid.Row></Grid.Row>

          <Grid.Row>
            <Sidebar
              workflowStep={TicketWorkflowSteps.Summary}
              autoCapitalizeFirstLetter={
                selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
              }
              showTaxUpdate={false}
            />
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
});
