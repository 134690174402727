import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import { AuthenticationLogs } from "../../models/systemAdmin";

export default class AuthenticationLogStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams(undefined, 10);
        this.authenticationLogsRegistry.clear();
      }
    );
  }

  authenticationLogsRegistry = new Map<number, AuthenticationLogs>();
  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 10);

  resetAuthenticationLogs=()=> {
    this.authenticationLogsRegistry.clear();
  }


  loadAuthenticationLogs = async (clientId?: string, userId?: string, isRelatedIp?: boolean) => {
    try {
      this.authenticationLogsRegistry.clear();
      const result = isRelatedIp
        ? await agent.SystemAdminLogs.getRelatedIpAuthenticationLog(this.axiosParams, clientId, userId)
        : await agent.SystemAdminLogs.getAuthenticationLog(this.axiosParams, clientId, userId);
      runInAction(() => {
        result.data.forEach((x) => {
          this.setAuthenticationLogs(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setAuthenticationLogs = (authenticationLogs: AuthenticationLogs) => {
    authenticationLogs.createdDate = new Date(authenticationLogs.createdDate);
    this.authenticationLogsRegistry.set(
      authenticationLogs.id,
      authenticationLogs
    );
  };

  get getAuthenticationLogs() {
    return Array.from(this.authenticationLogsRegistry.values()).sort(
      (a, b) => b.createdDate.getTime() - a.createdDate.getTime()
    );
  }

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value?: string
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 10);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
}
