import React, { useMemo } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Icon,
  Label,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { UpsertSelectableCommentFormValues } from "../../../app/models/settings";
import { selectableCommentStatusOptions } from "../../../app/common/options/option";
import MyRadioGroup from "../../../app/common/form/RadioButtonGroups/MyRadioGroup";

interface Props {
  isAdd: boolean;
  clientId: string;
}

export default observer(function UpsertSelectableComment({
  isAdd,
  clientId,
}: Props) {
  const { t } = useTranslation(["common", "translation", "tickets"]);

  const { modalStore, selectableCommentsStore } = useStore();
  const { selectedSelectableComments, createSelectableComment, updateSelectableComment, loadSelectableComments } = selectableCommentsStore;
  const formValues = useMemo(() => selectedSelectableComments ? new UpsertSelectableCommentFormValues(selectedSelectableComments) : new UpsertSelectableCommentFormValues(), [selectedSelectableComments]);

  const validationSchema = Yup.object({
    name: Yup.string().required(
      t("required_selectable_comment_name", {
        ns: "errors",
      })
    ),

    comment: Yup.string().required(
      t("required_description", {
        ns: "errors",
      })
    ),
    status: Yup.number().required(
      t("required_selectable_comment_status", {
        ns: "errors",
      })
    ),
  });

  function handleFormSubmit(
    values: UpsertSelectableCommentFormValues,
    setErrors: (errors: FormikErrors<UpsertSelectableCommentFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void) {


    const action = () => isAdd ? createSelectableComment(values.toJSON()) : updateSelectableComment(selectedSelectableComments?.id!, values.toJSON());

    action()
      .then(() => {
        loadSelectableComments();
      })
      .catch((error) => {
        console.log("error:", error);
      })
      .finally(() => {
        setSubmitting(false);
      })
    modalStore.closeModal();
  }

  return (
    <>
      <PageHeader
        type={"h3"}
        header={isAdd ? t("selectableComments.new", {
          ns: "settings",
        }) : t("selectableComments.edit", {
          ns: "settings",
        })}
        divider={true}
        addTitle={false}
      />
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values, { setErrors, setSubmitting }) => handleFormSubmit(values, setErrors, setSubmitting)}
        initialValues={formValues}
      >
        {({
          handleSubmit,
          setFieldValue,
          errors,
          touched,
        }) => (
          <Form
            className="ui form error"
            autoComplete="on"
            onSubmit={handleSubmit}
          >
            <Grid>
              <Container className="page">
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <MyTextInput
                        placeholder={t("selectableComments.commentName", { ns: "settings", })}
                        label={`${t("selectableComments.commentName", { ns: "settings", })}:`}
                        name={"name"}
                        maxLength={255}
                        autoCapitalizeFirstLetter={false} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <MyTextArea
                        placeholder={t("selectableComments.newComment", { ns: "settings", })}
                        label={`${t("selectableComments.newComment", { ns: "settings", })}:`}
                        name={"comment"}
                        rows={2}
                        maxLength={2000} />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column>
                      <label className="myLabel pb-3">{`${t("status", {
                        ns: "common",
                      })}:`}</label>
                      <Form.Group>
                        <MyRadioGroup
                          inline={false}
                          name={"status"}
                          options={selectableCommentStatusOptions}
                          displayErrorOutsideOfGroup={true}
                        />
                      </Form.Group>
                      {touched.status && errors.status && (
                        <Label basic color="red">
                          {errors.status}
                        </Label>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
              <Grid.Row>
                <Grid.Column columns={2}>
                  <Button color="green" type="submit">
                    <Icon name="check" />
                    {isAdd ? t("add", { ns: "common" }) : t("save", { ns: "common" })}
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      modalStore.closeModal();
                    }}
                  >
                    <Icon name="x" />
                    {t("close", { ns: "common" })}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik >
    </>
  );
});
