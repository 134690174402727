import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { observer } from "mobx-react-lite";
import { Button, Dropdown, DropdownItemProps, DropdownProps, Form, Icon, Label } from "semantic-ui-react";
import MyHelpButton from "./MyHelpButton";

interface Props {
  placeholder: string;
  name: string;
  options: DropdownItemProps[];
  label?: string;
  disabled?: boolean;
  className?: string;
  defaultOpen?: boolean;
  open?: boolean;
  onChangeFunction?: (value: any, text: any) => void;
  onSearchChange?: (value: any) => void;
  showHelp?: boolean;
  helpName?: string;
  showDefaults?: boolean;
  onSetDefaults?: (value: string) => void;
  upward?: boolean;
}

export default observer(function MyDropdownInput(props: Props) {
  const [field, meta, helpers] = useField(props.name);
  const [dropDownOptions, setDropDownOptions] = useState(props.options);


  useEffect(() => {
    if (props.options) setDropDownOptions(props.options);
  }, [props.options, setDropDownOptions]);

  const handleAddition = (
    event: React.SyntheticEvent<HTMLElement, Event>,
    data: DropdownProps
  ) => {
    setDropDownOptions((prevOptions: any) => [
      { text: data.value, value: data.value },
      ...prevOptions,
    ]);
  };

  return (
    <Form.Field
      error={meta.touched && !!meta.error}
      className="ui"
      name={props.name}
    >
      {props.label && (
        <label className="myLabel" htmlFor={props.name}>
          {`${props.label} `}
          {props.showHelp && (
            <MyHelpButton
              name={`${props.name}.help`}
              help={props.helpName ? props.helpName : ""}
              color={"blue"}
            />
          )}
                    {props.showDefaults && (
            <Button
              size={"mini"}
              basic
              floated="right"
              compact
              type="button"
              color="green"
              onClick={() => {
                if (props.onSetDefaults) props.onSetDefaults("test");
              }}
            >
              <Icon
                floated="right"
                color="green"
                name="copy outline"
              />
            </Button>
          )}
        </label>
      )}
      <Dropdown
        name={props.name}
        clearable
        options={dropDownOptions}
        value={field.value || undefined}
        search={(o, s) => { return o.filter((x)=> {return String(x.text).toLowerCase().startsWith(s.toLowerCase())})}}
        selection
        selectOnBlur={true}
        additionPosition="top"
        disabled={props.disabled}
        defaultOpen={props.defaultOpen}
        upward={props.upward}
        open={props.open}
        onChange={(e, d) => {
          let val = d.value !== "" ? d.value : undefined;
          helpers.setValue(val);
          let textfield = e.currentTarget.ariaChecked
            ? e.currentTarget.textContent
            : undefined;
          if (props.onChangeFunction)
            props.onChangeFunction(d.value, textfield);
        }}
        onSearchChange={(e, d) => {
          if (props.onSearchChange) props.onSearchChange(d.searchQuery);
        }}
        onBlur={() => helpers.setTouched(true)}
        onAddItem={(e, d) => {
          if (d.value) handleAddition(e, d);
        }}
        placeholder={props.placeholder}
        className={props.className}
        noResultsMessage=""
        id={props.name}
      />
      {(meta.touched && meta.error) && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
});
