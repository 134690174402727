import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  Vendor,
  VendorsFormValues,
  SettingsVendors,
  SettingsVendorsFormValues,
} from "../models/vendor";
import { v4 as uuid } from "uuid";
import { DropdownItemProps } from "semantic-ui-react";

export default class VendorStore {
  loadingInitial = false;
  loadingListInitial = false;

  vendorRegistry = new Map<string | undefined, Vendor>();
  vendorDropdownList: DropdownItemProps[] = [];

  selectedVendor: VendorsFormValues = {
    id: undefined,
    name: "",
    defaultMarkup: 0,
    isActive: false,
  };

  selectedSettingsVendor: SettingsVendorsFormValues = {
    id: undefined,
    enablePartMarkup: true,
    defaultMarkup: 0,
  };

  activeTab: number = 0;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
        this.vendorRegistry.clear();
        this.loadVendors();
      }
    );
  }

  resetVendorRegistries = () => {
    this.vendorRegistry.clear();
    this.vendorDropdownList = [];
  };

  loadVendors = async () => {
    this.loadingListInitial = true;
    try {
      this.vendorRegistry.clear();
      const result = await agent.Vendors.listVendors();
      runInAction(() => {
        result.forEach((vendors) => {
          this.setVendor(vendors);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingListInitial(false);
    }
  };

  loadDropdownListVendors = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Vendors.dropDownVendorList();
      runInAction(() => {
        this.vendorDropdownList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  get getVendors() {
    return Array.from(this.vendorRegistry.values()).sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    });
  }

  private setVendor = (vendors: Vendor) => {
    vendors.createdDate = new Date(vendors.createdDate);
    this.vendorRegistry.set(vendors.id, vendors);
  };

  getVendor = (id: string) => {
    return this.vendorRegistry.get(id);
  };

  setSelectedVendor = (values: VendorsFormValues) => {
    this.selectedVendor = values;
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setLoadingListInitial = (state: boolean) => {
    this.loadingListInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  createVendor = async (vendor: VendorsFormValues) => {
    try {
      let myNew: Vendor = new Vendor(vendor);
      myNew.id = uuid();
      await agent.Vendors.create(vendor);

      runInAction(() => {
        this.vendorRegistry.clear();
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateVendor = async (vendor: VendorsFormValues) => {
    try {
      let myNew: Vendor = new Vendor(vendor);
      await agent.Vendors.update(myNew);

      runInAction(() => {
        if (vendor.id) {
          let updatedVendor = { ...this.getVendor(vendor.id), ...vendor };
          this.vendorRegistry.set(vendor.id, updatedVendor as Vendor);
          this.selectedVendor = updatedVendor as Vendor;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  toggleVendor = async (id: string) => {
    try {
      await agent.Vendors.toggleVendors(id);
      runInAction(() => {
        if (id) {
          let updatedVendor = this.getVendor(id);
          if (updatedVendor) updatedVendor.isActive = !updatedVendor.isActive;
          this.vendorRegistry.set(id, updatedVendor as Vendor);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  removeVendor = async (id: string) => {
    try {
      await agent.Vendors.removeVendor(id);
      runInAction(() => {
        this.vendorRegistry.delete(id);
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadSettingVendors = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.Vendors.getSettingsVendor();
      runInAction(() => {
        this.selectedSettingsVendor = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  setSelectedSettingsVendors = (values: SettingsVendorsFormValues) => {
    this.selectedSettingsVendor = values;
  };

  createSettingsVendors = async (values: SettingsVendorsFormValues) => {
    try {
      let myNew: SettingsVendors = new SettingsVendors(values);
      await agent.Vendors.addSettingsVendor(myNew);

      runInAction(() => {
        this.setSelectedSettingsVendors(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateSettingsVendors = async (values: SettingsVendorsFormValues) => {
    try {
      let myNew: SettingsVendors = new SettingsVendors(values);
      await agent.Vendors.updateSettingsVendor(myNew);

      runInAction(() => {
        this.setSelectedSettingsVendors(values);
      });
    } catch (error) {
      console.log(error);
    }
  };
}
