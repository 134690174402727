import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import PaymentProcessingDetails from "./PaymentProcessingDetails";
import PaymentProcessingUnPair from "./PaymentProcessingUnPair";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default observer(function PaymentProcessing() {
  const { t } = useTranslation(["common", "advancedSettings", "errors"]);

  const { paymentProcessingStore } = useStore();

  const { loadSettingsPaymentProcessor, selectedPaymentProcessor, loadingInitial } =
    paymentProcessingStore;

  //want to set store in this case for sub pages
  useEffect(() => {
    loadSettingsPaymentProcessor();
  }, [loadSettingsPaymentProcessor]);

  if (loadingInitial)
    return <LoadingComponent content={t("payment_loading", { ns: "advancedSettings" })} />;

  return (
    <>
      <PageHeader
        header={t("payprocessing.header", { ns: "advancedSettings" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={false}
      />
      <>
        <Message
          info
          header={t("payprocessing.banner", { ns: "advancedSettings" })}
        />
        {selectedPaymentProcessor.terminalSerialNumber ? (
          <PaymentProcessingUnPair />
        ) : (
          <PaymentProcessingDetails />
        )}
      </>
    </>
  );
});
