import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { formatCurrency } from "../../../app/common/util/functions";
import { Link } from "react-router-dom";
import PageHeader from "../../../app/layout/PageHeader";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";

export default observer(function RecurringCharges() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { recurringChargesStore } = useStore();
  const {
    loadRecurringChargeReport,
    getRecurringChargesReport,
    loadRecurringChargeTotal,
    pagination,
    setPagingParams,
    pagingParams,
    getRecurringChargeTotal,
    setPredicate,
  } = recurringChargesStore;

  const [filterDate, setFilterDate] = useState<Date>(new Date());

  useEffect(() => {
    setPredicate(filterDate);
    loadRecurringChargeReport();
    loadRecurringChargeTotal();
  }, [loadRecurringChargeReport, loadRecurringChargeTotal]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadRecurringChargeReport();
    }
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={"Recurring Charges"}
              className="modal-text-color"
              textAlign="left"
              divider={false}
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <SemanticDatepicker
              name="startDate"
              format="MM/DD/YYYY"
              placeholder={t("start_date", { ns: "reports" })}
              value={filterDate}
              onChange={(e, d) => {
                if (d.value && !Array.isArray(d.value)) {
                  let newDate = new Date(
                    d.value.getFullYear(),
                    d.value.getMonth(),
                    d.value.getDate()
                  );
                  setFilterDate(newDate);
                }
              }}
            />
            <Button
              color="green"
              className="save_button"
              icon="check"
              content={t("get_report", { ns: "reports" })}
              onClick={() => {
                setPredicate(filterDate);
                loadRecurringChargeReport();
                loadRecurringChargeTotal();
              }}
            />
          </Grid.Column>
          <Grid.Column textAlign="right" className="terms_bold">
            <label>Grand Total: </label>
            {formatCurrency(getRecurringChargeTotal ?? 0)}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Table basic="very" striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>#</Table.HeaderCell>
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Company Name</Table.HeaderCell>
                <Table.HeaderCell>Company ID</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {getRecurringChargesReport &&
              getRecurringChargesReport.length > 0 && (
                <Table.Body>
                  {getRecurringChargesReport.map((info, index) => (
                    <Table.Row key={`recurringRow.${info.id}`}>
                      <Table.Cell>{index + 1}</Table.Cell>
                      <Table.Cell>{info.firstName}</Table.Cell>
                      <Table.Cell>{info.lastName}</Table.Cell>
                      <Table.Cell>
                        <Link to={`/admin/company/${info.id}`}>
                          {info.clientName}
                        </Link>
                      </Table.Cell>
                      <Table.Cell>{info.id}</Table.Cell>
                      <Table.Cell>{formatCurrency(info.amount)}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              )}
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="10">
                  <MyPaging
                    itemCount={pagination ? pagination.totalItems : 25}
                    pageSize={pagingParams.pageSize}
                    currentPage={pagination ? pagination.currentPage : 1}
                    onClick={listOnClick}
                    onChange={listOnClick}
                    floated="right"
                  ></MyPaging>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Row>
      </Grid>
    </>
  );
});
