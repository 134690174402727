import axios, { AxiosResponse } from "axios";
import { Parts } from "../models/parts";
import { PaginatedResult } from "../models/pagination";
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const PartApi = {
  listSearchParts: (params: URLSearchParams) =>
  axios
    .get<PaginatedResult<Parts[]>>(
      `/Parts/listParts`,
      { params }
    )
    .then(responseBody),

};