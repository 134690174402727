import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Grid, Message, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { PagingParams } from "../../../app/models/pagination";
import MyPaging from "../../../app/common/form/MyPaging";
import PageHeader from "../../../app/layout/PageHeader";
import SemanticDatepicker from "react-semantic-ui-datepickers";


export default observer(function CarfaxNightly() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { quickVinNightlyLogStore } = useStore();
  const {
    loadQuickVinNightlyLog,
    getQuickVinNightlyLog,
    QuickVinNightlyLogRegistry,
    pagingParams,
    pagination,
    setPagingParams,
    setPredicate,
    setLoading,
    loading
  } = quickVinNightlyLogStore;

  useEffect(() => {
    loadQuickVinNightlyLog();
  }, [loadQuickVinNightlyLog, pagingParams]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      QuickVinNightlyLogRegistry.clear();
    }
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row></Grid.Row>
        <Grid.Row columns={3}>
          <Grid.Column>
            <label>{t("start_date", { ns: "reports" }) + ":"}</label>{" "}
            <SemanticDatepicker
              name="startDate"
              placeholder={t("start_date", { ns: "reports" })}
              onChange={(e, d) => {
                setPredicate('startDate', d.value ? d.value : undefined);
              }}
            />{" "}
          </Grid.Column>
          <Grid.Column>
            <label>{t("end_date", { ns: "reports" }) + ":"}</label>{" "}
            <SemanticDatepicker
              name="endDate"
              placeholder={t("end_date", { ns: "reports" })}
              onChange={(e, d) => {
                setPredicate('endDate', d.value ? d.value : undefined);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              color="green"
              className="save_button"
              icon="check"
              content={t("get_report", { ns: "reports" })}
              type='button'
              onClick={async (e) => {
                e.preventDefault();
                setLoading(true);
                await loadQuickVinNightlyLog();
                setLoading(false);
              }}
              loading={loading}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={t("logs.carfax_intro", { ns: "systemAdmin" }).toString()}
              className="modal-text-color"
              textAlign="left"
              divider
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Column stackable>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("logs.created", { ns: "systemAdmin" })}
                </Table.HeaderCell>                
                <Table.HeaderCell>
                  {t("logs.companies", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("logs.rows", { ns: "systemAdmin" })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {getQuickVinNightlyLog &&
                getQuickVinNightlyLog.length > 0 ?
                getQuickVinNightlyLog.map((x) => (
                  <Table.Row key={`carFaxNightly}`}>
                    <Table.Cell>{format(x.createdDate, "P, p")}</Table.Cell>
                    <Table.Cell>{x.clientCount}</Table.Cell>
                    <Table.Cell>{x.clientRowCount}</Table.Cell>
                  </Table.Row>
                )) : (
                  <Table.Row key={"noitems"}>
                    <Table.Cell width="8" colSpan={8}>
                      <Message
                        color="yellow"
                        className="textAlignCenter fontStyleItalic"
                      >
                        {t("grid_noItems", { ns: "common" })}
                      </Message>
                    </Table.Cell>
                  </Table.Row>
                )}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <MyPaging
                    itemCount={pagination ? pagination.totalItems : 50}
                    pageSize={pagingParams.pageSize}
                    currentPage={pagination ? pagination.currentPage : 1}
                    onClick={listOnClick}
                    onChange={listOnClick}
                    floated="right"
                  ></MyPaging>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
});
