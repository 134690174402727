import React from "react";
import { Button, Container, Form, Segment } from "semantic-ui-react";
import useQuery from "../../../app/common/util/hooks";
import { useStore } from "../../../app/stores/store";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../app/layout/PageHeader";
import { ErrorMessage, Formik } from "formik";
import ValidationErrors from "../../errors/ValidationErrors";

export default function ResendConfirmEmail() {
  const { userStore } = useStore();
  const { resendEmail } = userStore;
  const email = useQuery().get("email") as string;
  const token = useQuery().get("token") as string;
  const { t } = useTranslation(["common", "registration"]);

  let initial = { email: email, token: token, error: "" };

  return (
    <>
      <PageHeader
        header={t("email.resendEmailVerify", { ns: "registration" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container text>
        <Segment textAlign="left" className="form-background-color">
          <Formik
            initialValues={initial}
            onSubmit={(values, { setErrors }) =>
              resendEmail()
                .then(() => {
                  //TODO figure out if this is needed
                })
                .catch((error) => setErrors({ error }))
            }
          >
            {({ handleSubmit, isSubmitting, errors, isValid, dirty }) => (
              <Form
                className="ui form error"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
                <p>{t("email.resendMessage", { ns: "registration" })}</p>
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  icon={"check"}
                  color={"green"}
                  positive
                  content={t("email.resend", { ns: "registration" })}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </Segment>
      </Container>
    </>
  );
}
