import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Label,
  Message,
  Radio,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import { Link } from "react-router-dom";
import MyTextInput from "../../../app/common/form/MyTextInput";
import StateSelect from "../../../app/common/form/StateSelect";
import PageHeader from "../../../app/layout/PageHeader";
import { yesNoOptions } from "../../../app/common/options/option";
import { SettingsTicketInputDefaultsFormValues } from "../../../app/models/ticket";
import { useStore } from "../../../app/stores/store";
import { RegexConstants } from "../../../app/common/util/regexConstants";
import ValidationErrors from "../../errors/ValidationErrors";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyHelpButton from "../../../app/common/form/MyHelpButton";

export default observer(function InputDefaults() {
  const { t } = useTranslation(["common", "ticketSettings"]);

  const { ticketStore } = useStore();

  const {
    loadSettingsTicketInputDefaults,
    createSettingsTicketInputDefaults,
    updateSettingsTicketInputDefaults,
    loadingInitial,
  } = ticketStore;

  const validationSchema = Yup.object({
    capitalizeFirstCharacter: Yup.boolean().required(),
    formatPhoneNumber: Yup.boolean().required(),
    defaultPostalCode: Yup.string()
      .nullable()
      .matches(
        new RegExp(RegexConstants.postalCodeExp),
        t("registration.invalid_postalcode", { ns: "errors" })
      )
      .max(50),
  });

  const [settingsTicketInputDefaults, setSettingsTicketInputDefaults] =
    useState<SettingsTicketInputDefaultsFormValues>(
      new SettingsTicketInputDefaultsFormValues()
    );

  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadValues();
  }, [loadSettingsTicketInputDefaults]);

  function loadValues() {
    loadSettingsTicketInputDefaults().then((ticketInputDefaults) =>
      setSettingsTicketInputDefaults(
        new SettingsTicketInputDefaultsFormValues(ticketInputDefaults)
      )
    );
  }

  function handleFormSubmit(
    values: SettingsTicketInputDefaultsFormValues,
    setErrors: (
      errors: FormikErrors<SettingsTicketInputDefaultsFormValues>
    ) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsTicketInputDefaults(values)
      : () => createSettingsTicketInputDefaults(values);

    action()
      .then(() => {
        loadValues();
        setSubmitting(false);
        setCompleted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("input_loading", { ns: "ticketSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("inputdefaults", { ns: "common" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />

      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsTicketInputDefaults}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
              <Grid columns={2} stackable>
                <Grid.Row>
                  <Grid.Column>
                    <label className="required">
                      {`${t("input_defaults.autocapitalizefirstchar", {
                        ns: "ticketSettings",
                      })}:`}
                    </label>
                    <MyHelpButton
                      name="autoCapitalize"
                      position="bottom left"
                      help="settings.ticket.autoCapitalize"
                      color={"blue"}
                    />
                    <Form.Group inline>
                      {yesNoOptions.map((data, index) => (
                        <Form.Field
                          key={`capitalizeFirstCharacter[${data.value}]`}
                        >
                          <Radio
                            id={`capitalizeFirstCharacter[${index}]`}
                            key={`capitalizeFirstCharacter[${data.value}]`}
                            value={data.value}
                            name="capitalizeFirstCharacter"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={data.text}
                            checked={
                              values.capitalizeFirstCharacter.toString() ===
                              data.value
                            }
                          />
                        </Form.Field>
                      ))}
                      {touched.capitalizeFirstCharacter &&
                        errors.capitalizeFirstCharacter && (
                          <Label basic color="red">
                            {errors.capitalizeFirstCharacter}
                          </Label>
                        )}
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <label className="required">
                      {`${t("input_defaults.autoformatphone", {
                        ns: "ticketSettings",
                      })}:`}
                    </label>
                    <MyHelpButton
                      name="autoFormatPhoneNumber"
                      position="bottom right"
                      help="settings.ticket.formatPhoneNumber"
                      color={"blue"}
                    />
                    <Form.Group inline>
                      {yesNoOptions.map((data, index) => (
                        <Form.Field key={`formatPhoneNumber[${data.value}]`}>
                          <Radio
                            id={`formatPhoneNumber[${index}]`}
                            key={`formatPhoneNumber[${data.value}]`}
                            value={data.value}
                            name="formatPhoneNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={data.text}
                            checked={
                              values.formatPhoneNumber.toString() === data.value
                            }
                          />
                        </Form.Field>
                      ))}
                      {touched.formatPhoneNumber &&
                        errors.formatPhoneNumber && (
                          <Label basic color="red">
                            {errors.formatPhoneNumber}
                          </Label>
                        )}
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
                <PageHeader
                  header={t("input_defaults.newcustomerdefaults", {
                    ns: "ticketSettings",
                  })}
                  type={"h3"}
                  divider={true}
                  addTitle={false}
                />
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("input_defaults.city", {
                        ns: "ticketSettings",
                      })}
                      name="defaultCity"
                      label={
                        t("input_defaults.city", { ns: "ticketSettings" }) + ":"
                      }
                      showHelp
                      helpName="settings.ticket.defaultCity"
                      autoCapitalizeFirstLetter={
                        settingsTicketInputDefaults.capitalizeFirstCharacter
                      }
                      maxLength={255}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <StateSelect
                      label={
                        t("input_defaults.state", { ns: "ticketSettings" }) +
                        ":"
                      }
                      placeholder={t("input_defaults.state", {
                        ns: "ticketSettings",
                      })}
                      name="defaultState"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("input_defaults.postalcode", {
                        ns: "ticketSettings",
                      })}
                      name="defaultPostalCode"
                      label={`${t("input_defaults.postalcode", {
                        ns: "ticketSettings",
                      })}:`}
                      showHelp
                      helpName="settings.ticket.defaultZip"
                      autoCapitalizeFirstLetter={
                        settingsTicketInputDefaults.capitalizeFirstCharacter
                      }
                      maxLength={50}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color="green"
                      className="save_button"
                      icon="check"
                      content={t("save", { ns: "common" })}
                      disabled={isSubmitting || !dirty || !isValid}
                      loading={isSubmitting}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/settings"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
