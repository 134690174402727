import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Grid, Button, Table, Message } from "semantic-ui-react";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default observer(function CancellationReport() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { cancellationReportStore } = useStore();
  const {
    loadCancellationReport,
    getCancellationReport,
    pagination,
    setPagingParams,
    pagingParams,
    loading,
    cancellationReportRegistry,
    setPredicate
  } = cancellationReportStore;


  useEffect(() => {
    loadCancellationReport();
  }, []);



  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      cancellationReportRegistry.clear();
    }
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h2"
              header="Cancellation Report"
              className="modal-text-color"
              textAlign="left"
              divider
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row columns={3}>
          <Grid.Column>
            <label>{"Start Date:"}</label>{" "}
            <SemanticDatepicker
              name="startDate"
              placeholder="Start Date"
              onChange={(e, d) => {
                setPredicate('startDate', d.value ? d.value : undefined);
              }}
            />{" "}
          </Grid.Column>
          <Grid.Column>
            <label>{"End Date:"}</label>{" "}
            <SemanticDatepicker
              name="endDate"
              placeholder="End Date"
              onChange={(e, d) => {
                setPredicate('endDate', d.value ? d.value : undefined);
              }}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              color="green"
              className="save_button"
              icon="check"
              content={"Get Report"}
              loading={loading}
              onClick={() => loadCancellationReport()}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.Cell width={12} className="terms_bold" textAlign="left">
                  Date Of Cancellation
                </Table.Cell>
                <Table.Cell className="terms_bold" textAlign="left">
                  Number of Cancellations
                </Table.Cell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getCancellationReport && getCancellationReport.length > 0 ? (
                getCancellationReport.map((x) => (
                  <Table.Row key={`subGap.${x.date.getDate().toString()}`}>
                    <Table.Cell width={12}>{format(x.date, "P p")}</Table.Cell>
                    <Table.Cell>{x.count}</Table.Cell>
                  </Table.Row>
                ))) : (
                <Table.Row key={"noitems"}>
                  <Table.Cell width="8" colSpan={8}>
                    <Message
                      color="yellow"
                      className="textAlignCenter fontStyleItalic"
                    >
                      {t("grid_noItems", { ns: "common" })}
                    </Message>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan='12'>
                    <MyPaging
                      itemCount={pagination ? pagination.totalItems : 25}
                      pageSize={pagingParams.pageSize}
                      currentPage={pagination ? pagination.currentPage : 1}
                      onClick={listOnClick}
                      onChange={listOnClick}
                      floated="right"
                    ></MyPaging>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
          </Table>
        </Grid.Row>
      </Grid>
    </>
  );
});
