import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import PageHeader from "../../app/layout/PageHeader";
import ValidationErrors from "../errors/ValidationErrors";

export default observer(function LoginForm() {
  const { userStore } = useStore();
  const { t } = useTranslation(["common", "users", "errors"]);

  return (
    <Formik
      initialValues={{
        login: "",
        password: "",
        ipAddress: "",
        location: "",
        error: undefined,
      }}
      onSubmit={(values, { setErrors }) =>
        userStore
          .ipCheck()
          .then((ip) => {
            if (ip.ip) values.ipAddress = ip.ip;
            if (ip.country_code) values.location = ip.country_code;
            userStore.login(values).then(()=>{
              userStore.initialRedirects();
            }).catch((error) => {
              setErrors({ error: error });
            });
          })
          .catch((error) => {
            setErrors({ error: error });
          })
      }
      validationSchema={Yup.object({
        login: Yup.string().required(
          t("registration.required_UserName", { ns: "errors" })
        ),
        password: Yup.string().required(
          t("registration.required_Password", { ns: "errors" })
        ),
      })}
    >
      {({ handleSubmit, isSubmitting, errors, setErrors }) => (
        <Form className="ui form error" onSubmit={handleSubmit} autoComplete="off">
          <PageHeader
            type="h2"
            header={t("login", { ns: "common" })}
            textAlign="center"
            divider={false}
            addTitle={false}
          />
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <MyTextInput
            name="login"
            placeholder={t("email_username", { ns: "common" })}
            autoCapitalizeFirstLetter={false}
            maxLength={255}
          />
          <MyTextInput
            name="password"
            placeholder={t("password", { ns: "common" })}
            type="password"
            autoCapitalizeFirstLetter={false}
            maxLength={50}
          />

          <Button
            loading={isSubmitting}
            className="modal-button-color"
            size="huge"
            positive
            disabled={isSubmitting}
            content={t("login", { ns: "common" })}
            type="submit"
            fluid
          />
          <Link to={"/forgotpassword"}>
            {t("login.forgot_password", { ns: "users" })}
          </Link>
        </Form>
      )}
    </Formik>
  );
});
