import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Grid, Icon, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import PageHeader from "../../../../app/layout/PageHeader";
import { SelectableCommentsDto } from "../../../../app/models/settings";
import ValidationErrors from "../../../errors/ValidationErrors";
import MyPaging from "../../../../app/common/form/MyPaging";
import { PagingParams } from "../../../../app/models/pagination";

interface Props {
  currentNotes: string | undefined;
  setNotes: (newNotes: string) => void;
}

export default observer(function AddSelectableCommentsToNotes({
  currentNotes, setNotes
} : Props) {
  const { t } = useTranslation(["common", "translation", "tickets"]);
  const { modalStore, selectableCommentsStore, userStore, ticketSessionStore } = useStore();
  const { user } = userStore;
  const { addSelectableCommentsToTicketNotes } = ticketSessionStore;
  const {
    loadSelectableComments,
    getSelectableComments,
    pagingParams,
    setPagingParams,
    pagination,
    SelectableCommentsRegistry
  } = selectableCommentsStore;
  const [commentsSelected, setCommentsSelected] = useState<SelectableCommentsDto[]>([]);

  const validationSchema = Yup.object({
    selectedComments: Yup.array().required(
      t("descriptionRequired", {
        ns: "errors",
      })
    ),
  });


  useEffect(
    () => {
      loadSelectableComments();
    },
    []
  );

  function handleFormSubmit(values: SelectableCommentsDto[]) {
    var newNotes = (currentNotes ?? "") + ` ${Array.from(new Set(values)).map(c => c.comment).join(' ')}`
    addSelectableCommentsToTicketNotes(newNotes);
    setNotes(newNotes);
    modalStore.closeModal();
  }

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadSelectableComments();
    }
  }

  return (
    <>
      <PageHeader
        type={"h3"}
        header={t("selectableComments.addToNotes", {
          ns: "settings",
        })}
        divider={true}
        addTitle={false}
      />
      <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => handleFormSubmit(values.selectedComments)}
        initialValues={{
          selectedComments: commentsSelected,
        }}
      >
        {({
          handleSubmit,
          errors,
          values,
          setFieldValue,
        }) => (
          <Form
            className="ui form error"
            autoComplete="on"
            onSubmit={handleSubmit}
          >
            <ErrorMessage
              name="error"
              render={() => <ValidationErrors errors={errors} />}
            />
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Table>

                    <Table.Header>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell textAlign="left">{t("selectableComments.commentName", { ns: "settings" })}</Table.HeaderCell>
                    </Table.Header>
                    <Table.Body>
                      {getSelectableComments &&
                        getSelectableComments.length > 0 ?
                        getSelectableComments.map((c: SelectableCommentsDto) => (
                          <Table.Row key={c.id}>
                            <Table.Cell>
                              <Checkbox
                                checked={values.selectedComments.some(sc => sc.id == c.id)}
                                onChange={e => {
                                  const isChecked = e.currentTarget.checked;
                                  if (isChecked)
                                    setFieldValue('selectedComments', values.selectedComments.slice().filter(sc => sc.id != c.id))
                                  else
                                    setFieldValue('selectedComments', [...values.selectedComments, c]);
                                }}
                              />
                            </Table.Cell>
                            <Table.Cell>{c.name}</Table.Cell>
                          </Table.Row>
                        ))
                        : (
                          <Table.Row key={"noitems"}>
                            <Table.Cell width="8" colSpan={8}>
                              <Message
                                color="yellow"
                                className="textAlignCenter fontStyleItalic"
                              >
                                {t("grid_noItems", { ns: "common" })}
                              </Message>
                            </Table.Cell>
                          </Table.Row>
                        )}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan='12'>
                          <MyPaging
                            itemCount={pagination ? pagination.totalItems : 10}
                            pageSize={pagingParams.pageSize}
                            currentPage={pagination ? pagination.currentPage : 1}
                            onClick={listOnClick}
                            onChange={listOnClick}
                            floated="right"
                          ></MyPaging>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column columns={2}>
                  <Button color="green" type="submit">
                    <Icon name="check" />
                    {t("add", { ns: "common" })}
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      modalStore.closeModal();
                    }}
                  >
                    <Icon name="x" />
                    {t("close", { ns: "common" })}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
});
