import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Container, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../app/layout/PageHeader";
import CustomerForm from "./CustomerForm";
import { useStore } from "../../app/stores/store";
import { useParams, useLocation } from "react-router-dom";
import { format } from "date-fns";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { CustomerFormValues } from "../../app/models/customer";

export default observer(function EditCustomerDetails() {
  const { t } = useTranslation(["common", "customer"]);
  const location = useLocation();
  const { customerStore } = useStore();
  const {
    loadCustomerChangeLogs,
    customerChangeLogs,
    loadingInitial,
    loadCustomer
  } = customerStore;

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadCustomer(id ?? "").then((result) => {
      setCustomer(new CustomerFormValues(result));
      loadCustomerChangeLogs(id ?? "");
    });
  }, [id, loadCustomer, loadCustomerChangeLogs]);

  const [customer, setCustomer] = useState<CustomerFormValues>(new CustomerFormValues());

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("customerDetails_loading", { ns: "customer" })}
      />
    );

  return (
    <Container className="page">
      <CustomerForm
        isTicket={false}
        isModal={false}
        previousLocation={location.state?.location.pathname ?? ""}
        customerFormValues={customer} />

      <PageHeader
        header={t("change_log", { ns: "customer" })}
        type={"h2"}
        divider={false}
        getAlerts={true}
        addTitle={false}
      />
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {`${t("date", { ns: "common" })}:`}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {`${t("author", { ns: "customer" })}:`}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {`${t("field", { ns: "customer" })}:`}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {`${t("old_value", { ns: "customer" })}:`}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {`${t("new_value", { ns: "customer" })}:`}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {customerChangeLogs?.length > 0 ? (
            customerChangeLogs.map((changeLog) => (
              <Table.Row key={changeLog.id}>
                <Table.Cell>
                  {changeLog.createdDate && format(changeLog.createdDate, "Pp")}
                </Table.Cell>
                <Table.Cell>{changeLog.author}</Table.Cell>
                <Table.Cell>{changeLog.changeType}</Table.Cell>
                <Table.Cell>{changeLog.oldValues}</Table.Cell>
                <Table.Cell>{changeLog.newValues}</Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row key={"noitems"}>
              <Table.Cell width="5" colSpan={5}>
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Container>
  );
});
