import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Input,
  Label,
  Message,
  Radio,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import { Link } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import PageHeader from "../../../app/layout/PageHeader";
import SettingsNavBar from "../SettingsNavBar";
import SettingsDropdownMenuMobile from "../SettingsDropdownMenuMobile";

export default observer(function InventorySettings() {
  const { t } = useTranslation(["common", "ticketSettings"]);

  const { ticketStore } = useStore();

  const {
    loadSettingsTicketFormatting,
    createSettingsTicketFormatting,
    updateSettingsTicketFormatting,
    loadingInitial,
  } = ticketStore;

  const validationSchema = Yup.object({
    showBorderOnPrint: Yup.boolean().required(),
    shadeAlternatingRows: Yup.boolean().required(),
    customerSignatureLine: Yup.number().required(),
    showNotesLabel: Yup.boolean().required(),
    showPayment: Yup.boolean().required(),
    showRemoveAndReplace: Yup.boolean().required(),
    logoPlacement: Yup.number().required(),
    showPONumber: Yup.boolean().required(),
    showEstimateNumber: Yup.boolean().required(),
  });

  const [completed, setCompleted] = useState<boolean>(false);

  <LoadingComponent
    content={t("formatting_loading", { ns: "ticketSettings" })}
  />;

  return (
    <>
      <Grid stackable>
        <Grid.Column width={3} className="mobileHeight">
          <SettingsNavBar />
          <SettingsDropdownMenuMobile />
        </Grid.Column>
        <Grid.Column width={13}>
          <PageHeader
            header={`${t("inventory.header", {
              ns: "settings",
            })}`}
            type={"h2"}
            divider={true}
            getAlerts={true}
            addTitle={true}
          />
          <>
            <Container className="page">
              {/* <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsTicketFormatting}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            values,
            resetForm,
            handleChange,
            handleBlur,
            touched,
            errors,
          }) => (
            <Form
              className="ui form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              /> */}
              <Grid stackable>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <label className="required">
                      {`${t("inventory.header", {
                        ns: "settings",
                      })}:`}
                    </label>
                    <Form.Group inline>
                      <Form.Field key={``}>
                        <Radio label="On" />
                        <Radio label="Off" />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <label className="required">
                      {`${t("Core Tracking", {
                        ns: "settings",
                      })}:`}
                    </label>
                    <Form.Group inline>
                      <Form.Field key={``}>
                        <Radio label="On" />
                        <Radio label="Off" />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <label className="required">
                      {`${t("Low Inventory Notifications", {
                        ns: "settings",
                      })}:`}
                    </label>
                    <Form.Group inline>
                      <Form.Field key={``}>
                        <Radio label="On" />
                        <Radio label="Off" />
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <Input
                      label="Add days/time for low qty reminders: "
                      placeholder="Remind Me"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color="green"
                      className="save_button"
                      icon="check"
                      content={t("save", { ns: "common" })}
                      // disabled={isSubmitting || !dirty || !isValid}
                      // loading={isSubmitting}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/settings"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              {/* </Form>
          )}
        </Formik> */}
            </Container>
          </>
        </Grid.Column>
      </Grid>
    </>
  );
});
