import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Grid, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useStore } from "../../../app/stores/store";
import {
  formatCurrency,
  getNegative,
} from "../../../app/common/util/functions";
import PageHeader from "../../../app/layout/PageHeader";

interface Props {
  startDate: Date;
  endDate: Date;
}

export default observer(function CustomerHistory({
  startDate,
  endDate,
}: Props) {
  const { t } = useTranslation(["common", "reports"]);

  const { customerHistoryReportStore } = useStore();
  const {
    groupedByVehicle,
    selectedCustomer,
    calculateTotalSales,
    getCustomerHistory,
  } = customerHistoryReportStore;

  return (
    <>
      <Container className="page">
        <Grid stackable columns={2} className="headerPrint">
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={`${t("customer", { ns: "common" })}: ${
                  selectedCustomer.isCommercial
                    ? selectedCustomer.companyName
                    : `${selectedCustomer.lastName}, ${
                        selectedCustomer.firstName
                          ? selectedCustomer.firstName
                          : "--"
                      }`
                }`}
                type={"h3"}
                divider={false}
                addTitle={false}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`${format(startDate, "PP")} - ${format(
                  endDate,
                  "PP"
                )}`}
                as="h2"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {groupedByVehicle.length > 0 && (
          <>
            <Table stackable>
              {groupedByVehicle.map(([group, x]) => (
                <>
                  <Table.Header>
                    <Table.Row key={`vehicle.${group}`}>
                      <Table.HeaderCell textAlign="left" colSpan={9}>
                        {group}
                        {/* {(x.year ?? 0) > 0 && `${x.year} `}
                        {x.make && `${x.make} `}
                        {x.model} {x.engine && `${x.engine}`} */}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Header key={`table.${group}`}>
                    <Table.Row>
                      <Table.HeaderCell textAlign="left">
                        {t("estimate.invoice", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="left">
                        {t("date", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="left">
                        {t("description", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.parts", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("misc", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.labor", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.tax", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("total", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("pay_type.header", { ns: "reports" })}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {x.map((vehicle) => (
                      <>
                        <Table.Row key={vehicle.invoiceNumber}>
                          <Table.Cell textAlign="left">
                            {vehicle.invoiceNumber}
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            {vehicle.invoiceDate &&
                              format(vehicle.invoiceDate, "P")}
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            {vehicle.description}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatCurrency(vehicle.totalParts)}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatCurrency(vehicle.totalMisc)}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatCurrency(vehicle.totalLabor)}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatCurrency(vehicle.totalTax)}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {formatCurrency(vehicle.totalAmount)}
                          </Table.Cell>
                          <Table.Cell>{vehicle.payType}</Table.Cell>
                        </Table.Row>
                      </>
                    ))}
                      <Table.Row key={"totals"} className="terms_bold">
                        <Table.Cell colSpan={7}>
                          {t("vehicleTotals", { ns: "reports" })}:
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("revenue", { ns: "reports" })}:`}
                          textAlign="right"
                          style={{
                            color: getNegative(
                              calculateTotalSales(x ?? []).totalAmount
                            ),
                          }}
                        >
                          {formatCurrency(
                            calculateTotalSales(x ?? []).totalAmount
                          )}
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                  </Table.Body>
                </>
              ))}
              <Table.Body>
                <Table.Row key={"grandTotals"} className="terms_bold greenRow">
                  <Table.Cell colSpan={7}>
                    {t("grandTotals", { ns: "reports" })}:
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("revenue", { ns: "reports" })}:`}
                    textAlign="right"
                    style={{
                      color: getNegative(
                        calculateTotalSales(getCustomerHistory).totalAmount
                      ),
                    }}
                  >
                    {formatCurrency(
                      calculateTotalSales(getCustomerHistory).totalAmount
                    )}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </>
        )}
      </Container>
    </>
  );
});
