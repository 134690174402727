import { observer } from "mobx-react-lite";
import React from "react";
import { Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function Import() {
  const { t } = useTranslation(["common", "advancedSettings"]);

  return (
    <>
      <PageHeader
        header={t("import.header", { ns: "advancedSettings" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        showHelp
        helpName="settings.advanceSettings.dataImport"
        addTitle={true}
      />

      <Container className="page">
        <p>{t("import.p1", { ns: "advancedSettings" })}</p>
        <p>{t("import.p2", { ns: "advancedSettings" })}</p>
        <p>{t("import.p3", { ns: "advancedSettings" })}</p>
      </Container>
    </>
  );
});
