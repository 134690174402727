import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../../api/agent";
import { CancellationReport } from "../../../models/systemAdmin";
import { Pagination, PagingParams } from "../../../models/pagination";

export default class CancellationReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.cancellationReportRegistry.clear();
        this.loadCancellationReport();
      }
    );
  }

  loading = false;
  setLoading = (state: boolean) => {
    this.loading = state;
  };
  cancellationReportRegistry = new Map<Date, CancellationReport>();
  predicate = new Map();
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 10);

  loadCancellationReport = async () => {
    this.setLoading(true);
    try {
      this.cancellationReportRegistry.clear();
      
      const result = await agent.SystemAdminReports.getCancellationReport(
        this.axiosParams
      );
      runInAction(() => {
        result.forEach((cancellationReport) => {
          this.setCancellationReport(cancellationReport);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  private setCancellationReport = (cancellationReport: CancellationReport) => {
    cancellationReport.date = new Date(cancellationReport.date);
    this.cancellationReportRegistry.set(
      cancellationReport.date,
      cancellationReport
    );
  };

  get getCancellationReport() {
    return Array.from(this.cancellationReportRegistry.values()).sort(
      (a, b) => b.date!.getTime() - a.date!.getTime()
    );
  }
  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString()); 
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (key: string, value: any) => {
    if (value) {
      this.predicate.set(key, value);
    } else {
      this.predicate.delete(key);
    }
  };
  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };
}
