import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { TicketCardTransaction, TicketPaymentItem } from "../models/ticket";

export default class TicketPaymentProcessingStore {
  loadingInitial = false;
  activeTab: number = 0;


  selectedTicketCardTransaction: TicketCardTransaction = {
    ticketId: undefined,
    ticketPaymentId: undefined,
    ticketCardTransactionId: undefined,
    transactionType: undefined,
    cardNumber: undefined,
    cardType: undefined,
    cardEntryMethod: undefined,
    transactionDate: undefined,
    cardHolderName: undefined,
    bin: undefined,
    batchNumber: undefined,
    terminalId: undefined,
    authorizationCode: undefined,
    signature: undefined,
    amount: undefined,
  };

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  loadTicketCardTransaction = async (ticketPaymentId: string) => {
    this.loadingInitial = true;
    try {
      const result =
        await agent.PaymentProcessing.getTicketTransaction(ticketPaymentId);
        runInAction(() => {
          this.selectedTicketCardTransaction = result;
          this.selectedTicketCardTransaction.transactionDate = result.transactionDate ? new Date(result.transactionDate) : undefined;
        });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  setSelectedTicketCardTransaction = (values: TicketCardTransaction) => {
    this.selectedTicketCardTransaction = values;
  };


  // updateTicketCardTransaction = async (values: TicketCardTransaction) => {
  //   try {
  //     //   let mynew: SettingsPaymentProcessor = new SettingsPaymentProcessor(
  //     //     values
  //     //   );
  //     //   await agent.PaymentProcessing.updateSettingsPaymentProcessor(mynew);

  //     runInAction(() => {
  //       //this.setSelectedSettingsPaymentProcessor(values);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  processTicketPayment = async (
    values: TicketPaymentItem,
    byPassCheckStatus: boolean
  ) => {
    try {
      this.loadingInitial = true;
      let myNew: TicketPaymentItem = new TicketPaymentItem(values);
      await agent.PaymentProcessing.processPayment(
        myNew.id ?? "",
        byPassCheckStatus
      );

      runInAction(() => {
        //this.setSelectedSettingsPaymentProcessor(values);
        //not sure what could be done here other than update the values
        //send the cardTransaction?
        this.loadingInitial = false;
      });
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  checkStatus = async (values: TicketPaymentItem) => {
    try {
      this.loadingInitial = true;
      await agent.PaymentProcessing.checkStatus(values.id ?? "");

      runInAction(() => {
        this.loadingInitial = false;
      });
      return true;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };
}
