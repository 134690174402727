import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Divider, Grid, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import {
  ReportType,
  SearchType,
  TicketItemType,
} from "../../../app/models/enums";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import {
  convertTicketItemTypeToString,
  formatCurrency,
  formatDecimal,
  getNegative,
} from "../../../app/common/util/functions";
import PageHeader from "../../../app/layout/PageHeader";

interface Props {
  startDate: Date;
  endDate: Date;
  reportType: ReportType;
}

export default observer(function Technicians({
  startDate,
  endDate,
  reportType,
}: Props) {
  const { t } = useTranslation(["common", "reports"]);
  const navigate = useNavigate();

  const { technicianReportStore, searchStore } = useStore();
  const {
    groupedTechnicianReport,
    calculateTotals,
    groupTechniciansByDate,
  } = technicianReportStore;
  const { setPredicate: searchPredicate } = searchStore;

  return (
    <>
      <Container className="page">
        <Grid stackable columns={2} className="headerPrint">
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={`${t("report", { ns: "reports" })}: ${t("technicians", {
                  ns: "common",
                })}`}
                type="h2"
                divider={false}
                getAlerts={true}
                addTitle={true}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`${format(startDate, "PP")} - ${format(
                  endDate,
                  "PP"
                )}`}
                as="h2"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {groupedTechnicianReport &&
          groupedTechnicianReport.length > 0 &&
          (reportType === ReportType.Detailed
            ? groupedTechnicianReport.map(([group, technicianData]) => (
                <Table
                  striped
                  unstackable
                  className="dnxTable"
                  key={`table.${group}`}
                >
                  <Table.Body className="terms_bold">
                    <Table.Row>
                      <Table.Cell colSpan={8} className="textAlignLeft">
                        {group}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        {t("date", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("inv", { ns: "search" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("description", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("type", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("pops.qty", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("hours", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("pops.rate", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("total", { ns: "common" })}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {technicianData.map((item) => (
                      <Table.Row key={item.invoiceNumber}>
                        <Table.Cell
                          data-label={`${t("date", { ns: "common" })}:`}
                        >
                          {item.date && format(item.date, "PP")}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("inv", { ns: "search" })}:`}
                        >
                          <Link
                            onClick={() => {
                              searchPredicate(
                                SearchType.InvoiceNumber,
                                item.invoiceNumber
                              );
                              navigate(
                                `/search/${SearchType.InvoiceNumber}/${item.invoiceNumber}`
                              );
                            }}
                            to={""}
                          >
                            {item.invoiceNumber}
                          </Link>
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("description", { ns: "common" })}:`}
                        >
                          {item.description}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("type", { ns: "common" })}:`}
                        >
                          {convertTicketItemTypeToString(item.type)}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("pops.qty", { ns: "tickets" })}:`}
                          textAlign="right"
                        >
                          {item.type === TicketItemType.Parts &&
                            formatDecimal(item.quantity, 2)}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("hours", { ns: "tickets" })}:`}
                          textAlign="right"
                        >
                          {item.type === TicketItemType.Labor &&
                            formatDecimal(item.quantity, 2)}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("pops.rate", { ns: "tickets" })}:`}
                          textAlign="right"
                          style={{
                            color: getNegative(item.rate),
                          }}
                        >
                          {formatCurrency(item.rate)}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("total", { ns: "common" })}:`}
                          textAlign="right"
                          style={{
                            color: getNegative(item.subTotal),
                          }}
                        >
                          {formatCurrency(item.subTotal)}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                  <Table.Header>
                    <Table.Row className="greenRow" key={"techTotalsHeader"}>
                      <Table.HeaderCell colSpan={3}>
                        {t("technicianTotals", { ns: "reports" })}:
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {convertTicketItemTypeToString(TicketItemType.Parts)}:
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("hours", { ns: "tickets" })}:
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {convertTicketItemTypeToString(TicketItemType.Labor)}:
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right"></Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("total", { ns: "common" })}:
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row
                      key={`groupFooter.${group}`}
                      className="terms_bold greenRow"
                    >
                      <Table.Cell
                        colSpan={3}
                        data-label={`${t("technicianTotals", {
                          ns: "reports",
                        })}:`}
                      ></Table.Cell>
                      <Table.Cell
                        data-label={`${convertTicketItemTypeToString(
                          TicketItemType.Parts
                        )}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(
                            calculateTotals(
                              technicianData.filter((x) => {
                                return x.type === TicketItemType.Parts;
                              })
                            ).subTotal
                          ),
                        }}
                      >
                        {formatCurrency(
                          calculateTotals(
                            technicianData.filter((x) => {
                              return x.type === TicketItemType.Parts;
                            })
                          ).subTotal
                        )}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("hours", { ns: "tickets" })}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(
                            calculateTotals(
                              technicianData.filter((x) => {
                                return x.type === TicketItemType.Labor;
                              })
                            ).quantity
                          ),
                        }}
                      >
                        {formatDecimal(
                          calculateTotals(
                            technicianData.filter((x) => {
                              return x.type === TicketItemType.Labor;
                            })
                          ).quantity,
                          2
                        )}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${convertTicketItemTypeToString(
                          TicketItemType.Labor
                        )}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(
                            calculateTotals(
                              technicianData.filter((x) => {
                                return x.type === TicketItemType.Labor;
                              })
                            ).subTotal
                          ),
                        }}
                      >
                        {formatCurrency(
                          calculateTotals(
                            technicianData.filter((x) => {
                              return x.type === TicketItemType.Labor;
                            })
                          ).subTotal
                        )}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell
                        data-label={`${t("total", { ns: "common" })}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(
                            calculateTotals(technicianData).subTotal
                          ),
                        }}
                      >
                        {formatCurrency(
                          calculateTotals(technicianData).subTotal
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              ))
            : groupedTechnicianReport.map(([group, technicianData]) => (
                <Table
                  striped
                  unstackable
                  className="dnxTable"
                  key={`table.${group}`}
                >
                  <Table.Body className="terms_bold">
                    <Table.Row>
                      <Table.Cell colSpan={8} className="textAlignLeft">
                        {group}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        {t("date", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.labor", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.parts", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("total", { ns: "common" })}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {groupTechniciansByDate(technicianData).map(
                      ([group, item]) => (
                        <Table.Row
                          key={`technician.${group}`}
                          className=" terms_bold"
                        >
                          <Table.Cell>{group}</Table.Cell>
                          <Table.Cell
                            data-label={`${t("estimate.labor", {
                              ns: "tickets",
                            })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(
                                calculateTotals(
                                  item.filter((x) => {
                                    return x.type === TicketItemType.Labor;
                                  })
                                ).subTotal
                              ),
                            }}
                          >
                            {formatCurrency(
                              calculateTotals(
                                item.filter((x) => {
                                  return x.type === TicketItemType.Labor;
                                })
                              ).subTotal
                            )}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("estimate.parts", {
                              ns: "tickets",
                            })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(
                                calculateTotals(
                                  item.filter((x) => {
                                    return x.type === TicketItemType.Parts;
                                  })
                                ).subTotal
                              ),
                            }}
                          >
                            {formatCurrency(
                              calculateTotals(
                                item.filter((x) => {
                                  return x.type === TicketItemType.Parts;
                                })
                              ).subTotal
                            )}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("total", { ns: "common" })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(
                                calculateTotals(item).subTotal
                              ),
                            }}
                          >
                            {formatCurrency(calculateTotals(item).subTotal)}
                          </Table.Cell>
                        </Table.Row>
                      )
                    )}
                  </Table.Body>
                </Table>
              )))}
      </Container>
    </>
  );
});
