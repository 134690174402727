export const monthsOptions = [
  { text: "01 - Jan", value: "01" },
  { text: "02 - Feb", value: "02" },
  { text: "03 - Mar", value: "03" },
  { text: "04 - Apr", value: "04" },
  { text: "05 - May", value: "05" },
  { text: "06 - Jun", value: "06" },
  { text: "07 - Jul", value: "07" },
  { text: "08 - Aug", value: "08" },
  { text: "09 - Sep", value: "09" },
  { text: "10 - Oct", value: "10" },
  { text: "11 - Nov", value: "11" },
  { text: "12 - Dec", value: "12" },
];
