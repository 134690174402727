import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, List } from "semantic-ui-react";
import PageHeader from "../../app/layout/PageHeader";

export default observer(function Terms() {
  const { t } = useTranslation(["common", "terms"]);

  return (
    <Container fluid className="page">
      <PageHeader
        header={t("terms_header", { ns: "terms" })}
        type={"h1"}
        divider={true}
        addTitle={true}
      />
      <List ordered>
        <List.Item>
          {t("li_1.not_bold", { ns: "terms" })}
          <span className="terms_bold">{t("li_1.bold", { ns: "terms" })}</span>
        </List.Item>
        <List.Item>{t("li_2", { ns: "terms" })}</List.Item>
        <List.Item>
          {t("li_3.not_bold1", { ns: "terms" })}
          <span className="terms_bold">{t("li_3.bold1", { ns: "terms" })}</span>
          {t("li_3.not_bold2", { ns: "terms" })}
          <span className="terms_bold">{t("li_3.bold2", { ns: "terms" })}</span>
        </List.Item>
        <List.Item>{t("li_4", { ns: "terms" })}</List.Item>
        <List.Item>
          {t("li_5.not_bold", { ns: "terms" })}
          <span className="terms_underlined">
            {t("li_5.underlined", { ns: "terms" })}
          </span>
          <span className="terms_bold">{t("li_5.bold", { ns: "terms" })}</span>
        </List.Item>
        <List.Item>{t("li_6", { ns: "terms" })}</List.Item>
        <List.Item>{t("li_7", { ns: "terms" })}</List.Item>
      </List>
    </Container>
  );
});
