import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { DropdownItemProps } from "semantic-ui-react";

export default class DataSourceStore {
  dataSourceList: DropdownItemProps[] = [];
  selectedDataSource: DropdownItemProps | undefined;

  customerList: DropdownItemProps[] = [];
  selectedCustomer: DropdownItemProps | undefined;

  loadingInitial = false;
  loading = false;
  predicate = new Map().set("all", true);

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.dataSourceList = [];
        this.loadDataSourceList();
      }
    );
  }

  loadDataSourceList = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.DataSource.DataSourceList();
      runInAction(() => {
        this.dataSourceList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  loadCustomerList = async () => {
    this.loadingInitial = true;
    try {
      const result = await agent.DataSource.CustomerList();
      runInAction(() => {
        this.customerList = result;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
}
