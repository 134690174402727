import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { PagingParams } from "../../../app/models/pagination";
import {
  Grid,
  Divider,
  Button,
  Icon,
  Table,
  Input,
  Dropdown,
  DropdownItemProps,
} from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import MyPaging from "../../../app/common/form/MyPaging";
import { Link } from "react-router-dom";
import { formatPhoneNumber } from "../../../app/common/util/functions";
import NumberFormat from "react-number-format";
import { ClientStatus } from "../../../app/models/enums";
import { clientStatusOptions } from "../../../app/common/options/option";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function ManageClients() {
  const { t } = useTranslation(["common", "systemAdmin"]);

  const { clientSystemAdminStore } = useStore();

  const {
    loadClientList,
    getClientList,
    pagination,
    pagingParams,
    setPagingParams,
    resetAllPredicates,
    setPredicate,
  } = clientSystemAdminStore;

  useEffect(() => {
    resetAllPredicates();
    loadClientList();
  }, []);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadClientList();
    }
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={t("companies.intro", { ns: "systemAdmin" }).toString()}
              className="modal-text-color"
              textAlign="left"
              divider={false}
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
          <>
            <Grid.Row columns={4} className="lessPaddingSysAdminTables">
            <Grid.Column>
                <label className="myLabel">Search All</label>
                <br />
                <Input
                  onChange={(e, d) => {
                    setPagingParams(new PagingParams());
                    setPredicate("SearchAll", d.value);
                  }}
                  placeholder="Search All"
                />
              </Grid.Column>
              <Grid.Column>
                <label className="myLabel" htmlFor="status">
                  Status
                </label>
                <br />
                <Dropdown
                  placeholder="Status"
                  fluid
                  selection
                  clearable
                  options={clientStatusOptions}
                  onChange={(e, d) => {
                    setPredicate("Status", d.value?.toString());
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <label className="myLabel">Id</label>
                <br />
                <Input
                  onChange={(e, d) => {
                    setPagingParams(new PagingParams());
                    setPredicate("Id", d.value);
                  }}
                  placeholder="Id"
                />
              </Grid.Column>
              <Grid.Column>
                <label className="myLabel">Company Name</label>
                <br />
                <Input
                  onChange={(e, d) => {
                    setPagingParams(new PagingParams());
                    setPredicate("CompanyName", d.value);
                  }}
                  placeholder="Company Name"
                />
              </Grid.Column>
              
            </Grid.Row>
            <Grid.Row columns={5} className="lessPaddingSysAdminTables">
            <Grid.Column>
                <label className="myLabel">City</label>
                <br />
                <Input
                  onChange={(e, d) => {
                    setPagingParams(new PagingParams());
                    setPredicate("City", d.value);
                  }}
                  placeholder="City"
                />
              </Grid.Column>
              <Grid.Column>
                <label className="myLabel">Zip</label>
                <br />
                <Input
                  onChange={(e, d) => {
                    setPagingParams(new PagingParams());
                    setPredicate("Zip", d.value);
                  }}
                  placeholder="Zip"
                />
              </Grid.Column>
              <Grid.Column>
                <label className="myLabel">Phone</label>
                <br />
                <Input>
                  <NumberFormat
                    type="tel"
                    format="(###) ###-####"
                    mask="_"
                    placeholder="Phone"
                    onValueChange={(value) => {
                      setPagingParams(new PagingParams());
                      setPredicate("Phone", value.floatValue?.toString());
                    }}
                  ></NumberFormat>
                </Input>
              </Grid.Column>
              <Grid.Column>
                <label className="myLabel">Payment Gateway Id</label>
                <br />
                <Input
                  onChange={(e, d) => {
                    setPagingParams(new PagingParams());
                    setPredicate("PaymentGatewayId", d.value);
                  }}
                  placeholder="Payment Gateway Id"
                />
              </Grid.Column>
              <Grid.Column>
                <label className="myLabel">Active Plan Users</label>
                <br />
                <Input
                  onChange={(e, d) => {
                    setPagingParams(new PagingParams());
                    setPredicate("ActivePlanUsers", d.value);
                  }}
                  placeholder="Active Plan Users"
                />
              </Grid.Column>
            </Grid.Row>
          </>
      </Grid>
      <Grid stackable>
        <Grid.Column stackable>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("companyname", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("company_address", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("address.zip", { ns: "common" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("phone", { ns: "common" })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {getClientList.map((x) => (
                <Table.Row key={`client.${x.id}`}>
                  <Table.Cell>
                    <Link
                      to={`/admin/company/${x.id}`}
                    >
                      {x.companyName}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {x.addressLine1 && (
                      <>
                        {x.addressLine1}
                        {x.addressLine2 && `, ${x.addressLine2}`}
                        {x.city && (
                          <>
                            {`, ${x.city}`}
                            {x.state && `, ${x.state}`}
                          </>
                        )}
                      </>
                    )}
                  </Table.Cell>
                  <Table.Cell>{x.postalCode}</Table.Cell>
                  <Table.Cell>
                    {formatPhoneNumber(x.phone.toString())}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <MyPaging
                    itemCount={pagination ? pagination.totalItems : 25}
                    pageSize={pagingParams.pageSize}
                    currentPage={pagination ? pagination.currentPage : 1}
                    onClick={listOnClick}
                    onChange={listOnClick}
                    floated="right"
                  ></MyPaging>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
});
