import { TimeType } from "./enums";
import { Job } from "./job";

export interface JobLabor {
  id?: string;
  jobLaborId?: number;
  job?: Job;
  highTime?: number;
  averageTime?: number;
  lowTime?: number;
  hasJobAddon: boolean;
  hasJobComment: boolean;
  laborHash?: string;

  availableTimes?: TimeType;
  selectedTimeType?: TimeType;
  selectedRate?: string;
  selectedRateValue?: number;
  selectedTime?: number;
  selectedTimeTotal?: number;
  isCustom: boolean;

  selectedJobAddons : JobAddOn[];

  isComplete: boolean;

}

export class JobLabor implements JobLabor {
  constructor(init?: JobLabor) {
    Object.assign(this, init);
  }
}


export interface JobAddOn {
    jobLaborId?: number;
    jobAddonId?: number;
    description?: string;
    time?: number;
    isRequired: boolean;
}

