import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Button, Grid, Header, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { Link } from "react-router-dom";
import { format } from "date-fns";

export default observer(function EarlyChargeReport() {
  const { earlyChargeStore } = useStore();
  const { loadEarlyChargeReport, getEarlyChargeReport, fixEarlyChargeReport } = earlyChargeStore;

  useEffect(() => {
    loadEarlyChargeReport();
  }, [loadEarlyChargeReport]);

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={"Early Charge Report"}
              className="modal-text-color"
              textAlign="left"
              divider={false}
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              as="h3"
              content={"Total Early Charges: " + getEarlyChargeReport.length}
              className="modal-text-color"
              textAlign="left"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Table basic="very" striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">Company ID</Table.HeaderCell>
                <Table.HeaderCell>Charge 1</Table.HeaderCell>
                <Table.HeaderCell>Charge 2</Table.HeaderCell>
                <Table.HeaderCell>Plan Interval</Table.HeaderCell>
                <Table.HeaderCell>Day Difference</Table.HeaderCell>
                <Table.HeaderCell>Next Charge</Table.HeaderCell>
                <Table.HeaderCell>New Plan Interval</Table.HeaderCell>
                <Table.HeaderCell>Early By</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {getEarlyChargeReport.map((x) => (
              <Table.Body>
                <Table.Row>
                  <Table.Cell textAlign="left">
                    <Link 
                      to={`/admin/company/${x.clientId}`}
                      target="_blank"
                    >
                      {x.clientId}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{format(x.charge1, 'P')}</Table.Cell>
                  <Table.Cell>{format(x.charge2, 'P')}</Table.Cell>
                  <Table.Cell>{x.term}</Table.Cell>
                  <Table.Cell>{x.days}</Table.Cell>
                  <Table.Cell>{format(x.nextChargeDate, 'P')} (+{x.nextChargeDays} days)</Table.Cell>
                  <Table.Cell>{x.newPlanTerm}</Table.Cell>
                  <Table.Cell>{x.term - x.days} {x.term - x.days > 1 ? 'days' : 'day'}</Table.Cell>
                  <Table.Cell>
                    <Button primary size="small" content={"Fix"} onClick={() => fixEarlyChargeReport([x.id])}/>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ))}
          </Table>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Button primary size="small" content={"Fix All"} onClick={() => fixEarlyChargeReport([])} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <span>
              **Skips companies that were charged more than 3 days early.
            </span>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
});
