import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  formatCurrency,
  formatCurrencyDecimal,
  formatDecimal,
} from "../../../../app/common/util/functions";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";
import {
  SettingsTicketFormatting,
  TicketItem,
  TicketSession,
} from "../../../../app/models/ticket";
import { TicketItemType } from "../../../../app/models/enums";

interface Props {
  ticketItems: TicketItem[];
  ticketItemType: TicketItemType;
  selectedTicketSession: TicketSession;
  selectedSettingsTicketFormatting: SettingsTicketFormatting;
}

export default observer(function TicketItemPdfView({
  ticketItems,
  ticketItemType,
  selectedTicketSession,
  selectedSettingsTicketFormatting
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  const [showLaborHours, setShowLaborHours] = useState<boolean>((selectedTicketSession.showLaborHours &&
    ticketItemType === TicketItemType.Labor) ||
    ticketItemType !== TicketItemType.Labor)

  return (
    <View
      style={{ ...compose("view pageWidth") }}
    >
      <View
        style={{
          ...compose(
            " view flex pageWidth borderSolidTop borderSolidRight borderSolidLeft noMargin noPadding lineHeight darkHeader textAlignCenter borderSolidBottom"
          ),
        }}
      >
        <View
          style={{ ...compose(`view ${showLaborHours ? "width130": "width174"}  borderSolidRight padding1mmTop`) }}
        >
          <Text>{t("description", { ns: "common" })}</Text>
        </View>
        {showLaborHours && (
          <>
            <View
              style={{
                ...compose("view width20 borderSolidRight padding1mmTop"),
              }}
            >
              <Text>
                {ticketItemType === TicketItemType.Labor
                  ? t("hours", { ns: "tickets" })
                  : t("pops.qty", { ns: "tickets" })}
              </Text>
            </View>
            <View
              style={{
                ...compose("view width24 borderSolidRight padding1mmTop"),
              }}
            >
              <Text>{t("estimate.unit_cost", { ns: "tickets" })}</Text>
            </View>
          </>
        )}
        <View style={{ ...compose("view width26 padding1mmTop padding5pxRight") }}>
          <Text>{t("estimate.price", { ns: "tickets" })}</Text>
        </View>
      </View>
       { ticketItems && ticketItems.length > 0 ? (
        ticketItems.map((x, index) => (
          <View
            key={`ticketItem.${x.id}`}
            style={{
              ...compose(
                `view flex pageWidth borderSolidRight borderSolidLeft noMargin noPadding ${
                  ticketItems.length - 1 === index && "borderSolidBottom"
                }  ${
                  selectedSettingsTicketFormatting.shadeAlternatingRows &&
                  index % 2 === 1 &&
                  "backgroundColorEEE"
                }`
              ),
              flexGrow:1,
              flexShrink:1
            }}
            wrap={false}
          >
            <View
              style={{
                ...compose(
                  `view ${showLaborHours ? "width130": "width174"} padding1mmTop padding5pxLeft ${
                    selectedSettingsTicketFormatting.showBorderOnPrint &&
                    "borderSolidRight"
                  }`
                ),
              }}
            >
              <Text>{x.description}</Text>
            </View>
            {showLaborHours && (
              <>
                <View
                  style={{
                    ...compose(
                      `view width20 padding1mmTop  textAlignRight padding5pxRight ${
                        selectedSettingsTicketFormatting.showBorderOnPrint &&
                        "borderSolidRight"
                      }`
                    ),
                  }}
                >
                  <Text>{formatDecimal(x.quantity, 2)}</Text>
                </View>
                <View
                  style={{
                    ...compose(
                      `view width24 padding1mmTop textAlignRight padding5pxRight ${
                        selectedSettingsTicketFormatting.showBorderOnPrint &&
                        "borderSolidRight"
                      }`
                    ),
                  }}
                >
                  <Text>
                    {formatCurrencyDecimal(
                      Number(x.rate ?? 0),
                      x.type === TicketItemType.Parts ? 3 : 2
                    )}
                  </Text>
                </View>
              </>
            )}
            <View
              style={{
                ...compose(
                  "view width26 textAlignRight padding1mmTop padding5pxRight"
                ),
              }}
            >
              <Text style={{
                ...compose(
                  "width26 padding5pxRight"
                ),
              }}>{formatCurrency(x.subTotal ?? 0.0)}</Text>
            </View>
          </View>
        ))
      ) : (
        <View
          style={{
            ...compose(
              `view flex pageWidth borderSolidRight borderSolidLeft noMargin noPadding lineHeight borderSolidBottom`
            ),
          }}
        >
          <View
            style={{
              ...compose("view pageWidth padding1mmTop padding5pxLeft"),
            }}
          >
            <Text>{t("pdf_noItems", { ns: "common" })}</Text>
          </View>
        </View>
      )}
    </View>
  );
});
