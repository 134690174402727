import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, List } from "semantic-ui-react";
import PageHeader from "../../app/layout/PageHeader";

export default observer(function Privacy() {
  const { t } = useTranslation(["common", "users", "errors"]);

  return (
    <Container fluid className="page">
      <PageHeader
        header={t("privacy_header", { ns: "privacy" })}
        type={"h1"}
        divider={true}
        addTitle={true}
      />
      <p> {t("intro", { ns: "privacy" })} </p>

      <PageHeader
        header={t("scope.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("scope.p1", { ns: "privacy" })} </p>
      <p> {t("scope.p2", { ns: "privacy" })} </p>

      <PageHeader
        header={t("collected_info.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("collected_info.p1", { ns: "privacy" })} </p>

      <PageHeader
        header={t("use_personal_info.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("use_personal_info.p1", { ns: "privacy" })} </p>
      <List bulleted>
        <List.Item>{t("use_personal_info.li_1", { ns: "privacy" })}</List.Item>
        <List.Item>{t("use_personal_info.li_2", { ns: "privacy" })}</List.Item>
        <List.Item>{t("use_personal_info.li_3", { ns: "privacy" })}</List.Item>
        <List.Item>{t("use_personal_info.li_4", { ns: "privacy" })}</List.Item>
        <List.Item>{t("use_personal_info.li_5", { ns: "privacy" })}</List.Item>
        <List.Item>{t("use_personal_info.li_6", { ns: "privacy" })}</List.Item>
      </List>
      <p> {t("use_personal_info.p2", { ns: "privacy" })} </p>
      <p> {t("use_personal_info.p3", { ns: "privacy" })} </p>

      <PageHeader
        header={t("disclose_info.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("disclose_info.p1", { ns: "privacy" })} </p>
      <p>
        <em>{t("disclose_info.p2", { ns: "privacy" })}</em>
      </p>
      <p>
        <em>{t("disclose_info.p3", { ns: "privacy" })}</em>
      </p>
      <p> {t("disclose_info.p4", { ns: "privacy" })} </p>

      <PageHeader
        header={t("children.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("children.p1", { ns: "privacy" })} </p>

      <PageHeader
        header={t("cookies.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("cookies.p1", { ns: "privacy" })} </p>
      <p> {t("cookies.p2", { ns: "privacy" })} </p>

      <PageHeader
        header={t("modify_choices.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("modify_choices.p1", { ns: "privacy" })} </p>

      <PageHeader
        header={t("security.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("security.p1", { ns: "privacy" })} </p>

      <PageHeader
        header={t("info_process.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("info_process.p1", { ns: "privacy" })} </p>

      <PageHeader
        header={t("policies.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("policies.p1", { ns: "privacy" })} </p>

      <PageHeader
        header={t("contact_us.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("contact_us.p1", { ns: "privacy" })} </p>
      <p>
        Dowell Systems, Inc. <br></br>
        PO Box 21728<br></br>
        Tampa, FL 33622
      </p>
      <p> {t("contact_us.phone", { ns: "privacy" })} </p>

      <PageHeader
        header={t("sensitive_security.intro", { ns: "privacy" })}
        type={"h3"}
        divider={false}
        addTitle={false}
      />
      <p> {t("sensitive_security.p1", { ns: "privacy" })} </p>
      <List bulleted>
        <List.Item>{t("sensitive_security.li1", { ns: "privacy" })}</List.Item>
        <List.Item>{t("sensitive_security.li2", { ns: "privacy" })}</List.Item>
      </List>
      <p> {t("sensitive_security.phone", { ns: "privacy" })} </p>
    </Container>
  );
});
