import { useField } from "formik";
import React from "react";
import {
  Button,
  DropdownItemProps,
  Form,
  Icon,
  Label,
  Select,
} from "semantic-ui-react";
import MyHelpButton from "./MyHelpButton";

interface Props {
  placeholder: string;
  name: string;
  options: DropdownItemProps[];
  label?: string;
  className?: string;
  showHelp?: boolean;
  helpName?: string;
  onChange?: (value: any) => void;
  showDefaults?: boolean;
  onSetDefaults?: (value: string) => void;
  clearable: boolean;
}
export default function MySelectInput({
  placeholder,
  name,
  options,
  label,
  className,
  showHelp,
  helpName,
  onChange,
  showDefaults,
  onSetDefaults,
  clearable,
}: Readonly<Props>) {
  const [field, meta, helpers] = useField(name);
  return (
    <Form.Field error={meta.touched && !!meta.error}>
      {label && (
        <label className={`${className ?? ""} myLabel`}>
          {`${label} `}
          {showHelp && (
            <MyHelpButton
              name={`${name}.help`}
              help={helpName ?? ""}
              color={"blue"}
            />
          )}
          {showDefaults && (
            <Button
              size={"mini"}
              basic
              floated="right"
              compact
              type="button"
              color="green"
              onClick={() => {
                if (onSetDefaults) onSetDefaults("test");
              }}
            >
              <Icon floated="right" color="green" name="copy outline" />
            </Button>
          )}
        </label>
      )}
      <Select
        className="selection dropdown"
        clearable = {clearable}
        options={options}
        value={field.value ?? undefined}
        onChange={(e, d) => {
          helpers.setValue(d.value);
          if (onChange) onChange(d.value);
        }}
        onBlur={() => helpers.setTouched(true)}
        placeholder={placeholder}
        id={name}
      />
      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
