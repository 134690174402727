import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Page, Document, Font } from "@react-pdf/renderer";
import TicketHeaderPdf from "./TicketHeaderPdf";
import { TicketType } from "../../../app/models/enums";
import {
  SettingsTicketFormatting,
  SettingsTicketInputDefaults,
  TicketSession,
  TicketItem,
} from "../../../app/models/ticket";
import compose from "../../../app/layout/compose";
import TicketBodyPdfView from "./TicketBodyPdfView";
import TicketFooterPdfView from "./TicketFooterPdfView";
import { SettingsDisclaimer } from "../../../app/models/disclaimers";

interface Props {
  selectedTicketSession: TicketSession;
  paymentMethod: string;
  selectedSettingsTicketFormatting: SettingsTicketFormatting;
  selectedSettingsTicketInputDefaults: SettingsTicketInputDefaults;
  selectedSettingsDisclaimer: SettingsDisclaimer;
  totalPayments: number;
  ticketItems: TicketItem[];
}

export default observer(function TicketPdfDocument({
  selectedTicketSession,
  paymentMethod,
  selectedSettingsTicketFormatting,
  selectedSettingsTicketInputDefaults,
  selectedSettingsDisclaimer,
  totalPayments,
  ticketItems,
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  let leftColumnWidth: number = 98;
  let rightColumnWidth: number = 98;
  let left: number = 0;
  let right: number = 0;

  if (selectedTicketSession?.ticketType == TicketType.Estimate) {
    left =
      selectedSettingsDisclaimer.estimateLeft != null
        ? selectedSettingsDisclaimer.estimateLeft.length
        : 0;
    right =
      selectedSettingsDisclaimer.estimateRight != null
        ? selectedSettingsDisclaimer.estimateRight.length
        : 0;
  } else {
    left =
      selectedSettingsDisclaimer.invoiceLeft != null
        ? selectedSettingsDisclaimer.invoiceLeft.length
        : 0;
    right =
      selectedSettingsDisclaimer.invoiceRight != null
        ? selectedSettingsDisclaimer.invoiceRight.length
        : 0;
  }

  if (left + right > 0) {
    leftColumnWidth = 200 * (left / (left + right));
  }

  let rows: number = 0;
  let max: number = left >= right ? left : right;
  let width = left >= right ? leftColumnWidth : 200 - leftColumnWidth;
  let height: number = 0;

  if (width > 0) {
    rows = (max * 2.112) / width;
    rightColumnWidth = 200-leftColumnWidth;
  }

  //using pts to mm :D
  height = Math.ceil(rows * 2.112) ;

  Font.registerHyphenationCallback(word => {
    // Return entire word as unique part
    return [word];
  });

  return (
    <Document
      pageMode={"useThumbs"}
      title={`${t(
        `estimate.${
          selectedTicketSession?.ticketType === TicketType.Estimate
            ? "est"
            : "invoice"
        }`,
        { ns: "tickets" }
      )}`}
    >
      <Page
        size={"LETTER"}
        style={{
          ...compose("page invoice-wrapper body"),
          paddingBottom: `${
            selectedTicketSession?.ticketType == TicketType.Estimate
              ? `${height + 7}mm`
              : `${height + 14}mm`
          }`,
          marginBottom: '7mm'
        }}
        wrap
      >
        <TicketHeaderPdf
          paymentMethod={paymentMethod ?? ""}
          selectedTicketSession={selectedTicketSession ?? new TicketSession()}
          selectedSettingsTicketFormatting={selectedSettingsTicketFormatting}
          selectedSettingsTicketInputDefaults={
            selectedSettingsTicketInputDefaults
          }
        />
        <TicketBodyPdfView
          selectedTicketSession={selectedTicketSession ?? new TicketSession()}
          selectedSettingsTicketFormatting={selectedSettingsTicketFormatting}
          selectedSettingsDisclaimers={selectedSettingsDisclaimer}
          ticketItems={ticketItems}
        />

        <TicketFooterPdfView
          paymentMethod={paymentMethod ?? ""}
          customerSignatureLine={
            selectedSettingsTicketFormatting.customerSignatureLine
          }
          showPaymentMethod={selectedSettingsTicketFormatting.showPayment}
          totalPaymentAmount={totalPayments}
          height={height}
          leftColumnWidth={leftColumnWidth}
          rightColumnWidth={rightColumnWidth}
        />
      </Page>
    </Document>
  );
});
