export enum RateTypes {
  Flat = 1,
  Hourly = 2,
}

export enum PromisedDateOptions {
  Blank = 0,
  Today = 1,
  Tomorrow = 2,
}

export enum AddressType {
  Commercial = 0,
  Residential = 1,
}

export enum AnnouncementType {
  Login = 0,
}

export enum Color {
  red = 0,
  green = 1,
  blue = 2,
  yellow = 3,
}

export enum ContactType {
  None,
  Primary,
  Secondary,
}

export enum CreditCardType {
  Visa,
  MasterCard,
  AmericanExpress,
  Discover,
  JBL,
  DinersClub,
}

export enum PaymentStatus {
  Created,
  Pending,
  Succeeded,
  Declined,
  Failed
}


export enum TransactionType
{
  None,
  Sale,
  Void,
  Refund
}

export enum PhoneNumberType {
  Commercial = 1,
  Residential = 2,
  Landline = 4,
  Mobile = 8,
  Fax = 16,
}

export enum PayType {
  None,
  Cash,
  Check,
  Charge,
}

export enum SalesTaxOptionType {
  Labor = 1,
  Parts = 2,
  OnTicket = 4,
}

export enum CustomerSignatureLine {
  Underline,
  Box,
}

export enum LogoPlacement {
  Left,
  Center,
}

export enum NumberIncrementOptions {
  SameNumber,
  NewNumber,
  AlwaysAsk,
}

export enum SubscriptionType {
  None = 0,
  Basic = 1,
  Pro = 2,
  Expert = 4,
  Create = 8,
  Payment = 16,
  AdditionalUsers = 32,
  ReduceUser = 64,
  Upgrade = 128,
  Downgrade = 256,
  ChangePeriod = 512,
  Monthly = 1024,
  Quarterly = 2048,
  Yearly = 4096,
}

export enum MiscellaneousChargeStatus {
  Remove = -1,
  Off = 0,
  AlwaysAsk = 1,
  Always = 2,
}

export enum TermEnum {
  Annual = 365,
  Quarterly = 91,
  Monthly = 30,
  Trial = 21,
}

// export enum PackageJobItemType {
//   Labor,
//   Part,
//   Miscellaneous,
// }

export enum TimeType {
  Custom = 1,
  Low = 2,
  Average = 4,
  High = 8,
}

export enum TicketType {
  Invoice = 0,
  Estimate = 1,
}

export enum ReportType {
  None = 0,
  Summary = 1,
  Detailed = 2,
}

export enum TicketItemType {
  Labor,
  Parts,
  MiscellaneousCharge,
  AdhocMiscellaneous,
}

export enum TicketPhoneTypes {
  Home,
  Work,
  Mobile,
  Main,
  Secondary,
  Fax,
  Authorized,
}

export enum AccessLevel {
  None = 0,
  Inactive = 1,
  Staff = 2,
  Manager = 4,
  Owner = 8,
  SystemAdministrator = 16,
}

export enum Application {
  None = 0,
  RealTimeLaborGuide = 1,
  RealTimeLaborGuidePro = 2,
  AutomotiveExpert = 4,
}

export enum ClientStatus {
  TerminatedDnr = -2,
  Declined = -1,
  Deactivated = 0,
  Expired = 1,
  SignUpInProgress = 2,
  PreTrial = 4,
  Trial = 8,
  Subscription = 16,
  SubscriptionExtension = 32,
  Active = 64,
}

export enum DateRangeOption {
  Custom = 0,
  Today = 1,
  LastSevenDays = 2,
  ThisMonth = 3,
  ThisQuarter = 4,
  ThisYear = 5,
  Yesterday = 6,
  PreviousMonth = 7,
  PreviousQuarter = 8,
  PreviousYear = 9,
}


export enum TicketWorkflowSteps {
  Customer1 = 0,
  Vehicle = 1,
  JobCategory = 2,
  JobType = 3,
  Job = 4,
  Time = 5,
  Summary = 6,
  Customer2 = 7,
  Finalize = 8,
  Complete = 9,
  View = 10
}

export enum JobCategory {
  AdjustmentsDiagnosis = 1,
  BasicMaintenance = 2,
  BrakesWheels = 3,
  CoolingSystemBelts = 4,
  ComputersElectronics = 5,
  DriveTrainRearEnd = 6,
  ElectricalLights = 7,
  EngineValveTrain = 8,
  ExhaustEmissions = 9,
  FrontEndSuspension = 10,
  FuelSystemTuneup = 11,
  GasketsSeals = 12,
  HeatingAC = 13,
  MiscellaneousAccessories = 14,
  SteeringColumnGauges = 15,
  TransmissionTransaxle = 16,
}

export enum TicketChangeLogType {
  TicketCreated,
  TicketReset,
  OpenedToView,
  OpenedToEdit,
  TicketCompleted,
  TicketImported,
  VehicleReset,
  VehicleSelected,
  VehicleAssigned,
  VehicleDataRemoved,
  VehicleDataEntered,
  VehicleMileageOverrode,
  VehicleVinEntered,
  VehicleVinRemoved,
  CustomerDataReset,
  CustomerDataEntered,
  CustomerAssigned,
  PoNumberReset,
  PoNumberUpdated,
  TicketTypeUpdatedtoEstimate,
  TicketTypeUpdatedtoInvoice,
  TicketNumberAssigned,
  TicketDeleted,
  TicketDeleteRelated,
  TicketRestored,
  TaxExemptToggledOff,
  TaxExemptToggledOn,
  TicketItemAdded,
  TicketItemUpdated,
  TicketPackageJob,
  TicketItemMcAdded,
  TicketItemMcRemoved,
  TicketItemTechRemoved,
  TicketItemTechAssigned,
  TicketItemTechUpdated,
  TicketTechAssignment,
  TicketItemVendorRemoved,
  TicketItemVendorAssigned,
  TicketItemVendorUpdated,
  TicketVendorAssignment,
  TicketItemRemoved,
  TicketPaymentAdded,
  TicketPaymentRefunded,
}

export enum UserAdminNoteType {
  UpdatedCompanyDetails = 2100,
  UpdatedAddress = 2106,
  UpdatedInfoMisc = 2101,
  UpdatedInfoAdditional = 2102,
  UpdatedEmail = 2103,
  UpdatedPhoneFax = 2104,
  UpdatedPhoneMain = 2105,
  UpdatedCompanyName = 2107,
  UpdatedExcludedManufacturers = 2111,
  UpdatedAutoPoNumberAssignmentEnabled = 2121,
  UpdatedInvoiceNumber = 2122,
  UpdatedEstimateNumber = 2123,
  UpdatedAutoPoNumber = 2124,
  UpdatedTicketDefaultSettings = 2141,
  ToggledCustomPayType = 2161,
  ToggledStandardPayType = 2162,
  AddedPayType = 2163,
  UpdatedPayType = 2164,
  DeletedPayType = 2165,
  UpdatedRates = 2171,
  UpdatedTax = 2181,
  UpdatedTicketWorkflow = 2131,

  AddedMiscCharge = 2311,
  UpdatedMiscCharge = 2312,
  DeletedMiscCharge = 2313,

  UpdatedPackageJobTimeMode = 2362,
  UpdatedPackageJobName = 2363,
  DeletedPackageJob = 2364,
  DisabledPackageJob = 2365,
  EnabledPackageJob = 2366,

  AddedNewCustomVehicle = 2411,
  UpdatedCustomVehicle = 2412,
  DeletedCustomVehicle = 2413, //NOTE: Added in V4
  UpdatedIncludeCustomVehiclesInVehicleList = 2414, //NOTE: Added in V4

  AddedVendor = 2561,
  ToggledPartsMarkup = 2562,
  UpdatedDefaultPartMarkup = 2563, //NOTE: Added in V4
  DeletedVendor = 2566,
  UpdatedVendor = 2567,
  EnabledVendor = 2568,
  DisabledVendor = 2569,

  UpdatedEstimateDisclaimers = 2611,
  UpdatedInvoiceDisclaimers = 2621,
  UpdatedTicketFormatting = 2701,

  AddedTechnician = 2761,
  ToggledTechnicianTracking = 2762,
  ToggledTechnicianTrackingPartAssignment = 2763,
  DisabledTechnicianTrackingDueToNoEnabledTechs = 2764,
  DeletedTechnician = 2766,
  UpdatedTechnicianName = 2767,
  EnabledTechnician = 2768,
  DisabledTechnician = 2769,

  Unpaired360PaymentsTerminal = 2796,
  Paired360PaymentsTerminal = 2799,

  UpdatedInputSettings = 2801,

  ImportTicketDateStartOptioned = 2961,
  ImportTicketDateStartNotOptioned = 2962,
  UserConfirmedDisclaimers = 2963,
  ImportUploaded = 2965,

  UpdatedStaffUserIsActive = 3101,
  UpdatedStaffUserEmailAddress = 3102,
  UpdatedStaffUserAccessLevel = 3103,
  ResetStaffUserPassword = 3104,

  UpdatedCustomerName = 3500, //NOTE: Added in V4
  DeletedCustomer = 3501, //NOTE: Added in V4

  CompanyReviewedAndDeclined = 4102,

  UserVerifiedEmailAddress = 4401,
  UserRequestedVerificationEmail = 4402, //NOTE: Added in V4
  UserDeactivatedTrial = 4406,
  UserActivatedTrial = 4408,

  AdminUserExtendedSubscription = 4557,
  UserExtendedSubscription = 4558,

  SubscriptionCancellationPageViewed = 4590,
  CancelledSubscription = 4591,

  UserExtendedTrial = 4731,
  AdminUserExtendedTrial = 4732,

  UserSetPassword = 5401,
  UserResetPassword = 5402,
  UserChangedPassword = 5403,

  RequestedQuickVinAuthorization = 7101,
  UserTriggeredQuickVinPreAuthorization = 7102,
  QuickVinEnabled = 7151,
  QuickVinDisabled = 7152,

  SubscriptionActivated = 8101,
  UpdatedSubscription = 8102,
  UpdatedSubscriptionBillingOptions = 8103,
  PurchasedAdditionalStaffUsers = 8105,
  UpdatedBillingInformation = 8205,
  UpdatedServiceType = 8907,
}

export enum SystemAdminNoteType {
  SignUpInstantActivationByEmail = 1101,
  SignUpInstantActivationByAddress = 1102,
  SignUpInstantActivationWithPreviouslyUsedEmail = 1401,
  SignUpInstantActivationWithPreviouslyUsedAddress = 1402,
  SignUpFoundIpAddressInPreviousLogs = 1403,
  SignUpFromNonWhitelistedCountry = 1405,
  SignUpWithPreviouslyUsedAddress = 1411,
  SignUpWithPreviouslyUsedPhoneNumber = 1412,
  SignUp = 1414,

  ServiceTypeUpdated = 4510,

  IncorrectAccountStatus = 4512,
  InvalidAccountStatus = 4513,

  SubscriptionEndedWithActiveTrial = 4551,
  SubscriptionEnded = 4552,
  SubscriptionExtensionEndedWithActiveTrial = 4553,
  SubscriptionExtensionEnded = 4554,
  SubscriptionEndedWithActiveTrialEnded = 4555,
  UpdatedMissingSubscriptionEndDate = 4556,
  ResetSubscriptionExtension = 4559,
  AccountDataAccessEnabled = 4601,

  TrialStartAndEndDatesPopulated = 4701,
  TrialEndDatePopulated = 4702,
  TrialEnded = 4703,
  TrialEndedWithActiveSubscription = 4704,
  TrialActivatedAfterSubscriptionEnded = 4705,

  AuthenticationAttemptedFromTooManyLocations = 5101,
  AccountDisabledDueToExcessiveAuthenticationsDetected = 5102,
  AccountDisabledDueToLogInFromNonWhitelistedCountry = 5103,

  QuickVinCompanyInformationReviewAudit = 7121,

  SubscriptionExpiringCancellationForNewSubscription = 8108,
  CustomerAddressUnableToBeRevertedAfterCharge = 8109,

  NewSubscriptionFutureStartDateCheckSkipped = 8203,
  NewSubscriptionAttemptedWithActiveSubscription = 8209,
  NewSubscriptionFutureStartDateCheckSkipDisabled = 8505,

  SubscriptionPaymentReported = 8901,
  SubscriptionOrCardExpirationReported = 8905,
  SubscriptionCancellationReported = 8909,
}

export enum SearchType {
  None=0,
  CustomerName = 1,
  CustomerPhone = 2,
  InvoiceNumber = 3,
  EstimateNumber = 4,
  PONumber = 5,
  Tag = 6,
  Vin = 7,
  VehicleNumber = 8,
  VehicleModel = 9,
  Site = 10,
}

export enum EmailType {
  CardExpiration = 0,
  CardExpired = 1,
  ImportantPaymentInformation = 2,
  ImportShopFinished = 3,
  NewAccountNoTrial = 4,
  Payment = 5,
  PaymentReminder = 6,
  QuickVinApproved = 7,
  ReminderFreeTrial = 8,
  ReminderSubExtensionEnded = 9,
  ReminderTrialEnding = 10,
  SubscriptionPayment = 11,
  Trial7Days = 12,
  TrialExtensionAvailable = 13,
  TrialExtensionUsed = 14,
  AccountVerification = 15,
  ResentAccountVerification = 16,
  ForgotPassword = 17,
  SignUpActivation = 18,
  SignUpDecline = 19,
  TrialApproval = 20,
  AeTrialFollowUp = 21,
  AeTrialReject = 22,
  ContactForm = 23,
  TrialRejected = 24,
}
