export interface MiscellaneousCharge {
  id?: string;
  version?: number;
  description: string;
  useMinimumAmount: boolean;
  minimumAmount: number;
  useMaximumAmount: boolean;
  maximumAmount: number;
  useLaborPercentage: boolean;
  useLaborPercentageValues: boolean;
  laborPercentOver: number;
  laborPercentSplit: number;
  laborPercentUnder: number;
  usePartPercentage: boolean;
  partPercentage: number;
  status: number;
  appliesTo: string;
  isTaxable: boolean;
  createdDate?: Date;
  isLatest?:boolean;
  error?: string;
}

export class MiscellaneousCharge implements MiscellaneousCharge {
  constructor(init?: MiscellaneousChargeFormValues) {
    if (init) {
      this.id = init.id;
      this.version = init.version;
      this.description = init.description;
      this.useMinimumAmount =
        init.useMinimumAmount.toString() === "true";
      this.minimumAmount = Number(init.minimumAmount);
      this.useMaximumAmount =
        init.useMaximumAmount.toString() === "true";
      this.maximumAmount = Number(init.maximumAmount);
      this.useLaborPercentage =
        init.useLaborPercentage.toString() === "true";
      this.useLaborPercentageValues = init.useLaborPercentageValues;
      this.laborPercentOver = Number(init.laborPercentOver);
      this.laborPercentSplit = Number(init.laborPercentSplit);
      this.laborPercentUnder = Number(init.laborPercentUnder);
      this.usePartPercentage =
        init.usePartPercentage.toString() === "true";
      this.partPercentage = Number(init.partPercentage);
      this.status = Number(init.status);
      this.appliesTo = init.appliesTo;
      this.isTaxable = init.isTaxable.toString() === "true";
      if (init.createdDate) this.createdDate = new Date(init.createdDate);
      this.isLatest = init.isLatest;
      this.error = init.error;
    }
  }
}

export class MiscellaneousChargeFormValues {
  id?: string = undefined;
  version?: number;
  description: string = "";
  useMinimumAmount: boolean = false;
  minimumAmount: number = 0;
  useMaximumAmount: boolean = false;
  maximumAmount: number = 0;
  useLaborPercentage: boolean = false;
  useLaborPercentageValues: boolean = false;
  laborPercentOver: number = 0;
  laborPercentSplit: number = 0;
  laborPercentUnder: number = 0;
  usePartPercentage: boolean = false;
  partPercentage: number = 0;
  status: number = 0;
  appliesTo: string = "";
  isTaxable: boolean = false;
  createdDate?: Date;
  isLatest?:boolean = true;
  error?: string = undefined;

  constructor(miscellaneousCharge?: MiscellaneousChargeFormValues) {
    if (miscellaneousCharge) {
      this.id = miscellaneousCharge.id;
      this.version = miscellaneousCharge.version;
      this.description = miscellaneousCharge.description;
      this.useMinimumAmount = miscellaneousCharge.useMinimumAmount;
      this.minimumAmount = miscellaneousCharge.minimumAmount;
      this.useMaximumAmount = miscellaneousCharge.useMaximumAmount;
      this.maximumAmount = miscellaneousCharge.maximumAmount;
      this.useLaborPercentage = miscellaneousCharge.useLaborPercentage;
      this.useLaborPercentageValues =
      miscellaneousCharge.useLaborPercentageValues;
      this.laborPercentOver = miscellaneousCharge.laborPercentOver;
      this.laborPercentSplit = miscellaneousCharge.laborPercentSplit;
      this.laborPercentUnder = miscellaneousCharge.laborPercentUnder;
      this.usePartPercentage = miscellaneousCharge.usePartPercentage;
      this.partPercentage = miscellaneousCharge.partPercentage;
      this.status = miscellaneousCharge.status;
      this.appliesTo = miscellaneousCharge.appliesTo;
      this.isTaxable = miscellaneousCharge.isTaxable;
      if (miscellaneousCharge.createdDate) this.createdDate = new Date(miscellaneousCharge.createdDate);
      this.isLatest = miscellaneousCharge.isLatest;

      this.error = miscellaneousCharge.error;
    }
  }
}