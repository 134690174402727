import React, { useState } from "react";
import { Button, Container, Form, Grid, Select } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { UserChangePassword } from "../../../app/models/user";
import { observer } from "mobx-react-lite";

interface Props {
  userId: string;
}

export default observer(function SystemAdminChangePassword({ userId }: Props) {
  const { t } = useTranslation(["common", "pricingSettings", "errors"]);
  const { modalStore, vehicleStore, userStore, adminNotesStore } = useStore();
  const { passwordList } = vehicleStore;
  const { changePassword } = userStore;
  const { loadUserAdminNotes, resetAdminNotesRegistry } = adminNotesStore;

  const [selectedPassword, setSelectedPassword] = useState<string>(
    passwordList[1].value!.toString()
  );

  const passwordChange = new UserChangePassword();
  passwordChange.forcePasswordReset = true;
  passwordChange.isChangePassword = true;

  return (
    <>
      <PageHeader
        header=" Change Password"
        type="h2"
        divider={true}
        addTitle={false}
      />
      <Container className="page">
        <Form className="ui form error padding_top" autoComplete="off">
          <Grid>
            <Select
              placeholder=""
              label={`${t("password", { ns: "common" })}:`}
              options={passwordList}
              onChange={(e, d) => {
                setSelectedPassword(d.value!.toString());
              }}
              defaultValue={passwordList[1].value}
            ></Select>

            <Grid.Row>
              <Grid.Column>
                <Button
                  className="save_button ui button"
                  icon="cancel"
                  type="button"
                  content={t("close", { ns: "common" })}
                  onClick={modalStore.closeModal}
                  size="medium"
                />
                <Button
                  className="save_button ui button"
                  icon="save"
                  type="button"
                  color="green"
                  content={t("save", { ns: "common" })}
                  onClick={() => {
                    passwordChange.confirmPassword = selectedPassword;
                    passwordChange.newPassword = selectedPassword;
                    changePassword(
                      passwordChange,
                      true,
                      userId,
                      undefined
                    ).then(() => {
                      resetAdminNotesRegistry();
                      loadUserAdminNotes(userId);
                      modalStore.closeModal();
                    });
                  }}
                  size="medium"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Container>
    </>
  );
});
