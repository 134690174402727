import { observer } from "mobx-react-lite";
import { Button, Divider, Grid, Message, Table, } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import PageHeader from "../../../app/layout/PageHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default observer(function ClientAddresses() {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const { clientReviewStore } = useStore();

  const { loadCompanyAddressList, getCompanyAddressList } = clientReviewStore;

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    loadCompanyAddressList(id ?? "");
  }, [loadCompanyAddressList, id]);

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <PageHeader
            type={"h1"}
            header={"Company Addresses"}
            divider={false}
            addTitle={false}
          />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            content={"Back"}
            color="grey"
            icon="left caret"
            onClick={() => {
              navigate(`/admin/companyReview`);
            }}
          />
        </Grid.Column>
        <Divider />
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Company Name</Table.HeaderCell>
                <Table.HeaderCell>Date Created</Table.HeaderCell>
                <Table.HeaderCell>Address 1</Table.HeaderCell>
                <Table.HeaderCell>Address 2</Table.HeaderCell>
                <Table.HeaderCell>City</Table.HeaderCell>
                <Table.HeaderCell>State</Table.HeaderCell>
                <Table.HeaderCell>Postal Code</Table.HeaderCell>
                <Table.HeaderCell>Country</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getCompanyAddressList && getCompanyAddressList.length > 0 ? (
                getCompanyAddressList.map((addressList) => (
                  <Table.Row key={`client.${addressList.id}`}>
                    <Table.Cell><Link to={`/admin/company/${addressList.id}`}>{addressList.companyName}</Link></Table.Cell>
                    <Table.Cell>
                      {format(addressList.createdDate, "P")}
                    </Table.Cell>
                    <Table.Cell>{addressList.addressLine1}</Table.Cell>
                    <Table.Cell>{addressList.addressLine2}</Table.Cell>
                    <Table.Cell>{addressList.city}</Table.Cell>
                    <Table.Cell>{addressList.state}</Table.Cell>
                    <Table.Cell>{addressList.postalCode}</Table.Cell>
                    <Table.Cell>{addressList.country}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row key={"noItems"}>
                  <Table.Cell width="8" colSpan={8}>
                    <Message
                      color="yellow"
                      className="textAlignCenter fontStyleItalic"
                    >
                      {t("grid_noItems", { ns: "common" })}
                    </Message>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
});
