import React, { useEffect } from "react";
import { Button, Grid, Message } from "semantic-ui-react";
import MySelectInput from "./MySelectInput";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { dateOptions, filterOptions } from "../options/reportOptions";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { ReportInput } from "../../models/reports";
import { useFormikContext } from "formik";
import { reportTypeOptions } from "../options/option";
import { DateRangeOption } from "../../models/enums";
import { useStore } from "../../stores/store";
import MyDropdownInput from "./MyDropdownInput";
import { observer } from "mobx-react-lite";
import MyRadioGroup from "./RadioButtonGroups/MyRadioGroup";
import MyCheckbox from "./MyCheckbox";

interface Props {
  cancelButtonLink?: string;
  values: ReportInput;
  hasReportType: boolean;
  isUpdate: boolean;
  hasResults: boolean;
  isVendor?: boolean;
  isTech?: boolean;
  useAccrual?: boolean;
}

export default observer(function ReportDateSelector({
  cancelButtonLink,
  values,
  hasReportType,
  isUpdate,
  hasResults,
  isVendor,
  isTech,
  useAccrual,
}: Props) {
  const { t } = useTranslation(["common", "reports"]);
  const { technicianStore } = useStore();

  const { loadReportTechnicianDropdownList, technicianList } = technicianStore;
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (isTech) loadReportTechnicianDropdownList();
  }, [isTech, loadReportTechnicianDropdownList]);

  return (
    <Grid stackable className="paddingContainer">
      <Grid.Row columns={5}>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <MySelectInput
            className="myLabel"
            label={`${t("date_range", { ns: "reports" })}:`}
            options={dateOptions}
            placeholder={t("date_range", { ns: "reports" })}
            name="dateRange"
            clearable={true}
            onChange={(value: any) => {
              let today = new Date();
              let temp = new Date();
              let quarter: number = 0;
              let startDate: Date | undefined = undefined;
              let endDate: Date = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate(),
                0,
                0,
                0,
                0
              );

              switch (value) {
                case DateRangeOption.Today:
                  startDate = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDate(),
                    0,
                    0,
                    0,
                    0
                  );
                  break;
                case DateRangeOption.LastSevenDays:
                  temp = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
                  startDate = new Date(
                    temp.getFullYear(),
                    temp.getMonth(),
                    temp.getDate(),
                    0,
                    0,
                    0,
                    0
                  );
                  break;
                case DateRangeOption.ThisMonth:
                  temp = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    1,
                    0,
                    0,
                    0,
                    0
                  );
                  startDate = temp;
                  break;
                case DateRangeOption.ThisQuarter:
                  quarter = Math.floor(today.getMonth() / 3) * 3;
                  temp = new Date(today.getFullYear(), quarter, 1, 0, 0, 0, 0);
                  startDate = temp;
                  break;
                case DateRangeOption.ThisYear:
                  temp = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0);
                  startDate = temp;
                  break;
                case DateRangeOption.Yesterday:
                  temp = new Date();
                  temp.setDate(today.getDate() - 1);
                  startDate = new Date(
                    temp.getFullYear(),
                    temp.getMonth(),
                    temp.getDate(),
                    0,
                    0,
                    0,
                    0
                  );
                  endDate = new Date(
                    temp.getFullYear(),
                    temp.getMonth(),
                    temp.getDate(),
                    0,
                    0,
                    0,
                    0
                  );
                  break;
                case DateRangeOption.PreviousMonth:
                  temp = new Date(
                    today.getFullYear(),
                    today.getMonth() - 1,
                    1,
                    0,
                    0,
                    0,
                    0
                  );
                  startDate = temp;
                  temp = new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    1,
                    0,
                    0,
                    0,
                    0
                  );
                  temp.setDate(temp.getDate()-1)
                  endDate = temp;
                  break;
                case DateRangeOption.PreviousQuarter:
                  temp = new Date(
                    today.getFullYear(),
                    Math.floor(today.getMonth() / 3) * 3 - 3,
                    1,
                    0,
                    0,
                    0,
                    0
                  );
                  startDate = temp;
                  temp = new Date(
                    today.getFullYear(),
                    Math.floor(today.getMonth() / 3) * 3,
                    0,
                    0,
                    0,
                    0,
                    0
                  );
                  endDate = temp;
                  break;
                case DateRangeOption.PreviousYear:
                  temp = new Date(today.getFullYear() - 1, 0, 1, 0, 0, 0, 0);
                  startDate = temp;
                  temp = new Date(
                    today.getFullYear() - 1,
                    11,
                    31,
                    0,
                    0,
                    0,
                    0
                  );
                  endDate = temp;
                  break;
                case 0:
                  break;
              }

              setFieldValue("startDate", startDate);
              setFieldValue("endDate", endDate);
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <label className="myLabel">{`${t("start_date", {
            ns: "reports",
          })}:`}</label>
          <br />
          <SemanticDatepicker
            name="startDate"
            format="MM/DD/YYYY"
            placeholder={t("start_date", { ns: "reports" })}
            value={values.startDate}
            onChange={(e, d) => {
              setFieldValue("startDate", d.value);
            }}
          />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <label className="myLabel">{`${t("end_date", {
            ns: "reports",
          })}:`}</label>
          <br />
          <SemanticDatepicker
            name="endDate"
            format="MM/DD/YYYY"
            placeholder={t("end_date", { ns: "reports" })}
            value={values.endDate}
            onChange={(e, d) => {
              setFieldValue("endDate", d.value);
            }}
          />
        </Grid.Column>
        {hasReportType && (
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <MyRadioGroup
              name={"reportType"}
              options={reportTypeOptions}
              inline={true}
            />
          </Grid.Column>
        )}
        {(isVendor || isTech) && (
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <MyDropdownInput
              label={`${t("filter", { ns: "reports" })}:`}
              placeholder={""}
              name={"filter"}
              options={
                isTech ? filterOptions.concat(technicianList) : filterOptions
              }
              className={""}
            ></MyDropdownInput>
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        {useAccrual && (
          <Grid.Column mobile={16} tablet={8} computer={4}>
            <MyCheckbox
              label={t("accrualAccounting", {
                ns: "reports",
              })}
              name={"useAccrual"}
              disabled={false}
            />
          </Grid.Column>
        )}
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Button
            color="green"
            className="save_button"
            icon="check"
            content={
              isUpdate
                ? t("update", { ns: "common" })
                : t("get_report", { ns: "reports" })
            }
            type="submit"
          />
          <Button
            className="save_button"
            as={Link}
            to={cancelButtonLink}
            icon="cancel"
            type="reset"
            content={t("cancel", { ns: "common" })}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          {!isUpdate && (
            <Message info header={t("banner", { ns: "reports" })} />
          )}
          {isUpdate && !hasResults && (
            <Message info>
              <b>{t("noResults", { ns: "reports" })}</b>{" "}
              {t("changeReportOptions", { ns: "reports" })}
            </Message>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
});
