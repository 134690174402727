import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";
import { useTranslation } from "react-i18next";
import { SettingsTicketInputDefaults } from "../../../../app/models/ticket";
import { formatPhoneNumber } from "../../../../app/common/util/functions";

interface Props {
  settingsTicketInputDefaults: SettingsTicketInputDefaults;
}

export default observer(function CustomerPdfView({
  settingsTicketInputDefaults,
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);
  const { ticketSessionStore } = useStore();

  const { selectedTicketSession } = ticketSessionStore;

  // let fontSize = 10.5;

  // if (
  //   selectedTicketSession?.customer?.email &&
  //   ((selectedTicketSession.customer.email.length > 15 && selectedTicketSession.customer.email === selectedTicketSession.customer.email.toUpperCase())
  //   || (selectedTicketSession.customer.email.length > 20))
  // ) {

  //   let email = (1 - (selectedTicketSession.customer.email.length/15));

  // }

  return (
    <View
      style={{
        ...compose(`view borderSolid padding5pxLeft`),
        justifyContent: "center",
        flexGrow:1,
        flexShrink:1
      }}
    >
      {selectedTicketSession?.customer &&
      (selectedTicketSession.customer.firstName ||
        selectedTicketSession.customer.lastName ||
        selectedTicketSession.customer.companyName) ? (
        <>
          <Text style={{ ...compose("text customer-title") }}>
            {selectedTicketSession.customer.firstName
              ? `${selectedTicketSession.customer.firstName} `
              : ""}
            {selectedTicketSession.customer.lastName}{" "}
          </Text>

          {selectedTicketSession.customer.isCommercial &&
            selectedTicketSession.customer.companyName && (
              <Text style={{ ...compose("text customer-title") }}>
                {selectedTicketSession.customer.companyName}
              </Text>
            )}
        </>
      ) : (
        <Text style={{ ...compose("text customer-title") }}>
          {t("unnamedCustomer", { ns: "tickets" })}
        </Text>
      )}

      <Text>{selectedTicketSession?.customer?.addressLine1}</Text>
      <Text>{selectedTicketSession?.customer?.addressLine2}</Text>
      <Text>
        {selectedTicketSession?.customer?.city &&
          selectedTicketSession.customer.city}
        {selectedTicketSession?.customer?.state &&
          `, ${selectedTicketSession.customer.state}`}
        {selectedTicketSession?.customer?.postalCode &&
          ` ${selectedTicketSession.customer.postalCode}`}
      </Text>
      {selectedTicketSession?.customer?.primaryNumber && (
        <Text>
          {selectedTicketSession.customer.isCommercial
            ? t("main", { ns: "customer" })
            : t("cell", { ns: "common" })}
          :{" "}
          {settingsTicketInputDefaults.formatPhoneNumber
            ? formatPhoneNumber(selectedTicketSession.customer.primaryNumber)
            : selectedTicketSession.customer.primaryNumber}
        </Text>
      )}
      {selectedTicketSession?.customer?.secondaryNumber && (
        <Text>
          {selectedTicketSession.customer.isCommercial
            ? t("secondary", { ns: "customer" })
            : t("home", { ns: "common" })}
          :{" "}
          {settingsTicketInputDefaults.formatPhoneNumber
            ? formatPhoneNumber(selectedTicketSession.customer.secondaryNumber)
            : selectedTicketSession.customer.secondaryNumber}
        </Text>
      )}
      {selectedTicketSession?.customer?.ternaryNumber && (
        <Text>
          {selectedTicketSession.customer.isCommercial
            ? t("fax", { ns: "common" })
            : t("work", { ns: "customer" })}
          :{" "}
          {settingsTicketInputDefaults.formatPhoneNumber
            ? formatPhoneNumber(selectedTicketSession.customer.ternaryNumber)
            : selectedTicketSession.customer.ternaryNumber}
        </Text>
      )}
      {selectedTicketSession?.customer?.email && (
        <Text>{selectedTicketSession.customer.email}</Text>
      )}
    </View>
  );
});
