import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Container, Form, Message, Segment } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useStore } from "../../../app/stores/store";
import { useTranslation } from "react-i18next";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import { RegexConstants } from "../../../app/common/util/regexConstants";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import ValidationErrors from "../../errors/ValidationErrors";
import PageHeader from "../../../app/layout/PageHeader";
import { PhoneNumberType } from "../../../app/models/enums";

export default observer(function SignUp() {
  const { userStore } = useStore();
  const { getUserNameSuggestion, registrationValues, checkEmail } = userStore;
  const { t } = useTranslation(["common", "profile", "errors", "corrections"]);

  const phoneRegExp = new RegExp(RegexConstants.phoneRegExp);
  const nameRegExp = new RegExp(RegexConstants.nameRegExp);
  const businessNameRegExp = new RegExp(RegexConstants.businessNameRegExp);

  const validationSchema = Yup.object({
    clientName: Yup.string()
      .required(t("registration.required_ClientName", { ns: "errors" }))
      .matches(
        businessNameRegExp,
        t("registration.required_ClientName", { ns: "errors" })
      ),
    firstName: Yup.string()
      .required(t("registration.required_FirstName", { ns: "errors" }))
      .matches(
        nameRegExp,
        t("registration.invalid_FirstName", { ns: "errors" })
      ),
    lastName: Yup.string()
      .required(t("registration.required_LastName", { ns: "errors" }))
      .matches(
        nameRegExp,
        t("registration.invalid_LastName", { ns: "errors" })
      ),
    email: Yup.string()
      .required(t("registration.required_Email", { ns: "errors" }))
      .email(t("registration.invalid_Email", { ns: "errors" }))
      .test(
        "test-email",
        t("userAddEmailTaken", { ns: "errors" }),
        async (username) => {
          return checkEmail(username ?? "")
            .then(() => true)
            .catch(() => false);
        })
      ,
    phoneNumber: Yup.string()
      .required(t("registration.required_PhoneNumber", { ns: "errors" }))
      .matches(
        phoneRegExp,
        t("registration.invalid_PhoneNumber", { ns: "errors" })
      ),
  });

  return (
    <>
      <PageHeader
        header={t("signup", { ns: "common" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container text>
        <Segment textAlign="left" className="form-background-color">
          <Formik
            initialValues={registrationValues}
            onSubmit={(values, { setErrors }) =>
              getUserNameSuggestion(values).catch((error) =>
                setErrors({ error: error })
              )
            }
            validationSchema={validationSchema}
          >
            {({
              handleSubmit,
              isSubmitting,
              errors,
              isValid,
              dirty,
              setFieldError
            }) => (
              <Form
                className="ui form error"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Message info>{t("mission", { ns: "common" })}</Message>
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
                <MyTextInput
                  name="clientName"
                  placeholder={t("companyname", { ns: "common" })}
                  label={`${t("companyname", { ns: "common" })}:`}
                  autoCapitalizeFirstLetter={false}
                  maxLength={500}
                />
                <MyTextInput
                  name="firstName"
                  placeholder={t("first_name", { ns: "common" })}
                  label={`${t("first_name", { ns: "common" })}:`}
                  autoCapitalizeFirstLetter={false}
                  maxLength={255}
                />
                <MyTextInput
                  name="lastName"
                  placeholder={t("last_name", { ns: "common" })}
                  label={`${t("last_name", { ns: "common" })}:`}
                  autoCapitalizeFirstLetter={false}
                  maxLength={255}
                />
                <MyTextInput
                  name="email"
                  placeholder={t("email", { ns: "common" })}
                  label={`${t("email", { ns: "common" })}:`}
                  autoCapitalizeFirstLetter={false}
                  maxLength={255}
                />
                <PhoneNumberInput
                  name="phoneNumber"
                  placeholder={t("phonenumber", { ns: "common" })}
                  label={`${t("phonenumber", { ns: "common" })}:`}
                  autoFormat={true}
                  phoneType={PhoneNumberType.Landline}
                ></PhoneNumberInput>
                <Button
                  disabled={!isValid || !dirty || isSubmitting}
                  loading={isSubmitting}
                  icon={"thumbs up"}
                  color={"green"}
                  positive
                  content={t("signup", { ns: "common" })}
                />
              </Form>
            )}
          </Formik>
        </Segment>
      </Container>
    </>
  );
});
