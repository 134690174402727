import React, { useEffect, useState } from "react";
import { Button, Divider, Form, Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { useStore } from "../../app/stores/store";
import { PackageJobsFormValues } from "../../app/models/packageJobs";
import { observer } from "mobx-react-lite";
import PageHeader from "../../app/layout/PageHeader";
import MyRadioGroup from "../../app/common/form/RadioButtonGroups/MyRadioGroup";
import { packageJobOptions } from "../../app/common/options/option";

export default observer(function PackageJobDetails() {
  const { t } = useTranslation(["common", "translation", "tickets"]);

  const { packageJobStore, modalStore, ticketSessionStore, ticketStore } =
    useStore();
  const { createPackageJobs } = packageJobStore;
  const { ticketItems } = ticketSessionStore;
  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  const [packageJob] = useState<PackageJobsFormValues>(
    new PackageJobsFormValues()
  );

  const validationSchema = Yup.object({
    name: Yup.string().required("Description is Required"),
  });

  function handleFormSubmit(values: PackageJobsFormValues) {
    createPackageJobs(values, Array.from(ticketItems.values()))
      .then(() => modalStore.closeModal())
      .catch((error) => console.log(error));
  }

  return (
    <>
      <PageHeader
        type={"h3"}
        header={t("pops.save_pkg_job", {
          ns: "tickets",
        }).toString()}
        divider={true}
        addTitle={false}
      />
      <p>{t("pops.save_items", { ns: "tickets" })}</p>
      <Formik
        validationSchema={validationSchema}
        initialValues={packageJob}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
        }) => (
          <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <MyTextInput
                    label={`${t("description", { ns: "common" })}:`}
                    name="name"
                    placeholder={t("description", { ns: "common" })}
                    autoCapitalizeFirstLetter={
                      selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                    }
                    maxLength={255}
                  />
                </Grid.Column>
                <Grid.Column>
                  <MyRadioGroup
                    name={"isFixedRate"}
                    label={`${t("packages.laborTimes", {
                      ns: "miscSettings",
                    })}:`}
                    options={packageJobOptions}
                    inline={true}
                  />
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column>
                  <Button type="submit">
                    <Icon name="check" />
                    {t("pops.save_pkg_job", { ns: "tickets" })}
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      modalStore.closeModal();
                    }}
                  >
                    <Icon name="x" />
                    {t("cancel", { ns: "common" })}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
});
