import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, List } from "semantic-ui-react";
import PageHeader from "../../app/layout/PageHeader";

export default observer(function Eula() {
  const { t } = useTranslation(["common", "users", "errors", "eula"]);

  return (
    <Container fluid className="page">
      <PageHeader
        header={t("eula_header", { ns: "eula" })}
        type={"h1"}
        divider={true}
        addTitle={true}
      />
      <p> {t("intro", { ns: "eula" })} </p>
      <div>
        <List ordered>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("sign_up.intro", { ns: "eula" })}: `}
              </span>
              {t("sign_up.content", { ns: "eula" })}
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("products.intro", { ns: "eula" })}: `}
              </span>
              {t("products.content", { ns: "eula" })}
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("subscriptions.intro", { ns: "eula" })}: `}
              </span>
              <List.List>
                <List.Item>
                  <span className="firstList">
                    {t("subscriptions.content_a", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("subscriptions.content_b", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("subscriptions.content_c", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("subscriptions.content_d", { ns: "eula" })}
                  </span>
                </List.Item>
              </List.List>
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("account.intro", { ns: "eula" })}: `}
              </span>
              {t("account.content", { ns: "eula" })}
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("term.intro", { ns: "eula" })}: `}
              </span>
              {t("term.content", { ns: "eula" })}
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("license.intro", { ns: "eula" })}: `}
              </span>
              <List.List>
                <List.Item>
                  <span className="firstList">
                    {t("license.content_a", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("license.content_b", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("license.content_c", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("license.content_d", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("license.content_e", { ns: "eula" })}
                  </span>
                </List.Item>
              </List.List>
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("price_terms.intro", { ns: "eula" })}: `}
              </span>
              <List.List>
                <List.Item>
                  <span className="firstList">
                    {t("price_terms.content_a", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("price_terms.content_b", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("price_terms.content_c", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("price_terms.content_d", { ns: "eula" })}
                  </span>
                </List.Item>
              </List.List>
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("maintenance.intro", { ns: "eula" })}: `}
              </span>
              {t("maintenance.content", { ns: "eula" })}
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("confidentiality.intro", { ns: "eula" })}: `}
              </span>
              {t("confidentiality.content", { ns: "eula" })}
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("modifications.intro", { ns: "eula" })}: `}
              </span>
              {t("modifications.content", { ns: "eula" })}
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("data.intro", { ns: "eula" })}: `}
              </span>
              <List.List>
                <List.Item>
                  <span className="intro">
                    {t("data.intro_1", { ns: "eula" })}
                  </span>
                  <List.List>
                    <List.Item>
                      <span className="subList">
                        {t("data.content_a", { ns: "eula" })}
                      </span>
                    </List.Item>
                    <List.Item>
                      <span className="subList">
                        {t("data.content_b", { ns: "eula" })}
                      </span>
                    </List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                  <span className="intro">
                    {t("data.intro_2", { ns: "eula" })}
                  </span>
                  <List.List>
                    <List.Item>
                      <span className="subList">
                        {t("data.content_d", { ns: "eula" })}
                      </span>
                    </List.Item>
                    <List.Item>
                      <span className="subList">
                        {t("data.content_e", { ns: "eula" })}
                      </span>
                    </List.Item>
                    <List.Item>
                      <span className="subList">
                        {t("data.content_f", { ns: "eula" })}
                      </span>
                    </List.Item>
                  </List.List>
                </List.Item>
              </List.List>
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("disclaimer.intro", { ns: "eula" })}: `}
              </span>
              <List.List>
                <List.Item>
                  <span className="firstList">
                    {t("disclaimer.content_a", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("disclaimer.content_b", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.List>
                  <List.Item>
                    <span className="subList">
                      {t("disclaimer.content_1", { ns: "eula" })}
                    </span>
                  </List.Item>
                  <List.Item>
                    <span className="subList">
                      {t("disclaimer.content_2", { ns: "eula" })}
                    </span>
                  </List.Item>
                  <List.Item>
                    <span className="subList">
                      {t("disclaimer.content_3", { ns: "eula" })}
                    </span>
                  </List.Item>
                  <List.Item>
                    <span className="subList">
                      {t("disclaimer.content_4", { ns: "eula" })}
                    </span>
                  </List.Item>
                  <List.Item>
                    <span className="subList">
                      {t("disclaimer.content_5", { ns: "eula" })}
                    </span>
                  </List.Item>
                  <List.Item>
                    <span className="subList">
                      {t("disclaimer.content_6", { ns: "eula" })}
                    </span>
                  </List.Item>
                  <List.Item>
                    <span className="subList">
                      {t("disclaimer.content_7", { ns: "eula" })}
                    </span>
                  </List.Item>
                  <List.Item>
                    <span className="subList">
                      {t("disclaimer.content_8", { ns: "eula" })}
                    </span>
                  </List.Item>
                </List.List>
              </List.List>
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("liability_limitation.intro", { ns: "eula" })}: `}
              </span>
              <List.List>
                <List.Item>
                  <span className="firstList">
                    {t("liability_limitation.content_a", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("liability_limitation.content_b", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("liability_limitation.content_c", { ns: "eula" })}
                  </span>
                </List.Item>
              </List.List>
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("termination.intro", { ns: "eula" })}: `}
              </span>
              {`${t("termination.content_intro", { ns: "eula" })}: `}
              <List.List>
                <List.Item>
                  <span className="firstList">
                    {t("termination.content_a", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("termination.content_b", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("termination.content_c", { ns: "eula" })}
                  </span>
                </List.Item>
              </List.List>
              {t("termination.content", { ns: "eula" })}
            </span>
          </List.Item>
          <List.Item>
            <span className="masterList">
              <span className="intro">
                {`${t("general_provisions.intro", { ns: "eula" })}: `}
              </span>
              <List.List>
                <List.Item>
                  <span className="firstList">
                    <span className="intro">
                      {`${t("general_provisions.intro_1", { ns: "eula" })}: `}
                    </span>
                    {t("general_provisions.content_1", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    <span className="intro">
                      {`${t("general_provisions.intro_2", { ns: "eula" })}: `}
                    </span>
                    {t("general_provisions.content_2", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    <span className="intro">
                      {`${t("general_provisions.intro_3", { ns: "eula" })}: `}
                    </span>
                  </span>
                  <List.List>
                    <List.Item>
                      <span className="subList">
                        {t("general_provisions.content_a", { ns: "eula" })}
                      </span>
                    </List.Item>
                    <List.Item>
                      <span className="subList">
                        {t("general_provisions.content_b", { ns: "eula" })}
                      </span>
                    </List.Item>
                    <List.Item>
                      <span className="subList">
                        {t("general_provisions.content_c", { ns: "eula" })}
                      </span>
                    </List.Item>
                  </List.List>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    <span className="intro">
                      {`${t("general_provisions.intro_4", { ns: "eula" })}: `}
                    </span>
                    {t("general_provisions.content_4", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    <span className="intro">
                      {`${t("general_provisions.intro_5", { ns: "eula" })}: `}
                    </span>
                    {t("general_provisions.content_5", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    <span className="intro">
                      {`${t("general_provisions.intro_6", { ns: "eula" })}: `}
                    </span>
                    {t("general_provisions.content_6", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("general_provisions.content_7", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    {t("general_provisions.content_8", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    <span className="intro">
                      {`${t("general_provisions.intro_9", { ns: "eula" })}: `}
                    </span>
                    {t("general_provisions.content_9", { ns: "eula" })}
                  </span>
                </List.Item>
                <List.Item>
                  <span className="firstList">
                    <span className="intro">
                      {`${t("general_provisions.intro_10", { ns: "eula" })}: `}
                    </span>
                    {t("general_provisions.content_10", { ns: "eula" })}
                  </span>
                </List.Item>
              </List.List>
            </span>
          </List.Item>
        </List>
      </div>
    </Container>
  );
});
