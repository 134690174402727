import { Button, Container, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";

interface Props {
  clientId: string;
}

export default observer(function ClientExtendedTrial({ clientId }: Props) {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const {
    modalStore,
    clientSystemAdminStore,
    subscriptionStore,
    adminNotesStore,
  } = useStore();

  const { trialDetails, ClientSubscriptionHistoryRegistry, loadClientInformation } =
    clientSystemAdminStore;
  const { loadClientAdminNotes } = adminNotesStore;

  const { createTrialExtension } = subscriptionStore;

  const [selectedDate, setSelectedDate] = useState(() => new Date());
  const [dateDiff, setDateDiff] = useState(0);

  const IncrementDate = (increment: number) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + increment);
    setSelectedDate(newDate);

    GetDateDiff(newDate);
  };

  const GetDateDiff = (newDate: Date) => {
    const today = new Date();

    const timeDifference = newDate.getTime() - today.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    setDateDiff(daysDifference);
  };

  return (
    <>
      <PageHeader
        header=" Extend Trial"
        type="h2"
        divider={true}
        addTitle={false}
      />
      <Container className="page">
        <Grid columns={2} divided>
          <Grid.Row>
            <Grid.Column>
              <b>Date Trial Started</b>
            </Grid.Column>
            <Grid.Column>
              {trialDetails?.trialStart
                ? format(trialDetails.trialStart, "MM/dd/yyyy")
                : "N/A"}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <b>Date Trial Ended</b>
            </Grid.Column>
            <Grid.Column>
              {trialDetails?.trialEnd
                ? format(trialDetails.trialEnd, "MM/dd/yyyy")
                : "N/A"}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <b>Trial Extensions</b>
            </Grid.Column>
            <Grid.Column>{trialDetails?.totalTrials}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>Please check current company data again.</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              Change to:{" "}
              <SemanticDatepicker
                name="startDate"
                placeholder={t("start_date", { ns: "reports" })}
                value={selectedDate}
                onChange={(e, d) => {
                  setSelectedDate(
                    d.value && !Array.isArray(d.value) ? d.value : new Date()
                  );
                  GetDateDiff(
                    d.value && !Array.isArray(d.value) ? d.value : new Date()
                  );
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Button.Group vertical>
                <Button
                  className="save_button ui button"
                  type="button"
                  content={"Today:"}
                  onClick={() => {
                    setSelectedDate(new Date());
                  }}
                  size="medium"
                />{" "}
                <Button
                  className="save_button ui button"
                  type="button"
                  content={"+3 Days:"}
                  onClick={() => {
                    IncrementDate(3);
                  }}
                  size="medium"
                />{" "}
                <Button
                  className="save_button ui button"
                  color="green"
                  type="button"
                  content={"+7 Days:"}
                  onClick={() => {
                    IncrementDate(7);
                  }}
                  size="medium"
                />
              </Button.Group>
            </Grid.Column>
          </Grid.Row>
          <Button
            className="save_button ui button"
            icon="cancel"
            type="button"
            content={t("close", { ns: "common" })}
            onClick={modalStore.closeModal}
            size="medium"
          />
          <Button
            className="save_button ui button"
            icon="save"
            type="button"
            content="Extend Trial"
            onClick={() => {
              createTrialExtension(clientId, true, dateDiff).then(() => {
                loadClientInformation(clientId);
                loadClientAdminNotes(clientId);
                ClientSubscriptionHistoryRegistry.clear();
                toast.success("Extension to trial has been applied.");
                modalStore.closeModal();
              });
            }}
            size="medium"
          />
        </Grid>
      </Container>
    </>
  );
});
