import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  formatCurrency,
  formatCurrencyDecimal,
  formatDecimal,
} from "../../../../app/common/util/functions";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";
import {
  SettingsTicketFormatting,
  TicketItem,
  TicketSession,
} from "../../../../app/models/ticket";
import { TicketItemType } from "../../../../app/models/enums";

interface Props {
  ticketItems: TicketItem[];
  selectedTicketSession: TicketSession;
  selectedSettingsTicketFormatting: SettingsTicketFormatting;
}

export default observer(function PartsPdfView({
  ticketItems,
  selectedTicketSession,
  selectedSettingsTicketFormatting,
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  return (
    <View
      style={{
        ...compose("view pageWidth"),
      }}
    >
      <View
        style={{
          ...compose(
            " view flex pageWidth borderSolidTop borderSolidRight borderSolidLeft noMargin noPadding darkHeader lineHeight textAlignCenter borderSolidBottom"
          ),
        }}
      >
        <View
          style={{ ...compose("view width40 borderSolidRight padding1mmTop") }}
        >
          <Text>{t("partNo", { ns: "tickets" })}</Text>
        </View>
        <View
          style={{ ...compose("view width90 borderSolidRight padding1mmTop") }}
        >
          <Text>{t("description", { ns: "common" })}</Text>
        </View>
        <View
          style={{ ...compose("view width20 borderSolidRight padding1mmTop") }}
        >
          <Text>
            {selectedTicketSession?.showSeparateCharges ?? false
              ? t("pops.qty", { ns: "tickets" })
              : t("estimate.hr_qt", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{ ...compose("view width24 borderSolidRight padding1mmTop") }}
        >
          <Text>{t("estimate.unit_cost", { ns: "tickets" })}</Text>
        </View>
        <View style={{ ...compose("view width26 padding1mmTop") }}>
          <Text>{t("estimate.price", { ns: "tickets" })}</Text>
        </View>
      </View>
      {ticketItems && ticketItems.length > 0 ? (
        ticketItems.map((x, index) => (
          <View
            key={`ticketItem.${x.id}`}
            style={{
              ...compose(
                `view flex pageWidth borderSolidRight borderSolidLeft noMargin noPadding ${
                  ticketItems.length - 1 === index && "borderSolidBottom"
                } ${
                  selectedSettingsTicketFormatting.shadeAlternatingRows &&
                  index % 2 === 1 &&
                  "backgroundColorEEE"
                }`
              ),
              flexGrow: 1,
              flexShrink: 1,
            }}
            wrap={false}
          >
            <View
              style={{
                ...compose(
                  `view width40 padding1mmTop padding5pxLeft ${
                    selectedSettingsTicketFormatting.showBorderOnPrint &&
                    "borderSolidRight"
                  }`
                ),
              }}
            >
              <Text>{x.partCode}</Text>
            </View>
            <View
              style={{
                ...compose(
                  `view width90 padding1mmTop padding5pxLeft  ${
                    selectedSettingsTicketFormatting.showBorderOnPrint &&
                    "borderSolidRight"
                  }`
                ),
              }}
            >
              <Text>{x.description}</Text>
            </View>
            <View
              style={{
                ...compose(
                  `view width20 textAlignRight padding1mmTop padding5pxRight  ${
                    selectedSettingsTicketFormatting.showBorderOnPrint &&
                    "borderSolidRight"
                  }`
                ),
              }}
            >
              <Text>
                {((selectedTicketSession.showLaborHours &&
                  x.type === TicketItemType.Labor) ||
                  x.type !== TicketItemType.Labor) &&
                  formatDecimal(x.quantity, 2)}
              </Text>
            </View>
            <View
              style={{
                ...compose(
                  `view width24 textAlignRight padding1mmTop padding5pxRight  ${
                    selectedSettingsTicketFormatting.showBorderOnPrint &&
                    "borderSolidRight"
                  }`
                ),
              }}
            >
              <Text>
                {((selectedTicketSession.showLaborHours &&
                  x.type === TicketItemType.Labor) ||
                  x.type !== TicketItemType.Labor) &&
                  formatCurrencyDecimal(
                    Number(x.rate ?? 0),
                    x.type === TicketItemType.Parts ? 3 : 2
                  )}
              </Text>
            </View>
            <View
              style={{
                ...compose(
                  "view width26 textAlignRight padding1mmTop padding5pxRight"
                ),
              }}
            >
              <Text>{formatCurrency(x.subTotal ?? 0.0)}</Text>
            </View>
          </View>
        ))
      ) : (
        <View
          style={{
            ...compose(
              `view flex pageWidth borderSolidRight borderSolidLeft noMargin noPadding lineHeight borderSolidBottom`
            ),
          }}
        >
          <View
            style={{
              ...compose("view pageWidth padding1mmTop padding5pxLeft"),
            }}
          >
            <Text>{t("pdf_noItems", { ns: "common" })}</Text>
          </View>
        </View>
      )}
    </View>
  );
});
