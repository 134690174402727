import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Divider, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { PagingParams } from "../../../app/models/pagination";
import MyPaging from "../../../app/common/form/MyPaging";
import PageHeader from "../../../app/layout/PageHeader";
import { Link } from "react-router-dom";

export default observer(function CompanyUser() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { companyUsersStore } = useStore();
  const {
    loadClientUserTotalStat,
    getClientUserTotalStat,
    loadActiveCompanyUserTotal,
    getActiveCompanyUserTotal,
    loadCompanyUserStats,
    getCompanyUserStats,
    pagingParams,
    pagination,
    setPagingParams,
  } = companyUsersStore;

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      loadClientUserTotalStat();
    }
  }

  useEffect(() => {
    loadCompanyUserStats();
    loadClientUserTotalStat();
    loadActiveCompanyUserTotal();
  }, [loadCompanyUserStats, loadClientUserTotalStat, loadActiveCompanyUserTotal]);

  return (
    <>
      <PageHeader
        type="h1"
        header={t("Company User Stats", { ns: "systemAdmin" })}
        className="modal-text-color"
        textAlign="left"
        divider
        addTitle
      />
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Users</Table.HeaderCell>
            <Table.HeaderCell>Companies</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {getCompanyUserStats &&
            getCompanyUserStats.length > 0 &&
            getCompanyUserStats.map((x) => (
          <Table.Row>
            <Table.Cell>
              <b>{x.userCount}</b>
            </Table.Cell>
            <Table.Cell>{x.clientCount}</Table.Cell>
          </Table.Row>
            ))}
        </Table.Body>
      </Table>

      <Header
        as="h1"
        content={t("Active Company Total Users Stat", { ns: "systemAdmin" })}
        className="modal-text-color"
        textAlign="left"
      />
      <Divider />
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              Total Users For Active Companies
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          
          <Table.Row>
            <Table.Cell>
              <b>{getActiveCompanyUserTotal}</b>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Header
        as="h1"
        content={t("Company User Count Report", { ns: "systemAdmin" })}
        className="modal-text-color"
        textAlign="left"
      />
      <Divider />
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <b>Company Id</b>
            </Table.HeaderCell>
            <Table.HeaderCell>
              <b>Users</b>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getClientUserTotalStat &&
            getClientUserTotalStat.length > 0 &&
            getClientUserTotalStat.map((x) => (
              <Table.Row>
                <Table.Cell><Link className="tableLink" to={`/admin/company/${x.clientId}`}>{x.clientId}</Link></Table.Cell>
                <Table.Cell>{x.userCount}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="8">
              <MyPaging
                itemCount={pagination ? pagination.totalItems : 25}
                pageSize={pagingParams.pageSize}
                currentPage={pagination ? pagination.currentPage : 1}
                onClick={listOnClick}
                onChange={listOnClick}
                floated="right"
              ></MyPaging>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
});
