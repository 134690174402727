import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { AgCharts } from "ag-charts-react";
import { AgChartOptions, AgFlowProportionSeriesOptions, AgLineSeriesOptions } from "ag-charts-community";


interface LineGraphProps<T> {
    title: string;
    statLines: AgLineSeriesOptions[];
    data: any[];
}

export function defineSeries<T>(items: T[]){
    return items.map((itm: any) => itm)
}

export default function LineGraph<T>({
    title,
    statLines,
    data
}: LineGraphProps<T>) {
  const [options, setOptions] = useState<AgChartOptions>({
    title: {
      text: title,
    },
    data: data,
    series: statLines
  });

  return <AgCharts options={options} />;
};
