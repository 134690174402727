import { observer } from "mobx-react-lite";
import React from "react";
import { Form } from "semantic-ui-react";
import MyCheckbox from "../../../app/common/form/MyCheckbox";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { SettingsLaborRates } from "../../../app/models/pricing";
import MyIconDecimalInput from "../../../app/common/form/MyIconDecimalInput";

interface Props {
  id: number;
  name: string;
  source?: SettingsLaborRates;
  autoCapitalizeFirstLetter: boolean;
}
export default observer(function LaborRate(props: Props) {
  return (
    <Form.Group inline>
      <MyCheckbox
        name={`laborRates.${props.id}.isActive`}
        disabled={props.source?.order === 0}
        label=""
        className="checkbox_disabled"
      />
      <MyTextInput
        placeholder={""}
        name={`laborRates.${props.id}.name`}
        autoCapitalizeFirstLetter={props.autoCapitalizeFirstLetter}
        maxLength={255}
      />
      <MyIconDecimalInput
        placeholder={""}
        name={`laborRates.${props.id}.rate`}
        icon={"dollar"}
        iconPosition="left"
        maxLength={500}
      />
    </Form.Group>
  );
});
