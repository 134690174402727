import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Container,
  Form,
  Grid,
  Label,
  Message,
  SemanticCOLORS,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../app/layout/PageHeader";
import { useStore } from "../../app/stores/store";
import { Link } from "react-router-dom";
import { router } from "../../app/router/Routes";
import ConfirmDelete from "../../app/common/form/ConfirmDelete";
import LoadingComponent from "../../app/layout/LoadingComponent";
import SettingsNavBar from "../settings/SettingsNavBar";
import { MiscellaneousChargeStatus } from "../../app/models/enums";
import SettingsDropdownMenuMobile from "../settings/SettingsDropdownMenuMobile";

export default observer(function MiscCharges() {
  const { t } = useTranslation(["common", "miscSettings"]);
  const { miscellaneousChargeStore, modalStore } = useStore();
  const {
    loadMiscellaneousCharges,
    miscellaneousChargeRegistry,
    removeMiscellaneousCharge,
    setSelectedMiscellaneousCharge,
    getMiscellaneousChargesList,
    loadingInitial,
  } = miscellaneousChargeStore;

  useEffect(() => {
    loadMiscellaneousCharges();
  }, [loadMiscellaneousCharges]);

  function statusColor(miscellaneousChargeStatus: MiscellaneousChargeStatus) {
    let color: SemanticCOLORS = "grey";
    switch (miscellaneousChargeStatus) {
      case MiscellaneousChargeStatus.Always:
        color = "green";
        break;
      case MiscellaneousChargeStatus.AlwaysAsk:
        color = "yellow";
        break;

      case MiscellaneousChargeStatus.Off:
        color = "red";
        break;

      case MiscellaneousChargeStatus.Remove:
        break;
    }
    return color;
  }

  function statusText(miscellaneousChargeStatus: MiscellaneousChargeStatus) {
    let text: string = t("remove", { ns: "miscSettings" });

    switch (miscellaneousChargeStatus) {
      case MiscellaneousChargeStatus.Always:
        text = t("always", { ns: "miscSettings" });
        break;
      case MiscellaneousChargeStatus.AlwaysAsk:
        text = t("alwaysask", { ns: "miscSettings" });
        break;

      case MiscellaneousChargeStatus.Off:
        text = t("never", { ns: "miscSettings" });
        break;

      case MiscellaneousChargeStatus.Remove:
        text = t("remove", { ns: "miscSettings" });
        break;
    }
    return text;
  }

  if (loadingInitial)
    return (
      <LoadingComponent content={t("misc_loading", { ns: "miscSettings" })} />
    );

  return (
    <Grid stackable>
      <Grid.Column width={3} className="mobileHeight">
        <SettingsNavBar />
        <SettingsDropdownMenuMobile />
      </Grid.Column>
      <Grid.Column width={13}>
        <PageHeader
          header={t("miscellaneouscharges", { ns: "common" })}
          type={"h1"}
          divider={true}
          getAlerts={true}
          showHelp
          helpName="settings.miscCharges.general"
          addTitle={true}
        />
        <Container className="page">
          <Form className="ui form error" autoComplete="off">
            <Button
              color="green"
              as={Link}
              to="/settings/miscCharges/addMiscCharge"
              className="save_button"
              icon="plus"
              content={t("misc_charges.new_charge", { ns: "miscSettings" })}
            />
            <Table striped unstackable className="dnxTable">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="left"></Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("description", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("applieto", { ns: "miscSettings" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("status", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right"></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {getMiscellaneousChargesList &&
                  (getMiscellaneousChargesList.length > 0 ? (
                    Array.from(getMiscellaneousChargesList.values()).map(
                      (miscCharges, index) => (
                        <Table.Row key={`miscCharge.${miscCharges.id}`}>
                          <Table.Cell className="textAlignLeft">
                            <Button
                              color="grey"
                              icon="edit"
                              type="button"
                              onClick={() => {
                                setSelectedMiscellaneousCharge(miscCharges);
                                router.navigate(
                                  `/settings/miscCharges/editMiscCharge/${miscCharges.id}`
                                );
                              }}
                            ></Button>
                          </Table.Cell>
                          <Table.Cell data-label={`${t("description", { ns: "common" })}:`}>{miscCharges.description}</Table.Cell>
                          <Table.Cell data-label={`${t("applieto", { ns: "miscSettings" })}:`}>{miscCharges.appliesTo}</Table.Cell>
                          <Table.Cell data-label={`${t("status", { ns: "common" })}:`}>
                            <Label
                              basic
                              color={statusColor(miscCharges.status)}
                            >
                              {statusText(miscCharges.status)}
                            </Label>
                          </Table.Cell>
                          <Table.Cell className="textAlignRight">
                            <Button
                              color="grey"
                              icon="trash"
                              type="button"
                              onClick={() =>
                                modalStore.openModal(
                                  <ConfirmDelete
                                    header={t("misc_charges.removeCharge", {
                                      ns: "miscSettings",
                                    })}
                                    value={miscCharges.id ? miscCharges.id : ""}
                                    deleteValue={removeMiscellaneousCharge}
                                  />
                                )
                              }
                            ></Button>
                          </Table.Cell>
                        </Table.Row>
                      )
                    )
                  ) : (
                    <Table.Row key={"noItems"}>
                      <Table.Cell colSpan={5}>
                        <Message
                          color={"yellow"}
                          className="textAlignCenter fontStyleItalic"
                        >
                          {t("grid_noItems", {
                            ns: "common",
                          })}
                        </Message>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Form>
        </Container>
      </Grid.Column>
    </Grid>
  );
});
