import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import {
  MiscellaneousCharge,
  MiscellaneousChargeFormValues,
} from "../models/miscellaneousCharges";
import { MiscellaneousChargeStatus, SalesTaxOptionType } from "../models/enums";
import {
  getMiscChargeAmount,
  round2Decimals,
  sortingStrings,
} from "../common/util/functions";
import { TicketItem } from "../models/ticket";

export default class MiscellaneousChargeStore {
  loadingInitial = false;
  activeTab: number = 0;
  miscellaneousChargeRegistry = new Map<
    string | undefined,
    MiscellaneousCharge
  >();

  ticketMiscCharge = new Map<string | undefined, MiscellaneousCharge>();

  previousTicketMiscellaneousCharges = new Map<
    string | undefined,
    MiscellaneousCharge
  >();

  useUpdatedMiscellaneousCharges: boolean = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
        this.miscellaneousChargeRegistry.clear();
        this.loadMiscellaneousCharges();
      }
    );
  }

  resetMiscellaneousChargesRegistries = () => {
    this.miscellaneousChargeRegistry.clear();
    this.previousTicketMiscellaneousCharges.clear();
    this.ticketMiscCharge.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  selectedMiscellaneousCharge?: MiscellaneousChargeFormValues = {
    id: undefined,
    description: "",
    useMinimumAmount: false,
    minimumAmount: 0,
    useMaximumAmount: false,
    maximumAmount: 0,
    useLaborPercentage: false,
    useLaborPercentageValues: false,
    laborPercentOver: 0,
    laborPercentSplit: 0,
    laborPercentUnder: 0,
    usePartPercentage: false,
    partPercentage: 0,
    status: 0,
    appliesTo: "",
    isTaxable: false,
  };

  loadMiscellaneousCharges = async () => {
    this.loadingInitial = true;
    try {
      this.miscellaneousChargeRegistry.clear();
      const result =
        await agent.MiscellaneousChanges.listMiscellaneousCharges();
      runInAction(() => {
        result.forEach((miscellaneousCharge) => {
          this.setMiscellaneousCharge(miscellaneousCharge);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  get getMiscellaneousChargesList() {
    return Array.from(this.miscellaneousChargeRegistry.values()).sort(
      (a, b) => {
        return sortingStrings(a.description ?? "", b.description ?? "") ?? 0;
      }
    );
  }

  resetPreviousTicketMiscellaneousCharges = () => {
    this.previousTicketMiscellaneousCharges.clear();
  };

  loadPreviousTicketMiscellaneousCharges = async (ticketId: string) => {
    this.loadingInitial = true;
    try {
      this.previousTicketMiscellaneousCharges.clear();
      const result =
        await agent.MiscellaneousChanges.listPreviousTicketMiscellaneousCharges(
          ticketId
        );
      runInAction(() => {
        result.forEach((miscellaneousCharge) => {
          this.setPreviousTicketMiscellaneousCharge(miscellaneousCharge);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setPreviousTicketMiscellaneousCharge = (
    miscellaneousCharge: MiscellaneousCharge
  ) => {
    if (miscellaneousCharge.createdDate)
      miscellaneousCharge.createdDate = new Date(
        miscellaneousCharge.createdDate
      );

    this.previousTicketMiscellaneousCharges.set(
      miscellaneousCharge.id,
      miscellaneousCharge
    );
  };

  loadMiscellaneousCharge = async (id: string) => {
    let miscCharge = this.getMiscellaneousCharge(id);
    if (miscCharge) {
      this.selectedMiscellaneousCharge = miscCharge;
    } else {
      try {
        this.setLoadingInitial(true);
        miscCharge = await agent.MiscellaneousChanges.getMiscellaneousCharge(
          id
        );
        this.setMiscellaneousCharge(miscCharge);
        runInAction(() => {
          this.selectedMiscellaneousCharge = miscCharge;
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.setLoadingInitial(false);
      }
    }
    return miscCharge;
  };

  private getMiscellaneousCharge = (id: string) => {
    return this.miscellaneousChargeRegistry.get(id);
  };

  setSelectedMiscellaneousCharge = (values: MiscellaneousChargeFormValues) => {
    this.selectedMiscellaneousCharge = values;
  };

  private setMiscellaneousCharge = (
    miscellaneousCharge: MiscellaneousCharge
  ) => {
    if (miscellaneousCharge.createdDate)
      miscellaneousCharge.createdDate = new Date(
        miscellaneousCharge.createdDate
      );

    this.miscellaneousChargeRegistry.set(
      miscellaneousCharge.id,
      miscellaneousCharge
    );
  };

  createMiscellaneousCharge = async (values: MiscellaneousChargeFormValues) => {
    try {
      let myNew: MiscellaneousCharge = new MiscellaneousCharge(values);
      await agent.MiscellaneousChanges.addMiscellaneousCharge(myNew);

      runInAction(() => {
        this.miscellaneousChargeRegistry.clear();
      });
    } catch (error) {
      console.log(error);
    }
  };

  updateMiscellaneousCharge = async (values: MiscellaneousChargeFormValues) => {
    try {
      let myNew: MiscellaneousCharge = new MiscellaneousCharge(values);
      await agent.MiscellaneousChanges.updateMiscellaneousCharge(myNew);

      runInAction(() => {
        this.miscellaneousChargeRegistry.clear();
      });
    } catch (error) {
      console.log(error);
    }
  };

  removeMiscellaneousCharge = async (id: string) => {
    try {
      await agent.MiscellaneousChanges.removeMiscellaneousCharge(id);

      runInAction(() => {
        this.miscellaneousChargeRegistry.clear();
      });
    } catch (error) {
      console.log(error);
    }
  };

  calculateMiscCharge = (
    labor: number,
    parts: number,
    miscellaneousCharge: MiscellaneousChargeFormValues
  ) => {
    let misc = getMiscChargeAmount(labor, parts, miscellaneousCharge);
    return misc;
  };

  calculateMiscChargeTax = (
    labor: number,
    parts: number,
    miscCharge: number,
    isTaxable: boolean,
    taxRate: number,
    settingsTaxOptions: SalesTaxOptionType
  ) => {
    let tax: number = 0;

    if (isTaxable) {
      tax = this.calculateTax(
        labor,
        parts,
        miscCharge,
        taxRate,
        settingsTaxOptions
      );
    }

    return tax;
  };

  calculateTax(
    labor: number,
    parts: number,
    miscellaneousCharge: number,
    taxRate: number,
    settingsTaxOptions: SalesTaxOptionType
  ) {
    let rateLabor: number =
      (settingsTaxOptions & SalesTaxOptionType.Labor) ===
      SalesTaxOptionType.Labor
        ? taxRate
        : 0;
    let rateParts: number =
      (settingsTaxOptions & SalesTaxOptionType.Parts) ===
      SalesTaxOptionType.Parts
        ? taxRate
        : 0;
    let rateMisc: number = taxRate;
    let taxAmount: number = 0;

    if (rateLabor > 0) {
      taxAmount = round2Decimals(rateLabor * round2Decimals(labor) * 0.01);
    }
    if (rateParts > 0) {
      taxAmount =
        taxAmount + round2Decimals(rateLabor * round2Decimals(parts) * 0.01);
    }
    if (miscellaneousCharge > 0) {
      taxAmount =
        taxAmount +
        round2Decimals(rateMisc * round2Decimals(miscellaneousCharge) * 0.01);
    }

    return taxAmount;
  }

  get alwaysMiscellaneousChargeItems() {
    if (
      this.previousTicketMiscellaneousCharges.size > 0 &&
      !this.useUpdatedMiscellaneousCharges
    ) {
      return Array.from(
        this.previousTicketMiscellaneousCharges.values()
      ).filter((x) => {
        return x.status === MiscellaneousChargeStatus.Always;
      });
    } else {
      return Array.from(this.miscellaneousChargeRegistry.values()).filter(
        (x) => {
          return x.status === MiscellaneousChargeStatus.Always;
        }
      );
    }
  }

  get allMiscellaneousChargesForTicket() {
    if (
      this.previousTicketMiscellaneousCharges.size > 0 &&
      !this.useUpdatedMiscellaneousCharges
    ) {
      return Array.from(
        this.previousTicketMiscellaneousCharges.values()
      ).filter((x) => {
        return (
          x.status === MiscellaneousChargeStatus.Always ||
          x.status === MiscellaneousChargeStatus.AlwaysAsk
        );
      });
    } else {
      return Array.from(this.miscellaneousChargeRegistry.values()).filter(
        (x) => {
          return (
            x.status === MiscellaneousChargeStatus.Always ||
            x.status === MiscellaneousChargeStatus.AlwaysAsk
          );
        }
      );
    }
  }

  get updatedCharge() {
    if (this.previousTicketMiscellaneousCharges.size > 0) {
      let updates = Array.from(
        this.previousTicketMiscellaneousCharges.values()
      ).filter((x) => {
        return x.isLatest === false;
      });

      return (
        updates.length > 0 ||
        this.previousTicketMiscellaneousCharges.size !=
          this.miscellaneousChargeRegistry.size
      );
    } else {
      return false;
    }
  }

  setUpdateMiscellaneousCharges = (value: boolean) => {
    this.useUpdatedMiscellaneousCharges = value;
  };

  loadTicketSummaryMiscCharges = (
    miscellaneousChargeTicketItems: TicketItem[],
    useUpdated?: boolean
  ) => {
    this.ticketMiscCharge.clear();
    let diffs = this.alwaysMiscellaneousChargeItems.filter(
      (item) =>
        !miscellaneousChargeTicketItems.some(
          (charges) => item.id === charges.miscellaneousChargeId
        )
    );
    diffs.forEach((items) => {
      this.ticketMiscCharge.set(items.id, items);
    });

    let miscCharges = new Map<string | undefined, MiscellaneousCharge>();

    if (this.previousTicketMiscellaneousCharges.size > 0 && !useUpdated) {
      miscCharges = new Map(
        [...this.previousTicketMiscellaneousCharges].filter(
          ([k, v]) => v.status === MiscellaneousChargeStatus.AlwaysAsk
        )
      );
    } else {
      miscCharges = new Map(
        [...this.miscellaneousChargeRegistry].filter(
          ([k, v]) => v.status === MiscellaneousChargeStatus.AlwaysAsk
        )
      );
    }

    diffs = Array.from(miscCharges.values()).filter(
      (item) =>
        !miscellaneousChargeTicketItems.some(
          (charges) => item.id === charges.miscellaneousChargeId
        )
    );

    diffs.forEach((items) => {
      this.ticketMiscCharge.set(items.id, items);
    });


  };

  get ticketSummaryMiscCharges() {
    let miscellaneousCharges: MiscellaneousCharge[] = Array.from(
      this.ticketMiscCharge.values()
    ).sort((a, b) => b.createdDate!.getTime() - a.createdDate!.getTime());
    return miscellaneousCharges;
  }

  addTicketSummaryMiscCharges = (miscChargeId: string) => {
    let charge = this.miscellaneousChargeRegistry.get(miscChargeId);
    if (charge) this.ticketMiscCharge.set(charge.id, charge);
  };

  removeTicketSummaryMiscCharges = (key: string) => {
    if (this.ticketMiscCharge.size > 0) this.ticketMiscCharge.delete(key);
  };
}
