import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import {
  DeletedCustomers,
} from "../../models/systemAdmin";

export default class CompanyDataRecoveryStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
  DeletedCustomersRegistry = new Map<string, DeletedCustomers>();

  restoreCustomer = async (id: string) => {
    try {
      this.DeletedCustomersRegistry.clear();
      await agent.SystemAdminTools.restoreCustomer(id);
      runInAction(() => {})
    } catch (error) {
      console.log(error);
    } finally {

    }
  };
  
  loadDeletedCustomers = async (id: string) => {
    try {
      const result = await agent.SystemAdminTools.getDeletedCustomers(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((x) => {
          this.setDeletedCustomers(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setDeletedCustomers = (deletedCustomers: DeletedCustomers) => {
    deletedCustomers.deletedDate = new Date(deletedCustomers.deletedDate);
    this.DeletedCustomersRegistry.set(
      deletedCustomers.customerId,
      deletedCustomers
    );
  };

  get getDeletedCustomers() {
    return Array.from(this.DeletedCustomersRegistry.values()).sort((a, b) => 
    b.deletedDate.getTime() - a.deletedDate.getTime()
    );
  }

}
