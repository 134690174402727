import React from "react";
import { observer } from "mobx-react-lite";
import { Dropdown, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import { Application, ClientStatus } from "../../app/models/enums";

interface Props {
  isCancelled: boolean;
}

export default observer(function SubscriptionMobileMenu({
  isCancelled,
}: Props) {
  const { t } = useTranslation(["common", "users"]);
  const { userStore } = useStore();
  const { user, checkStatus } = userStore;

  return (
    <>
    {user?.status && user.status !== ClientStatus.PreTrial && (
    <Menu className="mobileDropdownMenu">
      <Dropdown
        selection={true}
        placeholder={t("select_page", { ns: "subscription" })}
        className="widerDropdownMenu"
      >

        <Dropdown.Menu>
        {(user?.application ?? 0) > Application.None && (
          <Dropdown.Item
            key={"status"}
            as={Link}
            to={`/subscription/Status`}
            text={t("status", { ns: "common" })}
          />)}
          {user?.isDataActive && checkStatus(ClientStatus.Trial) && (user?.application??0) > Application.None && (
              <Dropdown.Item
                key={"billingInformation"}
                as={Link}
                to={`/subscription/BillingInformation`}
                text={t("billingInformation", { ns: "subscription" })}
              />
            )}
          {user?.status &&
            checkStatus(ClientStatus.Active | ClientStatus.Subscription) &&
            !isCancelled && (
              <>
                <Dropdown.Item
                  key={"serviceType"}
                  as={Link}
                  to={`/subscription/ServiceType`}
                  text={t("service_type.label", { ns: "common" })}
                />
                <Dropdown.Item
                  key={"users"}
                  as={Link}
                  to={`/subscription/Users`}
                  text={t("subscription.additionalUsers", {
                    ns: "subscription",
                  })}
                />
                <Dropdown.Item
                  key={"renewalOptions"}
                  as={Link}
                  to={"/subscription/RenewalOptions"}
                  text={t("subscription.renewal_options", {
                    ns: "subscription",
                  })}
                />
              </>
            )}

            <Dropdown.Item
              key={"availableTrials"}
              as={Link}
              to={`/subscription/AvailableTrials`}
              text={t("availabletrials", { ns: "subscription" })}
            />
         
        </Dropdown.Menu>
      </Dropdown>
    </Menu> )}
    </>
  );
});
