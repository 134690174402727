import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Table, Message } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function MultiRecurring() {
  const { t } = useTranslation(["common", "systemAdmin"]);

  const { systemAdminReportStore } = useStore();

  const { getMultipleRecurring, loadMultipleRecurring } =
    systemAdminReportStore;

  useEffect(() => {
    loadMultipleRecurring();
  }, [loadMultipleRecurring]);

  return (
    <Grid stackable>
      <Grid.Row columns={1}>
        <Grid.Column>
          <PageHeader
            type="h2"
            header={t("reports.report_companies", {
              ns: "systemAdmin",
            }).toString()}
            className="modal-text-color"
            textAlign="left"
            divider
            addTitle
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Company ID</Table.HeaderCell>
                <Table.HeaderCell>Company Name</Table.HeaderCell>
                <Table.HeaderCell>Recurring Count</Table.HeaderCell>
                <Table.HeaderCell>Subscription ID</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getMultipleRecurring && getMultipleRecurring.length > 0 ? (
                getMultipleRecurring.map((x) => (
                  <Table.Row key={`client.${x.clientId}`}>
                    <Table.Cell>{x.clientId}</Table.Cell>
                    <Table.Cell>{x.clientName}</Table.Cell>
                    <Table.Cell>{x.recurringCount}</Table.Cell>
                    <Table.Cell>{x.subscriptionId}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row key={"noitems"}>
                  <Table.Cell width="8" colSpan={8}>
                    <Message
                      color="yellow"
                      className="textAlignCenter fontStyleItalic"
                    >
                      {t("grid_noItems", { ns: "common" })}
                    </Message>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
});
