import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Grid, Divider } from "semantic-ui-react";
import AuthenticationLog from "../AuthenticationLog";
import { useStore } from "../../../app/stores/store";
import { useParams } from "react-router-dom";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function AuthLogs() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { authenticationLogStore } = useStore();
  const {resetAuthenticationLogs, clearPredicate} = authenticationLogStore

  const { ip } = useParams<{ ip: string }>();
  const { clientId } = useParams<{ clientId: string }>();
  const { userId } = useParams<{ userId: string }>();
  const isRelatedIp = !!(clientId || userId);

  useEffect(() => {
    resetAuthenticationLogs();
    clearPredicate();
  }, []);

  const getHeading = () => {
    const heading = t("logs.auth_intro", { ns: "systemAdmin" }).toString()

    if (!isRelatedIp) return heading;

    return heading + (userId
      ? ` - User ${userId} related`
      : ` - Company ${clientId} related`);
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={getHeading()}
              className="modal-text-color"
              textAlign="left"
              divider={false}
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Column stackable>
          <AuthenticationLog ipAddress={ip} clientId={clientId} userId={userId} isRelatedIp={isRelatedIp} />
        </Grid.Column>
      </Grid>
    </>
  );
});
