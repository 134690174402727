import { DropdownItemProps } from "semantic-ui-react";
import { requests } from "./Requests";

export const DataSource = {
  DataSourceList: () =>
    requests.get<DropdownItemProps[]>(
      `/CorrectionDataSource/listCorrectionDataSources`
    ),
  CustomerList: () =>
    requests.get<DropdownItemProps[]>(
      `/CorrectionDataSource/listCorrectionDataSources/2`
    ),
};
