import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  Grid,
  Table
} from "semantic-ui-react";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { convertApplicationToString, convertClientStatusToString } from "../../../app/common/util/functions";

export default observer(function ActiveServiceTypes() {
  const { t } = useTranslation(["common", "systemAdmin"]);

  const { systemAdminReportStore } = useStore();

  const { getActiveServiceType, loadActiveServiceType } =
    systemAdminReportStore;

  useEffect(() => {
    loadActiveServiceType();
  }, [loadActiveServiceType]);

  return (
    <>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={t("reports.active_report", {
                ns: "systemAdmin",
              }).toString()}
              className="modal-text-color"
              textAlign="left"
              divider
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column stackable>
            <Table striped>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t("reports.initial_trial", { ns: "systemAdmin" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("status", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("service_type.current_service", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("reports.pro_trialed", { ns: "systemAdmin" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("reports.count", { ns: "systemAdmin" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t("reports.logins", { ns: "systemAdmin" })}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {getActiveServiceType.map((x) => (
                    <Table.Row>
                      <Table.Cell>{convertApplicationToString(x.initialApplication)}</Table.Cell>
                      <Table.Cell>{convertClientStatusToString(x.status)}</Table.Cell>
                      <Table.Cell>{convertApplicationToString(x.currentApplication)}</Table.Cell>
                      <Table.Cell>{x.triedOtherTrials ? "Yes" : "No"}</Table.Cell>
                      <Table.Cell>{x.count}</Table.Cell>
                      <Table.Cell>{x.logins}</Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
});
