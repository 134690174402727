import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import PageHeader from "../../../app/layout/PageHeader";
import { Button, ButtonGroup, Dropdown, Grid } from "semantic-ui-react";
import LineGraph from "../../../app/layout/LineGraph";
import { useStore } from "../../../app/stores/store";
import { Col, Row } from "react-bootstrap";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { subDays } from "date-fns";


export default observer(function SignUp() {
  const { statsStore } = useStore();
  const { 
    getGeneralSignupStats, 
    getCompanyApprovalStats, 
    getCompanySubscribedStats, 
    loadSignupStatsOverview, 
    setPredicate,
    setTimePeriodInDays, 
    timePeriodInDays, 
    loading 
  } = statsStore;

  useEffect(
    () => {
      setPredicate('startDate', subDays(new Date(), timePeriodInDays))
      setPredicate('endDate', new Date())
      loadSignupStatsOverview();
    },
    [timePeriodInDays]
  )

  return (
    <>
      <Row>
        <div className='col-8'>
          <PageHeader
            type="h1"
            header={`Signup Stats for ${timePeriodInDays} days`}
            className="modal-text-color"
            textAlign="left"
            divider
            addTitle
          />
        </div>
        <div className='col-4 text-end p-0'>
          <ButtonGroup color='blue'>
            <Button>Time Period</Button>
            <Dropdown
              floating
              className="button icon"
              onChange={(e, { value }) => {
                const newTimePeriodInDays = value as number;
                setTimePeriodInDays(newTimePeriodInDays);
                setPredicate('startDate', subDays(new Date(), newTimePeriodInDays));
                setPredicate("endDate", new Date());
              }}
              options={[
                { key: 1, text: '30 Days', value: 30 },
                { key: 2, text: '60 Days', value: 60 },
                { key: 3, text: '90 Days', value: 90 },
                { key: 4, text: '120 Days', value: 120 },
                { key: 5, text: '180 Days', value: 180 },
                { key: 6, text: '274 Days', value: 274 },
                { key: 7, text: '365 Days', value: 365 }
              ]}
              placeholder='Time Period'
              trigger={<></>}
            />
          </ButtonGroup>
        </div>

      </Row>
      {loading ? (
        <LoadingComponent />
      ) : (
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <LineGraph<{ date: string, emailConfirmed: number, companyWaitingForReview: number, companyApprovedActive: number, companyApprovedNoLogin: number }>
                title="Signups"
                data={getGeneralSignupStats}
                statLines={[
                  {
                    type: "line",
                    xKey: 'date',
                    yKey: "emailConfirmed",
                    yName: "Email Confirmed",
                  },
                  {
                    type: "line",
                    xKey: 'date',
                    yKey: "companyWaitingForReview",
                    yName: "Company Waiting for Review",
                  },
                  {
                    type: "line",
                    xKey: 'date',
                    yKey: "companyApprovedNoLogin",
                    yName: "Company Approved No Login",
                  },
                  {
                    type: "line",
                    xKey: 'date',
                    yKey: "companyApprovedActive",
                    yName: "Company Approved Active",
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <LineGraph<{ date: string, companyDeclined: number, companyApproved: number }>
                title="Company's Approvals"
                data={getCompanyApprovalStats}
                statLines={[
                  {
                    type: "line",
                    xKey: 'date',
                    yKey: "companyApproved",
                    yName: "Company Approved",
                  },
                  {
                    type: "line",
                    xKey: 'date',
                    yKey: "companyDeclined",
                    yName: "Company Declined",
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <LineGraph<{ date: string, companySubscribed: number, companyUsersSubscribed: number }>
                title="Company's Subscribed"
                data={getCompanySubscribedStats}
                statLines={[
                  {
                    type: "line",
                    xKey: 'date',
                    yKey: "companySubscribed",
                    yName: "Company Subscribed",
                  },
                  {
                    type: "line",
                    xKey: 'date',
                    yKey: "companyUsersSubscribed",
                    yName: "Company Users Subscribed",
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

      )}
    </>
  );
});
