import { observer } from "mobx-react-lite";
import {
  Divider,
  Dropdown,
  Grid,
  Icon,
  Input,
  Message,
  PlaceholderLine,
  PlaceholderParagraph,
  Segment,
  Table,
} from "semantic-ui-react";
import PageHeader from "../../app/layout/PageHeader";
import { SearchType } from "../../app/models/enums";
import { searchOptions } from "../../app/common/options/searchOptions";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import { PagingParams } from "../../app/models/pagination";
import MyPaging from "../../app/common/form/MyPaging";
import { useEffect, useState } from "react";
import MyHelpButton from "../../app/common/form/MyHelpButton";
import TicketSearch from "./TicketSearch";
import VehicleSearch from "./VehicleSearch";
import CustomerSearch from "./CustomerSearch";
import { useParams } from "react-router-dom";
import LoadingComponent from "../../app/layout/LoadingComponent";

export default observer(function Search() {
  const { t } = useTranslation(["common", "search"]);

  const { searchStore } = useStore();

  const {
    setCustomerPagingParams,
    setTicketPagingParams,
    setVehiclePagingParams,
    customerPagingParams,
    customerPagination,
    ticketPagingParams,
    ticketPagination,
    vehiclePagingParams,
    vehiclePagination,
    setPredicate,
    getCustomerSearch,
    getVehicleSearch,
    getTicketSearch,
    loadSearch,
    resetSearchRegistry,
    loadingInitial,
  } = searchStore;

  const { searchType } = useParams<{ searchType: string }>();
  const { searchValue } = useParams<{ searchValue: string }>();

  const [value, setValue] = useState(searchValue);

  const [searchState, setSearchState] = useState<SearchType>(
    !isNaN(parseInt(searchType ?? ""))
      ? parseInt(searchType ?? "")
      : SearchType.Site
  );

  useEffect(() => {
    if (value && searchState) {
      search(value, Number(searchState));
    }
  }, []);

  const [title, setTitle] = useState<string>(getSearchTitle(searchState));

  function customerListOnClick(value: number) {
    if (
      value > 0 &&
      customerPagination &&
      value < customerPagination?.totalPages + 1
    ) {
      setCustomerPagingParams(new PagingParams(value));
      resetSearchRegistry();
      loadSearch();
    }
  }
  function vehicleListOnClick(value: number) {
    if (
      value > 0 &&
      vehiclePagination &&
      value < vehiclePagination?.totalPages + 1
    ) {
      setVehiclePagingParams(new PagingParams(value));
      resetSearchRegistry();
      loadSearch();
    }
  }
  function ticketListOnClick(value: number) {
    if (
      value > 0 &&
      ticketPagination &&
      value < ticketPagination?.totalPages + 1
    ) {
      setTicketPagingParams(new PagingParams(value));
      resetSearchRegistry();
      loadSearch();
    }
  }

  function search(searchValue: string, state: SearchType) {
    setSearchState(state);
    setPredicate(state, searchValue);
  }

  function getSearchTitle(value: SearchType) {
    let returnValue: string = "";
    switch (value) {
      case SearchType.CustomerName:
      case SearchType.CustomerPhone:
        returnValue = "customer";
        break;
      case SearchType.InvoiceNumber:
      case SearchType.EstimateNumber:
      case SearchType.PONumber:
        returnValue = "ticket";
        break;
      case SearchType.Tag:
      case SearchType.Vin:
      case SearchType.VehicleNumber:
      case SearchType.VehicleModel:
        returnValue = "vehicle";
        break;

      default:
        returnValue = "";
        break;
    }
    return returnValue;
  }

  // if (loadingInitial)
  //  return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <>
      <Grid columns={2}>
        <PageHeader
          header={`${t("buttons.search", { ns: "customer" })} ${
            title.length > 0
              ? t(title, {
                  ns: "search",
                })
              : ""
          }`}
          type={"h2"}
          divider={false}
          addTitle={true}
        />
        <MyHelpButton
          position="bottom left"
          name="search"
          help="search"
          color="blue"
          seeMore={true}
        />
      </Grid>
      <Divider />
      <Segment basic={true} textAlign="right">
        <Input
          className="searchHighlight"
          action={
            <Dropdown
              button
              basic
              floating
              options={searchOptions}
              value={searchState}
              onChange={(e, d) => {
                setSearchState(Number(d.value));
                setTitle(getSearchTitle(Number(d.value)));
                if (value && d.value) search(value, Number(d.value));
              }}
            />
          }
          onChange={(e, d) => {
            setValue(d.value);
          }}
          onBlur={(e: any, d: any) => {
            if (value && searchState) search(value, Number(searchState));
          }}
          onKeyPress={(e: any, d: any) => {
            if (
              (e.nativeEvent.code === "Enter" ||
                e.nativeEvent.code === "NumpadEnter") &&
              value &&
              searchState
            ) {
              search(value, Number(searchState));
            }
          }}
          icon="search"
          iconPosition="left"
          placeholder="Search..."
          value={value}
        />
      </Segment>

      {(searchState === SearchType.CustomerName ||
        searchState === SearchType.CustomerPhone ||
        searchState === SearchType.Site) && (
        <Table striped unstackable className="dnxTable">
          <Table.Header className="needAHeader">
            <Table.Row>
              <Table.HeaderCell colSpan="8" className="addedHeader">
                <Icon name="users" />
                {t("customer", { ns: "search" })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {getCustomerSearch.length > 0 ? (
              getCustomerSearch.map((x) => (
                <CustomerSearch key={`customerSearch.${x.id}`} customer={x} />
              ))
            ) : !loadingInitial && (
              <Table.Row key={"noItems"}>
                <Table.Cell colSpan={8} className="noPaddingLeft">
                  <Message
                    color="yellow"
                    className="textAlignCenter fontStyleItalic"
                  >
                    {t("grid_noItems", { ns: "common" })}
                  </Message>
                </Table.Cell>
              </Table.Row>
            ) }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan="8">
                <MyPaging
                  itemCount={
                    customerPagination ? customerPagination.totalItems : 10
                  }
                  pageSize={customerPagingParams.pageSize}
                  currentPage={
                    customerPagination ? customerPagination.currentPage : 1
                  }
                  onClick={customerListOnClick}
                  onChange={customerListOnClick}
                  floated="right"
                ></MyPaging>
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
      {searchState > SearchType.PONumber && (
        <Table striped unstackable className="dnxTable">
          <Table.Header className="needAHeader">
            <Table.Row>
              <Table.HeaderCell colSpan="8" className="addedHeader">
                <Icon name="car" />
                {t("vehicle", { ns: "search" })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {getVehicleSearch.length > 0 ? (
              getVehicleSearch.map((x) => (
                <VehicleSearch key={`vehicleSearch.${x.id}`} vehicle={x} />
              ))
            ) : (
              <Table.Row key={"noItems"}>
                <Table.Cell colSpan={8} className="noPaddingLeft">
                  <Message
                    color="yellow"
                    className="textAlignCenter fontStyleItalic"
                  >
                    {t("grid_noItems", { ns: "common" })}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan="8">
                <MyPaging
                  itemCount={
                    vehiclePagination ? vehiclePagination.totalItems : 10
                  }
                  pageSize={vehiclePagingParams.pageSize}
                  currentPage={
                    vehiclePagination ? vehiclePagination.currentPage : 1
                  }
                  onClick={vehicleListOnClick}
                  onChange={vehicleListOnClick}
                  floated="right"
                ></MyPaging>
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
      {((searchState > SearchType.CustomerPhone &&
        searchState < SearchType.Tag) ||
        searchState === SearchType.Site) && (
        <Table striped unstackable className="dnxTable">
          <Table.Header className="needAHeader">
            <Table.Row>
              <Table.HeaderCell colSpan="4" className="addedHeader">
                <Icon name="ticket" />
                {t("ticket", { ns: "search" })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {getTicketSearch.length > 0 ? (
              getTicketSearch.map((x) => (
                <TicketSearch key={`ticketSearch.${x.id}`} ticket={x} />
              ))
            ) : (
              <Table.Row key={"noItems"}>
                <Table.Cell colSpan={4} className="noPaddingLeft">
                  <Message
                    color="yellow"
                    className="textAlignCenter fontStyleItalic"
                  >
                    {t("grid_noItems", { ns: "common" })}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan="4">
                <MyPaging
                  itemCount={
                    ticketPagination ? ticketPagination.totalItems : 10
                  }
                  pageSize={ticketPagingParams.pageSize}
                  currentPage={
                    ticketPagination ? ticketPagination.currentPage : 1
                  }
                  onClick={ticketListOnClick}
                  onChange={ticketListOnClick}
                  floated="right"
                ></MyPaging>
              </Table.Cell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
    </>
  );
});
