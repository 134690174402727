export interface CustomVehicle {
  id?: string ;
  lowYear?: number;
  highYear?: number;
  makeName?: string;
  modelName: string;
  engineName?: string;
  isActive: boolean;
  yearOption: number;
  error?: string;
  notes?: string;
}
export class CustomVehicle implements CustomVehicle {
  constructor(init?: CustomVehicleFormValues) {
    if (init) {
      this.id = init.id;
      this.lowYear = init.lowYear ? Number(init.lowYear) : undefined;
      this.highYear = init.highYear ? Number(init.highYear) : undefined;
      this.makeName = init.makeName;
      this.modelName = init.modelName;
      this.engineName = init.engineName;
      this.isActive = init.isActive.toString() === "true";
      this.yearOption = Number(init.yearOption);
      this.notes = init.notes ?? "";
      this.error = init.error;
    }
  }
}

export class CustomVehicleFormValues {
  id?: string = undefined;
  lowYear?: number = undefined;
  highYear?: number = undefined;
  makeName?: string = undefined;
  modelName: string = "";
  engineName?: string = undefined;
  isActive: boolean = false;
  yearOption: number = 1;
  notes?: string = undefined;
  error?: string = undefined;

  constructor(customVehicle?: CustomVehicleFormValues) {
    if (customVehicle) {
      this.id = customVehicle.id;
      this.lowYear = customVehicle.lowYear;
      this.highYear = customVehicle.highYear;
      this.makeName = customVehicle.makeName;
      this.modelName = customVehicle.modelName;
      this.engineName = customVehicle.engineName;
      this.isActive = customVehicle.isActive;
      this.yearOption = customVehicle.yearOption;
      this.notes = customVehicle.notes;
      this.error = customVehicle.error;
    }
  }
}

export class CustomVehicleNoteFormValues {
  id?: string = undefined;
  notes?: string = undefined;
  constructor(id: string, notes?: string) {
    this.id = id;
    this.notes = notes;
  }
}