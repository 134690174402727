import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { GeoLocation} from "../../models/systemAdmin";

export default class GeoLocationStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  geoLocation: GeoLocation | undefined = undefined;

  loadGeoLocation = async (ip: string) => {
    try {
      this.clearGeoLocation();
      const result = await agent.SystemAdminTools.getGeoLocation(ip);
      runInAction(() => {
        this.setGeoLocation(result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  setGeoLocation = (value: GeoLocation | undefined) => {
    if (value) this.geoLocation = value;
  };

  clearGeoLocation = () => {
    this.geoLocation = undefined;
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
}
