import React, { useEffect } from "react";
import { Button, Icon, DropdownItemProps } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import {
  SalesTaxOptionType,
  TicketItemType,
  TicketWorkflowSteps,
} from "../../../app/models/enums";
import { MiscellaneousCharge } from "../../../app/models/miscellaneousCharges";
import { VehicleFormValues } from "../../../app/models/vehicle";
import { CustomerFormValues } from "../../../app/models/customer";

interface Props {
  id: string;
  selectedVehicle: VehicleFormValues;
  selectedCustomer?: CustomerFormValues;
  completed: string;
  alwaysMiscellaneousChargeItems: MiscellaneousCharge[];
}

export default observer(function SummaryButton({
  id,
  selectedVehicle,
  selectedCustomer,
  completed,
  alwaysMiscellaneousChargeItems,
}: Props) {
  const { t } = useTranslation(["common", "translation", "recentTickets"]);

  const navigate = useNavigate();

  const { ticketSessionStore } = useStore();

  const {
    updateTicketSession,
    setJobLaborsToTicketItems,
    calculateTotals,
    calculateMiscellaneousCharges,
    selectedTicketSession,
    hasParts
  } = ticketSessionStore;

  return (
    <Button
      animated
      color="green"
      floated="right"
      type="button"
      onClick={() => {

        setJobLaborsToTicketItems(
          alwaysMiscellaneousChargeItems,
          hasParts(),
          selectedTicketSession?.salesTaxOptionType ?? SalesTaxOptionType.Parts
        );
        calculateMiscellaneousCharges(alwaysMiscellaneousChargeItems);
        calculateTotals();
        updateTicketSession(
          TicketWorkflowSteps.Time,
          id,
          selectedVehicle,
          selectedCustomer
        );
        navigate(`/ticket/summary/${id}/${completed}`);
      }}
    >
      <Button.Content visible>{t("summary", { ns: "tickets" })}</Button.Content>
      <Button.Content hidden>
        <Icon name="caret right" />
      </Button.Content>
    </Button>
  );
});
