import React, { useEffect, useState } from "react";
import { Button, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { TicketPaymentItem, TicketSession } from "../../../app/models/ticket";
import { formatCurrency } from "../../../app/common/util/functions";
import ValidationErrors from "../../errors/ValidationErrors";
import { toast } from "react-toastify";
import { resetTicket } from "../../../app/common/util/ticketfunctions";
import { VehicleFormValues } from "../../../app/models/vehicle";
import { CustomerFormValues } from "../../../app/models/customer";

interface Props {
  ticketPaymentItem: TicketPaymentItem;
  isPaymentProcessor: boolean;
}

export default function ConfirmPaymentReversal({
  ticketPaymentItem,
  isPaymentProcessor,
}: Readonly<Props>) {
  const { t } = useTranslation(["common"]);
  const { modalStore, ticketSessionStore, vehicleStore, customerStore, ticketStore } =
    useStore();
  const {
    setBatchTicketItems,
    populateLaborMap,
    setBatchTicketPaymentItems,
    populateJobLabors,
    selectedTicketSession,
    loadingInitial,
    loadTicket,
    setSelectedPONumber,
    setSelectedTicketSession,
    processRefund,
  } = ticketSessionStore;
  const { setSelectedVehicle } = vehicleStore;
  const { setSelectedCustomer } = customerStore;
  const {
    selectedSettingsTicketFormatting,
    loadSettingsTicketFormatting,
  } = ticketStore;

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [processing, setProcessing] = useState<boolean>(false);
  const [refundAmount, setRefundAmount] = useState<number>(
    Number(ticketPaymentItem.amount ?? 0) -
      Number(ticketPaymentItem.refundedAmount)
  );


  useEffect(() => {
    loadSettingsTicketFormatting();
  }, [loadSettingsTicketFormatting]);

  return (
    <>
      <PageHeader
        header={t("confirmPaymentReversal", { ns: "tickets" })}
        type={"h3"}
        divider={true}
        addTitle={false}
      />

      {errorMessage && (
        <ValidationErrors errors={[errorMessage]}></ValidationErrors>
      )}
      <p>
        <label className="myLabel">
          {t("reversePayment", { ns: "tickets" })}{" "}
          <strong>{formatCurrency(ticketPaymentItem.amount ?? 0.0)}</strong>{" "}
          {t("toTheTicket", { ns: "tickets" })}
        </label>
      </p>
      <Divider />
      <Button
        color="green"
        icon="check"
        className="save_button"
        content={t("process", { ns: "tickets" })}
        type="button"
        disabled={processing}
        loading={processing}
        onClick={() => {
          if (
            (ticketPaymentItem?.amount ?? 0) >= (refundAmount + (ticketPaymentItem.refundedAmount ?? 0))
          ) {
            setProcessing(true);
            processRefund(
              ticketPaymentItem,
              ticketPaymentItem.amount ?? 0.0,
              isPaymentProcessor
            )
              .then(() => {
                resetTicket();
                if (selectedTicketSession?.id) {
                  loadTicket(selectedTicketSession.id).then((result) => {
                    if (result) {
                      setSelectedVehicle(new VehicleFormValues(result.vehicle));
                      setSelectedCustomer(
                        new CustomerFormValues(result.customer)
                      );
                      setSelectedPONumber(result.poNumber);

                      setSelectedTicketSession(new TicketSession(result));

                      if (result.jobLabors && result.jobLabors.length > 0) {
                        populateJobLabors(result.jobLabors);
                      }

                      if (result.ticketItems && result.ticketItems.length > 0) {
                        setBatchTicketItems(result.ticketItems,selectedSettingsTicketFormatting.showRemoveAndReplace );
                      }
                      //need this due to the history is being displayed off of it
                      if (
                        result.ticketPayments &&
                        result.ticketItems.length > 0
                      ) {
                        setBatchTicketPaymentItems(result.ticketPayments);
                      }
                      if (result.labors && result.labors.length > 0) {
                        populateLaborMap(result.labors);
                      }
                    }
                  });
                  toast.success(t("reversalSuccessful", { ns: "tickets" }).toString());
                  modalStore.closeModal();
                }
                else {
                  setErrorMessage(t("ticketError", { ns: "errors" }));
                }
              })
              .catch((error) => setErrorMessage(error))
              .finally(() => setProcessing(false));
          } else {
            setErrorMessage(t("refundAmountsDifference", { ns: "errors" }));
            setProcessing(false);
          }
        }}
      />
      <Button
        className="save_button"
        type="reset"
        content={t("cancel", { ns: "common" })}
        onClick={() => modalStore.closeModal()}
      />
    </>
  );
}
