import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { Button, Table } from "semantic-ui-react";
import { format } from "date-fns";
import {
  formatCurrency,
  getNegative,
  convertPaymentStatusToString,
} from "../../../app/common/util/functions";
import ConfirmPaymentReversal from "../paymentProcessing/ConfirmPaymentReversal";
import { PaymentStatus, TransactionType } from "../../../app/models/enums";
import {
  loadTicket,
  resetTicket,
} from "../../../app/common/util/ticketfunctions";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

interface Props {
  ticketId: string;
}

export default observer(function TicketPaymentHistory({ ticketId }: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  const {
    ticketSessionStore,
    pricingStore,
    modalStore,
    ticketPaymentProcessingStore,
  } = useStore();

  const { selectedTicketSession, successfulTicketPayments } =
    ticketSessionStore;

  const { checkStatus } = ticketPaymentProcessingStore;

  const { getPayType } = pricingStore;

  const [loading, setLoading] = useState<boolean>(false);
  const [checkStatusDelay, setCheckStatusDelay] = useState<boolean>(false);
  const [showStatus, setShowStatus] = useState<boolean>(
    hasPaymentProcessorTransaction()
  );

  function getPaymentName(paymentId: string) {
    let payMethod = getPayType(paymentId);
    return payMethod ? payMethod.name : "";
  }

  function isPaymentProcessor(paymentId: string): boolean {
    let payMethod = getPayType(paymentId);
    return payMethod && payMethod.isPaymentProcessor ? true : false;
  }

  function hasPaymentProcessorTransaction() {
    let retVal: boolean = false;
    successfulTicketPayments.map((x) => {
      if (!retVal) {
        let payMethod = getPayType(x.paymentTypeId ?? "");
        retVal = payMethod && payMethod.isPaymentProcessor ? true : false;
      }
    });
    return retVal;
  }

  function setTheCheckStatusDelay() {
    setCheckStatusDelay(false);
  }

  return (
    <>
      {successfulTicketPayments && successfulTicketPayments.length > 0 && (
        <>
          <PageHeader
            header={t("ticketPayHist", { ns: "tickets" })}
            type={"h2"}
            divider={false}
            addTitle={false}
          />
          <Table basic="very" striped unstackable className="dnxTable">
            <Table.Header>
              <Table.HeaderCell>
                {t("estimate.date", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("estimate.type", { ns: "tickets" })}
              </Table.HeaderCell>
              {showStatus && (
                <Table.HeaderCell>
                  {t("status", { ns: "common" })}
                </Table.HeaderCell>
              )}
              <Table.HeaderCell textAlign="right">
                {t("estimate.amount", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("estimate.lastFour", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right"></Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {successfulTicketPayments.map((payment, index) => (
                <Table.Row key={`ticketPayment.${payment.id}`}>
                  <Table.Cell
                    data-label={`${t("estimate.date", { ns: "tickets" })}:`}
                  >
                    {payment.createdDate && format(payment.createdDate, "Pp")}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("estimate.type", { ns: "tickets" })}:`}
                  >
                    {getPaymentName(payment.paymentTypeId ?? "")}
                  </Table.Cell>
                  {showStatus &&
                    isPaymentProcessor(payment.paymentTypeId ?? "") && (
                      <Table.Cell>
                        {convertPaymentStatusToString(
                          payment.status ?? PaymentStatus.Created
                        )}
                      </Table.Cell>
                    )}

                  <Table.Cell
                    data-label={`${t("estimate.amount", { ns: "tickets" })}:`}
                    textAlign="right"
                    style={{
                      color: getNegative(payment.amount ?? 0.0),
                    }}
                  >
                    {formatCurrency(payment.amount ?? 0)}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    data-label={`${t("estimate.lastFour", { ns: "tickets" })}:`}
                  >
                    {!payment.refunded &&
                      payment.transactionType !== TransactionType.Refund &&
                      payment.status == PaymentStatus.Succeeded && (
                        <Button
                          color="green"
                          icon="undo"
                          content={t("estimate.reverse", { ns: "tickets" })}
                          onClick={() => {
                            modalStore.openModal(
                              <ConfirmPaymentReversal
                                ticketPaymentItem={payment}
                                isPaymentProcessor={isPaymentProcessor(
                                  payment.paymentTypeId ?? ""
                                )}
                              />
                            );
                          }}
                        />
                      )}
                    {payment.status == PaymentStatus.Succeeded &&
                      isPaymentProcessor(payment.paymentTypeId ?? "") && (
                        <Button
                          color="blue"
                          icon="newspaper"
                          type="button"
                          content={t("payments.receipt", { ns: "tickets" })}
                          target="_blank"
                          to={`/ticket/pdf/receipt/${payment.id}`}
                          as={Link}
                        />
                      )}

                    {!payment.refunded &&
                      payment.transactionType !== TransactionType.Refund &&
                      payment.status == PaymentStatus.Pending && (
                        <Button
                          color="green"
                          icon="undo"
                          content={t("payments.checkStatus", { ns: "tickets" })}
                          loading={loading || checkStatusDelay}
                          disabled={loading || checkStatusDelay}
                          onClick={() => {
                            setCheckStatusDelay(true);
                            setLoading(true);
                            checkStatus(payment)
                              .then(() => {
                                resetTicket();
                                loadTicket(ticketId);
                                toast.success(
                                  t("payments.checkStatusSuccess", {
                                    ns: "tickets",
                                  }).toString()
                                );
                              })
                              .catch(() => {
                                toast.error(
                                  t("payments.checkStatusFailed", {
                                    ns: "tickets",
                                  }).toString()
                                );
                              })
                              .finally(() => {
                                setTimeout(setTheCheckStatusDelay, 6000);
                                setLoading(false);
                              });
                          }}
                        />
                      )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Body>
              <Table.Row key={`ticketPayment.footer`}>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                {showStatus && <Table.Cell></Table.Cell>}
                <Table.Cell
                  textAlign="right"
                  data-label={`${t("estimate.amount", { ns: "tickets" })}:`}
                >
                  {formatCurrency(
                    selectedTicketSession?.paymentReceived ?? 0.0
                  )}
                </Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell textAlign="right"></Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
    </>
  );
});
