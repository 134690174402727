import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Grid, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { ReportType } from "../../../app/models/enums";
import {
  formatCurrency,
  getNegative,
} from "../../../app/common/util/functions";
import PageHeader from "../../../app/layout/PageHeader";
import { format } from "date-fns";

interface Props {
  startDate: Date;
  endDate: Date;
  reportType: ReportType;
}

export default observer(function Tax({
  startDate,
  endDate,
  reportType,
}: Props) {
  const { t } = useTranslation(["common", "reports"]);

  const { taxReportStore } = useStore();
  const { getTaxReport, calculateTax, groupedTaxReportByDate } =
    taxReportStore;

  return (
    <>
      <Container className="page">
        <Grid stackable columns={2} className="headerPrint">
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={`${t("report", { ns: "reports" })}: ${t(
                  "estimate.tax",
                  {
                    ns: "tickets",
                  }
                )}`}
                type="h2"
                divider={false}
                getAlerts={true}
                addTitle={true}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`${format(startDate, "PP")} - ${format(
                  endDate,
                  "PP"
                )}`}
                as="h2"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {getTaxReport && getTaxReport.length > 0 && (
          <Table striped unstackable className="dnxTable">
            <Table.Header>
              <Table.Row colSpan={7}>
                {reportType === ReportType.Detailed ? (
                  <Table.HeaderCell textAlign="left">
                    {t("creditCard.expirationMonth", { ns: "subscription" })}
                  </Table.HeaderCell>
                ) : (
                  <></>
                )}
                <Table.HeaderCell textAlign="right">
                  {t("estimate.labor", { ns: "tickets" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("estimate.parts", { ns: "tickets" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("estimate.misc", { ns: "tickets" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("gross_sales", { ns: "reports" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("non_taxable", { ns: "reports" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("taxable", { ns: "reports" })}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  {t("tax_collected", { ns: "reports" })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {reportType === ReportType.Detailed && (
              <Table.Body>
                {groupedTaxReportByDate.map(([group, x]) => (
                  <Table.Row key={`taxreport.${group}`}>
                    {reportType === ReportType.Detailed ? (
                      <Table.Cell textAlign="left">{group}</Table.Cell>
                    ) : (
                      <></>
                    )}
                    <Table.Cell
                      data-label={`${t("estimate.labor", { ns: "tickets" })}:`}
                      textAlign="right"
                      style={{
                        color: getNegative(calculateTax(x).totalLabor),
                      }}
                    >
                      {formatCurrency(calculateTax(x).totalLabor)}
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("estimate.parts", { ns: "tickets" })}:`}
                      textAlign="right"
                      style={{
                        color: getNegative(calculateTax(x).totalParts),
                      }}
                    >
                      {formatCurrency(calculateTax(x).totalParts)}
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("estimate.misc", { ns: "tickets" })}:`}
                      textAlign="right"
                      style={{
                        color: getNegative(calculateTax(x).totalMisc),
                      }}
                    >
                      {formatCurrency(calculateTax(x).totalMisc)}
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("gross_sales", { ns: "reports" })}:`}
                      textAlign="right"
                      style={{
                        color: getNegative(calculateTax(x).grossSales),
                      }}
                    >
                      {formatCurrency(calculateTax(x).grossSales)}
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("non_taxable", { ns: "reports" })}:`}
                      textAlign="right"
                      style={{
                        color: getNegative(calculateTax(x).nonTaxable),
                      }}
                    >
                      {formatCurrency(calculateTax(x).nonTaxable)}
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("taxable", { ns: "reports" })}:`}
                      textAlign="right"
                      style={{
                        color: getNegative(calculateTax(x).taxable),
                      }}
                    >
                      {formatCurrency(calculateTax(x).taxable)}
                    </Table.Cell>
                    <Table.Cell
                      data-label={`${t("tax_collected", { ns: "reports" })}:`}
                      textAlign="right"
                      style={{
                        color: getNegative(calculateTax(x).totalTax),
                      }}
                    >
                      {formatCurrency(calculateTax(x).totalTax)}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            )}
            <Table.Body>
              <Table.Row className="greenRow terms_bold">
                {reportType === ReportType.Detailed && (
                  <Table.Cell textAlign="left">
                    {t("grandTotals", { ns: "reports" })}:
                  </Table.Cell>
                )}
                <Table.Cell
                  data-label={`${t("estimate.labor", { ns: "tickets" })}:`}
                  textAlign="right"
                  style={{
                    color: getNegative(calculateTax(getTaxReport).totalLabor),
                  }}
                >
                  {formatCurrency(calculateTax(getTaxReport).totalLabor)}
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("estimate.parts", { ns: "tickets" })}:`}
                  textAlign="right"
                  style={{
                    color: getNegative(calculateTax(getTaxReport).totalParts),
                  }}
                >
                  {formatCurrency(calculateTax(getTaxReport).totalParts)}
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("estimate.misc", { ns: "tickets" })}:`}
                  textAlign="right"
                  style={{
                    color: getNegative(calculateTax(getTaxReport).totalMisc),
                  }}
                >
                  {formatCurrency(calculateTax(getTaxReport).totalMisc)}
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("gross_sales", { ns: "reports" })}:`}
                  textAlign="right"
                  style={{
                    color: getNegative(calculateTax(getTaxReport).grossSales),
                  }}
                >
                  {formatCurrency(calculateTax(getTaxReport).grossSales)}
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("non_taxable", { ns: "reports" })}:`}
                  textAlign="right"
                  style={{
                    color: getNegative(calculateTax(getTaxReport).nonTaxable),
                  }}
                >
                  {formatCurrency(calculateTax(getTaxReport).nonTaxable)}
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("taxable", { ns: "reports" })}:`}
                  textAlign="right"
                  style={{
                    color: getNegative(calculateTax(getTaxReport).taxable),
                  }}
                >
                  {formatCurrency(calculateTax(getTaxReport).taxable)}
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("tax_collected", { ns: "reports" })}:`}
                  textAlign="right"
                  style={{
                    color: getNegative(calculateTax(getTaxReport).totalTax),
                  }}
                >
                  {formatCurrency(calculateTax(getTaxReport).totalTax)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </Container>
    </>
  );
});
