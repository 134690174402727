import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import PageHeader from "../../app/layout/PageHeader";
import { convertDowellSystemApplicationToString, convertTermToString, formatCurrency } from "../../app/common/util/functions";
import { ClientSubscriptionsFormValues } from "../../app/models/clientSubscription";
import { format } from "date-fns";
import { useStore } from "../../app/stores/store";

interface Props {
  clientSubscription: ClientSubscriptionsFormValues;
  subscriptionAmount: number;
  isNew: boolean;
}

export default observer(function SubscriptionDisplay({
  clientSubscription,
  subscriptionAmount,
  isNew
}: Props) {
  const { userStore } = useStore();
  const { user } = userStore;
  const { t } = useTranslation(["common", "subscription"]);

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <PageHeader
              header={isNew ? t("subscription.newSubscription", { ns: "subscription" }) : t("subscription.current_sub", { ns: "subscription" })}
              type={"h2"}
              divider={true}
              getAlerts={true}
              addTitle={false}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="RemoveTopBottomPadding">
          <Grid.Column textAlign="right">
            <label className="myLabel">{`${t("upcoming.renewal", {
              ns: "subscription",
            })}:`}</label>
          </Grid.Column>
          <Grid.Column>{clientSubscription.endDate && format(clientSubscription.endDate, "PP")
          }</Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="RemoveTopBottomPadding">
          <Grid.Column textAlign="right">
            <label className="myLabel">{`${t("service_type.label", {
              ns: "common",
            })}:`}</label>
          </Grid.Column>
          <Grid.Column>
            {clientSubscription.dowellSystemApplication ? convertDowellSystemApplicationToString(Number(clientSubscription.dowellSystemApplication)) : convertDowellSystemApplicationToString(user?.application ?? 0)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="RemoveTopBottomPadding">
          <Grid.Column textAlign="right">
            <label className="myLabel">{`${t("users", {
              ns: "common",
            })}:`}</label>
          </Grid.Column>
          <Grid.Column>{clientSubscription.userCount ?? 1}</Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="RemoveTopBottomPadding">
          <Grid.Column textAlign="right">
            <label className="myLabel">{`${t("subscription.renewalPeriod", {
              ns: "subscription",
            })}:`}</label>
          </Grid.Column>
          <Grid.Column>{convertTermToString(Number(clientSubscription.term??0))}</Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} className="RemoveTopBottomPadding">
          <Grid.Column textAlign="right">
            <label className="myLabel">
              {t("amount", { ns: "common" }) + ": "}
            </label>
          </Grid.Column>
          <Grid.Column>
            <label className="myLabel"> {formatCurrency(subscriptionAmount)}</label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
});
