import { observer } from "mobx-react-lite";
import { Button, Table } from "semantic-ui-react";
import { useState } from "react";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomerTicketSearch from "./CustomerTicketSearch";
import { v4 as uuid } from "uuid";
import { VehicleSearch } from "../../app/models/search";
import VinDisplay from "../../app/common/form/VinDisplay";
import { resetTicket } from "../../app/common/util/ticketfunctions";

interface Props {
  vehicle: VehicleSearch;
}

export default observer(function CustomerVehicleRow({ vehicle }: Props) {
  const { t } = useTranslation(["common", "search"]);
  const navigate = useNavigate();

  const { ticketSessionStore } = useStore();

  const {
    createCustomerTicket,
  } = ticketSessionStore;

  const [expand, setExpand] = useState<boolean>(false);

  const handleExpand = () => {
    setExpand(!expand);
  };

  return (
    <>
      <Table.Row>
        <Table.Cell data-label={`${t("vehicle", { ns: "common" })}:`}>
          {`${vehicle.year ?? ""} ${vehicle.make ?? ""} ${vehicle.model}`}
        </Table.Cell>
        <Table.Cell data-label={`${t("tag", { ns: "common" })}:`}>
          {vehicle.tag}
        </Table.Cell>
        <Table.Cell data-label={`${t("vin", { ns: "recentTickets" })}:`}>
        <VinDisplay vin={vehicle.vin ?? ""} />
        </Table.Cell>
        <Table.Cell data-label={`${t("mileage", { ns: "common" })}:`}>
          {vehicle.mileageIn}
        </Table.Cell>
        <Table.Cell width={8} className="textAlignRight">
          <Button
            onClick={handleExpand}
            content={t("see_tickets", { ns: "common" })}
            icon="eye"
            className="tealishButtons"
            size="small"
          />
          <Button
            content={t("new_veh_tix", { ns: "recentTickets" })}
            icon="plus"
            color="green"
            size="small"
            onClick={() => {
              if (vehicle.customerId && vehicle.id) {
                resetTicket();
                let ticketId = uuid();
                createCustomerTicket(
                  vehicle.customerId,
                  ticketId,
                  vehicle.id
                ).then(() => {
                  navigate(`/ticket/jobCategory/${ticketId}/false`);
                });
              }
            }}
          />
        </Table.Cell>
      </Table.Row>
      {expand && <CustomerTicketSearch id={vehicle.id} />}
    </>
  );
});
