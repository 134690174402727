import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  Page,
  Document,
  PDFViewer,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import compose from "../../../app/layout/compose";
import ClientPdfView from "./TicketViewComponents/ClientPdfView";
import { useStore } from "../../../app/stores/store";
import { format } from "date-fns";
import { useParams } from "react-router-dom";
import { formatCurrency } from "../../../app/common/util/functions";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import TicketReceipt from "./TicketViewComponents/TicketReceipt";

export default observer(function TicketReceiptPdfView() {
  const { t } = useTranslation(["common", "tickets"]);
  const { id } = useParams<{ id: string }>();

  const { clientStore, ticketPaymentProcessingStore } = useStore();
  const { loadClientDetails, loadingInitial: clientLoadingInitial } =
    clientStore;
  const {
    selectedTicketCardTransaction,
    loadTicketCardTransaction,
    loadingInitial,
  } = ticketPaymentProcessingStore;

  useEffect(() => {
    document.title = t(`payments.transactionReceipt`, { ns: "tickets" })
  }, []);

  useEffect(() => {
    if (id) {
      loadTicketCardTransaction(id).then(() => loadClientDetails());
    }
  }, [loadTicketCardTransaction]);

  if (loadingInitial && clientLoadingInitial)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <TicketReceipt
      selectedTicketCardTransaction={selectedTicketCardTransaction}
    />
  );
});
