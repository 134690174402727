import { observer } from "mobx-react-lite";
import { Message, Table } from "semantic-ui-react";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import { VehicleSearch } from "../../app/models/search";
import CustomerVehicleRow from "./CustomerVehicleRow";

interface Props {
  id: string;
}

export default observer(function CustomerVehicleSearch({ id }: Props) {
  const { t } = useTranslation(["common", "search"]);

  const { searchStore } = useStore();

  const { filterVehiclesByCustomerId, loadCustomerVehicle } = searchStore;


  const [vehicleSearch, setVehicleSearch] = useState<VehicleSearch[]>([]);

  useEffect(() => {
    loadCustomerVehicle(id).then(() => {
      setVehicleSearch(filterVehiclesByCustomerId(id));
    });
  }, [loadCustomerVehicle, setVehicleSearch, filterVehiclesByCustomerId, id]);

  return (
    <Table.Row>
      <Table.Cell className="tableInTable" colSpan={8}>
        <Table unstackable striped className="no_padding dnxTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={6}>
                {t("vehicle", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell>{t("tag", { ns: "common" })}</Table.HeaderCell>
              <Table.HeaderCell>
                {t("vin", { ns: "recentTickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("mileage", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {vehicleSearch.length > 0 ? (
              vehicleSearch.map((vehicle) => (
                <CustomerVehicleRow vehicle={vehicle} key={`customerVehicleRow.${vehicle.id}`}/>
              ))
            ) : (
              <Table.Row key={"noItems"}>
                <Table.Cell width="5" colSpan={5} className="noTextAlignMobile">
                  <Message
                    color="yellow"
                    className="textAlignCenter fontStyleItalic"
                  >
                    {t("grid_noItems", { ns: "common" })}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Table.Cell>
    </Table.Row>
  );
});
