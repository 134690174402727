import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Divider,
  Grid,
  Message,
  Select,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../app/stores/store";
import PageHeader from "../../app/layout/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import { TicketItem, TicketSession } from "../../app/models/ticket";
import { VehicleFormValues } from "../../app/models/vehicle";
import { CustomerFormValues } from "../../app/models/customer";
import LoadingComponent from "../../app/layout/LoadingComponent";
import TechTrackingRow from "./TechTrackingRow";
import { resetTicket } from "../../app/common/util/ticketfunctions";
import { TicketItemType } from "../../app/models/enums";
//import {TechTrackingRow} from "./TechTrackingRow"

export default observer(function TechTracking() {
  const { t } = useTranslation(["common", "miscSettings"]);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const {
    technicianStore,
    ticketSessionStore,
    ticketStore,
    vehicleStore,
    customerStore,
  } = useStore();

  const {
    loadTechnicianDropdownList,
    technicianList,
    selectedSettingsTechnician,
    loadSettingsTechnician,
  } = technicianStore;

  const {
    setTechTrackingOnTicketItem,
    setBatchTechTrackingOnTicketItem,
    updateTechTracking,
    loadTicket,
    setSelectedTicketSession,
    setSelectedPONumber,
    setBatchTicketItems,
    calculateTotals,
    setBatchTicketPaymentItems,
    resetTicketPaymentItems,
    populateJobLabors,
    populateLaborMap,
    ticketItems,
    //laborTicketItems,
    //partsTicketItems,
  } = ticketSessionStore;

  const { loadSettingsTicketFormatting, selectedSettingsTicketFormatting } =
    ticketStore;

  const { setSelectedVehicle } = vehicleStore;
  const { setSelectedCustomer } = customerStore;

  useEffect(() => {
    loadTechnicianDropdownList();
  }, [loadTechnicianDropdownList]);

  useEffect(() => {
    loadSettingsTechnician();
  }, [loadSettingsTechnician]);

  const [ticketLoaded, setTicketLoaded] = useState<boolean>(false);

  useEffect(() => {
    loadSettingsTicketFormatting();
  }, [loadSettingsTicketFormatting]);

  useEffect(() => {
    resetTicket();
    if (id) {
      setTicketLoaded(true);
      loadTicket(id)
        .then((result) => {
          if (result) {
            setSelectedVehicle(new VehicleFormValues(result.vehicle));
            setSelectedCustomer(new CustomerFormValues(result.customer));
            setSelectedPONumber(result.poNumber);

            let ticketSession = new TicketSession(result);
            setSelectedTicketSession(ticketSession);

            if (result.jobLabors && result.jobLabors.length > 0) {
              populateJobLabors(result.jobLabors);
            }

            if (result.ticketItems && result.ticketItems.length > 0) {
              setBatchTicketItems(
                result.ticketItems,
                selectedSettingsTicketFormatting.showRemoveAndReplace
              );
            }

            if (result.ticketPayments && result.ticketItems.length > 0) {
              setBatchTicketPaymentItems(result.ticketPayments);
            } else {
              resetTicketPaymentItems();
            }

            calculateTotals();

            if (result.labors && result.labors.length > 0) {
              populateLaborMap(result.labors);
            }
          }
        })
        .finally(() => setTicketLoaded(true));
    }
  }, [loadTicket, calculateTotals, id, populateJobLabors, populateLaborMap, resetTicketPaymentItems, setBatchTicketItems, setBatchTicketPaymentItems, setSelectedCustomer, setSelectedPONumber, setSelectedTicketSession, setSelectedVehicle]);

  const [labTicketItems, setLabTicketItems] = useState<TicketItem[]>(
    Array.from(ticketItems.values())
      .filter((x) => {
        return x.type === TicketItemType.Labor;
      })
      .sort((a, b) => a.order! - b.order!)
  );
  const [partTicketItems, setPartTicketItems] = useState<TicketItem[]>(
    Array.from(ticketItems.values())
      .filter((x) => {
        return x.type === TicketItemType.Parts;
      })
      .sort((a, b) => a.order! - b.order!)
  );

  const [laborTechAssignment, setLaborTechAssignment] = useState<string>();

  useEffect(() => {
    handleTechAddAll(laborTechAssignment ?? "", true, false);
    setLabTicketItems(
      Array.from(ticketItems.values())
        .filter((x) => {
          return x.type === TicketItemType.Labor;
        })
        .sort((a, b) => a.order! - b.order!)
    );
  }, [laborTechAssignment]);


  const [partTechAssignment, setPartTechAssignment] = useState<string>();
  useEffect(() => {
    handleTechAddAll(partTechAssignment ?? "", false, true);
    setPartTicketItems(
      Array.from(ticketItems.values())
        .filter((x) => {
          return x.type === TicketItemType.Parts;
        })
        .sort((a, b) => a.order! - b.order!)
    );
  }, [partTechAssignment]);

  function handleFormSubmit() {
    updateTechTracking()
      .then(() => {})
      .catch((error) => {
        console.log(error);
      });
  }

  function handleTechAdd(ticketItemId: string, value: string) {
    setTechTrackingOnTicketItem(ticketItemId, value);
  }

  function handleTechAddAll(
    value: string,
    addLabor: boolean,
    addParts: boolean
  ) {
    setBatchTechTrackingOnTicketItem(value, addLabor, addParts);
  }

  if (!ticketLoaded)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <>
      <PageHeader
        header={t("techTrackingAssignment", { ns: "tickets" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <Button
              color="green"
              className="save_button"
              content={t("continue", { ns: "tickets" })}
              type="submit"
              floated="right"
              onClick={() => {
                handleFormSubmit();
                navigate(`/ticket/print/${id}`);
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2} verticalAlign="bottom">
          <Grid.Column>
            <PageHeader
              header={t("laborAssignment", { ns: "tickets" })}
              type={"h2"}
              divider={false}
              addTitle={false}
            />
          </Grid.Column>
          <Grid.Column textAlign="right" verticalAlign="bottom">
            <Select
              float="right"
              placeholder={`${t("assignAllLabor", { ns: "tickets" })}:`}
              name={"techs"}
              options={technicianList}
              label=""
              clearable
              selectOnNavigation
              onChange={(e, d) => {
                setLabTicketItems([]);
                setLaborTechAssignment(d.value?.toString());
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Table stackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={10}>
                    {t("description", { ns: "common" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="left">
                    {t("hours", { ns: "tickets" })}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="right">
                    {t("techAssignment", { ns: "tickets" })}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {labTicketItems && labTicketItems.length > 0 ? (
                  labTicketItems.map((ticketItem, index) => (
                    <TechTrackingRow
                      key={`laborRow.${ticketItem.id}`}
                      ticketItem={ticketItem}
                      technicianList={technicianList}
                      handleTechChange={handleTechAdd}
                    />
                  ))
                ) : (
                  <Table.Row key={"noItems1"}>
                    <Table.Cell width="16" colSpan={16}>
                      <Message
                        color="yellow"
                        className="textAlignCenter fontStyleItalic"
                      >
                        {t("grid_noItems", { ns: "common" })}
                      </Message>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
        {selectedSettingsTechnician.technicianPartsTrackingEnabled && (
          <>
            <Divider />
            <Grid.Row columns={2}>
              <Grid.Column verticalAlign="bottom">
                <PageHeader
                  header={t("partsAssignment", { ns: "tickets" })}
                  type={"h2"}
                  divider={false}
                  addTitle={false}
                />
              </Grid.Column>
              <Grid.Column textAlign="right" verticalAlign="bottom">
                <Select
                  float="right"
                  placeholder={`${t("assignAllParts", { ns: "tickets" })}:`}
                  name={"techs"}
                  options={technicianList}
                  clearable
                  label=""
                  onChange={(e, d) => {
                    setPartTicketItems([]);
                    setPartTechAssignment(d.value?.toString());
                    //set all the part ticketItems
                    //setPartTicketItems([]);
                    //handleTechAddAll(d.value?.toString() ?? "", false, true);
                    //setPartTicketItems(Array.from(ticketItems.values()).filter((x) => {
                    //  return x.type === TicketItemType.Parts;
                    //}).sort((a, b) => a.order! - b.order!));
                  }}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Table stackable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={10} verticalAlign={"middle"}>
                        {t("description", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell verticalAlign={"middle"}>
                        {t("pops.qty", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("techAssignment", { ns: "tickets" })}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {partTicketItems && partTicketItems.length > 0 ? (
                        partTicketItems.map((ticketItem) => (
                          <TechTrackingRow
                            key={`laborRow.${ticketItem.id}`}
                            ticketItem={ticketItem}
                            technicianList={technicianList}
                            handleTechChange={handleTechAdd}
                          />
                        ))
                    ) : (
                      <Table.Row key={"noItems2"}>
                        <Table.Cell width="16" colSpan={16}>
                          <Message
                            color="yellow"
                            className="textAlignCenter fontStyleItalic"
                          >
                            {t("grid_noItems", { ns: "common" })}
                          </Message>
                        </Table.Cell>
                      </Table.Row>
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
    </>
  );
});
