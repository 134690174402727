export interface Job {
  jobCategoryId?: number;
  jobTypeId?: number;
  jobType?: string;
  jobId?: number;
  job?: string;
  jobRequiresEngine: boolean;
}

export class Job implements Job {
  constructor(init?: JobFormValues) {
    Object.assign(this, init);
  }
}

export class JobFormValues {
  jobCategoryId?: number;
  jobTypeId?: number;
  jobType?: string;
  jobId?: number;
  job?: string;
  jobRequiresEngine: boolean = false;

  constructor(jobItem?: JobFormValues) {
    if (jobItem) {
      this.jobCategoryId = jobItem.jobCategoryId;
      this.jobType = jobItem.jobType;
      this.jobTypeId = jobItem.jobTypeId;
      this.job = jobItem.job;
      this.jobId = jobItem.jobId;
      this.jobRequiresEngine = jobItem.jobRequiresEngine;
    }
  }
}