import React, { useState } from "react";
import { Button, Container } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { ClientDetails } from "../../../app/models/client";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import { PhoneNumberType } from "../../../app/models/enums";

interface Props {
  clientDetailsFormValues: ClientDetails;
}

const validationSchema = Yup.object({
  name: Yup.string().required("Company name is required."),
  addressLine1: Yup.string().required("Company address is required."),
  city: Yup.string().required("Company's city is required."),
  state: Yup.string().required("Company's state is required."),
  postalCode: Yup.string().required("Company's zip code is required."),
  commercialNumber: Yup.string().required(
    "Company's phone number is required."
  ),
});

export default function UpdateClient(props: Readonly<Props>) {
  const { t } = useTranslation(["common", "pricingSettings", "errors"]);
  const { modalStore, clientSystemAdminStore, adminNotesStore } = useStore();
  const { updateClient, loadClientInformation } = clientSystemAdminStore;
  const { loadClientAdminNotes } = adminNotesStore;

  const [client] = useState<ClientDetails>(
    props.clientDetailsFormValues
  );

  function handleFormSubmit(values: ClientDetails) {
    updateClient(values.id ?? "", values).then(() => {
      loadClientInformation(props.clientDetailsFormValues.id ?? "");
      loadClientAdminNotes(values.id);
      modalStore.closeModal();
    });
  }

  return (
    <>
      <PageHeader header="Update Company Details" type="h2" divider={true} addTitle={false}/>

      <Formik
        validationSchema={validationSchema}
        initialValues={client}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
            <Container className="page">
              <MyTextInput
                placeholder={""}
                name={"name"}
                label="Company:"
                autoCapitalizeFirstLetter={false} maxLength={500}              />
              <MyTextInput
                placeholder={""}
                name={"email"}
                label="Email:"
                autoCapitalizeFirstLetter={false} maxLength={255}              />
              <MyTextInput
                placeholder={""}
                name={"addressLine1"}
                label="Address Line 1:"
                autoCapitalizeFirstLetter={false} maxLength={500}              />
              <MyTextInput
                placeholder={""}
                name={"addressLine2"}
                label="Address Line 2:"
                autoCapitalizeFirstLetter={false} maxLength={500}              />
              <MyTextInput
                placeholder={""}
                name={"city"}
                label="City:"
                autoCapitalizeFirstLetter={false} maxLength={255}              />
              <MyTextInput
                placeholder={""}
                name={"state"}
                label="State:"
                autoCapitalizeFirstLetter={false} maxLength={50}              />
              <MyTextInput
                placeholder={""}
                name={"postalCode"}
                label="Postal Code:"
                autoCapitalizeFirstLetter={false} maxLength={50}              />
              <MyTextInput
                placeholder={""}
                name={"country"}
                label="Country:"
                autoCapitalizeFirstLetter={false} maxLength={255}              />
              <PhoneNumberInput
                placeholder={""}
                name={"commercialNumber"}
                label="Commercial Number:"
                autoFormat={true}
                phoneType={PhoneNumberType.Landline}
              />
              <PhoneNumberInput
                placeholder={""}
                name={"faxNumber"}
                label="Fax Number:"
                autoFormat={true}
                phoneType={PhoneNumberType.Fax}
              />

              <Button
                className="save_button ui button"
                icon="cancel"
                type="button"
                content={t("close", { ns: "common" })}
                onClick={modalStore.closeModal}
                size="medium"
              />

              <Button
                className="save_button ui button"
                icon="save"
                color="green"
                type="submit"
                disabled={isSubmitting || !dirty || !isValid}
                content={t("save", { ns: "common" })}
                size="medium"
              />
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
}
