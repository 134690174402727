import { JobAddOn } from "./jobLabor";

export interface Labor {
  id?: number;

  lowTime?: number;
  averageTime?: number;
  highTime?: number;
  laborHash?: string;
  hasJobAddon: boolean;
  hasJobComment: boolean;
  shopTimeId?: string;
  customTime?: number;
  customRate?: number;

  jobAddons : JobAddOn[];
  comments : LaborComment[]

}

export class Labor implements Labor {
  constructor(init?: Labor) {
    Object.assign(this, init);
  }
}

export interface LaborComment {
  jobLaborId? : number;
  commentId?: number;
  description: string;
}
