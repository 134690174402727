import { Address } from "./address";


export interface Customer {
  id?: string;
  isCommercial: boolean;
  isTaxExempt: boolean;
  title?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
  companyName?: string;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  ternaryNumber?: string;
  primaryNumber?: string;
  secondaryNumber?: string;
  createdDate?: Date;
  updatedDate?: Date;
  error?: string;
}
export class Customer implements Customer {
  constructor(init?: CustomerFormValues) {
    if (init) {
      this.id = init.id;
      this.isCommercial = init.isCommercial.toString() === "true";
      this.isTaxExempt = init.isTaxExempt.toString() === "true";
      this.title = init.title;
      this.firstName = init.firstName;
      this.lastName = init.lastName;
      this.middleName = init.middleName;
      this.suffix = init.suffix;
      this.companyName = init.companyName;
      this.email = init.email;
      this.addressLine1 = init.addressLine1;
      this.addressLine2 = init.addressLine2;
      this.city = init.city;
      this.state = init.state;
      this.postalCode = init.postalCode;
      this.primaryNumber = init.primaryNumber;
      this.secondaryNumber = init.secondaryNumber;
      this.ternaryNumber = init.ternaryNumber;
      this.createdDate = init.createdDate;
      this.updatedDate = init.updatedDate;
      this.error = init.error;
    }
  }
}

export class CustomerFormValues {
  id?: string = undefined;
  isCommercial: boolean = false;
  isTaxExempt: boolean = false;
  title?: string = undefined;
  firstName?: string = undefined;
  lastName?: string = undefined;
  middleName?: string = undefined;
  suffix?: string = undefined;
  companyName?: string = undefined;
  email?: string = undefined;
  addressLine1?: string = undefined;
  addressLine2?: string = undefined;
  city?: string = undefined;
  state?: string = undefined;
  postalCode?: string = undefined;
  ternaryNumber?: string = undefined;
  primaryNumber?: string = undefined;
  secondaryNumber?: string = undefined;
  createdDate?: Date;
  updatedDate?: Date;
  error?: string = undefined;

  constructor(customer?: Customer) {
    if (customer) {
      this.id = customer.id;
      this.isCommercial = customer.isCommercial;
      this.isTaxExempt = customer.isTaxExempt;
      this.title = customer.title;
      this.firstName = customer.firstName;
      this.lastName = customer.lastName;
      this.middleName = customer.middleName;
      this.suffix = customer.suffix;
      this.companyName = customer.companyName;
      this.email = customer.email;
      this.addressLine1 = customer.addressLine1;
      this.addressLine2 = customer.addressLine2;
      this.city = customer.city;
      this.state = customer.state;
      this.postalCode = customer.postalCode;
      this.ternaryNumber = customer.ternaryNumber;
      this.primaryNumber = customer.primaryNumber;
      this.secondaryNumber = customer.secondaryNumber;
      this.createdDate = customer.createdDate ? new Date(customer.createdDate) : undefined;
      this.updatedDate = customer.updatedDate ? new Date(customer.updatedDate) : undefined; 
      this.error = customer.error;
    }
  }
}

export interface PotentialCustomer {
  id?: string;
  isCommercial: boolean;
  isTaxExempt: boolean;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  email?: string;

  //address: Address;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  country?: string;
  type?:number;

  ternaryNumber?: string;
  primaryNumber?: string;
  secondaryNumber?: string;

  recentTicket?: Date;
  ticketCount?: number;
}

export interface CustomerNote {
  id?: string;
  customerId?: string;
  notes?: string;
  createdDate?: Date;
  lastName?: string;
  firstName?: string;
  userName?: string;
  error?: string;
}
export class CustomerNote implements CustomerNote {
  constructor(init?: CustomerNoteFormValues) {
    if (init) {
      this.id = init.id;
      this.customerId = init.customerId;
      this.notes = init.notes;
      this.createdDate = init.createdDate;
      this.lastName = init.lastName;
      this.firstName = init.firstName;
      this.userName = init.userName;
      this.error = init.error;
    }
  }
}
export class CustomerNoteFormValues {
  id?: string = undefined;
  customerId?: string = undefined;
  notes?: string = undefined;
  createdDate?: Date = new Date();
  lastName?: string = undefined;
  firstName?: string = undefined;
  userName?: string = undefined;
  error?: string = undefined;

  constructor(customerNotes?: CustomerNote) {
    if (customerNotes) {
      this.id = customerNotes.id;
      this.customerId = customerNotes.customerId;
      this.notes = customerNotes.notes;
      this.createdDate = customerNotes.createdDate;
      this.lastName = customerNotes.lastName;
      this.firstName = customerNotes.firstName;
      this.userName = customerNotes.userName;
      this.error = customerNotes.error;
    }
  }
}
export class VehicleNoteFormValues {
  id?: string = undefined;
  notes?: string = undefined;

  constructor(id: string | undefined, notes: string | undefined) {
    this.id = id;
    this.notes = notes;
  }
}


export interface TicketCustomerVehicle {
  isCommercial: boolean;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  email?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  ternaryNumber?: string;
  primaryNumber?: string;
  secondaryNumber?: string;
  mileageIn?: number;
  mileageOut?: number;
  tagState?: string;
  tag?: string;
  vehicleNumber?: string;
  vin?: string;
  error?: string;
}

export class TicketCustomerVehicle implements TicketCustomerVehicle {
  constructor(init?: TicketCustomerVehicleFormValues) {
    if (init) {
      this.isCommercial = init.isCommercial.toString() === "true";
      this.firstName = init.firstName;
      this.lastName = init.lastName;
      this.companyName = init.companyName;
      this.email = init.email;
      this.addressLine1 = init.addressLine1;
      this.addressLine2 = init.addressLine2;
      this.city = init.city;
      this.state = init.state;
      this.postalCode = init.postalCode;
      this.primaryNumber = init.primaryNumber;
      this.secondaryNumber = init.secondaryNumber;
      this.ternaryNumber = init.ternaryNumber;

      this.mileageIn = Number(init.mileageIn);
      this.mileageOut = Number(init.mileageOut);
      this.tagState = init.tagState;
      this.tag = init.tag;
      this.vehicleNumber = init.vehicleNumber;
      this.vin = init.vin;
      this.error = init.error;
    }
  }
}

export class TicketCustomerVehicleFormValues {
  isCommercial: boolean = false;
  firstName?: string = undefined;
  lastName?: string = undefined;
  companyName?: string = undefined;
  email?: string = undefined;
  addressLine1?: string = undefined;
  addressLine2?: string = undefined;
  city?: string = undefined;
  state?: string = undefined;
  postalCode?: string = undefined;
  ternaryNumber?: string = undefined;
  primaryNumber?: string = undefined;
  secondaryNumber?: string = undefined;
  mileageIn?: number = undefined;
  mileageOut?: number = undefined;
  tagState?: string = undefined;
  tag?: string = undefined;
  vehicleNumber?: string = undefined;
  vin?: string = undefined;
  error?: string = undefined;

  constructor(customer?: TicketCustomerVehicle) {
    if (customer) {
      this.isCommercial = customer.isCommercial;
      this.firstName = customer.firstName;
      this.lastName = customer.lastName;
      this.companyName = customer.companyName;
      this.email = customer.email;
      this.addressLine1 = customer.addressLine1;
      this.addressLine2 = customer.addressLine2;
      this.city = customer.city;
      this.state = customer.state;
      this.postalCode = customer.postalCode;
      this.ternaryNumber = customer.ternaryNumber;
      this.primaryNumber = customer.primaryNumber;
      this.secondaryNumber = customer.secondaryNumber;

      this.mileageIn = Number(customer.mileageIn);
      this.mileageOut = Number(customer.mileageOut);
      this.tagState = customer.tagState;
      this.tag = customer.tag;
      this.vehicleNumber = customer.vehicleNumber;
      this.vin = customer.vin;
      this.error = customer.error;
    }
  }
}

export interface CustomerChangeLog {
  id?: string;
  author?: string;
  changeType?: string;
  oldValues?: string;
  newValues?: string;
  createdDate?: Date;
}

export interface CustomerSearchValues {
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  companyName?: string;
}
