import React, { useState } from "react";
import { Button, Divider, Form, Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import { v4 as uuid } from "uuid";
import { TicketItem, TicketItemFormValues } from "../../../../app/models/ticket";
import MyCheckbox from "../../../../app/common/form/MyCheckbox";
import MyIconDecimalInput from "../../../../app/common/form/MyIconDecimalInput";
import MyIconTextInput from "../../../../app/common/form/MyIconTextInput";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { round2Decimals } from "../../../../app/common/util/functions";
import PageHeader from "../../../../app/layout/PageHeader";
import { TicketItemType } from "../../../../app/models/enums";
import { useStore } from "../../../../app/stores/store";


interface Props {
  isAdd: boolean;
  ticketItem?: TicketItem;
  addItem: (values: TicketItemFormValues, type: TicketItemType) => void;
  type: TicketItemType;
  autoCapitalizeFirstLetter: boolean;
}

export default observer(function MiscellaneousItemForm(props: Props) {
  const { t } = useTranslation(["common", "translation", "tickets"]);

  const { modalStore } = useStore();

  let newItem: TicketItem = new TicketItem();
  if (props.isAdd || !props.ticketItem) {
    newItem.id = uuid();
    newItem.quantity = 1;
    newItem.rate = 0.0;
    newItem.subTotal = newItem.rate;
    newItem.isTaxable = true;
  } else {
    newItem = props.ticketItem;
  }

  const [miscItem] = useState<TicketItemFormValues>(newItem);

  const validationSchema = Yup.object({
    description: Yup.string().required(
      t("descriptionRequired", {
        ns: "errors",
      })
    ),
  });

  function handleFormSubmit(values: TicketItemFormValues) {
    props.addItem(values, props.type);
    modalStore.closeModal();
  }

  return (
    <>
      <PageHeader
        type={"h3"}
        header={
          props.isAdd 
            ? t("pops.add_misc_itm", {
                ns: "tickets",
              }) 
            : t("pops.edit_misc_itm", {
              ns: "tickets",
            })
        }
        divider={true}
        addTitle={false}
      />
      <Formik
        validationSchema={validationSchema}
        initialValues={miscItem}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form
            className="ui form error"
            autoComplete="on"
            onSubmit={handleSubmit}
          >
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <MyTextInput
                    placeholder={t("description", { ns: "common" })}
                    label={t("description", { ns: "common" }) + ":"}
                    name="description"
                    disabled={props.type === TicketItemType.MiscellaneousCharge}
                    autoCapitalizeFirstLetter={props.autoCapitalizeFirstLetter}
                    maxLength={1000}
                    autoFocus={true}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <MyIconTextInput
                    icon="clock outline"
                    iconPosition="left"
                    placeholder="1.00"
                    label={t("pops.qty", { ns: "tickets" }) + ":"}
                    name="quantity"
                    type="number"
                    onChange={(d) => {
                      if (d && values.rate) {
                        let subTotal = Number(values.rate) * Number(d);
                        setFieldValue("subTotal", round2Decimals(subTotal));
                      }
                    }}
                    maxLength={500}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <MyIconDecimalInput
                    icon="dollar sign"
                    iconPosition="left"
                    placeholder="0.00"
                    label={t("estimate.amount", { ns: "tickets" }) + ":"}
                    name="rate"
                    disabled={props.type === TicketItemType.MiscellaneousCharge}
                    onChange={(d) => {
                      if (d && values.quantity) {
                        let subTotal = Number(d) * Number(values.quantity);
                        setFieldValue("subTotal", round2Decimals(subTotal));
                      }
                    }}
                    maxLength={500}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <MyIconDecimalInput
                    icon="dollar sign"
                    iconPosition="left"
                    placeholder="0.00"
                    label={t("pops.sub_total", { ns: "tickets" }) + ":"}
                    name="subTotal"
                    disabled={props.type === TicketItemType.MiscellaneousCharge}
                    onChange={(d) => {
                      if (d && values.quantity) {
                        let rate = Number(d) / Number(values.quantity);
                        setFieldValue("rate", round2Decimals(rate));
                      }
                    }}
                    maxLength={500}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <MyCheckbox
                    label={t("pops.tax", { ns: "tickets" })}
                    name={"isTaxable"}
                    disabled={props.type === TicketItemType.MiscellaneousCharge}
                  />
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column>
                  <Button
                    color="green"
                    className="save_button"
                    icon="check"
                    content={
                      props.isAdd 
                        ? t("pops.add_misc_itm", { ns: "tickets" }) 
                        : t("pops.save_misc_itm", { ns: "tickets" })
                    }
                    disabled={isSubmitting || !dirty || !isValid}
                    loading={isSubmitting}
                    type="submit"
                  />
                  <Button
                    type="button"
                    onClick={() => {
                      modalStore.closeModal();
                    }}
                  >
                    <Icon name="x" />
                    {t("cancel", { ns: "common" })}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
});
