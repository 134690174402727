import { ErrorMessage, Form, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider } from "semantic-ui-react";
import { store, useStore } from "../../app/stores/store";
import { useParams } from "react-router-dom";
import MyTextArea from "../../app/common/form/MyTextArea";
import { CustomerNoteFormValues } from "../../app/models/customer";
import PageHeader from "../../app/layout/PageHeader";
import ValidationErrors from "../errors/ValidationErrors";

interface Props {
  name: string;
  header: string;
  isAdd: boolean;
}

export default observer(function AddCustomerNotes(props: Props) {
  const { t } = useTranslation(["common", "miscSettings"]);
  const { id } = useParams<{ id: string }>();

  const { customerStore, modalStore } = useStore();

  const {
    createCustomerNotes,
    selectedCustomerNote,
    updateCustomerNotes,
    loadCustomerNotes,
  } = customerStore;

  const [customerNote] = useState<CustomerNoteFormValues>(
    props.isAdd ? new CustomerNoteFormValues() : selectedCustomerNote
  );

  function handleFormSubmit(
    values: CustomerNoteFormValues,
    setErrors: (errors: FormikErrors<CustomerNoteFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    values.customerId = id!;

    const action = props.isAdd
      ? () => createCustomerNotes(values)
      : () => updateCustomerNotes(values);

    action()
      .then(() => {
        setSubmitting(false);
        loadCustomerNotes(id ?? "");
        modalStore.closeModal();
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors(error);
      });
  }
  return (
    <Formik
      initialValues={customerNote}
      onSubmit={(values, { setErrors, setSubmitting }) =>
        handleFormSubmit(values, setErrors, setSubmitting)
      }
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        dirty,
        handleBlur,
        handleChange,
        errors,
        values,
        touched,
      }) => (
        <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
          <PageHeader header={props.header} type={"h3"} divider={true} getAlerts={true} addTitle={false}/>
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <MyTextArea placeholder={""} name={"notes"} rows={4} maxLength={2000} />
          <Divider></Divider>
          <Button
            color="green"
            className="save_button"
            icon="check"
            content={t("save", { ns: "common" })}
            disabled={isSubmitting || !dirty || !isValid}
            loading={isSubmitting}
            type="submit"
          />
          <Button
            className="save_button"
            onClick={() => store.modalStore.closeModal()}
            icon="cancel"
            type="reset"
            content={t("cancel", { ns: "common" })}
          />
        </Form>
      )}
    </Formik>
  );
});
