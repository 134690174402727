import React, { useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Icon,
  Label,
  Radio,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import MyIconTextInput from "../../../app/common/form/MyIconTextInput";

export default observer(function AddNewTaxRate() {
  const { t } = useTranslation(["common", "translation", "tickets"]);

  const { modalStore } = useStore();

  const validationSchema = Yup.object({
    description: Yup.string().required(
      t("descriptionRequired", {
        ns: "errors",
      })
    ),
  });

  function handleFormSubmit() {
    modalStore.closeModal();
  }

  return (
    <>
      <PageHeader
        type={"h3"}
        header={t("tax_rates.new", {
            ns: "settings",
          })}
        divider={true}
        addTitle={false}
      />
      {/* <Formik
        validationSchema={validationSchema}
        onSubmit={(values) => handleFormSubmit(values)}
        initialValues={undefined}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form
            className="ui form error"
            autoComplete="on"
            onSubmit={handleSubmit}
          > */}
      {/* <ErrorMessage
              name="error"
              render={() => <ValidationErrors errors={errors} />}
            /> */}
      <Grid>
        <Container className="page">
          <Grid.Row>
            <Grid.Column>
              {/* uncomment after adding Formik */}
              {/* <MyTextInput 
                placeholder={t("tax_rates.taxName", {
                                ns: "settings",
                              })}
                label={t("tax_rates.taxName", {
                                ns: "settings",
                              })}
                name={""} 
                maxLength={25} 
                autoCapitalizeFirstLetter={false} /> */}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {/* uncomment after adding Formik */}
              {/* <MyIconTextInput
                name="taxRate"
                placeholder={t("tax.rate", { ns: "pricingSettings" })}
                icon="percent"
                label={`${t("tax.rate", { ns: "pricingSettings" })}:`}
                className="drop-down required"
                showHelp={false}
                maxLength={500}
              /> */}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
                {/* uncomment after adding Formik */}
              {/* <label className="mylabel required">
                          {`${t("tax.apply", { ns: "pricingSettings" })}:`}
                        </label>

                        <Form.Group grouped>
                          {taxOptions.map((data, index) => (
                            <Form.Field key={`saleTaxOption[${data.value}]`}>
                              <Radio
                                id={`saleTaxOption[${index}]`}
                                key={`saleTaxOption[${data.value}]`}
                                value={data.value}
                                name={"saleTaxOption"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={data.text}
                                checked={
                                  values.saleTaxOption.toString() ===
                                  data.value.toString()
                                }
                              />
                            </Form.Field>
                          ))}
                        </Form.Group>

                        {touched.saleTaxOption && errors.saleTaxOption && (
                          <Label basic color="red">
                            {errors.saleTaxOption}
                          </Label>
                        )} */}
            </Grid.Column>
          </Grid.Row>
        </Container>
        <Grid.Row>
          <Grid.Column columns={2}>
            <Button color="green" type="submit">
              <Icon name="check" />
              {t("add", { ns: "common" })}
            </Button>
            <Button
              type="button"
              onClick={() => {
                modalStore.closeModal();
              }}
            >
              <Icon name="x" />
              {t("close", { ns: "common" })}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* </Form>
        )}
      </Formik> */}
    </>
  );
});
