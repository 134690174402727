import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { Pagination, PagingParams } from "../../../models/pagination";
import { ActiveServiceType, MultipleRecurring, SubscriptionGap, SubscriptionGapOverview, ClientNoLoginReport } from "../../../models/systemAdmin";
import { sortingStrings } from "../../../common/util/functions";

export default class SystemAdminReportStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    if (value) this.predicate.set(predicate, value);
    else this.predicate.delete(predicate);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  SubscriptionGapRegistry = new Map<string, SubscriptionGap>();

  loadSubscriptionGap = async () => {
    try {
      this.SubscriptionGapRegistry.clear();
      const result = await agent.SystemAdminReports.getSubscriptionGap(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((x) => {
          this.setSubscriptionGap(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setSubscriptionGap = (subscriptionGap: SubscriptionGap) => {
    subscriptionGap.dateCreated = new Date(subscriptionGap.dateCreated);
    subscriptionGap.gapStart = new Date(subscriptionGap.gapStart);
    subscriptionGap.gapEnd = subscriptionGap?.gapEnd ? new Date(subscriptionGap.gapEnd) : null;
    this.SubscriptionGapRegistry.set(subscriptionGap.clientId, subscriptionGap);
  };

  get getSubscriptionGap() {
    return Array.from(this.SubscriptionGapRegistry.values()).sort(
      (a, b) => b.gapStart.getTime() - a.gapStart.getTime()
    );
  }

  SubscriptionGapOverviewResult: SubscriptionGapOverview | null = null;

  loadSubscriptionGapOverview = async() => {
    try {
      const startDate = this.predicate.has('startDate') ? this.predicate.get('startDate').toISOString() : null;
      const endDate = this.predicate.has('endDate') ? this.predicate.get('endDate').toISOString() : null;

      const result = await agent.SystemAdminReports.getSubscriptionGapOverview(
        (startDate && endDate) ? new URLSearchParams({startDate: startDate, endDate: endDate}) : undefined
      );
      runInAction(() => {
        this.SubscriptionGapOverviewResult = result;
      });
    } catch (error) {
      console.log(error);
    }
  }

  get getSubscriptionGapOverview() {
    return this.SubscriptionGapOverviewResult;
  }

  MultipleRecurringRegistry = new Map<string, MultipleRecurring>();

  loadMultipleRecurring = async () => {
    try {
      this.MultipleRecurringRegistry.clear();
      const result = await agent.SystemAdminReports.getMultipleRecurring();
      runInAction(() => {
        result.forEach((multipleRecurring) => {
          this.setMultipleRecurring(multipleRecurring);
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setMultipleRecurring = (multipleRecurring: MultipleRecurring) => {
    this.MultipleRecurringRegistry.set(
      multipleRecurring.clientId,
      multipleRecurring
    );
  };

  get getMultipleRecurring() {
    return Array.from(this.MultipleRecurringRegistry.values()).sort((a, b) => {
      return sortingStrings(a.clientName, b.clientName) ?? 0;
    });
  }

  ActiveServiceTypeRegistry = new Map<string, ActiveServiceType>();

  loadActiveServiceType = async() => {
    try {
      this.ActiveServiceTypeRegistry.clear();
      const result = await agent.SystemAdminReports.getActiveServiceTypes();
      runInAction(() => {
        result.forEach((x) => {
          this.setActiveServiceType(x);
        });
      });
    } catch (error) {
      console.log(error);
    }
  }

  private setActiveServiceType = (x: ActiveServiceType) => {
    this.ActiveServiceTypeRegistry.set(
      `${x.initialApplication}${x.currentApplication}${x.status}${x.triedOtherTrials}`, x 
    );
  }

  get getActiveServiceType(): ActiveServiceType[] {
    return Array.from(this.ActiveServiceTypeRegistry.values()).sort((a, b) => {
      const initApp = Math.sign(a.initialApplication - b.initialApplication);
      const currApp = Math.sign(a.currentApplication - b.currentApplication);
      const status = Math.sign(a.status - b.status);

      return initApp ?? currApp ?? status;
    });
  }

  ClientNoLoginRegistry = new Map<string, ClientNoLoginReport>();

  loadClientNoLogin = async() => {
    try {
      this.ClientNoLoginRegistry.clear();
      const result = await agent.SystemAdminReports.getClientNoLoginReport(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((x) => {
          x.createdDate = new Date(x.createdDate);
          this.setClientNoLogin(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  }

  private setClientNoLogin = (x: ClientNoLoginReport) => {
    this.ClientNoLoginRegistry.set(
      x.clientId, x
    );
  }

  get getClientNoLogin(): ClientNoLoginReport[] {
    return Array.from(this.ClientNoLoginRegistry.values()).sort((a, b) => {
      return Math.sign(a.createdDate.getTime() - b.createdDate.getTime());
    });
  }
}
