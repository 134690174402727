import { SemanticCOLORS } from "semantic-ui-react";
import { AnnouncementType } from "./enums";

export interface Announcement {
  color: SemanticCOLORS;
  header: string;
  body: string;
}

export interface SystemAdminAnnouncement {
  id?: number;
  startDate?: Date;
  endDate?: Date;
  announcementType?: AnnouncementType;
  color?: SemanticCOLORS;
  header?: string;
  body?: string;
  isActive: boolean;
  createdDate?: Date;
}

export class SystemAdminAnnouncement implements SystemAdminAnnouncement {
  constructor(init?: SystemAdminAnnouncement) {
    if (init) {
      this.id = init.id;
      this.startDate = init.startDate;
      this.endDate = init.endDate;
      this.announcementType = init.announcementType;
      this.color = init.color;
      this.header = init.header;
      this.body = init.body;
      this.isActive = init.isActive;
      this.createdDate = init.createdDate;
    }
  }
}
