import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Container,
  Form,
  Grid,
  Icon,
  Input,
  Message,
  TextArea,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import { Link } from "react-router-dom";
import PageHeader from "../../../../app/layout/PageHeader";
import { useStore } from "../../../../app/stores/store";
import { SettingsTicketNumberFormValues } from "../../../../app/models/ticket";


export default observer(function EmailDefaults() {
  const { t } = useTranslation(["common", "ticketSettings"]);
  const { modalStore } = useStore();

  const [settingsTicketNumber, setSettingsTicketNumber] =
    useState<SettingsTicketNumberFormValues>(
      new SettingsTicketNumberFormValues()
    );
  const [completed, setCompleted] = useState(false);

  function handleFormSubmit() {
    modalStore.closeModal();
  }

  return (
    <>
      <PageHeader
        header={t("Email Ticket/Invoice", { ns: "common" })}
        type="h2"
        divider
        getAlerts={true}
        addTitle={false}
      />

      <Container className="page">
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Input
                placeholder="Email Address To Send Invoice To"
                label="Send To:"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Input placeholder="From Address..." label="From Address:" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Input placeholder="Subject" label="Subject:" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <TextArea placeholder="Body" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Input placeholder="Signature" label="Signature:" />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <Button
                color="green"
                className="save_button"
                icon="check"
                content={t("Send", { ns: "common" })}
                type="submit"
              />
              <Button
                type="button"
                onClick={() => {
                  modalStore.closeModal();
                }}
              >
                <Icon name="x" />
                {t("cancel", { ns: "common" })}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
});
