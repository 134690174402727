export enum SettingsStatus
{
    Remove = -1,
    Off = 0,
    AlwaysAsk = 1,
    Always = 2
}
export interface IUpsertSelectableCommentFormValues {
    name: string;
    comment: string;
    status: SettingsStatus | undefined;
    isStandard?: boolean;
    standardId?: string | undefined;
}

export class UpsertSelectableCommentFormValues implements IUpsertSelectableCommentFormValues {
    name: string = "";
    comment: string = "";
    status: SettingsStatus | undefined;
    isStandard?: boolean = false;
    standardId?: string = "";

    constructor(comm?: SelectableCommentsDto) {
        if(comm){
            this.name = comm.name;
            this.comment = comm.comment;
            this.status = comm.status;
            this.isStandard = comm.isStandard;
        }
    }
    
    toJSON(): IUpsertSelectableCommentFormValues {
        return {
            name: this.name,
            comment: this.comment,
            status: parseInt(this.status!.toString()),
            isStandard: this.isStandard
        }
    }
}

export interface SelectableCommentsDto {
    id: string;
    name: string;
    comment: string;
    status: SettingsStatus;
    isStandard: boolean;
    createdDate: Date;
}