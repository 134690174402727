import { ErrorMessage, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Form, Grid } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { UserForgotPassword, UserFormValues } from "../../../app/models/user";
import * as Yup from "yup";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import ValidationErrors from "../../errors/ValidationErrors";
import MySelectInput from "../../../app/common/form/MySelectInput";
import {
  accessLevelOptions,
  enableOptions,
} from "../../../app/common/options/option";
import MyIconTextInput from "../../../app/common/form/MyIconTextInput";
import { RegexConstants } from "../../../app/common/util/regexConstants";
import MyRadioGroup from "../../../app/common/form/RadioButtonGroups/MyRadioGroup";
import { ClientStatus, PhoneNumberType } from "../../../app/models/enums";
import { getAccessOptionList } from "../../../app/common/util/functions";

interface Props {
  isAdd: boolean;
}

export default observer(function Details({ isAdd }: Props) {
  const { t } = useTranslation(["common", "errors"]);
  const navigate = useNavigate();

  const { userStore, subscriptionStore } = useStore();
  const {
    createUser,
    updateUser,
    getUserDetails,
    loadingInitial,
    requestPasswordReset,
    checkUsername,
    checkEmail,
    user,
    getActiveUserCount,
    loadUsersList,
  } = userStore;

  const { getCurrentSubscription } =
    subscriptionStore;

  const [activeUserCount, setActiveUserCount] = useState<number>(0);
  const [subscriptionUserCount, setSubscriptionUserCount] = useState<number>(0);

  const userNameExp = new RegExp(RegexConstants.userNameExp);
  const passwordExp = new RegExp(RegexConstants.passwordExp);
  const [loading, setLoading] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();

  const validationSchema = Yup.object({
    username: Yup.string()
      .required(t("registration.required_UserName", { ns: "errors" }))
      .test(
        "test-username",
        t("username_taken", { ns: "errors" }),
        async (username) => {
          return checkUsername(username ?? "", id)
            .then(() => true)
            .catch(() => false);
        }
      )
      .matches(
        userNameExp,
        t("registration.invalid_UserName", { ns: "errors" })
      ),
    email: Yup.string()
      .notRequired()
      .nullable()
      .email(t("registration.invalid_email", { ns: "errors" }))
      .test(
        "test-email",
        t("userAddEmailTaken", { ns: "errors" }),
        async (email) => {
          return checkEmail(email ?? "", id)
            .then(() => true)
            .catch(() => false);
        }
      ),
    password: Yup.string()
      .when("id", {
        is: id === undefined,
        then: Yup.string()
          .required(t("registration.required_Password", { ns: "errors" }))
          .matches(
            passwordExp,
            t("registration.invalid_Password", { ns: "errors" })
          )
          .nullable(),
      })
      .nullable()
      .matches(
        passwordExp,
        t("registration.invalid_Password", { ns: "errors" })
      ),
  });
  const [newUser, setNewUser] = useState<UserFormValues>();

  useEffect(() => {
    if (!isAdd && id) {
      setLoading(true);
      getUserDetails(id)
        .then((user) => {
          setNewUser(new UserFormValues(user));
          getCurrentSubscription().then((result) => {
            setSubscriptionUserCount(
              (result?.subscriptionUsers ?? 0) <= (result?.renewalUsers ?? 0)
                ? result?.subscriptionUsers ?? 0
                : result?.renewalUsers ?? 0
            );
          });
          if (getActiveUserCount() < 1) {
            loadUsersList()
              .then((result) => {
                setActiveUserCount(getActiveUserCount());
              })
              .catch();
          } else {
            setActiveUserCount(getActiveUserCount());
          }
        })
        .then()
        .catch()
        .finally(() => {
          setLoading(false);
        });
    }
  }, [getUserDetails, id, isAdd]);

  function handleFormSubmit(
    values: UserFormValues,
    setErrors: (errors: FormikErrors<UserFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateUser(values)
      : () => createUser(values);

    action()
      .then(() => {
        setSubmitting(false);
        navigate("/staff");
      })
      .catch((error) => {
        console.log(error);
        setErrors({ error: error });
        setSubmitting(false);
      });
  }

  if (loadingInitial || loading)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={newUser ?? new UserFormValues()}
      enableReinitialize={false}
      onSubmit={(values, { setErrors, setSubmitting }) =>
        handleFormSubmit(values, setErrors, setSubmitting)
      }
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        dirty,
        errors,
        handleBlur,
        handleChange,
        values,
        touched,
        setFieldError,
        setErrors,
        setFieldTouched,
        setFieldValue,
      }) => (
        <Form
          className="ui form error"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <PageHeader
            header={
              isAdd
                ? t("staff.addStaff", {
                    ns: "users",
                  })
                : t("staff.editStaff", {
                    ns: "users",
                  })
            }
            type={"h3"}
            divider={true}
            addTitle={false}
          />
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column>
                <MyIconTextInput
                  placeholder={t("username", { ns: "common" })}
                  label={`${t("username", { ns: "common" })}: `}
                  name="username"
                  icon={""}
                  className="required"
                  maxLength={255}
                />
              </Grid.Column>
              <Grid.Column>
                <MyTextInput
                  placeholder={t("email", { ns: "common" })}
                  label={`${t("email", { ns: "common" })}: `}
                  name="email"
                  autoCapitalizeFirstLetter={false}
                  maxLength={255}
                ></MyTextInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <MyTextInput
                  placeholder={t("last_name", { ns: "common" })}
                  label={`${t("last_name", { ns: "common" })}:`}
                  name="lastName"
                  autoCapitalizeFirstLetter={false}
                  maxLength={255}
                ></MyTextInput>
              </Grid.Column>
              <Grid.Column>
                <MyTextInput
                  placeholder={t("first_name", { ns: "common" })}
                  label={`${t("first_name", { ns: "common" })}:`}
                  name="firstName"
                  autoCapitalizeFirstLetter={false}
                  maxLength={255}
                ></MyTextInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <PhoneNumberInput
                  placeholder={t("staff.homePhone", { ns: "users" })}
                  label={`${t("staff.homePhone", { ns: "users" })}: `}
                  name="primaryNumber"
                  autoFormat={true}
                  phoneType={PhoneNumberType.Residential}
                ></PhoneNumberInput>
              </Grid.Column>
              <Grid.Column>
                <PhoneNumberInput
                  placeholder={t("staff.mobilePhone", { ns: "users" })}
                  label={`${t("staff.mobilePhone", { ns: "users" })}: `}
                  name="cellNumber"
                  autoFormat={true}
                  phoneType={PhoneNumberType.Mobile}
                ></PhoneNumberInput>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <MySelectInput
                  placeholder={t("accessLevel", { ns: "users" })}
                  label={`${t("accessLevel", { ns: "users" })}: `}
                  name={"accessLevel"}
                  clearable={true}
                  options={getAccessOptionList(user?.accessLevel ?? 0)}
                ></MySelectInput>
              </Grid.Column>
              <Grid.Column>
                <MyTextInput
                  placeholder={t("staff.referenceNote", { ns: "users" })}
                  label={`${t("staff.referenceNote", { ns: "users" })}:`}
                  name="referenceNote"
                  autoCapitalizeFirstLetter={false}
                  maxLength={2000}
                ></MyTextInput>
              </Grid.Column>
            </Grid.Row>

            {!isAdd && user?.id != values.id && (
              <Grid.Row>
                <Grid.Column>
                  <MyRadioGroup
                    label={`${t("status", { ns: "common" })}:`}
                    name={"isActive"}
                    options={enableOptions}
                    inline={false}
                    disabled={
                      !values.isActive &&
                      !(
                        activeUserCount < subscriptionUserCount ||
                        ((user?.status ?? 0) & ClientStatus.Trial) ==
                          ClientStatus.Trial
                      )
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            )}
            {user?.id == values.id ? (
              <Grid.Row>
                <Grid.Column>
                  <Link to={`/users/changePassword/${user?.id}`}>
                    {t("password.change", { ns: "users" })}
                  </Link>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Grid.Column>
                  <MyTextInput
                    placeholder={t("password", { ns: "common" })}
                    label={`${t("password", { ns: "common" })}:  * ${t(
                      "staff.mustChange",
                      { ns: "users" }
                    )}`}
                    name="password"
                    className={isAdd ? "required" : undefined}
                    autoCapitalizeFirstLetter={false}
                    maxLength={50}
                  ></MyTextInput>
                </Grid.Column>
                <Grid.Column verticalAlign="bottom">
                  {!isAdd && (
                    <>
                      <Button
                        content={
                          !values.email
                            ? t("staff.setEmail", { ns: "users" })
                            : t("staff.sendResetPassword", { ns: "users" })
                        }
                        disabled={!values.email}
                        onClick={() => {
                          if (values.email) {
                            let email: UserForgotPassword = {
                              emailAddress: values.email,
                            };
                            requestPasswordReset(email).then(() =>
                              navigate("/staff")
                            );
                          }
                        }}
                        color="green"
                        className="margin-left"
                      ></Button>
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            )}
            <Grid.Row columns={1}>
              <Grid.Column>
                <Button
                  color="green"
                  className="save_button"
                  icon="check"
                  content={t("save", { ns: "common" })}
                  disabled={isSubmitting || !dirty || !isValid}
                  loading={isSubmitting}
                  type="submit"
                />
                <Button
                  className="save_button"
                  as={Link}
                  to="/staff"
                  icon="cancel"
                  type="reset"
                  content={t("cancel", { ns: "common" })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
});
