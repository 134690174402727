import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Message,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import MyTextInput from "../../app/common/form/MyTextInput";
import PageHeader from "../../app/layout/PageHeader";
import { useStore } from "../../app/stores/store";
import { CustomerSearchValues } from "../../app/models/customer";
import LoadingComponent from "../../app/layout/LoadingComponent";
import MyPaging from "../../app/common/form/MyPaging";
import { PagingParams } from "../../app/models/pagination";
import CustomerVehicleSelection from "../tickets/recentTickets/CustomerVehicleSelection";
import PartDetailsSearch from "./PartDetailsSearch";
import { TicketItemType } from "../../app/models/enums";
import { TicketItemFormValues } from "../../app/models/ticket";
import PartItemForm from "../tickets/dashboard/modals/PartItemForm";

export default observer(function InventoryIndex() {
  const { t } = useTranslation(["common", "customer"]);
  const navigate = useNavigate();
  const { modalStore } = useStore();

  const [expand, setExpand] = useState<boolean>(false);

  const handleExpand = () => {
    setExpand(!expand);
  };

  let resetFilterValues: CustomerSearchValues = {
    phoneNumber: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    address: undefined,
    city: undefined,
    postalCode: undefined,
    state: undefined,
    companyName: undefined,
  };

  const [customerSearchValues, setCustomerSearchValues] =
    useState<CustomerSearchValues>(resetFilterValues);

  const [active, setActive] = useState(false);

  function handleFormSubmit(values: CustomerSearchValues) {
    setCustomerSearchValues(values);
  }

  function resetFilter() {
    throw new Error("Function not implemented.");
  }

  // if (loadingInitial)
  //   return (
  //     <LoadingComponent
  //       content={t("customerIndex_loading", { ns: "customer" })}
  //     />
  //   );

  return (
    <>
      <Grid stackable columns={2}>
        <Grid.Column>
          <PageHeader
            header={t("inventory", { ns: "common" })}
            type="h1"
            divider={false}
            getAlerts={true}
            addTitle={true}
          />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            color="green"
            icon="plus"
            content="Add New Part"
            onClick={() => {
              modalStore.openModal(
                //TODO Fix the vendor Dropdownlist as this list is needed in the part form
                <PartItemForm
                  isAdd={false}
                  addItem={function (
                    values: TicketItemFormValues,
                    type: TicketItemType
                  ): void {
                    throw new Error("Function not implemented.");
                  }}
                  autoCapitalizeFirstLetter={false}
                  vendorDropdownList={[]}
                />
              );
            }}
          />
        </Grid.Column>
      </Grid>
      <Divider />
      <Container className="page">
        <Formik
          initialValues={customerSearchValues}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({ handleSubmit, values }) => (
            <Form
              className="ui form"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid stackable columns={3}>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("Part Number or Description", {
                        ns: "reports",
                      })}
                      name="phoneNumber"
                      label={`${t("Part Number or Description", {
                        ns: "reports",
                      })}: `}
                      autoCapitalizeFirstLetter={false}
                      maxLength={25}
                    />
                  </Grid.Column>
                  {/* <Grid.Column>
                    <MyTextInput
                      placeholder={t("pops.part_desc", { ns: "tickets" })}
                      name="firstName"
                      label={`${t("pops.part_desc", { ns: "tickets" })}: `}
                      autoCapitalizeFirstLetter={false}
                      maxLength={225}
                    />
                  </Grid.Column> */}
                  {/* <Grid.Column>
                    <MyTextInput
                      placeholder={t("last_name", { ns: "common" })}
                      name="lastName"
                      label={`${t("last_name", { ns: "common" })}: `}
                      autoCapitalizeFirstLetter={false}
                      maxLength={255}
                    />
                  </Grid.Column> */}
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color="green"
                      className="save_button"
                      icon="filter"
                      content={t("buttons.search", { ns: "customer" })}
                      type="submit"
                      onClick={() => setActive(true)}
                    />
                    <Button
                      color="grey"
                      className="save_button"
                      content={t("removeSearch", { ns: "customer" })}
                      type="button"
                      onClick={() => {
                        resetFilter();
                        setCustomerSearchValues(resetFilterValues);
                        setActive(false);
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
        <Table striped unstackable className="dnxTable">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t("part_number", { ns: "reports" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {t("pops.part_desc", { ns: "tickets" })}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {`${t("Total Quantity", { ns: "tickets" })}`}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {`${t("Average Cost", { ns: "tickets" })}`}
              </Table.HeaderCell>
              <Table.HeaderCell>
                {`${t("Unit Price", { ns: "tickets" })}`}
              </Table.HeaderCell>
              <Table.HeaderCell content={" "} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row key={""}>
              <Table.Cell data-label={`${t("name", { ns: "common" })}:`}>
                Part
              </Table.Cell>
              <Table.Cell
                data-label={`${t("customeraddressMobile", {
                  ns: "customer",
                })}:`}
              >
                The thingy
              </Table.Cell>
              <Table.Cell
                data-label={`${t("contactinfoMobile", {
                  ns: "customer",
                })}:`}
              >
                12
              </Table.Cell>
              <Table.Cell
                data-label={`${t("contactinfoMobile", {
                  ns: "customer",
                })}:`}
              >
                $250
              </Table.Cell>
              <Table.Cell
                data-label={`${t("contactinfoMobile", {
                  ns: "customer",
                })}:`}
              >
                $500
              </Table.Cell>
              <Table.Cell className="textAlignRight">
                <Button
                  size="mini"
                  as={Link}
                  to={`/inventoryIndex`}
                  icon="eye"
                  type="button"
                  color="blue"
                  content={t("See More", { ns: "common" })}
                  onClick={handleExpand}
                />
                <Button
                  content={t("See All Details", { ns: "common" })}
                  icon="clipboard list"
                  size="mini"
                  onClick={() => {
                    navigate(`/inventory/partDetails/`);
                  }}
                />
              </Table.Cell>
            </Table.Row>

            {/* ) : (
              <Table.Row key={"noItems"}>
                <Table.Cell width="8" colSpan={8}>
                  <Message
                    color="yellow"
                    className="textAlignCenter fontStyleItalic"
                  >
                    {t("grid_noItems", { ns: "common" })}
                  </Message>
                </Table.Cell>
              </Table.Row>
            )} */}
            {expand && <PartDetailsSearch />}
          </Table.Body>
          {/* <Table.Footer>
            <Table.Row>
              <Table.Cell colSpan="6">
                <MyPaging
                  itemCount={pagination ? pagination.totalItems : 10}
                  pageSize={pagingParams.pageSize}
                  currentPage={pagination ? pagination.currentPage : 1}
                  onClick={listOnClick}
                  onChange={listOnClick}
                  floated="right"
                ></MyPaging>
              </Table.Cell>
            </Table.Row>
          </Table.Footer> */}
        </Table>
      </Container>
    </>
  );
});
