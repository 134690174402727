import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { Application } from "../../../app/models/enums";
import { convertApplicationToString, convertClientStatusToString, formatDecimal, getCellColor } from "../../../app/common/util/functions";
import { StatsCompanyStatus } from "../../../app/models/systemAdmin";

export interface CompanyStatusesProps {
  isCompare: boolean;
}

export default observer(function CompanyStatuses({isCompare}: CompanyStatusesProps) {
  const { statsStore } = useStore();

  const { statsOverview, loadStatsOverview, loadStatsCompare, statsCompare } = statsStore;

  useEffect(() => {
    if (!isCompare && !statsOverview) {
      loadStatsOverview();
    } else if (isCompare && !statsCompare) {
      loadStatsCompare();
    }
  }, [statsOverview, loadStatsOverview, statsCompare, loadStatsCompare, isCompare]);

  const companyStatuses: StatsCompanyStatus[] = ((isCompare ? statsCompare?.companyStatuses : statsOverview?.companyStatuses) ?? []) as StatsCompanyStatus[];
  const statuses = new Set(companyStatuses.map(x => x.status))

  const noneApp = companyStatuses.filter(x => x.application === Application.None);
  const rtlgApp = companyStatuses.filter(x => x.application === Application.RealTimeLaborGuide);
  const rtlpApp = companyStatuses.filter(x => x.application === Application.RealTimeLaborGuidePro);

  return (
    <Table singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">None</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">{convertApplicationToString(Application.RealTimeLaborGuide)}</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">{convertApplicationToString(Application.RealTimeLaborGuidePro)}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.from(statuses).map(s => {
          const none = noneApp.find((x) => x.status === s)?.statusCount ?? 0; 
          const rtlg = rtlgApp.find((x) => x.status === s)?.statusCount ?? 0; 
          const rtlp = rtlpApp.find((x) => x.status === s)?.statusCount ?? 0; 

          return (
            <Table.Row key={s}>
              <Table.Cell>{convertClientStatusToString(s)}</Table.Cell>
              <Table.Cell textAlign="right" className={isCompare ? getCellColor(none) : ''}>
                {formatDecimal(none, 0)}
              </Table.Cell>
              <Table.Cell textAlign="right" className={isCompare ? getCellColor(rtlg) : ''}>
                {formatDecimal(rtlg, 0)}
              </Table.Cell>
              <Table.Cell textAlign="right" className={isCompare ? getCellColor(rtlp) : ''}>
                {formatDecimal(rtlp, 0)}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
});