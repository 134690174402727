import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function Extensions() {
  const { t } = useTranslation(["common", "companySettings"]);

  const { clientExtensionStore } = useStore();

  const { extensionReport, loadExtensionReport } = clientExtensionStore;

  useEffect(() => {
    loadExtensionReport();
  }, [loadExtensionReport]);

  return (
    <>
      <PageHeader
        type="h1"
        header={t("Active Company Extensions", { ns: "systemAdmin" })}
        className="modal-text-color"
        textAlign="left"
        divider
        addTitle
      />
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Service Type</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>User Trial Extension</Table.HeaderCell>
            <Table.HeaderCell>Admin Trial Extensions</Table.HeaderCell>
            <Table.HeaderCell>User Sub Extension</Table.HeaderCell>
            <Table.HeaderCell>Admin Sub Extensions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <b>Real-Time Labor Guide</b>
            </Table.Cell>
            <Table.Cell>Trial Account</Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeLaborGuideTrial.userTrialExtension}
            </Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeLaborGuideTrial.adminTrialExtension}
            </Table.Cell>
            <Table.Cell>
              {
                extensionReport?.realTimeLaborGuideTrial
                  .userSubscriptionExtension
              }
            </Table.Cell>
            <Table.Cell>
              {
                extensionReport?.realTimeLaborGuideTrial
                  .adminSubscriptionExtension
              }
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Real-Time Labor Guide</b>
            </Table.Cell>
            <Table.Cell>Subscription Active</Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeLaborGuideActive.userTrialExtension}
            </Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeLaborGuideActive.adminTrialExtension}
            </Table.Cell>
            <Table.Cell>
              {
                extensionReport?.realTimeLaborGuideActive
                  .userSubscriptionExtension
              }
            </Table.Cell>
            <Table.Cell>
              {
                extensionReport?.realTimeLaborGuideActive
                  .adminSubscriptionExtension
              }
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Real-Time Labor Guide</b>
            </Table.Cell>
            <Table.Cell>Subscription Extended</Table.Cell>
            <Table.Cell>
              {
                extensionReport?.realTimeLaborGuideActiveExtension
                  .userTrialExtension
              }
            </Table.Cell>
            <Table.Cell>
              {
                extensionReport?.realTimeLaborGuideActiveExtension
                  .adminTrialExtension
              }
            </Table.Cell>
            <Table.Cell>
              {
                extensionReport?.realTimeLaborGuideActiveExtension
                  .userSubscriptionExtension
              }
            </Table.Cell>
            <Table.Cell>
              {
                extensionReport?.realTimeLaborGuideActiveExtension
                  .adminSubscriptionExtension
              }
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Real-Time Pro</b>
            </Table.Cell>
            <Table.Cell>Trial Account</Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeProTrial.userTrialExtension}
            </Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeProTrial.adminTrialExtension}
            </Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeProTrial.userSubscriptionExtension}
            </Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeProTrial.adminSubscriptionExtension}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Real-Time Pro</b>
            </Table.Cell>
            <Table.Cell>Subscription Active</Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeProActive.userTrialExtension}
            </Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeProActive.adminTrialExtension}
            </Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeProActive.userSubscriptionExtension}
            </Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeProActive.adminSubscriptionExtension}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <b>Real-Time Pro</b>
            </Table.Cell>
            <Table.Cell>Subscription Extended</Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeProActiveExtension.userTrialExtension}
            </Table.Cell>
            <Table.Cell>
              {extensionReport?.realTimeProActiveExtension.adminTrialExtension}
            </Table.Cell>
            <Table.Cell>
              {
                extensionReport?.realTimeProActiveExtension
                  .userSubscriptionExtension
              }
            </Table.Cell>
            <Table.Cell>
              {
                extensionReport?.realTimeProActiveExtension
                  .userSubscriptionExtension
              }
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
});
