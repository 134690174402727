import axios from "axios";
import { PaginatedResult } from "../models/pagination";
import { requests, responseBody } from "./Requests";
import { CustomerSearch, TicketItemSearch, TicketSearch, VehicleSearch } from "../models/search";


export const SearchApi = {
    getCustomerSearch: (params: URLSearchParams) =>
        axios
            .get<PaginatedResult<CustomerSearch[]>>(`/Search/getCustomerSearch`, { params })
            .then(responseBody),
    getVehiclesByCustomerId: (id: string) =>
        requests.get<VehicleSearch[]>(`/Search/getVehiclesByCustomerId/${id}`),
    getTicketByVehicleId: (id: string) =>
        requests.get<TicketSearch[]>(`/Search/getTicketsByVehicleId/${id}`),
    getVehicle: (params: URLSearchParams) =>
        axios
            .get<PaginatedResult<VehicleSearch[]>>(`/Search/getVehicle`, { params })
            .then(responseBody),
    getTicketSearch: (params: URLSearchParams) =>
        axios
            .get<PaginatedResult<TicketSearch[]>>(`/Search/getTicketSearch`, { params })
            .then(responseBody),
    getTicketItem: (id: string) =>
        requests.get<TicketItemSearch[]>(`/Search/getTicketItems/${id}`),

};
