import { RegexConstants } from "./regexConstants";

interface LinkifyProps {
  content: string;
}

export const Linkify = ({ content }: LinkifyProps) => {
  const isUrl = (word: string) => {
    let ret = word.match(RegexConstants.webUrlPattern);
    return ret;
  };

  const addMarkup = (word: string) => {
    return isUrl(word) ? `<a href="${word}">${word}</a>` : word;
  };

  const words = content.split(" ");
  const formattedWords = words.map((w) => addMarkup(w));
  const html = formattedWords.join(" ");

  // This content isn't sanitized nor purified. Intended for use where we control the user input such as admin notes
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};
