import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { Transaction, ReportInput } from "../../models/reports";
import { PagingParams, Pagination } from "../../models/pagination";
import { DateRangeOption, ReportType } from "../../models/enums";

export default class CardTransactionReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.ccTransactionRegistry.clear();
        this.loadCCTransaction();
      }
    );
  }

  loadingInitial = false;
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  reportCriteria: ReportInput = new ReportInput({
    dateRange: DateRangeOption.Today,
    startDate: new Date(),
    endDate: new Date(),
    reportType: ReportType.Summary,
    filter: "0",
  });

  ccTransactionRegistry = new Map<Date, Transaction>();

  resetCCTransactionsRegistry = () => {
    this.ccTransactionRegistry.clear();
  };

  loadCCTransaction = async () => {
    this.loadingInitial = true;
    try {
      this.ccTransactionRegistry.clear();
      const result = await agent.Reports.getCardTransaction(this.axiosParams);
      runInAction(() => {
        result.forEach((ccTransaction) => {
          this.setCCTransaction(ccTransaction);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setCCTransaction = (ccTransaction: Transaction) => {
    if (ccTransaction.date) ccTransaction.date = new Date(ccTransaction.date);
    this.ccTransactionRegistry.set(
      ccTransaction.date ?? new Date(),
      ccTransaction
    );
  };

  get getCCTransaction() {
    return Array.from(this.ccTransactionRegistry.values()).sort(
      (a, b) => b.date!.getTime() - a.date!.getTime()
    );
  }

  pagingParams = new PagingParams();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (startDate?: Date, endDate?: Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
  };

  setReportCriteria = (values: ReportInput) => {
    this.reportCriteria = new ReportInput(values);
  };

  get groupedTransactionReport() {
    return Object.entries(
      this.getCCTransaction.reduce((groups, Transaction) => {
        const date = Transaction.date!.toISOString().split("T")[0];
        groups[date] = groups[date]
          ? [...groups[date], Transaction]
          : [Transaction];
        return groups;
      }, {} as { [key: string]: Transaction[] })
    );
  }

  calculateGrandTotal = (data: Transaction[]) => {
    const summary = data.reduce(
      (accumulator: Transaction, item: Transaction) => {
        accumulator.amount += item.amount;
        return accumulator;
      },
      {
        amount: 0,
      }
    );
    return summary;
  };
}
