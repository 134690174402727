import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";

export default observer(function TrialActivity() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const { trialActivityStore } = useStore();
  const { loadTrialActivityReport, getTrialActivityReport } = trialActivityStore;

  useEffect(() => {
    loadTrialActivityReport();
  }, [loadTrialActivityReport]);

  return (
    <>
      <PageHeader
        type="h1"
        header={t("Trial Activity", { ns: "systemAdmin" })}
        className="modal-text-color"
        textAlign="left"
        divider
        addTitle
      />
      <PageHeader
        type="h4"
        header="Represents how many trials were approved 1 day ago, 2 days ago, etc up to 14 days ago"
        divider={false}
        addTitle={false}
      />
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            {getTrialActivityReport.map((x) => (
              <Table.HeaderCell>{x.daysAgo}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <b>Count</b>
            </Table.Cell>
            {getTrialActivityReport.map((x) => (
              <Table.Cell>{x.count}</Table.Cell>
            ))}
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
});
