import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import { Button, Form, Grid, Message } from "semantic-ui-react";
import ValidationErrors from "../errors/ValidationErrors";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import { CustomerFormValues } from "../../app/models/customer";
import * as Yup from "yup";
import PageHeader from "../../app/layout/PageHeader";
import CustomerEdit from "./CustomerEdit";
import { toast } from "react-toastify";
import { TicketWorkflowSteps } from "../../app/models/enums";
import { PagingParams } from "../../app/models/pagination";

interface Props {
  isTicket: boolean;
  isModal: boolean;
  isCustomerSet?: (value: boolean) => void;
  previousLocation: string;
  customerFormValues: CustomerFormValues;
  updateData?: boolean;
  ticketWorkflowStep?: TicketWorkflowSteps;
  ticketId?: string;
  showDefaults?: boolean;
}

export default observer(function CustomerForm({
  isTicket,
  isModal,
  isCustomerSet,
  previousLocation,
  customerFormValues,
  updateData,
  ticketWorkflowStep,
  ticketId,
}: Props) {
  const { t } = useTranslation([
    "common",
    "translation",
    "tickets",
    "customer",
  ]);
  const navigate = useNavigate();

  const {
    customerStore,
    modalStore,
    ticketStore,
    ticketSessionStore,
    vehicleStore,
  } = useStore();
  const {
    updateCustomer,
    setSelectedCustomer,
    loadPotentialCustomersList,
    potentialCustomerPredicate,
    setPagingParams
  } = customerStore;

  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;
  const { selectedVehicle } = vehicleStore;
  const {
    updateTicketSession,
    setSelectedTicketCustomer,
  } = ticketSessionStore;

  const [completed, setCompleted] = useState<boolean>(false);
  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  const validationSchema = Yup.object({});


  function handleFormSubmit(
    values: CustomerFormValues,
    setErrors: (errors: FormikErrors<CustomerFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    //eventually need a create
    if (isTicket) {
      setSelectedCustomer(values);
      setSelectedTicketCustomer(values);

      if (updateData && ticketWorkflowStep && ticketId) {
        //store data
          updateTicketSession(
            ticketWorkflowStep,
            ticketId,
            selectedVehicle,
            values
          );
      }

      let setLastName: boolean = false;
      let setCompanyName: boolean = false;
      if (values.lastName && values.lastName.length > 0) setLastName = true;
      if (
        values?.isCommercial &&
        values?.companyName &&
        values.companyName.length > 0
      )
        setCompanyName = true;

      if (isCustomerSet) {
        isCustomerSet(setLastName || setCompanyName);
      }

      if (!values.id && (setLastName || setCompanyName)) {
        setPagingParams(new PagingParams());
        potentialCustomerPredicate(
          values.lastName ?? "",
          values.isCommercial,
          values.companyName ?? ""
        );
        loadPotentialCustomersList().then(() => {
          setCompleted(true);
          setSubmitting(false);
          modalStore.closeModal();
        });
      } else {
        setCompleted(true);
        setSubmitting(false);
        modalStore.closeModal();
      }
    } else {
      updateCustomer(values)
        .then(() => {
          setCompleted(true);
          setSubmitting(false);
          toast.success(t("customerupdated", { ns: "common" }).toString());
          navigate(previousLocation);
        })
        .catch((error) => {
          setSubmitting(false);
          setErrors(error);
        });
    }
  }

  return (
    <>
      <PageHeader
        header={t("editcustomerdetails", { ns: "customer" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Message warning>
        {t("customerupdatedwarning", {
            ns: "common",
          })}
      </Message>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize={false}
        initialValues={customerFormValues}
        onSubmit={(values, { setErrors, setSubmitting }) =>
          handleFormSubmit(values, setErrors, setSubmitting)
        }
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setErrors,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
            {completed && (
              <Message positive>
                {t("customerupdated", {
                  ns: "common",
                })}
              </Message>
            )}
            <ErrorMessage
              name="error"
              render={() => <ValidationErrors errors={errors.error} />}
            />
            <Grid stackable>
              <CustomerEdit
                isTicket={isTicket}
                ticketInputDefaults={selectedSettingsTicketInputDefaults}
                showDefaults={true}
                initialIsCommercial={values.isCommercial}
              />
              <Grid.Row>
                <Grid.Column>
                  <Button
                    color="green"
                    className="save_button"
                    icon="check"
                    content={t("update", { ns: "common" })}
                    disabled={isSubmitting || !dirty || !isValid}
                    loading={isSubmitting}
                    type="submit"
                  />
                  <Button
                    className="save_button"
                    icon="cancel"
                    type="reset"
                    content={t("cancel", { ns: "common" })}
                    onClick={() => {
                      if (isModal) modalStore.closeModal();
                      else {
                        navigate(previousLocation);
                      }
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
});
