import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Label,
  Message,
  Radio,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik, FormikErrors } from "formik";
import { Link } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { SettingsTicketDefaultsFormValues } from "../../../app/models/ticket";
import {
  hourlyRateOptions,
  promisedDateOptions,
  yesNoOptions,
} from "../../../app/common/options/option";
import MySelectInput from "../../../app/common/form/MySelectInput";
import PageHeader from "../../../app/layout/PageHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import ValidationErrors from "../../errors/ValidationErrors";
import MyHelpButton from "../../../app/common/form/MyHelpButton";
import MyIconDecimalInput from "../../../app/common/form/MyIconDecimalInput";

export default observer(function TicketDefault() {
  const { t } = useTranslation(["common", "ticketSettings"]);

  const { ticketStore, pricingStore } = useStore();
  const {
    loadSettingsTicketDefaults,
    loadingInitial,
    createTicketDefaults,
    updateTicketDefaults,
  } = ticketStore;

  const { loadDropdownPayTypeList, dropdownPayTypesList } = pricingStore;

  const validationSchema = Yup.object({
    showLaborHours: Yup.boolean().required(),
    showSeparateCharges: Yup.boolean().required(),
    showReturnOldParts: Yup.boolean().required(),
  });

  useEffect(() => {
    loadDropdownPayTypeList();
  }, [loadDropdownPayTypeList]);

  const [settingsTicketDefaults, setSettingsTicketDefaults] =
    useState<SettingsTicketDefaultsFormValues>(
      new SettingsTicketDefaultsFormValues()
    );

  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadValues();
  }, [loadSettingsTicketDefaults]);

  function loadValues() {
    loadSettingsTicketDefaults().then((ticketDefaults) =>
      setSettingsTicketDefaults(
        new SettingsTicketDefaultsFormValues(ticketDefaults)
      )
    );
  }

  function handleFormSubmit(
    values: SettingsTicketDefaultsFormValues,
    setErrors: (errors: FormikErrors<SettingsTicketDefaultsFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateTicketDefaults(values)
      : () => createTicketDefaults(values);

    action()
      .then(() => {
        loadValues();
        setSubmitting(false);
        setCompleted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("ticket_loading", { ns: "ticketSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("ticketdefaults", { ns: "common" })}
        type={"h2"}
        divider={true}
        showAlerts={true}
        getAlerts={true}
        showHelp
        helpName="settings.ticket.ticketDefaults"
        addTitle={true}
      />

      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsTicketDefaults}
          onSubmit={(values, { setErrors, setSubmitting }) => {
            handleFormSubmit(values, setErrors, setSubmitting);
          }}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
              <Grid columns={2} stackable>
                <Grid.Row>
                  <Grid.Column>
                    <label className="required">
                      {`${t("ticket_defaults.showlaborhoursonticket", {
                        ns: "ticketSettings",
                      })}:`}
                    </label>
                    <MyHelpButton
                      name="showLaborHoursHelp"
                      position="bottom left"
                      help="settings.ticket.showLaborHours"
                      color={"blue"}
                    />
                    <Form.Group inline>
                      {yesNoOptions.map((data, index) => (
                        <Form.Field key={`showLaborHours[${data.value}]`}>
                          <Radio
                            id={`showLaborHours[${index}]`}
                            key={`showLaborHours[${data.value}]`}
                            value={data.value}
                            name="showLaborHours"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={data.text}
                            checked={
                              values.showLaborHours.toString() === data.value
                            }
                          />
                        </Form.Field>
                      ))}
                      {touched.showLaborHours && errors.showLaborHours && (
                        <Label basic color="red">
                          {errors.showLaborHours}
                        </Label>
                      )}
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <label className="required">
                      {`${t("ticket_defaults.sepratechargesonticket", {
                        ns: "ticketSettings",
                      })}:`}
                    </label>
                    <MyHelpButton
                      name="showLaborHoursHelp"
                      position="bottom right"
                      help="settings.ticket.seperateItems"
                      color={"blue"}
                    />
                    <Form.Group inline>
                      {yesNoOptions.map((data, index) => (
                        <Form.Field key={`showSeparateCharges[${data.value}]`}>
                          <Radio
                            {...Field}
                            id={`showSeparateCharges[${index}]`}
                            key={`showSeparateCharges[${data.value}]`}
                            value={data.value}
                            name="showSeparateCharges"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={data.text}
                            checked={
                              values.showSeparateCharges.toString() ===
                              data.value
                            }
                          />
                        </Form.Field>
                      ))}
                      {touched.showSeparateCharges &&
                        errors.showSeparateCharges && (
                          <Label basic color="red">
                            {errors.showSeparateCharges}
                          </Label>
                        )}
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <label className="required">
                      {`${t("ticket_defaults.returnoldparts", {
                        ns: "ticketSettings",
                      })}:`}
                    </label>
                    <MyHelpButton
                      name="showLaborHoursHelp"
                      help="settings.ticket.returnedOldParts"
                      color={"blue"}
                    />
                    <Form.Group inline>
                      {yesNoOptions.map((data, index) => (
                        <Form.Field key={`showReturnOldParts[${data.value}]`}>
                          <Radio
                            id={`showReturnOldParts[${index}]`}
                            key={`showReturnOldParts[${data.value}]`}
                            value={data.value}
                            name="showReturnOldParts"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={data.text}
                            checked={
                              values.showReturnOldParts.toString() ===
                              data.value
                            }
                          />
                        </Form.Field>
                      ))}
                      {touched.showReturnOldParts &&
                        errors.showReturnOldParts && (
                          <Label basic color="red">
                            {errors.showReturnOldParts}
                          </Label>
                        )}
                    </Form.Group>
                  </Grid.Column>
                  <Grid.Column>
                    <label className="required">
                      {`${t("ticket_defaults.repaircompletiondatepromise", {
                        ns: "ticketSettings",
                      })}:`}
                    </label>
                    <MyHelpButton
                      name="showLaborHoursHelp"
                      position="bottom right"
                      help="settings.ticket.completedDate"
                      color={"blue"}
                    />
                    <Form.Group inline>
                      {promisedDateOptions.map((data, index) => (
                        <Form.Field key={`promisedDateOptions[${data.value}]`}>
                          <Radio
                            id={`promisedDateOptions[${index}]`}
                            key={`promisedDateOptions[${data.value}]`}
                            value={data.value}
                            name={"promisedDateOptions"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={data.text}
                            checked={
                              values.promisedDateOptions.toString() ===
                              data.value.toString()
                            }
                          />
                        </Form.Field>
                      ))}
                    </Form.Group>
                    {touched.promisedDateOptions &&
                      errors.promisedDateOptions && (
                        <Label basic color="red">
                          {errors.promisedDateOptions}
                        </Label>
                      )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <label className="required">
                      {`${t("ticket_defaults.typeofratecharge", {
                        ns: "ticketSettings",
                      })}:`}
                    </label>
                    <MyHelpButton
                      name="showLaborHoursHelp"
                      help="settings.ticket.typeRate"
                      color={"blue"}
                    />
                    <Form.Group inline>
                      {hourlyRateOptions.map((data, index) => (
                        <Form.Field key={`rateTypes[${data.value}]`}>
                          <Radio
                            id={`rateTypes[${index}]`}
                            key={`rateTypes[${data.value}]`}
                            value={data.value}
                            name={"rateTypes"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            label={data.text}
                            checked={
                              values.rateTypes.toString() ===
                              data.value.toString()
                            }
                          />
                        </Form.Field>
                      ))}
                    </Form.Group>
                    {touched.rateTypes && errors.rateTypes && (
                      <Label basic color="red">
                        {errors.rateTypes}
                      </Label>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MySelectInput
                      placeholder={t("ticket_defaults.expectedPaymentMethod", {
                        ns: "ticketSettings",
                      })}
                      name={"defaultPayTypeId"}
                      label={`${t("ticket_defaults.expectedPaymentMethod", {
                        ns: "ticketSettings",
                      })}:`}
                      options={dropdownPayTypesList}
                      showHelp={true}
                      helpName="settings.ticket.expectedPayment"
                      clearable={true}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <MyIconDecimalInput
                      placeholder={t("ticket_defaults.estimateCharge", {
                        ns: "ticketSettings",
                      })}
                      name={"estimateCharge"}
                      label={`${t("ticket_defaults.estimateCharge", {
                        ns: "ticketSettings",
                      })}:`}
                      icon={"dollar"}
                      iconPosition={"left"}
                      showHelp={true}
                      helpName="settings.ticket.estimateCharge"
                      maxLength={500}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color="green"
                      className="save_button"
                      icon="check"
                      content={t("save", { ns: "common" })}
                      disabled={isSubmitting || !dirty || !isValid}
                      loading={isSubmitting}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/settings"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
