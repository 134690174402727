import React, { useEffect, useState } from "react";

interface Props {
  vin: string;
}

export default function VinDisplay(props: Props) {
  const [firstSegment, setFirstSegment] = useState<string>(props.vin);
  const [lastSegment, setLastSegment] = useState<string>();

  useEffect(() => {
    if (props.vin && props.vin.length > 8) {
      setFirstSegment(props.vin.substring(0, props.vin.length - 8));
      setLastSegment(
        props.vin.substring(props.vin.length - 8, props.vin.length)
      );
    }
  }, [props.vin, props.vin.length]);

  return (
    <>
      {firstSegment}
      <b>{lastSegment}</b>
    </>
  );
}
