import { DropdownItemProps } from "semantic-ui-react";
import { SettingsVendors } from "../models/vendor";
import { Vendor, VendorsFormValues } from "../models/vendor";
import { requests } from "./Requests";

export const Vendors = {
  listVendors: () => requests.get<Vendor[]>(`/Vendor/list/`),
  dropDownVendorList: () => requests.get<DropdownItemProps[]>(`/Vendor/dropDownVendorList/`),
  create: (vendor: VendorsFormValues) => requests.post<void>("/Vendor", vendor),
  update: (values: VendorsFormValues) =>
    requests.put<void>(`/Vendor/updateVendor/${values.id}`, values),
  toggleVendors: (id: string) => requests.put<void>(`/Vendor/toggle/${id}`, {}),
  getSettingsVendor: () =>
    requests.get<SettingsVendors>(`/SettingsVendor/getSettingsVendor/`),
  addSettingsVendor: (values: SettingsVendors) =>
    requests.post<void>("/SettingsVendor/addSettingsVendor", values),
  updateSettingsVendor: (values: SettingsVendors) =>
    requests.put<void>(
      `/SettingsVendor/updateSettingsVendor/${values.id}`,
      values
    ),
  removeVendor: (id?: string) =>
    requests.put<void>(`/Vendor/remove/${id}`, {}),
};
