import axios from "axios";
import { Transaction, CustomerHistory, IndividualTypeSales, PayTypeReport, TaxReport, TechnicianReport, TotalSalesReport, VendorReport } from "../models/reports";
import { responseBody } from "./Requests";

export const Reports = {
    getLaborReport: (params: URLSearchParams) =>
        axios
            .get<IndividualTypeSales[]>(`/Report/listTotalLaborSales`, { params })
            .then(responseBody),
    getCardTransaction: (params: URLSearchParams) =>
        axios
            .get<Transaction[]>(`/Report/listCardPaymentReport`, { params })
            .then(responseBody),
    getPartReport: (params: URLSearchParams) =>
        axios
            .get<IndividualTypeSales[]>(`/Report/listTotalPartSales`, { params })
            .then(responseBody),
    getMiscChargesReport: (params: URLSearchParams) =>
        axios
            .get<IndividualTypeSales[]>(`/Report/listTotalMiscCharges`, { params })
            .then(responseBody),
    getTaxReport: (params: URLSearchParams) =>
        axios
            .get<TaxReport[]>(`/Report/listTotalTaxes`, { params })
            .then(responseBody),
    getPayTypeReport: (params: URLSearchParams) =>
        axios
            .get<PayTypeReport[]>(`/Report/listTotalPayTypeReport`, { params })
            .then(responseBody),
    getTotalSalesReport: (params: URLSearchParams) =>
        axios
            .get<TotalSalesReport[]>(`/Report/listTotalSales`, { params })
            .then(responseBody),
    getTechnicianReport: (params: URLSearchParams) =>
        axios
            .get<TechnicianReport[]>(`/Report/listTotalTechnicianReport`, { params })
            .then(responseBody),
    getVendorReport: (params: URLSearchParams) =>
        axios
            .get<VendorReport[]>(`/Report/listTotalVendorReport`, { params })
            .then(responseBody),
    getCustomerHistory: (params: URLSearchParams, id: string) => 
        axios
            .get<CustomerHistory[]>(`/Report/listCustomerHistory/${id}`, {params})
            .then(responseBody),
};
