import { requests } from "./Requests";
import { PackageJobs } from "../models/packageJobs";

export const packageJobs = {
  listPackageJobs: () =>
    requests.get<PackageJobs[]>(`/PackageJobs/listPackageJobs/`),
  getPackageJobs: (id: string) =>
    requests.get<PackageJobs>(`/PackageJobs/getPackageJob/${id}`),
  addPackageJobs: (values: PackageJobs) =>
    requests.post<void>("/PackageJobs/addPackageJobs", values),
  togglePackageJobs: (id: string) =>
    requests.put<void>(`/PackageJobs/toggle/${id}`, {}),
  removePackageJobs: (id: string | undefined) =>
    requests.put<void>(`/PackageJobs/removePackageJobs/${id}`, {}),
  updatePackageJobs: (values: PackageJobs) =>
    requests.put<void>(`/PackageJobs/updatePackageJobs/${values.id}`, values),
  addPackageJobToTicketSession: (
    packageJobId: string,
    ticketSessionId: string
  ) =>
    requests.put<void>(
      `/PackageJobs/addPackageJobToTicketSession/${packageJobId}/${ticketSessionId}`,
      {}
    ),
};
