import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../app/stores/store";
import { TicketType } from "../../../../app/models/enums";
import { format, parseISO } from "date-fns";
import { convertTicketTypeToString } from "../../../../app/common/util/functions";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";

interface Props {
  showEstimateNumber: boolean;
  showPoNumber: boolean;
  isRepairOrder: boolean;
}

export default observer(function TicketDetailPdfView({
  showEstimateNumber,
  showPoNumber,
  isRepairOrder,
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  const { ticketSessionStore } = useStore();

  const { selectedTicketSession } = ticketSessionStore;

  return (
    <View
      style={{
        ...compose("view floatRight"),
        minWidth: `${isRepairOrder ? "44mm": "60mm"}`,
      }}
    >
      <View style={{alignItems: "flex-end",}}>
        {(selectedTicketSession?.ticketType === TicketType.Invoice ||
          showEstimateNumber) && (
          <View
            style={{
              ...compose(
                "view flex borderSolidTop borderSolidLeftRight borderSolidBottom floatRight noPadding noMargin"
              ),
              width: "44mm",
            }}
          >
            <View
              style={{
                ...compose(
                  "view floatRight metaHead noPadding padding1mmTop lineHeight padding5pxLeft padding5pxRight borderSolidRight textAlignRight"
                ),
                width: "20mm",
                maxWidth: "20mm",
              }}
            >
              <Text>
                {convertTicketTypeToString(
                  selectedTicketSession?.ticketType ?? 0
                )}{" "}
                #
              </Text>
            </View>

            <View
              style={{
                ...compose(
                  "view floatRight noPadding padding1mmTop noMarginLeft lineHeight padding5pxLeft padding5pxRight textAlignRight"
                ),
                width: "24mm",
                maxWidth: "24mm",
                marginRight: "0mm",
              }}
            >
              <Text>
                {selectedTicketSession &&
                  (selectedTicketSession?.ticketType === TicketType.Estimate
                    ? selectedTicketSession.estimateNumber
                    : selectedTicketSession.invoiceNumber)}
              </Text>
            </View>
          </View>
        )}

        {showPoNumber && (
          <View
            style={{
              ...compose(
                `view flex borderSolidLeftRight borderSolidBottom floatRight noPadding lineHeight ${
                  !showEstimateNumber &&
                  selectedTicketSession?.ticketType == TicketType.Estimate &&
                  "borderSolidTop"
                }`
              ),
              width: "44mm",
              padding: "0mm",
              margin: "0mm",
            }}
          >
            <View
              style={{
                ...compose(
                  "view floatRight metaHead noPadding padding1mmTop borderSolidBottom tableLayoutFixed padding5pxLeft padding5pxRight borderSolidRight textAlignRight"
                ),
                width: "20mm",
                maxWidth: "20mm",
                height: `${
                  !showEstimateNumber &&
                  selectedTicketSession?.ticketType == TicketType.Estimate
                    ? "6.7mm"
                    : "7mm"
                }`,
              }}
            >
              <Text>{t("po", { ns: "common" })}</Text>
            </View>
            <View
              style={{
                ...compose(
                  "view floatRight noPadding padding1mmTop lineHeight noMarginLeft padding5pxLeft padding5pxRight textAlignRight"
                ),
                width: "24mm",
                maxWidth: "24mm",
                marginRight: "0mm",
              }}
            >
              <Text>{selectedTicketSession?.poNumber}</Text>
            </View>
          </View>
        )}
        <View
          style={{
            ...compose(
              `view flex borderSolidLeftRight borderSolidBottom floatRight noPadding lineHeight ${
                !showEstimateNumber &&
                selectedTicketSession?.ticketType == TicketType.Estimate &&
                !showPoNumber &&
                "borderSolidTop"
              }`
            ),
            width: "44mm",
            padding: "0mm",
            margin: "0mm",
          }}
        >
          <View
            style={{
              ...compose(
                `view metaHead floatRight noPadding padding1mmTop borderSolidBottom padding5pxLeft padding5pxRight borderSolidRight textAlignRight`
              ),
              width: "20mm",
              maxWidth: "20mm",
              height: `${
                !showEstimateNumber &&
                selectedTicketSession?.ticketType == TicketType.Estimate &&
                !showPoNumber
                  ? "6.7mm"
                  : "7mm"
              }`,
            }}
          >
            <Text>{t("date", { ns: "common" })}</Text>
          </View>
          <View
            style={{
              ...compose(
                "view floatRight noPadding padding1mmTop lineHeight noMarginLeft padding5pxRight textAlignRight"
              ),
              width: "24mm",
              maxWidth: "24mm",
              marginRight: "0mm",
            }}
          >
            <Text>
              {selectedTicketSession &&
                format(
                  selectedTicketSession?.ticketType === TicketType.Estimate
                    ? selectedTicketSession.estimateDate
                      ? new Date(selectedTicketSession.estimateDate)
                      : new Date()
                    : selectedTicketSession.invoiceDate
                    ? new Date(selectedTicketSession.invoiceDate)
                    : new Date(),
                  "PP"
                )}
            </Text>
          </View>
        </View>
        <View
          style={{
            ...compose(
              "view flex borderSolidLeftRight borderSolidBottom floatRight noPadding lineHeight"
            ),
            width: "44mm",
            padding: "0mm",
            margin: "0mm",
          }}
        >
          <View
            style={{
              ...compose(
                "view floatRight metaHead noPadding borderSolidBottom padding1mmTop lineHeight padding5pxLeft padding5pxRight borderSolidRight textAlignRight"
              ),
              width: "20mm",
              maxWidth: "20mm",
            }}
          >
            <Text>{t("estimate.time", { ns: "tickets" })}</Text>
          </View>
          <View
            style={{
              ...compose(
                "view floatRight noPadding lineHeight padding5pxLeft noMarginLeft padding5pxRight textAlignRight padding1mmTop"
              ),
              width: "24mm",
              maxWidth: "24mm",
              marginRight: "0mm",
            }}
          >
            <Text>
              {selectedTicketSession &&
                format(
                  selectedTicketSession?.ticketType === TicketType.Estimate
                    ? selectedTicketSession.estimateDate
                      ? new Date(selectedTicketSession.estimateDate)
                      : new Date()
                    : selectedTicketSession.invoiceDate
                    ? new Date(selectedTicketSession.invoiceDate)
                    : new Date(),
                  "p"
                )}
            </Text>
          </View>
        </View>
        {selectedTicketSession?.ticketType === TicketType.Estimate && (
          <View
            style={{
              ...compose(
                "view flex borderSolidLeftRight borderSolidBottom floatRight noPadding lineHeight"
              ),
              width: "44mm",
              padding: "0mm",
              margin: "0mm",
            }}
          >
            <View
              style={{
                ...compose(
                  "view floatRight metaHead noPadding padding1mmTop borderSolidBottom lineHeight padding5pxLeft padding5pxRight borderSolidRight textAlignRight"
                ),
                width: "20mm",
                maxWidth: "20mm",
              }}
            >
              <Text>{t("estimate.promised", { ns: "tickets" })}</Text>
            </View>
            <View
              style={{
                ...compose(
                  "view floatRight noPadding lineHeight padding5pxRight noMarginLeft textAlignRight padding1mmTop"
                ),
                width: "24mm",
                maxWidth: "24mm",
                marginRight: "0mm",
              }}
            >
              <Text>
                {selectedTicketSession?.promisedDate &&
                  format(
                    parseISO(selectedTicketSession.promisedDate.toISOString()),
                    "PP"
                  )}
              </Text>
            </View>
          </View>
        )}
      </View>
    </View>
  );
});
