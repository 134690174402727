import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Button, Container, Form, Message, Segment } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useStore } from "../../../app/stores/store";
import { useTranslation } from "react-i18next";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { countryOptions } from "../../../app/common/options/countryOptions";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import ValidationErrors from "../../errors/ValidationErrors";
import { RegexConstants } from "../../../app/common/util/regexConstants";
import PageHeader from "../../../app/layout/PageHeader";
import { ReCaptcha } from "../../../app/common/form/ReCaptcha";
import MyIconTextInput from "../../../app/common/form/MyIconTextInput";
import StateSelect from "../../../app/common/form/StateSelect";
import { PhoneNumberType } from "../../../app/models/enums";

export default observer(function GettingStarted() {
  const { userStore, commonStore } = useStore();
  const { registrationValues, checkUsername, signUp, ipCheck } = userStore;
  const { loadScriptByURL } = commonStore;
  const { t } = useTranslation(["common", "registration", "users", "errors"]);

  const phoneRegExp = new RegExp(RegexConstants.phoneRegExp);
  const userNameExp = new RegExp(RegexConstants.userNameExp);
  const passwordExp = new RegExp(RegexConstants.passwordExp);
  const businessNameRegExp = new RegExp(RegexConstants.businessNameRegExp);

  useEffect(() => {
    loadScriptByURL(
      "recaptcha-key",
      `${process.env.REACT_APP_RECAPTCHA_URL}`,
      function () {
        console.log("Script loaded!");
      }
    );
  }, []);

  const validationSchema = Yup.object({
    clientName: Yup.string()
      .required(t("registration.required_ClientName", { ns: "errors" }))
      .matches(
        businessNameRegExp,
        t("registration.required_ClientName", { ns: "errors" })
      ),
    phoneNumber: Yup.string()
      .required(t("registration.required_PhoneNumber", { ns: "errors" }))
      .matches(
        phoneRegExp,
        t("registration.invalid_PhoneNumber", { ns: "errors" })
      ),
    username: Yup.string()
      .required(t("registration.required_UserName", { ns: "errors" }))
      .test(
        "test-username",
        t("username_taken", { ns: "errors" }),
        async (username) => {
          return checkUsername(username ?? "")
            .then(() => true)
            .catch(() => false);
        }
      )
      .matches(
        userNameExp,
        t("registration.invalid_UserName", { ns: "errors" })
      ),
    password: Yup.string()
      .required(t("registration.required_Password", { ns: "errors" }))
      .matches(
        passwordExp,
        t("registration.invalid_Password", { ns: "errors" })
      ),
    confirmPassword: Yup.string()
      .required(t("registration.required_Password", { ns: "errors" }))
      .when("password", {
        is: (val: string) => val && val.length > 0,
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("registration.required_PasswordMatch", { ns: "errors" })
        ),
      }),
    address1: Yup.string().required(
      t("registration.required_address", { ns: "errors" })
    ),
    city: Yup.string().required(
      t("registration.required_city", { ns: "errors" })
    ),
    state: Yup.string().required(
      t("registration.required_state", { ns: "errors" })
    ),
    postalCode: Yup.string().required(
      t("registration.required_postalcode", { ns: "errors" })
    ),
    country: Yup.string().required(
      t("registration.required_country", { ns: "errors" })
    ),
  });

  return (
    <>
      <PageHeader
        header={t("gettingStared", { ns: "registration" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container text>
        <Segment textAlign="left" className="form-background-color">
          <ReCaptcha action="signup">
            {(captcha) => (
              <Formik
                initialValues={registrationValues}
                onSubmit={async (values, { setErrors, resetForm }) => {
                  const token = await captcha.execute();
                  ipCheck().then((ip) => {
                    values.ipAddress = ip.ip;
                    values.location = ip.country_code;
                    signUp(values, token, ip.ip)
                      .then(() => {
                        window.dataLayer.push("event", "conversion", {
                          send_to: "AW-715275038/RsJLCNvFl94ZEJ72iNUC",
                        });
                        resetForm();
                      })
                      .catch((error) => {
                        setErrors({ error: error });
                      });
                  });
                }}
                validationSchema={validationSchema}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  errors,
                  isValid,
                  dirty,
                  setFieldError,
                }) => (
                  <Form
                    className="ui form error"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    <ErrorMessage
                      name="error"
                      render={() => <ValidationErrors errors={errors.error} />}
                    />
                    <MyIconTextInput
                      name="username"
                      placeholder={t("username", { ns: "common" })}
                      label={`${t("username", { ns: "common" })}:`}
                      icon={""}
                      maxLength={255}
                    />
                    <MyTextInput
                      name="password"
                      placeholder={t("password", { ns: "common" })}
                      label={`${t("password", { ns: "common" })}:`}
                      type="password"
                      autoCapitalizeFirstLetter={false}
                      maxLength={50}
                    />
                    <MyTextInput
                      name="confirmPassword"
                      placeholder={t("password.confirm", { ns: "users" })}
                      label={`${t("password.confirm", { ns: "users" })}:`}
                      type="password"
                      autoCapitalizeFirstLetter={false}
                      maxLength={50}
                    />
                    <Message info>
                      {t("invoiceaddressmessage", { ns: "registration" })}
                    </Message>
                    <PageHeader
                      header={t("enterbusinessinfo", { ns: "registration" })}
                      type={"h3"}
                      divider={false}
                      addTitle={false}
                    />
                    <MyTextInput
                      name="clientName"
                      placeholder={t("companyname", { ns: "common" })}
                      label={`${t("companyname", { ns: "common" })}:`}
                      autoCapitalizeFirstLetter={false}
                      maxLength={500}
                    />
                    <MyTextInput
                      name="address1"
                      placeholder={t("address.address", { ns: "common" })}
                      label={`${t("address.address", { ns: "common" })}:`}
                      autoCapitalizeFirstLetter={false}
                      maxLength={500}
                    />
                    <MyTextInput
                      name="address2"
                      placeholder={t("address.address2", { ns: "common" })}
                      label={`${t("address.address2", { ns: "common" })}:`}
                      autoCapitalizeFirstLetter={false}
                      maxLength={500}
                    />
                    <MyTextInput
                      name="city"
                      placeholder={t("address.city", { ns: "common" })}
                      label={`${t("address.city", { ns: "common" })}:`}
                      autoCapitalizeFirstLetter={false}
                      maxLength={255}
                    />
                    <StateSelect
                      name="state"
                      placeholder={t("address.state", { ns: "common" })}
                      label={`${t("address.state", { ns: "common" })}:`}
                    />
                    <MyTextInput
                      name="postalCode"
                      placeholder={t("address.postalcode", { ns: "common" })}
                      label={`${t("address.postalcode", { ns: "common" })}:`}
                      autoCapitalizeFirstLetter={false}
                      maxLength={50}
                    />
                    <MySelectInput
                      name="country"
                      placeholder={t("address.country", { ns: "common" })}
                      label={`${t("address.country", { ns: "common" })}:`}
                      options={countryOptions}
                      clearable={false}
                    />

                    <PhoneNumberInput
                      name="phoneNumber"
                      placeholder={t("phonenumber", { ns: "common" })}
                      label={`${t("phonenumber", { ns: "common" })}:`}
                      autoFormat={true}
                      phoneType={PhoneNumberType.Landline}
                    ></PhoneNumberInput>
                    <PhoneNumberInput
                      name="faxNumber"
                      placeholder={t("fax", { ns: "common" })}
                      label={`${t("fax", { ns: "common" })}:`}
                      autoFormat={true}
                      phoneType={PhoneNumberType.Fax}
                    ></PhoneNumberInput>

                    <Button
                      disabled={
                        !dirty || isSubmitting || !isValid || !captcha.isReady
                      }
                      loading={isSubmitting}
                      icon={"thumbs up"}
                      color={"green"}
                      positive
                      content={t("signup", { ns: "common" })}
                      type="submit"
                      //fluid
                    />
                  </Form>
                )}
              </Formik>
            )}
          </ReCaptcha>
        </Segment>
      </Container>
    </>
  );
});
