import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Container, Segment } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useStore } from "../../../app/stores/store";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ValidationErrors from "../../errors/ValidationErrors";
import * as Yup from "yup";
import PageHeader from "../../../app/layout/PageHeader";
import { RegexConstants } from "../../../app/common/util/regexConstants";
interface Props {
  isChange: boolean;
}

export default observer(function ChangePassword({ isChange }: Props) {
  const { id } = useParams<{ id: string }>();
  const { token } = useParams<{ token: string }>();
  const { userStore } = useStore();

  const { user, changePassword, initialRedirects, getUser} = userStore;
  const { t } = useTranslation(["common", "users"]);

  const passwordExp = new RegExp(RegexConstants.passwordExp);

  const initialSchema = {
    id: id,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    token: token,
    isChangePassword: isChange,
    error: undefined,
    forcePasswordReset: user?.forcePasswordReset ?? false,
  };

  return (
    <>
      <PageHeader
        header={
          isChange
            ? t("password.change", { ns: "users" })
            : t("password.reset_password", { ns: "users" })
        }
        type={"h1"}
        divider={true}
        addTitle={true}
      />
      <Container text>
        <Segment className="form-background-color">
          <p>{t("password.change_message", { ns: "users" })}</p>
          <Formik
            initialValues={initialSchema}
            onSubmit={(values, { setErrors }) =>
                changePassword(values, isChange, id, token)
                .then(() => {
                  getUser().then(()=>{initialRedirects();})
                  
                })
                .catch((error) => setErrors({ error: error }))
            }
            validationSchema={Yup.object({
              isChangePassword: Yup.boolean(),
              currentPassword: Yup.string().when("isChangePassword", {
                is: true,
                then: Yup.string().required(
                  t("registration.required_Password", { ns: "errors" })
                ),
                otherwise: Yup.string(),
              }),
              newPassword: Yup.string()
                .required(t("registration.required_Password", { ns: "errors" }))
                .matches(
                  passwordExp,
                  t("registration.invalid_Password", { ns: "errors" })
                ),
              confirmPassword: Yup.string()
                .required(t("registration.required_Password", { ns: "errors" }))
                .when("newPassword", {
                  is: (val: string) => val && val.length > 0,
                  then: Yup.string().oneOf(
                    [Yup.ref("newPassword")],
                    t("registration.required_PasswordMatch", { ns: "errors" })
                  ),
                }),
            })}
          >
            {({ handleSubmit, isSubmitting, errors }) => (
              <Form
                className="ui form error form-background-color"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
                {isChange && (
                  <MyTextInput
                    name="currentPassword"
                    placeholder={t("password.current", { ns: "users" })}
                    label={`${t("password.current", { ns: "users" })}:`}
                    type="password"
                    autoCapitalizeFirstLetter={false}
                    maxLength={50}
                  />
                )}
                <MyTextInput
                  name="newPassword"
                  placeholder={t("password.new", { ns: "users" })}
                  label={`${t("password.new", { ns: "users" })}:`}
                  type="password"
                  autoCapitalizeFirstLetter={false}
                  maxLength={50}
                />
                <MyTextInput
                  name="confirmPassword"
                  placeholder={t("password.confirm", { ns: "users" })}
                  label={`${t("password.confirm", { ns: "users" })}:`}
                  type="password"
                  autoCapitalizeFirstLetter={false}
                  maxLength={50}
                />
                <Button
                  loading={isSubmitting}
                  color="green"
                  size="huge"
                  positive
                  content={t("password.update", { ns: "users" })}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </Segment>
      </Container>
    </>
  );
});
