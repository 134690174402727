import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { SettingsMakeList } from "../models/makeList";
import { sortingStrings } from "../common/util/functions";

export default class MakeListStore {
  loadingInitial = false;
  activeTab: number = 0;

  settingsMakeListRegistry = new Map<number | undefined, SettingsMakeList>();

  isAllDisabled: boolean = false;

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
        this.settingsMakeListRegistry.clear();
        this.loadSettingsMakeList();
      }
    );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  resetSettingsMakeListRegistry = () => {
    this.settingsMakeListRegistry.clear();
  };

  loadSettingsMakeList = async () => {
    this.loadingInitial = true;
    try {
      this.isAllDisabled = true;
      this.settingsMakeListRegistry.clear();
      const result = await agent.makeList.listSettingsMakeFilters();
      runInAction(() => {
        result.forEach((settingsMakeList) => {
          if (!settingsMakeList.isDisabled) this.isAllDisabled = false;
          this.setSettingsMakeList(settingsMakeList);
        });
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  get getMakeLists() {
    return Array.from(this.settingsMakeListRegistry.values()).sort((a, b) => {
      return (
        sortingStrings(a.makeName.toLowerCase(), b.makeName.toLowerCase()) ?? 0
      );
    });
  }

  toggleSettingsMakeFilter = async (id: number) => {
    try {
      await agent.makeList.toggleSettingsMakeFilter(id);
      runInAction(() => {
        let makeFilter = this.getSettingsMakeList(id);
        if (makeFilter) {
          makeFilter.isDisabled = !makeFilter.isDisabled;
          this.settingsMakeListRegistry.set(makeFilter.makeId, makeFilter);
          if (this.isAllDisabled && !makeFilter.isDisabled)
            this.isAllDisabled = false;
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  toggleBatchSettingsMakeFilter = async (isAllDisabled: boolean) => {
    try {
      await agent.makeList.toggleBatchSettingsMakeFilter(isAllDisabled);
      runInAction(() => {
        this.settingsMakeListRegistry.clear();
        this.isAllDisabled = isAllDisabled;
      });
    } catch (error) {
      console.log(error);
    }
  };

  private getSettingsMakeList = (id: number) => {
    return this.settingsMakeListRegistry.get(id);
  };

  private setSettingsMakeList = (item: SettingsMakeList) => {
    this.settingsMakeListRegistry.set(item.makeId, item);
  };
}
