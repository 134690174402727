import { makeAutoObservable } from "mobx";
import React from "react";

interface Modal {
  open: boolean;
  body: React.JSX.Element | null;
  className: string | null;
  autoDim: boolean;
}

export default class ModalStore {
  modal: Modal = {
    open: false,
    body: null,
    className: null,
    autoDim: true,
  };

  constructor() {
    makeAutoObservable(this);
  }

  openWidestModal = (content: React.JSX.Element) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.className = "widestModal";
  };

  openWiderModal = (content: React.JSX.Element) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.className = "widerModal";
  };

  openTallModal = (content: React.JSX.Element) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.className = "tallModal";
  };

  openModal = (content: React.JSX.Element, autoDim?: boolean) => {
    this.modal.open = true;
    this.modal.body = content;
    this.modal.className = "";
    if (autoDim) this.modal.autoDim = autoDim;
  };

  closeModal = () => {
    this.modal.open = false;
    this.modal.body = null;
  };
}
