import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import { DeletedTickets } from "../../models/systemAdmin";

export default class TicketDataRecoveryStore {
  constructor() {
    makeAutoObservable(this);
  }

  loading = false;

  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  setLoading = (state: boolean) => {
    this.loading = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    if (value) this.predicate.set(predicate, value);
    else this.predicate.delete(predicate);
  };

  clearFilters = () => {
    this.predicate.delete('filterType');
    this.predicate.delete('filterValue');
  }

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());

    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
  DeletedTicketsRegistry = new Map<string, DeletedTickets>();

  restoreTicket = async (id: string) => {
    try {
      await agent.SystemAdminTools.restoreTicket(id);
      this.DeletedTicketsRegistry.clear();
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  loadDeletedTicket = async (id: string) => {
    this.setLoading(true);
    this.DeletedTicketsRegistry.clear();
    try {
      const result = await agent.SystemAdminTools.getDeletedTickets(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((x) => {
          this.setDeletedTickets(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };
  private setDeletedTickets = (deletedTickets: DeletedTickets) => {
    deletedTickets.deletedDate = new Date(deletedTickets.deletedDate);
    deletedTickets.date = new Date(deletedTickets.date);
    this.DeletedTicketsRegistry.set(deletedTickets.id, deletedTickets);
  };

  get getDeletedTickets() {
    return Array.from(this.DeletedTicketsRegistry.values()).sort(
      (a, b) => b.date.getTime() - a.date.getTime()
    );
  }
}
