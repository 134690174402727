import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";

export default observer(function ActiveCompanyStatusesComponent() {
  const { t } = useTranslation(["common", "companySettings"]);

  const { statsStore } = useStore();

  const { statsOverview, loadStatsOverview } = statsStore;

  useEffect(() => {
    if (!statsOverview) {
      loadStatsOverview();
    }
  }, [statsOverview, loadStatsOverview]);

  return (
    <Table singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Service Type</Table.HeaderCell>
          <Table.HeaderCell>Deactivated</Table.HeaderCell>
          <Table.HeaderCell>Trial Accounts</Table.HeaderCell>
          <Table.HeaderCell>Trial Extended</Table.HeaderCell>
          <Table.HeaderCell>Sub Active</Table.HeaderCell>
          <Table.HeaderCell>Sub Extended</Table.HeaderCell>
          <Table.HeaderCell>After Sub Trial</Table.HeaderCell>
          <Table.HeaderCell>Active Total</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <b>No Service</b>
          </Table.Cell>
          <Table.Cell>{statsOverview?.noServiceDeactivated}</Table.Cell>
          <Table.Cell>{statsOverview?.noServiceTrial}</Table.Cell>
          <Table.Cell>{statsOverview?.noServiceTrialExtended}</Table.Cell>
          <Table.Cell>0</Table.Cell>
          <Table.Cell>0</Table.Cell>
          <Table.Cell>0</Table.Cell>
          <Table.Cell>
            {statsOverview ? statsOverview.noServiceTrial : 0}
          </Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Basic</b>
          </Table.Cell>
          <Table.Cell>{statsOverview?.basicDeactivated}</Table.Cell>
          <Table.Cell>{statsOverview?.basicTrial}</Table.Cell>
          <Table.Cell>{statsOverview?.basicTrialExtended}</Table.Cell>
          <Table.Cell>{statsOverview?.basicActive}</Table.Cell>
          <Table.Cell>{statsOverview?.basicExtended}</Table.Cell>
          <Table.Cell>{statsOverview?.basicAfterTrial}</Table.Cell>
          <Table.Cell>{statsOverview?.clientBasicTotal}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell>
            <b>Pro</b>
          </Table.Cell>
          <Table.Cell>{statsOverview?.proDeactivated}</Table.Cell>
          <Table.Cell>{statsOverview?.proTrial}</Table.Cell>
          <Table.Cell>{statsOverview?.proTrialExtended}</Table.Cell>
          <Table.Cell>{statsOverview?.proActive}</Table.Cell>
          <Table.Cell>{statsOverview?.proExtended}</Table.Cell>
          <Table.Cell>{statsOverview?.proAfterTrial}</Table.Cell>
          <Table.Cell>{statsOverview?.clientProTotal}</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell></Table.Cell>
          <Table.Cell>
            {(statsOverview ? statsOverview.basicDeactivated : 0) +
              (statsOverview ? statsOverview.proDeactivated : 0) +
              (statsOverview ? statsOverview.noServiceDeactivated : 0)}
          </Table.Cell>
          <Table.Cell>
            {(statsOverview ? statsOverview.noServiceTrial : 0) +
              (statsOverview ? statsOverview.basicTrial : 0) +
              (statsOverview ? statsOverview.proTrial : 0)}
          </Table.Cell>
          <Table.Cell>
            {(statsOverview ? statsOverview.noServiceTrialExtended : 0) +
              (statsOverview ? statsOverview.basicTrialExtended : 0) +
              (statsOverview ? statsOverview.proTrialExtended : 0)}
          </Table.Cell>
          <Table.Cell>
            {(statsOverview ? statsOverview.basicActive : 0) +
              (statsOverview ? statsOverview.proActive : 0)}
          </Table.Cell>
          <Table.Cell>
            {(statsOverview ? statsOverview.basicExtended : 0) +
              (statsOverview ? statsOverview.proExtended : 0)}
          </Table.Cell>
          <Table.Cell>
            {(statsOverview ? statsOverview.basicAfterTrial : 0) +
              (statsOverview ? statsOverview.proAfterTrial : 0)}
          </Table.Cell>
          <Table.Cell>
            {(statsOverview ? statsOverview.clientProTotal : 0) +
              (statsOverview ? statsOverview.clientBasicTotal : 0) +
              (statsOverview ? statsOverview.noServiceTrial : 0)}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
});
