import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Button, Form } from "semantic-ui-react";
import PageHeader from "../../../app/layout/PageHeader";
import MyDateTimeInput from "../../../app/common/form/MyDateTimeInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import MySelectInput from "../../../app/common/form/MySelectInput";
import { colorOptions } from "../../../app/common/options/option";
import { useStore } from "../../../app/stores/store";
import { Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import { SystemAdminAnnouncement } from "../../../app/models/admin";
import { useNavigate, useParams } from "react-router-dom";
import MyCheckbox from "../../../app/common/form/MyCheckbox";

export default observer(function UpdateAnnouncement() {
  const navigate = useNavigate();
  const { announcementStore } = useStore();
  const {
    loadAnnouncement,
    updateAnnouncement,
    announcement,
    createAnnouncement,
  } = announcementStore;

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!isNaN(parseInt(id ?? ""))) {
      loadAnnouncement(parseInt(id ?? ""))
    }
  }, [id, loadAnnouncement]);

  function handleFormSubmit(
    values: SystemAdminAnnouncement,
    setErrors: (errors: FormikErrors<SystemAdminAnnouncement>) => void
  ) {

    const normalizedValues: SystemAdminAnnouncement = {
      ...values,
      startDate: values.startDate ? new Date(values.startDate) : undefined,
      endDate: values.endDate ? new Date(values.endDate) : undefined,
    };

    const action = values.id
      ? () => updateAnnouncement(normalizedValues)
      : () => createAnnouncement(normalizedValues);

    action()
      .then(() => {
        if (id) navigate(`/admin/ViewAnnouncement/${id}`);
        else navigate(`/admin/Tools/ManageAnnouncements`);
      })
      .catch((error) => {
        console.log(error);
        //setErrors({ error: error });
      });
  }

  const validationSchema = Yup.object({
    isActive: Yup.boolean().required("Active state is required."),
    color: Yup.string().required("Message Color is required."),
  });

  return (
    <>
      <PageHeader
        header="Add or Update Announcement"
        type={"h1"}
        divider={true}
        addTitle={false}
      />

      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={id ? announcement : new SystemAdminAnnouncement()}
        onSubmit={(values, { setErrors }) =>
          handleFormSubmit(values, setErrors)
        }
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
            {/* <MySelectInput
            label="Area: "
              placeholder={"Area"}
              name={"announcementType"}
              options={areaOption}
            /> */}
            <MyCheckbox 
              name="isActive" 
              label="Is Active?"
            />

            <MyDateTimeInput label={"Start Date: "} name={"startDate"} setFieldValue={setFieldValue} />
            <MyDateTimeInput label={"End Date: "} name={"endDate"} setFieldValue={setFieldValue} />
            <MySelectInput
              label={"Color Options: "}
              placeholder={"Color"}
              name={"color"}
              options={colorOptions}
              clearable={true}
            />
            <MyTextArea
              label={"Header: "}
              placeholder={"Header"}
              name={"header"}
              rows={2}
              maxLength={255}
            />
            <MyTextArea
              label={"Body: "}
              placeholder={"Body"}
              name={"body"}
              rows={5}
              maxLength={2000}
            />
            <Button
              className="save_button"
              icon="check"
              disabled={isSubmitting || !dirty || !isValid}
              loading={isSubmitting}
              content={"Submit"}
              color={"green"}
              type="submit"
            />
            <Button
              icon="cancel"
              className="save_button"
              type="reset"
              content={"Cancel"}
              onClick={() => navigate(`/admin/Tools/ManageAnnouncements`)}
            />
          </Form>
        )}
      </Formik>
    </>
  );
});
