import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Header,
  Segment,
  Button,
  Grid,
  Message,
} from "semantic-ui-react";
import { useStore } from "../../app/stores/store";
import LoginForm from "../users/LoginForm";
import "../../app/common/i18n/i18n";
import { useTranslation } from "react-i18next";
import PageHeader from "../../app/layout/PageHeader";

export default observer(function HomePage() {
  const { userStore, announcementStore, commonStore } = useStore();
  const { t } = useTranslation(["common", "registrtion", "users"]);

  const { loadCurrentAnnouncement, announcement, currentAnnouncement } =
    announcementStore;

  useEffect(() => {
    loadCurrentAnnouncement();
  }, [loadCurrentAnnouncement]);

  useEffect(() => {
    if (userStore.isLoggedIn) userStore.initialRedirects();
  }, []);

  return (
    <Segment textAlign="center" vertical className="masthead">
      <Container text>
        {userStore.isLoggedIn && <>Welcome to Real-Time Labor Guide.</>}
        {!userStore.isLoggedIn && (
          <>
            <Grid stackable>
              {currentAnnouncement.length > 0 &&
                currentAnnouncement.map((announcement, index) => (
                  <Grid.Row>
                    <Message
                      color={announcement.color}
                      key={`announcement.${index}`}
                    >
                      <Message.Header>{announcement.header}</Message.Header>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: announcement.body,
                        }}
                      />
                    </Message>
                  </Grid.Row>
                ))}

              <Grid.Row columns={2}>
                <Grid.Column>
                  <Segment className="form-background-color">
                    <LoginForm />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment className="fill-height form-background-color">
                    <PageHeader
                      type="h2"
                      header={t("header", { ns: "registration" })}
                      className="modal-text-color"
                      textAlign="center"
                      divider={false}
                      addTitle={true}
                    />

                    <Button as={Link} to="/registration" size="huge" positive>
                      {t("register", { ns: "common" })}
                    </Button>
                    <p>{t("free_trial", { ns: "registration" })}</p>
                    <a href="https://www.autolaborexperts.com/">
                      {t("learnMore", { ns: "help" })}
                    </a>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </>
        )}
      </Container>
    </Segment>
  );
});
