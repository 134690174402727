import axios, { AxiosResponse } from "axios";
import { requests } from "./Requests";
import { CustomVehicle, CustomVehicleNoteFormValues } from "../models/customvehicles";
import { PaginatedResult } from "../models/pagination";
import { DropdownItemProps } from "semantic-ui-react";
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export const customVehicles = {
  listCustomVehicle: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<CustomVehicle[]>>(
        `/CustomVehicle/listCustomVehicle`,
        { params }
      )
      .then(responseBody),
  addCustomVehicle: (values: CustomVehicle) =>
    requests.post<void>("/CustomVehicle/addCustomVehicle", values),
  removeCustomVehicle: (id: string | undefined) =>
    requests.put<void>(`/CustomVehicle/removeCustomVehicle/${id}`, {}),
  updateCustomVehicle: (values: CustomVehicle) =>
    requests.put<void>(
      `/CustomVehicle/updateCustomVehicle/${values.id}`,
      values
    ),
  listCustomVehicleYears: (id: string | undefined ) =>
    requests.get<DropdownItemProps[]>(`/CustomVehicle/ListCustomVehicleYears/${id}`),
  upsertVehicleNote: (values: CustomVehicleNoteFormValues) =>
    requests.put<void>(
      `/CustomVehicle/UpsertCustomVehicleNotes/${values.id}`,
      values
    ),
};
