import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../../api/agent";
import { Pagination, PagingParams } from "../../../models/pagination";
import { SignUpReportView, StatsOverview } from "../../../models/systemAdmin";
import { addMonths, format, isSameMonth, subMonths } from "date-fns";
import { getTimePeriodInMonths } from "../../../common/util/functions";

export default class StatsOverviewStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    if (value)
      this.predicate.set(predicate, value);
    else
      this.predicate.delete(predicate);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
  loading = false;
  statsOverview: StatsOverview | undefined = undefined;
  statsCompare: StatsOverview | undefined = undefined;
  signupStats: SignUpReportView[] | undefined = undefined;
  timePeriodInDays: number = 30;

  setLoading = (state: boolean) => {
    this.loading = state;
  };

  loadStatsOverview = async () => {
    if (this.loading) {
      return;
    }

    try {
      this.setLoading(true);
      this.clearStatsOverview();
      const result = await agent.SystemAdminReports.getStatsOverview();
      runInAction(() => {
        this.setStatsOverview(result);
        this.setLoading(false);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  loadSignupStatsOverview = async () => {
    try {
      this.setLoading(true);
      this.clearSignupStatsOverview();
      const result = await agent.SystemAdminReports.getSignupReportStats(this.axiosParams);
      runInAction(() => {
        this.setSignupStatsOverview(result);
        this.setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  isExpectedSignupStatus = (itm: SignUpReportView, status: string, monthToDisplay: Date) => {
    var itmDate = new Date(itm.date);

    if(isSameMonth(new Date(), monthToDisplay))
      return itm.status == status && itmDate > subMonths(monthToDisplay, 1) && itmDate <= monthToDisplay
    else
     return itm.status == status && itmDate > monthToDisplay && itmDate <= addMonths(monthToDisplay, 1)
  }

  get getGeneralSignupStats() {

    return getTimePeriodInMonths(new Date(), this.timePeriodInDays).map((mD, idx, arr) => ({
      emailConfirmed: (this.signupStats ?? []).filter((itm: SignUpReportView) => this.isExpectedSignupStatus(itm, "Email Confirmed", mD))
        .reduce((total: number, itm: SignUpReportView) => total += itm.companyCount, 0),
      companyWaitingForReview: (this.signupStats ?? []).filter((itm: SignUpReportView) => this.isExpectedSignupStatus(itm, "Client Pending Review", mD))
        .reduce((total: number, itm: SignUpReportView) => total += itm.companyCount, 0),
      companyApprovedActive: (this.signupStats ?? []).filter((itm: SignUpReportView) => this.isExpectedSignupStatus(itm, "Client Approved with Applications", mD))
        .reduce((total: number, itm: SignUpReportView) => total += itm.companyCount, 0),
      companyApprovedNoLogin: (this.signupStats ?? []).filter((itm: SignUpReportView) => this.isExpectedSignupStatus(itm, "Client Approved but No Login", mD))
        .reduce((total: number, itm: SignUpReportView) => total += itm.companyCount, 0),
      date: format(mD, 'MM/dd/yyyy')
    }));
  }

  get getCompanyApprovalStats() {

    return getTimePeriodInMonths(new Date(), this.timePeriodInDays).map(mD => ({

      companyDeclined: (this.signupStats ?? []).filter((itm: SignUpReportView) => this.isExpectedSignupStatus(itm, "Client Declined", mD))
        .reduce((total: number, itm: SignUpReportView) => total += itm.companyCount, 0),

      companyApproved: (this.signupStats ?? []).filter((itm: SignUpReportView) => this.isExpectedSignupStatus(itm, "Client Approved with Applications", mD))
        .reduce((total: number, itm: SignUpReportView) => total += itm.companyCount, 0),
      date: format(mD, 'MM/dd/yyyy')
    }));
  }

  get getCompanySubscribedStats() {

    return getTimePeriodInMonths(new Date(), this.timePeriodInDays).map(mD => ({

      companySubscribed: (this.signupStats ?? []).filter((itm: SignUpReportView) => this.isExpectedSignupStatus(itm, "Client Subscribed", mD))
        .reduce((total: number, itm: SignUpReportView) => total += itm.companyCount, 0),

      companyUsersSubscribed: (this.signupStats ?? []).filter((itm: SignUpReportView) => this.isExpectedSignupStatus(itm, "Client User Subscribed", mD))
        .reduce((total: number, itm: SignUpReportView) => total += itm.companyCount, 0),
      date: format(mD, 'MM/dd/yyyy')
    }));
  }

  loadStatsCompare = async () => {
    try {
      this.setLoading(true);
      const result = await agent.SystemAdminReports.loadStatsCompare(this.axiosParams);
      runInAction(() => {
        this.setStatsCompare(result);
        this.setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  setStatsOverview = (value: StatsOverview | undefined) => {
    if (value) {
      value.lastCronRun = new Date(value.lastCronRun);
    }
    this.statsOverview = value;
  };

  clearStatsOverview = () => {
    this.statsOverview = undefined;
  };


  setStatsCompare = (value: StatsOverview | undefined) => {
    if (value) {
      value.lastCronRun = new Date(value.lastCronRun);
    }
    this.statsCompare = value;
  };

  clearStatsCompare = () => {
    this.statsCompare = undefined;
  };

  setSignupStatsOverview = (value: SignUpReportView[] | undefined) => {
    this.signupStats = value;
  };

  clearSignupStatsOverview = () => {
    this.signupStats = undefined;
  };

  setTimePeriodInDays = (timePeriodInDays: number) => {
    this.timePeriodInDays = timePeriodInDays;
  }
}
