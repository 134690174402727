import { requests } from "./Requests";
import { ClientDetails, Logo } from "../models/client";
import axios from "axios";

export const Client = {
  getLogo: () => requests.get<Logo>(`/Client/getLogo/`),
  updateLogo: (file: any) => {
    let formData = new FormData();
    formData.append('File', file);
    return axios.post<void>(`/Client/updateLogo`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
  })},
  removeLogo: () => requests.put<void>(`/Client/removeLogo`, {}),
  getClientDetails: () =>
    requests.get<ClientDetails>(`/Client/getClientDetails/`),
  updateClientDetails: (values: ClientDetails) =>
    requests.put<void>(`/Client/updateClientDetails/`, values),
};
