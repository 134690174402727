import { observer } from "mobx-react-lite";
import { Button, Table } from "semantic-ui-react";
import { VehicleSearch } from "../../app/models/search";
import { formatPhoneNumber } from "../../app/common/util/functions";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CustomerTicketSearch from "./CustomerTicketSearch";
import { v4 as uuid } from "uuid";
import { useStore } from "../../app/stores/store";
import VinDisplay from "../../app/common/form/VinDisplay";
import { resetTicket } from "../../app/common/util/ticketfunctions";

interface Props {
  vehicle: VehicleSearch;
}

export default observer(function VehicleSearch({ vehicle }: Props) {
  const navigate = useNavigate();
  const { t } = useTranslation(["common", "search"]);
  const { ticketSessionStore } = useStore();

  const { createCustomerTicket } = ticketSessionStore;
  const [expand, setExpand] = useState<boolean>(false);

  const handleExpand = () => {
    setExpand(!expand);
  };

  return (
    <>
      <Table.Row verticalAlign="top">
        <Table.Header></Table.Header>
        <Table.Cell verticalAlign="bottom" className="noTextAlignMobile">
          <div className="intro">
            {`${vehicle.year ?? ""} ${vehicle.make ?? ""} ${
              vehicle.model ?? ""
            }`}
          </div>
          {vehicle.businessName && `${vehicle.businessName}: `}
          {vehicle.lastName}
          {vehicle.firstName && `, ${vehicle.firstName}`} <br />
          {`${t("tag", { ns: "common" })}: ${vehicle.tag ? vehicle.tag : "--"}`}
        </Table.Cell>
        <Table.Cell verticalAlign="bottom" className="noTextAlignMobile">
          <br />
          {`${
            vehicle.isCommercial
              ? t("main", { ns: "customer" })
              : t("home", { ns: "common" })
          }: `}
          {vehicle.primaryPhone
            ? formatPhoneNumber(vehicle.primaryPhone)
            : "--"}
          <br /> {t("vin", { ns: "recentTickets" })}:{" "}
          {vehicle.vin ? <VinDisplay vin={vehicle.vin} /> : "--"}
        </Table.Cell>
        <Table.Cell verticalAlign="bottom" className="noTextAlignMobile">
          <br />
          {t("mileage", { ns: "common" })}:{" "}
          {vehicle.mileageIn ? vehicle.mileageIn : "--"} <br />
          {t("veh", { ns: "recentTickets" })}:{" "}
          {vehicle.vehicleNumber ? vehicle.vehicleNumber : "--"}
        </Table.Cell>
        <Table.Cell textAlign="right" width={8} className="noTextAlignMobile">
          <Button
            color="blue"
            size="small"
            icon="file alternate outline"
            content={t("see_tickets", { ns: "common" })}
            onClick={handleExpand}
          />
          <Button
            color="green"
            size="small"
            icon="plus"
            content={t("ticket_create", { ns: "common" })}
            onClick={() => {
              if (vehicle.customerId && vehicle.id) {
                resetTicket();
                let ticketId = uuid();
                createCustomerTicket(
                  vehicle.customerId,
                  ticketId,
                  vehicle.id
                ).then(() => {
                  navigate(`/ticket/jobCategory/${ticketId}/false`);
                });
              }
            }}
          />
          <Button
            size="small"
            icon="address card"
            content={t("customerdetails", { ns: "common" })}
            onClick={(e) => navigate(`/customer/${vehicle.customerId}`)}
          />
        </Table.Cell>
      </Table.Row>

      {expand && <CustomerTicketSearch id={vehicle.id} />}
    </>
  );
});
