import { ErrorMessage, Form, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { store, useStore } from "../../../app/stores/store";
import { SettingsPayTypeFormValues } from "../../../app/models/pricing";
import PageHeader from "../../../app/layout/PageHeader";
import ValidationErrors from "../../errors/ValidationErrors";

interface Props {
  name: string;
  header: string;
  isAdd: boolean;
  autoCapitalizeFirstLetter: boolean;
}

export default observer(function AddPayType(props: Props) {
  const { t } = useTranslation(["common", "pricingSettings", "errors"]);
  const { pricingStore } = useStore();
  const { createSettingsPayType, selectedPayType, updateSettingsPayType } =
    pricingStore;

  const validationSchema = Yup.object({
    name: Yup.string()
      .max(50, t("pricing.payTypeName_length", { ns: "errors" }))
      .required(t("pricing.payTypeName_required", { ns: "errors" })),
  });

  const [payType] = useState<SettingsPayTypeFormValues>(
    props.isAdd ? new SettingsPayTypeFormValues() : selectedPayType
  );

  function handleFormSubmit(
    values: SettingsPayTypeFormValues,
    setErrors: (errors: FormikErrors<SettingsPayTypeFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsPayType(values)
      : () => createSettingsPayType(values);

    action()
      .then(() => {
        setSubmitting(false);
        store.modalStore.closeModal();
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  return (
    <Formik
      initialValues={payType}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, { setErrors, setSubmitting }) =>
        handleFormSubmit(values, setErrors, setSubmitting)
      }
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        dirty,
        handleBlur,
        handleChange,
        errors,
        values,
        touched,
      }) => (
        <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
          <PageHeader
            header={props.header}
            type={"h3"}
            divider={true}
            getAlerts={true}
            addTitle={false}
          />
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <MyTextInput
            placeholder={t("pricing.payTypeName", {
              ns: "pricingSettings",
            })}
            name={"name"}
            autoFocus={true}
            className="required"
            label={t("pricing.payTypeName", {
              ns: "pricingSettings",
            })}
            showHelp={true}
            helpName="settings.pricing.payTypeAdd"
            autoCapitalizeFirstLetter={props.autoCapitalizeFirstLetter}
            maxLength={255}
          ></MyTextInput>
          <Button
            color="green"
            className="save_button"
            icon="check"
            content={t("save", { ns: "common" })}
            disabled={isSubmitting || !dirty || !isValid}
            loading={isSubmitting}
            type="submit"
          />
          <Button
            className="save_button"
            onClick={() => store.modalStore.closeModal()}
            icon="cancel"
            type="reset"
            content={t("cancel", { ns: "common" })}
          />
        </Form>
      )}
    </Formik>
  );
});
