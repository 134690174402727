import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, Container, Message, Segment } from "semantic-ui-react";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { useStore } from "../../../app/stores/store";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import PageHeader from "../../../app/layout/PageHeader";
import ValidationErrors from "../../errors/ValidationErrors";

export default observer(function ForgotPassword() {
  const { userStore } = useStore();
  const { t } = useTranslation(["common", "users", "errors", "alerts"]);

  const [isComplete, setIsComplete] = useState<boolean>(false);

  return (
    <>
      <PageHeader
        header={t("password.forgot_label", { ns: "users" })}
        type={"h1"}
        divider={true}
        addTitle={true}
      />
      <Container text>
        <Segment textAlign="left" className="form-background-color">
          <Formik
            initialValues={{ emailAddress: "", domain: "", error: undefined }}
            onSubmit={(values, { setErrors }) => {
              userStore
                .requestPasswordReset(values)
                .then(() => {
                  setIsComplete(true);
                })
                .catch((error) => setErrors({ error }));
            }}
            validationSchema={Yup.object({
              emailAddress: Yup.string()
                .required(t("registration.required_Email", { ns: "errors" }))
                .email(t("registration.invalid_Email", { ns: "errors" })),
            })}
          >
            {({ handleSubmit, isSubmitting, errors }) => (
              <Form
                className="ui form error"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                {isComplete && !errors.error && (
                  <Message positive>
                    <p>
                      {t("forgotpassword.successful1", { ns: "alerts" })}
                      <br />
                      <br />
                      {t("forgotpassword.successful2", { ns: "alerts" })}
                    </p>
                  </Message>
                )}
                <ErrorMessage
                  name="error"
                  render={() => <ValidationErrors errors={errors.error} />}
                />
                <Container>
                  {t("password.forgot_message", { ns: "users" })}
                </Container>
                <MyTextInput
                  name="emailAddress"
                  placeholder={t("email", { ns: "common" })}
                  autoCapitalizeFirstLetter={false}
                  maxLength={255}
                />

                <Button
                  loading={isSubmitting && !isComplete && !errors.error}
                  size="large"
                  positive
                  content={t("password.forgot_button", { ns: "users" })}
                  color={"green"}
                  type="submit"
                />
              </Form>
            )}
          </Formik>
        </Segment>
      </Container>
    </>
  );
});
