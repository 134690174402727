import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Message,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Link } from "react-router-dom";
import StateSelect from "../../../app/common/form/StateSelect";
import PageHeader from "../../../app/layout/PageHeader";
import PhoneNumberInput from "../../../app/common/form/PhoneNumberInput";
import MyTextArea from "../../../app/common/form/MyTextArea";
import { useStore } from "../../../app/stores/store";
import { ClientDetailsFormValues } from "../../../app/models/client";
import ValidationErrors from "../../errors/ValidationErrors";
import { RegexConstants } from "../../../app/common/util/regexConstants";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { PhoneNumberType } from "../../../app/models/enums";

export default observer(function CompanyDetails() {
  const { t } = useTranslation(["common", "companySettings", "errors"]);

  const { clientStore, ticketStore } = useStore();

  const { loadClientDetails, updateClientDetails, loadingInitial } =
    clientStore;

  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;

  const phoneRegExp = new RegExp(RegexConstants.phoneRegExp);
  const postalCodeRegExp = new RegExp(RegexConstants.postalCodeExp);
  const businessNameRegExp = new RegExp(RegexConstants.businessNameRegExp);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t("registration.required_ClientName", { ns: "errors" }))
      .matches(
        businessNameRegExp,
        t("registration.required_ClientName", { ns: "errors" })
      )
      .max(500),
    commercialNumber: Yup.string()
      .required(t("registration.required_PhoneNumber", { ns: "errors" })),
    email: Yup.string().email("registration.invalid_email").max(255),
    addressLine1: Yup.string()
      .required(t("registration.required_address", { ns: "errors" }))
      .max(500),
    city: Yup.string()
      .required(t("registration.required_city", { ns: "errors" }))
      .max(255),
    state: Yup.string()
      .required(t("registration.required_state", { ns: "errors" }))
      .max(50),
    postalCode: Yup.string()
      .required(t("registration.required_postalcode", { ns: "errors" }))
      .matches(
        postalCodeRegExp,
        t("registration.invalid_postalcode", { ns: "errors" })
      )
      .max(50),
    additionalInformationHeader: Yup.string()
      .nullable()
      .max(
        2000,
        t("registration.invalid_additional_header_length", { ns: "errors" })
      ),
    additionalInformationFooter: Yup.string()
      .nullable()
      .max(
        2000,
        t("registration.invalid_additional_footer_length", { ns: "errors" })
      ),
    faxNumber: Yup.string().nullable(),
  });

  const [clientDetails, setClientDetails] = useState<ClientDetailsFormValues>(
    new ClientDetailsFormValues()
  );
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  useEffect(() => {
    loadValues();
  }, [loadClientDetails]);

  function loadValues() {
    loadClientDetails().then((details) => {
      setClientDetails(new ClientDetailsFormValues(details));
    });
  }

  function handleFormSubmit(
    values: ClientDetailsFormValues,
    setErrors: (errors: FormikErrors<ClientDetailsFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = () => updateClientDetails(values);

    action()
      .then(() => {
        loadValues();
        setSubmitting(false);
        setCompleted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("compDeets_loading", { ns: "companySettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("commpanydetails", { ns: "companySettings" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />

      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={clientDetails}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {isValid && completed && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("name", { ns: "common" })}
                      label={`${t("name", { ns: "common" })}:`}
                      name="name"
                      showHelp={true}
                      helpName="settings.company.name"
                      className="required"
                      autoCapitalizeFirstLetter={
                        selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                      }
                      maxLength={500}
                    ></MyTextInput>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("address.address", { ns: "common" })}
                      label={`${t("address.address", { ns: "common" })}:`}
                      name="addressLine1"
                      showHelp={true}
                      helpName="settings.company.address"
                      className="required"
                      autoCapitalizeFirstLetter={
                        selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                      }
                      maxLength={500}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("address.address2", { ns: "common" })}
                      name="addressLine2"
                      autoCapitalizeFirstLetter={
                        selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                      }
                      maxLength={500}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("address.city", { ns: "common" })}
                      label={`${t("address.city", { ns: "common" })}:`}
                      name="city"
                      showHelp={true}
                      helpName="settings.company.address"
                      className="required"
                      autoCapitalizeFirstLetter={
                        selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                      }
                      maxLength={255}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <StateSelect
                      label={`${t("address.state", { ns: "common" })}:`}
                      placeholder={t("address.state", { ns: "common" })}
                      name="state"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("address.postalcode", { ns: "common" })}
                      label={`${t("address.postalcode", { ns: "common" })}:`}
                      name="postalCode"
                      showHelp={true}
                      helpName="settings.company.address"
                      className="required"
                      autoCapitalizeFirstLetter={
                        selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                      }
                      maxLength={50}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <PhoneNumberInput
                      placeholder={t("phonenumber", { ns: "common" })}
                      label={`${t("phonenumber", { ns: "common" })}:`}
                      name="commercialNumber"
                      showHelp={false}
                      helpName="settings.company.phoneNumbers"
                      className="required"
                      autoFormat={
                        selectedSettingsTicketInputDefaults.formatPhoneNumber
                      }
                      phoneType={PhoneNumberType.Landline}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <PhoneNumberInput
                      placeholder={t("form.fax", { ns: "companySettings" })}
                      label={`${t("form.fax", { ns: "companySettings" })}:`}
                      name="faxNumber"
                      showHelp={false}
                      helpName="settings.company.phoneNumbers"
                      autoFormat={
                        selectedSettingsTicketInputDefaults.formatPhoneNumber
                      }
                      phoneType={PhoneNumberType.Fax}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextInput
                      placeholder={t("email", { ns: "common" })}
                      label={`${t("email", { ns: "common" })}:`}
                      name="email"
                      showHelp={true}
                      helpName="settings.company.email"
                      autoCapitalizeFirstLetter={false}
                      maxLength={255}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Divider />
                <Grid.Row>
                  <Grid.Column>
                    <MyTextArea
                      placeholder={t("form.additional1", {
                        ns: "companySettings",
                      })}
                      label={`${t("form.additional1", {
                        ns: "companySettings",
                      })}:`}
                      name="additionalInformationHeader"
                      rows={2}
                      showHelp={true}
                      helpName="settings.company.additionalInformation" maxLength={2000}                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyTextArea
                      placeholder={t("form.additional2", {
                        ns: "companySettings",
                      })}
                      label={`${t("form.additional2", {
                        ns: "companySettings",
                      })}:`}
                      name="additionalInformationFooter"
                      rows={2}
                      showHelp={true}
                      helpName="settings.company.additionalInformation" maxLength={2000}                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color="green"
                      className="save_button"
                      icon="check"
                      content={t("save", { ns: "common" })}
                      disabled={isSubmitting || !dirty || !isValid}
                      loading={isSubmitting}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/settings"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
