import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../app/stores/store";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";
import {
  convertRateTypeToString,
  formatCurrency,
  formatPhoneNumber,
} from "../../../../app/common/util/functions";
import { SettingsTicketInputDefaults } from "../../../../app/models/ticket";

interface Props {
  paymentMethod: string;
  settingsTicketInputDefaults: SettingsTicketInputDefaults;
}

export default observer(function EstimateDetailsPdfView({
  paymentMethod,
  settingsTicketInputDefaults,
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  const { ticketSessionStore } = useStore();

  const { selectedTicketSession } = ticketSessionStore;

  return (
    <View
      style={{
        ...compose("view pageWidth noMarginLeftRight noPaddingLeftRight"),
        flex: 1,
      }}
    >
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom pageWidth lineHeight"
          ),
          flex: 1,
        }}
      >
        <View
          style={{
            ...compose(
              "view borderSolidRight borderSolidBottom borderSolidTop borderSolidLeft noMarginLeft lineHeight width22 noPaddingLeft"
            ),
            flex: 1,
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead padding5pxRight borderSolidRight padding1mmTop noMarginLeft lineHeight width22 noPaddingLeft"
              ),
              flex: 1,
            }}
          >
            {t("estimate.rate_type", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view borderSolidRight padding5pxRight padding1mmTop noMarginLeft borderSolidTop padding5pxLeft lineHeight"
            ),
            flex: 1,
            width: "48mm",
            maxWidth: "48mm",
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft lineHeight"),
              flex: 1,
              width: "48mm",
              maxWidth: "48mm",
            }}
          >
            {selectedTicketSession &&
              convertRateTypeToString(selectedTicketSession.rateType)}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view borderSolidRight borderSolidBottom borderSolidTop noMarginLeft lineHeight noPaddingLeft"
            ),
            flex: 1,
            width: "34mm",
            maxWidth: "34mm",
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead borderSolidRight padding5pxRight padding1mmTop noMarginLeft lineHeight noPaddingLeft"
              ),
              flex: 1,
              width: "34mm",
              maxWidth: "34mm",
            }}
          >
            {t("estimate.payment_method", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding1mmTop  borderSolidTop borderSolidRight padding5pxLeft noMarginLeft lineHeight"
            ),
            flex: 1,
            width: "42mm",
            maxWidth: "42mm",
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft lineHeight"),
              flex: 1,
              width: "42mm",
              maxWidth: "42mm",
            }}
          >
            {paymentMethod}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view borderSolidRight borderSolidBottom borderSolidTop noMarginLeft lineHeight noPaddingLeft"
            ),
            flex: 1,
            width: "34mm",
            maxWidth: "34mm",
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead borderSolidRight padding5pxRight padding1mmTop noMarginLeft lineHeight noPaddingLeft"
              ),
              flex: 1,
              width: "34mm",
              maxWidth: "34mm",
            }}
          >
            {t("estimate.est_charge", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding1mmTop  borderSolidTop borderSolidRight padding5pxLeft noMarginLeft lineHeight"
            ),
            flex: 1,
            width: "20mm",
            maxWidth: "20mm",
          }}
        >
          <Text
            style={{
              ...compose("text noMarginLeft lineHeight"),
              flex: 1,
              width: "20mm",
              maxWidth: "20mm",
            }}
          >
            {formatCurrency(selectedTicketSession?.estimateCharge ?? 0.0)}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex noPadding noMargin borderSolidBottom pageWidth noMarginLeft lineHeight noPaddingLeft"
          ),
          flex: 1,
        }}
      >
        <View
          style={{
            ...compose(
              "view borderSolidRight borderSolidBottom borderSolidLeft noMarginLeft lineHeight noPaddingLeft"
            ),
            flex: 1,
            width: "46mm",
            maxWidth: "46mm",
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead borderSolidRight padding5pxRight padding1mmTop noMarginLeft lineHeight noPaddingLeft"
              ),
              flex: 1,
              width: "46mm",
              maxWidth: "46mm",
            }}
          >
            {t("estimate.auth_person", { ns: "tickets" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "text borderSolidRight padding5pxLeft padding1mmTop noMarginLeft lineHeight"
            ),
            flex: 1,
            width: "99mm",
            maxWidth: "99mm",
          }}
        >
          <Text>{selectedTicketSession?.authorizedContact}</Text>
        </View>
        <View
          style={{
            ...compose(
              "view borderSolidRight borderSolidBottom noMarginLeft lineHeight noPaddingLeft"
            ),
            flex: 1,
            width: "19mm",
            maxWidth: "19mm",
          }}
        >
          <Text
            style={{
              ...compose(
                "text metaHead borderSolidRight padding5pxRight padding1mmTop noMarginLeft lineHeight noPaddingLeft"
              ),
              flex: 1,
              width: "19mm",
              maxWidth: "19mm",
            }}
          >
            {t("phone", { ns: "common" })}
          </Text>
        </View>
        <View
          style={{
            ...compose(
              "text padding5pxRight padding1mmTop borderSolidRight padding5pxLeft noMarginLeft lineHeight"
            ),
            flex: 1,
            width: "46mm",
            maxWidth: "46mm",
          }}
        >
          <Text
            style={{
              ...compose(
                "text padding5pxRight noMarginLeft padding5pxLeft lineHeight"
              ),
              flex: 1,
              width: "46mm",
              maxWidth: "46mm",
            }}
          >
            {settingsTicketInputDefaults.formatPhoneNumber &&
            selectedTicketSession?.otherAuthorizedPersonPhone
              ? formatPhoneNumber(
                  selectedTicketSession?.otherAuthorizedPersonPhone
                )
              : selectedTicketSession?.otherAuthorizedPersonPhone}
          </Text>
        </View>
      </View>
    </View>
  );
});
