import { SearchType } from "../../models/enums";
import i18n from "../i18n/i18n";

export const searchOptions = [
    {
        key: 0,
        text: "",
        value: 0,
    },
    {
        key: SearchType.Site,
        text: i18n.t("searchAll", { ns: "search" }),
        value: SearchType.Site,
    },
    {
        key: SearchType.CustomerName,
        text: i18n.t("name", { ns: "search" }),
        value: SearchType.CustomerName,
    },
    {
        key: SearchType.CustomerPhone,
        text: i18n.t("phone", { ns: "search" }),
        value: SearchType.CustomerPhone,
    },
    {
        key: SearchType.InvoiceNumber,
        text: i18n.t("invoice", { ns: "search" }),
        value: SearchType.InvoiceNumber,
    },
    {
        key: SearchType.EstimateNumber,
        text: i18n.t("estimate", { ns: "search" }),
        value: SearchType.EstimateNumber,
    },
    { key: SearchType.PONumber, text: i18n.t("po", { ns: "search" }), value: SearchType.PONumber },
    { key: SearchType.Tag, text: i18n.t("tag", { ns: "search" }), value: SearchType.Tag },
    { key: SearchType.Vin, text: i18n.t("vin", { ns: "search" }), value: SearchType.Vin },
    {
        key: SearchType.VehicleNumber,
        text: i18n.t("number", { ns: "search" }),
        value: SearchType.VehicleNumber,
    },
    {
        key: SearchType.VehicleModel,
        text: i18n.t("model", { ns: "search" }),
        value: SearchType.VehicleModel,
    },
];