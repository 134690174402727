import { observer } from "mobx-react-lite";
import { Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useEffect } from "react";
import { useStore } from "../../../app/stores/store";

interface Props {
  clientId: string;
}

export default observer(function ClientAvailableTrials({ clientId }: Props) {
  const { t } = useTranslation(["common"]);
  const { clientSystemAdminStore } = useStore();

  const { loadClientAvailableTrials, getClientAvailableTrials } =
    clientSystemAdminStore;

  useEffect(() => {
    loadClientAvailableTrials(clientId);
  }, [loadClientAvailableTrials, clientId]);

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Trial </Table.HeaderCell>
          <Table.HeaderCell>End Date (started now)</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {getClientAvailableTrials && getClientAvailableTrials.length > 0 ? (
          getClientAvailableTrials.map((x, i) => (
            <Table.Row key={`client.${x.endDate}${i}`}>
              <Table.Cell>{x.trialType}</Table.Cell>
              <Table.Cell>{format(x.endDate, "P")}</Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noItems"}>
            <Table.Cell width="8" colSpan={8}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  );
});
