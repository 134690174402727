import { observer } from "mobx-react-lite";
import { Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { formatPhoneNumber, getPhoneNumber } from "../../../app/common/util/functions";
import { PhoneNumberType } from "../../../app/models/enums";

interface Props {
    clientId: string;
}

export default observer(function ClientContact({clientId}:Props) {
    const { clientSystemAdminStore } = useStore();

    const {
        loadClientContacts,
        clientContacts,
    } = clientSystemAdminStore;

    useEffect(() => {
        loadClientContacts(clientId);
    }, [loadClientContacts, clientId]);


    const phoneList = clientContacts?.phone;

    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>First Name</Table.HeaderCell>
                    <Table.HeaderCell>Last Name</Table.HeaderCell>
                    <Table.HeaderCell>Commercial Phone</Table.HeaderCell>
                    <Table.HeaderCell>Mobile Phone</Table.HeaderCell>
                    <Table.HeaderCell>Home Phone</Table.HeaderCell>
                    <Table.HeaderCell>Fax</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>{clientContacts?.emailAddress}</Table.Cell>
                    <Table.Cell>{clientContacts?.firstName}</Table.Cell>
                    <Table.Cell>{clientContacts?.lastName}</Table.Cell>
                    <Table.Cell>
                        {phoneList && getPhoneNumber(PhoneNumberType.Commercial, phoneList) ? formatPhoneNumber(getPhoneNumber(PhoneNumberType.Commercial, phoneList)?.toString()!) : ""}
                    </Table.Cell>
                    <Table.Cell>
                        {phoneList && getPhoneNumber(PhoneNumberType.Mobile, phoneList) ? formatPhoneNumber(getPhoneNumber(PhoneNumberType.Mobile, phoneList)?.toString()!) : ""}
                    </Table.Cell>
                    <Table.Cell>
                        {phoneList && getPhoneNumber(PhoneNumberType.Residential, phoneList) ? formatPhoneNumber(getPhoneNumber(PhoneNumberType.Residential, phoneList)?.toString()!) : ""}
                    </Table.Cell>
                    <Table.Cell>
                        {phoneList && getPhoneNumber(PhoneNumberType.Fax, phoneList) ? formatPhoneNumber(getPhoneNumber(PhoneNumberType.Fax, phoneList)?.toString()!) : ""}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )
})
