import React, { useState } from "react";
import { Button, Divider, Form, Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import { v4 as uuid } from "uuid";
import { TicketItem, TicketItemFormValues } from "../../../../app/models/ticket";
import { TicketItemType } from "../../../../app/models/enums";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../app/stores/store";
import { SettingsLaborRates } from "../../../../app/models/pricing";
import PageHeader from "../../../../app/layout/PageHeader";
import ValidationErrors from "../../../errors/ValidationErrors";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import MyIconDecimalInput from "../../../../app/common/form/MyIconDecimalInput";
import { round2Decimals } from "../../../../app/common/util/functions";
import MySelectInput from "../../../../app/common/form/MySelectInput";


interface Props {
  isAdd: boolean;
  ticketItem?: TicketItem;
  saveItem: (values: TicketItemFormValues, type: TicketItemType) => void;
  autoCapitalizeFirstLetter: boolean;
}

export default observer(function LaborItemForm({
  isAdd,
  ticketItem,
  saveItem,
  autoCapitalizeFirstLetter,
}: Props) {
  const { t } = useTranslation(["common", "translation", "tickets"]);

  const { pricingStore, modalStore } = useStore();
  const {
    dropdownLaborRatesList,
    getPrimaryLaborRate,
    selectedSettingsPricing,
    getLaborRate,
  } = pricingStore;

  let primary: SettingsLaborRates | undefined = getPrimaryLaborRate();

  let newItem: TicketItem = new TicketItem();

  if (isAdd || !ticketItem) {
    newItem.id = uuid();
    newItem.quantity = 1;
    newItem.rate = primary ? primary.rate : 0.0;
    newItem.unitPrice = primary ? primary.rate : 0.0;
    newItem.subTotal = newItem.rate;
    newItem.laborRate = primary ? primary.id : undefined;
  } else {
    newItem = ticketItem;
  }

  const [laborItem] = useState<TicketItemFormValues>(newItem);

  const validationSchema = Yup.object({
    description: Yup.string().required(
      t("descriptionRequired", {
        ns: "errors",
      })
    ),
  });

  function handleFormSubmit(values: TicketItemFormValues) {
    saveItem(values, TicketItemType.Labor);
    modalStore.closeModal();
  }

  return (
    <>
      <PageHeader
        type={"h3"}
        header={
          isAdd
            ? t("pops.add_lbr_itm", {
                ns: "tickets",
              })
            : t("pops.edit_lbr_itm", {
                ns: "tickets",
              })
        }
        divider={true}
        addTitle={false}
      />
      <Formik
        validationSchema={validationSchema}
        initialValues={laborItem}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form
            className="ui form error"
            autoComplete="on"
            onSubmit={handleSubmit}
          >
            <ErrorMessage
              name="error"
              render={() => <ValidationErrors errors={errors} />}
            />
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <MyTextInput
                    placeholder={t("pops.lbr_desc", { ns: "tickets" })}
                    label={t("pops.lbr_desc", { ns: "tickets" }) + ":"}
                    name="description"
                    autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                    maxLength={1000}
                    autoFocus={true}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <MyIconDecimalInput
                    icon="clock outline"
                    iconPosition="left"
                    placeholder="1.00"
                    label={t("pops.hours", { ns: "tickets" }) + ":"}
                    name="quantity"
                    onChange={(d) => {
                      if (d && values.rate) {
                        let subTotal =
                          round2Decimals(Number(values.rate)) *
                          round2Decimals(Number(d));
                        setFieldValue("subTotal", subTotal.toFixed(2));
                      }
                    }}
                    maxLength={500}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <MyIconDecimalInput
                    icon="dollar sign"
                    iconPosition="left"
                    placeholder="0.00"
                    label={t("pops.rate", { ns: "tickets" }) + ":"}
                    name="rate"
                    onChange={(d) => {
                      if (d && values.quantity) {
                        let subTotal =
                          round2Decimals(Number(d)) *
                          round2Decimals(Number(values.quantity));
                        setFieldValue("subTotal", subTotal.toFixed(2));
                      }
                    }}
                    onBlur={(value) => {
                      if (value) {
                        setFieldValue("rate", Number(value).toFixed(2) as unknown as number);
                      }
                    }}
                    maxLength={500}
                  />
                </Grid.Column>
                <Grid.Column verticalAlign="bottom">
                  <MySelectInput
                    options={dropdownLaborRatesList}
                    placeholder={""}
                    name="laborRate"
                    onChange={(e) => {
                      if (e) {
                        if (selectedSettingsPricing?.laborRates) {
                          let rate = getLaborRate(e.toString());
                          setFieldValue("rate", rate?.rate);
                          if (rate?.rate) {
                            let subTotal =
                              round2Decimals(rate?.rate) *
                              round2Decimals(values.quantity);
                            setFieldValue("subTotal", subTotal.toFixed(2));
                          }
                        }
                      }
                    }}
                    clearable={true}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <MyIconDecimalInput
                    icon="dollar"
                    placeholder="0.00"
                    label={t("pops.sub_total", { ns: "tickets" })}
                    name="subTotal"
                    iconPosition="left"
                    onChange={(d) => {
                      if (d && values.quantity) {
                        let rate =
                          round2Decimals(Number(d)) /
                          round2Decimals(Number(values.quantity));
                        setFieldValue("rate", rate.toFixed(2));
                      }
                    }}
                    onBlur={(value) => {
                      if (value) {
                        setFieldValue("subTotal", Number(value).toFixed(2) as unknown as number);
                      }
                    }}
                    maxLength={500}
                  />
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column columns={2}>
                  <Button color="green" type="submit">
                    <Icon name="check" />
                    {
                      isAdd
                      ? t("pops.add_lbr_itm", {
                          ns: "tickets",
                        })
                      : t("pops.save_lbr_itm", {
                          ns: "tickets",
                        })
                    }
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      modalStore.closeModal();
                    }}
                  >
                    <Icon name="x" />
                    {t("close", { ns: "common" })}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
});
