import { useField } from "formik";
import React from "react";
import { Form, Label } from "semantic-ui-react";
import MyHelpButton from "./MyHelpButton";

interface Props {
  placeholder: string;
  name: string;
  type?: string;
  label?: string;
  className?: string;
  showHelp?: boolean;
  helpName?: string;
  disabled?: boolean;
  myValidate?: (value: string) => void;
  helpSeeMore?: boolean;
  maxLength: number;
}

export default function MyCapitalsInput({
  placeholder,
  name,
  type,
  label,
  className,
  showHelp,
  helpName,
  disabled,
  myValidate,
  helpSeeMore,
  maxLength,
}: Readonly<Props>) {
  const [field, meta] = useField(name);
  let labelClassName: string = "myLabel";
  let inputClassName: string = "input";
  if (className) labelClassName = `${labelClassName} ${className}`;
  if (className) inputClassName = `${inputClassName} ${className}`;

  return (
    <Form.Field
      error={meta.touched && !!meta.error}
      className="ui"
      name={name}
    >
      {label && label.length > 0 && (
        <label className={labelClassName} htmlFor={name}>
          {`${label} `}
          {showHelp && (
            <MyHelpButton
              name={`${name}.help`}
              help={helpName ?? ""}
              seeMore={helpSeeMore}
              color={"blue"}
            />
          )}
        </label>
      )}
      <input
        {...field}
        placeholder={placeholder}
        name={name}
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        onInput={(e) => {
          const value = e.currentTarget.value;
          if (value) {
            e.currentTarget.value = value.toUpperCase();
          }
          if (myValidate) myValidate(field.value);
        }}
        className={inputClassName}
        value={
          (field.value != null ? field.value.toString() : undefined) ||
          undefined
        }
        id={name}
      />
      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
