import React, { useEffect, useState } from "react";
import { Button, Divider, DropdownItemProps, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { TicketPaymentItem } from "../../../app/models/ticket";
import { PaymentStatus } from "../../../app/models/enums";
import {
  loadTicket,
  resetTicket,
} from "../../../app/common/util/ticketfunctions";
import { toast } from "react-toastify";

interface Props {
  header: string;
  ticketPaymentItem: TicketPaymentItem;
  ticketId: string;
  dropdownPaymentItemList: DropdownItemProps[];
}

export default function PaymentTerminal({
  header,
  ticketPaymentItem,
  ticketId,
}: Readonly<Props>) {
  const { t } = useTranslation(["common"]);
  const { modalStore, ticketPaymentProcessingStore } = useStore();
  const { processTicketPayment, checkStatus } = ticketPaymentProcessingStore;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);

  const [completed, setCompleted] = useState<boolean>(false);
  const [checkStatusDelay, setCheckStatusDelay] = useState<boolean>(false);

  useEffect(() => {
    processTicketPayment(ticketPaymentItem, false)
      .then((result) => {
        if (result) {
          resetTicket();
          loadTicket(ticketId);
          toast.success(
            t("payments.transactionSuccessful", { ns: "tickets" }).toString()
          );
          setCompleted(true);
          modalStore.closeModal();
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorCode(error);
        setErrorMessage(t(`payments.${error}`, { ns: "tickets" }));
        ticketPaymentItem.status = PaymentStatus.Failed;
        setCompleted(true);
      })
      .finally(() => {
        setCompleted(true);
      });
  }, []);

  function setTheCheckStatusDelay() {
    setCheckStatusDelay(false);
  }

  return (
    <>
      <PageHeader header={header} type={"h3"} divider={true} addTitle={false}/>

      {errorCode && (
        <Message error>
          <div
            dangerouslySetInnerHTML={{
              __html: errorMessage ?? "",
            }}
          />
        </Message>
      )}
      {!completed && <LoadingComponent content="Waiting for Terminal" />}
      <Divider />

      {(errorCode == "Error" || errorCode == "Connection Timeout") && (
        <>
          <Button
            color="green"
            icon="undo"
            loading={!completed || checkStatusDelay}
            disabled={!completed || checkStatusDelay}
            content={t("payments.Recheck", { ns: "tickets" })}
            onClick={() => {
              setCheckStatusDelay(true);
              setErrorMessage(undefined);
              setCompleted(false);

              checkStatus(ticketPaymentItem)
                .then(() => {
                  resetTicket();
                  loadTicket(ticketId);
                })
                .catch((error) => {
                  setErrorMessage(t(`payments.${error}`, { ns: "tickets" }));
                })
                .finally(() => {
                  setCompleted(true);
                  setTimeout(setTheCheckStatusDelay, 6000);
                });
            }}
          />
        </>
      )}

      {(errorCode == "CheckStatusPaymentFailure" ||
        errorCode == "Declined" ||
        errorCode == "Cancelled" ||
        errorCode == "Error" ||
        errorCode == "Terminal Busy") && (
        <>
          <Button
            loading={!completed}
            disabled={!completed}
            onClick={() => {
              setErrorCode(undefined);
              setErrorMessage(undefined);
              setCompleted(false);
              processTicketPayment(ticketPaymentItem, false)
                .then((result) => {
                  if (result) {
                    resetTicket();
                    loadTicket(ticketId);
                    toast.success(
                      t("payments.transactionSuccessful", {
                        ns: "tickets",
                      }).toString()
                    );
                    modalStore.closeModal();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setErrorCode(error);
                  setErrorMessage(t(`payments.${error}`, { ns: "tickets" }));
                  ticketPaymentItem.status = PaymentStatus.Failed;
                })
                .finally(() => {
                  setCompleted(true);
                });
            }}
            content={t("payments.tryAgain", { ns: "tickets" })}
          ></Button>
        </>
      )}

      {(errorCode == "CheckStatusPaymentFailure" || errorCode == "Error") && (
        <>
          <Button
            loading={!completed}
            disabled={!completed}
            onClick={() => {
              setErrorCode(undefined);
              setErrorMessage(undefined);
              setCompleted(false);
              processTicketPayment(ticketPaymentItem, true)
                .then((result) => {
                  if (result) {
                    resetTicket();
                    loadTicket(ticketId);
                    toast.success(
                      t("payments.transactionSuccessful", {
                        ns: "tickets",
                      }).toString()
                    );
                    modalStore.closeModal();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  setErrorCode(error);
                  setErrorMessage(t(`payments.${error}`, { ns: "tickets" }));
                  ticketPaymentItem.status = PaymentStatus.Failed;
                })
                .finally(() => {
                  setCompleted(true);
                });
            }}
            content={t("payments.Bypass", { ns: "tickets" })}
          ></Button>
        </>
      )}

      <Button
        className="save_button"
        onClick={modalStore.closeModal}
        icon="cancel"
        type="reset"
        content={t("cancel", { ns: "common" })}
      />
    </>
  );
}
