export interface SettingsTechnician {
  id?: string;
  technicianTrackingEnabled: boolean;
  technicianPartsTrackingEnabled: boolean;
  error?: string;
}

export class SettingsTechnician implements SettingsTechnician {
  constructor(init?: SettingsTechnicianFormValues) {
    if (init) {
      this.id = init.id;
      this.technicianPartsTrackingEnabled =
        init.technicianPartsTrackingEnabled.toString() === "true";
      this.technicianTrackingEnabled =
        init.technicianTrackingEnabled.toString() === "true";
      this.error = init.error;
    }
  }
}
export class SettingsTechnicianFormValues {
  id?: string = undefined;
  technicianTrackingEnabled: boolean = false;
  technicianPartsTrackingEnabled: boolean = false;
  error?: string = undefined;

  constructor(settingsTechnician?: SettingsTechnicianFormValues) {
    if (settingsTechnician) {
      this.id = settingsTechnician.id;
      this.technicianTrackingEnabled =
        settingsTechnician.technicianTrackingEnabled;
      this.technicianPartsTrackingEnabled =
        settingsTechnician.technicianPartsTrackingEnabled;
      this.error = settingsTechnician.error;
    }
  }
}

export interface Technician {
  id?: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  createdDate: Date;
  error?: string;
}
export class Technician implements Technician {
  constructor(init?: TechnicianFormValues) {
    if (init) {
      this.id = init.id;
      this.firstName = init.firstName;
      this.lastName = init.lastName;
      this.isActive = init.isActive.toString() === "true";
      this.error = init.error;
    }
  }
}

export class TechnicianFormValues {
  id?: string = undefined;
  firstName: string = "";
  lastName: string = "";
  isActive: boolean = false;
  error?: string = undefined;

  constructor(technician?: Technician) {
    if (technician) {
      this.id = technician.id;
      this.firstName = technician.firstName;
      this.lastName = technician.lastName;
      this.isActive = technician.isActive;
      this.error = technician.error;
    }
  }
}
