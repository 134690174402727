import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../app/stores/store";
import { formatCurrency } from "../../../../app/common/util/functions";
import { View, Text } from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";

export default observer(function TicketAmountPdfView() {
  const { t } = useTranslation(["common", "tickets"]);

  const { ticketSessionStore } = useStore();

  const { selectedTicketSession } = ticketSessionStore;

  return (
    <View
      style={{
        ...compose("view height44mm "),
        minWidth:"40mm",
        maxWidth:"65mm"
      }}
    >
      <View
        style={{
          ...compose(
            "view flex borderSolidTop borderSolidRight borderSolidLeft noMargin noPadding lineHeight "
          )
        }}
      >
        <View
          style={{
            ...compose(
              "view floatRight metaHead noPadding noMarginLeft padding1mmTop padding5pxLeft padding5pxRight borderSolidRight textAlignRight lineHeight width17"
            )
          }}
        >
          <Text>{t("tech.labor", { ns: "miscSettings" })}</Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding5pxRight padding1mmTop padding5pxLeft noMarginLeft lineHeight"
            ),
            flex: 1,
            textAlign: "right",
          }}
        >
          <Text>
            {formatCurrency(selectedTicketSession?.totalLaborAmount ?? 0.0)}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex borderSolidTop borderSolidRight borderSolidLeft noMargin noPadding lineHeight"
          )
        }}
      >
        <View
          style={{
            ...compose(
              "view floatRight metaHead noPadding noMarginLeft padding1mmTop padding5pxLeft padding5pxRight borderSolidRight textAlignRight width17 lineHeight"
            )
          }}
        >
          <Text>{t("estimate.parts", { ns: "tickets" })}</Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding5pxRight padding1mmTop padding1mmBottom padding5pxLeft noMarginLeft lineHeight"
            ),
            flex: 1,
            textAlign: "right",
          }}
        >
          <Text>
            {formatCurrency(selectedTicketSession?.totalPartsAmount ?? 0.0)}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex borderSolidTop borderSolidRight borderSolidLeft noMargin noPadding lineHeight"
          )
        }}
      >
        <View
          style={{
            ...compose(
              "view floatRight metaHead noPadding padding1mmTop noMarginLeft padding1mmBottom padding5pxLeft padding5pxRight borderSolidRight textAlignRight width17 lineHeight"
            )
          }}
        >
          <Text>{t("estimate.misc", { ns: "tickets" })}</Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding5pxRight padding1mmTop padding1mmBottom padding5pxLeft noMarginLeft lineHeight"
            ),
            flex: 1,
            textAlign: "right",
          }}
        >
          <Text>
            {formatCurrency(selectedTicketSession?.totalMiscellaneousAmount ?? 0.0)}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex borderSolidTop borderSolidRight borderSolidLeft noMargin noPadding lineHeight"
          )
        }}
      >
        <View
          style={{
            ...compose(
              "view floatRight metaHead noPadding padding1mmTop noMarginLeft padding5pxLeft padding5pxRight borderSolidRight textAlignRight width17 lineHeight"
            )
          }}
        >
          <Text>{t("estimate.subtotal", { ns: "tickets" })}</Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding5pxRight padding1mmTop padding1mmBottom padding5pxLeft noMarginLeft lineHeight"
            ),
            flex: 1,
            textAlign: "right",
          }}
        >
          <Text>
            {formatCurrency(selectedTicketSession?.subTotalAmount ?? 0.0)}
          </Text>
        </View>
      </View>
      <View
        style={{
          ...compose(
            "view flex borderSolidTop borderSolidRight borderSolidLeft noMargin noPadding borderSolidBottom"
          ),
          height: "7.5mm",
          maxHeight: "7.5mm"
        }}
      >
        <View
          style={{
            ...compose(
              "view floatRight metaHead noPadding padding1mmTop noMarginLeft padding5pxLeft padding5pxRight borderSolidRight textAlignRight width17"
            ),
            //flex: 1,
            height: "6.5mm",
            maxHeight: "6.5mm",
          }}
        >
          <Text>{t("estimate.tax", { ns: "tickets" })}</Text>
        </View>
        <View
          style={{
            ...compose(
              "view padding5pxRight padding1mmTop padding5pxLeft noMarginLeft lineHeight"
            ),
            flex: 1,
            textAlign: "right",
          }}
        >
          <Text>
            {formatCurrency(selectedTicketSession?.totalTaxes ?? 0.0)}
          </Text>
        </View>
      </View>

      <View
        style={{
          ...compose(
            "view flex border3px noMargin noPadding marginTop"
          ),
          height: "8mm",
          maxHeight: "8mm"
        }}
      >
        <View
          style={{
            ...compose(
              "view floatRight metaHead noPadding padding1mmTop noMarginLeft padding5pxLeft padding5pxRight borderSolidRight textAlignRight width17"
            ),
            height: "6mm",
            maxHeight: "6mm",
          }}
        >
          <Text
          
          style={{
            ...compose("text"),
            fontFamily: "Times-Bold",
            fontWeight: 800
          }}
          >{t("estimate.total", { ns: "tickets" })}</Text>
        </View>
        <View
          style={{
            ...compose(
              "view metaHead padding5pxRight padding1mmTop padding5pxLeft noMarginLeft"
            ),
            flex: 1,
            height: "6mm",
            maxHeight: "6mm",
            textAlign: "right",
          }}
        >
          <Text>
            {formatCurrency(selectedTicketSession?.totalAmount ?? 0.0)}
          </Text>
        </View>
      </View>
    </View>
  );
});
