import axios from "axios";
import {
  ClientPaymentHistory,
  ClientSubscriptionStatus,
  ClientSubscriptions,
} from "../models/clientSubscription";
import { Application } from "../models/enums";

import { requests, responseBody } from "./Requests";
import { PaginatedResult } from "../models/pagination";

export const Subscription = {
  cancelSubscriptionPageView: () =>
    requests.post<void>(`/ClientBillingInformation/cancelSubscriptionPageView/`, {}),
  cancelSubscription: () =>
    requests.post<void>(`/ClientBillingInformation/cancelSubscription/`, {}),
  calculateSubscription: (
    dowellSystemApplication: Application,
    userCount: number,
    term: number
  ) =>
    requests.get<number>(
      `/ClientSubscription/CalculateSubscription?DowellSystemApplication=${dowellSystemApplication}&Term=${term}&UserCount=${userCount}`
    ),
  createSubscription: (clientSubscriptionsFormValues: ClientSubscriptions) =>
    requests.post<void>(
      `/clientBillingInformation/createSubscription`,
      clientSubscriptionsFormValues
    ),
  availableTrials: () =>
    requests.get<Application>(`/clientSubscription/availableTrials`),

  trialExtensionAvailable: () =>
    requests.get<boolean>(`/clientSubscription/trialExtensionAvailable`),

  subscriptionExtensionAvailable: () =>
    requests.get<boolean>('/clientSubscription/subscriptionExtensionAvailable'),

  getCurrentSubscription: () =>
    requests.get<ClientSubscriptionStatus>(`/clientSubscription/status`),
  getSubscription: () =>
    requests.get<ClientSubscriptions>(
      `/clientBillingInformation/getSubscription`
    ),

  listClientSubscriptionPayments: (
    params: URLSearchParams
  ) =>
    axios.get<PaginatedResult<ClientPaymentHistory[]>>(
      `/clientBillingInformation/listClientSubscriptionPayments?`,
      { params }
    ).then(responseBody),
  updateBillingInformation: (clientSubscriptions: ClientSubscriptions) =>
    requests.put<void>(
      `/clientBillingInformation/updateBillingInformation`,
      clientSubscriptions
    ),
  updateServiceOptions: (clientSubscriptions: ClientSubscriptions) =>
    requests.put<void>(
      `/clientBillingInformation/updateServiceOptions`,
      clientSubscriptions
    ),
  updateAddUsers: (clientSubscriptions: ClientSubscriptions) =>
    requests.put<void>(
      `/clientBillingInformation/UpdateAddUsers`,
      clientSubscriptions
    ),
  updateRenewalTerms: (clientSubscriptions: ClientSubscriptions) =>
    requests.put<void>(
      `/clientBillingInformation/updateRenewalTerms`,
      clientSubscriptions
    ),
  prorateSubscription: (
    dowellSystemApplication: Application,
    userCount: number,
    term: number
  ) =>
    requests.get<number>(
      `/ClientSubscription/prorateSubscription?DowellSystemApplication=${dowellSystemApplication}&Term=${term}&UserCount=${userCount}`
    ),

  prorateAddUser: (
    dowellSystemApplication: Application,
    userCount: number,
    term: number
  ) =>
    requests.get<number>(
      `/ClientSubscription/prorateAddUser?DowellSystemApplication=${dowellSystemApplication}&Term=${term}&UserCount=${userCount}`
    ),
    addTrialExtension: (id: string, isAdmin: boolean, length: number) =>
      requests.post<void>(`/ClientBillingInformation/addTrialExtension/${id}/${isAdmin}/${length}`, {}),
    addSubscriptionExtension: (id: string, isAdmin: boolean) =>
      requests.post<void>(
        `/ClientBillingInformation/addSubscriptionExtension/${id}/${isAdmin}`,
        {}
      ),
};
