import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, Checkbox, Container, Grid, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { Link } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";

export default observer(function Manufacturers() {
  const { t } = useTranslation(["common", "workflowSettings"]);
  const { makeListStore } = useStore();
  const {
    loadSettingsMakeList,
    getMakeLists,
    toggleSettingsMakeFilter,
    loadingInitial,
  } = makeListStore;

  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    loadSettingsMakeList().then((result) => {
      if (result) {
        let allowClick = result.filter((x) => !x.isDisabled);
        setHasError(allowClick.length < 2);
      }
    });
  }, [loadSettingsMakeList, setHasError]);

  const [completed, setCompleted] = useState<boolean>(false);

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("manufacturer_loading", { ns: "workflowSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("manufacturers", { ns: "common" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        showHelp
        helpName="settings.workflow.manufacturers"
        addTitle={true}
      />
      <Container className="page">
        {completed && (
          <Message positive>
            {t("settingsupdated", {
              ns: "common",
            })}
          </Message>
        )}
        {hasError && (
          <Message negative>
            {t("oneMake", {
              ns: "errors",
            })}
          </Message>
        )}
        <Grid columns={4} stackable>
          <Grid.Row columns={1}>
            <Grid.Column>
              {t("manufacturers.p1", { ns: "workflowSettings" })}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            {getMakeLists &&
              getMakeLists.length > 0 &&
              getMakeLists.map((source, index) => (
                <Grid.Column key={source.makeId}>
                  <Checkbox
                    id={source.makeId}
                    key={source.makeId}
                    name={source.id}
                    label={source.makeName}
                    onClick={(e, d) => {
                      if (d.id)
                        toggleSettingsMakeFilter(Number(d.id)).then(() => {
                          setCompleted(true);
                          let allowClick = getMakeLists.filter(
                            (x) => !x.isDisabled
                          );
                          setHasError(allowClick.length <= 1);
                        });
                    }}
                    defaultChecked={source.isDisabled}
                    className={
                      source.isDisabled ? "red-checked-strike-thru" : ""
                    }
                    disabled={!source.isDisabled && hasError}
                  ></Checkbox>
                </Grid.Column>
              ))}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button
                className="save_button"
                as={Link}
                to="/settings"
                icon="cancel"
                type="reset"
                content={t("cancel", { ns: "common" })}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
});
