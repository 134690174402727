import React from "react";
import { Button, Icon, Card, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import PageHeader from "../../../app/layout/PageHeader";
import CustomerVehicleForm from "../../customerVehicle/CustomerVehicleForm";
import { TicketWorkflowSteps } from "../../../app/models/enums";
import VinDisplay from "../../../app/common/form/VinDisplay";

interface Props {
  workflowStep: TicketWorkflowSteps;
  disableButtons?: boolean;
  autoCapitalizeFirstLetter: boolean;
}

export default observer(function VehicleDisplay({
  workflowStep,
  disableButtons,
  autoCapitalizeFirstLetter,
}: Props) {
  const { t } = useTranslation(["common", "ticketSettings"]);
  const { modalStore, vehicleStore, ticketSessionStore } = useStore();
  const {
    selectedVehicle,
    isYearSelected,
    isMakeSelected,
    isModelSelected,
    isEngineSelected,
  } = vehicleStore;

  const { selectedTicketSession } = ticketSessionStore;

  return (
    <Card fluid key="vehicle">
      <Card.Content>
        <Card.Header as="h2">{t("vehicle", { ns: "tickets" })}</Card.Header>
        <Divider />
        <Card.Description>
          {isModelSelected || isYearSelected || isMakeSelected ? (
            <>
              {selectedVehicle?.year && Number(selectedVehicle.year) > -1
                ? selectedVehicle.year
                : ""}
              {isMakeSelected && <> {selectedVehicle?.makeName}</>}
              {isModelSelected && (
                <>
                  {selectedVehicle.isCustom && " ◊ "}{" "}
                  {selectedVehicle?.modelName}
                </>
              )}
              {isEngineSelected && <>, {selectedVehicle?.engineName}</>}
            </>
          ) : (
            <i>{t("unknownVehicle", { ns: "tickets" })}</i>
          )}
          <br />
          <PageHeader
            header={`${t("vehicleDetails", { ns: "tickets" })}:`}
            type={"h4"}
            divider={false}
            addTitle={false}
          />
          <p>
            {`${t("vin", { ns: "recentTickets" })}: `}
            {selectedVehicle?.vin ? (
              <VinDisplay vin={selectedVehicle.vin} />
            ) : (
              <i>{t("noVinSelected", { ns: "tickets" })}</i>
            )}
            {selectedVehicle?.tag && (
              <>
                <br />
                {`${t("tag", { ns: "common" })}#: ${selectedVehicle.tag}`}
              </>
            )}
            {selectedVehicle?.vehicleNumber && (
              <>
                <br />
                {`${t("veh", { ns: "recentTickets" })}#: ${
                  selectedVehicle.vehicleNumber
                }`}
              </>
            )}
            {selectedVehicle?.mileageIn && (
              <>
                <br />
                {`${t("estimate.mileageIn", { ns: "tickets" })}: ${
                  selectedVehicle.mileageIn
                }`}
              </>
            )}
            {selectedVehicle?.mileageOut && (
              <>
                <br />
                {`${t("estimate.mileageOut", {
                  ns: "tickets",
                })}: ${selectedVehicle.mileageOut}`}
              </>
            )}
          </p>
          {!disableButtons && (
            <Button
              floated="right"
              color="grey"
              onClick={() =>
                modalStore.openModal(
                  <CustomerVehicleForm
                    autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                    updateData={true}
                    ticketWorkflowStep={workflowStep}
                    ticketId={selectedTicketSession?.id ?? ""}
                  />
                )
              }
            >
              <Icon name="edit" />
              {t("edit", { ns: "common" })}
            </Button>
          )}
        </Card.Description>
      </Card.Content>
    </Card>
  );
});
