import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Grid, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { ReportType } from "../../../app/models/enums";
import {
  formatCurrency,
  formatDecimal,
  getNegative,
} from "../../../app/common/util/functions";
import PageHeader from "../../../app/layout/PageHeader";
import { format } from "date-fns";

interface Props {
  startDate: Date;
  endDate: Date;
  reportType: ReportType;
}

export default observer(function PartSalesPrint({
  startDate,
  endDate,
  reportType,
}: Props) {
  const { t } = useTranslation(["common", "reports"]);

  const { partSalesReportStore } = useStore();
  const { getPartReport, calculateTotalSales } = partSalesReportStore;

  return (
    <>
      <Container className="page">
        <Grid stackable columns={2} className="headerPrint">
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={`${t("report", { ns: "reports" })}: ${t(
                  "part_sales.header",
                  {
                    ns: "reports",
                  }
                )}`}
                type="h2"
                divider={false}
                getAlerts={true}
                addTitle={false}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`${format(startDate, "PP")} - ${format(
                  endDate,
                  "PP"
                )}`}
                as="h2"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {getPartReport && getPartReport.length > 0 && (
          <Table striped unstackable className="dnxTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign="left">
                  {t("pops.part_desc", { ns: "tickets" })}
                </Table.HeaderCell>
                {reportType === ReportType.Detailed ? (
                  <>
                    <Table.HeaderCell>
                      {t("part_number", { ns: "reports" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {t("pops.qty", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {t("pops.rate", { ns: "tickets" })}
                    </Table.HeaderCell>
                  </>
                ) : (
                  <Table.HeaderCell textAlign="right">
                    {t("pops.qty", { ns: "tickets" })}
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell textAlign="right">
                  {t("revenue", { ns: "reports" })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getPartReport.map((x) => (
                <Table.Row key={x.description}>
                  <Table.Cell
                    data-label={`${t("description", { ns: "common" })}:`}
                    textAlign="left"
                  >
                    {x.description}
                  </Table.Cell>
                  {reportType === ReportType.Detailed ? (
                    <>
                      <Table.Cell
                        data-label={`${t("estimate.part_no", {
                          ns: "tickets",
                        })}:`}
                      >
                        {x.partCode}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("pops.qty", { ns: "tickets" })}:`}
                        textAlign="right"
                      >
                        {formatDecimal(x.quantity ?? 0, 2)}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("pops.rate", { ns: "tickets" })}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(x.rate ?? 0),
                        }}
                      >
                        {formatCurrency(x.rate ?? 0)}
                      </Table.Cell>
                    </>
                  ) : (
                    <Table.Cell
                      data-label={`${t("pops.qty", { ns: "tickets" })}:`}
                      textAlign="right"
                    >
                      {formatDecimal(x.quantity ?? 0, 2)}
                    </Table.Cell>
                  )}
                  <Table.Cell
                    data-label={`${t("revenue", { ns: "reports" })}:`}
                    textAlign="right"
                    style={{
                      color: getNegative(x.revenue ?? 0),
                    }}
                  >
                    {formatCurrency(x.revenue ?? 0)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Body>
              <Table.Row key={"grandTotals"} className="terms_bold greenRow">
                <Table.Cell
                  colSpan={reportType === ReportType.Detailed ? "4" : "2"}
                >
                  {t("grandTotals", { ns: "reports" })}:
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("revenue", { ns: "reports" })}:`}
                  textAlign="right"
                  style={{
                    color: getNegative(
                      calculateTotalSales(getPartReport).revenue
                    ),
                  }}
                >
                  {formatCurrency(calculateTotalSales(getPartReport).revenue)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </Container>
    </>
  );
});
