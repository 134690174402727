import axios from "axios";
import { PaginatedResult } from "../../models/pagination";
import {
  DeletedCustomers,
  DeletedTickets,
  GeoLocation,
  FreeTrialRequestReport,
  FreeTrial,
  SetTrial,
  DeletedTicketSessions,
} from "../../models/systemAdmin";
import { requests, responseBody } from "../Requests";
import { Announcement, SystemAdminAnnouncement } from "../../models/admin";
import { User } from "../../models/user";

export const SystemAdminTools = {

  getDeletedCustomers: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<DeletedCustomers[]>>(
        `/SystemAdminTools/getDeletedCustomers`,
        {
          params,
        }
      )
      .then(responseBody),
  getDeletedTickets: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<DeletedTickets[]>>(
        `/SystemAdminTools/getDeletedTickets`,
        {
          params,
        }
      )
      .then(responseBody),
    getDeletedTicketSessions: (params: URLSearchParams) =>
    axios
      .get<PaginatedResult<DeletedTicketSessions[]>>(
        `/SystemAdminTools/getDeletedTicketSessions`,
        {
          params,
        }
      )
      .then(responseBody),    
  restoreCustomer: (id: string) =>
    requests.put<void>(`/SystemAdminTools/restoreCustomer/${id}`, {}),
  restoreTicket: (id: string) =>
    requests.put<void>(`/SystemAdminTools/restoreTicket/${id}`, {}),
  restoreTicketSession: (id: string) =>
    requests.put<void>(`/SystemAdminTools/restoreTicketSession/${id}`, {}),
  getGeoLocation: (ip: string) =>
    requests.get<GeoLocation>(`/SystemAdminTools/getGeoLocation/${ip}`),
  getAnnouncement: (id: number) =>
    requests.get<SystemAdminAnnouncement>(`/SystemAdminTools/getAnnouncement/${id}`),
  getCurrentAnnouncement: () =>
    requests.get<Announcement[]>(`/SystemAdminTools/getCurrentAnnouncement`),
  getAnnouncementList: () =>
    requests.get<SystemAdminAnnouncement[]>(`/SystemAdminTools/listAnnouncement`),
  updateAnnouncement: (
    id: number | undefined,
    announcement: SystemAdminAnnouncement
  ) => requests.put<void>(`/SystemAdminTools/updateAnnouncement/${id}`, announcement),
  addAnnouncement: (values: SystemAdminAnnouncement) =>
    requests.post<void>(`/SystemAdminTools/addAnnouncement`, values),
  deleteAnnouncement: (id: number) => requests.del(`/SystemAdminTools/deleteAnnouncement/${id}`),
  emulateUser: (userName: string) =>
    requests.post<User>(`/account/EmulateUser/${userName}`, userName),
  getFreeTrialRequestReport: (params: URLSearchParams, abortController: AbortController) =>
    axios
      .get<PaginatedResult<FreeTrialRequestReport[]>>(
        `/SystemAdminTools/listAEFreeTrialRequests`,
        { 
          params,
          signal: abortController.signal
        }
      )
      .then(responseBody),
  approveFreeTrial: (values: FreeTrial) =>
    requests.post<void>(`/SystemAdminTools/approveFreeTrial`, values),
  rejectFreeTrial: (values: FreeTrial) =>
    requests.post<void>(`/SystemAdminTools/rejectFreeTrial`, values),
  followUpFreeTrial: (values: FreeTrial) =>
    requests.post<void>(`/SystemAdminTools/followUpFreeTrial`, values),
  setTrialRequestType: (values: SetTrial) =>
    requests.post<void>(`/SystemAdminTools/setTrialRequestType`, values),

}