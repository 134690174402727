import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import { AdminNotes } from "../../models/systemAdmin";

export default class AdminNotesStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams(undefined, 10);
        this.adminNotesRegistry.clear();
      }
    );
  }

  adminNotesRegistry = new Map<string, AdminNotes>();
  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 10);

  resetAdminNotesRegistry = () => {
    this.adminNotesRegistry.clear();
  };

  loadClientAdminNotes = async (id?: string) => {
    try {
      this.adminNotesRegistry.clear();
      const result = await agent.SystemAdminLogs.getClientAdminNotes(
        this.axiosParams,
        id
      );
      runInAction(() => {
        result.data.forEach((x) => {
          this.setAdminNotes(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  };

  loadUserAdminNotes = async (id?: string) => {
    try {
      const result = await agent.SystemAdminLogs.getUserAdminNotes(
        this.axiosParams,
        id
      );
      runInAction(() => {
        result.data.forEach((x) => {
          this.setAdminNotes(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  };

  createAdminNotes = async (
    note: string,
    clientId: string,
    userId?: string
  ) => {
    try {
      this.adminNotesRegistry.clear();
      await agent.SystemAdminLogs.addAdminNotes(note, clientId, userId);
      runInAction(() => {});
    } catch (error) {
      console.log(error);
    }
  };

  private setAdminNotes = (adminNotes: AdminNotes) => {
    adminNotes.createdDate = new Date(adminNotes.createdDate);
    this.adminNotesRegistry.set(adminNotes.id, adminNotes);
  };

  get getAdminNotes() {
    return Array.from(this.adminNotesRegistry.values()).sort(
      (a, b) => b.createdDate.getTime() - a.createdDate.getTime()
    );
  }

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (predicate: string, value?: string) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 10);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
}
