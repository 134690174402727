import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { FreeTrialRequestReport } from "../../models/systemAdmin";
import { Pagination, PagingParams } from "../../models/pagination";

export default class TrialRequestsStore {
  constructor() {
    makeAutoObservable(this);
  }

  predicate = new Map();
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  abortController: AbortController | null = null;

  TrialRequestsRegistry = new Map<number, FreeTrialRequestReport>();
  SelectedRequestsRegistry = new Set<number>();

  loadTrialRequests = async () => {
    try {
      // Cancel any pending operation to avoid race conditions
      if (this.abortController) {
        this.abortController.abort();
        this.abortController = null;
      }
      this.abortController = new AbortController();

      await agent.SystemAdminTools.getFreeTrialRequestReport(this.axiosParams, this.abortController).then(result => {
        this.abortController = null;
        this.TrialRequestsRegistry.clear();

        runInAction(() => {
          result.data.forEach((x) => this.setTrialRequest(x))
          this.setPagination(result.pagination);
        });
      });

    } catch (error) {
      console.log(error);
    }
  };

  private setTrialRequest = (trialRequest: FreeTrialRequestReport) => {
    trialRequest.createdDate = new Date(trialRequest.createdDate);
    this.TrialRequestsRegistry.set(
      trialRequest.id,
      trialRequest
    )
  };

  get getTrialRequests() {
    return Array.from(this.TrialRequestsRegistry.values()).sort(
      (a, b) => b.createdDate.getTime() - a.createdDate.getTime()
    );
  }

  selectId = (id: number, select: boolean) => select ? this.SelectedRequestsRegistry.add(id) : this.SelectedRequestsRegistry.delete(id);

  selectedId = (id: number) => this.SelectedRequestsRegistry.has(id);

  selectedIds = () => Array.from(this.SelectedRequestsRegistry.values());

  clearSelected = () => this.SelectedRequestsRegistry.clear();

  selectAll = () => this.TrialRequestsRegistry.forEach(r => this.SelectedRequestsRegistry.add(r.id));

  anySelected = () => this.SelectedRequestsRegistry.size > 0;

  getTrialRequest = (id: number) => this.TrialRequestsRegistry.get(id);

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
}
