import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Divider, Grid, Button, Icon, Image, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../app/layout/PageHeader";
import Sidebar from "./Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { TicketWorkflowSteps } from "../../../app/models/enums";
import { MiscellaneousCharge } from "../../../app/models/miscellaneousCharges";
import PotentialCustomerMatchingMessage from "../../customers/PotentialCustomerMatchingMessage";
import SummaryButton from "./SummaryButton";

interface Props {
  autoCapitalizeFirstLetter: boolean;
  alwaysMiscellaneousChargeItems: MiscellaneousCharge[];
}

export default observer(function Job({
  autoCapitalizeFirstLetter,
  alwaysMiscellaneousChargeItems,
}: Props) {
  const { t } = useTranslation(["common", "translation"]);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { complete } = useParams<{ complete: string }>();
  const { ticketSessionStore, vehicleStore, jobLaborStore, customerStore } =
    useStore();

  const { selectedCustomer } = customerStore;

  const {
    selectedTicketSession,
    updateTicketSession,
    updatesJobLabor,
    setSelectedJobLaborJobName,
    selectedJobLabor,
  } = ticketSessionStore;
  const { selectedVehicle } = vehicleStore;
  const { handleImage, jobList, loadDsiJobList, resetJob } = jobLaborStore;

  useEffect(() => {
    if (
      selectedJobLabor?.job &&
      selectedJobLabor.job.jobTypeId &&
      selectedVehicle.modelId &&
      selectedVehicle.engineId
    ) {
      resetJob();
      loadDsiJobList(
        selectedJobLabor.job.jobTypeId,
        selectedVehicle.modelId,
        selectedVehicle.engineId,
        selectedVehicle.isCustom
      );
    }
  }, [
    loadDsiJobList,
    resetJob,
    selectedJobLabor?.job,
    selectedJobLabor?.job?.jobTypeId,
    selectedVehicle.modelId,
    selectedVehicle.isCustom,
    selectedVehicle.engineId,
  ]);

  function handleJob(jobId: number, jobName: string) {
    if (selectedJobLabor?.job) {
      setSelectedJobLaborJobName(jobId, jobName);
      updatesJobLabor(selectedJobLabor);

      if (selectedTicketSession && id && selectedJobLabor)
        updateTicketSession(
          TicketWorkflowSteps.Job,
          id,
          selectedVehicle,
          selectedCustomer
        ).then(() => {
          navigate(`/ticket/time/${id}/${complete}`);
        });
    }
  }

  return (
    <Grid stackable>
      <Grid.Column width={11}>
        <PotentialCustomerMatchingMessage
          workflowStep={TicketWorkflowSteps.Finalize}
        />
        <Grid>
          <Grid.Row columns={2} verticalAlign="bottom">
            <Grid.Column verticalAlign="bottom">
              <PageHeader
                type="h1"
                header={t("select_job", { ns: "tickets" }).toString()}
                className="modal-text-color"
                textAlign="left"
                divider={false}
                addTitle={true}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="bottom">
              <Button
                floated="right"
                className="no_padding_image_buttons"
                onClick={() => {
                  navigate(`/ticket/jobCategory/${id}/${complete}`);
                }}
              >
                <Image
                  src={
                    selectedJobLabor?.job &&
                    selectedJobLabor.job.jobCategoryId &&
                    handleImage(selectedJobLabor.job.jobCategoryId)
                  }
                />
              </Button>
              <SummaryButton
                id={id ?? ""}
                selectedVehicle={selectedVehicle}
                selectedCustomer={selectedCustomer}
                completed={complete ?? "false"}
                alwaysMiscellaneousChargeItems={alwaysMiscellaneousChargeItems}
              />
              <Button
                floated="right"
                className="save_button"
                onClick={() => {
                  navigate(`/ticket/jobType/${id}/${complete}`);
                }}
              >
                <Icon name="caret left" />
                {t("back", { ns: "tickets" })}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Grid>
          <Grid.Row>
            <Table striped singleLine>
              <Table.Body>
                {jobList &&
                  jobList.length > 0 &&
                  jobList.map((job, index) => (
                    <Table.Row key={`jobType${job.value}`}>
                      <Table.Cell>
                        <Link
                          onClick={() => {
                            if (job.value && job.text)
                              handleJob(Number(job.value), job.text.toString());
                          }}
                          to={""}
                        >
                          {job.text}
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column width={5}>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Sidebar
              workflowStep={TicketWorkflowSteps.Job}
              autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
            />
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
});
