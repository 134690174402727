import { makeAutoObservable, reaction, runInAction } from "mobx";
import { Pagination, PagingParams } from "../models/pagination";
import { Vehicle, VehicleFormValues } from "../models/vehicle";
import agent from "../api/agent";
import { VehicleNoteFormValues } from "../models/customer";


export default class CustomerVehicleStore {
  loadingInitial = false;
  loading = false;
  
  pagination: Pagination | null = null;
  pagingParams = new PagingParams();
  predicate = new Map().set("all", true);
  selectedVehicle: VehicleFormValues = {
    id: undefined,
    year: undefined,
    makeId: undefined,
    makeName: undefined,
    modelId: undefined,
    modelName: undefined,
    engineId: undefined,
    engineName: undefined,
    vin: undefined,
    vehicleNumber: undefined,
    tag: undefined,
    mileageIn: undefined,
    mileageOut: undefined,
    isCustom: false,
    error: undefined,
  };

  
  customerVehiclesRegistry = new Map<string | undefined, VehicleFormValues>();
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.predicate.keys(),
      () => {
        this.customerVehiclesRegistry.clear();
      }
    );
  }
  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  get customerVehicles() {
    return Array.from(this.customerVehiclesRegistry.values()).sort(
      (a, b) => b.createdDate!.getTime() - a.createdDate!.getTime()
    );
  }

  resetCustomerVehicleRegistry = () =>{
    this.customerVehiclesRegistry.clear();
  }
  
  loadCustomerVehicles = async (customerId: string) => {
    this.loadingInitial = true;
    try {
      this.customerVehiclesRegistry.clear();
      const result = await agent.VehicleApi.listCustomerVehicle(customerId, this.axiosParams);
      runInAction(() => {
        result.data.forEach((vehicle) => {
          this.setCustomerVehicle(vehicle);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setCustomerVehicle(vehicle: Vehicle) {
    if (vehicle.createdDate)
    vehicle.createdDate = new Date(vehicle.createdDate);
    this.customerVehiclesRegistry.set(vehicle.id, vehicle);
  }

  updateCustomerVehicle = async (vehicle: VehicleFormValues) => {
    try {
      let myNew: Vehicle = new Vehicle(vehicle);
      await agent.VehicleApi.updateCustomerVehicle(myNew);
      runInAction(() => {
        this.customerVehiclesRegistry.set(myNew.id, myNew)
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  upsertCustomerVehicleNote = async (customerId: string, vehicleNotes: VehicleNoteFormValues) => {
    this.loadingInitial = true;
    try {
      await agent.VehicleApi.upsertVehicleNote(customerId, vehicleNotes);
      runInAction(() => {});
    } catch(error) {
      console.log(error);
      throw error;
    } finally {
      this.loadingInitial = false;
    }
  }

  removeCustomerVehicle = async (vehicleId: string) =>{
    this.loadingInitial = true;
    try {
      await agent.VehicleApi.removeCustomerVehicle(vehicleId);
      runInAction(() => {
        this.customerVehiclesRegistry.delete(vehicleId);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  }

  setPredicate = (predicate: string, value?: string) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };
    resetPredicate();
    this.predicate.set(predicate, value);
  };

  resetPredicate = () => {
    this.predicate.clear();
  };

}
