import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Label,
  Message,
  Radio,
  Table,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { taxOptions } from "../../../app/common/options/option";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { SettingsTaxRateFormValues } from "../../../app/models/pricing";
import ValidationErrors from "../../errors/ValidationErrors";
import MyIconTextInput from "../../../app/common/form/MyIconTextInput";
import { RegexConstants } from "../../../app/common/util/regexConstants";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyHelpButton from "../../../app/common/form/MyHelpButton";
import { Application } from "../../../app/models/enums";
import modalStore from "../../../app/stores/modalStore";
import AddNewTaxRate from "./AddNewTaxRate";
import ConfirmDelete from "../../../app/common/form/ConfirmDelete";

export default observer(function SalesTax() {
  const { t } = useTranslation(["common", "pricingSettings", "errors"]);
  const { pricingStore, userStore, modalStore } = useStore();
  const {
    loadSettingsTaxRate,
    createSettingsTaxRate,
    updateSettingsTaxRate,
    loadingInitial,
  } = pricingStore;
  const { checkApplication } = userStore;

  const decimalExp = new RegExp(RegexConstants.decimalExp);
  const validationSchema = Yup.object({
    taxRate: Yup.string()
      .required(t("pricing.required_taxrate", { ns: "errors" }))
      .matches(decimalExp, t("pricing.invalid_taxrate", { ns: "errors" }))
      .matches(
        new RegExp(RegexConstants.threeDecimalPlacesRegex),
        t("pricing.taxrate_length", { ns: "errors" })
      ),
    saleTaxOption: Yup.string().required(),
  });

  const [settingsTaxRate, setSettingsTaxRate] =
    useState<SettingsTaxRateFormValues>(new SettingsTaxRateFormValues());

  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadValues();
  }, [loadSettingsTaxRate]);

  function loadValues() {
    loadSettingsTaxRate().then((taxRate) =>
      setSettingsTaxRate(new SettingsTaxRateFormValues(taxRate))
    );
  }

  function handleFormSubmit(
    values: SettingsTaxRateFormValues,
    setErrors: (errors: FormikErrors<SettingsTaxRateFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsTaxRate(values)
      : () => createSettingsTaxRate(values);

    action()
      .then(() => {
        loadValues();
        setSubmitting(false);
        setCompleted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("salesTax_loading", { ns: "pricingSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("salestax", { ns: "common" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />

      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsTaxRate}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
              <Grid stackable>
                {checkApplication(Application.AutomotiveExpert) && (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          color={"green"}
                          className="save_button"
                          icon="plus"
                          type="button"
                          content={t("tax_rates.new", {
                            ns: "settings",
                          })}
                          onClick={() => {
                            modalStore.openModal(<AddNewTaxRate />);
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Table>
                          <Table.Header>
                            <Table.HeaderCell textAlign="left"></Table.HeaderCell>
                            <Table.HeaderCell textAlign="left">
                              {t("tax_rates.taxName", {
                                ns: "settings",
                              })}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              {t("tax.rate", { ns: "pricingSettings" })}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              {t("tax.apply2", { ns: "pricingSettings" })}
                            </Table.HeaderCell>
                            <Table.HeaderCell textAlign="right"></Table.HeaderCell>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell>
                                <Button
                                  color="grey"
                                  icon="edit"
                                  type="button"
                                  onClick={() => {
                                    modalStore.openModal(<AddNewTaxRate />);
                                  }}
                                ></Button>
                              </Table.Cell>
                              <Table.Cell>Tax Name Here</Table.Cell>
                              <Table.Cell>Rate % Here</Table.Cell>
                              <Table.Cell>Applies to here</Table.Cell>
                              <Table.Cell textAlign="right">
                                <Button
                                  color="grey"
                                  icon="trash"
                                  type="button"
                                  onClick={() =>
                                    modalStore.openModal(
                                      <ConfirmDelete
                                        header={t("tax.remove", {
                                          ns: "pricingSettings",
                                        })}
                                      />
                                    )
                                  }
                                ></Button>
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={"7"}>
                        <MyIconTextInput
                          name="maxRate"
                          placeholder={t("tax.maxRate", {
                            ns: "pricingSettings",
                          })}
                          icon="dollar"
                          iconPosition="left"
                          label={`${t("tax.maxRate", {
                            ns: "pricingSettings",
                          })}:`}
                          className="drop-down required"
                          showHelp={false}
                          maxLength={500}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
                  <>
                    <Grid.Row>
                      <Grid.Column width={"7"}>
                        <MyIconTextInput
                          name="taxRate"
                          placeholder={t("tax.rate", { ns: "pricingSettings" })}
                          icon="percent"
                          label={`${t("tax.rate", { ns: "pricingSettings" })}:`}
                          className="drop-down required"
                          showHelp={true}
                          helpName="settings.pricing.salesTaxRate"
                          maxLength={500}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <label className="mylabel required">
                          {`${t("tax.apply", { ns: "pricingSettings" })}:`}
                        </label>
                        <MyHelpButton
                          name="saleTaxOptionHelp"
                          position="bottom left"
                          help="settings.pricing.salesTaxOptions"
                          color={"blue"}
                          seeMore={true}
                        />

                        <Form.Group grouped>
                          {taxOptions.map((data, index) => (
                            <Form.Field key={`saleTaxOption[${data.value}]`}>
                              <Radio
                                id={`saleTaxOption[${index}]`}
                                key={`saleTaxOption[${data.value}]`}
                                value={data.value}
                                name={"saleTaxOption"}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={data.text}
                                checked={
                                  values.saleTaxOption.toString() ===
                                  data.value.toString()
                                }
                              />
                            </Form.Field>
                          ))}
                        </Form.Group>

                        {touched.saleTaxOption && errors.saleTaxOption && (
                          <Label basic color="red">
                            {errors.saleTaxOption}
                          </Label>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          color="green"
                          className="save_button"
                          icon="check"
                          content={t("save", { ns: "common" })}
                          disabled={isSubmitting || !dirty || !isValid}
                          loading={isSubmitting}
                          type="submit"
                        />
                        <Button
                          className="save_button"
                          as={Link}
                          to="/settings"
                          icon="cancel"
                          type="reset"
                          content={t("cancel", { ns: "common" })}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
