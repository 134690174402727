import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { CompanyApprovalReviewDTO, ClientList } from "../../models/systemAdmin";

export default class ClientReviewStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  CompanyApprovalReviewListRegistry = new Map<
    string,
    CompanyApprovalReviewDTO
  >();
  CompanyPhoneNumberRegistry = new Map<string, ClientList>();
  CompanyAddressRegistry = new Map<string, ClientList>();

  loadCompanyApprovalReviewList = async () => {
    this.loadingInitial = true;
    try {
      this.CompanyApprovalReviewListRegistry.clear();
      const result =
        await agent.SystemAdminClient.getCompanyApprovalReviewList();
      runInAction(() => {
        result.forEach((companyApprovalReview) => {
          this.setCompanyApprovalReviewList(companyApprovalReview);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setCompanyApprovalReviewList = (
    companyApprovalReview: CompanyApprovalReviewDTO
  ) => {
    companyApprovalReview.createdDate = new Date(
      companyApprovalReview.createdDate
    );
    this.CompanyApprovalReviewListRegistry.set(
      companyApprovalReview.clientId,
      companyApprovalReview
    );
  };

  get getCompanyApprovalReviewList() {
    return Array.from(this.CompanyApprovalReviewListRegistry.values()).sort(
      (a, b) => a.createdDate.getTime() - b.createdDate.getTime()
    );
  }

  approveCompany = async (id: string) => {
    try {
      await agent.SystemAdminClient.approveCompany(id);
    } catch (error) {
      console.log(error);
    } finally {
      this.CompanyApprovalReviewListRegistry.delete(id);
    }
  };

  rejectCompany = async (id: string) => {
    try {
      await agent.SystemAdminClient.rejectCompany(id);
    } catch (error) {
      console.log(error);
    } finally {
      this.CompanyApprovalReviewListRegistry.delete(id);
    }
  };

  loadCompanyPhoneNumbersList = async (id: string, phoneNumber: string) => {
    this.loadingInitial = true;
    try {
      this.CompanyPhoneNumberRegistry.clear();
      const result = await agent.SystemAdminClient.getCompanyPhoneNumberList(id, phoneNumber);
      runInAction(() => {
        result.forEach((numberList) => {
          this.setCompanyPhoneNumberList(numberList);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setCompanyPhoneNumberList = (clientNumberList: ClientList) => {
    clientNumberList.createdDate = new Date(clientNumberList.createdDate);
    this.CompanyPhoneNumberRegistry.set(clientNumberList.id, clientNumberList);
  };

  get getCompanyPhoneNumberList() {
    return Array.from(this.CompanyPhoneNumberRegistry.values()).sort(
      (a, b) => a.createdDate.getTime() - b.createdDate.getTime()
    );
  }

  loadCompanyAddressList = async (id: string) => {
    this.loadingInitial = true;
    try {
      this.CompanyAddressRegistry.clear();
      const result = await agent.SystemAdminClient.getCompanyAddressList(id);
      runInAction(() => {
        result.forEach((addressList) => {
          this.setCompanyAddressList(addressList);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setCompanyAddressList = (clientAddressList: ClientList) => {
    clientAddressList.createdDate = new Date(clientAddressList.createdDate);
    this.CompanyAddressRegistry.set(clientAddressList.id, clientAddressList);
  };

  get getCompanyAddressList() {
    return Array.from(this.CompanyAddressRegistry.values()).sort(
      (a, b) => a.createdDate.getTime() - b.createdDate.getTime()
    );
  }


  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
}
