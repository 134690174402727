import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useStore } from "../stores/store";
import { AccessLevel, Application, ClientStatus } from "../models/enums";

interface Props {
  accessLevel?: AccessLevel;
  application?: Application;
  status?: ClientStatus;
  allowExpired?: boolean;
}

export default function RequireAuth({
  accessLevel,
  application,
  status,
}: Readonly<Props>) {
  const {
    userStore: {
      isLoggedIn,
      checkStatus,
      checkAccessLevel,
      checkApplication,
      user,
    },
  } = useStore();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (status && !checkStatus(status)) {
    //not allowed due to statues
    return <Navigate to="/" state={{ from: location }} />;
  }

  if (accessLevel && !checkAccessLevel(accessLevel)) {
    return <Navigate to="/AccessDenied" state={{ from: location }} />;
  }

  if (application && !checkApplication(application))
    return <Navigate to="/UpgradeAccount" state={{ from: location }} />;

  return <Outlet />;
}
