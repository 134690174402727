export interface SettingsLaborRates {
  id?: string;
  isActive: boolean;
  name: string;
  rate: number;
  order: number;
  error?: string;
}

export interface SettingsPayType {
  id?: string;
  name: string;
  isActive: boolean;
  isStandard: boolean;
  isPaymentProcessor: boolean;
  error?: string;
}

export class SettingsPayType implements SettingsPayType {
  constructor(init?: SettingsPayTypeFormValues) {
    if (init) {
      this.id = init.id;
      this.name = init.name;
      this.isActive = init.isActive.toString() === "true";
      this.isStandard = init.isActive.toString() === "true";
      this.isPaymentProcessor = init.isPaymentProcessor;
      this.error = init.error;
    }
  }
}

export class SettingsPayTypeFormValues {
  id?: string = undefined;
  name: string = "";
  isActive: boolean = false;
  isStandard: boolean = false;
  isPaymentProcessor: boolean = false;
  error?: string = undefined;

  constructor(settingsPayType?: SettingsPayTypeFormValues) {
    if (settingsPayType) {
      this.id = settingsPayType.id;
      this.name = settingsPayType.name;
      this.isActive = settingsPayType.isActive;
      this.isStandard = settingsPayType.isStandard;
      this.isPaymentProcessor = settingsPayType.isPaymentProcessor;
      this.error = settingsPayType.error;
    }
  }
}

export interface SettingsPricing {
  id?: string;
  laborTimeHighIsActive: boolean;
  laborTimeAverageIsActive: boolean;
  laborTimeLowIsActive: boolean;
  taxRate: number;

  laborRates: SettingsLaborRates[];

  error?: string;
}

export class SettingsPricing implements SettingsPricing {
  constructor(init?: SettingsPricingFormValues) {
    if (init) {
      this.id = init.id;
      this.laborTimeHighIsActive =
        init.laborTimeHighIsActive.toString() === "true";
      this.laborTimeAverageIsActive =
        init.laborTimeAverageIsActive.toString() === "true";
      this.laborTimeLowIsActive =
        init.laborTimeLowIsActive.toString() === "true";
      this.taxRate = Number(init.taxRate);
      this.laborRates = init.laborRates;
      this.error = init.error;
    }
  }
}

export class SettingsPricingFormValues {
  id?: string = undefined;
  laborTimeHighIsActive: boolean = false;
  laborTimeAverageIsActive: boolean = false;
  laborTimeLowIsActive: boolean = false;
  taxRate: number = 0;
  laborRates: SettingsLaborRates[] = [];
  error?: string = undefined;

  constructor(settingsPricing?: SettingsPricingFormValues) {
    if (settingsPricing) {
      this.id = settingsPricing.id;
      this.laborTimeHighIsActive = settingsPricing.laborTimeHighIsActive;
      this.laborTimeAverageIsActive = settingsPricing.laborTimeAverageIsActive;
      this.laborTimeLowIsActive = settingsPricing.laborTimeLowIsActive;
      this.taxRate = settingsPricing.taxRate;
      this.laborRates = settingsPricing.laborRates;
      this.error = settingsPricing.error;
    }
  }
}

export interface SettingsTaxRate {
  id?: string;
  taxRate: number;
  saleTaxOption: number;
  error?: string;
}

export class SettingsTaxRate implements SettingsTaxRate {
  constructor(init?: SettingsTaxRateFormValues) {
    if (init) {
      this.id = init.id;
      this.taxRate = Number(init.taxRate);
      this.saleTaxOption = Number(init.saleTaxOption);
      this.error = init.error;
    }
  }
}

export class SettingsTaxRateFormValues {
  id?: string = undefined;
  taxRate: number = 0;
  saleTaxOption: number = 0;
  error?: string = undefined;

  constructor(settingsTaxRate?: SettingsTaxRateFormValues) {
    if (settingsTaxRate) {
      this.id = settingsTaxRate.id;
      this.taxRate = settingsTaxRate.taxRate;
      this.saleTaxOption = settingsTaxRate.saleTaxOption;
      this.error = settingsTaxRate.error;
    }
  }
}

export class SettingsLaborRatesFormValues {
  id?: string = undefined;
  isActive: boolean = false;
  name: string = "";
  rate: number = 0;
  order: number = 0;
  error?: string = undefined;

  constructor(settingsLaborRates?: SettingsLaborRatesFormValues) {
    if (settingsLaborRates) {
      this.id = settingsLaborRates.id;
      this.isActive = settingsLaborRates.isActive;
      this.name = settingsLaborRates.name;
      this.rate = Number(settingsLaborRates.rate);
      this.order = settingsLaborRates.order;
      this.error = settingsLaborRates.error;
    }
  }
}
