import React, { useState } from "react";
import { Button, Divider, Form, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";
import MyTextInput from "../../../../app/common/form/MyTextInput";
import { useStore } from "../../../../app/stores/store";
import { observer } from "mobx-react-lite";
import PageHeader from "../../../../app/layout/PageHeader";
import { PONumberFormValues } from "../../../../app/models/ticket";
import { TicketWorkflowSteps } from "../../../../app/models/enums";

interface Props {
  autoCapitalizeFirstLetter: boolean;
  updateData: boolean;
  ticketWorkflowStep: TicketWorkflowSteps;
  ticketId: string;
}

export default observer(function PONumberForm({
  autoCapitalizeFirstLetter,
  updateData,
  ticketWorkflowStep,
  ticketId,
}: Props) {
  const { t } = useTranslation(["common", "translation", "tickets"]);
  const { ticketSessionStore, modalStore, customerStore, vehicleStore } =
    useStore();
  const {
    setSelectedPONumber,
    poNumber,
    updateTicketSession,
  } = ticketSessionStore;
  const { selectedVehicle } = vehicleStore;
  const { selectedCustomer } = customerStore;

  function handleFormSubmit(values: PONumberFormValues) {
    setSelectedPONumber(values.value);

    if (updateData && ticketWorkflowStep && ticketId) {
      //store data
        updateTicketSession(
          ticketWorkflowStep,
          ticketId,
          selectedVehicle,
          selectedCustomer
        );
    }

    modalStore.closeModal();
  }

   const validationSchema = Yup.object({});
  //   value: Yup.string().required("P.O. is Required"),
  // });

  const [myPONumber] = useState<PONumberFormValues>(poNumber);

  return (
    <>
      <PageHeader
        header={t("pops.edit_po", {
          ns: "tickets",
        })}
        type={"h3"}
        divider={true}
        addTitle={false}
      ></PageHeader>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={myPONumber}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
        }) => (
          <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
            <MyTextInput
              placeholder="0000"
              name="value"
              autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
              maxLength={100}
            />
            <Divider />
            <Button className="save_button" color="green" type="submit">
              <Icon name="check" />
              {t("update", { ns: "common" })}
            </Button>
            <Button
              className="save_button"
              onClick={() => modalStore.closeModal()}
              type="reset"
            >
              <Icon name="x" />
              {t("cancel", { ns: "common" })}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
});
