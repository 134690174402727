import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button, Container, Form, Grid, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors, FormikState } from "formik";
import MyTextInput from "../../../app/common/form/MyTextInput";
import { Link } from "react-router-dom";
import { useStore } from "../../../app/stores/store";
import { SettingsPaymentProcessorFormValues } from "../../../app/models/paymentProcessing";
import PageHeader from "../../../app/layout/PageHeader";
import ValidationErrors from "../../errors/ValidationErrors";
import MyCheckbox from "../../../app/common/form/MyCheckbox";

export default observer(function PaymentProcessingUnPair() {
  const { t } = useTranslation(["common", "advancedSettings", "errors"]);

  const { paymentProcessingStore } = useStore();

  const {
    loadSettingsPaymentProcessor,
    unPairSettingsPaymentProcessor,
    selectedPaymentProcessor,
  } = paymentProcessingStore;

  const validationSchema = Yup.object({
    removeTerminal: Yup.boolean().required(
      "In order to unpair you must click here"
    ),
  });

  const [completed, setCompleted] = useState<boolean>(false);

  function handleFormSubmit(
    values: SettingsPaymentProcessorFormValues,
    setErrors: (
      errors: FormikErrors<SettingsPaymentProcessorFormValues>
    ) => void,
    setSubmitting: (isSubmitting: boolean) => void,
    resetForm: (
      nextState?:
        | Partial<FormikState<SettingsPaymentProcessorFormValues>>
        | undefined
    ) => void
  ) {
    const action = () => unPairSettingsPaymentProcessor();

    action()
      .then(() => {
        setSubmitting(false);
        setCompleted(true);
        loadSettingsPaymentProcessor();
        resetForm({ values });
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  return (
    <Container className="page">
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={selectedPaymentProcessor}
        onSubmit={(values, { setErrors, setSubmitting, resetForm }) =>
          handleFormSubmit(values, setErrors, setSubmitting, resetForm)
        }
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setErrors,
        }) => (
          <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
            {completed && isValid && (
              <Message positive>
                {t("settingsupdated", {
                  ns: "common",
                })}
              </Message>
            )}
            <ErrorMessage
              name="error"
              render={() => <ValidationErrors errors={errors.error} />}
            />
            <PageHeader
              header={t("payprocessing.activeTerminal", {
                ns: "advancedSettings",
              })}
              type={"h2"}
              divider={true}
              addTitle={true}
            />
            <Grid stackable columns={3}>
              <Grid.Row>
                <Grid.Column>
                  <MyTextInput
                    placeholder={t("payprocessing.serial", {
                      ns: "advancedSettings",
                    })}
                    label={`${t("payprocessing.serial", {
                      ns: "advancedSettings",
                    })}:`}
                    name={"terminalSerialNumber"}
                    disabled={true}
                    autoCapitalizeFirstLetter={false}
                    maxLength={255}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <MyCheckbox
                    name={"removeTerminal"}
                    disabled={false}
                    label="Unpair and disable terminals"
                    className="required"
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Button
                    color="green"
                    className="save_button"
                    icon="check"
                    content={t("save", { ns: "common" })}
                    disabled={isSubmitting || !isValid || !dirty}
                    loading={isSubmitting}
                    type="submit"
                  />
                  <Button
                    className="save_button"
                    as={Link}
                    to="/settings"
                    icon="cancel"
                    type="reset"
                    content={t("cancel", { ns: "common" })}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
});
