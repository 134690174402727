import { useField } from "formik";
import React from "react";
import { Button, Form, Icon, Label } from "semantic-ui-react";
import MyHelpButton from "./MyHelpButton";

interface Props {
  placeholder: string;
  name: string;
  type?: string;
  label?: string;
  className?: string;
  showHelp?: boolean;
  helpName?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  myValidate?: (value: string) => void;
  helpSeeMore?: boolean;
  showDefaults?: boolean;
  onSetDefaults?: (value: string) => void;
  autoCapitalizeFirstLetter: boolean;
  maxLength: number;
  onBlur?: (value: any) => void;
}

export default function MyTextInput({
  placeholder,
  name,
  type,
  label,
  className,
  showHelp,
  helpName,
  disabled,
  autoFocus,
  myValidate,
  helpSeeMore,
  showDefaults,
  onSetDefaults,
  autoCapitalizeFirstLetter,
  maxLength,
  onBlur
}: Readonly<Props>) {
  const [field, meta] = useField(name);
  let labelClassName: string = "myLabel";
  let inputClassName: string = "input";
  if (className) labelClassName = `${labelClassName} ${className}`;
  if (className) inputClassName = `${inputClassName} ${className}`;

  return (
    <Form.Field error={meta.touched && !!meta.error} className="ui" name={name}>
      {label && label.length > 0 && (
        <label className={labelClassName} htmlFor={name}>
          {`${label} `}
          {showHelp && (
            <MyHelpButton
              name={`${name}.help`}
              help={helpName ?? ""}
              seeMore={helpSeeMore}
              color={"blue"}
            />
          )}
          {showDefaults && (
            <Button
              size={"mini"}
              basic
              floated="right"
              compact
              type="button"
              color="green"
              onClick={() => {
                if (onSetDefaults) onSetDefaults("");
              }}
            >
              <Icon floated="right" color="green" name="copy outline" />
            </Button>
          )}
        </label>
      )}
      <input
        {...field}
        placeholder={placeholder}
        name={name}
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        id={name}
        autoFocus={autoFocus ?? false}
        onInput={(e) => {
          if (autoCapitalizeFirstLetter) {
            const value = e.currentTarget.value;
            if (value) {
              e.currentTarget.value =
                value.charAt(0).toUpperCase() + value.slice(1);
            }
          }
          if (myValidate) myValidate(field.value);
        }}
        className={inputClassName}
        value={
          (field.value != null ? field.value.toString() : undefined) ||
          undefined
        }
        spellCheck={true}
        onBlur={(e: any) => {
          field.onBlur(e);
          if (onBlur) onBlur(field.value);
        }}
      />
      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
