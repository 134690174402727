import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StateSelect from "../../app/common/form/StateSelect";
import MyCheckbox from "../../app/common/form/MyCheckbox";
import MyTextInput from "../../app/common/form/MyTextInput";
import { observer } from "mobx-react-lite";
import { useFormikContext } from "formik";
import { Grid } from "semantic-ui-react";
import PhoneNumberInput from "../../app/common/form/PhoneNumberInput";
import { yesNoOptions } from "../../app/common/options/option";
import MyRadioGroup from "../../app/common/form/RadioButtonGroups/MyRadioGroup";
import { SettingsTicketInputDefaultsFormValues } from "../../app/models/ticket";
import { PhoneNumberType } from "../../app/models/enums";

interface Props {
  isTicket: boolean;
  showDefaults?: boolean;
  ticketInputDefaults: SettingsTicketInputDefaultsFormValues;
  initialIsCommercial: boolean;
}

export default observer(function CustomerEdit({
  isTicket,
  showDefaults,
  ticketInputDefaults,
  initialIsCommercial,
}: Props) {
  const { t } = useTranslation([
    "common",
    "translation",
    "tickets",
    "customer",
  ]);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  const [isCommercial, setIsCommercial] =
    useState<boolean>(initialIsCommercial);

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <MyCheckbox
            label={t("business_customer", { ns: "customer" })}
            name={"isCommercial"}
            disabled={false}
            updateValue={(d, e) => {
              setIsCommercial(d ?? false);
              if (!d) {
                setFieldValue("companyName", undefined);
                setFieldTouched("companyName", false);
              }
            }}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <MyTextInput
            label={`${t("first_name", { ns: "common" })}:`}
            placeholder={t("first_name", { ns: "common" })}
            name="firstName"
            autoCapitalizeFirstLetter={
              ticketInputDefaults.capitalizeFirstCharacter
            }
            maxLength={255}
          />
        </Grid.Column>
        <Grid.Column>
          <MyTextInput
            label={`${t("last_name", { ns: "common" })}:`}
            placeholder={t("last_name", { ns: "common" })}
            name="lastName"
            className={!isCommercial ? "required" : ""}
            autoCapitalizeFirstLetter={
              ticketInputDefaults.capitalizeFirstCharacter
            }
            maxLength={255}
          />
        </Grid.Column>
      </Grid.Row>
      {isCommercial && (
        <Grid.Row>
          <MyTextInput
            label={`${t("companyname", { ns: "common" })}:`}
            placeholder={t("companyname", { ns: "common" })}
            name={"companyName"}
            className={isCommercial ? "required" : ""}
            autoCapitalizeFirstLetter={
              ticketInputDefaults.capitalizeFirstCharacter
            }
            maxLength={255}
          />
        </Grid.Row>
      )}
      <Grid.Row columns={2}>
        <Grid.Column>
          <PhoneNumberInput
            label={
              isCommercial
                ? `${t("main", { ns: "customer" })}:`
                : `${t("cell", { ns: "common" })}:`
            }
            placeholder={
              isCommercial
                ? `${t("main", { ns: "customer" })}`
                : `${t("cell", { ns: "common" })}`
            }
            name="primaryNumber"
            autoFormat={ticketInputDefaults.formatPhoneNumber}
            phoneType={
              isCommercial ? PhoneNumberType.Commercial : PhoneNumberType.Mobile
            }
          />
        </Grid.Column>
        <Grid.Column>
          <PhoneNumberInput
            label={
              isCommercial
                ? `${t("work", { ns: "customer" })}:`
                : `${t("home", { ns: "common" })}:`
            }
            placeholder={
              isCommercial
                ? `${t("work", { ns: "customer" })}`
                : `${t("home", { ns: "common" })}`
            }
            name="secondaryNumber"
            autoFormat={ticketInputDefaults.formatPhoneNumber}
            phoneType={
              isCommercial
                ? PhoneNumberType.Commercial
                : PhoneNumberType.Landline
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <MyTextInput
            label={`${t("email", { ns: "common" })}:`}
            placeholder={t("email", { ns: "common" })}
            name="email"
            autoCapitalizeFirstLetter={false}
            maxLength={255}
          />
        </Grid.Column>
        <Grid.Column>
          <PhoneNumberInput
            label={              isCommercial
              ? `${t("fax", { ns: "common" })}:`
              : `${t("work", { ns: "customer" })}:`}
            placeholder={              isCommercial
              ? `${t("fax", { ns: "common" })}`
              : `${t("work", { ns: "customer" })}`}
            name="ternaryNumber"
            autoFormat={ticketInputDefaults.formatPhoneNumber}
            phoneType={
              isCommercial ? PhoneNumberType.Fax : PhoneNumberType.Commercial
            }
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <MyTextInput
            label={`${t("address.address", { ns: "common" })}:`}
            placeholder={t("address.address", { ns: "common" })}
            name="addressLine1"
            autoCapitalizeFirstLetter={
              ticketInputDefaults.capitalizeFirstCharacter
            }
            maxLength={500}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <MyTextInput
            label={`${t("address.address2", { ns: "common" })}:`}
            placeholder={t("address.address2", { ns: "common" })}
            name="addressLine2"
            autoCapitalizeFirstLetter={
              ticketInputDefaults.capitalizeFirstCharacter
            }
            maxLength={500}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <MyTextInput
            label={`${t("address.city", { ns: "common" })}:`}
            placeholder={t("address.city", { ns: "common" })}
            name="city"
            showDefaults={
              showDefaults &&
              ticketInputDefaults?.defaultCity !== undefined &&
              ticketInputDefaults.defaultCity?.length > 0
            }
            onSetDefaults={() => {
              if (ticketInputDefaults.defaultCity)
                setFieldValue("city", ticketInputDefaults.defaultCity);
            }}
            autoCapitalizeFirstLetter={
              ticketInputDefaults.capitalizeFirstCharacter
            }
            maxLength={255}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column>
          <StateSelect
            label={`${t("address.state", { ns: "common" })}:`}
            placeholder={t("address.state", { ns: "common" })}
            name="state"
            showDefaults={
              showDefaults &&
              ticketInputDefaults?.defaultState !== undefined &&
              ticketInputDefaults.defaultState?.length > 0
            }
            onSetDefaults={() => {
              if (ticketInputDefaults.defaultState)
                setFieldValue("state", ticketInputDefaults.defaultState);
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <MyTextInput
            label={`${t("address.postalcode", { ns: "common" })}:`}
            placeholder={t("address.postalcode", { ns: "common" })}
            name="postalCode"
            showDefaults={
              showDefaults &&
              ticketInputDefaults?.defaultPostalCode !== undefined &&
              ticketInputDefaults.defaultPostalCode?.length > 0
            }
            onSetDefaults={() => {
              if (ticketInputDefaults.defaultPostalCode)
                setFieldValue(
                  "postalCode",
                  ticketInputDefaults.defaultPostalCode
                );
            }}
            autoCapitalizeFirstLetter={
              ticketInputDefaults.capitalizeFirstCharacter
            }
            maxLength={50}
          />
        </Grid.Column>
      </Grid.Row>
      {!isTicket && (
        <Grid.Row>
          <Grid.Column>
            <MyRadioGroup
              name={"isTaxExempt"}
              label={`${t("tax_exempt", { ns: "customer" })}:`}
              options={yesNoOptions}
              inline={true}
            />
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
});
