import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../../app/layout/PageHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import RecentTicketsItem from "./RecentTicketsItem";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";

export default observer(function RecentTickets() {
  const { t } = useTranslation(["common", "recentTickets"]);
  const { ticketStore } = useStore();

  const {
    loadingInitial,
    pagingParams,
    setPagingParams,
    pagination,
    loadTickets,
    ticketsByDate,
    resetRecentTickets
  } = ticketStore;

  //TODO fix infinite scroller
  // const [loadingNext, setLoadingNext] = useState(false);

  // function handleGetNext() {
  //   setLoadingNext(true);
  //   setPagingParams(new PagingParams(pagination!.currentPage + 1));
  //   loadTickets().then(() => setLoadingNext(false));
  // }

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value, 25));
      resetRecentTickets();
      loadTickets();
    }
  }

  useEffect(() => {
    setPagingParams(new PagingParams(1, 25));
    resetRecentTickets();
    loadTickets();
  }, [loadTickets, resetRecentTickets,setPagingParams ]);

  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <>
      <PageHeader
        header={t("title", { ns: "recentTickets" })}
        type={"h1"}
        divider={true}
        addTitle={true}
      />
        <Table celled className="recentsMobile">
          <Table.Header className="recentSticky">
            <Table.Row>
              <Table.HeaderCell width={5} textAlign="center">
                {t("customer", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell width={5} textAlign="center">
                {t("vehicle", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell width={5} textAlign="center">
                {t("ticket", { ns: "common" })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {ticketsByDate &&
              ticketsByDate.length > 0 &&
              ticketsByDate.map((ticket, index) => (
                <Table.Row key={`recentTicketItem.${ticket.ticketId}`}>
                  <Table.Cell colSpan={3}>
                    <RecentTicketsItem
                      ticket={ticket}
                      initialOpen={index === 0}
                      key={ticket.ticketId}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan="6">
              <MyPaging
                itemCount={pagination ? pagination.totalItems : 10}
                pageSize={pagingParams.pageSize}
                currentPage={pagination ? pagination.currentPage : 1}
                onClick={listOnClick}
                onChange={listOnClick}
                floated="right"
              />
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
        </Table>
    </>
  );
});
