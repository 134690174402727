import { useEffect, useState } from "react";
import { Button, Grid, Header } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import PhotoUploadWidgetDropzone from "./PhotoWidgetDropzone";
import PhotoWidgetCropper from "./PhotoWidgetCropper";
import { useTranslation } from "react-i18next";

interface Props {
  loading: boolean;
  uploadPhoto: (file: Blob, fileName: string) => void;
  setInvalidType: (value: boolean) => void;
}

export default observer(function PhotoUploadWidget({
  loading,
  uploadPhoto,
  setInvalidType
}: Props) {
  const { t } = useTranslation(["common"]);
  const [files, setFiles] = useState<any>([]);
  const [filePath, setFilesPath] = useState<string>("");
  const [cropper, setCropper] = useState<Cropper>();

  function onCrop() {

    if (cropper && cropper.getCroppedCanvas()) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadPhoto(blob!, filePath);
      });
      setInvalidType(false);
    }
    else {
      setInvalidType(true);
    }
  }

  useEffect(() => {
    return () => {
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  return (
    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Header
            color="teal"
            sub
            content={t("images.step1", { ns: "common" })}
          />
          <PhotoUploadWidgetDropzone
            setFiles={setFiles}
            setFileName={setFilesPath}
          />
        </Grid.Column>
        <Grid.Column>
          <Header
            sub
            color="teal"
            content={t("images.step2", { ns: "common" })}
          />
          {files && files.length > 0 && (
            <PhotoWidgetCropper
              setCropper={setCropper}
              imagePreview={files[0].preview}
            />
          )}
        </Grid.Column>
        <Grid.Column width={4}>
          <Header
            sub
            color="teal"
            content={t("images.step3", { ns: "common" })}
          />
          <div
            className="img-preview"
            style={{ minHeight: 150, minWidth: 330, overflow: "hidden" }}
          />
          {files && files.length > 0 && (
            <Button.Group widths={2}>
              <Button
                loading={loading}
                onClick={onCrop}
                positive
                icon="check"
                content={t("images.uploadLogo", { ns: "common" })}
              />
              <Button
                disabled={loading}
                onClick={() => {
                  setFiles([]);
                  setInvalidType(false);
                }}
                icon="close"
                content={t("images.clear", { ns: "common" })}
              />
            </Button.Group>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
});
