import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../../api/agent";
import { Pagination, PagingParams } from "../../../models/pagination";
import { AuthorizeNetReport } from "../../../models/systemAdmin";
import { Application, CreditCardType, PaymentStatus, SubscriptionType, TermEnum, TransactionType } from "../../../models/enums";

export default class AuthorizeNetReportStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.authorizeNetRegistry.clear();
      }
    );
  }

  loading = false;

  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  setLoading = (state: boolean) => {
    this.loading = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  authorizeNetRegistry = new Map<string, AuthorizeNetReport>();

  loadAuthorizeNetReport = async (date: Date) => {
    try {
      this.setLoading(true);
      this.authorizeNetRegistry.clear();
      const result = await agent.SystemAdminReports.getAuthorizeNetReport(date);
      runInAction(() => {
        result.forEach((x) => {
          this.setAuthorizeNetReport(x);
        });
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  private setAuthorizeNetReport = (authorizeNetReport: AuthorizeNetReport) => {
    if (authorizeNetReport.reportDate)
      authorizeNetReport.reportDate = new Date(authorizeNetReport.reportDate);

    // Additional users does not set the app bit, so do it manually so we can put in the appropriate service type row.
    if (authorizeNetReport.subscriptionType && authorizeNetReport.subscriptionType & SubscriptionType.AdditionalUsers) {
      // Unset this so we don't include with the app upgrades
      authorizeNetReport.subscriptionType &= ~SubscriptionType.Upgrade;
      switch (authorizeNetReport.application) {
        case Application.RealTimeLaborGuide:
          authorizeNetReport.subscriptionType = authorizeNetReport.subscriptionType | SubscriptionType.Basic;
          break;
        case Application.RealTimeLaborGuidePro:
          authorizeNetReport.subscriptionType = authorizeNetReport.subscriptionType | SubscriptionType.Pro;
          break;
      }
    }

    this.authorizeNetRegistry.set(
      authorizeNetReport.transactionId ?? "",
      authorizeNetReport
    );
  };

  get getTransactions() {
    return Array.from(this.authorizeNetRegistry.values());
  }

  get getApprovedTransactions() {
    return Array.from(this.authorizeNetRegistry.values()).filter(
      (x) => (x.transactionType === TransactionType.Sale && x.paymentStatus === PaymentStatus.Succeeded) 
        || (x.paymentStatus == null && x.status === 'settledSuccessfully'));
  }

  get getDeclinedTransactions() {
    return Array.from(this.authorizeNetRegistry.values()).filter(
      (x) => x.status === "declined"
    );
  }

  get getVoidedTransactions() {
    return Array.from(this.authorizeNetRegistry.values()).filter(
      (x) => x.transactionType === TransactionType.Void && (x.paymentStatus === PaymentStatus.Succeeded)
    );
  }

  get getRefundedTransactions() {
    return Array.from(this.authorizeNetRegistry.values()).filter(
      (x) => (x.transactionType === TransactionType.Refund && x.paymentStatus === PaymentStatus.Succeeded)
        || (x.paymentStatus == null && x.status === 'refundSettledSuccessfully')
    );
  }

  private getTransactionsByCardType(cards: CreditCardType[]) {
    return this.getApprovedTransactions
      .concat(this.getVoidedTransactions)
      .concat(this.getRefundedTransactions)
      .filter(x => cards.map(c => CreditCardType[c]).includes(x.cardType as string));
  }

  get getVisaMcTransactions() { 
    return this.getTransactionsByCardType([CreditCardType.Visa, CreditCardType.MasterCard]) 
  };

  get getAmexTransactions() {
    return this.getTransactionsByCardType([CreditCardType.AmericanExpress]) 
  }
  get getDiscoverTransactions() {
    return this.getTransactionsByCardType([CreditCardType.Discover]) 
  }

  public filterBySubscriptionTypeAll(data: AuthorizeNetReport[], bitmask: number, term?: TermEnum) {
    return data.filter(x => ((x.subscriptionType ?? 0) & bitmask) === x.subscriptionType && (!term || x.term === term));
  }

  public filterBySubscriptionTypeAny(data: AuthorizeNetReport[], bitmask: number) {
    return data.filter(x => ((x.subscriptionType ?? 0) & bitmask) > 0);
  }

  calculateTotals = (data: AuthorizeNetReport[]) => {
    const summary = data.reduce(
      (accumulator: AuthorizeNetReport, item: AuthorizeNetReport) => {
        accumulator.amount += item.amount ?? 0;
        return accumulator;
      },
      {
        amount: 0,
      }
    );
    return summary;
  };
}
