import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Container, Form, Grid, Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import { Link } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import PageHeader from "../../../app/layout/PageHeader";
import MyCheckbox from "../../../app/common/form/MyCheckbox";
import WholeNumberInput from "../../../app/common/form/WholeNumberInput";
import { useStore } from "../../../app/stores/store";
import { SettingsTicketNumberFormValues } from "../../../app/models/ticket";
import ValidationErrors from "../../errors/ValidationErrors";

export default observer(function Numbers() {
  const { t } = useTranslation(["common", "ticketSettings"]);
  const { ticketStore } = useStore();

  const {
    loadSettingsTicketNumber,
    loadingInitial,
    selectedSettingsTicketNumber,
    createSettingsTicketNumber,
    updateSettingsTicketNumber,
  } = ticketStore;

  const validationSchema = Yup.object({
    invoiceNumber: Yup.number()
      .min(
        selectedSettingsTicketNumber.invoiceNumber,
        t("ticketnumber.invoiceNumber", { ns: "errors" }) +
          selectedSettingsTicketNumber.invoiceNumber
      )
      .required(t("ticketnumber.required_invoiceNumber", { ns: "errors" }))
      .max(
        Number.MAX_SAFE_INTEGER,
        t("ticketnumber.max_invoiceNumber", { ns: "errors" })
      ),
    estimateNumber: Yup.number()
      .min(
        selectedSettingsTicketNumber.estimateNumber,
        t("ticketnumber.estimateNumber", { ns: "errors" }) +
          selectedSettingsTicketNumber.estimateNumber
      )
      .required(t("ticketnumber.required_estimateNumber", { ns: "errors" }))
      .max(
        Number.MAX_SAFE_INTEGER,
        t("ticketnumber.max_estimateNumber", { ns: "errors" })
      ),
    autoPONumber: Yup.number()
      .min(
        selectedSettingsTicketNumber.autoPONumber,
        t("ticketnumber.poNumber", { ns: "errors" }) +
          selectedSettingsTicketNumber.autoPONumber
      )
      .required(t("ticketnumber.required_PONumber", { ns: "errors" }))
      .max(
        Number.MAX_SAFE_INTEGER,
        t("ticketnumber.max_PONumber", { ns: "errors" })
      ),
  });

  const [settingsTicketNumber, setSettingsTicketNumber] =
    useState<SettingsTicketNumberFormValues>(
      new SettingsTicketNumberFormValues()
    );
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    loadValues();
  }, [loadSettingsTicketNumber]);

  function loadValues() {
    loadSettingsTicketNumber().then((settingsTicketNumber) =>
      setSettingsTicketNumber(
        new SettingsTicketNumberFormValues(settingsTicketNumber)
      )
    );
  }

  function handleFormSubmit(
    values: SettingsTicketNumberFormValues,
    setErrors: (errors: FormikErrors<SettingsTicketNumberFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsTicketNumber(values)
      : () => createSettingsTicketNumber(values);

    action()
      .then(() => {
        loadValues();
        setSubmitting(false);
        setCompleted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("numbers_loading", { ns: "ticketSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("numbers", { ns: "common" })}
        type="h2"
        divider
        getAlerts={true}
        showHelp
        helpName="settings.ticket.numbers"
        addTitle={true}
      />
      <Message floating warning>
        <span className="intro">
          {`${t("numbers.notice", { ns: "ticketSettings" })}: `}
        </span>
        {t("numbers.notice_info", { ns: "ticketSettings" })}
      </Message>
      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsTicketNumber}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <WholeNumberInput
                      placeholder={t("invoicenumber", { ns: "common" })}
                      name="invoiceNumber"
                      label={`${t("invoicenumber", { ns: "common" })}:`}
                      className="drop-down required"
                      showHelp={true}
                      helpName="settings.ticket.invoiceNumber"
                      maxLength={25}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <WholeNumberInput
                      placeholder={t("numbers.estimate", {
                        ns: "ticketSettings",
                      })}
                      name="estimateNumber"
                      label={`${t("numbers.estimate", {
                        ns: "ticketSettings",
                      })}:`}
                      className="drop-down required"
                      showHelp={true}
                      helpName="settings.ticket.estimateNumber"
                      maxLength={25}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <WholeNumberInput
                      placeholder={t("ponumber", { ns: "common" })}
                      name="autoPONumber"
                      label={`${t("ponumber", { ns: "common" })}:`}
                      className="drop-down required"
                      showHelp={true}
                      helpName="settings.ticket.poNumber"
                      maxLength={25}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <MyCheckbox
                      label={t("numbers.autoassignponumber", {
                        ns: "ticketSettings",
                      })}
                      name="assignAutoPO"
                      disabled={false}
                      showHelp={true}
                      helpName="settings.ticket.autoAssign"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color="green"
                      className="save_button"
                      icon="check"
                      content={t("save", { ns: "common" })}
                      disabled={isSubmitting || !dirty || !isValid}
                      loading={isSubmitting}
                      type="submit"
                    />
                    <Button
                      className="save_button"
                      as={Link}
                      to="/settings"
                      icon="cancel"
                      type="reset"
                      content={t("cancel", { ns: "common" })}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
