import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { useTranslation } from "react-i18next";
import MySelectInput from "../../app/common/form/MySelectInput";
import {
  Options,
  planIntervalOptions,
  serviceTypeOptions,
} from "../../app/common/options/option";
import { DropdownItemProps, Grid } from "semantic-ui-react";
import PageHeader from "../../app/layout/PageHeader";
import { formatCurrency } from "../../app/common/util/functions";
import { ClientSubscriptions } from "../../app/models/clientSubscription";
import MyRadioGroup from "../../app/common/form/RadioButtonGroups/MyRadioGroup";

interface Props {
  minimumUsers: number;
}

export default observer(function SubscriptionOptions({ minimumUsers }: Props) {
  const { subscriptionStore } = useStore();
  const { t } = useTranslation(["common", "subscription"]);
  const { calculateSubscription } = subscriptionStore;
  const { values } = useFormikContext<ClientSubscriptions>();

  let usersOption: DropdownItemProps[] = [];
  for (let i = minimumUsers; i < 100; i++) {
    let uOption: Options = {
      text:
        i > 1
          ? `${i} ${t("users", { ns: "common" })}`
          : `${i} ${t("user", { ns: "common" })}`,
      value: i,
    };
    usersOption.push(uOption);
  }

  const [amount, setAmount] = useState<number>(0.0);

  useEffect(() => {
    if (values.dowellSystemApplication && values.userCount && values.term)
      calculateSubscription(
        values.dowellSystemApplication ?? 0,
        values.userCount ?? 0,
        values.term ?? 0
      ).then((results) => {
        setAmount(results);
      });
  }, [values.dowellSystemApplication, values.term, values.userCount]);

  return (
    <>
      <Grid.Row>
        <Grid.Column>
          <PageHeader
            header={t("subscription", { ns: "common" })}
            type={"h2"}
            divider={true}
            getAlerts={true}
            addTitle={false}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <MyRadioGroup
          name={"dowellSystemApplication"}
          options={serviceTypeOptions}
          inline={true}
          label={`${t("service_type.label", {
            ns: "common",
          })}:`}
        />
      </Grid.Row>
      <Grid.Row>
        <MySelectInput
          name="userCount"
          placeholder={t("users", { ns: "common" })}
          options={usersOption}
          label={`${t("users", { ns: "common" })}:`}
          onChange={(d) => {
            if (d > 0 && values.dowellSystemApplication && values.term)
              calculateSubscription(
                values.dowellSystemApplication,
                Number(d),
                values.term
              ).then((amount) => setAmount(amount));
          }}
          clearable={true}
        />
      </Grid.Row>
      <Grid.Row>
        <MyRadioGroup
          name={"term"}
          options={planIntervalOptions}
          inline={true}
          label={`${t("subscription.renewalPeriod", {
            ns: "subscription",
          })}:`}
        />
      </Grid.Row>
      <Grid.Row>
        <label className="myLabel">{t("amount", { ns: "common" })}:</label>
        <br />
        <label className="myLabel">{formatCurrency(amount)}</label>
      </Grid.Row>
    </>
  );
});
