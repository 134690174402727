import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Menu, Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import AdminNotes from "./AdminNotes";
import AuthLogs from "./AuthLogs";
import CarfaxNightly from "./CarfaxNightly";
import { Link, useNavigate } from "react-router-dom";

interface Props {
  activeSubTab: number;
}


export default observer(function LogsTabs(props: Props) {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const navigate = useNavigate();

  const { systemAdminStore } = useStore();
  const { setActiveSubTab, activeSubTab } = systemAdminStore;

  const panes = [
    {
      menuItem: {
        as: Link,
        content: t("logs.notes", { ns: "systemAdmin" }),
        to: "/admin/Logs/AdminNotes",
        exact: "true",
      },
      render: () => (
        <Tab.Pane active={0 === activeSubTab}>
          <AdminNotes />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: t("logs.auth", { ns: "systemAdmin" }),
        to: "/admin/Logs/AuthLogs",
        exact: "true",
      },
      render: () => (
        <Tab.Pane active={1 === activeSubTab}>
          <AuthLogs />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: t("logs.carfax", { ns: "systemAdmin" }),
        to: "/admin/Logs/CarFaxNightly",
        exact: "true",
      },
      render: () => (
        <Tab.Pane active={2 === activeSubTab}>
          <CarfaxNightly />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Tab
        menu={{ fluid: true }}
        menuPosition="left"
        panes={panes}
        activeIndex={activeSubTab}
        onTabChange={(e, data) => {
          setActiveSubTab(data.activeIndex);
        }}
      />
    </>
  );
});
