import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Icon,
  Radio,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import { TicketItem, TicketItemFormValues } from "../../app/models/ticket";
import { useStore } from "../../app/stores/store";
import PageHeader from "../../app/layout/PageHeader";
import { observer } from "mobx-react-lite";
import { TicketItemType } from "../../app/models/enums";
import MyTextInput from "../../app/common/form/MyTextInput";

interface Props {
  isAdd: boolean;
  part?: TicketItemFormValues;
  addItem: (values: TicketItemFormValues, type: TicketItemType) => void;
  autoCapitalizeFirstLetter: boolean;
}

export default observer(function ARForm({
  isAdd,
  part,
  addItem,
  autoCapitalizeFirstLetter,
}: Props) {
  const { t } = useTranslation(["common", "translation", "tickets"]);

  const { modalStore, vendorStore, partsStore } = useStore();
  const {
    loadDropdownListVendors,
    vendorDropdownList,
    loadSettingVendors,
    selectedSettingsVendor,
    loadVendors,
    getVendors,
  } = vendorStore;

  const { resetSearchPartRegistry } = partsStore;

  useEffect(() => {
    loadDropdownListVendors();
  }, [loadDropdownListVendors]);

  useEffect(() => {
    loadVendors();
  }, [loadVendors]);

  const [partItem, setPartItem] = useState<TicketItemFormValues>(
    new TicketItemFormValues(part)
  );
  const [profit, setProfit] = useState<number>(0);

  useEffect(() => {
    loadSettingVendors().then(() => {
      if (selectedSettingsVendor.enablePartMarkup && isAdd) {
        partItem.partMarkup = selectedSettingsVendor.defaultMarkup;
        setPartItem(partItem);
      }
    });
  }, [
    loadSettingVendors,
    partItem,
    selectedSettingsVendor.defaultMarkup,
    selectedSettingsVendor.enablePartMarkup,
  ]);

  const validationSchema = Yup.object({});

  function handleFormSubmit(values: TicketItem) {
    addItem(values, TicketItemType.Parts);
    modalStore.closeModal();
  }

  return (
    <>
      <PageHeader
        type={"h3"}
        header={t("Add/Edit Accounts Receivable Information", {
          ns: "tickets",
        })}
        divider={true}
        addTitle={false}
      />
      <Formik
        validationSchema={validationSchema}
        initialValues={partItem}
        onSubmit={(values: TicketItem) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form
            className="ui form error"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Grid stackable>
              <Grid.Row columns={2}>
                <Grid.Column verticalAlign="bottom">
                  <Container className="search-input">
                    <MyTextInput
                      placeholder={t("Credit Limit", { ns: "tickets" })}
                      label={t("Credit Limit", { ns: "tickets" }) + ":"}
                      className="full-width"
                      name="creditLimit"
                      autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                      maxLength={50}
                    />
                  </Container>
                </Grid.Column>
                <Grid.Column>
                  <Container className="search-input">
                    <MyTextInput
                      placeholder={t("Account Number", { ns: "tickets" })}
                      label={t("Account Number", { ns: "tickets" }) + ":"}
                      className="full-width"
                      name="accountNumber"
                      autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                      maxLength={1000}
                    />
                  </Container>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <label className="required">
                    {`${t("Active", {
                      ns: "settings",
                    })}:`}
                  </label>
                  <Form.Group inline>
                    <Form.Field key={``}>
                      <Radio label="Yes" />
                      <Radio label="No" />
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column>
                  <Button color="green" type="submit">
                    <Icon name="check" />
                    {t("Add/Edit", { ns: "tickets" })}
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      modalStore.closeModal();
                    }}
                  >
                    <Icon name="x" />
                    {t("cancel", { ns: "common" })}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
});
