import { observer } from "mobx-react-lite";
import { Button, Divider, Grid, Message, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { formatPhoneNumber } from "../../../app/common/util/functions";
import PageHeader from "../../../app/layout/PageHeader";
import { format } from "date-fns";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default observer(function ClientPhoneNumbers() {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const { clientReviewStore } = useStore();

  const { loadCompanyPhoneNumbersList, getCompanyPhoneNumberList } =
    clientReviewStore;

  const { id } = useParams<{ id: string }>();
  const { phoneNumber } = useParams<{ phoneNumber: string }>();

  useEffect(() => {
    loadCompanyPhoneNumbersList(id ?? "", phoneNumber ?? "");
  }, [loadCompanyPhoneNumbersList, id]);

  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <PageHeader
            type={"h1"}
            header={"Company Phone Numbers"}
            divider={false}
            addTitle={false}
          />
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button
            content={"Back"}
            color="grey"
            icon="left caret"
            onClick={() => {
              navigate(`/admin/companyReview`);
            }}
          />
        </Grid.Column>
        <Divider />
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Company Name</Table.HeaderCell>
                <Table.HeaderCell>Date Created</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {getCompanyPhoneNumberList &&
              getCompanyPhoneNumberList.length > 0 ? (
                getCompanyPhoneNumberList.map((numberList) => (
                  <Table.Row key={`client.${numberList.id}`}>
                    <Table.Cell><Link to={`/admin/company/${numberList.id}`}>{numberList.companyName}</Link></Table.Cell>
                    <Table.Cell>
                      {format(numberList.createdDate, "P")}
                    </Table.Cell>
                    <Table.Cell>
                      {formatPhoneNumber(numberList.phone.toString())}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row key={"noItems"}>
                  <Table.Cell width="8" colSpan={8}>
                    <Message
                      color="yellow"
                      className="textAlignCenter fontStyleItalic"
                    >
                      {t("grid_noItems", { ns: "common" })}
                    </Message>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
});
