import React, { useState } from "react";
import {
  Button,
  Divider,
  DropdownItemProps,
  Form,
  Grid,
  Icon,
  Label,
} from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import PageHeader from "../../../app/layout/PageHeader";
import { useTranslation } from "react-i18next";
import { TicketPaymentItem } from "../../../app/models/ticket";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyIconDecimalInput from "../../../app/common/form/MyIconDecimalInput";
import ValidationErrors from "../../errors/ValidationErrors";
import {
  formatCurrency,
  formatDecimal,
  getNegative,
  round2Decimals,
} from "../../../app/common/util/functions";
import { SettingsPayType } from "../../../app/models/pricing";
import { PaymentStatus } from "../../../app/models/enums";

interface Props {
  name: string;
  dropdownItems: DropdownItemProps[];
  ticketPaymentItem: TicketPaymentItem;
  addItem?: (values: TicketPaymentItem) => void;
  amountDue: number;
  isAdd: boolean;
}

export default observer(function PaymentItem({
  name,
  dropdownItems,
  addItem,
  ticketPaymentItem,
  amountDue,
  isAdd,
}: Props) {
  const { t } = useTranslation(["common", "translation", "tickets"]);
  const { modalStore, pricingStore } = useStore();
  const { payTypeRegistry } = pricingStore;

  const [overage, setOverage] = useState<number | undefined>(undefined);

  const calcOverage = (value: number | undefined) => {
    if (value) {
      const diff: number = Number(amountDue) - Number(value);
      setOverage(diff < 0 ? diff : undefined);
    } else {
      setOverage(undefined);
    }
  };

  const validationSchema = Yup.object({
    paymentTypeId: Yup.string().required(
      t("paymentProcessing.payMethod", { ns: "errors" })
    ),
    amount: Yup.string().required(
      t("paymentProcessing.payAmountRecieved", {
        ns: "errors",
      })
    ),
  });

  function handleFormSubmit(values: TicketPaymentItem) {
    let paymentType: SettingsPayType | undefined = payTypeRegistry.get(
      values.paymentTypeId
    );

    if (paymentType) {
      values.paymentType = paymentType.name.toString();
      values.status = paymentType.isPaymentProcessor
        ? PaymentStatus.Created
        : PaymentStatus.Succeeded;
    }

    if (addItem) addItem(values);
    modalStore.closeModal();
  }

  return (
    <>
      <PageHeader
        type={"h3"}
        header={`${
          isAdd
            ? t("add", {
                ns: "common",
              })
            : t("edit", {
                ns: "common",
              })
        } ${t("payment", {
          ns: "tickets",
        })}`}
        divider={true}
        addTitle={false}
      />
      <Formik
        validationSchema={validationSchema}
        initialValues={ticketPaymentItem}
        onSubmit={(values: TicketPaymentItem) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form
            className="ui form error"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <ErrorMessage
              name="error"
              render={() => <ValidationErrors errors={errors.error} />}
            />
            {errors.error}
            <Grid stackable>
              <Grid.Row columns={2} key={name}>
                <Grid.Column verticalAlign="top">
                  <MySelectInput
                    name={"paymentTypeId"}
                    options={dropdownItems}
                    placeholder={""}
                    clearable={false}
                    label={`${t("estimate.payment_method", {
                      ns: "tickets",
                    })}:`}
                  />
                </Grid.Column>
                <Grid.Column verticalAlign="top">
                  <MyIconDecimalInput
                    iconPosition={"left"}
                    className={"input"}
                    name={"amount"}
                    icon={"dollar"}
                    placeholder={"0.00"}
                    label={`${t("paymentAmount", { ns: "tickets" })}:`}
                    maxLength={500}
                    onChange={(v) => calcOverage(v)}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2} key={name}>
                <Grid.Column verticalAlign="middle" textAlign="right">
                  {t("estimate.amountDue", {
                    ns: "tickets",
                  })}
                  :
                </Grid.Column>
                <Grid.Column verticalAlign="top">
                  <Label
                    basic
                    simple
                    fluid
                    className="myLabel shortInput labelHeight"
                    style={{
                      color: getNegative(amountDue ?? 0),
                    }}
                  >
                    <Icon name="dollar" />
                    {formatDecimal(round2Decimals(amountDue ?? 0) ?? 0, 2)}
                  </Label>
                  <Button
                    icon="caret square up outline"
                    attached="right"
                    type="button"
                    className="caretButton"
                    disabled={amountDue === 0.0}
                    onClick={() => {
                      setFieldValue("amount", round2Decimals(amountDue ?? 0));
                      calcOverage(amountDue);
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
              {overage && (
                <Grid.Row columns={2} key={"overage"}>
                  <Grid.Column verticalAlign="middle" textAlign="right">
                    {t("estimate.changeDue", {
                      ns: "tickets",
                    })}
                    :
                  </Grid.Column>
                  <Grid.Column
                    style={{
                      color: getNegative(overage ?? 0),
                    }}
                  >
                    {formatCurrency(overage ?? 0)}
                  </Grid.Column>
                </Grid.Row>
              )}
              <Divider />
              <Grid.Row>
                <Grid.Column>
                  <Button
                    color="green"
                    className="save_button"
                    icon="check"
                    content={t("save", { ns: "common" })}
                    disabled={isSubmitting || !dirty || !isValid}
                    loading={isSubmitting}
                    type="submit"
                  >
                    {`${t("save", {
                      ns: "common",
                    })} ${t("payment", {
                      ns: "tickets",
                    })}`}
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      modalStore.closeModal();
                    }}
                  >
                    <Icon name="x" />
                    {t("cancel", { ns: "common" })}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
});
