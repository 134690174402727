import React, { useState } from "react";
import { Button, Container, Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PageHeader from "../../../app/layout/PageHeader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  clientStatusOptions,
  enableOptions,
  serviceTypeOptions,
} from "../../../app/common/options/option";
import MyRadioGroup from "../../../app/common/form/RadioButtonGroups/MyRadioGroup";
import MySelectInput from "../../../app/common/form/MySelectInput";
import MyDropdownInput from "../../../app/common/form/MyDropdownInput";
import { SystemAdminClientDetails } from "../../../app/models/systemAdmin";

interface Props {
  clientDetailsFormValues: SystemAdminClientDetails;
}

export default function UpdateClientStatus({
  clientDetailsFormValues,
}: Readonly<Props>) {
  const { t } = useTranslation(["common", "pricingSettings", "errors"]);
  const { clientSystemAdminStore, modalStore, adminNotesStore } = useStore();
  const { updateClientStatus, loadClientInformation } = clientSystemAdminStore;
  const { loadClientAdminNotes } = adminNotesStore;

  const [client] = useState<SystemAdminClientDetails>(clientDetailsFormValues);

  const validationSchema = Yup.object({});

  function handleFormSubmit(values: SystemAdminClientDetails) {
    updateClientStatus(values.id ?? "", values).then(() => {
      loadClientInformation(clientDetailsFormValues.id ?? "");
      loadClientAdminNotes(values.id);
      modalStore.closeModal();
    });
  }

  return (
    <>
      <PageHeader
        header="Update Company Status"
        type="h2"
        divider={true}
        addTitle={false}
      />

      <Formik
        validationSchema={validationSchema}
        initialValues={client}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setFieldValue,
        }) => (
          <Form
            className="ui form error"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Container className="page">
              <MyDropdownInput
                placeholder="Status"
                label="Status Override"
                options={clientStatusOptions}
                name={"status"}
              />
              <MyRadioGroup
                name={"dowellSystemApplication"}
                options={serviceTypeOptions}
                inline={true}
                label={`${t("service_type.label", {
                  ns: "common",
                })}:`}
              />
              <MyRadioGroup
                name={"laborDataAccess"}
                label="Labor Data Access"
                options={enableOptions}
                inline={true}
              />
              {/* <MySelectInput
                name="userCount"
                placeholder={t("subscription.additionalUsers", {
                  ns: "subscription",
                })}
                options={usersOption}
                label={`${t("subscription.additionalUsers", {
                  ns: "subscription",
                })}:`}
                onChange={() => {}}
                clearable={true}
              /> */}
              <Button
                className="save_button ui button"
                icon="cancel"
                type="button"
                content={t("close", { ns: "common" })}
                onClick={() => {modalStore.closeModal()}}
                size="medium"
              />

              <Button
                className="save_button ui button"
                icon="save"
                color="green"
                type="submit"
                disabled={isSubmitting || !dirty || !isValid}
                content={t("save", { ns: "common" })}
                size="medium"
              />
            </Container>
          </Form>
        )}
      </Formik>
    </>
  );
}
