import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Grid, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import {
  ReportType,
  SearchType,
} from "../../../app/models/enums";
import { useStore } from "../../../app/stores/store";
import {
  formatCurrency,
  getNegative,
} from "../../../app/common/util/functions";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../../app/layout/PageHeader";

interface Props {
  startDate: Date;
  endDate: Date;
  reportType: ReportType;
}

export default observer(function TotalSalesPrint({
  startDate,
  endDate,
  reportType,
}: Props) {
  const { t } = useTranslation(["common", "reports"]);
  const navigate = useNavigate();

  const { totalSalesReportStore, searchStore } = useStore();
  const {
    getTotalSalesReport,
    groupedTotalSalesReport,
    calculateTotalSales,
    reportCriteria,
  } = totalSalesReportStore;

  const { setPredicate: searchPredicate } = searchStore;

  return (
    <>
      <Container className="page">
        <Grid stackable columns={2} className="headerPrint">
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={`${t("report", { ns: "reports" })}: ${t(
                  "total_sales.header",
                  {
                    ns: "reports",
                  }
                )} `}
                type="h2"
                divider={false}
                getAlerts={true}
                addTitle={true}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`${format(startDate, "PP")} - ${format(
                  endDate,
                  "PP"
                )}`}
                as="h2"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {getTotalSalesReport && getTotalSalesReport.length > 0 && (
          <Table striped unstackable className="dnxTable">
            {reportType === ReportType.Detailed ? (
              groupedTotalSalesReport.map(([group, x]) => (
                <>
                  <Table.Body>
                    <Table.Row key={`dateHeader.${group}`}>
                      <Table.Cell
                        colSpan={10}
                        className="textAlignLeft terms_bold"
                      >
                        {format(new Date(group), "PP")}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                  <Table.Header>
                    <Table.Row key={`itemsHeader.${group}`}>
                      <Table.HeaderCell>
                        {t("inv", { ns: "search" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("po", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("customer", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        {t("description", { ns: "common" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.labor", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.parts", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.misc", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.tax", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="right">
                        {t("estimate.total", { ns: "tickets" })}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="left">
                        {t("pay_type.header", { ns: "reports" })}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {x.map((item) => (
                      <Table.Row key={`totalSales.${item.date}`}>
                        <Table.Cell
                          data-label={`${t("inv", { ns: "search" })}:`}
                        >
                          <Link
                            onClick={() => {
                              searchPredicate(
                                SearchType.InvoiceNumber,
                                item.invoiceNumber
                              );
                              navigate(
                                `/search/${SearchType.InvoiceNumber}/${item.invoiceNumber}`
                              );
                            }}
                            to={""}
                          >
                            {item.invoiceNumber}
                          </Link>
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("po", { ns: "common" })}:`}
                        >
                          <Link
                            onClick={() => {
                              searchPredicate(
                                SearchType.PONumber,
                                item.poNumber
                              );
                              navigate(
                                `/search/${SearchType.PONumber}/${item.poNumber}`
                              );
                            }}
                            to={""}
                          >
                            {item.poNumber}
                          </Link>
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("customer", { ns: "common" })}:`}
                        >
                          {item.isCommercial
                            ? item.businessName
                            : `${item.lastName ?? "--"}, ${
                                item.firstName ?? "--"
                              }`}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("description", { ns: "common" })}:`}
                        >
                          {item.description}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("estimate.labor", {
                            ns: "tickets",
                          })}:`}
                          textAlign="right"
                          style={{
                            color: getNegative(item.totalLabor),
                          }}
                        >
                          {formatCurrency(item.totalLabor)}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("estimate.parts", {
                            ns: "tickets",
                          })}:`}
                          textAlign="right"
                          style={{
                            color: getNegative(item.totalParts),
                          }}
                        >
                          {formatCurrency(item.totalParts)}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("estimate.misc", {
                            ns: "tickets",
                          })}:`}
                          textAlign="right"
                          style={{
                            color: getNegative(item.totalMisc),
                          }}
                        >
                          {formatCurrency(item.totalMisc)}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("estimate.tax", {
                            ns: "tickets",
                          })}:`}
                          textAlign="right"
                          style={{
                            color: getNegative(item.totalTax),
                          }}
                        >
                          {formatCurrency(item.totalTax)}
                        </Table.Cell>
                        <Table.Cell
                          data-label={`${t("final", { ns: "reports" })}:`}
                          textAlign="right"
                          style={{
                            color: getNegative(item.totalFinal),
                          }}
                        >
                          {formatCurrency(item.totalFinal)}
                        </Table.Cell>
                        <Table.Cell>{item.payType}</Table.Cell>
                      </Table.Row>
                    ))}
                    <Table.Row>
                      <Table.Cell className="terms_bold">
                        {t("dailyTotals", { ns: "reports" })}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell></Table.Cell>
                      <Table.Cell
                        data-label={`${t("estimate.labor", {
                          ns: "tickets",
                        })}:`}
                        className="terms_bold"
                        textAlign="right"
                        style={{
                          color: getNegative(calculateTotalSales(x).totalLabor),
                        }}
                      >
                        {formatCurrency(calculateTotalSales(x).totalLabor)}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("estimate.parts", {
                          ns: "tickets",
                        })}:`}
                        className="terms_bold"
                        textAlign="right"
                        style={{
                          color: getNegative(calculateTotalSales(x).totalParts),
                        }}
                      >
                        {formatCurrency(calculateTotalSales(x).totalParts)}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("estimate.misc", { ns: "tickets" })}:`}
                        className="terms_bold"
                        textAlign="right"
                        style={{
                          color: getNegative(calculateTotalSales(x).totalMisc),
                        }}
                      >
                        {formatCurrency(calculateTotalSales(x).totalMisc)}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("estimate.tax", { ns: "tickets" })}:`}
                        className="terms_bold"
                        textAlign="right"
                        style={{
                          color: getNegative(calculateTotalSales(x).totalTax),
                        }}
                      >
                        {formatCurrency(calculateTotalSales(x).totalTax)}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("final", { ns: "reports" })}:`}
                        className="terms_bold"
                        textAlign="right"
                        style={{
                          color: getNegative(calculateTotalSales(x).totalFinal),
                        }}
                      >
                        {formatCurrency(calculateTotalSales(x).totalFinal)}
                      </Table.Cell>
                      <Table.Cell></Table.Cell>
                    </Table.Row>
                    <Table.Row className="noBackground">
                      <Table.Cell colSpan={11}></Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </>
              ))
            ) : (
              <>
                <Table.Header>
                  <Table.Row key={"summaryHeader"}>
                    <Table.HeaderCell>
                      {t("month", { ns: "reports" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {t("estimate.labor", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {t("estimate.parts", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {t("estimate.misc", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {t("estimate.tax", { ns: "tickets" })}
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      {t("final", { ns: "reports" })}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {getTotalSalesReport.map((x) => (
                    <Table.Row key={`totalSales2.${x.date}`}>
                      <Table.Cell textAlign="left">
                        {format(x.date!, "MMMM Y")}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("estimate.labor", {
                          ns: "tickets",
                        })}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(x.totalLabor),
                        }}
                      >
                        {formatCurrency(x.totalLabor)}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("estimate.parts", {
                          ns: "tickets",
                        })}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(x.totalParts),
                        }}
                      >
                        {formatCurrency(x.totalParts)}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("estimate.misc", { ns: "tickets" })}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(x.totalMisc),
                        }}
                      >
                        {formatCurrency(x.totalMisc)}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("estimate.tax", { ns: "tickets" })}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(x.totalTax),
                        }}
                      >
                        {formatCurrency(x.totalTax)}
                      </Table.Cell>
                      <Table.Cell
                        data-label={`${t("final", { ns: "reports" })}:`}
                        textAlign="right"
                        style={{
                          color: getNegative(x.totalFinal),
                        }}
                      >
                        {formatCurrency(x.totalFinal)}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </>
            )}

            <Table.Body>
              <Table.Row key={"grandTotals"} className="greenRow">
                <Table.Cell
                  colSpan={reportType === ReportType.Detailed ? "4" : "1"}
                  className="terms_bold"
                >
                  {t("grandTotals", { ns: "reports" })}:
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("estimate.labor", { ns: "tickets" })}:`}
                  className="terms_bold"
                  textAlign="right"
                  style={{
                    color: getNegative(
                      calculateTotalSales(getTotalSalesReport).totalLabor
                    ),
                  }}
                >
                  {formatCurrency(
                    calculateTotalSales(getTotalSalesReport).totalLabor
                  )}
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("estimate.parts", { ns: "tickets" })}:`}
                  className="terms_bold"
                  textAlign="right"
                  style={{
                    color: getNegative(
                      calculateTotalSales(getTotalSalesReport).totalParts
                    ),
                  }}
                >
                  {formatCurrency(
                    calculateTotalSales(getTotalSalesReport).totalParts
                  )}
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("estimate.misc", { ns: "tickets" })}:`}
                  className="terms_bold"
                  textAlign="right"
                  style={{
                    color: getNegative(
                      calculateTotalSales(getTotalSalesReport).totalMisc
                    ),
                  }}
                >
                  {formatCurrency(
                    calculateTotalSales(getTotalSalesReport).totalMisc
                  )}
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("estimate.tax", { ns: "tickets" })}:`}
                  className="terms_bold"
                  textAlign="right"
                  style={{
                    color: getNegative(
                      calculateTotalSales(getTotalSalesReport).totalTax
                    ),
                  }}
                >
                  {formatCurrency(
                    calculateTotalSales(getTotalSalesReport).totalTax
                  )}
                </Table.Cell>
                <Table.Cell
                  data-label={`${t("final", { ns: "reports" })}:`}
                  className="terms_bold"
                  textAlign="right"
                  style={{
                    color: getNegative(
                      calculateTotalSales(getTotalSalesReport).totalFinal
                    ),
                  }}
                >
                  {formatCurrency(
                    calculateTotalSales(getTotalSalesReport).totalFinal
                  )}
                </Table.Cell>
                {reportType === ReportType.Detailed && (
                  <Table.Cell></Table.Cell>
                )}
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </Container>
    </>
  );
});
