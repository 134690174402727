import { Labor } from "../models/labor";
import { requests } from "./Requests";

export const JobLabors = {

  getJobLabor: (
    year: number,
    modelId: string,
    jobId: number,
    engineId: string
  ) =>
    requests.get<Labor>(
      `/jobLabor/getJobLabor?Year=${year}&model=${modelId}&jobId=${jobId}&engine=${engineId}`
    ),

  getCustomJobLabor:(
    year: number,
    model: string,
    jobId: number,
    make?: string,
    customVehicleId?: string
  ) =>
    requests.get<Labor>(
      `/jobLabor/getCustomJobLabor?Year=${year}&model=${model}&jobId=${jobId}&make=${make}&customVehicleId=${customVehicleId}`
    ),
};
