import { observer } from "mobx-react-lite";
import { Button, Message, Table } from "semantic-ui-react";
import {
  formatCurrency,
  getTicketTypeColor,
} from "../../app/common/util/functions";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TicketType } from "../../app/models/enums";
import { TicketSearch } from "../../app/models/search";
import { resetTicket } from "../../app/common/util/ticketfunctions";

interface Props {
  id: string;
}

export default observer(function CustomerTicketSearch({ id }: Props) {
  const { t } = useTranslation(["common", "search"]);
  const navigate = useNavigate();

  const { searchStore, ticketStore } = useStore();

  const { loadVehicleTicket, filterTicketsByVehicleId } = searchStore;
  const { resetTicketHistoryRegistry } = ticketStore;

  const [ticketSearch, setTicketSearch] = useState<TicketSearch[]>([]);
  useEffect(() => {
    loadVehicleTicket(id).then(() => {
      setTicketSearch(filterTicketsByVehicleId(id));
    });
  }, [loadVehicleTicket, setTicketSearch, filterTicketsByVehicleId, id]);

  return (
    <Table.Cell className="tableInTable textAlignRightNotMobile" colSpan={7}>
      <Table striped unstackable className="dnxTable no_padding">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={5}>
              {t("description", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell width={2}>{t("date", { ns: "common" })}</Table.HeaderCell>
            <Table.HeaderCell width={2}>{t("ticket", { ns: "common" })}</Table.HeaderCell>
            <Table.HeaderCell>{t("po", { ns: "common" })} #</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {t("total", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {ticketSearch.length > 0 ? (
            ticketSearch.map((ticket) => (
              <Table.Row key={ticket.id}>
                <Table.Cell data-label={`${t("description", { ns: "common" })}:`}>{ticket.description}</Table.Cell>
                <Table.Cell data-label={`${t("date", { ns: "common" })}:`}>
                  {ticket.ticketType === TicketType.Estimate
                    ? ticket.estimateDate && format(ticket.estimateDate, "PP")
                    : ticket.invoiceDate && format(ticket.invoiceDate, "PP")}
                </Table.Cell>
                <Table.Cell data-label={`${t("ticket", { ns: "common" })}:`}>
                  <span
                    style={{ color: getTicketTypeColor(ticket.ticketType) }}
                  >
                    {ticket.ticketType === TicketType.Estimate
                      ? `${t("est", { ns: "search" })}: # ${
                          ticket.estimateNumber
                        }`
                      : `${t("inv", { ns: "search" })}: # ${
                          ticket.invoiceNumber
                        }`}
                  </span>
                </Table.Cell>
                <Table.Cell data-label={`${t("po", { ns: "common" })}#:`}>{ticket.poNumber}</Table.Cell>
                <Table.Cell textAlign="right" data-label={`${t("total", { ns: "common" })}:`}>
                  {formatCurrency(ticket.totalAmount)}
                </Table.Cell>
                <Table.Cell className="textAlignRight" width={6}>
                  <Button
                    content={t("view", { ns: "common" })}
                    icon="eye"
                    className="tealishButtons"
                    size="small"
                    onClick={() => {
                      resetTicket();
                      resetTicketHistoryRegistry();
                      navigate(`/ticket/view/${ticket.id}`);
                    }}
                  />
                  <Button
                    content={t("edit", { ns: "common" })}
                    icon="edit"
                    size="small"
                    onClick={() => {
                      resetTicket();
                      navigate(`/ticket/summary/${ticket.id}/true`);
                    }}
                  />
                  <Button
                    content={t("more", { ns: "recentTickets" })}
                    icon="plus"
                    size="small"
                    onClick={() => {
                      navigate(`/customer/${ticket.customerId}`);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row key={"noItems"}>
              <Table.Cell width="5" colSpan={5}  className="noPaddingLeft">
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Table.Cell>
  );
});
