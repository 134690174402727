import { MiscellaneousCharge } from "../models/miscellaneousCharges";
import { requests } from "./Requests";

export const MiscellaneousChanges = {
  listMiscellaneousCharges: () =>
    requests.get<MiscellaneousCharge[]>(
      `/MiscellaneousCharge/listMiscellaneousCharges`
    ),

  listPreviousTicketMiscellaneousCharges: (ticketId: string) =>
    requests.get<MiscellaneousCharge[]>(
      `/MiscellaneousCharge/listPreviousTicketMiscellaneousCharges/${ticketId}`
    ),

  getMiscellaneousCharge: (id: string) =>
    requests.get<MiscellaneousCharge>(
      `/MiscellaneousCharge/getMiscellaneousCharge/${id}`
    ),
  addMiscellaneousCharge: (values: MiscellaneousCharge) =>
    requests.post<void>("/MiscellaneousCharge/addMiscellaneousCharge", values),

  removeMiscellaneousCharge: (id: string | undefined) =>
    requests.put<void>(
      `/MiscellaneousCharge/removeMiscellaneousCharge/${id}`,
      {}
    ),
  updateMiscellaneousCharge: (values: MiscellaneousCharge) =>
    requests.put<void>(
      `/MiscellaneousCharge/updateMiscellaneousCharge/${values.id}`,
      values
    ),
};
