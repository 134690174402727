import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { Application, TermEnum } from "../../../app/models/enums";
import { convertApplicationToString, convertTermToString, formatDecimal, getCellColor } from "../../../app/common/util/functions";
import { StatsCompanyTerms } from "../../../app/models/systemAdmin";

export interface CompanyTermsProps {
  isCompare: boolean;
}
export default observer(function CompanyTerms({ isCompare }: CompanyTermsProps) {
  const { statsStore } = useStore();

  const { statsOverview, loadStatsOverview, loadStatsCompare, statsCompare } = statsStore;

  useEffect(() => {
    if (!isCompare && !statsOverview) {
      loadStatsOverview();
    } else if (isCompare && !statsCompare) {
      loadStatsCompare();
    }
  }, [statsOverview, loadStatsOverview, statsCompare, loadStatsCompare, isCompare]);

  const companyTerms: StatsCompanyTerms[] = ((isCompare ? statsCompare?.companyTerms : statsOverview?.companyTerms) ?? []) as StatsCompanyTerms[];

  const terms = new Set(companyTerms.map(x => x.term));

  return (
    <Table singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Term</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Active</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Inactive</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {Array.from(terms).map((t) => (
          <>
            {[
              Application.None,
              Application.RealTimeLaborGuide,
              Application.RealTimeLaborGuidePro,
            ].map((a) => {
              if (a === Application.None && t !== TermEnum.Trial) return null;

              const app = convertApplicationToString(a);
              const active = companyTerms.find((x) => x.term === t && x.application === a && x.isActive)?.count ?? 0;
              const inactive = companyTerms.find((x) => x.term === t && x.application === a && !x.isActive)?.count ?? 0;

              return (
                <Table.Row key={t}>
                  <Table.Cell>
                    {app !== "" ? app : "None"}, {convertTermToString(t)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    className={isCompare ? getCellColor(active) : ""}
                  >
                    {formatDecimal(active, 0)}
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    className={isCompare ? getCellColor(inactive) : ""}
                  >
                    {formatDecimal(inactive, 0)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </>
        ))}
      </Table.Body>
    </Table>
  );
});