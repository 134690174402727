import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { PDFViewer, Page, Font, Document } from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import { TicketType, TicketWorkflowSteps } from "../../../app/models/enums";
import { VehicleFormValues } from "../../../app/models/vehicle";
import { CustomerFormValues } from "../../../app/models/customer";
import { TicketSession } from "../../../app/models/ticket";
import compose from "../../../app/layout/compose";
import RepairOrderHeaderPdf from "./RepairOrderHeaderPdf";
import RepairOrderBodyPdf from "./RepairOrderBodyPdf";
import { resetTicket } from "../../../app/common/util/ticketfunctions";

export default observer(function RepairOrderPdfView() {
  const { t } = useTranslation(["common", "tickets"]);

  const {
    ticketSessionStore,
    ticketStore,
    clientStore,
    vehicleStore,
    customerStore,
    pricingStore,
  } = useStore();

  const {
    loadTicket,
    setSelectedTicketSession,
    setSelectedPONumber,
    setBatchTicketItems,
    setBatchTicketPaymentItems,
    resetTicketPaymentItems,
    resetTicketItems,
    populateJobLabors,
    populateLaborMap,
  } = ticketSessionStore;

  const {
    loadSettingsTicketFormatting,
    selectedSettingsTicketFormatting,
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;
  const {
    loadSettingsTaxRate,
    loadDropdownPayTypeList,
    dropdownPayTypesList,
    loadSettingsPayType,

    getPayType,
  } = pricingStore;
  const { setSelectedVehicle } = vehicleStore;
  const { setSelectedCustomer } = customerStore;

  useEffect(() => {
    document.title = t("repair_order", { ns: "tickets" });
  }, []);

  useEffect(() => {
    loadSettingsTaxRate();
  }, [loadSettingsTaxRate]);

  useEffect(() => {
    loadSettingsTicketFormatting();
  }, [loadSettingsTicketFormatting]);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  useEffect(() => {
    loadDropdownPayTypeList();
  }, [loadDropdownPayTypeList]);

  useEffect(() => {
    loadSettingsPayType(true);
  }, [loadSettingsPayType]);

  const [paymentMethod, setPaymentMethod] = useState<string>();
  const [totalPayments, setTotalPayments] = useState<number>(0.0);

  const { id } = useParams<{ id: string }>();
  const [ticketLoaded, setTicketLoaded] = useState<boolean>(false);
  Font.registerHyphenationCallback(word => {
    // Return entire word as unique part
    return [word];
  });
  useEffect(() => {
    resetTicket();

    if (id) {
      setTicketLoaded(true);

      loadTicket(id)
        .then((result) => {
          if (result) {
            setSelectedVehicle(new VehicleFormValues(result.vehicle));
            setSelectedCustomer(new CustomerFormValues(result.customer));
            setSelectedPONumber(result.poNumber);

            let ticketSession = new TicketSession(result);
            setSelectedTicketSession(ticketSession);

            if (result.jobLabors && result.jobLabors.length > 0) {
              populateJobLabors(result.jobLabors);
            }

            if (result.ticketItems && result.ticketItems.length > 0) {
              setBatchTicketItems(result.ticketItems, selectedSettingsTicketFormatting.showRemoveAndReplace);
            }

            if (result.ticketPayments && result.ticketItems.length > 0) {
              setBatchTicketPaymentItems(result.ticketPayments);

              let payments: number = 0;
              result.ticketPayments.forEach((x, index) => {
                payments += x.amount ?? 0;
              });

              setTotalPayments(payments);
            }

            if (result.ticketType === TicketType.Estimate) {
              let payMethod = dropdownPayTypesList.find((x) => {
                return x.value === result?.estimatePaymentMethod;
              });
              if (payMethod?.text) setPaymentMethod(payMethod.text.toString());
            } else {
              let ticketPaymentItem = result.ticketPayments.find((x) => {
                return (x.amount ?? 0) > 0;
              });

              if (ticketPaymentItem) {
                let payMethod = getPayType(
                  ticketPaymentItem?.paymentTypeId ?? ""
                );
                if (payMethod?.isStandard) {
                  if (payMethod.name === "Cash" || payMethod.name === "Check") {
                    setPaymentMethod(payMethod.name);
                  } else if (/Charge/.exec(payMethod.name)) {
                    setPaymentMethod("Charge");
                  }
                }
              }
            }

            if (result.labors && result.labors.length > 0) {
              populateLaborMap(result.labors);
            }
          }
        })
        .finally(() => setTicketLoaded(false));
    }
  }, [loadTicket, dropdownPayTypesList, getPayType, id, populateJobLabors, resetTicketItems, resetTicketPaymentItems, setBatchTicketItems, setBatchTicketPaymentItems, setSelectedCustomer, setSelectedPONumber, setSelectedTicketSession, setSelectedVehicle, populateLaborMap]);

  const { loadLogo, loadClientDetails } = clientStore;

  useEffect(() => {
    loadLogo();
  }, [loadLogo]);

  useEffect(() => {
    loadClientDetails();
  }, [loadClientDetails]);

  return (
    <PDFViewer showToolbar={true} width={"100%"} height={window.innerHeight}>
      <Document
        pageMode={"useThumbs"}
        title={`${t("repair_order", { ns: "tickets" })}`}
      >
        <Page size={"LETTER"} style={compose("page invoice-wrapper body")} wrap>
          <RepairOrderHeaderPdf
            selectedSettingsTicketFormatting={selectedSettingsTicketFormatting}
            selectedSettingsTicketInputDefaults={
              selectedSettingsTicketInputDefaults
            }
          />
          <RepairOrderBodyPdf
            showNotesLabel={selectedSettingsTicketFormatting.showNotesLabel}
          />
        </Page>
      </Document>
    </PDFViewer>
  );
});
