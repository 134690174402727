import { DropdownItemProps } from "semantic-ui-react";
import { DateRangeOption } from "../../models/enums";
import i18n from "../i18n/i18n";

export const filterOptions : DropdownItemProps[] = [
  { key: "assigned", text: `${i18n.t("options.assigned", { ns: "reports" })}`, value: "assigned" },
  { key: "unassigned", text: `${i18n.t("options.unassigned", { ns: "reports" })}`, value: "unassigned"}
];

export const dateOptions = [
  {
    key: DateRangeOption.Today,
    text: i18n.t("dateRange.today", { ns: "reports" }),
    value: DateRangeOption.Today,
  },
  {
    key: DateRangeOption.LastSevenDays,
    text: i18n.t("dateRange.last7Days", { ns: "reports" }),
    value: DateRangeOption.LastSevenDays,
  },
  {
    key: DateRangeOption.ThisMonth,
    text: i18n.t("dateRange.thisMonth", { ns: "reports" }),
    value: DateRangeOption.ThisMonth,
  },
  {
    key: DateRangeOption.ThisQuarter,
    text: i18n.t("dateRange.thisQuarter", { ns: "reports" }),
    value: DateRangeOption.ThisQuarter,
  },
  {
    key: DateRangeOption.ThisYear,
    text: i18n.t("dateRange.thisYear", { ns: "reports" }),
    value: DateRangeOption.ThisYear,
  },
  {
    key: DateRangeOption.Yesterday,
    text: i18n.t("dateRange.yesterday", { ns: "reports" }),
    value: DateRangeOption.Yesterday,
  },
  {
    key: DateRangeOption.PreviousMonth,
    text: i18n.t("dateRange.previousMonth", { ns: "reports" }),
    value: DateRangeOption.PreviousMonth,
  },
  {
    key: DateRangeOption.PreviousQuarter,
    text: i18n.t("dateRange.previousQuarter", { ns: "reports" }),
    value: DateRangeOption.PreviousQuarter,
  },
  {
    key: DateRangeOption.PreviousYear,
    text: i18n.t("dateRange.previousYear", { ns: "reports" }),
    value: DateRangeOption.PreviousYear,
  },
  {
    key: DateRangeOption.Custom,
    text: i18n.t("dateRange.custom", { ns: "reports" }),
    value: DateRangeOption.Custom,
  },
];
