import { useField } from "formik";
import React from "react";
import { Button, Form, Icon, Label } from "semantic-ui-react";
import MyHelpButton from "./MyHelpButton";

interface Props {
  placeholder: string;
  name: string;
  rows: number;
  label?: string;
  showHelp?: boolean;
  helpName?: string;
  className?: string;
  helpSeeMore?: boolean;
  showDefaults?: boolean;
  maxLength: number
}

export default function MyTextArea({
  placeholder,
  name,
  rows,
  label,
  showHelp,
  helpName,
  className,
  helpSeeMore,
  showDefaults,
  maxLength
}: Readonly<Props>) {
  const [field, meta] = useField(name);
  let labelClassName: string = "myLabel";
  let inputClassName: string = "input";
  if (className) labelClassName = `${labelClassName} ${className}`;
  if (className) inputClassName = `${inputClassName} ${className}`;
  return (
    <Form.Field
      error={meta.touched && !!meta.error}
      name={name}
      className="ui"
    >
      {(label && label.length > 0) && (
        <label className={labelClassName} htmlFor={name}>
          {`${label} `}
          {showHelp && (
            <MyHelpButton
              name={`${name}.help`}
              help={helpName ? helpName : ""}
              seeMore={helpSeeMore}
              color={"blue"}
            />
          )}
          {showDefaults && (
            <Button
              size={"mini"}
              basic
              floated="right"
              compact
              type="button"
              color="green"
            >
              <Icon floated="right" color="green" name="copy outline" />
            </Button>
          )}
        </label>
      )}
      <textarea
        {...field}
        placeholder={placeholder}
        name={name}
        rows={rows}
        maxLength={maxLength}
        className={inputClassName}
        id={name}
        spellCheck={true}
      />
      {(meta.touched && meta.error) && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
