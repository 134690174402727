import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  SettingsPaymentProcessor,
  SettingsPaymentProcessorFormValues,
} from "../models/paymentProcessing";

export default class PaymentProcessingStore {
  loadingInitial = false;
  activeTab: number = 0;

  selectedPaymentProcessor: SettingsPaymentProcessorFormValues = {
    id: undefined,
    businessName: "",
    addressLine1: "",
    city: "",
    state: "",
    postalCode: "",
    primaryNumber: "",
    contactName: "",
    contactEmail: "",
    terminalSerialNumber: undefined,
    error: undefined
  };

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  loadSettingsPaymentProcessor = async () => {
    this.loadingInitial = true;
    try {
      const result =
        await agent.PaymentProcessing.getSettingsPaymentProcessor();
      runInAction(() => {
        this.selectedPaymentProcessor = result;
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.loadingInitial = false;
      });
    }
  };

  setSelectedSettingsPaymentProcessor = (
    values: SettingsPaymentProcessorFormValues
  ) => {
    this.selectedPaymentProcessor = values;
  };

  createSettingsPaymentProcessor = async (
    values: SettingsPaymentProcessorFormValues
  ) => {
    try {
      let myNew: SettingsPaymentProcessor = new SettingsPaymentProcessor(
        values
      );
      await agent.PaymentProcessing.addSettingsPaymentProcessor(myNew);

      runInAction(() => {
        this.setSelectedSettingsPaymentProcessor(values);
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateSettingsPaymentProcessor = async (
    values: SettingsPaymentProcessorFormValues
  ) => {
    try {
      let myNew: SettingsPaymentProcessor = new SettingsPaymentProcessor(
        values
      );
      await agent.PaymentProcessing.updateSettingsPaymentProcessor(myNew);

      runInAction(() => {
        this.setSelectedSettingsPaymentProcessor(values);
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  unPairSettingsPaymentProcessor = async (
  ) => {
    try {
      await agent.PaymentProcessing.unPairSettingsPaymentProcessor();

      runInAction(() => {
        //this.setSelectedSettingsPaymentProcessor(values);
      });
    } catch (error) {
      console.log(error);
    }
  };

}
