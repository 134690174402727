import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Container, Form, Message, Segment } from "semantic-ui-react";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import { Trans, useTranslation } from "react-i18next";
import PageHeader from "../../app/layout/PageHeader";
import MyCheckbox from "../../app/common/form/MyCheckbox";
import { ErrorMessage, Formik } from "formik";
import ValidationErrors from "../errors/ValidationErrors";
import * as Yup from "yup";
import { format } from "date-fns";

export default observer(function CancelSubscription() {
  const { subscriptionStore } = useStore();
  const {
    loadingInitial,
    getCurrentSubscription,
    clientSubscriptionStatus,
    cancelSubscription,
    cancelSubscriptionPageView
  } = subscriptionStore;

  const { t } = useTranslation(["common", "subscription", "errors"]);
  const navigate = useNavigate();

  useEffect(() => {
    getCurrentSubscription()
      .then(() => {
        cancelSubscriptionPageView();
      })
      .catch();
  }, [getCurrentSubscription]);

  const [completed, setCompleted] = useState(false);

  const validationSchema = Yup.object({
    confirm1: Yup.boolean().oneOf([true], t("cancelSubscription", { ns: "errors" })).required(t("cancelSubscription", { ns: "errors" })),
    confirm2: Yup.boolean().oneOf([true], t("cancelSubscription", { ns: "errors" })).required(t("cancelSubscription", { ns: "errors" })),
  });

  let initialValues = {
    confirm1: false,
    confirm2: false,
    error: "",
  };

  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "subscription" })} />;

  return (
    <>
      <PageHeader
        header={t("cancel.cancelheader", { ns: "subscription" })}
        type={"h1"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Message negative>
        {`${t("cancel.message", { ns: "subscription" })} `}
      </Message>
      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { setErrors }) =>
            cancelSubscription()
              .then(() => {
                navigate("/subscription/status");
              })
              .catch((error) => {
                setErrors({error: error});
              })
          }
        >
          {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
              <MyCheckbox
                name={"confirm1"}
                label={`${t("cancel.checkbox1.1", {
                  ns: "subscription",
                })} ${format(
                  clientSubscriptionStatus.renewalDate ?? new Date(),
                  "P"
                )}${t("cancel.checkbox1.2", { ns: "subscription" })}${format(
                  clientSubscriptionStatus.renewalDate ?? new Date(),
                  "P"
                )}, ${t("cancel.checkbox1.3", { ns: "subscription" })}`}
                disabled={false}
              />
              <MyCheckbox
                name={"confirm2"}
                label={t("cancel.checkbox2", { ns: "subscription" })}
                disabled={false}
              />
              <Button
                color="green"
                className="save_button"
                icon="check"
                content={t("save", { ns: "common" })}
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                type="submit"
              />
              <Button
                className="save_button"
                as={Link}
                to="/subscription/status"
                icon="cancel"
                type="reset"
                content={t("cancel", { ns: "common" })}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
