import { ErrorMessage, Form, Formik, FormikErrors } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import * as Yup from "yup";
import MyTextInput from "../../app/common/form/MyTextInput";
import { store, useStore } from "../../app/stores/store";
import { TechnicianFormValues } from "../../app/models/technician";
import ValidationErrors from "../errors/ValidationErrors";
import PageHeader from "../../app/layout/PageHeader";

interface Props {
  name: string;
  header: string;
  isAdd: boolean;
  autoCapitalizeFirstLetter: boolean;
}

export default observer(function AddTechs(props: Props) {
  const { t } = useTranslation(["common", "miscSettings"]);

  const { technicianStore } = useStore();

  const {
    createTechnician,
    selectedTechnician,
    updateTechnician,
    loadTechnician,
  } = technicianStore;

  const validationSchema = Yup.object({
    lastName: Yup.string().required(
      t("registration.required_LastName", { ns: "errors" })
    ),
  });

  const [technician] = useState<TechnicianFormValues>(
    props.isAdd ? new TechnicianFormValues() : selectedTechnician
  );

  function handleFormSubmit(
    values: TechnicianFormValues,
    setErrors: (errors: FormikErrors<TechnicianFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateTechnician(values)
      : () => createTechnician(values);

    action()
      .then(() => {
        setSubmitting(false);
        loadTechnician();
        store.modalStore.closeModal();
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  return (
    <Formik
      initialValues={technician}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, { setErrors, setSubmitting }) =>
        handleFormSubmit(values, setErrors, setSubmitting)
      }
    >
      {({
        handleSubmit,
        isValid,
        isSubmitting,
        dirty,
        handleBlur,
        handleChange,
        errors,
        values,
        touched,
      }) => (
        <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
          <PageHeader
            header={props.header}
            type={"h3"}
            divider={true}
            getAlerts={true}
            addTitle={false}
          />
          <ErrorMessage
            name="error"
            render={() => <ValidationErrors errors={errors.error} />}
          />
          <MyTextInput
            placeholder={t("first_name", { ns: "common" })}
            name={"firstName"}
            label={`${t("first_name", { ns: "common" })}:`}
            autoCapitalizeFirstLetter={props.autoCapitalizeFirstLetter}
            maxLength={255}
          />
          <MyTextInput
            placeholder={t("last_name", { ns: "common" })}
            name={"lastName"}
            className="required"
            label={`${t("last_name", { ns: "common" })}:`}
            autoCapitalizeFirstLetter={props.autoCapitalizeFirstLetter}
            maxLength={255}
          />
          <Button
            color="green"
            className="save_button"
            icon="check"
            content={t("save", { ns: "common" })}
            disabled={isSubmitting || !dirty || !isValid}
            loading={isSubmitting}
            type="submit"
          />
          <Button
            className="save_button"
            onClick={() => store.modalStore.closeModal()}
            icon="cancel"
            type="reset"
            content={t("cancel", { ns: "common" })}
          />
        </Form>
      )}
    </Formik>
  );
});
