import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import {
  Page,
  Document,
  PDFViewer,
  View,
  Text,
  Image,
} from "@react-pdf/renderer";
import compose from "../../../../app/layout/compose";
import ClientPdfView from "../TicketViewComponents/ClientPdfView";
import { format } from "date-fns";
import { formatCurrency } from "../../../../app/common/util/functions";
import { TicketCardTransaction } from "../../../../app/models/ticket";

interface Props {
  selectedTicketCardTransaction: TicketCardTransaction;
}

export default observer(function TicketReceipt({
  selectedTicketCardTransaction,
}: Props) {
  const { t } = useTranslation(["common", "tickets"]);

  return (
    <PDFViewer showToolbar={true} width={"100%"} height={window.innerHeight}>
      <Document
        pageMode={"useOutlines"}
        title={`${t(`payments.transactionReceipt`, { ns: "tickets" })}`}
      >
        <Page
          size={"LETTER"}
          style={{
            ...compose("page invoice-wrapper body"),
            marginBottom: "7mm",
          }}
          wrap
        >
          <ClientPdfView logoCenter={false} isReceipt={true} />
          <View
            style={{
              ...compose("view"),
              width: "80mm",
              maxWidth: "80mm",
            }}
          >
            <View
              style={{
                ...compose("view flex noMargin noPadding"),
                width: "80mm",
                maxWidth: "80mm",
                flexGrow: 1,
                flexShrink: 1,
                marginTop: "10mm",
              }}
              wrap={false}
            >
              <View
                style={{
                  ...compose("view noMargin noPadding"),
                  width: "40mm",
                  maxWidth: "40mm",
                  fontFamily: "Times-Bold",
                  fontWeight: "bold",
                }}
              >
                <Text>{selectedTicketCardTransaction.transactionType}</Text>
              </View>
              <View
                style={{
                  ...compose("view noMargin noPadding textAlignRight"),
                  width: "40mm",
                  maxWidth: "40mm",
                }}
              >
                <Text>
                  **** **** **** {selectedTicketCardTransaction.cardNumber}
                </Text>
                <Text>{selectedTicketCardTransaction.cardType}</Text>
                <Text>{selectedTicketCardTransaction.cardEntryMethod}</Text>
                <Text style={{ marginTop: "10mm" }}>
                  {selectedTicketCardTransaction.transactionDate &&
                    format(selectedTicketCardTransaction.transactionDate, "Pp")}
                </Text>
              </View>
            </View>
            <View
              style={{
                ...compose("view flex noMargin noPadding"),
                width: "80mm",
                maxWidth: "80mm",
                flexGrow: 1,
                flexShrink: 1,
                marginTop: "10mm",
              }}
              wrap={false}
            >
              <View
                style={{
                  ...compose("view noMargin noPadding"),
                  width: "40mm",
                  maxWidth: "40mm",
                  fontFamily: "Times-Bold",
                  fontWeight: "bold",
                }}
              >
                <Text>{t("name", { ns: "tickets" }).toUpperCase()}:</Text>
              </View>
              <View
                style={{
                  ...compose("view noMargin noPadding textAlignRight"),
                  width: "40mm",
                  maxWidth: "40mm",
                }}
              >
                <Text>{selectedTicketCardTransaction.cardHolderName}</Text>
              </View>
            </View>
            <View
              style={{
                ...compose("view flex noMargin noPadding"),
                width: "80mm",
                maxWidth: "80mm",
                flexGrow: 1,
                flexShrink: 1,
                marginTop: "1mm",
              }}
              wrap={false}
            >
              <View
                style={{
                  ...compose("view noMargin noPadding"),
                  width: "40mm",
                  maxWidth: "40mm",
                  fontFamily: "Times-Bold",
                  fontWeight: "bold",
                }}
              >
                <Text>{t("payments.bin", { ns: "tickets" })}:</Text>
              </View>
              <View
                style={{
                  ...compose("view noMargin noPadding textAlignRight"),
                  width: "40mm",
                  maxWidth: "40mm",
                }}
              >
                <Text>{selectedTicketCardTransaction.bin}</Text>
              </View>
            </View>
            <View
              style={{
                ...compose("view flex noMargin noPadding"),
                width: "80mm",
                maxWidth: "80mm",
                flexGrow: 1,
                flexShrink: 1,
                marginTop: "1mm",
              }}
              wrap={false}
            >
              <View
                style={{
                  ...compose("view noMargin noPadding"),
                  width: "40mm",
                  maxWidth: "40mm",
                  fontFamily: "Times-Bold",
                  fontWeight: "bold",
                }}
              >
                <Text>{t("payments.batch", { ns: "tickets" })}:</Text>
              </View>
              <View
                style={{
                  ...compose("view noMargin noPadding textAlignRight"),
                  width: "40mm",
                  maxWidth: "40mm",
                }}
              >
                <Text>{selectedTicketCardTransaction.batchNumber}</Text>
              </View>
            </View>
            <View
              style={{
                ...compose("view flex noMargin noPadding"),
                width: "80mm",
                maxWidth: "80mm",
                flexGrow: 1,
                flexShrink: 1,
                marginTop: "1mm",
              }}
              wrap={false}
            >
              <View
                style={{
                  ...compose("view noMargin noPadding"),
                  width: "40mm",
                  maxWidth: "40mm",
                  fontFamily: "Times-Bold",
                  fontWeight: "bold",
                }}
              >
                <Text>{t("payments.terminal", { ns: "tickets" })}:</Text>
              </View>
              <View
                style={{
                  ...compose("view noMargin noPadding textAlignRight"),
                  width: "40mm",
                  maxWidth: "40mm",
                }}
              >
                <Text>{selectedTicketCardTransaction.terminalId}</Text>
              </View>
            </View>
            <View
              style={{
                ...compose("view flex noMargin noPadding"),
                width: "80mm",
                maxWidth: "80mm",
                flexGrow: 1,
                flexShrink: 1,
                marginTop: "1mm",
              }}
              wrap={false}
            >
              <View
                style={{
                  ...compose("view noMargin noPadding"),
                  width: "40mm",
                  maxWidth: "40mm",
                  fontFamily: "Times-Bold",
                  fontWeight: "bold",
                }}
              >
                <Text>{t("auth", { ns: "common" }).toUpperCase()}:</Text>
              </View>
              <View
                style={{
                  ...compose("view noMargin noPadding textAlignRight"),
                  width: "40mm",
                  maxWidth: "40mm",
                }}
              >
                <Text>{selectedTicketCardTransaction.authorizationCode}</Text>
              </View>
            </View>
            <View
              style={{
                ...compose("view flex noMargin noPadding"),
                width: "80mm",
                maxWidth: "80mm",
                flexGrow: 1,
                flexShrink: 1,
                marginTop: "10mm",
              }}
              wrap={false}
            >
              <View
                style={{
                  ...compose("view noMargin noPadding"),
                  width: "40mm",
                  maxWidth: "40mm",
                  fontFamily: "Times-Bold",
                  fontWeight: "bold",
                }}
              >
                <Text>{t("total", { ns: "tickets" }).toUpperCase()}:</Text>
              </View>
              <View
                style={{
                  ...compose("view noMargin noPadding textAlignRight"),
                  width: "40mm",
                  maxWidth: "40mm",
                }}
              >
                <Text>
                  {formatCurrency(selectedTicketCardTransaction.amount ?? 0.0)}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              ...compose("view textAlignCenter"),
              width: "80mm",
              maxWidth: "80mm",
              marginTop: "10mm",
              paddingLeft: "2mm",
              paddingRight: "2mm",
            }}
          >
            <Text>
              I agree to pay above total amount according to card issuer
              agreement
            </Text>
            <Text>(Merchant agreement if credit voucher)</Text>
          </View>
          {selectedTicketCardTransaction.signature && (
            <View
              style={{
                ...compose("view textAlignCenter height40mm"),
                maxWidth: "80mm",
                width: "80mm",
              }}
            >
              <Image
                style={{
                  ...compose(`image`),
                  maxHeight: "40mm",
                  maxWidth: "80mm",
                }}
                src={`data:image/png;base64,${selectedTicketCardTransaction.signature}`}
              />
            </View>
          )}
        </Page>
      </Document>
    </PDFViewer>
  );
});
