import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { Application } from "../models/enums";
import { Pagination, PagingParams } from "../models/pagination";
import {
  ClientPaymentHistory,
  ClientSubscriptionStatus,
  ClientSubscriptions,
  ClientSubscriptionsFormValues,
} from "../models/clientSubscription";

export default class SubscriptionStore {
  loadingInitial = false;
  activeTab: number = 0;

  subscriptionPaymentRegistry = new Map<
    string | undefined,
    ClientPaymentHistory
  >();
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 10);

  clientSubscriptionStatus: ClientSubscriptionStatus = {
    lastPaymentDate: new Date(),
    application: Application.None,
    activeUsers: 0,
    subscriptionUsers: 0,
    lastPaymentAmount: undefined,
    renewalApplication: undefined,
    renewalDate: undefined,
    renewalPaymentAmount: 0.0,
    renewalUsers: 0,
    renewalPeriod: 0,
    isTrial: false,
    isActive: false,
    isExtension: false,
    isCancelled: false,
    cancelledDate: undefined,
    subscriptionStart: new Date(),
    term: 0,
  };

  availableTrials: Application = Application.None;
  trialExtensionAvailable: boolean = false;
  subscriptionExtensionAvailable: boolean = false;

  clientSubscription: ClientSubscriptionsFormValues = {
    dowellSystemApplication: undefined,
    term: undefined,
    userCount: undefined,
    isTrial: false,
    firstName: undefined,
    lastName: undefined,
    clientName: undefined,
    email: undefined,
    addressLine1: undefined,
    addressLine2: undefined,
    city: undefined,
    state: undefined,
    postalCode: undefined,
    country: undefined,
    billingPhone: undefined,
    creditCardNumber: undefined,
    expirationMonth: undefined,
    expirationYear: undefined,
    securityCode: undefined,
    agreeTerms: false,
    agreeSubscription: false,
    useCreditCardOnFile: false,
    error: undefined,
  };

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => {},
      () => {
        this.pagingParams = new PagingParams(1, 10);
        this.subscriptionPaymentRegistry.clear();
        this.loadClientSubscriptionPayments();
      }
    );
  }

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  calculateSubscription = async (
    dowellSystemApplication: Application,
    userCount: number,
    term: number
  ) => {
    try {
      const ip = await agent.Subscription.calculateSubscription(
        dowellSystemApplication,
        userCount,
        term
      );
      return ip;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getCurrentSubscription = async () => {
    this.loadingInitial = true;
    try {
      let result = await agent.Subscription.getCurrentSubscription();

      runInAction(() => {
        this.clientSubscriptionStatus = result;
        if (result.lastPaymentDate)
          this.clientSubscriptionStatus.lastPaymentDate = new Date(
            result.lastPaymentDate
          );
        if (result.renewalDate)
          this.clientSubscriptionStatus.renewalDate = new Date(
            result.renewalDate
          );
        if (result.cancelledDate)
          this.clientSubscriptionStatus.cancelledDate = new Date(
            result.cancelledDate
          );
        if (result.subscriptionStart)
          this.clientSubscriptionStatus.subscriptionStart = new Date(
            result.subscriptionStart
          );
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadSubscription = async () => {
    this.loadingInitial = true;
    try {
      let result = await agent.Subscription.getSubscription();

      runInAction(() => {
        this.clientSubscription = new ClientSubscriptionsFormValues(result);
      });
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  loadClientSubscriptionPayments = async () => {
    try {
      this.subscriptionPaymentRegistry.clear();
      let result = await agent.Subscription.listClientSubscriptionPayments(this.axiosParams);

      runInAction(() => {
        result.data.forEach((miscellaneousCharge) => {
          this.setClientSubscriptionPayment(miscellaneousCharge);
        });
        this.setPagination(result.pagination);
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  private setClientSubscriptionPayment = (
    clientPaymentHistory: ClientPaymentHistory
  ) => {
    if (clientPaymentHistory.createdDate)
      clientPaymentHistory.createdDate = new Date(
        clientPaymentHistory.createdDate
      );

    this.subscriptionPaymentRegistry.set(
      clientPaymentHistory.clientBillingId,
      clientPaymentHistory
    );
  };

  get getSubscriptionPayments() {
    return Array.from(this.subscriptionPaymentRegistry.values()).sort(
      (a, b) => b.createdDate!.getTime() - a.createdDate!.getTime()
    );
  }

  cancelSubscription = async () => {
    try {
      await agent.Subscription.cancelSubscription();
    } catch (error) {
      console.log(error);
    }
  };

  cancelSubscriptionPageView = async () => {
    try {
      await agent.Subscription.cancelSubscriptionPageView();
    } catch (error) {
      console.log(error);
    }
  };

  createSubscription = async (
    clientSubscriptionsFormValues: ClientSubscriptionsFormValues
  ) => {
    try {
      let myNew: ClientSubscriptions = new ClientSubscriptions(
        clientSubscriptionsFormValues
      );
      await agent.Subscription.createSubscription(myNew);

      runInAction(() => {});
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getAvailableTrials = async () => {
    try {
      const trials = await agent.Subscription.availableTrials();

      runInAction(() => (this.availableTrials = trials));
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getTrialExtensionAvailable = async () => {

    try {
      const trials = await agent.Subscription.trialExtensionAvailable();

      runInAction(() => (this.trialExtensionAvailable = trials));
    } catch (error) {
      console.log(error);
      throw error;
    }
    
  }

  getSubscriptionExtensionAvailable = async() => {
    try {
      const available = await agent.Subscription.subscriptionExtensionAvailable();

      runInAction(() => this.subscriptionExtensionAvailable = available);
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  updateBillingInformation = async (
    clientSubscriptionsFormValues: ClientSubscriptionsFormValues
  ) => {
    try {
      let myNew: ClientSubscriptions = new ClientSubscriptions(
        clientSubscriptionsFormValues
      );
      await agent.Subscription.updateBillingInformation(myNew);

      runInAction(() => {});
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateServiceOptions = async (
    clientSubscriptionsFormValues: ClientSubscriptionsFormValues
  ) => {
    try {
      let myNew: ClientSubscriptions = new ClientSubscriptions(
        clientSubscriptionsFormValues
      );
      await agent.Subscription.updateServiceOptions(myNew);

      runInAction(() => {});
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateAddUser = async (
    clientSubscriptionsFormValues: ClientSubscriptionsFormValues
  ) => {
    try {
      let myNew: ClientSubscriptions = new ClientSubscriptions(
        clientSubscriptionsFormValues
      );
      await agent.Subscription.updateAddUsers(myNew);

      runInAction(() => {});
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateRenewalTerms = async (
    clientSubscriptionsFormValues: ClientSubscriptionsFormValues
  ) => {
    try {
      let myNew: ClientSubscriptions = new ClientSubscriptions(
        clientSubscriptionsFormValues
      );
      await agent.Subscription.updateRenewalTerms(myNew);

      runInAction(() => {});
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  prorateSubscription = async (
    dowellSystemApplication: Application,
    userCount: number,
    term: number
  ) => {
    try {
      const ip = await agent.Subscription.prorateSubscription(
        dowellSystemApplication,
        userCount,
        term
      );
      return ip;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  prorateAddUser = async (
    dowellSystemApplication: Application,
    userCount: number,
    term: number
  ) => {
    try {
      const ip = await agent.Subscription.prorateAddUser(
        dowellSystemApplication,
        userCount,
        term
      );
      return ip;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  createSubscriptionExtension = async (id: string, isAdmin:boolean) => {
    try {
      await agent.Subscription.addSubscriptionExtension(id, isAdmin);
    } catch (error) {
      console.log(error);
    }
  };

  createTrialExtension = async (id: string, isAdmin:boolean, extendedLength: number) => {
    try {
      await agent.Subscription.addTrialExtension(id, isAdmin, extendedLength);
    } catch (error) {
      console.log(error);
    }
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 10);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    return params;
  }
}
