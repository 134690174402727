import React from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

interface Props {
  imagePreview: string;
  setCropper: (cropper: Cropper) => void;
}

export default function PhotoWidgetCropper({
  imagePreview,
  setCropper,
}: Readonly<Props>) {
  return (
    <Cropper
      src={imagePreview}
      style={{ height: 150, width: "100%"}}
      initialAspectRatio={2.2}
      aspectRatio={2.2}
      preview=".img-preview"
      guides={false}
      viewMode={1}
      autoCropArea={1}
      background={false}
  
      onInitialized={(cropper) => setCropper(cropper)}
      minCropBoxHeight = {150}
      minCropBoxWidth = {330}

    />
  );
}
