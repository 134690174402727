import { format } from "date-fns";
import { useField } from "formik";
import React, { useEffect } from "react";
import { Form, Label } from "semantic-ui-react";
interface Props {
  name: string;
  label?: string;
  className?: string;
  setFieldValue?: (name: string, value: any) => void
}

export default function MyDateInput({
  name,
  label,
  className,
  setFieldValue,
}: Readonly<Props>) {
  const [field, meta] = useField(name);
  let labelClassName: string = "myLabel";
  let inputClassName: string = "input";
  if (className) labelClassName = `${labelClassName} ${className}`;
  if (className) inputClassName = `${inputClassName} ${className}`;

  useEffect(() => {
    if (!field.value && setFieldValue) {
      setFieldValue(name, new Date());
    }
  }, [name, field, setFieldValue]);

  return (
    <Form.Field error={meta.touched && !!meta.error} className="ui" name={name}>
      {label && label.length > 0 && (
        <label className={labelClassName} htmlFor={name}>
          {`${label} `}
        </label>
      )}
      <input
        name={name}
        type="datetime-local"
        className={inputClassName}
        value={format(field.value ? new Date(field.value) : new Date(), "yyyy-MM-dd'T'HH:mm")}
        onChange={(e) => { setFieldValue && setFieldValue(name, e.target.value) }}
        id={name}
      />
      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
