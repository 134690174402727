import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Menu, Tab } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { Link, useNavigate } from "react-router-dom";
import CancellationReport from "./CancellationReport";
import RecurringCharges from "./RecurringCharges";
import NextChargeAdjustments from "./NextChargeAdjustments";
import EarlyChargeReport from "./EarlyChargeReport";
import SubscriberReport from "./SubscriberReport";

interface Props {
  activeSubTab: number;
}

export default observer(function AdminToolsTabs(props: Props) {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const navigate = useNavigate();

  const { systemAdminStore } = useStore();
  const { setActiveSubTab, activeSubTab } = systemAdminStore;

  const panes = [
    {
      menuItem: {
        as: Link,
        content: t("Cancellation Report", { ns: "systemAdmin" }),
        to: "/admin/AdminTools/cancellationReport",
        exact: "true",
      },
      render: () => (
        <Tab.Pane active={0 === activeSubTab}>
          <CancellationReport />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: t("Recurring Charges", { ns: "systemAdmin" }),
        to: "/admin/AdminTools/recurringCharges",
        exact: "true",
      },
      render: () => (
        <Tab.Pane active={1 === activeSubTab}>
          <RecurringCharges />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: "Next Charge Adjustments",
        to: "/admin/AdminTools/nextChargeAdjustment",
        exact: "true",
      },
      render: () => (
        <Tab.Pane active={2 === activeSubTab}>
          <NextChargeAdjustments />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: "Early Charge Report",
        to: "/admin/AdminTools/earlyChargeReport",
        exact: "true",
      },
      render: () => (
        <Tab.Pane active={3 === activeSubTab}>
          <EarlyChargeReport />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        as: Link,
        content: "Subscriber Report", 
        to: "/admin/AdminTools/subscriberReport",
        exact: "true",
      },
      render: () => (
        <Tab.Pane active={4 === activeSubTab}>
          <SubscriberReport />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Tab
        menu={{ fluid: true }}
        menuPosition="left"
        panes={panes}
        activeIndex={activeSubTab}
        onTabChange={(e, data) => {
          setActiveSubTab(data.activeIndex);
        }}
      />
    </>
  );
});