import { observer } from "mobx-react-lite";
import React from "react";
import { Container, Grid, Header, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { ReportType, SearchType } from "../../../app/models/enums";
import { useStore } from "../../../app/stores/store";
import {
  formatCurrency,
  formatDecimal,
  getNegative,
} from "../../../app/common/util/functions";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "../../../app/layout/PageHeader";

interface Props {
  startDate: Date;
  endDate: Date;
  reportType: ReportType;
}

export default observer(function VendorPrint({
  startDate,
  endDate,
  reportType,
}: Props) {
  const { t } = useTranslation(["common", "reports"]);
  const navigate = useNavigate();

  const { vendorReportStore, searchStore } = useStore();
  const {
    groupedVendorReport,
    calculateVendor,
    reportCriteria,
    getVendorReport,
  } = vendorReportStore;

  const { setPredicate: searchPredicate } = searchStore;

  return (
    <>
      <Container className="page">
        <Grid stackable columns={2} className="headerPrint">
          <Grid.Row>
            <Grid.Column>
              <PageHeader
                header={`${t("report", { ns: "reports" })}: ${t("pops.vendor", {
                  ns: "tickets",
                })}`}
                type="h2"
                divider={false}
                getAlerts={true}
                addTitle={true}
              />
            </Grid.Column>
            <Grid.Column>
              <Header
                content={`${format(startDate, "PP")} - ${format(
                  endDate,
                  "PP"
                )}`}
                as="h2"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {groupedVendorReport && groupedVendorReport.length > 0 && (
          <Table striped unstackable className="dnxTable">
            {reportType === ReportType.Detailed
              ? groupedVendorReport.map(([group, x]) => (
                  <>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell
                          colSpan={11}
                          textAlign="left"
                          className="terms_bold"
                        >
                          {group}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          {t("date", { ns: "common" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t("inv", { ns: "search" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t("part_number", { ns: "reports" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          {t("description", { ns: "common" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("estimate.unit_cost", { ns: "tickets" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("pops.mark_up", { ns: "tickets" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("estimate.unit_price", { ns: "tickets" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("pops.qty", { ns: "tickets" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("total_cost", { ns: "reports" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("revenue", { ns: "reports" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("profit", { ns: "reports" })}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {x.map((item) => (
                        <Table.Row key={`vendor.${item.date}`}>
                          <Table.Cell
                            data-label={`${t("date", { ns: "common" })}:`}
                          >
                            {format(item.date, "PP")}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("inv", { ns: "search" })}:`}
                          >
                            <Link
                              onClick={() => {
                                searchPredicate(
                                  SearchType.InvoiceNumber,
                                  item.invoiceNumber
                                );
                                navigate(
                                  `/search/${SearchType.InvoiceNumber}/${item.invoiceNumber}`
                                );
                              }}
                              to={""}
                            >
                              {item.invoiceNumber}
                            </Link>
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("part_number", {
                              ns: "reports",
                            })}:`}
                          >
                            {item.partCode}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("description", {
                              ns: "common",
                            })}:`}
                          >
                            {item.description}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("estimate.unit_cost", {
                              ns: "tickets",
                            })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(item.unitPrice),
                            }}
                          >
                            {formatCurrency(item.unitPrice)}
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            data-label={`${t("pops.mark_up", {
                              ns: "tickets",
                            })}:`}
                          >
                            {item.markup < 0
                              ? `(${formatDecimal(Math.abs(item.markup), 2)}%)`
                              : `${formatDecimal(item.markup, 2)}%`}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("estimate.unit_price", {
                              ns: "tickets",
                            })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(item.rate),
                            }}
                          >
                            {formatCurrency(item.rate)}
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            data-label={`${t("pops.qty", { ns: "tickets" })}:`}
                          >
                            {item.quantity < 0
                              ? `(${formatDecimal(Math.abs(item.quantity), 2)})`
                              : `${formatDecimal(item.quantity, 2)}`}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("total_cost", {
                              ns: "reports",
                            })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(item.costTotal),
                            }}
                          >
                            {formatCurrency(item.costTotal)}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("revenue", { ns: "reports" })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(item.subTotal),
                            }}
                          >
                            {formatCurrency(item.subTotal)}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("profit", { ns: "reports" })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(item.profit),
                            }}
                          >
                            {formatCurrency(item.profit)}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                      <Table.Row className="terms_bold">
                        {
                          <>
                            <Table.Cell>
                              {t("vendor_totals", { ns: "reports" })}
                            </Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell
                              textAlign="right"
                              data-label={`${t("pops.mark_up", {
                                ns: "tickets",
                              })}:`}
                            >
                              {formatDecimal(calculateVendor(x).markup, 2)}%
                            </Table.Cell>
                            <Table.Cell textAlign="right"></Table.Cell>
                            <Table.Cell
                              textAlign="right"
                              data-label={`${t("pops.qty", {
                                ns: "tickets",
                              })}:`}
                              style={{
                                color: getNegative(calculateVendor(x).quantity),
                              }}
                            >
                              {formatDecimal(calculateVendor(x).quantity, 2)}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="right"
                              data-label={`${t("total_cost", {
                                ns: "reports",
                              })}:`}
                              style={{
                                color: getNegative(
                                  calculateVendor(x).costTotal
                                ),
                              }}
                            >
                              {formatCurrency(calculateVendor(x).costTotal)}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="right"
                              data-label={`${t("revenue", { ns: "reports" })}:`}
                              style={{
                                color: getNegative(calculateVendor(x).subTotal),
                              }}
                            >
                              {formatCurrency(calculateVendor(x).subTotal)}
                            </Table.Cell>
                            <Table.Cell
                              textAlign="right"
                              data-label={`${t("profit", { ns: "reports" })}:`}
                              style={{
                                color: getNegative(calculateVendor(x).profit),
                              }}
                            >
                              {formatCurrency(calculateVendor(x).profit)}
                            </Table.Cell>
                          </>
                        }
                      </Table.Row>
                      <Table.Row className="noBackground">
                        <Table.Cell colSpan={11}></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </>
                ))
              : groupedVendorReport.map(([group, x]) => (
                  <>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell
                          colSpan={11}
                          textAlign="left"
                          className="terms_bold"
                        >
                          {group}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          {t("month", { ns: "reports" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("cost", { ns: "reports" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("revenue", { ns: "reports" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("lowest_markup", { ns: "reports" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("highest_markup", { ns: "reports" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("total_markup", { ns: "reports" })}
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          {t("profit", { ns: "reports" })}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {x.map((item) => (
                        <Table.Row key={`vendor2.${item.date}`}>
                          <Table.Cell
                            data-label={`${t("month", { ns: "reports" })}:`}
                          >
                            {format(item.date, "MMMM yyyy")}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("cost", { ns: "reports" })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(item.costTotal),
                            }}
                          >
                            {formatCurrency(item.costTotal)}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("revenue", { ns: "reports" })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(item.subTotal),
                            }}
                          >
                            {formatCurrency(item.subTotal)}
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            data-label={`${t("lowest_markup", {
                              ns: "reports",
                            })}:`}
                          >
                            {item.lowestMarkup < 0
                              ? `(${formatDecimal(
                                  Math.abs(item.lowestMarkup),
                                  2
                                )}%)`
                              : `${formatDecimal(item.lowestMarkup, 2)}%`}
                          </Table.Cell>
                          <Table.Cell
                            textAlign="right"
                            data-label={`${t("highest_markup", {
                              ns: "reports",
                            })}:`}
                          >
                            {item.highestMarkup < 0
                              ? `(${formatDecimal(
                                  Math.abs(item.highestMarkup),
                                  2
                                )}%)`
                              : `${formatDecimal(item.highestMarkup, 2)}%`}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("total_markup", {
                              ns: "reports",
                            })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(item.totalMarkup),
                            }}
                          >
                            {item.totalMarkup < 0
                              ? `(${formatDecimal(
                                  Math.abs(item.totalMarkup),
                                  2
                                )}%)`
                              : `${formatDecimal(item.totalMarkup, 2)}%`}
                          </Table.Cell>
                          <Table.Cell
                            data-label={`${t("profit", { ns: "reports" })}:`}
                            textAlign="right"
                            style={{
                              color: getNegative(item.profit),
                            }}
                          >
                            {formatCurrency(item.profit)}
                          </Table.Cell>
                        </Table.Row>
                      ))}

                      <Table.Row className="terms_bold">
                        {
                          <>
                            <Table.Cell>
                              {t("vendor_totals", { ns: "reports" })}
                            </Table.Cell>
                            <Table.Cell
                              data-label={`${t("cost", { ns: "reports" })}:`}
                              textAlign="right"
                              style={{
                                color: getNegative(
                                  calculateVendor(x).costTotal
                                ),
                              }}
                            >
                              {formatCurrency(calculateVendor(x).costTotal)}
                            </Table.Cell>
                            <Table.Cell
                              data-label={`${t("revenue", { ns: "reports" })}:`}
                              textAlign="right"
                              style={{
                                color: getNegative(calculateVendor(x).subTotal),
                              }}
                            >
                              {formatCurrency(calculateVendor(x).subTotal)}
                            </Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell
                              data-label={`${t("profit", { ns: "reports" })}:`}
                              textAlign="right"
                              style={{
                                color: getNegative(calculateVendor(x).profit),
                              }}
                            >
                              {formatCurrency(calculateVendor(x).profit)}
                            </Table.Cell>
                          </>
                        }
                      </Table.Row>
                      <Table.Row className="noBackground">
                        <Table.Cell colSpan={11}></Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </>
                ))}
            <Table.Body>
              {reportType === ReportType.Detailed ? (
                <Table.Row className="terms_bold greenRow">
                  <Table.Cell>
                    {t("grandTotals", { ns: "reports" })}:
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell
                    data-label={`${t("amount", { ns: "common" })}:`}
                    textAlign="right"
                  >
                    {formatCurrency(calculateVendor(getVendorReport).costTotal)}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("amount", { ns: "common" })}:`}
                    textAlign="right"
                    l
                  >
                    {formatCurrency(calculateVendor(getVendorReport).subTotal)}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("amount", { ns: "common" })}:`}
                    textAlign="right"
                  >
                    {formatCurrency(calculateVendor(getVendorReport).profit)}
                  </Table.Cell>
                </Table.Row>
              ) : (
                <Table.Row className="terms_bold greenRow">
                  <Table.Cell>
                    {t("grandTotals", { ns: "reports" })}:
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("amount", { ns: "common" })}:`}
                    textAlign="right"
                  >
                    {" "}
                    {formatCurrency(calculateVendor(getVendorReport).costTotal)}
                  </Table.Cell>
                  <Table.Cell
                    data-label={`${t("amount", { ns: "common" })}:`}
                    textAlign="right"
                  >
                    {formatCurrency(calculateVendor(getVendorReport).subTotal)}
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell
                    data-label={`${t("amount", { ns: "common" })}:`}
                    textAlign="right"
                  >
                    {formatCurrency(calculateVendor(getVendorReport).profit)}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        )}
      </Container>
    </>
  );
});
