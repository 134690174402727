import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, Input, Message, Select, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";
import { TicketType, TicketWorkflowSteps } from "../../../app/models/enums";
import ConfirmDelete from "../../../app/common/form/ConfirmDelete";
import { useNavigate } from "react-router-dom";
import MyHelpButton from "../../../app/common/form/MyHelpButton";
import { resetTicket } from "../../../app/common/util/ticketfunctions";

export default observer(function Abandoned() {
  const { t } = useTranslation(["common", "abandoned"]);
  const { ticketSessionStore, modalStore, userStore, ticketStore } = useStore();

  const navigate = useNavigate();

  const {
    loadAbandonedTickets,
    setPagingParams,
    pagination,
    resetTicketSessionRegistry,
    resetPredicate,
    setPredicate,
    abandonedTicketsByDate,
    pagingParams,
    removeTicketSession,
  } = ticketSessionStore;

  const { staffList, loadStaff } = userStore;

  const { loadSettingsWorkflow, selectedWorkflow } = ticketStore;
  useEffect(() => {
    loadSettingsWorkflow();
  }, [loadSettingsWorkflow]);

  useEffect(() => {
    resetTicketSessionRegistry();
    resetPredicate();
    loadAbandonedTickets();
  }, [loadAbandonedTickets]);

  useEffect(() => {
    loadStaff();
  }, [loadStaff]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      resetTicketSessionRegistry();
      loadAbandonedTickets();
    }
  }

  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  function wheresWaldo(workflowsSteps: TicketWorkflowSteps, id: string) {
    resetTicket();
    switch (workflowsSteps) {
      case TicketWorkflowSteps.Customer1:
        navigate(`/ticket/vehicle/${id}/false`);
        break;
      case TicketWorkflowSteps.Vehicle:
        navigate(`/ticket/jobCategory/${id}/false`);
        break;
      case TicketWorkflowSteps.JobCategory:
        navigate(`/ticket/jobType/${id}/false`);
        break;
      case TicketWorkflowSteps.JobType:
        navigate(`/ticket/job/${id}/false`);
        break;
      case TicketWorkflowSteps.Job:
        navigate(`/ticket/time/${id}/false`);
        break;
      case TicketWorkflowSteps.Time:
        navigate(`/ticket/summary/${id}/false`);
        break;
      case TicketWorkflowSteps.Summary:
        if (selectedWorkflow.isCustomerInfoFirst)
          navigate(`/ticket/finalize/${id}/false`);
        else navigate(`/ticket/customer/${id}/false`);
        break;
      case TicketWorkflowSteps.Customer2:
        navigate(`/ticket/finalize/${id}/false`);
        break;
      case TicketWorkflowSteps.Finalize:
        navigate(`/ticket/finalize/${id}/false`);
        break;
      default:
        navigate(`/ticket/finalize/${id}/true`);
        break;
    }
  }

  return (
    <>
      <PageHeader
        header={t("title", { ns: "abandoned" })}
        type={"h1"}
        divider={true}
        addTitle={true}
      />
      <span className="intro">{t("paused", { ns: "abandoned" })}</span>
      <MyHelpButton
        name="abandoned"
        help="abandoned"
        position="bottom left"
        color={"blue"}
        seeMore={true}
      />
      <Message negative size="small" content={t("note", { ns: "abandoned" })} />
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center" verticalAlign="top">
              {t("accessed", { ns: "abandoned" })}
            </Table.HeaderCell>
            <Table.HeaderCell verticalAlign="top" textAlign="center">
              {t("type", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell verticalAlign="middle" textAlign="center">
              {t("number", { ns: "abandoned" })}
              {(isFiltered || (pagination && pagination.totalItems > 10)) && (
                <Input
                  type="text"
                  onChange={(e, d) => {
                    setPredicate("number", d.value);
                    setIsFiltered(d.value.length > 0);
                    resetTicketSessionRegistry();
                  }}
                  // icon={"search"}
                  className="width100"
                  maxLength={20}
                ></Input>
              )}
            </Table.HeaderCell>
            <Table.HeaderCell
              className="padding_down"
              verticalAlign="middle"
              textAlign="center"
            >
              {t("staff", { ns: "abandoned" })}
              {(isFiltered || (pagination && pagination.totalItems > 10)) && (
                <Select
                  className="selection dropdown width100"
                  clearable
                  options={staffList}
                  onClick={(e, d) => {
                    setPredicate("staff", "");
                    setIsFiltered(false);
                    resetTicketSessionRegistry();
                  }}
                  onChange={(e, d) => {
                    if (d.value) {
                      setPredicate("staff", d.value.toString());
                      setIsFiltered(d.value.toString().length > 0);
                    }
                    resetTicketSessionRegistry();
                  }}
                />
              )}
            </Table.HeaderCell>
            <Table.HeaderCell verticalAlign="middle" textAlign="center">
              {t("vehicledescription", { ns: "common" })}
              {(isFiltered || (pagination && pagination.totalItems > 10)) && (
                <Input
                  type="text"
                  onChange={(e, d) => {
                    setPredicate("vehicleDescription", d.value);
                    setIsFiltered(d.value.length > 0);
                    resetTicketSessionRegistry();
                  }}
                  className="width150"
                  maxLength={1000}
                ></Input>
              )}
            </Table.HeaderCell>
            <Table.HeaderCell verticalAlign="middle" textAlign="center">
              {t("customername", { ns: "common" })}
              {(isFiltered || (pagination && pagination.totalItems > 10)) && (
                <Input
                  type="text"
                  onChange={(e, d) => {
                    setPredicate("customerName", d.value);
                    setIsFiltered(d.value.length > 0);
                    resetTicketSessionRegistry();
                  }}
                  className="width100"
                  maxLength={500}
                ></Input>
              )}
            </Table.HeaderCell>
            <Table.HeaderCell verticalAlign="middle" textAlign="center">
              {t("ponumber", { ns: "common" }) + "#"}
              {(isFiltered || (pagination && pagination.totalItems > 10)) && (
                <Input
                  type="text"
                  onChange={(e, d) => {
                    setPredicate("poNumber", d.value);
                    setIsFiltered(d.value.length > 0);
                    resetTicketSessionRegistry();
                  }}
                  className="width100"
                  maxLength={100}
                ></Input>
              )}
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {abandonedTicketsByDate && abandonedTicketsByDate.length > 0 ? (
            abandonedTicketsByDate.map((ticket, index) => (
              <Table.Row key={`ticket.${ticket.id}`}>
                <Table.Cell>
                  <Button
                    color="grey"
                    icon="edit"
                    type="button"
                    onClick={() => {
                      if (ticket.workflowStep !== undefined)
                        wheresWaldo(ticket.workflowStep, ticket.id);
                    }}
                  />
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {format(ticket.accessedDate, "PPp")}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {ticket.ticketType === TicketType.Invoice
                    ? t("estimate.invoice", { ns: "tickets" })
                    : t("estimate.est", { ns: "tickets" })}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {ticket.ticketNumber}
                </Table.Cell>
                <Table.Cell textAlign="center">{ticket.staffName}</Table.Cell>
                <Table.Cell textAlign="center">
                  {ticket.vehicleDescription}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {ticket.customerName}
                </Table.Cell>
                <Table.Cell textAlign="center">{ticket.poNumber}</Table.Cell>
                <Table.Cell>
                  <Button
                    color="grey"
                    icon="trash"
                    onClick={() =>
                      modalStore.openModal(
                        <ConfirmDelete
                          header={t("removeAbandoned", {
                            ns: "tickets",
                          })}
                          value={ticket.id ? ticket.id : ""}
                          deleteValue={removeTicketSession}
                        />
                      )
                    }
                  ></Button>
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row key={"noitems"}>
              <Table.Cell colSpan={9}>
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="9">
              <MyPaging
                itemCount={pagination ? pagination.totalItems : 10}
                pageSize={pagingParams.pageSize}
                currentPage={pagination ? pagination.currentPage : 1}
                onClick={listOnClick}
                onChange={listOnClick}
                floated="right"
              ></MyPaging>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
});
