import React from "react";
import { Card } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import {
  SalesTaxOptionType,
  TicketWorkflowSteps,
} from "../../../app/models/enums";
import CustomerDisplay from "./CustomerDisplay";
import VehicleDisplay from "./VehicleDisplay";
import TicketDisplay from "./TicketDisplay";

interface Props {
  workflowStep: TicketWorkflowSteps;
  disableButtons?: boolean;
  autoCapitalizeFirstLetter: boolean;
  setCustomer?: (value: boolean) => void;
  showTaxUpdate?: boolean;
  settingsTaxRate?: number;
  settingsSalesTaxOptions?: SalesTaxOptionType;
  originalTaxRate?: number;
  originalSalesTaxOptions?: SalesTaxOptionType;
  //setTax?: (value: number) => void;
  //tax?: number;
}

export default observer(function Sidebar({
  workflowStep,
  disableButtons,
  autoCapitalizeFirstLetter,
  setCustomer,
  showTaxUpdate,
  settingsTaxRate,
  settingsSalesTaxOptions,
  originalTaxRate,
  originalSalesTaxOptions
  //setTax,
  //tax,
}: Props) {
  return (
    <Card.Group>
      <TicketDisplay
        workflowStep={workflowStep}
        autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
        showTaxUpdate={showTaxUpdate}
        //setTax={setTax}
       // tax={tax}
        settingsTaxRate={settingsTaxRate}
        settingsSalesTaxOptions={settingsSalesTaxOptions}
        originalTaxRate={originalTaxRate}
        originalSalesTaxOptions={originalSalesTaxOptions}
      />
      <VehicleDisplay
        workflowStep={workflowStep}
        disableButtons={disableButtons}
        autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
      />
      <CustomerDisplay
        workflowStep={workflowStep}
        disableButtons={disableButtons}
        setCustomer={setCustomer}
      />
    </Card.Group>
  );
});
