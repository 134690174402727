import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Label,
  Message,
  Radio,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import MyTextInput from "../../app/common/form/MyTextInput";
import MyCheckbox from "../../app/common/form/MyCheckbox";
import PageHeader from "../../app/layout/PageHeader";
import { miscChargeStatusOptions } from "../../app/common/options/option";
import { useStore } from "../../app/stores/store";
import { MiscellaneousChargeFormValues } from "../../app/models/miscellaneousCharges";
import ValidationErrors from "../errors/ValidationErrors";
import * as Yup from "yup";
import MyIconTextInput from "../../app/common/form/MyIconTextInput";
import { router } from "../../app/router/Routes";
import ExampleCalculations from "./ExampleCalculations";
import MyHelpButton from "../../app/common/form/MyHelpButton";
import LoadingComponent from "../../app/layout/LoadingComponent";

export default observer(function MiscellaneousCharge() {
  const { t } = useTranslation(["common", "miscSettings", "tickets", "errors"]);
  const { miscellaneousChargeStore, ticketStore } = useStore();
  const { id } = useParams<{ id: string }>();

  const {
    createMiscellaneousCharge,
    updateMiscellaneousCharge,
    loadMiscellaneousCharge,
    loadingInitial,
  } = miscellaneousChargeStore;
  const {
    loadSettingsTicketInputDefaults,
    selectedSettingsTicketInputDefaults,
  } = ticketStore;

  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadSettingsTicketInputDefaults();
  }, [loadSettingsTicketInputDefaults]);

  const validationSchema = Yup.object({
    description: Yup.string()
      .required(t("required_description", { ns: "errors" }))
      .max(255),
    appliesTo: Yup.string().max(255),
    //TODO fix these later
    // laborPercentOver: Yup.string().when("useLaborPercentage", {
    //   is: true,
    //   then: Yup.string().required(),
    //   otherwise: Yup.string().notRequired,
    // }),
    // laborPercentSplit: Yup.string().when("useLaborPercentageValues", {
    //   is: true,
    //   then: Yup.string().required(),
    //   otherwise: Yup.string().notRequired,
    // }),
    // laborPercentUnder: Yup.string().when("useLaborPercentageValues", {
    //   is: true,
    //   then: Yup.string().required(),
    //   otherwise: Yup.string().notRequired,
    // }),
    // partPercentage: Yup.string().when("usePartPercentage", {
    //   is: true,
    //   then: Yup.string().required(),
    //   otherwise: Yup.string().notRequired,
    // }),
    // minimumAmount: Yup.string().when("useMinimumAmount", {
    //   is: true,
    //   then: Yup.string().required(),
    //   otherwise: Yup.string().notRequired,
    // }),
    // maximumAmount: Yup.string().when("useMaximumAmount", {
    //   is: true,
    //   then: Yup.string().required(),
    //   otherwise: Yup.string().notRequired,
    // }),
  });

  const [miscellaneousCharge, setMiscellaneousCharge] =
    useState<MiscellaneousChargeFormValues>(
      new MiscellaneousChargeFormValues()
    );

  useEffect(() => {
    if (id)
      loadMiscellaneousCharge(id).then((miscCharge) =>
        setMiscellaneousCharge(new MiscellaneousChargeFormValues(miscCharge))
      );
  }, [id, loadMiscellaneousCharge]);

  function handleFormSubmit(
    values: MiscellaneousChargeFormValues,
    setErrors: (errors: FormikErrors<MiscellaneousChargeFormValues>) => void
  ) {
    const action = values.id
      ? () => updateMiscellaneousCharge(values)
      : () => createMiscellaneousCharge(values);

    action()
      .then(() => {
        setCompleted(true);
        router.navigate("/settings/misccharges");
      })
      .catch((error) => {
        setErrors({ error: error });
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent content={t("misc_loading", { ns: "miscSettings" })} />
    );

  return (
    <Container className="page">
      <PageHeader
        header={t("new_charges.intro", { ns: "miscSettings" })}
        type="h2"
        divider
        getAlerts={true}
        addTitle={true}
      />
      <Formik
        enableReinitialize
        initialValues={miscellaneousCharge}
        validationSchema={validationSchema}
        onSubmit={(values, { setErrors }) =>
          handleFormSubmit(values, setErrors)
        }
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
          setErrors,
          setFieldValue,
        }) => (
          <Form className="ui form error" autoComplete="off" onSubmit={handleSubmit}>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column width={12}>
                  {completed && isValid && (
                    <Message positive>
                      {t("settingsupdated", {
                        ns: "common",
                      })}
                    </Message>
                  )}
                  <ErrorMessage
                    name="error"
                    render={() => <ValidationErrors errors={errors.error} />}
                  />
                  {values.useMinimumAmount &&
                    values.useMaximumAmount &&
                    values.maximumAmount < values.minimumAmount && (
                      <Message negative>
                        {values.useLaborPercentage &&
                        values.laborPercentOver < 0 &&
                        !values.usePartPercentage
                          ? t("miscellaneousCharges.discountWarning", {
                              ns: "errors",
                            })
                          : t("miscellaneousCharges.amountWarning", {
                              ns: "errors",
                            })}
                      </Message>
                    )}

                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column verticalAlign="middle" width={8}>
                        <MyTextInput
                          name="description"
                          placeholder={t("description", { ns: "common" })}
                          label={`${t("description", { ns: "common" })}:`}
                          className="required"
                          helpName="settings.miscCharges.descirption"
                          showHelp
                          autoCapitalizeFirstLetter={
                            selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                          }
                          maxLength={255}
                        />
                      </Grid.Column>
                      <Grid.Column verticalAlign="middle" width={8}>
                        <MyTextInput
                          name="appliesTo"
                          placeholder={t("applieto", { ns: "miscSettings" })}
                          label={`${t("applieto", { ns: "miscSettings" })}:`}
                          helpName="settings.miscCharges.appliesTo"
                          showHelp
                          autoCapitalizeFirstLetter={
                            selectedSettingsTicketInputDefaults.capitalizeFirstCharacter
                          }
                          maxLength={255}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <MyCheckbox
                          label={t("new_charges.percentlabor", {
                            ns: "miscSettings",
                          })}
                          name="useLaborPercentage"
                          disabled={false}
                          updateValue={(checked) => {
                            values.useLaborPercentage = checked ?? false;
                            if (!values.useLaborPercentage) {
                              setFieldValue("laborPercentOver", 0);
                              setFieldValue("useLaborPercentageValues", false);
                              setFieldValue("laborPercentSplit", 0);
                              setFieldValue("laborPercentUnder", 0);
                            }
                          }}
                          helpName="settings.miscCharges.percentLabor"
                          showHelp
                        />
                      </Grid.Column>
                    </Grid.Row>

                    {values.useLaborPercentage && (
                      <>
                        <Grid.Row>
                          <Grid.Column width={2}></Grid.Column>
                          <Grid.Column verticalAlign="middle" width={1}>
                            {t("new_charges.charge", { ns: "miscSettings" })}
                          </Grid.Column>
                          <Grid.Column verticalAlign="middle" width={3}>
                            <MyIconTextInput
                              placeholder={`${t("percentPlaceholder", {
                                ns: "miscSettings",
                              })}`}
                              name="laborPercentOver"
                              type="number"
                              icon={"percent"}
                              maxLength={500}
                            />
                          </Grid.Column>
                          <Grid.Column verticalAlign="middle" width={3}>
                            {t("new_charges.oflaborsubtotal", {
                              ns: "miscSettings",
                            })}
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column verticalAlign="middle" width={5}>
                            <MyCheckbox
                              name="useLaborPercentageValues"
                              disabled={false}
                              updateValue={(checked) => {
                                if (!checked) {
                                  setFieldValue("laborPercentSplit", 0);
                                  setFieldValue("laborPercentUnder", 0);
                                }
                                values.useLaborPercentageValues =
                                  checked ?? false;
                              }}
                              label={t("new_charges.iflaboramountislessthen", {
                                ns: "miscSettings",
                              })}
                            />
                          </Grid.Column>
                          <Grid.Column verticalAlign="middle" width={3}>
                            <MyIconTextInput
                              placeholder={`${t("dollarPlaceholder", {
                                ns: "miscSettings",
                              })}`}
                              name="laborPercentSplit"
                              type="number"
                              disabled={!values.useLaborPercentageValues}
                              icon={"dollar"}
                              iconPosition="left"
                              maxLength={500}
                            />
                          </Grid.Column>
                          <Grid.Column verticalAlign="middle" width={2}>
                            {t("new_charges.commacharge", {
                              ns: "miscSettings",
                            })}
                          </Grid.Column>
                          <Grid.Column verticalAlign="middle" width={3}>
                            <MyIconTextInput
                              placeholder={`${t("percentPlaceholder", {
                                ns: "miscSettings",
                              })}`}
                              name="laborPercentUnder"
                              type="number"
                              disabled={!values.useLaborPercentageValues}
                              icon="percent"
                              maxLength={500}
                            />
                          </Grid.Column>
                          <Grid.Column verticalAlign="middle" width={3}>
                            {t("new_charges.oflaborsubtotal", {
                              ns: "miscSettings",
                            })}
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}

                    <Grid.Row>
                      <Grid.Column verticalAlign="middle" width={5}>
                        <MyCheckbox
                          label={t("new_charges.percentparts", {
                            ns: "miscSettings",
                          })}
                          name="usePartPercentage"
                          disabled={false}
                          updateValue={(checked) => {
                            values.usePartPercentage = checked ?? false;
                            if (!values.usePartPercentage) {
                              setFieldValue("partPercentage", 0);
                            }
                          }}
                          helpName="settings.miscCharges.percentPart"
                          showHelp
                        />
                      </Grid.Column>
                      <Grid.Column verticalAlign="middle" width={3}>
                        {values.usePartPercentage && (
                          <MyIconTextInput
                            placeholder={`${t("percentPlaceholder", {
                              ns: "miscSettings",
                            })}`}
                            name="partPercentage"
                            type="number"
                            icon={"percent"}
                            maxLength={500}
                          />
                        )}
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column verticalAlign="middle" width={5}>
                        <MyCheckbox
                          label={
                            values.useLaborPercentage ||
                            values.usePartPercentage
                              ? values.useLaborPercentage &&
                                values.laborPercentOver < 0 &&
                                !values.usePartPercentage
                                ? t("new_charges.maximumDiscount", {
                                    ns: "miscSettings",
                                  })
                                : t("new_charges.minimumAmount", {
                                    ns: "miscSettings",
                                  })
                              : t("new_charges.fixedAmount", {
                                  ns: "miscSettings",
                                })
                          }
                          name="useMinimumAmount"
                          disabled={false}
                          updateValue={(checked) => {
                            values.useMinimumAmount = checked ?? false;
                            if (!values.useMinimumAmount) {
                              setFieldValue("minimumAmount", null);
                            }
                          }}
                          helpName="settings.miscCharges.fixedAmount"
                          showHelp
                        />
                      </Grid.Column>
                      {values.useMinimumAmount && (
                        <>
                          <Grid.Column verticalAlign="middle" width={3}>
                            <MyIconTextInput
                              placeholder={`${t("dollarPlaceholder", {
                                ns: "miscSettings",
                              })}`}
                              name="minimumAmount"
                              type="number"
                              icon={"dollar"}
                              iconPosition="left"
                              maxLength={500}
                            />
                          </Grid.Column>

                          <Grid.Column verticalAlign="middle" width={6}>
                            {t("new_charges.willbecharged", {
                              ns: "miscSettings",
                            })}
                          </Grid.Column>
                        </>
                      )}
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column verticalAlign="middle" width={5}>
                        <MyCheckbox
                          label={
                            values.useLaborPercentage &&
                            values.laborPercentOver < 0 &&
                            !values.usePartPercentage
                              ? t("new_charges.minimalDiscount", {
                                  ns: "miscSettings",
                                })
                              : t("new_charges.maximumamount", {
                                  ns: "miscSettings",
                                })
                          }
                          name="useMaximumAmount"
                          disabled={false}
                          updateValue={(checked) => {
                            values.useMaximumAmount = checked ?? false;
                            if (!values.useMaximumAmount) {
                              setFieldValue("maximumAmount", null);
                            }
                          }}
                        />
                      </Grid.Column>
                      {values.useMaximumAmount && (
                        <>
                          <Grid.Column verticalAlign="middle" width={3}>
                            {t("new_charges.nomorethan", {
                              ns: "miscSettings",
                            })}
                          </Grid.Column>
                          <Grid.Column verticalAlign="middle" width={3}>
                            <MyIconTextInput
                              placeholder={`${t("dollarPlaceholder", {
                                ns: "miscSettings",
                              })}`}
                              name="maximumAmount"
                              type="number"
                              icon="dollar"
                              iconPosition="left"
                              maxLength={500}
                            />
                          </Grid.Column>
                          <Grid.Column verticalAlign="middle" width={4}>
                            {t("new_charges.willbecharged", {
                              ns: "miscSettings",
                            })}
                          </Grid.Column>
                        </>
                      )}
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column verticalAlign="middle">
                        <MyCheckbox
                          label={t("new_charges.taxable", {
                            ns: "miscSettings",
                          })}
                          name="isTaxable"
                          disabled={false}
                          helpName="settings.miscCharges.taxable"
                          showHelp
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Form.Group inline>
                          <label className="myLabel">{`${t("status", {
                            ns: "common",
                          })}:`}</label>
                          <MyHelpButton
                            name="status"
                            help="settings.miscCharges.status"
                            color={"blue"}
                          />
                          {miscChargeStatusOptions.map((data, index) => (
                            <Form.Field key={`miscChargeStatus[${data.value}]`}>
                              <Radio
                                id={`status[${index}]`}
                                key={`status[${data.value}]`}
                                value={data.value}
                                name="status"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                label={data.text}
                                checked={
                                  values.status.toString() ===
                                  data.value.toString()
                                }
                              />
                            </Form.Field>
                          ))}
                        </Form.Group>
                        {touched.status && errors.status && (
                          <Label basic color="red">
                            {errors.status}
                          </Label>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Button
                          color="green"
                          className="save_button"
                          icon="check"
                          content={t("save", { ns: "common" })}
                          disabled={isSubmitting || !dirty || !isValid}
                          loading={isSubmitting}
                          type="submit"
                        />
                        <Button
                          className="save_button"
                          as={Link}
                          to="/settings/misccharges"
                          icon="cancel"
                          type="reset"
                          content={t("cancel", { ns: "common" })}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column width={4}>
                  <ExampleCalculations miscellaneousCharge={values} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
});
