import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Label,
  Message,
  Radio,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import { Link } from "react-router-dom";
import {
  logoOptions,
  notesOptions,
  signatureOptions,
  yesNoOptions,
} from "../../../app/common/options/option";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";
import { SettingsTicketFormattingFormValues } from "../../../app/models/ticket";
import MyHelpButton from "../../../app/common/form/MyHelpButton";
import ValidationErrors from "../../errors/ValidationErrors";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Application } from "../../../app/models/enums";

export default observer(function Formatting() {
  const { t } = useTranslation(["common", "ticketSettings"]);

  const { ticketStore, userStore } = useStore();
  const { checkApplication } = userStore;

  const {
    loadSettingsTicketFormatting,
    createSettingsTicketFormatting,
    updateSettingsTicketFormatting,
    loadingInitial,
  } = ticketStore;

  const validationSchema = Yup.object({
    showBorderOnPrint: Yup.boolean().required(),
    shadeAlternatingRows: Yup.boolean().required(),
    customerSignatureLine: Yup.number().required(),
    showNotesLabel: Yup.boolean().required(),
    showPayment: Yup.boolean().required(),
    showRemoveAndReplace: Yup.boolean().required(),
    logoPlacement: Yup.number().required(),
    showPONumber: Yup.boolean().required(),
    showEstimateNumber: Yup.boolean().required(),
  });

  const [settingsTicketFormatting, setSettingsTicketFormatting] =
    useState<SettingsTicketFormattingFormValues>(
      new SettingsTicketFormattingFormValues()
    );

  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadValues();
  }, [loadSettingsTicketFormatting]);

  function loadValues() {
    loadSettingsTicketFormatting().then((setting) =>
      setSettingsTicketFormatting(
        new SettingsTicketFormattingFormValues(setting)
      )
    );
  }

  function handleFormSubmit(
    values: SettingsTicketFormattingFormValues,
    setErrors: (
      errors: FormikErrors<SettingsTicketFormattingFormValues>
    ) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsTicketFormatting(values)
      : () => createSettingsTicketFormatting(values);

    action()
      .then(() => {
        loadValues();
        setSubmitting(false);
        setCompleted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("formatting_loading", { ns: "ticketSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("formatting", { ns: "common" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />

      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsTicketFormatting}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            values,
            resetForm,
            handleChange,
            handleBlur,
            touched,
            errors,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
              <Grid columns={2} stackable>
                <Grid.Column>
                  <label className="required">
                    {`${t("formatting.showbordersonprintticket", {
                      ns: "ticketSettings",
                    })}:`}
                    <MyHelpButton
                      name="showbordersonprintticket"
                      help="settings.ticket.showBordered"
                      position="bottom left"
                      seeMore={true}
                      color={"blue"}
                    />
                  </label>
                  <Form.Group inline>
                    {yesNoOptions.map((data, index) => (
                      <Form.Field key={`showBorderOnPrint[${data.value}]`}>
                        <Radio
                          id={`showBorderOnPrint[${index}]`}
                          key={`showBorderOnPrint[${data.value}]`}
                          value={data.value}
                          name="showBorderOnPrint"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={
                            values.showBorderOnPrint.toString() === data.value
                          }
                        />
                      </Form.Field>
                    ))}
                    {touched.showBorderOnPrint && errors.showBorderOnPrint && (
                      <Label basic color="red">
                        {errors.showBorderOnPrint}
                      </Label>
                    )}
                  </Form.Group>
                </Grid.Column>
                <Grid.Column>
                  <label className="required">
                    {`${t("formatting.shadealtrows", {
                      ns: "ticketSettings",
                    })}:`}
                    {
                      <MyHelpButton
                        position="bottom right"
                        name={"settings"}
                        help={"settings.formatting.shade_alt_rows"}
                        seeMore={true}
                        color={"blue"}
                      />
                    }
                  </label>
                  <Form.Group inline>
                    {yesNoOptions.map((data, index) => (
                      <Form.Field key={`shadeAlternatingRows[${data.value}]`}>
                        <Radio
                          id={`shadeAlternatingRows[${index}]`}
                          key={`shadeAlternatingRows[${data.value}]`}
                          value={data.value}
                          name="shadeAlternatingRows"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={
                            values.shadeAlternatingRows.toString() ===
                            data.value
                          }
                        />
                      </Form.Field>
                    ))}
                    {touched.shadeAlternatingRows &&
                      errors.shadeAlternatingRows && (
                        <Label basic color="red">
                          {errors.shadeAlternatingRows}
                        </Label>
                      )}
                  </Form.Group>
                </Grid.Column>
                <Grid.Column>
                  <label className="required">
                    {`${t("formatting.customersignatureline", {
                      ns: "ticketSettings",
                    })}:`}
                  </label>
                  <MyHelpButton
                    name="customersignatureline"
                    help="settings.ticket.customerSignatureLine"
                    seeMore={true}
                    color={"blue"}
                  />
                  <Form.Group inline>
                    {signatureOptions.map((data, index) => (
                      <Form.Field key={`customerSignatureLine[${data.value}]`}>
                        <Radio
                          id={`customerSignatureLine[${index}]`}
                          key={`customerSignatureLine[${data.value}]`}
                          value={data.value}
                          name={"customerSignatureLine"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={
                            values.customerSignatureLine.toString() ===
                            data.value.toString()
                          }
                        />
                      </Form.Field>
                    ))}
                  </Form.Group>
                  {touched.customerSignatureLine &&
                    errors.customerSignatureLine && (
                      <Label basic color="red">
                        {errors.customerSignatureLine}
                      </Label>
                    )}
                </Grid.Column>
                <Grid.Column>
                  <label className="required">
                    {`${t("formatting.logoplacement", {
                      ns: "ticketSettings",
                    })}:`}
                  </label>
                  <MyHelpButton
                    name="logoplacement"
                    help="settings.ticket.logoPlacement"
                    seeMore={true}
                    color={"blue"}
                  />

                  <Form.Group inline>
                    {logoOptions.map((data, index) => (
                      <Form.Field key={`logoPlacement[${data.value}]`}>
                        <Radio
                          id={`logoPlacement[${index}]`}
                          key={`logoPlacement[${data.value}]`}
                          value={data.value}
                          name={"logoPlacement"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={
                            values.logoPlacement.toString() ===
                            data.value.toString()
                          }
                        />
                      </Form.Field>
                    ))}
                  </Form.Group>
                  {touched.logoPlacement && errors.logoPlacement && (
                    <Label basic color="red">
                      {errors.logoPlacement}
                    </Label>
                  )}
                </Grid.Column>
                <Grid.Column>
                  <label className="required">
                    {`${t("formatting.showhideifnonotes", {
                      ns: "ticketSettings",
                    })}:`}
                  </label>
                  <MyHelpButton
                    name="notesHelp"
                    help="settings.ticket.note"
                    color={"blue"}
                  />

                  <Form.Group inline>
                    {notesOptions.map((data, index) => (
                      <Form.Field key={`showNotesLabel[${data.value}]`}>
                        <Radio
                          id={`showNotesLabel[${index}]`}
                          key={`showNotesLabel[${data.value}]`}
                          value={data.value}
                          name={"showNotesLabel"}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={
                            values.showNotesLabel.toString() ===
                            data.value.toString()
                          }
                        />
                      </Form.Field>
                    ))}
                  </Form.Group>
                  {touched.showNotesLabel && errors.showNotesLabel && (
                    <Label basic color="red">
                      {errors.showNotesLabel}
                    </Label>
                  )}
                </Grid.Column>
                <Grid.Column>
                  <label className="required">
                    {`${t("formatting.showponumberonticket", {
                      ns: "ticketSettings",
                    })}:`}
                  </label>
                  <MyHelpButton
                    name="poHelp"
                    help="settings.ticket.showPoNumber"
                    color={"blue"}
                  />

                  <Form.Group inline>
                    {yesNoOptions.map((data, index) => (
                      <Form.Field key={`showPONumber[${data.value}]`}>
                        <Radio
                          id={`showPONumber[${index}]`}
                          key={`showPONumber[${data.value}]`}
                          value={data.value}
                          name="showPONumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={
                            values.showPONumber.toString() === data.value
                          }
                        />
                      </Form.Field>
                    ))}
                    {touched.showPONumber && errors.showPONumber && (
                      <Label basic color="red">
                        {errors.showPONumber}
                      </Label>
                    )}
                  </Form.Group>
                </Grid.Column>
                <Grid.Column>
                  <label className="required">
                    {`${t("formatting.showpaymentmethod", {
                      ns: "ticketSettings",
                    })}:`}
                  </label>
                  <MyHelpButton
                    name="paymentMethodHelp"
                    help="settings.ticket.showPaymentMethod"
                    color={"blue"}
                  />

                  <Form.Group inline>
                    {yesNoOptions.map((data, index) => (
                      <Form.Field key={`showPayment[${data.value}]`}>
                        <Radio
                          id={`showPayment[${index}]`}
                          key={`showPayment[${data.value}]`}
                          value={data.value}
                          name="showPayment"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={values.showPayment.toString() === data.value}
                        />
                      </Form.Field>
                    ))}
                    {touched.showPayment && errors.showPayment && (
                      <Label basic color="red">
                        {errors.showPayment}
                      </Label>
                    )}
                  </Form.Group>
                </Grid.Column>
                <Grid.Column>
                  <label className="required">
                    {`${t("formatting.showestimatenumber", {
                      ns: "ticketSettings",
                    })}:`}
                  </label>
                  <MyHelpButton
                    name="estimateNumber"
                    help="settings.ticket.showEstimateNumber"
                    color={"blue"}
                  />

                  <Form.Group inline>
                    {yesNoOptions.map((data, index) => (
                      <Form.Field key={`showEstimateNumber[${data.value}]`}>
                        <Radio
                          id={`showEstimateNumber[${index}]`}
                          key={`showEstimateNumber[${data.value}]`}
                          value={data.value}
                          name="showEstimateNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={
                            values.showEstimateNumber.toString() === data.value
                          }
                        />
                      </Form.Field>
                    ))}
                    {touched.showEstimateNumber &&
                      errors.showEstimateNumber && (
                        <Label basic color="red">
                          {errors.showEstimateNumber}
                        </Label>
                      )}
                  </Form.Group>
                </Grid.Column>
                <Grid.Column>
                  <label className="required">
                    {`${t("formatting.expandrrtoremovereplace", {
                      ns: "ticketSettings",
                    })}:`}
                  </label>
                  <MyHelpButton
                    name="R&RHelp"
                    help="settings.ticket.expandR&R"
                    color={"blue"}
                  />

                  <Form.Group inline>
                    {yesNoOptions.map((data, index) => (
                      <Form.Field key={`showRemoveAndReplace[${data.value}]`}>
                        <Radio
                          id={`showRemoveAndReplace[${index}]`}
                          key={`showRemoveAndReplace[${data.value}]`}
                          value={data.value}
                          name="showRemoveAndReplace"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label={data.text}
                          checked={
                            values.showRemoveAndReplace.toString() ===
                            data.value
                          }
                        />
                      </Form.Field>
                    ))}
                    {touched.showRemoveAndReplace &&
                      errors.showRemoveAndReplace && (
                        <Label basic color="red">
                          {errors.showRemoveAndReplace}
                        </Label>
                      )}
                  </Form.Group>
                </Grid.Column>
                <Grid.Column>
                  {checkApplication(Application.AutomotiveExpert) && (
                    <>
                      <label className="required">
                        {`${t("Miles or KM?", {
                          ns: "ticketSettings",
                        })}:`}
                      </label>
                      {/* <MyHelpButton
                    name="R&RHelp"
                    help="settings.ticket.expandR&R"
                    color={"blue"}
                  /> */}
                      <Form.Group inline>
                        {yesNoOptions.map((data, index) => (
                          <Form.Field key={`rr[${data.value}]`}>
                            <Radio
                              id={`rr[${index}]`}
                              key={`rr[${data.value}]`}
                              value={data.value}
                              name="showRemoveAndReplace"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label={data.text}
                              checked={
                                values.showRemoveAndReplace.toString() ===
                                data.value
                              }
                            />
                          </Form.Field>
                        ))}
                        {touched.showRemoveAndReplace &&
                          errors.showRemoveAndReplace && (
                            <Label basic color="red">
                              {errors.showRemoveAndReplace}
                            </Label>
                          )}
                      </Form.Group>
                    </>
                  )}
                </Grid.Column>
              </Grid>
              <Button
                color="green"
                className="save_button"
                icon="check"
                content={t("save", { ns: "common" })}
                disabled={isSubmitting || !dirty || !isValid}
                loading={isSubmitting}
                type="submit"
              />
              <Button
                className="save_button"
                as={Link}
                to="/settings"
                icon="cancel"
                type="reset"
                content={t("cancel", { ns: "common" })}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
