import { requests } from "./Requests";
import { QuickVinLookup, SettingsQuickVin } from "../models/quickVin";
import { Vehicle } from "../models/vehicle";

export const QuickVin = {
  getSettingsQuickVin: () =>
    requests.get<SettingsQuickVin>(`/SettingsQuickVin/getSettingsQuickVin/`),
  addSettingsQuickVin: (values: SettingsQuickVin) =>
    requests.post<void>("/SettingsQuickVin/addSettingsQuickVin", values),
  updateSettingsQuickVin: (values: SettingsQuickVin) =>
    requests.put<void>(
      `/SettingsQuickVin/updateSettingsQuickVin/${values.id}`,
      values
    ),
  lookUpVehicle: (quickVinLookup: QuickVinLookup) =>
    requests.post<Vehicle[]>(`/CarFax/lookupvehicle/`, quickVinLookup),
};
