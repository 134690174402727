import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../../api/agent";
import { NextChargeAdjustments } from "../../models/systemAdmin";

export default class NextChargeAdjustmentsStore {
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.nextChargeAdjustmentsRegistry.clear();
        this.loadNextChargeAdjustments();
      }
    );
  }

  loading = false;
  setLoading = (state: boolean) => {
    this.loading = state;
  };
  predicate = new Map().set("all", true);
  nextChargeAdjustmentsRegistry = new Map<number, NextChargeAdjustments>();

  loadNextChargeAdjustments = async () => {
    this.loading = true;
    try {
         this.nextChargeAdjustmentsRegistry.clear();
         const result = await agent.SystemAdminReports.getNextChargeAdjustments();
         runInAction(() => {
           result.forEach((nextChargeAdjustments) => {
             this.setNextChargeAdjustments(nextChargeAdjustments);
           });
         });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoading(false);
    }
  };

  private setNextChargeAdjustments = (
    nextChargeAdjustments: NextChargeAdjustments
  ) => {
    this.nextChargeAdjustmentsRegistry.set(
      nextChargeAdjustments.days,
      nextChargeAdjustments
    );
  };

  get getNextChargeAdjustments() {
    return Array.from(this.nextChargeAdjustmentsRegistry.values()).sort(
      (a, b) => a.days - b.days
    );
  }

  get axiosParams() {
    const params = new URLSearchParams();
    this.predicate.forEach((value, key) => {
      if (key === "StartDate" || key === "EndDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (startDate?: Date, endDate?: Date) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        if (key !== "startDate") this.predicate.delete(key);
      });
    };

    resetPredicate();
    if (startDate) this.predicate.set("StartDate", startDate);
    if (endDate) this.predicate.set("EndDate", endDate);
  };
}
