import React from "react";
import { Button, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../../app/layout/PageHeader";
import { VehicleFormValues } from "../../../../app/models/vehicle";
import { useStore } from "../../../../app/stores/store";


interface Props {
  backUrl: string;
  className?: string;
}

export default function TicketBackButton(props: Props) {
  const { t } = useTranslation(["common", "ticketSettings"]);
  const navigate = useNavigate();
  const { ticketSessionStore, modalStore, vehicleStore } = useStore();
  const { resetMakeList, resetModelList, resetEngineList, setSelectedVehicle } =
    vehicleStore;
  const {
    resetJobLabors,
    resetLabors,
    resetTicketItems,
    resetTicketPaymentItems,
    setSelectedPONumber,
  } = ticketSessionStore;

  function resetTicket() {
    resetMakeList();
    resetModelList();
    resetEngineList();
    resetJobLabors();
    resetLabors();
    resetTicketItems();
    resetTicketPaymentItems();
    setSelectedVehicle(new VehicleFormValues());
    setSelectedPONumber(undefined);
  }

  return (
    <>
      <PageHeader
        header={t("numbers.notice", { ns: "ticketSettings" })}
        type={"h3"}
        divider={true}
        addTitle={false}
      />
      <p>
        <label className="myLabel fontStyleItalic">
          {t("continueBackButton", { ns: "tickets" })}
        </label>
      </p>
      <Divider />
      <Button
        color="yellow"
        className="save_button"
        content={t("continue", { ns: "tickets" })}
        onClick={() => {
          resetTicket();
          navigate(props.backUrl);
          modalStore.closeModal();
        }}
      />
      <Button
        className="save_button"
        type="button"
        content={t("cancel", { ns: "common" })}
        onClick={() => modalStore.closeModal()}
      />
    </>
  );
}
