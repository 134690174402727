import { makeAutoObservable, reaction, runInAction } from "mobx";
import { Parts } from "../models/parts";
import agent from "../api/agent";
import { Pagination, PagingParams } from "../models/pagination";
import { sortingStrings } from "../common/util/functions";
export default class PartStore {
  loadingInitial = false;
  activeTab: number = 0;

  searchPartRegistry = new Map<string | undefined, Parts>();
  pagination: Pagination | null = null;
  predicate = new Map().set("all", true);
  pagingParams = new PagingParams();

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  setActiveTab = (activeTab: string | number | undefined) => {
    this.activeTab = activeTab ? Number(activeTab) : 0;
  };

  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.predicate.keys(),
      () => {
        this.pagingParams = new PagingParams();
        this.searchPartRegistry.clear();
        this.searchParts();
      }
    );
  }

  searchParts = async () => {
    this.loadingInitial = true;
    try {
      this.searchPartRegistry.clear()
      const result = await agent.PartApi.listSearchParts(this.axiosParams);
      runInAction(() => {
        result.data.forEach((part) => {
          this.setPart(part);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setLoadingInitial(false);
    }
  };

  private setPart = (part: Parts) => {
    this.searchPartRegistry.set(part.partCode, part);
  };

  resetSearchPartRegistry = () => {
    this.searchPartRegistry.clear();
  };
  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = pagingParams;
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }

  setPredicate = (
    predicate: string,
    value: string | Date | number | undefined
  ) => {
    const resetPredicate = () => {
      this.predicate.forEach((value, key) => {
        this.predicate.delete(key);
      });
    };
    switch (predicate) {
      case "partCode":
        resetPredicate();
        if (value) this.predicate.set("partCode", value);
        break;
      case "name":
        resetPredicate();
        if (value) this.predicate.set("name", value);
        break;
    }
  };

  get partsList() {
    return Array.from(this.searchPartRegistry.values()).sort((a, b) => {
      let temp = sortingStrings(a.partCode, b.partCode) ?? 0;
      if (temp === 0) {
        temp = sortingStrings(a.name ?? "", b.name?? "") ?? 0;
      }
      return temp;
    });
  }
}
