import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Form,
  Image,
  Message,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Formik, FormikErrors } from "formik";
import { useStore } from "../../../app/stores/store";
import MyTextArea from "../../../app/common/form/MyTextArea";
import PageHeader from "../../../app/layout/PageHeader";
import { SettingsDisclaimerFormValues } from "../../../app/models/disclaimers";
import ValidationErrors from "../../errors/ValidationErrors";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import MyHelpButton from "../../../app/common/form/MyHelpButton";

export default observer(function InvoiceDisclaimers() {
  const { t } = useTranslation(["common", "disclaimerSettings"]);
  const { disclaimerStore } = useStore();

  const {
    loadSettingDisclaimer,
    createSettingsDisclaimer,
    updateSettingsDisclaimerInvoice,
    loadingInitial,
  } = disclaimerStore;

  const validationSchema = Yup.object({});

  const [settingsDisclaimer, setSettingsDisclaimer] =
    useState<SettingsDisclaimerFormValues>(new SettingsDisclaimerFormValues());

  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    loadValues();
  }, [loadSettingDisclaimer]);

  function loadValues() {
    loadSettingDisclaimer().then((disclaimer) =>
      setSettingsDisclaimer(new SettingsDisclaimerFormValues(disclaimer))
    );
  }

  function handleFormSubmit(
    values: SettingsDisclaimerFormValues,
    setErrors: (errors: FormikErrors<SettingsDisclaimerFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void
  ) {
    const action = values.id
      ? () => updateSettingsDisclaimerInvoice(values)
      : () => createSettingsDisclaimer(values);

    action()
      .then(() => {
        loadValues();
        setSubmitting(false);
        setCompleted(true);
      })
      .catch((error) => {
        setSubmitting(false);
        setErrors({ error: error });
      });
  }

  if (loadingInitial)
    return (
      <LoadingComponent
        content={t("invoice_loading", { ns: "disclaimerSettings" })}
      />
    );

  return (
    <>
      <PageHeader
        header={t("invoice", { ns: "disclaimerSettings" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        showHelp
        helpName="settings.disclaimer.invoice"
        addTitle={true}
      />

      <Container className="page">
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={settingsDisclaimer}
          onSubmit={(values, { setErrors, setSubmitting }) =>
            handleFormSubmit(values, setErrors, setSubmitting)
          }
        >
          {({ handleSubmit, isValid, isSubmitting, errors, setFieldValue }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {completed && isValid && (
                <Message positive>
                  {t("settingsupdated", {
                    ns: "common",
                  })}
                </Message>
              )}
              <ErrorMessage
                name="error"
                render={() => <ValidationErrors errors={errors.error} />}
              />
              {/*
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    <Image
                      floated="left"
                      src="/assets/settings_page_middle.png"
                    />
                    <span className="disclaimer_header">
                      {t("middle", { ns: "disclaimerSettings" })}
                      <Button
                        floated="right"
                        content={t("default", { ns: "disclaimerSettings" })}
                        type="button"
                        onClick={() => {
                          setFieldValue(
                            "invoiceMiddle",
                            t("disclaimers.defaultMiddleInvoice", {
                              ns: "disclaimerSettings",
                            })
                          );
                        }}
                      />
                      <MyHelpButton
                        position="bottom left"
                        name=""
                        help="settings.disclaimer.leftInvoice"
                        color={"blue"}
                      />
                    </span>
                  </Card.Header>
                  <Card.Description>
                    <MyTextArea
                      placeholder={t("middle", { ns: "disclaimerSettings" })}
                      name={"invoiceMiddle"}
                      rows={4}
                      maxLength={8000}
                    ></MyTextArea>
                  </Card.Description>
                </Card.Content>
              </Card>
              */}
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    <Image
                      floated="left"
                      src="/assets/settings_page_left.png"
                    />
                    <span className="disclaimer_header">
                      {t("left", { ns: "disclaimerSettings" })}
                      <Button
                        floated="right"
                        content={t("default", { ns: "disclaimerSettings" })}
                        type="button"
                        onClick={() => {
                          setFieldValue(
                            "invoiceLeft",
                            t("disclaimers.defaultLeftInvoice", {
                              ns: "disclaimerSettings",
                            })
                          );
                        }}
                      />
                      <MyHelpButton
                        position="bottom left"
                        name=""
                        help="settings.disclaimer.leftInvoice"
                        color={"blue"}
                      />
                    </span>
                  </Card.Header>
                  <Card.Description>
                    <MyTextArea
                      placeholder={t("left", { ns: "disclaimerSettings" })}
                      name={"invoiceLeft"}
                      rows={4}
                      maxLength={8000}
                    ></MyTextArea>
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card fluid>
                <Card.Content>
                  <Card.Header>
                    <Image
                      floated="left"
                      src="/assets/settings_page_right.png"
                    />
                    <span className="disclaimer_header">
                      {t("right", { ns: "disclaimerSettings" })}
                      <Button
                        floated="right"
                        content={t("default", { ns: "disclaimerSettings" })}
                        type="button"
                        onClick={() => {
                          setFieldValue(
                            "invoiceRight",
                            t("disclaimers.defaultRightInvoice", {
                              ns: "disclaimerSettings",
                            })
                          );
                        }}
                      />
                      <MyHelpButton
                        name=""
                        help="settings.disclaimer.rightInvoice"
                        color={"blue"}
                      />
                    </span>
                  </Card.Header>
                  <Card.Description>
                    <MyTextArea
                      placeholder={t("right", { ns: "disclaimerSettings" })}
                      name={"invoiceRight"}
                      rows={4}
                      maxLength={8000}
                    ></MyTextArea>
                  </Card.Description>
                </Card.Content>
              </Card>
              <Button
                color="green"
                className="save_button"
                icon="check"
                content={t("save", { ns: "common" })}
                disabled={isSubmitting || !isValid}
                loading={isSubmitting}
                type="submit"
              />
              <Button
                className="save_button"
                as={Link}
                to="/settings"
                icon="cancel"
                type="reset"
                content={t("cancel", { ns: "common" })}
              />
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
});
