import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { ExtensionReport, TrialExtension} from "../../models/systemAdmin";

export default class ClientExtensionStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  extensionReport: ExtensionReport | undefined = undefined;

  loadExtensionReport = async () => {
    try {
      this.clearExtensionReport();
      const result = await agent.SystemAdminReports.getExtensionReport();
      runInAction(() => {
        this.setExtensionReport(result);
      });
    } catch (error) {
      console.log(error);
    }
  };

  setExtensionReport = (value: ExtensionReport | undefined) => {
    if (value) this.extensionReport = value;
  };

  clearExtensionReport = () => {
    this.extensionReport = undefined;
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };
}
