import { observer } from "mobx-react-lite";
import { Button, Icon, Message, Table } from "semantic-ui-react";
import {
  formatCurrency,
  getTicketTypeColor,
} from "../../app/common/util/functions";
import { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TicketType } from "../../app/models/enums";
import { TicketSearch } from "../../app/models/search";
import PartTicketSearch from "./PartTicketSearch";

// interface Props {
//   id: string;
// }

export default observer(function PartDetailsSearch() {
  const { t } = useTranslation(["common", "search"]);
  const navigate = useNavigate();

  const [expand, setExpand] = useState<boolean>(false);

  const handleExpand = () => {
    setExpand(!expand);
  };

  const { searchStore, ticketStore } = useStore();

  const { loadVehicleTicket, filterTicketsByVehicleId } = searchStore;
  const { resetTicketHistoryRegistry } = ticketStore;

  const [ticketSearch, setTicketSearch] = useState<TicketSearch[]>([]);
  //   useEffect(() => {
  //     loadVehicleTicket(id).then(() => {
  //       setTicketSearch(filterTicketsByVehicleId(id));
  //     });
  //   }, [loadVehicleTicket, setTicketSearch, filterTicketsByVehicleId, id]);

  return (
    <Table.Cell className="tableInTable textAlignRightNotMobile" colSpan={7}>
      <Table striped unstackable className="dnxTable no_padding">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t("vendors.vendor", { ns: "miscSettings" })}
            </Table.HeaderCell>
            <Table.HeaderCell>{t("Cost", { ns: "common" })}</Table.HeaderCell>
            <Table.HeaderCell>
              {t("Markup %", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("Quantity in Stock", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("Quantity Used", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t("Core Tracking", { ns: "common" })}
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row key={""}>
            <Table.Cell data-label={`${t("description", { ns: "common" })}:`}>
              O'Reilly
            </Table.Cell>
            <Table.Cell data-label={`${t("date", { ns: "common" })}:`}>
              $225
            </Table.Cell>
            <Table.Cell data-label={`${t("ticket", { ns: "common" })}:`}>
              125%
            </Table.Cell>
            <Table.Cell data-label={`${t("po", { ns: "common" })}#:`}>
              10
            </Table.Cell>
            <Table.Cell data-label={`${t("total", { ns: "common" })}:`}>
              482
            </Table.Cell>
            <Table.Cell data-label={`${t("total", { ns: "common" })}:`}>
              <Icon name="check" color="green" size="big" />
            </Table.Cell>
            <Table.Cell className="textAlignRight" width={6}>
              <Button
                content={t("Previous Tickets", { ns: "common" })}
                icon="eye"
                className="tealishButtons"
                size="mini"
                onClick={handleExpand}
              />
              <Button
                className="save_button mobile_buttons"
                size="mini"
                icon="edit"
                content={t("Edit", { ns: "common" })}
                onClick={() => {}}
              />

              <Button
                content={t("more", { ns: "recentTickets" })}
                icon="plus"
                size="mini"
                onClick={() => {
                  navigate(`/customer`);
                }}
              />
            </Table.Cell>
          </Table.Row>
          {/* ))
          ) : (
            <Table.Row key={"noItems"}>
              <Table.Cell width="8" colSpan={8}  className="noPaddingLeft">
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )} */}
          {expand && <PartTicketSearch />}
        </Table.Body>
      </Table>
    </Table.Cell>
  );
});
