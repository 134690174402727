import React from "react";
import {
  Button,
  Container
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import PageHeader from "../../../app/layout/PageHeader";
import { useStore } from "../../../app/stores/store";

interface Props {
  content: string;
}
export default function DisclaimerPreview(props: Readonly<Props>) {
  const { t } = useTranslation(["common", "pricingSettings", "errors"]);
  const { modalStore } = useStore();

  return (
    <>
      <PageHeader
        header={t("middle", { ns: "disclaimerSettings" })}
        type={"h2"}
        divider={true}
        getAlerts={true}
        addTitle={true}
      />
      <Container className="page">
        <p>
          <label className="linebreaks">{props.content}</label>
        </p>
        <Button
          className="save_button ui button"
          icon="cancel"
          type="button"
          content={t("close", { ns: "common" })}
          onClick={modalStore.closeModal}
          size={"medium"}
        />
      </Container>
    </>
  );
}
