import React, { useState } from "react";
import { Button, Divider, Form, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Formik } from "formik";
import { useStore } from "../../app/stores/store";
import * as Yup from "yup";
import PageHeader from "../../app/layout/PageHeader";
import { VehicleFormValues } from "../../app/models/vehicle";
import CustomerVehicleEdit from "./CustomerVehicleEdit";
import { TicketWorkflowSteps } from "../../app/models/enums";
interface Props {
  updateValue?: (values: VehicleFormValues) => void;
  isCustomerEdit?: boolean;
  autoCapitalizeFirstLetter: boolean;
  updateData: boolean;
  ticketWorkflowStep?: TicketWorkflowSteps;
  ticketId?: string;
}

export default observer(function CustomerVehicleForm({
  updateValue,
  isCustomerEdit,
  autoCapitalizeFirstLetter,
  updateData,
  ticketWorkflowStep,
  ticketId,
}: Props) {
  const { t } = useTranslation(["common", "translation", "tickets"]);
  const { vehicleStore, modalStore, ticketSessionStore, customerStore } =
    useStore();
  const { selectedVehicle, setCustomerVehicleValues } = vehicleStore;
  const { selectedCustomer } = customerStore;

  const [vehicle] = useState<VehicleFormValues>(
    selectedVehicle ?? new VehicleFormValues()
  );
  const { updateTicketSession, setSelectedTicketVehicle } = ticketSessionStore;

  function handleFormSubmit(values: VehicleFormValues) {
    setCustomerVehicleValues(values);
    setSelectedTicketVehicle(values);

    if (updateData && ticketWorkflowStep && ticketId) {
      //store data
      updateTicketSession(
        ticketWorkflowStep,
        ticketId,
        values,
        selectedCustomer
      );
    }

    if (updateValue) updateValue(values);

    modalStore.closeModal();
  }
  const validationSchema = Yup.object({});
  return (
    <>
      <PageHeader
        header={t("pops.editVehicleDetails", {
          ns: "tickets",
        })}
        type={"h3"}
        divider={true}
        getAlerts={true}
        addTitle={false}
      />
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={vehicle}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          handleSubmit,
          isValid,
          isSubmitting,
          dirty,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
        }) => (
          <Form
            className="ui form error"
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <CustomerVehicleEdit
              isCustomerEdit={isCustomerEdit}
              autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
            />
            <Divider />
            <Button className="save_button" color="green" type="submit">
              <Icon name="check" />
              {t("update", { ns: "common" })}
            </Button>
            <Button
              className="save_button"
              onClick={() => modalStore.closeModal()}
              type="reset"
            >
              <Icon name="x" />
              {t("cancel", { ns: "common" })}
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
});
