import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Grid, Input, Message, Table } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";
import { format, isValid } from "date-fns";
import { PagingParams } from "../../../app/models/pagination";
import MyPaging from "../../../app/common/form/MyPaging";
import PageHeader from "../../../app/layout/PageHeader";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useLocation } from "react-router-dom";
import { CarFaxShopDataReport } from "../../../app/models/systemAdmin";


export default observer(function CarFaxShopDataSubmissions() {
  const { t } = useTranslation(["common", "systemAdmin"]);
  const location = useLocation();
  const { carfaxShopDataStore } = useStore();
  const {
    loadCarFaxShopData,
    getCarFaxShopData,
    pagingParams,
    pagination,
    predicate,
    setPagingParams,
    setPredicate,
    clearPredicate,
    loading,
    setFiltering,
    filtering,
  } = carfaxShopDataStore;

  const [clientId, setClientId] = useState<string | undefined>(undefined);
  const [initiateFetch, setInitiateFetch] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const qryClientId = queryParams.get('clientId');
      if (qryClientId)
        setClientId(qryClientId);
    }
  }, []);

  useEffect(() => {
    if (initiateFetch && (clientId ?? "").length >= 9) {
      loadCarFaxShopData(clientId!);
    }
  }, [initiateFetch, pagingParams]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
    }
  }

  return (
    <>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <label>{t("carfax_clientId", { ns: "reports" }) + ":"}</label>{" "}
            <Input
              onChange={(e, d) => {
                setClientId(d.value)
              }}
              onKeyDown={(e: React.KeyboardEvent) => {
                if (e.key === 'Enter') {
                  // Handle enter key logic here, like triggering a form submission or calling a function
                  setInitiateFetch(true);
                }
              }}
              loading={loading && !filtering}
              placeholder="Company ID/CID"
            />
          </Grid.Column>
        </Grid.Row>
        {clientId && initiateFetch && (
          <Grid.Row columns={3}>
            <Grid.Column>
              <label>{t("start_date", { ns: "reports" }) + ":"}</label>{" "}
              <SemanticDatepicker
                name="startDate"
                placeholder={t("start_date", { ns: "reports" })}
                onChange={(e, d) => {
                  setStartDate(d.value ? d.value as Date : undefined);
                  setPredicate('startDate', d.value ? d.value : undefined);
                }}
                value={startDate}
              />{" "}
            </Grid.Column>
            <Grid.Column>
              <label>{t("end_date", { ns: "reports" }) + ":"}</label>{" "}
              <SemanticDatepicker
                name="endDate"
                placeholder={t("end_date", { ns: "reports" })}
                onChange={(e, d) => {
                  setEndDate(d.value ? d.value as Date : undefined);
                  setPredicate('endDate', d.value ? d.value : undefined);
                }}
                value={endDate}
              />
            </Grid.Column>
            <Grid.Column className='d-flex'>
              <Button
                color="green"
                className="save_button"
                icon="check"
                content={t("get_report", { ns: "reports" })}
                type='button'
                onClick={async (e) => {
                  e.preventDefault();
                  setFiltering(true);
                  await loadCarFaxShopData(clientId);
                  setFiltering(false);
                }}
                loading={filtering}
              />
              {predicate.size > 0 && (
                <Button
                  color="grey"
                  className="save_button"
                  icon="cancel"
                  type="reset"
                  content={t("clearFilters", { ns: "common" })}
                  onClick={async (e) => {
                    e.preventDefault();
                    setFiltering(true);
                    clearPredicate();
                    setEndDate(undefined);
                    setStartDate(undefined);
                    setPagingParams(new PagingParams(1, 25))
                    await loadCarFaxShopData(clientId);
                    setFiltering(false);
                  }}
                  disabled={filtering}
                />
              )}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      <Divider />
      <Grid stackable>
        <Grid.Row>
          <Grid.Column>
            <PageHeader
              type="h1"
              header={t("reports.report_carfaxShopData_submissions", { ns: "systemAdmin" }).toString()}
              className="modal-text-color"
              textAlign="left"
              divider
              addTitle
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Column stackable>
          <Table striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {t("reports.carfax_data_date", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("reports.carfax_processed_date", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("reports.carfax_invoice_number", { ns: "systemAdmin" })}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {t("reports.carfax_ticket_lines", { ns: "systemAdmin" })}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {getCarFaxShopData &&
                getCarFaxShopData.length > 0 ?
                getCarFaxShopData.map((x: CarFaxShopDataReport, xId: number) => (
                  <Table.Row key={`carFaxNightly-${xId}`}>
                    <Table.Cell>{isValid(x.dataDate) ? format(x.dataDate!, "P p") : ""}</Table.Cell>
                    <Table.Cell>{isValid(x.processedDate!) ? format(x.processedDate!, "P p") : ""}</Table.Cell>
                    <Table.Cell>{x.invoiceNumber}</Table.Cell>
                    <Table.Cell>{x.ticketLines}</Table.Cell>
                  </Table.Row>
                )
                ) : (
                  <Table.Row key={"noitems"}>
                    <Table.Cell width="8" colSpan={8}>
                      <Message
                        color="yellow"
                        className="textAlignCenter fontStyleItalic"
                      >
                        {t("grid_noItems", { ns: "common" })}
                      </Message>
                    </Table.Cell>
                  </Table.Row>
                )}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="8">
                  <MyPaging
                    itemCount={pagination ? pagination.totalItems : 25}
                    pageSize={pagingParams.pageSize}
                    currentPage={pagination ? pagination.currentPage : 1}
                    onClick={listOnClick}
                    onChange={listOnClick}
                    floated="right"
                  ></MyPaging>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid>
    </>
  );
});
