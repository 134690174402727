import { DropdownItemProps } from "semantic-ui-react";
import { requests } from "./Requests";

export const Job = {
  jobCategoryList: () =>
    requests.get<DropdownItemProps[]>(
      `/jobcategory/listjobcategories?toplevel=true`
    ),
  jobTypeList: (categoryId: number | undefined) =>
    requests.get<DropdownItemProps[]>(
      `/jobcategory/listjobcategories?toplevel=false&parentID=${categoryId}`
    ),
  jobList: (jobTypeId: number) =>
    requests.get<DropdownItemProps[]>(`/job/listjobs/${jobTypeId}`),
  dsiJobList: (
    jobTypeId: number,
    model: string,
    engine: string,
    isCustom: boolean
  ) =>
    requests.get<DropdownItemProps[]>(
      `/job/listdsijobs?model=${model}&jobcategoryid=${jobTypeId}&engine=${engine}&isCustom=${isCustom}`
    ),
  searchJobList: (
    model: string,
    engine: string
  ) =>
    requests.get<DropdownItemProps[]>(
      `/job/searchDsiJobs?model=${model}&engine=${engine}`
    ),
  jobRequiresEngine: (jobId: number) =>
    requests.get<boolean>(`/job/getJobRequiresEngine/${jobId}`),
};
