import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Card, Grid, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { TimeType } from "../../../app/models/enums";

interface Props {
  name: string;
  header: string;
  timeType: TimeType;
  hours?: string;
  amount?: string;
  className?: string;
  onClick?: (timeType: TimeType, isCustom: boolean) => void;
}

export default observer(function TimeControl(props: Props) {
  const { t } = useTranslation(["common", "translation"]);

  return (
      <Card className={props.className}>
        <Card.Header className="card-header" textAlign="center">
          {props.header}
        </Card.Header>
        <Card.Content>
          <Grid centered>
            <Grid.Row columns={2} centered>
              <Grid.Column textAlign="right">
                <b>{`${t("hours", { ns: "tickets" })}: `}</b> 
              </Grid.Column>
              <Grid.Column>
              {props.hours}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} centered>
              <Grid.Column textAlign="right">
                <b>{`${t("amount", { ns: "tickets" })}: `}</b> 
              </Grid.Column>
              <Grid.Column>
              {props.amount}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
        <Card.Content extra>
          <Button fluid primary floated="right" onClick={() => {if (props.onClick) props.onClick(props.timeType, false)}}>
            <Icon name="thumbs up" />
            {t("add_button", { ns: "tickets" })}
          </Button>
        </Card.Content>
      </Card>
  );
});
